import React, { FC, useState } from "react";
import { LABELS, initialFormData } from "./ChangePasswordFormConstants.data";
import { Box, Typography, Grid, Button, withStyles } from "@material-ui/core";
import DashboardButton from "../../../dashboardButton/DashboardButton";
import { color } from "../../../../../theme/Theme";
import { Formik, Field } from "formik";
import RenderInput from "../../../../FormikComponents/RenderInputField";
import {
  ChangePassWordFieldNames,
  ChangePasswordFormFields,
} from "./ChangePasswordFormData.data";
import { VALIDATION_SCHEMA } from "./ChangePasswordFormConstants.data";
import {
  inputStylePropsGenerator,
  RenderInputFieldStyles,
} from "../../../../FormikComponents/RenderInputFieldStyle";
import PasswordInstructions from "../../../../passwordInstructions/PasswordInstructions";

interface Props {
  onSubmit: (values: ChangePasswordFormFields) => void;
}

const SubmitButton = withStyles({
  root: {
    padding: ".5em 1em",
    borderRadius: "4px",
  },
})(Button);

const ChangePasswordForm: FC<Props> = (props) => {
  const { onSubmit } = props;
  const [form, showForm] = useState(false);
  const [showPassword, setPasswordVisible] = useState(false);
  const renderInputProps = inputStylePropsGenerator(RenderInputFieldStyles());

  return form ? (
    <Formik
      initialValues={initialFormData}
      onSubmit={(values) => {
        onSubmit(values);
        showForm(false);
      }}
      validationSchema={VALIDATION_SCHEMA}
      enableReinitialize>
      {({ values, handleSubmit, isValid, dirty }) => {
        return (
          <>
            <Grid container>
              <Grid item md={4}>
                <Box px={2}>
                  <Field name={ChangePassWordFieldNames.newPassword}>
                    {({ form, field }) => (
                      <RenderInput
                        label={LABELS.newPassword}
                        {...renderInputProps}
                        {...field}
                        type={showPassword ? "text" : "password"}
                        showPassword={showPassword}
                        handleShowPassword={() =>
                          setPasswordVisible(!showPassword)
                        }
                      />
                    )}
                  </Field>
                  <PasswordInstructions />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box pl={2}>
                  <Field name={ChangePassWordFieldNames.confirmPassword}>
                    {({ form, field }) => (
                      <RenderInput
                        label={LABELS.confirmPassword}
                        {...renderInputProps}
                        {...field}
                        type={showPassword ? "text" : "password"}
                        showPassword={showPassword}
                        handleShowPassword={() =>
                          setPasswordVisible(!showPassword)
                        }
                      />
                    )}
                  </Field>
                </Box>
              </Grid>
            </Grid>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <SubmitButton
                type="submit"
                variant={!isValid || !dirty ? "outlined" : "contained"}
                disabled={!isValid || !dirty}
                onClick={() => handleSubmit()}>
                {LABELS.saveNewPassword}
              </SubmitButton>
            </Box>
          </>
        );
      }}
    </Formik>
  ) : (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box
        width="196px"
        mt={2}
        pb={1}
        pl={2}
        borderBottom={`1px solid ${color.lightGrey}`}>
        <Typography variant="caption">{LABELS.password}</Typography>
        <Typography variant="h4">{LABELS.dummyPassword}</Typography>
      </Box>
      <DashboardButton
        action={() => showForm(true)}
        centered
        text={LABELS.update}
      />
    </Box>
  );
};

export default ChangePasswordForm;
