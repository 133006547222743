import {
  SignUpActionType,
  SignUpAction,
  SignUpErrorPayload,
  SignUpSuccessPayload,
} from "../actions/SignUpAction";
import { Logging } from "../../helper/Logging";
import { SignUpState } from "../../store/state/SignUpState";

const initialState: SignUpState = {
  loading: false,
  success: false,
  error: "",
  signUpDetails: null,
};

export const signUpReducer = (state = initialState, action: SignUpAction) => {
  if (action.type.includes("FORGOT_PASSWORD_LINK_REQUEST")) {
    Logging.debug("signUpReducer: Action", {
      type: action.type,
      data: Logging.traceData(action.data),
    });
  }

  switch (action.type) {
    case SignUpActionType.SignUpLinkRequest: {
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    }
    case SignUpActionType.SignUpLinkSuccess: {
      const signUpDetails = action.data as SignUpSuccessPayload;
      return {
        ...state,
        signUpDetails,
        loading: false,
        success: true,
        error: null,
      };
    }
    case SignUpActionType.SignUpLinkError: {
      const data = action.data as SignUpErrorPayload;
      return {
        ...state,
        loading: false,
        success: false,
        error: data ? data.error : null,
      };
    }
    default:
      return state;
  }
};

export default signUpReducer;
