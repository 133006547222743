import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import { AppSettings } from "../../AppSettings";
import ApiServiceMock from "../__mocks__/ApiService";
import ApiServiceBase from "../ApiServiceBase";
import { SignUpRequest, UserTradeEntitlement } from "./SignUpRequest.data";
import { QueryPath } from "../QueryPath.data";

const serviceType = ServiceType.Auth;

export interface SignUpRequestData {
  firstName?: string | undefined;
  lastName?: string | undefined;
  email: string | null | undefined;
  username: string;
  password: string | null | undefined;
  userTradeEntitlements: UserTradeEntitlement[];
}

export class SignUpService {
  /* istanbul ignore else*/
  private readonly service: ApiServiceBase = AppSettings.testSettings.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);

  public SignUpPostData(data: SignUpRequest): Promise<void> | void {
    const requestData: SignUpRequestData = {
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.username,
      email: data.email,
      password: data.password,
      userTradeEntitlements: data.userTradeEntitlements,
    };

    const query = data.agency ? { agencyCode: data.agency } : undefined;
    const pathParams: QueryPath = { query, route: ["user"] };

    return this.service.post(pathParams, requestData);
  }
}

const signUpService = new SignUpService();
export default signUpService;
