import React, { FC } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ContentHeadingSectionStyles } from "./ContentHeadingSectionStyles.data";
import { useHistory } from "react-router";
import { ArrowBack } from "@material-ui/icons";

interface Props {
  heading: string;
  onBack?: () => void;
  hideBack?: boolean;
  subheading?: string;
  optionsSection?: JSX.Element;
  hideOnSmallScreens?: boolean;
}

const ContentHeadingSection: FC<Props> = ({
  heading,
  optionsSection,
  subheading,
  hideOnSmallScreens,
  onBack,
  hideBack,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const classes = ContentHeadingSectionStyles();

  const history = useHistory();
  return !smallScreen || !hideOnSmallScreens ? (
    <>
      <Box width="100%" display={"flex"} alignItems="center">
        {!hideBack && (
          <Box
            display="flex"
            alignItems="center"
            onClick={onBack ? () => onBack() : () => history.goBack()}
            style={{ cursor: "pointer" }}>
            <ArrowBack />
          </Box>
        )}
        <Box ml={1} display="flex" alignItems="center" flexGrow="1">
          <Typography variant="h5">{heading}</Typography>
        </Box>
        {optionsSection ? <Box>{optionsSection}</Box> : null}
      </Box>
      {subheading ? (
        <Typography className={classes.subheading}>{subheading}</Typography>
      ) : null}
    </>
  ) : null;
};

export default ContentHeadingSection;
