import { makeStyles } from "@material-ui/core";
import { color } from "../../../../../theme/Theme";

export const UserDocumentsStyles = makeStyles({
  fileInput: {
    display: "none",
  },
  fixedListItems: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  pointer: {
    cursor: "pointer",
  },
  detailsContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
  },
  documentName: {
    flexBasis: "70%",
  },
  listItemDivider: {
    borderBottom: `1px solid ${color.lightGrey} !important`,
    backgroundClip: "padding-box",
  },
  expiryText: {
    color: color.warning,
  },
  listItemSecondaryAction: {
    display: "none",
  },
  listItem: {
    "&:hover $listItemSecondaryAction": {
      display: "block",
    },
    "&:hover": {
      background: color.wildSand,
      cursor: "pointer",
    },
  },
});
