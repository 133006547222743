export const formatCvvNumber = (cvv: string) => {
  return cvv.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
};

export const formatDate = (value: string, prevValue: string) => {
  if (prevValue && prevValue.includes("/") && !value.includes("/")) {
    return value;
  }
  if (value.includes("/") && prevValue && prevValue.includes("/")) {
    return value;
  }

  if (value) {
    const expiryValue = value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        "0" // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        "" // eslint-disable-line
      );
    return expiryValue;
  } else return value;
};
