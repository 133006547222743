import { takeEvery, put } from "redux-saga/effects";
import { Logging } from "../../helper/Logging";
import ListBranchesService from "../../services/listBranches/ListBranchesService";
import { GetTradeRequest, GetTradeRequestPayload } from "../../services/listBranches/ListBranchesServiceData.data";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import {ListBranchesActions, ListBranchesActionType, ListBranchesAction} from "../actions/ListBranchesActions"

export function* getListBranchesWorker(action: ListBranchesAction) {  
  const data = action.data as GetTradeRequestPayload
  const { request, onSuccess } = { ...data };

  try {
    Logging.debug("RegisterBusinessSagas.getListBranchesWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());
    Logging.debug("RegisterBusinessSagas.getListBranchesWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    const response: any = (yield ListBranchesService.get(
      request! as GetTradeRequest
    ))!;

    yield put(ListBranchesActions.listBranchesSuccess(response));

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    Logging.error("RegisterBusinessSagas.getListBranchesWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(LoadingIndicatorActions.hide());
    yield put(ListBranchesActions.listBranchesError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
  
}

const getListBranchesWatcher = [  
  takeEvery(ListBranchesActionType.ListBranchesRequest, (action) =>
  getListBranchesWorker({
      type: action.type,
      data: (action as ListBranchesAction).data,
    })
  ),
];

export default getListBranchesWatcher;