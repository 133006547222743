import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
} from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { Field, Formik } from "formik";
import React, { FC, useMemo, useState } from "react";
import { routes } from "../../Routes";
import { color } from "../../theme/Theme";
import AuthPagesWrapper from "../authPagesWrapper/AuthPagesWrapper";
import {
  LABELS,
  VALIDATION_SCHEMA,
  ResetPasswordFormData,
} from "./ResetPasswordConstants";
import RenderInputField from "../FormikComponents/RenderInputField";

import { resetPasswordStyles } from "./ResetPasswordStyles";
import PasswordInstructions from "../passwordInstructions/PasswordInstructions";
import { useHistory } from "react-router";
import ErrorText from "../registrationStepsComponents/errorText/ErrorText";

interface ResetPasswordComponentProps {
  onSubmit: (data: ResetPasswordFormData) => void;
  success: boolean;
  error: string;
}

const ResetPasswordComponent: FC<ResetPasswordComponentProps> = ({
  onSubmit,
  success,
  error,
}) => {
  // state
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  // hooks
  const classes = resetPasswordStyles();
  const history = useHistory();

  // functions and variables
  const initialValues: ResetPasswordFormData = useMemo(
    () => ({
      password: "",
      confirmPassword: "",
    }),
    []
  );

  return (
    <AuthPagesWrapper includeLogo containerClassName={"login-fields"}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur
        validationSchema={VALIDATION_SCHEMA}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.titleStyle}>
                <h2 className={classes.labelStyle}>
                  {success ? LABELS.success : LABELS.resetPassword}
                </h2>
                <p style={{ marginTop: "9px" }}>
                  {success ? LABELS.allDoneMessage : LABELS.nearlyDoneMessage}
                </p>
              </div>

              {/* Form fields */}
              {success ? (
                <>
                  <Box className={classes.resetLinkMessageContainer}>
                    <ListItem>
                      <ListItemAvatar className={classes.listItemAvatar}>
                        <Box color={color.teal} pr={2}>
                          <CheckCircleOutline />
                        </Box>
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            className={classes.resetLinkMessage}
                            variant="caption">
                            {LABELS.passwordUpdated}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Box>

                  <Button
                    fullWidth={true}
                    onClick={() => history.push(routes.login.viewGeneric())}
                    className={classes.loginButton}>
                    {LABELS.login.toUpperCase()}
                  </Button>
                </>
              ) : (
                <>
                  <Field name={"password"}>
                    {({ field }) => (
                      <RenderInputField
                        type={showPassword ? "text" : "password"}
                        showPassword={showPassword}
                        handleShowPassword={() =>
                          setShowPassword(!showPassword)
                        }
                        label={LABELS.newPassword}
                        {...field}
                      />
                    )}
                  </Field>

                  {/* Instructions */}
                  <PasswordInstructions />

                  <Field name={"confirmPassword"}>
                    {({ field }) => (
                      <RenderInputField
                        label={LABELS.confirmNewPassword}
                        showPassword={showConfirmPassword}
                        handleShowPassword={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        type={showConfirmPassword ? "text" : "password"}
                        {...field}
                      />
                    )}
                  </Field>

                  <div className={classes.buttonContainer}>
                    <Button
                      fullWidth={true}
                      type="submit"
                      className={classes.loginButton}>
                      {LABELS.resetPassword.toUpperCase()}
                    </Button>
                  </div>
                  {error && (
                    <Box width={"100%"} textAlign="center" mt={2}>
                      <ErrorText centered text={error} />
                    </Box>
                  )}
                </>
              )}
            </form>
          );
        }}
      />
    </AuthPagesWrapper>
  );
};

export default ResetPasswordComponent;
