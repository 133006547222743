import * as React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  LinearProgress,
  FormControlLabel,
  Box,
  Checkbox,
} from "@material-ui/core";
import {
  RegistrationFormStyles,
  RegistrationFormComponentStyles,
  inputsFields,
} from "./RegistrationStyles";
import HeaderTitle from "../headerTitle/HeaderTitle";
import { Field } from "redux-form";
import RenderInput from "../RenderInput/RenderInput";
// tslint:disable-next-line: no-implicit-dependencies
import RenderCheckbox from "../renderCheckbox/RenderCheckbox";
import {
  ABN_LENGTH,
  LABELS,
  sortedTncDocumentLink,
} from "./RegistrationFormConstants";
import RenderAddressPicker from "../AddressPicker/RenderAddressPicker";
import { AddressValue } from "../../helper/GooglePlaces/AddressValue.data";
import { AddressComponent } from "../AddressPicker/AddressPicker.data";
import {
  RegistrationFormData,
  BranchAddress,
  AddressFields,
  BUSINESS_TYPES,
} from "./RegistrationFormData.data";
import { StoreHelper } from "../../store/StoreHelper";
import RenderSelect from "../RenderSelect/RenderSelect";
import RenderImageInput from "../RenderImageInput/RenderImageInput";
import withWidth from "@material-ui/core/withWidth";
import { Trade } from "src/container/listBranches/ListBranches.data";
import { AbnService } from "../../services/registerBusiness/AbnDetailsService";
import { AbnDetails } from "../../services/registerBusiness/AbnDetails.data";

const { validation, form } = { ...RegistrationFormData.metadata };
interface StateProps {
  imageSource: any;
  validAddress: boolean | null;
  abnDetails: AbnDetails | null;
  abnValid: boolean | null;
  abnLoading: boolean;
  feeConfirmation: boolean;
}

interface PropsFromParent {
  reduxFormState: RegistrationFormData;
  invalid: boolean;
  handleSubmit: any;
  firstName: string;
  tradeDetails: Trade | null;
  change: (name: string, value: string | number | boolean) => any;
}

interface PropsFromWidthHOC {
  width: string;
}

type OwnProps = RegistrationFormComponentStyles &
  PropsFromParent &
  PropsFromWidthHOC;

export class RegistrationFormComponent extends React.Component<
  OwnProps,
  StateProps
> {
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      imageSource: null,
      validAddress: null,
      abnDetails: null,
      abnValid: null,
      abnLoading: false,
      feeConfirmation: false,
    };
    this.onImageChange = this.onImageChange.bind(this);
    if (!this.props.tradeDetails) {
      this.props.change("primaryAddress", true);
    }
  }

  public getRegistrationFormAddressComponent = (
    placeAddressComponents: AddressComponent[]
  ): BranchAddress => {
    const address = {};

    for (const component of placeAddressComponents) {
      for (const type of component.types) {
        if (AddressFields[type]) {
          address[AddressFields[type]] = component.long_name;
        }
      }
    }

    return address as BranchAddress;
  };

  public componentDidUpdate(
    prevProps: Readonly<OwnProps>,
    prevState: StateProps
  ) {
    const { tradeDetails, change } = this.props;
    if (tradeDetails) {
      if (
        prevState.imageSource !== this.state.imageSource ||
        this.state.imageSource === ""
      ) {
        this.setState({
          imageSource: tradeDetails.logoKey,
        });
        change("businessName", tradeDetails.name);
        change("businessType", tradeDetails.tradeTypeId);
      }
    }
  }

  getAbnDetails = async (abn: number) => {
    const { change } = this.props;
    if (abn.toString().length === ABN_LENGTH) {
      try {
        this.setState({ abnDetails: null, abnLoading: true });
        const abnDetails = await AbnService.getAbnDetails(abn);
        const abnValid = abnDetails.AbnStatus === "Active";
        this.setState({ abnValid });
        if (abnValid) {
          this.setState({ abnDetails });
          change("businessName", abnDetails.EntityName);
          change("branchName", `${abnDetails.EntityName} - Head office`);
        }
      } catch {
        this.setState({ abnValid: false });
      } finally {
        this.setState({ abnLoading: false });
      }
    }
  };

  // tslint:disable-next-line: cyclomatic-complexity
  public render(): JSX.Element {
    const { classes, invalid, handleSubmit, width, firstName, tradeDetails } =
      this.props;
    const { imageSource, validAddress, feeConfirmation } = this.state;
    const formInvalid = invalid || !validAddress;

    return (
      <div className={classes.loginComponent}>
        <Grid container>
          <Grid
            item
            lg={6}
            md={8}
            sm={10}
            xs={12}
            className={classes.registrationBox}>
            <HeaderTitle
              title={
                tradeDetails
                  ? LABELS.ADD_BRANCH_HEADER_MESSAGE
                  : `Hi ${firstName}, register your business below`
              }
              message={
                tradeDetails
                  ? LABELS.ADD_BRANCH_SUBHEADER_MESSAGE
                  : LABELS.ADD_BUSINESS_SUBHEADER_MESSAGE
              }></HeaderTitle>
            <section className={classes.registrationContent}>
              <article className={classes.articleContainer}>
                <div className={classes.sectionHeader}>
                  <Typography variant="h6">
                    {tradeDetails
                      ? LABELS.ADD_BRANCH_CONTENT_BOX_MESSAGE
                      : LABELS.ADD_BUSINESS_CONTENT_BOX_MESSAGE}
                  </Typography>
                </div>
                <h5 className={classes.articleHeader}>
                  {tradeDetails
                    ? LABELS.ADD_BRANCH_SECTION_HEADING
                    : LABELS.ADD_BUSINESS_SECTION_HEADING}
                </h5>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={2}
                    className={width === "xs" ? classes.centered : ""}>
                    <div
                      className={width === "xs" ? "" : classes.imageContainer}>
                      <RenderImageInput
                        onChange={this.onImageChange}
                        src={
                          imageSource
                            ? typeof imageSource === "string"
                              ? imageSource
                              : URL.createObjectURL(this.state.imageSource)
                            : ""
                        }
                        label={"Add logo"}
                        disabled={!!tradeDetails}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={10}>
                    <div>
                      <Field
                        name="businessType"
                        label="Business type"
                        style={inputsFields}
                        component={RenderSelect}
                        data={BUSINESS_TYPES}
                        validate={validation.businessType}
                        disabled={!!tradeDetails}
                        overrideInputStyles={{ input: classes.input }}
                      />

                      <Grid
                        container
                        spacing={width === "xs" ? 0 : width === "sm" ? 4 : 6}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="abn"
                            label="ABN"
                            style={inputsFields}
                            component={RenderInput}
                            type="number"
                            validate={validation.abn}
                            onChange={(_: React.ChangeEvent, value: number) =>
                              this.getAbnDetails(value)
                            }
                          />
                          {this.state.abnLoading && (
                            <LinearProgress
                              className={classes.abnLoadingMarginTop}
                            />
                          )}
                          {this.state.abnValid === false ? (
                            <p className={classes.errorText}>
                              ABN entered is invalid or cannot be found in the
                              registry.
                            </p>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="businessName"
                            label="Business name"
                            style={inputsFields}
                            component={RenderInput}
                            type="text"
                            overrideInputStyles={{ input: classes.input }}
                            validate={validation.businessName}
                            disabled={!!tradeDetails}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </article>

              {/* Branch Details */}
              <article className={classes.articleContainer}>
                <h5 className={classes.articleHeader}>
                  {LABELS.BRANCH_SECTION_HEADING}
                </h5>

                <div>
                  <Grid container spacing={width === "xs" ? 0 : 6}>
                    <Grid item xs={12}>
                      <Field
                        name="branchName"
                        label="Your office name"
                        style={inputsFields}
                        component={RenderInput}
                        type="text"
                        overrideInputStyles={{ input: classes.input }}
                        validate={validation.branchName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="branchPhone"
                        label="Your office phone"
                        style={inputsFields}
                        component={RenderInput}
                        type="text"
                        overrideInputStyles={{ input: classes.input }}
                        validate={validation.branchPhone}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="branchEmail"
                        label="Your office email"
                        style={inputsFields}
                        component={RenderInput}
                        type="text"
                        overrideInputStyles={{ input: classes.input }}
                        validate={validation.branchEmail}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.spaceTop}>
                  <RenderAddressPicker
                    name="branchAddress"
                    label="Your office address"
                    placeholder={"Your office address"}
                    onChange={(value: AddressValue) => {
                      if (value.place) {
                        const branchAddress =
                          this.getRegistrationFormAddressComponent(
                            value.place!
                              .address_components! as AddressComponent[]
                          );

                        StoreHelper.changeFormState<RegistrationFormData>(
                          form,
                          {
                            branchAddress: {
                              ...branchAddress,
                              latitude:
                                value.place.geometry?.location.lat() as number,
                              longitude:
                                value.place.geometry?.location.lng() as number,
                            },
                          }
                        );
                        this.setState({
                          validAddress: true,
                        });
                      }
                    }}
                    onBlur={(value: AddressValue) => {
                      if (!value.place) {
                        this.setState({
                          validAddress: false,
                        });
                      }
                    }}
                    changeEverykeyPress // Only used for validation purpose so that we can check if place array is filled or not in the end
                  />
                  {validAddress === false ? (
                    <p className={classes.errorText}>Invalid Address</p>
                  ) : null}
                </div>
                <div className={classes.checkBoxContainer}>
                  <Field
                    name="primaryAddress"
                    label={LABELS.PRIMARY_OFFICE}
                    component={RenderCheckbox}
                    labelStyle={classes.checkBoxLabel}
                    color={"primary"}
                  />
                </div>
              </article>
            </section>

            {/* Sorted fee confirmation */}
            <Box mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={feeConfirmation}
                    onChange={(event) => {
                      this.setState({
                        feeConfirmation: event.target.checked,
                      });
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography component="span" variant="caption">
                    I agree to the
                    <a
                      className={classes.tncLink}
                      target="_blank"
                      href={sortedTncDocumentLink()}>
                      Sorted Trades Terms and Conditions
                    </a>
                    and understand that there is no cost to join however a
                    processing fee may be automatically applied to the total
                    cost of a job, this will be specified to you at the time of
                    completing the job processing in the Sorted platform. This
                    fee is for ongoing support and improvements to the Sorted
                    platform, and will be invoiced to me on a monthly basis.
                  </Typography>
                }
                labelPlacement="end"
                classes={{
                  label: classes.checkboxLabel,
                  root: classes.checkboxRoot,
                }}
              />
            </Box>

            <section className={classes.buttonsSection}>
              <Button
                disabled={
                  formInvalid || !this.state.abnValid || !feeConfirmation
                }
                onClick={(e) => handleSubmit(e, this.state.imageSource)}
                className={classes.navButton}
                color="primary">
                Next
              </Button>
            </section>
          </Grid>
        </Grid>
      </div>
    );
  }

  private onImageChange(image) {
    const imageSource = image ? image : "";
    this.setState({
      imageSource,
    });
  }
}

export default withWidth()(
  withStyles(RegistrationFormStyles)(RegistrationFormComponent)
);
