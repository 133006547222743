import { createStyles, WithStyles } from "@material-ui/core";

export const RenderImageInputStyles = createStyles({
  previewImage: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    cursor: "pointer",
  },
  addImageDiv: {
    width: "80px",
    height: "80px",
    border: "solid 1px #f0f1f1",
    backgroundColor: "#fafafa",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "11px",
    color: "#9a9a9a",
    cursor: "pointer",
  },
});

export interface RenderImageInputComponentStyles
  extends WithStyles<typeof RenderImageInputStyles> {}
