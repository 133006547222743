import React, { FC } from "react";
import { Box, Typography, Link, IconButton } from "@material-ui/core";

import { color } from "../../theme/Theme";
import Shimmer from "../loader/Shimmer";
import { CounterData } from "./Counter.data";
import { RefreshOutlined } from "@material-ui/icons";
import { counterStyles } from "./CounterStyles";

interface CounterProps {
  loading?: boolean;
  data: CounterData[];
  onRefresh: () => void;
}

const Counter: FC<CounterProps> = ({ loading, data, onRefresh }) => {
  const classes = counterStyles();

  return (
    <Box display="flex" mb="2rem">
      <Box
        width="100%"
        display="flex"
        p="1rem"
        justifyContent="space-around"
        style={{
          border: `solid 1px ${color.borderColor}`,
          borderRight: "none",
          borderRadius: "0.25rem",
          borderTopRightRadius: "0",
          borderBottomRightRadius: "0",
        }}>
        {loading ? (
          <CounterLoader />
        ) : (
          <>
            {data.map(({ label, value, action }) => (
              <Box display="flex" flexDirection="column">
                <Typography color="textSecondary" variant="caption">
                  {label}
                </Typography>
                <Typography className={classes.count} variant="h2">
                  {value}
                </Typography>
                <Typography
                  className={classes.pointer}
                  color="primary"
                  variant="caption">
                  <Link onClick={action}>{"See all >"}</Link>
                </Typography>
              </Box>
            ))}
          </>
        )}
      </Box>
      <Box
        width="3rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          border: `solid 1px ${color.borderColor}`,
          borderTopRightRadius: "0.25rem",
          borderBottomRightRadius: "0.25rem",
        }}>
        <IconButton disabled={loading} onClick={onRefresh}>
          <RefreshOutlined />
        </IconButton>
      </Box>
    </Box>
  );
};

const CounterLoader: FC = () => {
  return (
    <>
      {new Array(4).fill(0).map(() => (
        <Box display="flex" flexDirection="column">
          <Shimmer width="180px" height="12px" />
          <Shimmer width="55px" height="42px" />
          <Shimmer width="65px" height="12px" />
        </Box>
      ))}
    </>
  );
};

export default Counter;
