import { JobStatus } from "./models/jobs/JobData";

export const routes = {
  overview: {
    new: "/overview",
    view: (refId: string) => `/overview/${refId}`,
  },
  dashboard: {
    view: "/dashboard",
    quotes: (filter?: string) =>
      filter ? `/dashboard/quotes/${filter}` : "/dashboard/quotes",
    submitQuote: (quoteId: string) =>
      `/dashboard/quotes/submitQuote/${quoteId}`,
    jobs: (status?: JobStatus[]) => {
      let queryParams = "";
      if (status && status.length) {
        status.forEach((s) => {
          queryParams =
            queryParams + (queryParams === "" ? `status=${s}` : `&status=${s}`);
        });
      }
      return queryParams !== ""
        ? `/dashboard/jobs?${queryParams}`
        : "/dashboard/jobs";
    },
    viewJob: (jobId: string) => `/dashboard/jobs/${jobId}`,
    viewVariationJob: (jobId: string, variationJobId: string) =>
      `/dashboard/jobs/${jobId}/variation/${variationJobId}`,
    settings: {
      billing: "/dashboard/settings/billing",
      profile: "/dashboard/settings/profile",
      account: "/dashboard/settings/account",
      addBranch: "/dashboard/settings/account/addBranch",
    },
    teamBase: "/team",
    team: {
      agents: "/dashboard/team/agents",
      branches: "/dashboard/team/branches",
      people: "/dashboard/team/people",
    },
    editPeople: `/dashboard/team/people/edit/:id`,
    peopleEdit: (id: string) => `/dashboard/team/people/edit/${id}`,
  },

  success: {
    new: "/success",
  },
  error: {
    new: "/404",
    linkExpired: () => "/linkExpired",
    view: () => "/page-not-found",
  },
  properties: {
    view: (agencyCode: string, branchCode?: string) => {
      if (branchCode && branchCode !== "undefined") {
        return `/properties/${agencyCode}/${branchCode}`;
      } else {
        return `/properties/${agencyCode}`;
      }
    },
    generic: "/properties*",
  },
  login: {
    view: (agencyCode: string) => `/${agencyCode}/login`,
    viewGeneric: () => `/login`,
    impersonation: `/login/impersonate`,
  },
  registration: {
    view: "/create-account",
  },
  forgotPassword: "/forgot-password",

  resetPassword: `/reset-password`,

  signUp: {
    view: (agencyCode: string) => `/${agencyCode}/signUp`,
    viewGeneric: () => `/signUp`,
  },
  registrationForm: {
    viewGeneric: () => `/registration`,
  },
  addServiceableRegion: {
    view: (officeId: string) => `/addServiceableRegions/${officeId}`,
  },
  listBranches: {
    view: (tradeId: string) => `/listBranches/${tradeId}`,
  },
  listUsers: {
    view: `/listUsers`,
  },
  inviteUser: {
    view: `/inviteUser`,
  },
  applications: {
    view: "/applications",
  },
  registrationSuccess: {
    new: "/verification-email-sent",
  },
  registrationConfirmation: {
    new: "/register-confirmation",
  },
  resetPasswordConfirmation: {
    new: "/reset-password-confirmation",
  },
  addTrades: {
    view: "/addTrades",
  },
  addTradesDocuments: {
    view: "/addTradesDocuments",
  },
  addPaymentMethods: {
    view: "/addPaymentMethods",
  },
  addUserDetails: {
    view: "/userDetails",
  },
  confirmRegistration: {
    view: "/confirmRegistration",
  },
  maintenanceImage: "/maintenance-request/:maintenanceId/images/:key",
};
