import React, { FC } from "react";
import {
  Paper,
  Box,
  Typography,
  Divider,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { availabilitySectionStyles } from "./AvailabilitySectionStyles.data";
import { LABELS } from "./AvailabilitySectionConstants.data";
import { ListItemBoxProps } from "../../quotes/submitQuote/SubmitQuoteComponentConstants.data";
import RequiredFieldAsterisk from "../../../requiredFieldAsterisk/RequiredFieldAsterisk";
import { DatePicker } from "../../../FormikComponents/RenderDatePicker";

interface AvailabilitySectionProps {
  oneDayTask: boolean;
  formikProps: any;
}

const AvailabilitySection: FC<AvailabilitySectionProps> = ({
  oneDayTask,
  formikProps,
}) => {
  // hooks
  const classes = availabilitySectionStyles();
  const { values, setFieldValue, errors } = formikProps;

  return (
    <Box mt={2}>
      <Paper variant="outlined">
        <Box p={2} pb={1} pr={0}>
          <Typography className={classes.formHeading}>
            {LABELS.heading}
          </Typography>
          <Divider color="disabled" />

          {/* Start date */}
          <Box pt={2} pb={1} {...ListItemBoxProps} alignItems="center">
            <Typography variant="h4">
              {LABELS.startDate}
              <RequiredFieldAsterisk />
            </Typography>
            <Box pr={1}>
              <Box className={classes.dateFieldWrapper}>
                <DatePicker
                  error={errors.startDate}
                  disablePast
                  name="startDate"
                  value={values.startDate}
                  diasbleFormControlMargins
                  label={""}
                  onChange={(value) => {
                    setFieldValue("startDate", value);
                    if (values.sameDay) {
                      setFieldValue("endDate", value);
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* End Date */}
          {/* Show end date field only if it's not a one day job */}
          {!oneDayTask && (
            <Box pt={2} pb={1} {...ListItemBoxProps} alignItems="center">
              <Typography variant="h4">
                {LABELS.expectedCompletion}
                {!oneDayTask && <RequiredFieldAsterisk />}
              </Typography>

              <Box pr={1}>
                <Box className={classes.dateFieldWrapper}>
                  <DatePicker
                    error={errors.startDate}
                    diasbleFormControlMargins
                    disabled={oneDayTask}
                    label={""}
                    onChange={(value) => {
                      setFieldValue("endDate", value);
                    }}
                    value={values.endDate}
                    name="endDate"
                  />
                </Box>
              </Box>
            </Box>
          )}

          {/* Same day/ multi day radio buttons */}
          <Box mt={2}>
            <RadioGroup
              name="sameDay"
              row
              value={oneDayTask ? "one" : "multi"}
              onChange={({ target: { value } }) => {
                // For one day task end date is same as start date
                if (value === "one") {
                  setFieldValue("endDate", values.startDate);
                }

                // If one day was selected earlier and user has clicked on multi-day job, show blank end date field
                if (value !== "one" && oneDayTask) {
                  setFieldValue("endDate", null);
                }
                setFieldValue("sameDay", value === "one");
              }}>
              <FormControlLabel
                value={"one"}
                checked={oneDayTask}
                control={<Radio color="primary" />}
                label={<Typography variant="h4">{LABELS.oneDayJob}</Typography>}
              />
              <FormControlLabel
                value={"multi"}
                checked={!oneDayTask}
                control={<Radio color="primary" />}
                label={
                  <Typography variant="h4">{LABELS.multiDayJob}</Typography>
                }
              />
            </RadioGroup>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AvailabilitySection;
