import * as React from "react";
import { withStyles } from "@material-ui/core";
import * as loginBackground from "../../assets/images/img-bg-loginblur.jpg";

export interface LoginBackgroundProps {
  readonly classes: LoginBackgroundStyles;
  children: JSX.Element;
}

export interface LoginBackgroundStyles {
  readonly root: any;
  readonly sortedLogo: any;
}

const styles: LoginBackgroundStyles = {
  root: {
    backgroundImage: `url(${loginBackground})`,
    width: "100%",
    height: "calc(100vh - 60px)",
    backgroundSize: "cover",
    position: "relative",
    padding: "2em 0 1em 0",
    backgroundOrigin: "padding-box",
    "@media only screen and (max-width: 600px)": {
      padding: "0px",
    },
    overflow: "auto"
  },
  sortedLogo: {
    position: "fixed",
    bottom: 5,
    left: "5px",
  },
};

type OwnProps = LoginBackgroundProps;

class LoginBackground extends React.Component<OwnProps, {}> {
  public render() {
    return <div className={this.props.classes.root}>{this.props.children}</div>;
  }
}

export default withStyles(styles)(LoginBackground);
