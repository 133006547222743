import {
  GetTradeUsersSuccessPayload,
  GetTradeUsersRequestPayload,
  GetTradeUsersErrorPayload,
  PostTradeUserRequestPayload,
  PostTradeUserErrorPayload,
  PostTradeUserSuccessPayload,
} from "../../services/tradeUsers/TradeUsers.data";
import { StoreAction } from "../StoreHelper";

//  Get Trade Users
export enum GetTradeUsersActionType {
  GetTradeUsersRequest = "GET_TRADE_USERS_REQUEST",
  GetTradeUsersSuccess = "GET_TRADE_USERS_SUCCESS",
  GetTradeUsersError = "GET_TRADE_USERS_ERROR",
}

export type GetTradeUsersActionPayload =
  | GetTradeUsersSuccessPayload
  | GetTradeUsersRequestPayload
  | GetTradeUsersErrorPayload;

export type GetTradeUsersAction = StoreAction<
  GetTradeUsersActionType,
  GetTradeUsersActionPayload
>;

export class GetTradeUsersActions {
  public static getTradeUsersRequest(data: GetTradeUsersRequestPayload) {
    return {
      type: GetTradeUsersActionType.GetTradeUsersRequest,
      data,
    };
  }

  public static getTradeUsersSuccess(data: GetTradeUsersSuccessPayload) {
    return {
      type: GetTradeUsersActionType.GetTradeUsersSuccess,
      data,
    };
  }

  public static getTradeUsersError(data: GetTradeUsersErrorPayload) {
    return { type: GetTradeUsersActionType.GetTradeUsersError, data };
  }
}

// Post/Add Trade Users
export enum PostTradeUserActionType {
  PostTradeUserRequest = "POST_TRADE_USER_REQUEST",
  PostTradeUserSuccess = "POST_TRADE_USER_SUCCESS",
  PostTradeUserError = "POST_TRADE_USER_ERROR",
}

export type PostTradeUserActionPayload =
  | PostTradeUserSuccessPayload
  | PostTradeUserRequestPayload
  | PostTradeUserErrorPayload;

export type PostTradeUserAction = StoreAction<
  PostTradeUserActionType,
  PostTradeUserActionPayload
>;

export class PostTradeUserActions {
  public static postTradeUserRequest(data: PostTradeUserRequestPayload) {
    return {
      type: PostTradeUserActionType.PostTradeUserRequest,
      data,
    };
  }

  public static postTradeUserSuccess(data: PostTradeUserSuccessPayload) {
    return {
      type: PostTradeUserActionType.PostTradeUserSuccess,
      data,
    };
  }

  public static postTradeUserError(data: PostTradeUserErrorPayload) {
    return { type: PostTradeUserActionType.PostTradeUserError, data };
  }
}
