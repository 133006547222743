import { makeStyles } from "@material-ui/core";

export const ContactCardsStyles = makeStyles({
  listItemText: {
    wordBreak: "break-word",
  },
  disableSidePadding: {
    paddingRight: 0,
    paddingLeft: 0,
  },
});
