import React, { FC } from "react";
import { Paper, Box } from "@material-ui/core";
import DashboardSectionHeading from "../dashBoardSectionHeading/DashboardSectionHeading";
import DashboardSectionInnerHeading from "../dashboardSectionInnerHeading/DashboardSectionInnerHeading";
import { DashboardSectionStyles } from "./DashbaordSectionStyles.data";

interface Props {
  borderColor?: string;
  heading?: string;
  innerHeading?: string | React.ReactNode;
  sectionTopMargin?: boolean;
  disableSidePadding?: boolean;
  paperClass?: any;
}

const DashboardSection: FC<Props> = (props) => {
  const classes = DashboardSectionStyles();
  const {
    children,
    borderColor,
    heading,
    innerHeading,
    sectionTopMargin,
    disableSidePadding,
    paperClass,
  } = props;
  return (
    <Box mt={sectionTopMargin ? 4 : 0}>
      {heading && <DashboardSectionHeading text={heading} />}
      <Paper
        variant="outlined"
        style={borderColor ? { borderLeft: `0.5rem solid ${borderColor}` } : {}}
        classes={{
          root: disableSidePadding
            ? classes.disableSidePaddingRoot
            : classes.root,
        }}
        className={paperClass ? paperClass : ""}>
        {innerHeading &&
          (typeof innerHeading == "string" ? (
            <DashboardSectionInnerHeading text={innerHeading} />
          ) : (
            innerHeading
          ))}
        {children}
      </Paper>
    </Box>
  );
};

export default DashboardSection;
