import React, { FC, useState, useEffect, useMemo } from "react";
import JobsComponent from "../../component/dashboard/jobs/JobsComponent";
import queryString from "query-string";
import { GetUserResponse } from "../../services/login/LoginData";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/RootReducers";
import { JobStatus } from "../../models/jobs/JobData";
import { JobFiltersData } from "./JobsFilters.data";
import { JobsActions } from "../../store/actions/JobsActions";
import { Job } from "../../services/jobs/JobsData.data";
import { paginationDefaultPageSize } from "../../constants/AppConstants";
import { LoadingIndicator } from "../loadingIndicator/LoadingIndicator";
import { routes } from "../../Routes";
import { useHistory, useLocation } from "react-router";

interface Props {
  userDetails: GetUserResponse;
}

const Jobs: FC<Props> = (props) => {
  // state
  const [statusFilter, setStatusFilter] = useState<JobStatus[]>([]);

  const location = useLocation();
  const { status: queryStatus } = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );

  const history = useHistory();

  const { jobsData, loading } = useAppSelector((state) => ({
    jobsData: state.jobs.jobsData,
    loading: state.jobs.loading,
  }));
  const [sidebarJob, setSidebarJob] = useState<Job | null>(null);

  // dispatcher
  const dispatch = useDispatch();
  const dispatcher = {
    getJobs: (filtersData: JobFiltersData, hideLoader?: boolean) => {
      const {
        filters,
        page = 0,
        size = paginationDefaultPageSize,
        sort = "createdDate,desc",
      } = filtersData;
      dispatch(
        JobsActions.GetJobs({
          STATUS: filters.status,
          ADDRESS_SEARCH: filters.addressSearch
            ? [filters.addressSearch]
            : undefined,
          paginationFilters: { page, size, sort },
          hideLoader,
        })
      );
    },
    clearViewJobData: () => {
      dispatch(JobsActions.ClearViewJobData());
    },
  };

  // effects
  useEffect(() => {
    dispatcher.clearViewJobData();
  }, []);

  // effects
  useEffect(() => {
    dispatcher.getJobs(
      {
        filters: {
          status: queryStatus
            ? Array.isArray(queryStatus)
              ? (queryStatus as JobStatus[])
              : [queryStatus as JobStatus]
            : [JobStatus.BookingRequired],
        },
      },
      true
    );
    setStatusFilter(
      queryStatus
        ? Array.isArray(queryStatus)
          ? (queryStatus as JobStatus[])
          : [queryStatus as JobStatus]
        : [JobStatus.BookingRequired]
    );
  }, [queryStatus]);

  const onFilterChange = (status: JobStatus[]) => {
    history.push(routes.dashboard.jobs(status));
  };

  const onAddressSearch = (address: string) => {
    dispatcher.getJobs(
      {
        filters: { status: statusFilter, addressSearch: address },
      },
      true //hides overlay loader
    );
    setSidebarJob(null);
  };

  const onPageChange = (page: number) => {
    setSidebarJob(null);
    dispatcher.getJobs({
      filters: { status: statusFilter },
      page,
    });
  };

  const onPageSizeChange = (newPageSize: number) => {
    setSidebarJob(null);
    dispatcher.getJobs({
      filters: { status: statusFilter },
      size: newPageSize,
    });
  };

  const { userDetails } = props;
  return jobsData && statusFilter ? (
    <JobsComponent
      loading={loading}
      propertyList={jobsData.properties}
      userDetails={userDetails}
      jobsResponse={jobsData.paginatedJobResponse}
      onFilterChange={onFilterChange}
      jobStatus={statusFilter}
      setSidebarJob={setSidebarJob}
      sidebarJob={sidebarJob}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onAddressSearch={onAddressSearch}
      jobStatusCount={jobsData.jobStatusCount}
    />
  ) : (
    <LoadingIndicator forceShow />
  );
};

export default Jobs;
