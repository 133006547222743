import React, { FC, useContext, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  Cancel,
  Add,
  PlayArrow,
  PictureAsPdfOutlined,
} from "@material-ui/icons";
import { ImageViewerContext } from "../../App";
import { useStyles } from "./DocGridStyles";
import { get } from "lodash";
import { FileTypes } from "../../constants/AppConstants";
import { useDispatch } from "react-redux";
import { VideoPlayerActions } from "../../store/actions/VideoPlayerActions";
import { NamedFile } from "../../models/file/NamedFile";
import { S3File } from "../../models/file/S3File";
import { color } from "../../theme/Theme";
import { getDocSrc, getFileType } from "../../helper/DownloadHelpers";
import { FileInfo } from "./DocGridConstants";

export type DocsSourceArray = Array<S3File | NamedFile>;

interface DocGridProps {
  remove?: any;
  docs: DocsSourceArray;
  onAddImage?: () => void;
  onFileRename?: (index: number, updatedName: string) => void;
}

const DocGrid: FC<DocGridProps> = ({
  remove,
  docs,
  onAddImage,
  onFileRename,
}) => {
  // hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const { setImages } = useContext(ImageViewerContext);

  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [renameText, setRenameText] = useState<string>("");

  // variables and functions
  const docArray: FileInfo[] = docs.map((doc) => {
    const src = getDocSrc(doc)!;
    const contentType =
      get(doc, "type") ||
      get(doc, "contentType") ||
      get((doc as NamedFile).file, "type");
    const name =
      typeof doc === "string"
        ? "image"
        : (doc as S3File).name ||
          (doc as NamedFile).formDataName ||
          (doc as NamedFile).file.name;
    const extension = name.split(".").pop()!;
    return {
      type: getFileType(contentType)!,
      src,
      downloadUrl: src,
      alt: name,
      name: name.split(".").slice(0, -1).join("."),
      extension,
    };
  });

  const dispatcher = {
    showVideoPlayer: (src: string) =>
      dispatch(VideoPlayerActions.show({ src })),
    hideVideoPlayer: () => dispatch(VideoPlayerActions.hide()),
  };

  return (
    <Box className={classes.container}>
      {docArray.map((doc, index) => (
        <Box className={classes.gridTileRoot}>
          {doc.type === FileTypes.Image && (
            <img
              alt="artefact"
              onClick={() => setImages(docArray, index)}
              className={`${classes.image} ${
                remove ? classes.zoomInCursor : classes.pointerCursor
              }`}
              src={doc.src}
            />
          )}
          {doc.type === FileTypes.Video && (
            <div
              onClick={() => dispatcher.showVideoPlayer(doc.src)}
              className={`${classes.image} ${classes.videoItem}`}>
              <PlayArrow />
            </div>
          )}
          {doc.type === FileTypes.Pdf && (
            <Box fontSize="80px" color={color.warning}>
              <PictureAsPdfOutlined color="inherit" fontSize="inherit" />
            </Box>
          )}
          {remove && (
            <Box className={`${classes.removeBar}`}>
              <Cancel
                style={{ cursor: "pointer" }}
                onClick={() => remove(docs[index])}
              />
            </Box>
          )}
          {onFileRename && editingIndex === index ? (
            <input
              type="text"
              onChange={(e) => {
                setRenameText(e.target.value);
              }}
              onBlur={() => {
                onFileRename(editingIndex, `${renameText}.${doc.extension}`);
                setEditingIndex(null);
                setRenameText("");
              }}
              value={renameText}
              autoFocus
              style={{ width: "100%" }}
            />
          ) : (
            <Typography
              className={classes.docName}
              variant="body2"
              onClick={(e) => {
                // Allow rename if not a s3 file (cannot rename already uploaded ones)
                if (!(docs[index] as S3File).id) {
                  setEditingIndex(index);
                  setRenameText(doc.name);
                }
              }}>
              {doc.name}
            </Typography>
          )}
        </Box>
      ))}
      {onAddImage && (
        <Box
          className={`${classes.gridTileRoot} ${classes.addImageTileContent} ${classes.addImage}`}
          onClick={onAddImage}>
          <Add color="disabled" />
        </Box>
      )}
    </Box>
  );
};

export default DocGrid;
