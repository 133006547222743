import React, { FC } from "react";
import {
  Dialog,
  Typography,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Formik, Field } from "formik";
import { AddUserDocFormData } from "./AddUserDocForm.data";
import RenderInput from "../../../../FormikComponents/RenderInputField";
import {
  inputStylePropsGenerator,
  RenderInputFieldStyles,
} from "../../../../FormikComponents/RenderInputFieldStyle";
import { LABELS, VALIDATION_SCHEMA } from "./AddUserDocConstants.data";
import RenderDatePicker from "../../../../FormikComponents/RenderDatePicker";
import { InsertDriveFileOutlined, Clear } from "@material-ui/icons";
import {
  UserDocType,
  userDocTypesOptions,
} from "../../../../../models/userDocument/UserDocumentTypes.data";
import RenderSelect from "../../../../FormikComponents/RenderSelect";
import { AddUserDocStyles } from "./AddUserDocStyles.data";

interface Props {
  fileName: string | undefined;
  closeModal: () => void;
  onSubmit: (values: AddUserDocFormData) => void;
  open: boolean;
}

const initialValues: AddUserDocFormData = {
  docName: "",
  tracker: false,
  expiryDate: null,
  type: UserDocType.PoliceCheck,
};

const AddDocumentDialog = withStyles({
  paper: {
    margin: 0,
  },
  paperWidthFalse: {
    maxWidth: "100%",
  },
})(Dialog);

const AddUserDocModal: FC<Props> = (props) => {
  const renderInputProps = inputStylePropsGenerator(RenderInputFieldStyles());
  const classes = AddUserDocStyles();
  const { closeModal, onSubmit, open, fileName } = props;
  const onClose = () => {
    closeModal();
  };
  return (
    <AddDocumentDialog maxWidth={false} open={open}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={VALIDATION_SCHEMA}>
        {({ handleSubmit, values, isValid, dirty }) => (
          <div className={classes.wrapper}>
            <section className={classes.modalHeader}>
              <Typography className={classes.heading} variant="h5">
                {LABELS.addDocument}
              </Typography>
              <div className={classes.closeIconContainer}>
                <Clear onClick={onClose} />
              </div>
            </section>
            <section className={classes.fieldsWrapper}>
              <ListItem className={classes.spaceTop} divider>
                <ListItemIcon>
                  <InsertDriveFileOutlined />
                </ListItemIcon>
                <ListItemText primary={fileName} />
              </ListItem>
              <Field name="docName">
                {({ field }) => (
                  <RenderInput
                    containerClass={classes.spaceTop}
                    label={LABELS.documentName}
                    {...renderInputProps}
                    {...field}
                  />
                )}
              </Field>
              <Field name="type">
                {({ field }) => (
                  <RenderSelect
                    containerClass={classes.spaceTop}
                    label={LABELS.documentType}
                    options={userDocTypesOptions}
                    {...renderInputProps}
                    {...field}
                  />
                )}
              </Field>
              <Field name="expiryDate">
                {({ form, field }) => (
                  <RenderDatePicker
                    disablePast
                    containerClass={classes.spaceTop}
                    label={LABELS.expiryDate}
                    {...renderInputProps}
                    {...field}
                    onChange={(value) =>
                      form.setFieldValue("expiryDate", value)
                    }
                    disabled={!values.tracker}
                  />
                )}
              </Field>
              <Field type="checkbox" name="tracker" value={false}>
                {({ form }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e) =>
                          form.setFieldValue("tracker", e.currentTarget.checked)
                        }
                        name="tracker"
                      />
                    }
                    label={
                      <Typography variant="h4">
                        {LABELS.trackedDocument}
                      </Typography>
                    }
                  />
                )}
              </Field>
            </section>
            <section className={classes.buttonsSection}>
              <Button
                color="secondary"
                onClick={onClose}
                className={classes.navButton}>
                {LABELS.cancel}
              </Button>
              <Button
                disabled={!isValid || !dirty}
                color="primary"
                onClick={() => handleSubmit()}
                className={classes.navButton}>
                {LABELS.save}
              </Button>
            </section>
          </div>
        )}
      </Formik>
    </AddDocumentDialog>
  );
};

export default AddUserDocModal;
