import React, { FC, useEffect } from "react";
import { GetUserResponse } from "../../services/login/LoginData";
import { useAppSelector } from "../../store/RootReducers";
import { useDispatch } from "react-redux";
import { TradeCategoriesActions } from "../../store/actions/TradeCategoriesActions";
import ProfileSettingsComponent from "../../component/dashboard/settings/profile/ProfileSettingsComponent";
import { UserDetailsFormData } from "../../component/userDetails/UserDetailsData.data";
import { LoginActions } from "../../store/actions/LoginActions";
import { userDetailsUpdateFormConverter } from "../userDetails/UserDetailsUpdateFormConverter";
import {
  UserTradeDocumentsUploadData,
  DeleteUserTradeCategoryDocumentRequest,
} from "../../services/userTrades/UserTradeService.data";
import { AddDocData } from "../addTradesDocuments/AddTradesDocumentsData.data";
import { AddTradeDocumentsFormConverter } from "../addTradesDocuments/AddTradesDocumentsFormConverter";
import { PostUserDocumentData } from "../../services/userDocumentsService/UserDocuments.data";
import { UserDocumentsActions } from "../../store/actions/UserDocumentsActions";
import { AddUserDocData } from "../../component/dashboard/settings/profile/userDocuments/UserDocumentsData.data";
import { TradeCategory } from "../../services/tradeCategories/TradeCategories.data";
import { UpdateTeamMemberActions } from "../../store/actions/UpdateTeamMembersAction";
import { UpdatePasswordPayload } from "src/services/user/UserData";
import { ChangePasswordFormFields } from "src/component/dashboard/settings/profile/changePasswordForm/ChangePasswordFormData.data";

interface ProfileSettingsProps {
  userDetails: GetUserResponse;
  updatingTeamMembers?: boolean
}

const ProfileSettings: FC<ProfileSettingsProps> = ({ userDetails, updatingTeamMembers }) => {

  // Values from store
  const { userTradeCategoriesDetails, userDocuments, tradeCategories } = useAppSelector(
    (state) => ({
      userTradeCategoriesDetails:
        state.tradeCategories.userTradeCategoriesDetails,
      userDocuments:
        state.login.userDetails && state.login.userDetails.userDocuments,
        tradeCategories: state.tradeCategories.tradeCategories,
    })
  );

  // dispatcher
  const dispatch = useDispatch();
  const dispatcher = {
    getUserTradeCategoriesDetails: () =>
      dispatch(TradeCategoriesActions.getUserTradeCategoriesRequest()),

    putUserDetails: (details: UserDetailsFormData) =>
      dispatch(
        LoginActions.putUserDetailsStart({
          request: userDetailsUpdateFormConverter(details, userDetails!),
        })
      ),
    
    updateTeamMemberDetails: (details: UserDetailsFormData) => 
      dispatch(
        UpdateTeamMemberActions.putTeamMemberDetailsStart({
          request: userDetailsUpdateFormConverter(details, userDetails!),
        })
      ),
    
    postUserTradeDocuments: (data: UserTradeDocumentsUploadData) =>
      dispatch(TradeCategoriesActions.postUserTradeDocumentRequest(data)),

    deleteUserTradeCategory: (tradeCategoryId: number) =>
      dispatch(
        TradeCategoriesActions.deleteUserTradeCategoryRequest({
          request: {
            tradeCategoryId,
          },
        })
      ),
    
    updateUserPassword: (data: UpdatePasswordPayload) =>
      dispatch(LoginActions.updatePasswordStart(data)),

    postUserDocument: (uploadDocumentData: PostUserDocumentData) =>
      dispatch(
        UserDocumentsActions.PostUserDocumentRequest(uploadDocumentData)
      ),

    deleteUserDocument: (userDocumentId: number) => 
      dispatch(
        UserDocumentsActions.DeleteUserDocumentRequest({ userDocumentId })),
    deleteUserTradeCategoryDocument: (
      data: DeleteUserTradeCategoryDocumentRequest
    ) =>
      dispatch(
        TradeCategoriesActions.deleteUserTradeCategoryDocumentRequest(data)
      ),

    getTradeCategories: () =>
      dispatch(TradeCategoriesActions.getTradeCategoriesRequest()),

    postTradeCategories: (selectedTradeCategories: TradeCategory[]) => {
      const request = selectedTradeCategories.map((trade) => ({
        tradeCategoryId: trade.id,
      }));
      dispatch(
        TradeCategoriesActions.postUserTradeCategoriesRequest({ request })
      );
    },
  };

  // Effects
  useEffect(() => {
    if (!userTradeCategoriesDetails) {
      dispatcher.getUserTradeCategoriesDetails();
    }
    if (!tradeCategories) {
      dispatcher.getTradeCategories();
    }
  }, []);

  const onPersonalInfoSubmit = (values: UserDetailsFormData) => {
    updatingTeamMembers ? dispatcher.updateTeamMemberDetails(values) : dispatcher.putUserDetails(values);
  };

  const onAddTradeCategory = (selectedTradeCategories: TradeCategory[]) => {
    dispatcher.postTradeCategories(selectedTradeCategories);
  };

  const onAddTradeCategoryDocument = (values: AddDocData) => {
    const tradeDocsRequestData = AddTradeDocumentsFormConverter(values);
    dispatcher.postUserTradeDocuments(tradeDocsRequestData);
  };

  const onRemoveUserTradeCategoryDocument = (request: DeleteUserTradeCategoryDocumentRequest) => {
    dispatcher.deleteUserTradeCategoryDocument(request)
  }

  const onRemoveTradeCategory = (tradeCategoryId: number) => {
    dispatcher.deleteUserTradeCategory(tradeCategoryId);
  };

  const onChangePasswordSubmit = (values: ChangePasswordFormFields) => {
    const { newPassword } = values;
    const request: UpdatePasswordPayload = {
      password: newPassword
    }
    dispatcher.updateUserPassword(request)
  }

  const onRemoveUserDocument = (userDocumentId: number) => {
    dispatcher.deleteUserDocument(userDocumentId)
  }

  const onUserDocSubmit = (uploadDocData: AddUserDocData) => {
    const { docName, file, expiryDate, type } = uploadDocData;

    const requestData: PostUserDocumentData = {
      file: file,
      data: {
        expiryDate: expiryDate ? expiryDate.format("DD/MM/YYYY") : null,
        name: docName,
        type,
      },
    };

    dispatcher.postUserDocument(requestData);
  };

  return userTradeCategoriesDetails && tradeCategories ? (
    <ProfileSettingsComponent
      onPersonalInfoSubmit={onPersonalInfoSubmit}
      onChangePasswordSubmit={onChangePasswordSubmit}
      onAddTradeCategory={onAddTradeCategory}
      onRemoveTradeCategory={onRemoveTradeCategory}
      onRemoveUserTradeCategoryDocument={onRemoveUserTradeCategoryDocument}
      onAddTradeCategoryDocument={onAddTradeCategoryDocument}
      onUserDocSubmit={onUserDocSubmit}
      userDetails={userDetails}
      userDocuments={userDocuments!}
      tradeCategories={tradeCategories}
      tradeCategoriesDetails={userTradeCategoriesDetails}
      onRemoveUserDocument={onRemoveUserDocument}
      updatingTeamMembers={updatingTeamMembers || false}
    />
  ) : null;
};

export default ProfileSettings;
