import { makeStyles } from "@material-ui/core";
import { color } from "../../../theme/Theme";

export const overviewStyles = makeStyles({
  scheduledJobsListContainer: {
    maxHeight: "416px",
    display: "flex",
    flexDirection: "column",
  },
  map: {
    width: "100%",
  },
  scheduledJobsList: {
    flexGrow: 1,
    overflow: "auto",
  },
  seeAllContainer: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${color.lightGrey}`,
  },
  overviewListSection: {
    height: "400px",
    overflow: "auto",
  },
  centeredBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  viewJob: {
    display: "none",
  },
  viewJobListItem: {
    "&:hover": {
      "& > .view-job": {
        display: "block",
      },
    },
  },
});
