import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../../theme/Theme";

export const TradeDocsStyles = makeStyles({
  tradeCategoryDocsContainer: {
    marginTop: "1.5em",
    padding: "0em 1em",
  },
  fixedListItems: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  pointer: {
    cursor: "pointer",
  },
  detailsContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap"
  },
  documentName: {
    flexBasis: "70%",
  },
  listItemDivider: {
    borderBottom: `1px solid ${color.lightGrey} !important`,
    backgroundClip: "padding-box",
  },
  listItemSecondaryAction: {
    display: "none",
  },
  listItem: {
    "&:hover $listItemSecondaryAction": {
      display: "block",
    },
    "&:hover": {
      background: color.wildSand,
      cursor: "pointer",
    },
  },
});
