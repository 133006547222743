export const LABELS = {
  expiryDate: "Expiry Date",
  expiring: "Expiring (In)",
  addDocuments: "Add new document",
  cancel: "Cancel",
  delete: "Delete",
  deleteDocument: "Delete Document",
  deleteDocumentDescription:
    "Are you sure you want to delete this document and remove from user profile?",
};
