import { makeStyles } from "@material-ui/core/styles";
import theme, { color } from "../../theme/Theme";

export const TopNavStyles = makeStyles({
  navSection: {
    background: color.wildSand,
    height: "10vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      background: color.primary,
      justifyContent: 'center',
      height: '4.5em'
    },
  },
  navWrapper: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  navLinks: {
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      textTransform: "capitalize",
      fontSize: "1.5em",
      color: color.light,
      opacity: ".5",
    },
  },
  navLinkActive: {
    color: `${color.light} !important`,
    opacity: 1,
    border: "0 !important",
  },
  profileOptions: {
    paddingBottom: "11px",
    width: "120px",
  },
});
