import React, { FC } from "react";
import { Formik, Field } from "formik";
import {
  CONFIRM_BOOKING_FORM_VALIDATION,
  LABELS,
  BOOKING_TIME_OPTIONS,
  ConfirmJobBookingFormValues,
} from "../ViewJobComponentConstants.data";
import { isEmpty } from "lodash";
import { Box, Divider, Typography, Button } from "@material-ui/core";
import StyledExpansionPanel from "../../../../styledExpansionPanel/StyledExpansionPanel";
import { ListItemBoxProps } from "../../../quotes/submitQuote/SubmitQuoteComponentConstants.data";
import RequiredFieldAsterisk from "../../../../requiredFieldAsterisk/RequiredFieldAsterisk";
import moment, { Moment } from "moment";
import DateFnsUtils from "@date-io/moment";
import { MOMENT_DATE_FORMATS } from "../../../../../constants/AppConstants";
import RenderDatePicker from "../../../../FormikComponents/RenderDatePicker";
import DashboardListItem from "../../../../lisItems/DashboardListItem";
import { titleCase } from "../../../../../helper/HelperFunctions";
import { Clear } from "@material-ui/icons";
import { JobStatus } from "../../../../../models/jobs/JobData";
import { KeyDates } from "../../../../../services/jobs/JobsData.data";
import { ConfirmJobBookingStyles } from "./ConfirmJobBookingStyles.data";
import { WorkOrder } from "../../../../../models/workOrder/Workorder.data";
import { User } from "../../../../../models/user/User.data";
import RenderSelect from "../../../../FormikComponents/RenderSelect";
import { QuoteAvailability } from "../../../../../models/quote/Quote.data";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

interface ConfirmBookingFormProps {
  jobStatus: JobStatus;
  readonlyForm: boolean;
  keyDates: KeyDates | null;
  jobAssignedUserId: number[];
  confirmJobBooking: (
    formValues: ConfirmJobBookingFormValues,
    id: string
  ) => void;
  createdDate: string;
  workOrder: WorkOrder;
  users: User[];
  id: string;
  quoteAvailability: QuoteAvailability;
  hideForm?: () => void;
}

const ConfirmBookingForm: FC<ConfirmBookingFormProps> = ({
  jobStatus,
  readonlyForm,
  keyDates,
  jobAssignedUserId,
  confirmJobBooking,
  createdDate,
  users,
  workOrder,
  id,
  quoteAvailability,
  hideForm,
}) => {
  //Hooks
  const classes = ConfirmJobBookingStyles();

  // Confirm booking form initial values
  interface FormValues extends ConfirmJobBookingFormValues {
    endTimeTouched: boolean; // to check if end time has been manually changed
  }
  let confirmBookingFormInitialValues: FormValues;

  if (jobStatus === JobStatus.BookingRequired) {
    confirmBookingFormInitialValues = {
      date: null,
      time: "",
      assignedUserId: users.length === 1 ? [users[0].id] : [],
      startTime: moment().set({ h: 8, m: 0 }),
      endTime: moment().set({ h: 10, m: 0 }),
      endTimeTouched: false,
    };
  } else {
    const bookingTime = BOOKING_TIME_OPTIONS.find(
      (bt) => bt.timeOfDay === keyDates!.timeSlot.timeOfDay
    )!;
    const { startDate, expectedCompletion } = keyDates!;

    confirmBookingFormInitialValues = {
      date: moment(startDate, MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM),
      assignedUserId: jobAssignedUserId,
      time: bookingTime || "Morning",
      startTime: moment(startDate, MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM),
      endTime: moment(expectedCompletion, MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM),
      endTimeTouched: false,
    };
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        initialValues={confirmBookingFormInitialValues}
        onSubmit={({ endTimeTouched, ...values }) =>
          confirmJobBooking(values as ConfirmJobBookingFormValues, id)
        }
        validationSchema={CONFIRM_BOOKING_FORM_VALIDATION}
        enableReinitialize>
        {/* tslint:disable-next-line:cyclomatic-complexity */}
        {({
          setFieldValue,
          values,
          handleSubmit,
          isSubmitting,
          errors,
          dirty,
        }) => {
          const disabled = isSubmitting || !isEmpty(errors) || !dirty;

          return (
            <>
              {/* Booking details */}
              <Box className={classes.expansionSection}>
                <StyledExpansionPanel
                  defaultExpanded={true}
                  heading={LABELS.bookingDetails}>
                  <Box pl={2}>
                    <Box pt={1}>
                      <Box mt={3} pb={2}>
                        <Typography variant="subtitle2">
                          {LABELS.quotedDates}
                        </Typography>
                      </Box>
                      <Divider />

                      {/* start date */}
                      <Box
                        pt={2}
                        pb={1}
                        {...ListItemBoxProps}
                        alignItems="center">
                        <Typography variant="h4">{LABELS.startDate}</Typography>
                        <Box pr={1}>
                          <Box pr={2}>
                            <Typography variant="h4">
                              {moment(quoteAvailability.startDate).format(
                                MOMENT_DATE_FORMATS.DO_MMM_YYYY
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      {/* completion date */}
                      <Box
                        pt={2}
                        pb={1}
                        {...ListItemBoxProps}
                        alignItems="center">
                        <Typography variant="h4">
                          {LABELS.completionDate}
                        </Typography>
                        <Box pr={1}>
                          <Box pr={2}>
                            <Typography variant="h4">
                              {moment(
                                quoteAvailability.endDate ||
                                  quoteAvailability.startDate
                              ).format(MOMENT_DATE_FORMATS.DO_MMM_YYYY)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box mt={3} pb={2}>
                        <Typography variant="subtitle2">
                          {LABELS.jobDateAndTime}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        pt={2}
                        pb={1}
                        {...ListItemBoxProps}
                        alignItems="center">
                        <Typography variant="h4">
                          {LABELS.jobDate}
                          {!readonlyForm ? <RequiredFieldAsterisk /> : null}
                        </Typography>
                        <Box pr={1}>
                          {readonlyForm ? (
                            <Box pr={2}>
                              <Typography variant="h4">
                                {moment(createdDate).format(
                                  MOMENT_DATE_FORMATS.DO_MMM_YYYY
                                )}
                              </Typography>
                            </Box>
                          ) : (
                            <Field name="date">
                              {({ form, field }) => (
                                <Box pr={1}>
                                  <RenderDatePicker
                                    filled
                                    diasbleFormControlMargins
                                    label={""}
                                    {...field}
                                    onChange={(value) =>
                                      form.setFieldValue("date", value)
                                    }
                                  />
                                </Box>
                              )}
                            </Field>
                          )}
                        </Box>
                      </Box>
                      <Box
                        pt={2}
                        pb={1}
                        pr={1}
                        {...ListItemBoxProps}
                        alignItems="center">
                        <Typography variant="h4">
                          {LABELS.jobTime}
                          {!readonlyForm ? <RequiredFieldAsterisk /> : null}
                        </Typography>
                        {readonlyForm ? (
                          <Box pr={2}>
                            <Typography variant="h4">
                              {keyDates!.timeSlot.timeOfDay}
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            maxWidth="70%"
                            display="flex"
                            alignItems="center"
                            gridGap="1em">
                            <KeyboardTimePicker
                              variant="inline"
                              value={values.startTime}
                              onChange={(date: Moment) => {
                                setFieldValue("startTime", date);
                                if (
                                  !values.endTimeTouched &&
                                  jobStatus === JobStatus.BookingRequired
                                ) {
                                  const updatedEndTime = moment(date);
                                  setFieldValue(
                                    "endTime",
                                    updatedEndTime.add(2, "hours")
                                  );
                                }
                              }}
                            />

                            <Typography variant="caption">to</Typography>

                            <KeyboardTimePicker
                              variant="inline"
                              value={values.endTime}
                              onChange={(date) => {
                                if (!values.endTimeTouched) {
                                  setFieldValue("endTimeTouched", true);
                                }
                                setFieldValue("endTime", date);
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>

                  {/* Trades person */}
                  <Box mt={2} px={2}>
                    {!readonlyForm && (
                      <Field name="assignedUserId">
                        {({ field }) => (
                          <RenderSelect
                            variant="filled"
                            {...field}
                            disabled={readonlyForm}
                            displayEmpty
                            value=""
                            containerClass={classes.selectContainer}
                            label=""
                            options={[
                              {
                                label: "Add tradesperson",
                                value: "",
                              },
                              ...users.map(
                                ({ firstName, lastName, id: uid }) => ({
                                  label: (
                                    <DashboardListItem
                                      primary={`${firstName} ${lastName}`}
                                      secondary={titleCase(workOrder.category)}
                                    />
                                  ),
                                  value: uid,
                                })
                              ),
                            ]}
                            onChange={({ target: { value } }) => {
                              if (
                                !values.assignedUserId.find(
                                  (assignedUser) => assignedUser === value
                                )
                              ) {
                                setFieldValue("assignedUserId", [
                                  ...values.assignedUserId,
                                  value,
                                ]);
                              }
                            }}
                          />
                        )}
                      </Field>
                    )}
                  </Box>
                  <Box mt={2} px={2}>
                    {values.assignedUserId.map((userId) => {
                      const {
                        firstName,
                        lastName,
                        id: uid,
                      } = users.find((u) => u.id === userId)!;
                      return (
                        <>
                          <Box
                            className={classes.assigneeNameCardWrapper}
                            pr={3}
                            display="flex">
                            <DashboardListItem
                              primary={`${firstName} ${lastName}`}
                              secondary={titleCase(
                                workOrder.category.replaceAll("_", " ")
                              )}
                            />
                            {!readonlyForm && (
                              <Box
                                className={classes.pointer}
                                display="flex"
                                alignItems="center"
                                onClick={() =>
                                  setFieldValue(
                                    "assignedUserId",
                                    values.assignedUserId.filter(
                                      (assignedUserId) => assignedUserId !== uid
                                    )
                                  )
                                }>
                                <Clear />
                              </Box>
                            )}
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </StyledExpansionPanel>
              </Box>

              {/* Confirm Job booking buttons section */}
              {!readonlyForm && (
                <Box className={classes.buttonSection}>
                  {hideForm && (
                    <Button
                      onClick={() => hideForm()}
                      variant="outlined"
                      className={classes.button}>
                      Hide
                    </Button>
                  )}
                  <Button
                    onClick={() => handleSubmit()}
                    className={classes.button}
                    variant={!disabled ? "contained" : "outlined"}
                    disabled={disabled}>
                    {jobStatus === JobStatus.BookingRequired
                      ? LABELS.confirmBooking
                      : LABELS.update}
                  </Button>
                </Box>
              )}
            </>
          );
        }}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

export default ConfirmBookingForm;
