import { makeStyles } from "@material-ui/core";

export const passwordInstructionsStyles = makeStyles({
  list: {
    padding: ".5em 1em",
  },
  listItem: {
    padding: '.5em 0'
  }
});
