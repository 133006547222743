import React, { FC, useEffect } from "react";
import { LABELS } from "./QuoteDetailsConstants.data";
import { Typography, Box, Tooltip } from "@material-ui/core";
import { color } from "../../../theme/Theme";
import { QuoteDetailsStyles } from "./QuoteDetailsStyles.data";
import { SpecificMaterial } from "../../../models/specificMaterial/SpecificMaterial.data";
import { QuoteType } from "../../../models/quote/QuoteType.data";
import { ListItemBoxProps } from "../quotes/submitQuote/SubmitQuoteComponentConstants.data";
import { submitQuoteFormHelpers } from "../../../helper/HelperFunctions";
import { WorkorderType } from "../../../models/workOrder/WorkOrderTypes.data";
import { PreApprovedDetails } from "../../../models/workOrder/Workorder.data";
import { HelpOutlined, SubdirectoryArrowLeft } from "@material-ui/icons";
import { LineAmountType } from "../../../models/payment/PaymentLineAmountType";
import useFetch from "../../../hooks/useFetch";
import serviceFeeService from "../../../services/serviceFee/ServiceFeeService";
import { Quote } from "src/models/quote/Quote.data";

interface Props {
  callOutCost: number;
  labourCost: number;
  materialCost: number;
  specificMaterials: SpecificMaterial[];
  totalCost: number;
  type: QuoteType;
  serviceFeePercent: number;
  workorderType?: WorkorderType;
  preApprovedDetails?: PreApprovedDetails | null;
  lineAmountType: LineAmountType;
  quoteTotalCost: number;
  quote: Quote;
}

// tslint:disable-next-line:cyclomatic-complexity
const QuoteDetails: FC<Props> = ({
  callOutCost,
  labourCost,
  materialCost,
  specificMaterials,
  totalCost,
  type,
  serviceFeePercent,
  workorderType,
  preApprovedDetails,
  lineAmountType,
  quoteTotalCost,
  quote,
}) => {
  const classes = QuoteDetailsStyles();
  const { getGST } = submitQuoteFormHelpers;

  const {
    state: { data: serviceFee },
    fetchData: fetchServiceFee,
  } = useFetch(serviceFeeService.getServiceFees, undefined, true);

  let total =
    callOutCost +
    materialCost +
    labourCost +
    specificMaterials.map((cost) => cost.charge).reduce((a, b) => a + b, 0);

  const gst = getGST(total, lineAmountType);
  const jobCost =
    lineAmountType !== LineAmountType.INCLUSIVE ? total : total - gst;

  if (lineAmountType !== LineAmountType.INCLUSIVE) {
    total += gst;
  }

  useEffect(() => {
    fetchServiceFee(
      total,
      quote.maintenanceRequestResponse.agencyId,
      quote.maintenanceRequestResponse.privateWorksDetails?.privateName
    );
  }, [total]);

  // const totalServiceFee = getProcessingFee(total, serviceFeePercent);
  const serviceFeeGST = getGST(serviceFee?.fee || 0, lineAmountType);

  return (
    <>
      <Box className={classes.detailsBox}>
        {/* Costs */}
        <Box mb={3}>
          {type === QuoteType.Regular && (
            <Box
              className={`${classes.costListItem} ${classes.costListItemFirst}`}>
              <Typography variant="h4">{LABELS.costOfLabour}</Typography>
              <Typography color="textSecondary" variant="h4">
                {`$${labourCost}`}
              </Typography>
            </Box>
          )}

          <Box className={classes.costListItem}>
            <Typography variant="h4">{LABELS.callOut}</Typography>
            <Typography color="textSecondary" variant="h4">
              {`$${callOutCost}`}
            </Typography>
          </Box>

          {type === QuoteType.Regular && (
            <Box className={classes.costListItem}>
              <Typography variant="h4">{LABELS.includedMaterials}</Typography>
              <Typography color="textSecondary" variant="h4">
                {`$${materialCost}`}
              </Typography>
            </Box>
          )}
        </Box>

        {/* Specific materials */}
        {type === QuoteType.Regular && (
          <Box mb={3}>
            <Box className={classes.costListItem}>
              <Typography variant="subtitle2">
                {LABELS.specificMaterial}
              </Typography>
            </Box>

            {specificMaterials.map((material) => (
              <Box className={classes.costListItem}>
                <Typography variant="h4">{material.description}</Typography>
                <Typography color="textSecondary" variant="h4">
                  {`$${material.charge}`}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {/* Total amount */}
      <Box bgcolor={color.lightCyan} p={2} pr={0} mt={2}>
        <Box pr={2} pb={1} {...ListItemBoxProps}>
          <Typography variant="h4">{LABELS.jobCost}</Typography>
          <Box textAlign="right">
            <Typography className={classes.formHeading} color="primary">
              {`$${jobCost.toFixed(2)}`}
            </Typography>
          </Box>
        </Box>

        <Box pr={2} py={1} {...ListItemBoxProps}>
          <Typography variant="h4">{LABELS.gst}</Typography>
          <Typography className={classes.formHeading} color="primary">
            {`$${gst.toFixed(2)}`}
          </Typography>
        </Box>

        <Box pr={2} py={1} {...ListItemBoxProps}>
          <Typography variant="h4">{LABELS.total}</Typography>
          <Box textAlign="right">
            <Typography className={classes.formHeading} color="primary">
              {`$${total.toFixed(2)}`}
            </Typography>
            {workorderType && preApprovedDetails && (
              <Typography variant="caption">
                {workorderType === WorkorderType.PreApproved
                  ? preApprovedDetails!.hasCostLimit
                    ? LABELS.costLimit(preApprovedDetails!.costLimit)
                    : ""
                  : LABELS.costLimit(quoteTotalCost)}
              </Typography>
            )}
          </Box>
        </Box>

        <Box pr={2} display="flex" alignItems="center" width="100%">
          <Box pr={2} py={1} {...ListItemBoxProps} alignItems="center">
            <Box display="flex" alignItems="center" flexGrow="1">
              <Tooltip title={LABELS.sortedFeeHelperText}>
                <HelpOutlined color="disabled" />
              </Tooltip>
              <Box ml={2}>
                <Typography className={classes.inline} variant="h4">
                  {LABELS.sortedFee} {serviceFeePercent}%
                </Typography>
              </Box>
            </Box>
            <Typography variant="h4">
              {/* tslint:disable-next-line:ban-comma-operator */}
              {`$${serviceFee?.fee}`}
            </Typography>
            <Typography variant="body2" className={classes.includesGSTLabel}>
              {LABELS.includesGstOf} {`$${serviceFeeGST}`}
            </Typography>
          </Box>
          <SubdirectoryArrowLeft color="disabled" />
        </Box>
      </Box>
    </>
  );
};

export default QuoteDetails;
