import { OnboardingStage } from "./OnboardingStages";
import { routes } from "../Routes";
import { getTradeId } from "./Auth";
import { store } from "../store/Store";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import { ToastType } from "../models/toastTypes/ToastTypesData.data";
import { ToastActions } from "../store/actions/ToastActions";
import { AddressComponent } from "../component/AddressPicker/AddressPicker.data";
import {
  BranchAddress,
  AddressFields,
} from "../component/registrationForm/RegistrationFormData.data";
import { OfficeDetails } from "../services/registerBusiness/RegistrationFormRequest.data";
import { Quote } from "../models/quote/Quote.data";
import {
  Property,
  TenantDetails,
  Tenants,
} from "../models/property/Property.data";
import {
  // [job cost limit] uncomment to enable check
  // PreApprovedDetails,
  WorkOrder,
} from "../models/workOrder/Workorder.data";
import { AppSettings } from "../AppSettings";
import { Job, JobDetails } from "../services/jobs/JobsData.data";
import { JobStatus } from "../models/jobs/JobData";
import { Image } from "../component/imageViewer/ImageViewerData.data";
import { TradesComment } from "../models/comment/Comment";
import { S3File } from "../models/file/S3File";
import moment, { Moment } from "moment";
import { get, isUndefined } from "lodash";
import { WorkorderType } from "../models/workOrder/WorkOrderTypes.data";
import { FileTypes, GST_PERCENT } from "../constants/AppConstants";
import { LineAmountType } from "../models/payment/PaymentLineAmountType";
import { OpenRequestUserDetails } from "../models/maintenance-request/MaintenanceRequest.data";
import { Contact } from "../component/contactCard/ContactCard";
import { NamedFile } from "../models/file/NamedFile";

const SERVICE_FEE_DECIMAL_PRECISION = 2;

export function titleCase(str: string) {
  return str.replace(
    /\w*\'/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export const showToast = (
  dispatch: Dispatch<AnyAction>,
  message: string = "",
  type: ToastType | undefined = undefined,
  autoHideDuration: number = 3000
) => {
  dispatch(
    ToastActions.showToast({
      open: true,
      message,
      type,
    })
  );

  setTimeout(
    () => dispatch(ToastActions.hideToast({ open: false })),
    autoHideDuration
  );
};

export function logOut() {
  const authTokenBasic = localStorage.getItem("authTokenBasic");
  localStorage.clear();
  localStorage.setItem("authTokenBasic", authTokenBasic!);
  store.dispatch({ type: "LOG_OUT" });
  window.open("/#/login", "_self");
}

export function onboardingRouteMapper(stage: string): string {
  switch (stage) {
    case OnboardingStage.AddBusiness:
      return routes.registrationForm.viewGeneric();

    case OnboardingStage.AddServicableRegions:
      return routes.addServiceableRegion.view(
        localStorage.getItem("firstOfficeId")!
      );

    case OnboardingStage.AddPaymentMethods:
      return routes.addPaymentMethods.view;

    case OnboardingStage.AddMoreOffice:
      return routes.listBranches.view(getTradeId()!);

    case OnboardingStage.UpdateAdminDetails:
      return routes.addUserDetails.view;

    case OnboardingStage.AddAdminTradeCategories:
      return routes.addTrades.view;

    case OnboardingStage.AddAdminTradeCategoriesDocuments:
      return routes.addTradesDocuments.view;

    case OnboardingStage.InviteMembers:
      return routes.listUsers.view;

    case OnboardingStage.Confirm:
      return routes.confirmRegistration.view;

    default:
      let authTokenBasic = localStorage.getItem("authTokenBasic");
      localStorage.clear();
      localStorage.setItem("authTokenBasic", authTokenBasic!);
      return "/login";
  }
}

export const getRegistrationFormAddressComponent = (
  placeAddressComponents: AddressComponent[]
): BranchAddress => {
  const address = {};

  placeAddressComponents.forEach((component) => {
    component.types.forEach((type) => {
      if (AddressFields[type]) {
        address[AddressFields[type]] = component.long_name;
      }
    });
  });

  return address as BranchAddress;
};

export const addressStringGeneratorFromBranchDetails = (
  branchResponse: Partial<OfficeDetails>
) => {
  const { streetNumber, streetName, suburb, country, postcode } =
    branchResponse;
  const components = [streetNumber, streetName, suburb, postcode, country];
  const responseArray = components.filter((component) => !!component);
  return responseArray.join(",");
};

// helper function returns property based on quote details from property list
export const getQuoteProperty = (
  quote: Quote,
  properties: Property[]
): Property => {
  const { propertyId } = quote.maintenanceRequestResponse;
  return properties.filter((property) => property.id === propertyId)[0]!;
};

// helper function returns property based on job details from property list
export const getJobProperty = (job: Job, properties: Property[]): Property => {
  const { propertyId } = job.quote.maintenanceRequestResponse!;
  return properties.filter((property) => property.id === propertyId)[0]!;
};

// get workorder from quote object with a given id
export const getWorkOrderById = (
  workOrders: WorkOrder[],
  id: string
): WorkOrder => workOrders.find((obj) => obj.referenceNumber === id)!;

export const agencyLogoURL = (agencyCode: string) => {
  return `${AppSettings.tenancyS3BaseURL()}agency_logos/${agencyCode.toLowerCase()}/logo.png`;
};
//return user friendly work order status
export const getWorkorderStatus = (status: string) => {
  switch (status) {
    case "requesting_quote":
      return "Requesting Quote";
    default:
      return "Pending Quote";
  }
};

// Returns next job status
export const nextJobStatus = (status: string) => {
  switch (status) {
    case JobStatus.BookingRequired:
      return JobStatus.Scheduled;

    case JobStatus.Scheduled:
      return JobStatus.ApprovalPending;

    case JobStatus.ApprovalPending:
      return JobStatus.Complete;

    default:
      return null;
  }
};

export const fileObjectToImageViewerObject = (image: File): Image => {
  const src = URL.createObjectURL(image);
  return { src, downloadUrl: src, alt: image.name };
};

export const fileObjectArrayToImageViewerObjectArray = (
  images: File[]
): Image[] =>
  images.map((img) => {
    const src = URL.createObjectURL(img);
    return { src, downloadUrl: src, alt: img.name };
  });

export function getEnumKeyByEnumValue(enumeration, enumValue) {
  const keys = Object.keys(enumeration).filter(
    (x) => enumeration[x] === enumValue
  );
  return keys.length > 0 ? keys[0] : null;
}

export const submitQuoteFormHelpers = {
  formatNumber: (n) => {
    // adds commas
    return n.replace(/\D/g, "");
  },
  formatField: (value: string, blur = false) => {
    if (value === "" || (value === "$" && blur)) {
      return "$0";
    }

    // check for decimal
    if (value.indexOf(".") >= 0) {
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      var decimal_pos = value.indexOf(".");

      // split number by decimal point
      var left_side = value.substring(0, decimal_pos);
      var right_side = value.substring(decimal_pos);

      // add commas to left side of number
      left_side = submitQuoteFormHelpers.formatNumber(left_side);

      // validate right side
      right_side = submitQuoteFormHelpers.formatNumber(right_side);

      // On blur make sure 2 numbers after decimal
      if (blur) {
        right_side += "00";
      }

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);

      // join number by .
      value = `$${left_side}.${right_side}`;
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      value = submitQuoteFormHelpers.formatNumber(value);
      value = `$${value}`;

      // final formatting
      if (blur) {
        value += ".00";
      }
    }

    // send updated string to input
    return value;
  },
  getProcessingFee: (cost: number, percentage: number) => {
    const power = 10 ** SERVICE_FEE_DECIMAL_PRECISION;
    return (
      Math.round((((percentage * cost) / 100) * power).toFixed(2) as any) /
      power
    );
  },
  getGST: (cost: number, type: LineAmountType) => {
    const power = 10 ** SERVICE_FEE_DECIMAL_PRECISION;
    if (type === LineAmountType.EXCLUSIVE) {
      return (
        Math.round((((cost * GST_PERCENT) / 100) * power).toFixed(2) as any) /
        power
      );
    }

    return Math.round((cost / 11) * power) / power;
  },
};

export const totalCostFromJobDetail = ({
  callOutCost,
  includedMaterialCost,
  labourCost,
  specificMaterial,
}: JobDetails) =>
  callOutCost +
  includedMaterialCost +
  labourCost +
  specificMaterial
    .map((material) => material.charge)
    .reduce((a, b) => a + b, 0);

export const getNameFirstCharacters = (firstName: string, lastName: string) =>
  `${firstName.charAt(0).toUpperCase()}${
    lastName ? lastName.charAt(0).toUpperCase() : ""
  }`;

export const getFirstCharactersFromFirstTwoWords = (name: string) => {
  const words = name.split(" ");
  return `${words[0].charAt(0).toUpperCase()}${
    words[1] ? words[1].charAt(0).toUpperCase() : ""
  }`;
};

export const insertReply = (
  commentsList: TradesComment[],
  comment: TradesComment,
  parentId: string
) => {
  commentsList.forEach((existingComment) => {
    if (existingComment.id === parentId) {
      existingComment.child.push(comment);
    } else {
      insertReply(existingComment.child, comment, parentId);
    }
  });
  return commentsList;
};
export const getImageSrc = (image: string | File | S3File) => {
  if (typeof image === "string") {
    return image;
  } else {
    const src = get(image, "link");
    return src || URL.createObjectURL(image);
  }
};

// Returns id if file is a S3 file, else returns undefined
export const isS3File = (file: S3File | File | NamedFile) => !!get(file, "id");

// Get ISO string from moment
export const getDateISOString = (date: Moment) => date.toDate().toISOString();
export const getDate = (date: string | null | undefined, format: string) =>
  date ? moment(date).format(format) : "N/A";

export const isValidJobCostUpdate = (
  existingTotal: number,
  total: number,
  workorderType: WorkorderType,
  // [job cost limit] uncomment to enable check
  // preApprovedDetails: PreApprovedDetails | null,
  onSite = false
) => {
  switch (workorderType) {
    case WorkorderType.Regular:
      if (!onSite && total === 0) {
        return {
          validUpdate: false,
          errorMessage:
            "Please update the job with correct cost before sending it for approval.",
        };
      } else {
        return { validUpdate: true, errorMessage: null };
      }

    case WorkorderType.PreApproved:
      // [job cost limit] uncomment to enable check

      // const { costLimit, hasCostLimit } = preApprovedDetails!;
      // if (hasCostLimit && total > costLimit) {
      //   return {
      //     validUpdate: false,
      //     errorMessage: `The cost cannot be more than the cost limit ($${costLimit})`,
      //   };
      // } else if (total === 0) {
      if (total === 0) {
        return {
          validUpdate: false,
          errorMessage:
            "Please update the job with correct cost before sending it for approval.",
        };
      } else {
        return { validUpdate: true, errorMessage: null };
      }

    default:
      return { validUpdate: true, errorMessage: null };
  }
};
// get file type from content type
export const getFileType = (contentType: string): FileTypes | undefined => {
  // Special case: for content-type as null, we have to assume it's an image
  if (contentType === null) {
    return FileTypes.Image;
  }

  const parts = contentType.split("/");
  const category = parts[0];
  const type = parts[1];

  // image
  if (category === FileTypes.Image) {
    return FileTypes.Image;
  }

  // pdf
  if (category === "application") {
    switch (type) {
      case FileTypes.Pdf:
        return FileTypes.Pdf;
    }
  }

  if (category === "video") {
    return FileTypes.Video;
  }

  return;
};

export const viewInNewTab = (link: string) => {
  window.open(link, "_blank");
};

export const scrollToRef = (
  elementRef: React.MutableRefObject<HTMLElement | null>
) => {
  if (elementRef.current) {
    elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export const googleMapsQuery = (query: string) =>
  `https://www.google.com/maps?q=${query}`;

export const getAddressString = (data: Property) => {
  const streetValue = data.unitNumber
    ? `${data.unitNumber}/${data.streetNumber || ""}`
    : data.streetNumber
    ? data.streetNumber
    : "";
  const addressString = `${streetValue} ${data.streetName || ""}`;
  return addressString.trim() || data.address;
};

export const getTenants = (
  tenantIds: number[],
  currentTenants: Tenants[]
): TenantDetails[] => {
  const tenantList: TenantDetails[] = [];

  currentTenants.forEach((tenant) => {
    if (
      tenant.primaryTenant &&
      tenantIds.includes(tenant.primaryTenant.id) &&
      isUndefined(
        tenantList.find((t) => {
          if (tenant.primaryTenant) return t.id === tenant.primaryTenant.id;
          return false;
        })
      )
    ) {
      tenantList.push(tenant.primaryTenant);
    }

    tenantList.push(
      ...tenant.secondaryTenants.filter(
        (st) =>
          tenantIds.includes(st.id) &&
          isUndefined(tenantList.find((t) => t.id === st.id))
      )
    );
  });
  return tenantList;
};

export const getContactDetails = (
  tenantIds: number[],
  allTenants: TenantDetails[]
): Contact[] => {
  const tenantList: Contact[] = [];
  const filteredTenants = (allTenants || []).filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  );

  filteredTenants.forEach((tenant) => {
    if (tenantIds.includes(tenant.id)) {
      const contact: Contact = {
        id: tenant.id,
        name: tenant.name,
        email: tenant.email,
        phone: tenant.phone,
        surname: tenant.surname,
      };
      tenantList.push(contact);
    }
  });

  return tenantList;
};

export const getJobsCountKeyNameFromStatus = (status: JobStatus) => {
  switch (status) {
    case JobStatus.BookingRequired:
      return "bookingRequiredCount";
    case JobStatus.Scheduled:
      return "scheduledCount";
    default:
      return null;
  }
};

export const getContactDetailsFromOpenRequestUserDetails = ({
  email,
  lastName,
  firstName,
  mobileNumber,
}: OpenRequestUserDetails): Contact => ({
  email,
  name: firstName,
  surname: lastName,
  phone: mobileNumber,
});

export const showBasedOnFileType = async (
  link: string,
  onImage: () => void,
  onOther: () => void
) => {
  const img = document.createElement("img");
  img.src = link;
  img.style.display = "none";
  img.onerror = onOther;
  img.onload = onImage;
};

export const getPageTitle = (location: string) => {
  const suffix = " | Sorted";
  return (
    (() => {
      switch (true) {
        case location === "/dashboard":
          return "Dashboard";
        case location.includes("quotes"):
          return "Quotes";
        case location.includes("jobs"):
          return "Jobs";
        case location.includes("people"):
          return "People";
        case location.includes("agents"):
          return "Agents";
        case location.includes("profile"):
          return "Profile";
        case location.includes("account"):
          return "Account";
        case location.includes("login"):
          return "Login";
        case location.includes("signUp"):
          return "Sign up";
        case location.includes("forgot-password"):
          return "Forgot password";
        case location.includes("404"):
          return "404";
        case location.includes("linkExpired"):
          return "Link expired";
        default:
          return "Trades";
      }
    })() + suffix
  );
};

export const truncateText = (text: string, length: number) => {
  if (text.length < length) return text;

  return `${text.substring(0, length)}...`;
};
