import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  chip: {
    whiteSpace: "normal",
    overflowWrap: "break-word",
    wordBreak: "break-word",
  },
  dialogPaper: {
    width: "80vw",
    maxWidth: "800px",
  },
});
