import React, { FC } from "react";
import { Box, Tabs, Tab, useTheme, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  TopNavType,
  TopNavLink,
  TopNavTabType,
  topNavTypeToTabsMapper,
} from "./TopNav.data";
import { TopNavStyles } from "./TopNavStyles.data";

interface Props {
  type: TopNavType;
  activeLink: TopNavLink;
}

const DashboardContent: FC<Props> = (props) => {
  const classes = TopNavStyles();
  const { activeLink, type } = props;
  const history = useHistory();
  const topNavTabs = topNavTypeToTabsMapper(type);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <section className={classes.navSection}>
      <Box className={classes.navWrapper}>
        <Box width="100%" flexGrow={smallScreen ? "0" : "1"}>
          <Tabs
            value={activeLink}
            onChange={(e, tabName) => {
              const selectedTab = (topNavTabs as Array<TopNavTabType>).filter(
                (tab) => tab.text === tabName
              )[0]!;
              history.push(selectedTab.route);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant={smallScreen ? "scrollable" : "standard"}
            centered={!smallScreen}
            scrollButtons={"auto"}
            TabIndicatorProps={{ style: { display: "none" } }}>
            {(topNavTabs as Array<TopNavTabType>).map((tab) => (
              <Tab
                disableRipple
                key={tab.text}
                label={tab.text}
                value={tab.text}
                className={classes.navLinks}
                classes={{
                  selected: smallScreen ? classes.navLinkActive : "",
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </section>
  );
};

export default DashboardContent;
