export const LABELS = {
  quotes: "Quotes",
  newQuote: "New Quote",
  jobsAvailable: "Jobs available",
  won: "Won",
  submitted: "Submitted",
  didntWin: "Didn't Win",
  quoteDetails: "Quote Details",
  noQuotes: (category) => `No ${category.toLowerCase()} quotes`,
  addAgencyMessage:
    "Got the agency code? Link your first agency by clicking the following button:",
  addAgent: "Add agent",
};
