import { makeStyles } from "@material-ui/core";

export const addVariationStyles = makeStyles({
  modalHeader: {
    display: "flex",
  },
  heading: {
    flexGrow: 1,
  },
  closeIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  toggleContainer: {
    display: "flex",
    marginTop: "2em",
    justifyContent: "flex-end",
  },
  navButton: {
    width: "124px",
    height: "32px",
  },
  buttonsSection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1.5em",
  },
  wrapper: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
});
