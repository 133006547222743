import React, { FC, useEffect } from "react";
import DashboardWrapperComponent from "../../component/dashboard/DashboardWrapperComponent";
import { useDispatch } from "react-redux";
import { LoginActions } from "../../store/actions/LoginActions";
import { useAppSelector } from "../../store/RootReducers";
import { Switch, Route } from "react-router";
import { routes } from "../../Routes";
import { TopNavSettingsLink } from "../../component/topNav/TopNavSettingsLink.data";
import ProfileSettings from "../settings/ProfileSettings";
import AccountSettings from "../accountSettingsContainer/AccountSettings";
import { TopNavType } from "../../component/topNav/TopNav.data";
import Quotes from "../quotes/Quotes";
import AgentsContainer from "../team/AgentsContainer";
import ViewJob from "../jobs/ViewJob";
import Jobs from "../jobs/Jobs";
import { NewBranch } from "../../component/dashboard/settings/account/branches/NewBranch";
import ListMembers from "../listMembers/ListMembers";
import { EditTeamMembers } from "../editMember/editMember";
import SubmitQuote from "../submitQuote/SubmitQuote";
import OverView from "../../component/dashboard/overView/OverView";
import { TopNavDashboardLink } from "../../component/topNav/TopNavDashboardLink.data";
import Payment from "../paymentContainer/Payment";
// import { TopNavDashboardLink } from "../../component/topNav/TopNavDashboardLink.data";

const Dashboard: FC<{}> = () => {
  // selectors
  const userDetails = useAppSelector((state) => state.login.userDetails);

  // dispatcher
  const dispatch = useDispatch();
  const dispatcher = {
    getUserDetails: () => dispatch(LoginActions.getUserDetails()),
  };

  // effects
  useEffect(() => {
    if (!userDetails) {
      dispatcher.getUserDetails();
    }
  }, [userDetails]);

  return userDetails ? (
    <Switch>
      {/* Overview Route commented until there is something to show on Overview screen */}
      <Route
        exact
        path={routes.dashboard.view}
        render={(props) => (
          <DashboardWrapperComponent
            navType={TopNavType.Dashboard}
            activeLink={TopNavDashboardLink.Overview}
            userDetails={userDetails}>
            <OverView {...props} monthJobCount={22} />
          </DashboardWrapperComponent>
        )}
      />

      <Route
        path={routes.dashboard.quotes(":filter?")}
        exact
        render={(props) => <Quotes {...props} userDetails={userDetails} />}
      />

      <Route
        path={routes.dashboard.submitQuote(":quoteId")}
        exact
        render={(submitQuoteRouteProps) => (
          <SubmitQuote {...submitQuoteRouteProps} userDetails={userDetails} />
        )}
      />

      <Route
        path={routes.dashboard.jobs()}
        exact
        render={(props) => <Jobs {...props} userDetails={userDetails} />}
      />

      <Route
        exact
        path={routes.dashboard.settings.profile}
        render={(props) => (
          <DashboardWrapperComponent
            navType={TopNavType.Settings}
            activeLink={TopNavSettingsLink.Profile}
            userDetails={userDetails}>
            <ProfileSettings userDetails={userDetails} {...props} />
          </DashboardWrapperComponent>
        )}
      />

      <Route
        exact
        path={routes.dashboard.settings.addBranch}
        render={() => (
          <DashboardWrapperComponent
            navType={TopNavType.Settings}
            activeLink={TopNavSettingsLink.Account}
            userDetails={userDetails}>
            <NewBranch />
          </DashboardWrapperComponent>
        )}
      />
      <Route
        exact
        path={routes.dashboard.settings.account}
        render={(props) => (
          <DashboardWrapperComponent
            navType={TopNavType.Settings}
            activeLink={TopNavSettingsLink.Account}
            userDetails={userDetails}>
            <AccountSettings {...props} />
          </DashboardWrapperComponent>
        )}
      />

      <Route
        exact
        path={routes.dashboard.settings.billing}
        render={(props) => (
          <DashboardWrapperComponent
            navType={TopNavType.Settings}
            activeLink={TopNavSettingsLink.Billing}
            userDetails={userDetails}>
            <Payment />
          </DashboardWrapperComponent>
        )}
      />

      <Route
        exact
        path={routes.dashboard.settings.profile}
        render={(props) => (
          <DashboardWrapperComponent
            navType={TopNavType.Settings}
            activeLink={TopNavSettingsLink.Profile}
            userDetails={userDetails}>
            <ProfileSettings userDetails={userDetails} {...props} />
          </DashboardWrapperComponent>
        )}
      />

      <Route
        path={routes.dashboard.team.agents}
        render={(props) => <AgentsContainer userDetails={userDetails} />}
      />

      <Route
        exact
        path={routes.dashboard.editPeople}
        render={() => <EditTeamMembers userDetails={userDetails} />}
      />

      <Route
        exact
        path={routes.dashboard.team.people}
        render={() => <ListMembers userDetails={userDetails} />}
      />

      {/* View Job */}
      <Route
        exact
        path={routes.dashboard.viewJob(":jobId")}
        render={(props) => <ViewJob userDetails={userDetails} {...props} />}
      />

      <Route
        exact
        path={routes.dashboard.viewVariationJob(":jobId", ":variationJobId")}
        render={(props) => <ViewJob userDetails={userDetails} {...props} />}
      />
    </Switch>
  ) : null;
};
export default Dashboard;
