import { makeStyles } from "@material-ui/core/styles";
import theme, { color } from "../../theme/Theme";

export const DetailsSidebarStyles = makeStyles({
  container: {
    background: color.wildSand,
    padding: ".75em .75em .75em 0",
    height: "90vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.2)",
      outline: "1px solid slategrey",
      borderRadius: "4px",
      cursor: "pointer",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
  sidebarContainer: {
    background: color.wildSand,
    height: "100vh",
    boxShadow:
      "0 1px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.14)",
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  sidebarUserDetailsContainer: {
    display: "flex",
    alignItems: "center",
    height: "10vh",
  },
});
