export enum UserDocType {
  PoliceCheck = "POLICE_CHECK",
  Other = "OTHER",
  Covid = "COVID",
}

export const userDocTypesOptions = [
  {
    value: UserDocType.PoliceCheck,
    label: "Police Check",
  },
  {
    value: UserDocType.Covid,
    label: "Covid",
  },
  {
    value: UserDocType.Other,
    label: "Other",
  },
];
