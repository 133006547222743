import { TextField } from "@material-ui/core";
import { Cancelable } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { color } from "../../theme/Theme";

interface SearchBoxProps {
  minChars: number;
  onChange: ((address: string) => void) & Cancelable;
  placeHolder?: string;
  // if recieved value of refresh counter is greater, clear the field
  parentRefreshCounter?: number;
}

const SearchBox: FC<SearchBoxProps> = ({
  minChars,
  onChange,
  parentRefreshCounter,
  placeHolder
}) => {
  // state
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [search, setSearch] = useState("");

  // effects
  useEffect(() => {
    if (parentRefreshCounter && parentRefreshCounter > refreshCounter) {
      setSearch("");
      setRefreshCounter(parentRefreshCounter);
    }
  }, [parentRefreshCounter]);

  return (
    <TextField
      variant="filled"
      fullWidth
      InputProps={{
        disableUnderline: true,
        inputProps: {
          style: {
            paddingTop: "10px",
            fontSize: "16px",
            background: color.wildSand,
          },
        },
        value: search,
      }}
      placeholder={placeHolder || "Search"}
      onChange={(e) => {
        e.preventDefault();
        const { value } = e.target;
        setSearch(value);
        if (value.trim().length >= minChars || value.length === 0) {
          onChange(value);
        }
      }}
    />
  );
};

export default SearchBox;
