import * as imageIpg from "../assets/ipg.png";
import * as imagePbs from "../assets/pbs.png";
import * as imageLsi from "../assets/lsi.png";
import * as imageImp from "../assets/imp.png";
import * as imageNax from "../assets/nax.png";
import * as imageIns from "../assets/ins.png";
import * as imageGeo from "../assets/geo.png";
import * as imageAuProp from "../assets/au_prop.png";
import * as imageGeneric from "../assets/sorted-logo.jpg";

export class AgencyCodeImgHelper {

    // Function to get logo image
    public static getImgSrc(agencyCode: string | undefined) {
        switch ((agencyCode || "").toLowerCase()) {
            case "ipg":
                return imageIpg;
                break;
            case "pbs":
            case "pbgun":
            case "pbsbn":
                return imagePbs;
                break;
            case "lsi":
                return imageLsi;
                break;
            case "imp":
                return imageImp;
                break;
            case "nax":
                return imageNax;
                break;
            case "ins":
                return imageIns;
                break;
            case "geo":
                return imageGeo;
                break;
            case "au_prop":
                return imageAuProp;
                break;
            default:
                return imageGeneric;
        }
    };
}