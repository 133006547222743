import {
  ServiceableRegionsAction,
  ServiceableRegionsActionType,  
} from "../actions/ServiceableRegionsActions";
import {AddServiceableRegionsSuccessPayload, ServiceableRegionsErrorPayload } from "../../services/serviceableRegions/serviceableRegionsServiceData.data"
import { ServiceableRegionsState } from "../state/ServiceableRegionsState";

const initialState: ServiceableRegionsState = {
  loading: false,
  success: false,
  error: "",
  serviceableRegions: [],
};

export const serviceableRegionsReducer = (
  state = initialState,
  action: ServiceableRegionsAction
) => {
  switch (action.type) {
    case ServiceableRegionsActionType.ADD_SERVICEABLE_REGIONS_REQUEST: {
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    }

    case ServiceableRegionsActionType.ADD_SERVICEABLE_REGIONS_SUCCESS: {
      const newServiceAbleRegions = action.data as AddServiceableRegionsSuccessPayload;
      return {
        ...state,
        serviceableRegions: [...state.serviceableRegions, ...newServiceAbleRegions],
        loading: false,
        success: true,
        error: null,
      };
    }

    case ServiceableRegionsActionType.SERVICEABLE_REGIONS_ERROR: {
      const data = action.data as ServiceableRegionsErrorPayload;
      return {
        ...state,
        loading: false,
        success: false,
        error: data ? data.error : null,
      };
    }

    default:
      return state;
  }
};
