import React, { FC, useState, useEffect } from "react";
import { ApplicationState } from "../../store/RootReducers";
import { ListUsersComponent } from "../../component/listUsers/ListUsers";
import { History } from "history";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { routes } from "../../Routes";
import { GetTradeUsersActions } from "../../store/actions/TradeUsersActions";
import { GetTradeUsersResponse } from "../../services/tradeService/TradeServiceData.data";
import { getTradeId } from "../../helper/Auth";
import { OnboardingStage } from "../../helper/OnboardingStages";
import { onboardingStageUpdater } from "../../helper/OnboardingStageUpdater/OnboardingStageUpdater";

interface Props {
  history: History;
}

type OwnProps = PropsFromState & Props;

interface PropsFromState {
  users: GetTradeUsersResponse | undefined;
  error: Error | null;
}

const ListUsers: FC<OwnProps> = (props: OwnProps) => {
  const tradeId = getTradeId();

  // State
  const [usersList, setUsersList] = useState<GetTradeUsersResponse | undefined>(
    undefined
  );
  const [getUsersError, setGetUsersError] = useState<Error | null>(null);

  // dispatcher
  const dispatch = useDispatch();

  const dispatcher = {
    getUsers: (id: string) => {
      dispatch(
        GetTradeUsersActions.getTradeUsersRequest({ request: { tradeId: id } })
      );
    },
  };

  const history = useHistory();

  // ReduxProps(StateToProps)
  const { users, error } = useSelector<ApplicationState, PropsFromState>(
    (state: ApplicationState) => ({
      error: state.tradeUsers.error,
      users: state.tradeUsers.tradeUsers,
    })
  );

  // Effects
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (users) {
      setUsersList(users);
    }
  }, [users]);

  useEffect(() => {
    setGetUsersError(error);
  }, [error]);

  const getUsers = (): void => {
    dispatcher.getUsers(tradeId!);
  };

  const onAddNew = () => {
    history.push(routes.inviteUser.view);
  };

  return usersList ? (
    <ListUsersComponent
      users={usersList!.content}
      error={getUsersError}
      onNextAndSkip={ async() => {
        await onboardingStageUpdater(OnboardingStage.Confirm)
        history.push(routes.confirmRegistration.view);
      }}
      onAddNew={onAddNew}
    />
  ) : null;
};

export default ListUsers;
