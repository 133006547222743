import React, { FC, useEffect } from "react";
import AddTradesDocumentsComponent from "../../component/addTradesDocuments/AddTradesDocumentsComponent";
import { useAppSelector } from "../../store/RootReducers";
import { UserTradeDocumentsUploadData } from "../../services/userTrades/UserTradeService.data";
import { AddDocData } from "./AddTradesDocumentsData.data";
import { useDispatch } from "react-redux";
import { TradeCategoriesActions } from "../../store/actions/TradeCategoriesActions";
import { AddTradeDocumentsFormConverter } from "./AddTradesDocumentsFormConverter";
import { useHistory } from "react-router-dom";
import { routes } from "../../Routes";
import { onboardingStageUpdater } from "../../helper/OnboardingStageUpdater/OnboardingStageUpdater";
import { OnboardingStage } from "../../helper/OnboardingStages";

const AddTrades: FC<{}> = (props) => {
  // Selector
  const { userTradeCategoriesDetails } = useAppSelector((state) => ({
    userTradeCategoriesDetails:
      state.tradeCategories.userTradeCategoriesDetails,
    error: state.tradeCategories.error,
  }));

  // Dispatcher
  const dispatch = useDispatch();
  const dispatcher = {
    postUserTradeDocuments: (data: UserTradeDocumentsUploadData) =>
      dispatch(TradeCategoriesActions.postUserTradeDocumentRequest(data)),

    getUserTradeCategories: () =>
      dispatch(TradeCategoriesActions.getUserTradeCategoriesRequest()),
  };

  // Effects
  useEffect(() => {
    dispatcher.getUserTradeCategories();
  }, []);

  // Action for next/skip button
  const history = useHistory();
  const onNextAndSkip = async () => {
    // for invited user, this is the end of his journey
    if (localStorage.getItem("inviteToken")) {
      localStorage.removeItem("inviteToken");
      localStorage.setItem("onboardingStage", "null");
      history.push(routes.dashboard.view);
    } else {
      await onboardingStageUpdater(OnboardingStage.InviteMembers);
      history.push(routes.listUsers.view);
    }
  };

  return userTradeCategoriesDetails ? (
    <AddTradesDocumentsComponent
      userTradeCategories={userTradeCategoriesDetails}
      onSubmit={(values: AddDocData) => {
        const tradeDocsRequestData = AddTradeDocumentsFormConverter(values);
        dispatcher.postUserTradeDocuments(tradeDocsRequestData);
      }}
      onNextAndSkip={onNextAndSkip}
    />
  ) : null;
};
export default AddTrades;
