import React, { FC, useState, useEffect } from "react";
import QuotesComponent from "../../component/dashboard/quotes/QuotesComponent";
import { GetUserResponse } from "../../services/login/LoginData";
import { useDispatch } from "react-redux";
import { QuotesActions } from "../../store/actions/QuotesActions";
import { QuoteStatus } from "../../models/quoteStatus/QuoteStatus.data";
import { QuoteFiltersData } from "./QuotesFilters.data";
import { useAppSelector } from "../../store/RootReducers";
import { Quote } from "../../models/quote/Quote.data";
import { useHistory, useParams } from "react-router";
import { routes } from "../../Routes";
import { paginationDefaultPageSize } from "../../constants/AppConstants";
import { TradeActions } from "../../store/actions/TradeActions";
import { isNull } from "lodash";

interface QuotesProps {
  userDetails: GetUserResponse;
}

interface ParamProps {
  filter?: string;
}

const Quotes: FC<QuotesProps> = (props) => {
  // state
  const { quotesData, loading, agencies } = useAppSelector(
    ({ quotes, trade }) => ({
      quotesData: quotes.quotesData,
      loading: quotes.loading,
      agencies: trade.agencies,
    })
  );
  const [sidebarQuote, setSidebarQuote] = useState<Quote | null>(null);
  const [quotesStatus, setQuotesStatus] = useState<QuoteStatus | undefined>(
    undefined
  );

  // hooks
  const history = useHistory();
  const { filter } = useParams<ParamProps>();

  // dispatcher
  const dispatch = useDispatch();
  const dispatcher = {
    getQuotes: (filtersData: QuoteFiltersData, onSuccess?: () => void) => {
      const {
        filters,
        page = 0,
        size = paginationDefaultPageSize,
        sort = "createdDate,desc",
      } = filtersData;
      dispatch(
        QuotesActions.GetQuotes({
          STATUS: [filters.status!],
          paginationFilters: { page, size, sort },
          onSuccess,
        })
      );
    },

    passQuote: (quoteId: string) => {
      dispatch(
        QuotesActions.UpdateQuoteStatusRequest({
          status: QuoteStatus.Pass,
          quoteId,
          onSuccess: () => {
            setSidebarQuote(null);
          },
        })
      );
    },

    getTradeAgencies: () => dispatch(TradeActions.GetTradeAgencies(0)),
  };

  const onFilterChange = (status: QuoteStatus) => {
    setSidebarQuote(null);
    history.push(routes.dashboard.quotes(status));
  };

  const onPageChange = (page: number, size: number) => {
    setSidebarQuote(null);
    dispatcher.getQuotes({
      filters: { status: quotesStatus },
      page,
      size,
    });
  };

  const onQuotesPerPageChange = (quotesPerPage: number) => {
    setSidebarQuote(null);
    dispatcher.getQuotes({
      filters: { status: quotesStatus },
      size: quotesPerPage,
    });
  };

  // effects
  useEffect(() => {
    const status =
      filter && Object.values(QuoteStatus).includes(filter as QuoteStatus)
        ? (filter as QuoteStatus)
        : QuoteStatus.Pending;
    dispatcher.getQuotes(
      {
        filters: {
          status,
        },
      },
      () => {
        setQuotesStatus(status);
      }
    );
  }, [filter]);

  useEffect(() => {
    dispatcher.getTradeAgencies();
  }, []);

  const { userDetails } = props;
  return !isNull(agencies) && quotesData && quotesStatus ? (
    <QuotesComponent
      showAgencyMessage={agencies.totalElements === 0}
      propertyList={quotesData.properties}
      userDetails={userDetails}
      loading={loading}
      onPageChange={onPageChange}
      onQuotesPerPageChange={onQuotesPerPageChange}
      quoteResponse={quotesData.paginatedQuoteResponse}
      onFilterChange={onFilterChange}
      quoteStatus={quotesStatus!}
      quotesStatusCount={quotesData.quoteStatusCount}
      setSidebarQuote={setSidebarQuote}
      sidebarQuote={sidebarQuote}
      onPassQuote={dispatcher.passQuote}
    />
  ) : null;
};

export default Quotes;
