import React, { FC } from "react";
import { Typography, Chip } from "@material-ui/core";

// Importing styles
import { TradsChipStyles } from "./TradesChipStyles.data";

interface TradesChipProps {
  active?: boolean;
  text: string;
  onClick: () => void;
}

const TradesChip: FC<TradesChipProps> = ({ active, text, onClick }) => {
  const classes = TradsChipStyles();
  return (
    <Chip
      classes={{ root: classes.chipRoot }}
      label={<Typography variant="caption">{text}</Typography>}
      className={active ? classes.activeChip : classes.inactiveChip}
      onClick={onClick}
    />
  );
};

export default TradesChip;
