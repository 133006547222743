export const LABELS = {
  description: "Description",
  requestedDate: "Requested date:",
  jobDue: "Job due:",
  additionalDetails: "Additional details",
  jobId: "Job ID:",
  propertyAccess: "Property access",
  preferredTime: "Preferred time",
  preferredDays: "Preferred days",
  noUnaccompaniedMinors: "No unaccompanied minors",
  pet: "Pet",
  animalDescription: "Animal description",
  clearAccess: "Clear access to site",
  stairsAccess: "Stairs to access site",
  parkingOnSite: "Parking onsite",
  unconfirmed: "Unconfirmed",
  propertyManager: "Property manager",
  tenants: "Tenants",
  contacts: "Contacts",
  images: "Images",
  propertyDetails: "Property details:",
  bookingDetails: "Booking details",
  quoteStartDate: "Quote start date",
  quoteCompletionDate: "Quote completion date",
  jobDateAndTime: "Job date and time",
  tradesPerson: "Trades person name and contact",
  onSite: "On-site quote request",
  labourCost: "Cost of labour",
  includedMaterialsCost: "Cost of included materials",
  callOutCost: "Call out cost",
  gst: "GST",
  totalCost: "Total cost",
  materials: "Materials:",
  scopeOfWork: "Scope of work description",
  costAndMaterials: "Cost and materials",
};
