import React, { FC } from "react";
import { TradeCategory } from "../../../services/tradeCategories/TradeCategories.data";
import { Select, MenuItem, FormControl, Typography } from "@material-ui/core";
import { titleCase } from "../../../helper/HelperFunctions";
import { AllTradesStyles } from "./AllTradeCategoriesStyles.data";
import { LABELS } from "../AddTradeCategoriesConstants";

interface Props {
  tradeCategories: TradeCategory[];
  addCategory: (trade: TradeCategory) => void;
}

const AllTrades: FC<Props> = (props) => {
  const { tradeCategories, addCategory } = props;
  const classes = AllTradesStyles();
  return (
    <FormControl className={classes.wrapper}>
      <Select
        defaultValue="none"
        style={{ width: "100%" }}
        variant="outlined"
        onChange={(event) => {
          event.preventDefault();
          addCategory(event.target.value as TradeCategory);
        }}>
        <MenuItem disabled key={"placeholder"} value={"none"}>
          <Typography variant="h4">{LABELS.allTradeCategories}</Typography>
        </MenuItem>
        {tradeCategories.map((trade) => (
          <MenuItem key={trade.id} value={trade as any}>
            <Typography variant="h4">{titleCase(trade.description)}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AllTrades;
