import { S3File } from "../../../../models/file/S3File";
import { LocalMarketplaceSummary } from "../../../../models/maintenance-request/MaintenanceRequest.data";

export const extractLocalMarketplaceImages = (
  localmarketplaceSummary: LocalMarketplaceSummary[]
): S3File[] => {
  const images = localmarketplaceSummary.find(
    (data) => data.labelId === "MediaUpload"
  );

  if (!images) return [];
  return (JSON.parse(images.value) as S3File[]).map((data) => ({
    contentType: "image/png",
    id: data.link,
    link: data.link,
    name: data.name || "",
  }));
};
