import { AppSettings } from "../../AppSettings";

export const sortedTncDocumentLink = () =>
  `${AppSettings.bucket}/sorted-services-agreement.pdf`;

export const LABELS = {
  ADD_PROPERTY: "Add a property using the search bar",
  SEARCH_AND_APPLY: "Search and apply for up to five properties",
  PROPERTY: "Property",
  PROPERTIES: "Properties",
  ADVERTISED_WEEKLY_RENTAL_AMOUNT: "Advertised weekly rental amount",
  INSPECTION_DATE: "Inspection date",
  PRIMARY_OFFICE: "This is the Head Office",
  ADD_BUSINESS_SUBHEADER_MESSAGE:
    "To get started we need some basic information about your business and the work that you do",
  ADD_BRANCH_SUBHEADER_MESSAGE:
    "Fill in the information below for additional offices or branches.",
  ADD_BRANCH_HEADER_MESSAGE: "Add branch",
  ADD_BUSINESS_CONTENT_BOX_MESSAGE: "Add business",
  ADD_BRANCH_CONTENT_BOX_MESSAGE: "Add new office",
  ADD_BUSINESS_SECTION_HEADING: "GENERAL INFORMATION",
  ADD_BRANCH_SECTION_HEADING: "BUSINESS INFORMATION",
  BRANCH_SECTION_HEADING: "ADDITIONAL DETAILS",
};

export const ABN_LENGTH = 11;
