import React, { FC } from "react";
import DashboardSection from "../../dashboardSection/DashboardSection";
import { color } from "../../../../theme/Theme";
import { List, Box, Typography, Chip, IconButton } from "@material-ui/core";
import DashboardListItem from "../../../lisItems/DashboardListItem";
import { ArrowForward, Home, Event, MonetizationOn } from "@material-ui/icons";
import { Quote } from "../../../../models/quote/Quote.data";
import { Property } from "../../../../models/property/Property.data";
import moment from "moment";
import { QuoteStatus } from "../../../../models/quoteStatus/QuoteStatus.data";
import TradeCategoryIcon from "../../../tradeCategoryIcon/TradeCategoryIcon";
import {
  getAddressString,
  titleCase,
  truncateText,
} from "../../../../helper/HelperFunctions";
import { MOMENT_DATE_FORMATS } from "../../../../constants/AppConstants";
import { QuoteCardStyles } from "./QuoteCardStyles.data";

import { routes } from "../../../../Routes";
import { useHistory } from "react-router-dom";
import UrgentTaskListItem from "../../urgentTaskListItem/UrgentTaskListItem";

interface Props {
  quote: Quote;
  property: Property;
  status: QuoteStatus;
  showQuoteDetailsInSidebar: React.Dispatch<React.SetStateAction<Quote | null>>;
  active?: boolean;
}

// tslint:disable-next-line:cyclomatic-complexity
const QuoteCard: FC<Props> = (props) => {
  const { quote, property, status, showQuoteDetailsInSidebar, active } = props;

  // Hooks
  const classes = QuoteCardStyles();
  const history = useHistory();

  // functions and variables
  const { maintenanceRequestResponse, workOrderId, totalCost } = quote;
  const {
    details: {
      title: maintenanceRequestHeading,
      description: maintenanceRequestDescription,
    },
    audit: { createdDate },
    workOrders,
  } = maintenanceRequestResponse;

  const workOrder = workOrders.find(
    (obj) => obj.referenceNumber === workOrderId
  )!;

  const { quoteDueDate, urgent } = workOrder;

  const { postcode, suburb, state, photos, address } = property;

  const getJobIdListItemSecondaryDetailPrimaryLabel = (
    quoteStatus: QuoteStatus,
    dueDate?: string | null
  ): string => {
    switch (quoteStatus) {
      case QuoteStatus.Pending:
        if (dueDate) {
          const dueDateForTimeRemaining = moment(dueDate).add(1, "day");
          return `Due: ${
            dueDateForTimeRemaining.isSameOrAfter()
              ? dueDateForTimeRemaining.fromNow(true)
              : "Overdue"
          }`;
        } else {
          return "Due: N/A";
        }

      case QuoteStatus.Sent:
        return "Submitted quote";

      case QuoteStatus.Rejected:
        return "Unsuccessful quote";

      default:
        return "";
    }
  };

  return (
    <Box
      mb={4}
      onClick={() =>
        history.push({
          pathname: routes.dashboard.submitQuote(quote.id),
          state: { quote, property },
        })
      }
      style={{ cursor: "pointer" }}>
      <DashboardSection
        borderColor={
          urgent ? color.warning : active ? color.teal : color.lightGrey
        }
        innerHeading={
          <DashboardListItem
            primary={maintenanceRequestHeading}
            secondary={`${moment(
              quote.maintenanceRequestResponse.audit.createdDate
            ).fromNow()} • ${titleCase(
              workOrder.category.replaceAll("_", " ")
            )}`}
            image={<TradeCategoryIcon tradeName={workOrder.category} />}
            secondaryData={
              <Chip
                color={status === QuoteStatus.Pending ? "secondary" : "primary"}
                label={getJobIdListItemSecondaryDetailPrimaryLabel(
                  status,
                  quoteDueDate
                )}
              />
            }
          />
        }>
        <>
          <List classes={{ root: classes.listRoot }}>
            {urgent && <UrgentTaskListItem />}
            <Box mb="1rem" mt="0.5rem">
              <Typography variant="caption">
                {truncateText(maintenanceRequestDescription, 200)}
              </Typography>
            </Box>

            <DashboardListItem
              image={photos && photos.length ? photos[0].link : <Home />}
              primary={
                address
                  ? getAddressString(property)
                  : "Address on quote approval"
              }
              secondary={`${suburb && titleCase(suburb)}${
                suburb && ","
              } ${state}${state && ","} ${postcode}`}
              secondaryData={
                <Box
                  display="flex"
                  gridGap="0.5rem"
                  justifyContent="center"
                  alignItems="center">
                  <Event />
                  <Typography variant="caption" component="h4">
                    {moment(createdDate).format(
                      MOMENT_DATE_FORMATS.DO_MMM_YYYY
                    )}
                  </Typography>
                </Box>
              }
            />

            <DashboardListItem
              image={<MonetizationOn />}
              primary={status === QuoteStatus.Pending ? "--" : `$${totalCost}`}
            />
          </List>

          <Box mt={2} display="flex" justifyContent="flex-end">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                showQuoteDetailsInSidebar(quote);
              }}>
              <ArrowForward />
            </IconButton>
          </Box>
        </>
      </DashboardSection>
    </Box>
  );
};

export default QuoteCard;
