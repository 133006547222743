/* eslint-disable @typescript-eslint/no-unsafe-return */
import axios from "axios";
import { HttpContentType } from "../HttpContentType";
import { AppSettings } from "../../AppSettings";

const { baseUrl } = AppSettings.server;
const ES_URL = `${baseUrl}/api/opensearch/`;

const instance = axios.create();
instance.interceptors.response.use(
  (response: any) => response,
  (error: any) => Promise.reject(error)
);

export enum ElasticQueryIndex {
  AustraliaLocations = "AustraliaLocations",
}

const getUrl = (index: ElasticQueryIndex) => {
  switch (index) {
    case ElasticQueryIndex.AustraliaLocations:
      return `${ES_URL}australia-locations/_search`;

    default:
      return "";
  }
};

export const runQuery = async (query: any, index: ElasticQueryIndex) => {
  const url = getUrl(index);

  try {
    const response = await instance.post(url, query, {
      headers: {
        "Content-Type": HttpContentType.Json,
        Authorization: `Bearer ${localStorage.getItem("authToken")!}`,
      },
    });

    return response.data;
  } catch (error) {
    return null;
  }
};
