import { get } from "lodash";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import SubmitQuoteComponent from "../../component/dashboard/quotes/submitQuote/SubmitQuoteComponent";
import { Property } from "../../models/property/Property.data";
import { Quote } from "../../models/quote/Quote.data";
import { QuoteStatus } from "../../models/quoteStatus/QuoteStatus.data";
import { routes } from "../../Routes";
import { GetUserResponse } from "../../services/login/LoginData";
import {
  SubmitQuoteRequestData,
  SubmitQuoteRequest,
  GetQuoteRequest,
  GetQuoteResponse,
} from "../../services/quotes/QuotesData.data";
import { QuotesActions } from "../../store/actions/QuotesActions";
import { useAppSelector } from "../../store/RootReducers";

interface SubmitQuoteProps {
  userDetails: GetUserResponse;
}

interface ParamsType {
  quoteId: string;
}

const SubmitQuote: FC<SubmitQuoteProps> = ({ userDetails }) => {
  // hooks
  const location = useLocation();
  const history = useHistory();
  const { quoteId } = useParams<ParamsType>();
  const dispatch = useDispatch();
  const quoteData = useAppSelector(
    (state) => state.quotes.getQuoteByIdResponse
  );

  // effects
  useEffect(() => {
    const { state } = location;
    const quotePassedFromQuotesPage: Quote | undefined = get(state, "quote");
    const propertyPassedFromQuotesPage: Property | undefined = get(
      state,
      "property"
    );
    if (!(quotePassedFromQuotesPage && propertyPassedFromQuotesPage)) {
      dispatcher.getQuote({ quoteId });
    } else {
      dispatcher.setQuote({
        quote: quotePassedFromQuotesPage,
        property: propertyPassedFromQuotesPage,
      });
    }
  }, []);

  // variables and functions
  const dispatcher = {
    getQuote: (getQuoteRequest: GetQuoteRequest) =>
      dispatch(QuotesActions.GetQuoteRequest(getQuoteRequest)),

    submitQuote: (request: SubmitQuoteRequestData) => {
      dispatch(QuotesActions.SubmitQuote(request));
    },

    passQuote: (id: string) => {
      dispatch(
        QuotesActions.UpdateQuoteStatusRequest({
          status: QuoteStatus.Pass,
          quoteId: id,
          onSuccess: () => history.replace(routes.dashboard.quotes()),
        })
      );
    },

    setQuote: (setQuoteData: GetQuoteResponse) => {
      dispatch(QuotesActions.GetQuoteSuccess(setQuoteData));
    },
  };

  const onSubmitQuote = (request: SubmitQuoteRequest) => {
    const requestObject: SubmitQuoteRequestData = {
      request,
      onSuccess: () => {
        history.push(routes.dashboard.quotes(QuoteStatus.Sent));
      },
    };
    dispatcher.submitQuote(requestObject);
  };

  return quoteData && quoteData.quote.id === quoteId ? (
    <SubmitQuoteComponent
      onSubmit={onSubmitQuote}
      quote={quoteData.quote}
      property={quoteData.property}
      userDetails={userDetails}
      onPassQuote={dispatcher.passQuote}
    />
  ) : null;
};

export default SubmitQuote;
