import React, { FC, useContext, useRef } from "react";
import { S3File } from "../../../../../models/file/S3File";
import { NamedFile } from "../../../../../models/file/NamedFile";
import { Box, Divider, Typography } from "@material-ui/core";
import { ListItemBoxProps } from "../../../quotes/submitQuote/SubmitQuoteComponentConstants.data";
import { Clear } from "@material-ui/icons";
import { AddArtefactsListSectionStyles } from "./ArtefactsListSectionStyles.data";
import { ImageViewerContext } from "../../../../../App";
import {
  getFileType,
  viewInNewTab,
} from "../../../../../helper/HelperFunctions";
import { FileTypes } from "../../../../../constants/AppConstants";
import UploadedFileListItemIcon from "../../../../UploadedFileListItemIcon/UploadedFileListItemIcon";
import RenderInput from "../../../../FormikComponents/RenderInputField";
import FileUpload from "../../../../fileUpload/FileUpload";

interface AddArtefactsListSectionProps {
  existing: S3File[];
  current: NamedFile[];
  // CurrentRemove and ExistingRemove expect updated array
  onExistingRemove: (newExistingArray: S3File[], removedId: string) => void;
  onCurrentRemove: (newCurrentArray: NamedFile[]) => void;
  onFileChange: (file?: File) => void;
  inputId: string;
  heading?: string;
  // These fields are to be used only for newly added files
  showArtefactInputField?: boolean;
  onArtefactInputFieldChange?: (newValue: string, index: number) => void;
  artefactInputFieldLabel?: string;
  singleItem?: boolean;
}

const AddArtefactsListSection: FC<AddArtefactsListSectionProps> = ({
  current,
  existing,
  onCurrentRemove,
  onExistingRemove,
  onFileChange,
  inputId,
  heading,
  showArtefactInputField,
  artefactInputFieldLabel,
  onArtefactInputFieldChange,
  singleItem,
}) => {
  // Hooks
  const classes = AddArtefactsListSectionStyles();
  const { setImages } = useContext(ImageViewerContext);
  const inputRef = useRef<HTMLInputElement>(null);

  // Functions
  const changeFile = (files: FileList | null) => {
    console.log("files", files);
    if (files) {
      const file = files[0];
      inputRef.current!.value = "";
      if (singleItem) {
        if (existing.length > 0) {
          onExistingRemove([], existing[0].id);
        } else if (current.length > 0) {
          onCurrentRemove([]);
        }
      }
      onFileChange(file);
    } else {
      inputRef.current!.value = "";
    }
  };
  return (
    <>
      {heading && <Typography variant="h4">{heading}</Typography>}
      <Box mt={2}>
        {/* Add special material name and file upload button */}
        <Box {...ListItemBoxProps}>
          <FileUpload
            id="file-upload"
            dropboxHeight="150px"
            inputRef={inputRef}
            onUpload={(files: FileList) => {
              changeFile(files);
            }}
          />
        </Box>

        {[...current, ...existing].length > 0 && (
          <Box my={1}>
            <Divider />
          </Box>
        )}

        {/* List of files added in the current instance of form */}
        {current.map((item, index) => {
          const fileType = getFileType(item.file.type);
          const imgSrc = URL.createObjectURL(item.file);

          return (
            <Box
              className={classes.documentListItem}
              key={`${index}-addedReceipt-${item.formDataName}`}>
              <Box
                onClick={() =>
                  fileType === FileTypes.Image
                    ? setImages([
                        {
                          src: imgSrc,
                          alt: item.formDataName,
                          downloadUrl: imgSrc,
                        },
                      ])
                    : viewInNewTab(imgSrc)
                }
                display="flex"
                alignItems="center"
                flexGrow="1">
                <UploadedFileListItemIcon fileType={fileType} />

                <Typography variant="h4">{item.formDataName}</Typography>
              </Box>
              {/* Input field for invoice id */}
              {showArtefactInputField && onArtefactInputFieldChange && (
                <Box mr={2}>
                  <RenderInput
                    onChange={(e) =>
                      onArtefactInputFieldChange(e.target.value, index)
                    }
                    placeholder={artefactInputFieldLabel}
                  />
                </Box>
              )}
              <Clear
                className={classes.pointer}
                onClick={() => {
                  current.splice(index, 1);
                  onCurrentRemove(current);
                }}
              />
            </Box>
          );
        })}

        {/* List of existing files  */}
        {existing.map((existingFile, index) => {
          const fileType = getFileType(existingFile.contentType);
          return (
            <Box
              key={`${index}-receipt-${existingFile.name}`}
              className={classes.documentListItem}>
              <Box
                onClick={() =>
                  fileType === FileTypes.Image
                    ? setImages([
                        {
                          src: existingFile.link,
                          alt: existingFile.name,
                          downloadUrl: existingFile.link,
                        },
                      ])
                    : viewInNewTab(existingFile.link)
                }
                display="flex"
                alignItems="center"
                flexGrow="1">
                <UploadedFileListItemIcon fileType={fileType} />

                <Typography className={classes.pointer} variant="h4">
                  {existingFile.name}
                </Typography>
              </Box>
              <Clear
                className={classes.pointer}
                onClick={() => {
                  const updatedExistingFiles = existing.filter(
                    (doc, docIndex) => docIndex !== index
                  );
                  onExistingRemove(updatedExistingFiles, existingFile.id);
                }}
              />
            </Box>
          );
        })}

        <input
          accept="image/*,application/pdf"
          ref={inputRef}
          className={classes.input}
          id={inputId}
          type="file"
          onChange={(e) => changeFile(e.target.files)}
        />
      </Box>
    </>
  );
};

export default AddArtefactsListSection;
