import React, { FC } from "react";
import { Input, withStyles, makeStyles } from "@material-ui/core";
import { color } from "../../../../theme/Theme";

interface Props {
  field?: any;
  disableUnderline?: boolean;
  placeholder?: string;
  type?: "number" | "text";
  teal?: boolean;
  onChange?: any;
  min?: number;
  step?: number;
  onBlur?: any;
  style?: any;
  whiteBackground?: boolean;
  endAdornment?: React.ReactNode;
}

const useStyles = makeStyles({
  input: {
    background: color.wildSand,
    width: "100%",
  },
});

export const StyledInput = withStyles({
  root: {
    color: color.teal,
    fontSize: "20px !important",
    background: color.wildSand,
    width: "100%",
  },
})(Input);

const InputField: FC<Props> = (props) => {
  const {
    field,
    disableUnderline,
    placeholder,
    type,
    teal,
    onChange,
    min,
    step,
    onBlur,
    style,
    whiteBackground,
    endAdornment,
  } = props;

  const classes = useStyles();

  return !!teal ? (
    <StyledInput
      inputProps={{
        min,
        step,
        style: { textAlign: "right", fontSize: "inherit" },
      }}
      disableUnderline={!!disableUnderline}
      endAdornment={endAdornment}
      type={type}
      {...field}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
    />
  ) : (
    <Input
      disableUnderline={!!disableUnderline}
      endAdornment={endAdornment}
      type={type}
      classes={whiteBackground ? "" : { root: classes.input }}
      {...field}
      onChange={onChange}
      placeholder={placeholder}
      inputProps={{ min, step, style }}
      onBlur={onBlur}
    />
  );
};

export default InputField;
