import { StoreAction } from "../StoreHelper";
import { OfficeDetails as AddBranchRequest } from "../../services/registerBusiness/RegistrationFormRequest.data";
import { Office as AddBranchSuccessPayload } from "../../container/registrationForm/AddBranch.data";
import {
  GetBranchRequestPayload,
  GetBranchSuccessPayload,
  GetBranchErrorPayload,
  BranchResponse,
} from "../../services/branch/Branch.data";
import {
  AddServiceableRegionsSuccessPayload,
  UpdateBranchDetailsRequestData,
} from "../../services/serviceableRegions/serviceableRegionsServiceData.data";

export enum AddBranchActionType {
  AddBranchRequest = "ADD_BRANCH_REQUEST",
  AddBranchSuccess = "ADD_BRANCH_SUCCESS",
  AddBranchError = "ADD_BRANCH_ERROR",
}

export interface AddBranchErrorPayload {
  readonly error: Error | any;
}

export interface AddBranchRequestPayload {
  readonly request: AddBranchRequest;
  readonly onSuccess?: ((response: AddBranchSuccessPayload) => void) | null;
  readonly onError?: ((error: Error | any) => void) | null;
}

export type AddBranchActionPayload =
  | AddBranchErrorPayload
  | AddBranchRequestPayload
  | AddBranchSuccessPayload
  | null;

export type AddBranchAction = StoreAction<
  AddBranchActionType,
  AddBranchActionPayload
>;

export class AddBranchActions {
  public static addBranchRequest(data: AddBranchRequestPayload) {
    return { type: AddBranchActionType.AddBranchRequest, data };
  }

  public static addBranchSuccess(data: AddBranchSuccessPayload) {
    return { type: AddBranchActionType.AddBranchSuccess, data };
  }

  public static addBranchError(data: AddBranchErrorPayload) {
    return { type: AddBranchActionType.AddBranchError, data };
  }
}

export enum GetBranchActionType {
  GetBranchRequest = "GET_BRANCH_REQUEST",
  GetBranchSuccess = "GET_BRANCH_SUCCESS",
  GetBranchError = "GET_BRANCH_ERROR",
}

export type GetBranchActionPayload =
  | GetBranchSuccessPayload
  | GetBranchRequestPayload
  | GetBranchErrorPayload;

export type GetBranchAction = StoreAction<
  GetBranchActionType,
  GetBranchActionPayload
>;

export class GetBranchActions {
  public static getBranchRequest(data: GetBranchRequestPayload) {
    return {
      type: GetBranchActionType.GetBranchRequest,
      data,
    };
  }

  public static getBranchSuccess(data: GetBranchSuccessPayload) {
    return {
      type: GetBranchActionType.GetBranchSuccess,
      data,
    };
  }

  public static getBranchError(data: GetBranchErrorPayload) {
    return { type: GetBranchActionType.GetBranchError, data };
  }
}

export enum UpdateBranchActionType {
  AddBranchServiceableRegion = "ADD_BRANCH_SERVICEABLE_REGION",

  UpdateBranchDetailsRequest = "UPDATE_BRANCH_DETAILS_REQUEST",
  UpdateBranchDetailsSuccess = "UPDATE_BRANCH_DETAILS_SUCCESS",
  UpdateBranchDetailsError = "UPDATE_BRANCH_DETAILS_ERROR",
}

export type UpdateBranchActionPayload =
  | AddServiceableRegionsSuccessPayload
  | UpdateBranchDetailsRequestData
  | BranchResponse
  | Error;

export type UpdateBranchAction = StoreAction<
  UpdateBranchActionType,
  UpdateBranchActionPayload
>;

export class UpdateBranchActions {
  public static addBranchServiceableRegion(
    data: AddServiceableRegionsSuccessPayload
  ) {
    return {
      type: UpdateBranchActionType.AddBranchServiceableRegion,
      data,
    };
  }

  public static updateBranchDetails(data: UpdateBranchDetailsRequestData) {
    return { type: UpdateBranchActionType.UpdateBranchDetailsRequest, data };
  }

  public static updateBranchDetailsSuccess(data: BranchResponse) {
    return { type: UpdateBranchActionType.UpdateBranchDetailsSuccess, data };
  }

  public static updateBranchDetailsError(error: Error) {
    return {
      type: UpdateBranchActionType.UpdateBranchDetailsError,
      data: error,
    };
  }
}
