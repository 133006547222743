import {
  PostUserDocumentSuccessPayload,
  PostUserDocumentData,
  DeleteUserDocumentRequest
} from "../../services/userDocumentsService/UserDocuments.data";
import { StoreAction } from "../StoreHelper";

//  Get Trade Users
export enum UserDocumentsActionType {
  PostUserDocumentRequest = "POST_USER_DOCUMENT_REQUEST",
  PostUserDocumentSuccess = "POST_USER_DOCUMENT_SUCCESS",
  PostUserDocumentError = "POST_USER_DOCUMENT_ERROR",

  DeleteUserDocumentRequest = "DELETE_USER_DOCUMENT_REQUEST",
  DeleteUserDocumentSuccess = "DELETE_USER_DOCUMENT_SUCCESS",
  DeleteUserDocumentError = "DELETE_USER_DOCUMENT_ERROR",
}

export type UserDocumentsActionPayload =
  | PostUserDocumentData
  | PostUserDocumentSuccessPayload
  | DeleteUserDocumentRequest

export type UserDocumentsAction = StoreAction<
  UserDocumentsActionType,
  UserDocumentsActionPayload
>;

export class UserDocumentsActions {
  public static PostUserDocumentRequest(data: PostUserDocumentData) {
    return {
      type: UserDocumentsActionType.PostUserDocumentRequest,
      data,
    };
  }

  public static PostUserDocumentSuccess(data: PostUserDocumentSuccessPayload) {
    return {
      type: UserDocumentsActionType.PostUserDocumentSuccess,
      data,
    };
  }

  public static PostUserDocumentError(error: Error) {
    return { type: UserDocumentsActionType.PostUserDocumentError, error };
  }

  public static DeleteUserDocumentRequest(data: DeleteUserDocumentRequest) {
    return {
      type: UserDocumentsActionType.DeleteUserDocumentRequest,
      data,
    };
  }

  public static DeleteUserDocumentSuccess(data: DeleteUserDocumentRequest) {
    return {
      type: UserDocumentsActionType.DeleteUserDocumentSuccess,
      data
    };
  }

  public static DeleteUserDocumentError(error: Error) {
    return { type: UserDocumentsActionType.DeleteUserDocumentError, error };
  }
}
