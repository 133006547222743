export const LABELS = {
  agents: "Agents",
  confirmBooking: "Confirm Booking",
  requestDetails: "Request details",
  viewJob: "View job",
  contactDetails: "Contact details",
  applianceRelated: "Appliance related",
  make: "Make",
  model: "Model",
  warrantyAvailable: "Warranty available",
  additionalDetails: "Additional details",
  quoteDueDate: "Quote due date",
  jobDueDate: "Job due date",
  requestedDate: "Requested date",
  requested: "Requested",
  cancelJob: "Cancel job",
  bookingDetails: "Booking details",

  // cancel job warnings
  notBookedCancelWarning:
    "You are cancelling this job and informing the property manager you can not complete the assigned job",
  notBookedCancelWarningCustomer:
    "You are cancelling this job and informing the customer you can not complete the assigned job",
  bookedCancelWarning:
    "You are cancelling a job you have a confirmed booking for. This will notify the Property Manager and tenants of the cancellation",
  bookedCancelWarningCustomer:
    "You are cancelling a job you have a confirmed booking for. This will notify the customer and tenants of the cancellation",
};
