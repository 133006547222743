import React, { FC } from "react";
import RegistrationStepsWrapper from "../registrationStepsComponents/registrationStepsWrapper/RegistrationStepsWrapper";
import { LABELS, VALIDATION_SCHEMA } from "./InviteUserConstants.data";
import ContentBoxSection from "../registrationStepsComponents/contentBox/ContentBoxSection";
import { InviteUserFormData } from "./InviteUserFomData.data";
import { UserRole } from "../userDetails/UserDetailsData.data";
import { Formik, Field } from "formik";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import {
  RenderInputFieldStyles,
  inputsFields,
  loginLabels,
} from "../FormikComponents/RenderInputFieldStyle";
import RenderInput from "../FormikComponents/RenderInputField";
import ContentBoxSubheading from "../registrationStepsComponents/contentBox/ContentBoxSubheading";
import RenderCheckBox from "../FormikComponents/RenderCheckbox";
import { InviteUserComponentStyles } from "./InviteUserComponentStyles";

interface Props {
  onSubmit: (formData: InviteUserFormData) => void;
}

const InviteUserComponent: FC<Props> = (props) => {
  const formInitialValues: InviteUserFormData = {
    firstName: "",
    lastName: "",
    email: "",
    role: [UserRole.tradeEmployee],
  };
  const { onSubmit } = props;
  const inputClasses = RenderInputFieldStyles();
  const classes = InviteUserComponentStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const renderInputProps = {
    style: inputsFields,
    labelStyle: loginLabels,
    overrideInputStyles: { input: inputClasses.input },
    overrideLabelStyles: { shrink: inputClasses.labels },
  };

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validationSchema={VALIDATION_SCHEMA}>
      {({ handleSubmit, setFieldValue, values, dirty, isValid }) => (
        <RegistrationStepsWrapper
          heading={LABELS.heading}
          subheading={LABELS.subheading}
          contentBox
          primaryButton={{
            text: "Next",
            action: () => {
              handleSubmit();
            },
            disabled: !dirty || !isValid,
          }}
          noPaddingButtonsSection>
          <ContentBoxSection heading={LABELS.nweUserDetails}>
            <Grid
              container
              spacing={isSmall ? 0 : 6}
              className={classes.container}>
              <Grid item lg={8}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Field name="firstName">
                      {({ form, field }) => (
                        <RenderInput
                          label={LABELS.firstName}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field name="lastName">
                      {({ form, field }) => (
                        <RenderInput
                          label={LABELS.lastName}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="email">
                      {({ form, field }) => (
                        <RenderInput
                          label={LABELS.email}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={isSmall ? "" : classes.roleContainer}
                item
                lg={4}>
                <ContentBoxSubheading text={LABELS.roles} />
                <Field type="checkbox" name="role" value={UserRole.tradeAdmin}>
                  {(fieldProps) => (
                    <RenderCheckBox
                      label={LABELS.tradeAdmin}
                      color={"primary"}
                      {...fieldProps}
                    />
                  )}
                </Field>
                <Field
                  type="checkbox"
                  name="role"
                  value={UserRole.tradeEmployee}>
                  {(fieldProps) => (
                    <RenderCheckBox
                      label={LABELS.tradeEmployee}
                      color={"primary"}
                      {...fieldProps}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </ContentBoxSection>
        </RegistrationStepsWrapper>
      )}
    </Formik>
  );
};

export default InviteUserComponent;
