import React, { FC, useState, useContext } from "react";
import {
  Box,
  Avatar,
  Typography,
  Divider,
  Select,
  MenuItem,
} from "@material-ui/core";
import parse from "html-react-parser";
import { agencyLogoURL } from "../../../../helper/HelperFunctions";
import { LABELS } from "../TermsConstants.data";
import DashboardButton from "../../dashboardButton/DashboardButton";
import { TermsAndConditionsContext } from "../../../../container/team/AgentsContainer";
import { color } from "../../../../theme/Theme";
import DashboardSection from "../../dashboardSection/DashboardSection";
import { TermsAndCondtionsStyles } from "./TermsAndConditionsStyles.data";
import { Alert } from "@material-ui/lab";

interface Props {
  setTermsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  viewOnly?: boolean;
}

const TermsAndConditions: FC<Props> = ({ setTermsDialogOpen, viewOnly }) => {
  // Context
  const {
    offices,
    selectedAgencies,
    setSelectedAgencies,
    onAcceptOrDecline,
    tradeAgencies,
    onUpdateTermsResponse,
    acceptTermsError,
  } = useContext(TermsAndConditionsContext);

  const agency = selectedAgencies[0];
  const {
    branch: { id: branchId, agencyId, name: branchName, suburb, internalCode },
    templateHtml,
    serviceFee,
    agencyCode,
  } = agency;

  // trade agency for getting notified members
  const tradeAgency = tradeAgencies.find(
    (ta) =>
      ta.branchDetail.branchDetail.id === branchId &&
      ta.branchDetail.agencyDetail.id === agencyId
  );

  let notifiedDepot = -1;
  if (tradeAgency) {
    const {
      termsDetail: { termsNotificationRecipients },
    } = tradeAgency;
    notifiedDepot = termsNotificationRecipients[0].id;
  }

  // State
  const [notifyDepot, setNotifyDepot] = useState<number>(notifiedDepot);

  const registeredAgent = tradeAgencies.find(
    (agencyBranch) =>
      agencyBranch.branchDetail.branchDetail.internalCode === internalCode
  );

  const acceptedTerms =
    registeredAgent === undefined
      ? undefined
      : registeredAgent.termsDetail.accepted;

  const classes = TermsAndCondtionsStyles();

  const disabled = notifyDepot === -1;
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box flexGrow="1">
        <Box textAlign="center" mb={3}>
          <Box mb={2} display="flex" justifyContent="center">
            <Avatar
              className={classes.laregIcons}
              alt={branchName}
              src={agencyCode ? agencyLogoURL(agencyCode) : undefined}>
              {!agencyCode && (
                <Box color={color.light}>
                  <Typography color="inherit" variant="h2">
                    {branchName.charAt(0)}
                  </Typography>
                </Box>
              )}
            </Avatar>
          </Box>
          <Box my={1}>
            <Typography variant="h4" color="textSecondary">
              {branchName}
            </Typography>
          </Box>
          <Typography variant="caption">{suburb}</Typography>
        </Box>

        {/* Terms and Conditions heading */}
        <Box mb={3}>
          <Typography color="textPrimary" variant="h5">
            {LABELS.termsAndConditions}
          </Typography>
        </Box>

        {/* Agency name */}
        <Box mb={2}>
          <Typography variant="subtitle2">{`${branchName} - Terms`}</Typography>
        </Box>

        {/* T&Cs */}
        <DashboardSection paperClass={classes.termsContainer}>
          {parse(templateHtml)}
        </DashboardSection>

        <Box my="1rem">
          <Alert severity="warning">
            {`I agree to and understand that a processing fee of ${
              serviceFee || 0
            }% will be
            automatically applied to the total cost of each job, capped at $90
            with a minimum fee of $5. This is for ongoing support and
            improvements to the Sorted platform, and will be invoiced to me on a
            monthly basis.`}
          </Alert>
        </Box>

        {/* Notify users dropdown */}
        <Box mt={4}>
          <Box mb={2}>
            <Typography variant="h4">{LABELS.depotsLabel}</Typography>
          </Box>
          <Divider />
          <Select
            disableUnderline
            fullWidth
            onChange={(e) => setNotifyDepot(e.target.value as number)}
            value={notifyDepot}>
            <MenuItem value={-1}>{LABELS.selectDepot}</MenuItem>
            {offices.map((office) => (
              <MenuItem key={`office-id:${office.id}`} value={office.id}>
                {office.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      {/* Buttons */}

      {viewOnly ? (
        <Box className={classes.buttonContainer}>
          <DashboardButton
            standardSize
            centered
            text="Cancel"
            variant="outlined"
            color="inherit"
            textButton
            action={() => {
              setSelectedAgencies([]);
              setTermsDialogOpen(false);
            }}
          />
        </Box>
      ) : (
        <>
          <Box className={classes.buttonContainer}>
            {/* Left Buttons */}
            <Box className={classes.button}>
              <DashboardButton
                standardSize
                centered
                text="Cancel"
                variant="outlined"
                color="inherit"
                textButton
                action={() => {
                  setSelectedAgencies([]);
                  setTermsDialogOpen(false);
                }}
              />
            </Box>

            {/* Right buttons */}
            <Box>
              {acceptedTerms !== false && (
                <Box className={classes.button} display="inline" mr={2}>
                  <DashboardButton
                    standardSize
                    disabled={disabled}
                    greyText={disabled}
                    centered
                    text="Decline"
                    variant="outlined"
                    color="inherit"
                    action={() => {
                      if (acceptedTerms) {
                        onUpdateTermsResponse({
                          termId: registeredAgent!.termsDetail.id,
                          accepted: false,
                        });
                      } else {
                        onAcceptOrDecline(false, [notifyDepot]);
                      }
                    }}
                  />
                </Box>
              )}
              {acceptedTerms !== true && (
                <Box className={classes.button}>
                  <DashboardButton
                    standardSize
                    centered
                    disabled={disabled}
                    greyText={disabled}
                    text="Accept"
                    variant={disabled ? "outlined" : "contained"}
                    lightText={!disabled}
                    action={() => {
                      if (acceptedTerms === false) {
                        onUpdateTermsResponse({
                          termId: registeredAgent!.termsDetail.id,
                          accepted: true,
                        });
                      } else {
                        onAcceptOrDecline(true, [notifyDepot]);
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          {acceptTermsError && (
            <Typography
              align="right"
              variant="caption"
              style={{ color: color.warning }}>
              {acceptTermsError.message}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default TermsAndConditions;
