import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { DeleteOutlineRounded, ExpandMoreRounded } from "@material-ui/icons";
import React, { FC, useEffect, useState } from "react";
import { confirmationDialogIconDimensions } from "../../../../constants/AppConstants";
import { color } from "../../../../theme/Theme";
import ConfirmationDialog from "../../../confirmationDialog/ConfirmationDialog";
import { GetPaymentMethodsResponse } from "../../../../services/payment/PaymentService.data";
import DashboardSection from "../../dashboardSection/DashboardSection";
import { SavedPaymentMethodsStyles } from "./SavedPaymentMethodsStyles.data";
import {
  getPaymentMethodLogo,
  getPaymentMethodSubtitle,
  getPaymentMethodTitle,
  LABELS,
  PaymentMethodType,
} from "./SavedPaymentMethodsConstants.data";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { PaymentActions } from "../../../../../src/store/actions/PaymentActions";
import { LoadingIndicatorActions } from "../../../../../src/store/actions/LoadingIndicatorAction";
import { getTradeId } from "../../../../../src/helper/Auth";
import { ApplicationState } from "../../../../../src/store/RootReducers";
import { Tag } from "../../../Tag/Tag";
import useFetch from "../../../../hooks/useFetch";
import paymentService from "../../../../services/payment/PaymentService";
import { Alert } from "@material-ui/lab";
import AddPaymentMethod from "../../../addPaymentMethod/AddPaymentMethod";

interface Props
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const SavedPaymentMethods: FC<Props> = ({
  paymentMethods,
  getPaymentMethods,
  deletePaymentMethod,
}) => {
  const [expandedPaymentMethod, setExpandedPaymentMethod] = useState<
    GetPaymentMethodsResponse | undefined
  >(undefined);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<
    string | undefined
  >(undefined);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const classes = SavedPaymentMethodsStyles();

  useEffect(() => {
    const tradeId = getTradeId()!;
    getPaymentMethods(parseInt(tradeId));
  }, []);

  const { state: setAsDefaultState, fetchData: setAsDefault } = useFetch(
    paymentService.setDefaultPaymentType.bind(paymentService),
    undefined,
    false
  );

  const tradeId = getTradeId()!;

  const toggleDeleteDialog = (refId: string | undefined, isOpen: boolean) => {
    setSelectedPaymentMethodId(refId);
    setIsDeleteConfirmationOpen(isOpen);
  };

  useEffect(() => {
    if (setAsDefaultState.success) {
      const tradeId = getTradeId()!;
      getPaymentMethods(parseInt(tradeId));
    }
  }, [setAsDefaultState.success]);

  useEffect(() => {
    setExpandedPaymentMethod(paymentMethods.find((pm) => pm.default));
  }, [paymentMethods]);

  const isSelectedPaymentMethodDefault =
    selectedPaymentMethodId &&
    paymentMethods.find((pm) => pm.refId === selectedPaymentMethodId)!.default;

  const renderDetailsRow = (title: string, subtitle: string) => {
    return (
      <>
        <Grid item xs={6}>
          <Typography variant="caption" className={classes.font14}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" className={classes.font14}>
            {subtitle}
          </Typography>
        </Grid>
      </>
    );
  };

  const renderPaymentMethodDetails = (payment: GetPaymentMethodsResponse) => {
    switch (payment.paymentMethod) {
      case PaymentMethodType.Bank:
        return (
          <>
            {renderDetailsRow(LABELS.accountName, payment.accountName)}
            {renderDetailsRow(LABELS.bsbNumber, payment.bsb)}
            {renderDetailsRow(LABELS.accountNumber, payment.accountNumber)}
          </>
        );
      case PaymentMethodType.Credit:
        return (
          <>
            {renderDetailsRow(
              LABELS.cardNumber,
              payment.cardNumber.replace(/\./g, "*")
            )}
            {renderDetailsRow(
              LABELS.expiry,
              `${payment.cardExpiryMonth}/${payment.cardExpiryYear}`
            )}
            {renderDetailsRow(LABELS.cvv, "***")}
          </>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <DashboardSection sectionTopMargin innerHeading={LABELS.paymentMethods}>
        {paymentMethods.length > 0 && (
          <Box mt={1} mb={2}>
            <Alert severity="info">{LABELS.defaultPaymentMethodInfo}</Alert>
          </Box>
        )}
        {paymentMethods.map((payment) => (
          <Accordion
            square
            expanded={expandedPaymentMethod === payment}
            onChange={(event, expanded) =>
              setExpandedPaymentMethod(expanded ? payment : undefined)
            }>
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start">
                  <img
                    src={getPaymentMethodLogo(payment)}
                    className={classes.imageStyle}
                  />
                  <Box>
                    <Typography
                      variant="caption"
                      className={classes.itemTextStyle}>
                      {getPaymentMethodTitle(payment)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.textStyle}>
                      {getPaymentMethodSubtitle(payment)}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  {!payment.default ? (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setAsDefault(payment.refId);
                      }}
                      variant="outlined">
                      {LABELS.setAsDefault}
                    </Button>
                  ) : (
                    <Tag
                      color={color.addButtonBg}
                      style={{
                        padding: `10px 15px`,
                        display: "flex",
                        alignItems: "center",
                      }}
                      fontWeight={600}
                      text="Default"
                      fontSize="12px"
                    />
                  )}
                  {paymentMethods.length > 1 && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDeleteDialog(payment.refId, true);
                      }}>
                      <DeleteOutlineRounded />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.detailsRoot }}>
              <Grid container className={classes.paymentMethodDetailsContainer}>
                {renderPaymentMethodDetails(payment)}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Box mt={2}>
          {/* For now only card is allowed so showing card directly */}
          {/* <Button
            fullWidth
            className={classes.addButton}
            startIcon={<Add />}
            onClick={() => setIsAddPaymentMethodPopupOpen(true)}>
            {LABELS.addNew}
          </Button> */}

          <AddPaymentMethod secondaryButtonText={LABELS.cancel} />
        </Box>
      </DashboardSection>
      <ConfirmationDialog
        primaryText={LABELS.deletePaymentMethod}
        secondaryText={LABELS.deleteConfirmation}
        open={isDeleteConfirmationOpen}
        handleConfirmationDialogClose={() => {
          toggleDeleteDialog(undefined, false);
        }}
        cancelText={LABELS.cancel}
        acceptText={LABELS.delete}
        action={() => {
          let setDefault;
          if (isSelectedPaymentMethodDefault && paymentMethods.length > 1) {
            setDefault = () => {
              setAsDefault(
                paymentMethods.find(
                  (pm) => pm.refId !== selectedPaymentMethodId
                )!.refId
              );
            };
          }

          selectedPaymentMethodId &&
            deletePaymentMethod(
              selectedPaymentMethodId,
              parseInt(tradeId),
              setDefault
            );
          toggleDeleteDialog(undefined, false);
        }}
        topIcon={
          <Box color={color.warning}>
            <DeleteOutlineRounded
              style={{ ...confirmationDialogIconDimensions }}
              color="inherit"
            />
          </Box>
        }>
        {isSelectedPaymentMethodDefault ? (
          <Alert severity="warning">{LABELS.deletingDefaultPayment}</Alert>
        ) : null}
      </ConfirmationDialog>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  paymentMethods: state.payment.paymentMethods,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPaymentMethods: (tradeId: number) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        tradeId,
      })
    );
  },
  deletePaymentMethod: (
    paymentRefId: string,
    tradeId: number,
    // reference to a function call where we will be setting another payment method as default
    // if default payment method is being deleted
    setAsDefault?: () => void
  ) => {
    dispatch(
      PaymentActions.deletePaymentStart({
        paymentRefId,
        onSuccess: () => {
          dispatch(PaymentActions.getPaymentMethodsStart({ tradeId }));
          dispatch(LoadingIndicatorActions.hide());
          if (setAsDefault) setAsDefault();
        },
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedPaymentMethods);
