import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { getFirstCharactersFromFirstTwoWords } from "../../helper/HelperFunctions";
import { ContactCardsStyles } from "./ContactCardStyles.data";

interface ContactCardNameAndDesignationProps {
  name: string;
  designation?: string;
  disableListItemSidePadding?: boolean;
  avatarColor?: string
}

const ContactCardNameAndDesignation: FC<ContactCardNameAndDesignationProps> = ({
  name,
  designation,
  avatarColor,
  disableListItemSidePadding,
}) => {
  const classes = ContactCardsStyles();

  return (
    <ListItem
      disableGutters={disableListItemSidePadding}
      className={`${disableListItemSidePadding && classes.disableSidePadding}`}>
      <ListItemAvatar>
        <Avatar style={{backgroundColor: avatarColor}} src={undefined}>
          {name && getFirstCharactersFromFirstTwoWords(name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="h4">{name}</Typography>}
        secondary={
          <Typography color="textSecondary" variant="caption">
            {designation}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default ContactCardNameAndDesignation;
