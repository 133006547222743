import LogRocket from "logrocket";
import * as redux from "redux";
import createSagaMiddleware from "redux-saga";
import sagas from "../Sagas";
import reducers, { ApplicationState } from "./RootReducers";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux.compose;
const sagaMiddleware = createSagaMiddleware();

// Creates store and sets it into singleton (Store.ts/store)
const createAppStore = (testing?: boolean | undefined | null) => {
  store = redux.createStore(
    reducers,
    composeEnhancers(
      redux.applyMiddleware(
        sagaMiddleware,
        /* LogRocket.reduxMiddleware() should be the very last 
    middleware applied, so it can properly log everything */
        LogRocket.reduxMiddleware()
      )
    )
  );

  sagaMiddleware.run(sagas);
  return store;
};

export type AppStore = redux.Store<ApplicationState>;
export let store: AppStore;

// initialize
createAppStore();
