import React, { FC, useEffect, useRef, useState } from "react";
import { GoogleMap, LatLng } from "../googleMap/GoogleMap";
import {
  Box,
  TextField,
  // IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Chip,
  Button,
  debounce,
} from "@material-ui/core";
// import { Search } from "@material-ui/icons";
import {
  ElasticSearchHighlight,
  convertElasticQueryResponse,
} from "../../helper/ElasticSearchHelper";
import { ElasticSearchLocationSuggestion } from "../../models/elasticQuery/elasticSearchLocationSuggestion";
import { LABELS } from "./NearbyAreasConstants";
import { useElasticQuery } from "../../hooks/UseElasticQuery";
import {
  ElasticQueryIndex,
  runQuery,
} from "../../services/elasticSearch/ElasticSearch";
import { Region } from "../../container/addServiceableRegionsForm/AddServiceableRegionsFormdata.data";
import { ElasticResponse } from "../../models/elasticQuery/formattedElasticResponse";
import { useStyles } from "./NearbyAreasStyles";
import { capitalize } from "lodash";

interface NearbyAreasProps {
  center: LatLng;
  addSuggestionRegions: (regions: Region[]) => void;
}

const NearbyAreas: FC<NearbyAreasProps> = ({
  center,
  addSuggestionRegions,
}) => {
  const [radius, setRadius] = useState<number | undefined>();
  const [suggestionResponses, setSuggestionResponses] = useState<
    (ElasticSearchLocationSuggestion & ElasticSearchHighlight)[] | undefined
  >();
  const [openLocationSuggestionsDialog, setOpenLocationSuggestionsDialog] =
    useState<boolean>(false);
  const [addLocations, setAddLocations] = useState<
    | {
        location: { lat: number; lon: number };
        name: string;
        postcode: string;
        state: string;
      }[]
    | undefined
  >();
  const [markers, setMarkers] = useState<LatLng[]>([center]);

  const { query, setState: setElasticQueryState } = useElasticQuery({
    index: ElasticQueryIndex.AustraliaLocations,
  });

  const mounted = useRef<boolean>(false);
  const changeRadius = useRef(
    debounce((radius: number | undefined) => {
      setRadius(radius);
    }, 1000)
  );

  useEffect(() => {
    if (suggestionResponses && suggestionResponses.length > 0) {
      setOpenLocationSuggestionsDialog(true);
      resetAddLocations();
    }
  }, [suggestionResponses]);

  useEffect(() => {
    if (mounted.current) {
      runQuery(query, ElasticQueryIndex.AustraliaLocations).then((res) => {
        if (res) {
          const response = convertElasticQueryResponse(
            res as ElasticResponse<ElasticSearchLocationSuggestion>
          );

          // setTotalLocationSuggestionResponses(response.total);
          // setSearchedTill(response.searchedTill);
          setSuggestionResponses(response.responses);
        }
      });
    }
  }, [query]);

  useEffect(() => {
    setMarkers([center]);
  }, [center]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  const handleDialogCancel = () => {
    resetAddLocations();
    setOpenLocationSuggestionsDialog(false);
  };

  const handleAddSuggestions = () => {
    if (addLocations && addLocations.length) {
      addSuggestionRegions(
        addLocations.map(({ state, name, postcode }) => ({
          country: "Australia",
          locality: name,
          postcode,
          state,
        }))
      );
      setOpenLocationSuggestionsDialog(false);
    }
  };

  const resetAddLocations = () => {
    setAddLocations(
      (
        suggestionResponses as (ElasticSearchLocationSuggestion &
          ElasticSearchHighlight)[]
      ).map(({ location, details }) => ({
        location,
        name: capitalize(details.locality),
        postcode: details.postcode,
        state: details.state,
      }))
    );
  };

  const classes = useStyles();

  return (
    <>
      <Box display="flex" gridGap="1em" mb={2}>
        <TextField
          onChange={(e) => {
            changeRadius.current(+e.target.value);
          }}
          type="number"
          variant="outlined"
          label="Radius to cover (in km)"
          fullWidth
        />

        <Button
          onClick={() => {
            if (radius)
              setElasticQueryState({
                filter: [
                  {
                    geo_distance: {
                      distance: `${radius}km`,
                      location: {
                        lat: center.lat,
                        lon: center.lng,
                      },
                    },
                  },
                ],
              });
          }}
          variant="outlined">
          {LABELS.search}
        </Button>
      </Box>

      <GoogleMap
        zoomCenter={center}
        markers={markers}
        circles={
          radius
            ? [
                {
                  center: center,
                  radius: radius * 1000,
                },
              ]
            : undefined
        }
      />

      {/* Suggestions box */}
      {openLocationSuggestionsDialog && (
        <Dialog classes={{ paper: classes.dialogPaper }} open>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              {LABELS.suggestionsDialogHeading}
              <Box display="flex">
                <Button onClick={handleDialogCancel} variant="outlined">
                  {LABELS.cancel}
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={handleAddSuggestions}
                  color="primary"
                  variant="contained">
                  {LABELS.add}
                </Button>
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent>
            <Typography variant="body2">
              {LABELS.suggestionsDialogSubheading}
            </Typography>
            <Box py={1}>
              <Grid spacing={2} container>
                {addLocations!.map(({ postcode, name }) => (
                  <Grid item xs={12} sm={4}>
                    <Chip
                      classes={{ label: classes.chip }}
                      key={`${name}-${postcode}`}
                      label={`${name}, ${postcode}`}
                      onDelete={() => {
                        setAddLocations(
                          addLocations!.filter(
                            (al) =>
                              !(al.postcode === postcode && al.name === name)
                          )
                        );
                      }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default NearbyAreas;
