/// <summary>
/// List of redux forms (key of StoreHelper.getState().form)
/// </summary>
export enum Forms {
    LoginForm = "loginForm",
    SignUpForm = "signUpForm",
    RegistrationForm = "registrationForm",
}

export enum TestForms {
    AddressPickerForm = "addressPickerForm",
    SelectBoxForm = "selectBoxForm"
}