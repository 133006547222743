import React, { FC, useState } from "react";
import { StyledDialog } from "../../../styledDialogContainer/StyledDialog";
import {
  Typography,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Button,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { addVariationStyles } from "./AddVariationStyles";
import { LABELS } from "./AddVariationModalConstants";
import { QuoteVarianceType } from "../../../../models/quoteVarianceType/QuoteVarianceType";
import { QuoteVariationDetails } from "../../../../models/quote/Quote.data";
import { color } from "../../../../theme/Theme";

interface AddVariationProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (data: QuoteVariationDetails) => void;
}

const AddVariationModal: FC<AddVariationProps> = ({
  open,
  handleClose,
  handleSubmit,
}) => {
  const classes = addVariationStyles();
  const [quoteVarianceType, setQuoteVarianceType] = useState<QuoteVarianceType>(
    QuoteVarianceType.jobApproved
  );
  const [singleInvoice, setSingleInvoice] = useState<boolean>(false);
  const [comments, setComments] = useState<string>("");

  const refreshModal = () => {
    setComments("");
    setSingleInvoice(false);
    setQuoteVarianceType(QuoteVarianceType.jobApproved);
  };

  return (
    <StyledDialog open={open}>
      <Box className={classes.wrapper}>
        <Box>
          <section className={classes.modalHeader}>
            <Typography className={classes.heading} variant="h5">
              {LABELS.confirmApproval}
            </Typography>
            <div className={classes.closeIconContainer}>
              <Clear
                onClick={() => {
                  handleClose();
                  refreshModal();
                }}
              />
            </div>
          </section>

          <Box mt={3}>
            <Typography variant="h4">{LABELS.topMessage}</Typography>
          </Box>

          <Box mt={2}>
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={quoteVarianceType}
              onChange={({ target: { value } }) =>
                setQuoteVarianceType(value as QuoteVarianceType)
              }>
              <FormControlLabel
                value={QuoteVarianceType.jobApproved}
                control={<Radio color="primary" />}
                label={LABELS.approvedJobVariation}
              />
              <FormControlLabel
                value={QuoteVarianceType.newBooking}
                control={<Radio color="primary" />}
                label={LABELS.newVariationBooking}
              />
            </RadioGroup>

            {/* Comment section */}
            <Box mt={4} mb={3}>
              <Typography variant="h4">
                {quoteVarianceType === QuoteVarianceType.newBooking ? (
                  LABELS.commentLabelNew
                ) : (
                  <>
                    {LABELS.commentLabelApproved}
                    <span style={{ color: color.warning }}>*</span>
                  </>
                )}
              </Typography>
            </Box>
            <TextField
              value={comments}
              fullWidth
              multiline
              rows="4"
              variant="outlined"
              onChange={({ target: { value } }) => setComments(value)}
              placeholder={LABELS.commentPlaceholder}
              inputProps={{ style: { fontSize: ".9em" } }}
            />
          </Box>
        </Box>

        <section className={classes.buttonsSection}>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
              refreshModal();
            }}
            className={classes.navButton}>
            {LABELS.cancel}
          </Button>
          <Button
            disabled={
              quoteVarianceType === QuoteVarianceType.jobApproved &&
              comments === ""
            }
            color="primary"
            onClick={() => {
              handleSubmit({ singleInvoice, comments, quoteVarianceType });
              refreshModal();
            }}
            className={classes.navButton}>
            {LABELS.confirm}
          </Button>
        </section>
      </Box>
    </StyledDialog>
  );
};

export default AddVariationModal;
