import {
  GetQuoteRequest,
  GetQuoteResponse,
  GetQuotesRequest,
  GetQuotesResponse,
  SubmitQuoteRequestData,
  UpdateQuoteRequest,
} from "../../services/quotes/QuotesData.data";
import { StoreAction } from "../StoreHelper";
import { Quote } from "../../models/quote/Quote.data";

export enum QuotesActionType {
  GetQuotesRequest = "GET_QUOTES_REQUEST",
  GetQuotesSuccess = "GET_QUOTES_SUCCESS",
  GetQuotesError = "GET_QUOTES_ERROR",

  GetQuoteRequest = "GET_QUOTE_REQUEST",
  GetQuoteSuccess = "GET_QUOTE_SUCCESS",
  GetQuoteError = "GET_QUOTE_ERROR",

  SubmitQuoteRequest = "SUBMIT_QUOTE_REQUEST",
  SubmitQuoteSuccess = "SUBMIT_QUOTE_SUCCESS",
  SubmitQuoteError = "SUBMIT_QUOTE_ERROR",

  UpdateQuoteStatusRequest = "UPDATE_QUOTE_STATUS_REQUEST",
  UpdateQuoteStatusSuccess = "UPDATE_QUOTE_STATUS_SUCCESS",
  UpdateQuoteStatusError = "UPDATE_QUOTE_STATUS_ERROR",
}

export type QuotesActionPayload =
  | GetQuotesRequest
  | GetQuotesResponse
  | GetQuoteResponse
  | SubmitQuoteRequestData
  | Quote
  | UpdateQuoteRequest
  | GetQuoteRequest
  | Error;

export type QuotesAction = StoreAction<QuotesActionType, QuotesActionPayload>;

export class QuotesActions {
  public static GetQuotes(data: GetQuotesRequest) {
    return { type: QuotesActionType.GetQuotesRequest, data };
  }

  public static GetQuotesSuccess(data: GetQuotesResponse) {
    return { type: QuotesActionType.GetQuotesSuccess, data };
  }

  public static GetQuotesError(error: Error): QuotesAction {
    return {
      type: QuotesActionType.GetQuotesError,
      data: error,
    };
  }

  // Get quote
  public static GetQuoteRequest(data: GetQuoteRequest): QuotesAction {
    return {
      type: QuotesActionType.GetQuoteRequest,
      data,
    };
  }

  public static GetQuoteSuccess(data: GetQuoteResponse): QuotesAction {
    return {
      type: QuotesActionType.GetQuoteSuccess,
      data,
    };
  }

  public static GetQuoteError(error: Error): QuotesAction {
    return {
      type: QuotesActionType.GetQuoteError,
      data: error,
    };
  }

  // Submit Quote
  public static SubmitQuote(data: SubmitQuoteRequestData): QuotesAction {
    return { type: QuotesActionType.SubmitQuoteRequest, data };
  }

  public static SubmitQuoteSuccess(data: Quote) {
    return { type: QuotesActionType.SubmitQuoteSuccess, data };
  }

  public static SubmitQuoteError(error: Error) {
    return {
      type: QuotesActionType.SubmitQuoteError,
      data: error,
    };
  }

  public static UpdateQuoteStatusRequest(data: UpdateQuoteRequest) {
    return {
      type: QuotesActionType.UpdateQuoteStatusRequest,
      data,
    };
  }

  public static UpdateQuoteStatusSuccess(data: Quote) {
    return {
      type: QuotesActionType.UpdateQuoteStatusSuccess,
      data,
    };
  }

  public static UpdateQuoteStatusError(error: Error) {
    return {
      type: QuotesActionType.UpdateQuoteStatusSuccess,
      data: error,
    };
  }
}
