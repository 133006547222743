export const LABELS = {
  addTrade: "Add Trade",
  addTradeDialogHeading: "Add trade categories",
  addTradeCategoriesSubheading:
    "Add any trades you hold the appropriate accreditation for and you will be able to quote for jobs in this trade category",
  selectedTradeCategories: "Following trade categories will be added",
  cancel: "Cancel",
  delete: "Delete",
  remove: "Remove",
  removeTradeCategory: "Remove Trade",
  removeTradeCategorySubheading:
    "Are you sure you want to remove this trade? This user will no longer be able to work on jobs within this trade category",
  deleteDocument: "Delete Document",
  deleteDocumentDescription:
    "Are you sure you want to delete this document and remove from user profile?",
};
