import React, { FC } from "react";
import { GetUserResponse } from "../../../../services/login/LoginData";
import FilterChipsSection, { BadgeData } from "../quotesFilterChipsSection/QuotesFilterChipsSection";
import QuoteCard from "../quoteCard/QuoteCard";
import {
  QuotesFilterStatusChips,
  QuotesFilterStatus,
} from "../quotesFilterChipsSection/QuotesFilterChips.data";
import { QuoteStatus } from "../../../../models/quoteStatus/QuoteStatus.data";
import { Quote } from "../../../../models/quote/Quote.data";
import { getQuoteProperty } from "../../../../helper/HelperFunctions";
import { Property } from "../../../../models/property/Property.data";
import { Box, Paper, TablePagination } from "@material-ui/core";
import { Pageable, QuoteStatusCount } from "../../../../services/quotes/QuotesData.data";
import { paginationRowsPerPageOptions } from "../../../../constants/AppConstants";

interface QuotesListProps {
  userDetails: GetUserResponse;
  onFilterChange: (filter: QuoteStatus) => void;
  count: number;
  quotes: Quote[];
  propertyList: Property[];
  status: QuoteStatus;
  showQuoteDetailsInSidebar: React.Dispatch<React.SetStateAction<Quote | null>>;
  sidebarQuoteId?: string;
  pageable: Pageable;
  loading: boolean;
  onPageChange: (page: number, size: number) => void;
  onQuotesPerPageChange: (quotesPerPage: number) => void;
  quotesStatusCount: QuoteStatusCount;
}

const NewQuotes: FC<QuotesListProps> = (props) => {
  const {
    onFilterChange,
    status,
    showQuoteDetailsInSidebar,
    quotes,
    propertyList,
    sidebarQuoteId,
    count,
    pageable: { pageNumber, pageSize },
    onPageChange,
    loading,
    onQuotesPerPageChange,
    quotesStatusCount
  } = props;

  // Active filter chip
  let activeChip: QuotesFilterStatus;
  QuotesFilterStatusChips.forEach((chip) => {
    if (chip.value === status) {
      activeChip = chip.text;
    }
  });

  const noQuotes = (quoteStatus: QuotesFilterStatus) =>
    `No ${quoteStatus.toLowerCase()}`;

  const badgeData: BadgeData[] = [{
    name: QuoteStatus.Pending,
    count: quotesStatusCount.newQuoteCount
  },{
    name: QuoteStatus.Sent,
    count: quotesStatusCount.submittedQuoteCount
  }]

  return (
    <>
      <FilterChipsSection
        activeChip={activeChip!}
        setActiveFilterChip={onFilterChange}
        badgeData={badgeData}
      />

      {/* Not to be shown when loading */}
      {!loading && (
        <>
          <Box mt={4} paddingX={"1em"}>
            {!!quotes.length ? (
              <>
                {quotes.map((quote, index) => {
                  const property = getQuoteProperty(quote, propertyList);
                  return (
                    <QuoteCard
                      key={`Quote-${index}`}
                      showQuoteDetailsInSidebar={showQuoteDetailsInSidebar}
                      status={status}
                      quote={quote}
                      property={property}
                      active={!!sidebarQuoteId && sidebarQuoteId === quote.id}
                    />
                  );
                })}

                <Box mb={2}>
                  <TablePagination
                    component={"div"}
                    count={count}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={paginationRowsPerPageOptions}
                    page={pageNumber}
                    onChangePage={(e, page) => onPageChange(page, pageSize)}
                    onChangeRowsPerPage={({ target: { value } }) =>
                      onQuotesPerPageChange(parseInt(value, 10))
                    }
                  />
                </Box>
              </>
            ) : (
              <Paper elevation={0} variant="outlined">
                <Box py={2} px={2}>
                  {noQuotes(activeChip!)}
                </Box>
              </Paper>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default NewQuotes;
