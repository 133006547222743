import { ServiceType } from "../ServiceType.data";
import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import {
  GetQuoteRequest,
  GetQuotesRequest,
  SubmitQuoteRequest,
  SubmitQuoteRequestPayload,
} from "./QuotesData.data";
import { getTradeId } from "../../helper/Auth";
import { QuoteStatus } from "../../models/quoteStatus/QuoteStatus.data";
import { Quote } from "../../models/quote/Quote.data";
import { MultipartFormData } from "../MultipartFormData.data";
import _ from "lodash";

const serviceType = ServiceType.TradesBff;
const quoteServiceType = ServiceType.Quotes;
const quoteBffServiceType = ServiceType.QuotesBff;
const QUOTE = "quote";
export class QuotesService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);
  private readonly quoteService: ApiServiceBase = new ApiService(
    quoteServiceType
  );
  private readonly quoteBffService: ApiServiceBase = new ApiService(
    quoteBffServiceType
  );

  // get quotes list using post call
  public post(payload: GetQuotesRequest): Promise<any> | any {
    const { paginationFilters, ...body } = payload;
    const { page, size, sort } = paginationFilters;
    return this.service.post(
      { route: [getTradeId()!, QUOTE], query: { page, size, sort } },
      body
    );
  }

  // get single quote
  public getQuote(data: GetQuoteRequest): Promise<Quote> | Quote {
    const { quoteId } = data;
    return this.quoteBffService.get({ route: [quoteId] });
  }

  public submitQuote(payload: SubmitQuoteRequest): Promise<any> | any {
    const { quoteId, files, ...body } = payload;
    const tradeDocStringified: SubmitQuoteRequestPayload = {
      files,
      quoteRequest: JSON.stringify({ ...body }),
    };
    const requestObj: MultipartFormData[] = [];
    _.forOwn(tradeDocStringified, (val, key) => {
      requestObj.push({
        name: key,
        content: val,
      });
    });

    return this.quoteService.putMultipart(
      { route: [quoteId.toString()] },
      requestObj
    );
  }

  public setQuoteStatus(status: QuoteStatus, id: string): Promise<void> | void {
    return this.quoteService.patch({ route: [id] }, JSON.stringify(status));
  }
}

const quotesService = new QuotesService();
export default quotesService;
