import React, { FC, FocusEvent } from "react";
import { FormControl, FormHelperText, Typography } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import moment, { Moment } from "moment";
import { DATE_FORMAT } from "../../constants/AppConstants";
import { ErrorMessage } from "formik";
import { RenderInputFieldStyles } from "./RenderInputFieldStyle";
import { renderDatePickerStyles } from "./styles/RenderDatePickerStyles.data";

interface Props {
  name: string;
  disabled?: boolean;
  onChange: (value: moment.Moment) => void;
  label: string;
  value: string | number | Moment;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  hidden?: boolean;
  inputClassName?: string;
  inputStyle?: any;
  type?: string;
  containerClass?: string;
  diasbleFormControlMargins?: boolean;
  disablePast?: boolean;
  filled?: boolean;
}

/* tslint:disable:cyclomatic-complexity */
const RenderDatePicker: FC<Props> = (props: Props) => {
  const {
    value,
    label,
    onChange,
    onBlur,
    containerClass,
    disabled,
    diasbleFormControlMargins,
    disablePast,
    name,
    filled,
  } = props;
  const { errorMessageStyles } = RenderInputFieldStyles();
  const classes = renderDatePickerStyles();

  return (
    <div className={`${containerClass || ""}`}>
      <FormControl
        margin={diasbleFormControlMargins ? "none" : "normal"}
        style={{ width: "100%" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={filled ? classes.filled : ""}
            autoOk
            disableToolbar
            InputProps={{
              disableUnderline: !!filled,
              className: filled ? classes.filled : "",
            }}
            disabled={disabled}
            variant="inline"
            format={DATE_FORMAT}
            margin={diasbleFormControlMargins ? "none" : "normal"}
            disablePast={!!disablePast}
            label={label}
            value={value}
            onChange={(date: moment.Moment) => onChange(date!)}
            onBlur={onBlur}
            KeyboardButtonProps={{
              "aria-label": "change date",
              className: classes.icon,
            }}
          />
        </MuiPickersUtilsProvider>
        <FormHelperText className={errorMessageStyles}>
          <ErrorMessage name={name} />
        </FormHelperText>
      </FormControl>
    </div>
  );
};
/* tslint:enable */

export default RenderDatePicker;

interface DatePickerProps {
  error: any;
}

export const DatePicker: FC<Props & DatePickerProps> = ({
  value,
  label,
  onChange,
  onBlur,
  containerClass,
  disabled,
  diasbleFormControlMargins,
  disablePast,
  name,
  filled,
  error,
}) => {
  const { errorMessageStyles } = RenderInputFieldStyles();
  const classes = renderDatePickerStyles();

  return (
    <FormControl
      margin={diasbleFormControlMargins ? "none" : "normal"}
      style={{ width: "100%" }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          // className={filled ? classes.filled : ""}
          autoOk
          disableToolbar
          InputProps={{
            disableUnderline: !!filled,
            className: filled ? classes.filled : "",
          }}
          disabled={disabled}
          variant="inline"
          format={DATE_FORMAT}
          margin={diasbleFormControlMargins ? "none" : "normal"}
          disablePast={!!disablePast}
          label={label}
          value={value}
          onChange={(date: moment.Moment) => onChange(date!)}
          onBlur={onBlur}
          KeyboardButtonProps={{
            "aria-label": "change date",
            className: classes.icon,
          }}
        />
      </MuiPickersUtilsProvider>
      <FormHelperText className={errorMessageStyles}>
        <Typography>{error}</Typography>
      </FormHelperText>
    </FormControl>
  );
};
