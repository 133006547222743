import { cancelledJobTypes } from "./../../../../models/jobs/JobData";
import { JobStatus } from "../../../../models/jobs/JobData";

export enum JobFilterStatus {
  BookingRequired = "Booking required",
  Scheduled = "Scheduled",
  ApprovalPending = "Pending approval",
  PaymentOnHold = "Payment on hold",
  Complete = "Complete",
  Cancelled = "Cancelled",
}

export const JobFilterStatusChips = [
  {
    text: JobFilterStatus.BookingRequired,
    value: [JobStatus.BookingRequired],
  },
  {
    text: JobFilterStatus.Scheduled,
    value: [JobStatus.Scheduled],
  },
  {
    text: JobFilterStatus.ApprovalPending,
    value: [JobStatus.ApprovalPending],
  },
  {
    text: JobFilterStatus.PaymentOnHold,
    value: [JobStatus.PaymentOnHold],
  },
  {
    text: JobFilterStatus.Complete,
    value: [JobStatus.Complete],
  },
  {
    text: JobFilterStatus.Cancelled,
    value: cancelledJobTypes, // should be kept as a reference, else some conditions will fail
  },
];
