import { StoreAction } from "../StoreHelper";
import {
  RegisterBusinessRequest,
  UpdateBusinessRequest,
  RegisterBusinessSuccessPayload,
  UpdateBusinessSuccessPayload,
} from "../../services/registerBusiness/RegistrationFormRequest.data";

export enum RegisterBusinessActionType {
  RegisterBusinessRequest = "REGISTER_BUSINESS_REQUEST",
  RegisterBusinessSuccess = "REGISTER_BUSINESS_SUCCESS",
  RegisterBusinessError = "REGISTER_BUSINESS_ERROR",
}

export interface RegisterBusinessErrorPayload {
  readonly error: Error | any;
}

export interface RegisterBusinessRequestPayload {
  readonly request: RegisterBusinessRequest;
  readonly onSuccess?: ((response: any) => void) | undefined | null;
  readonly onError?: ((error: Error | any) => void) | undefined | null;
}

export type RegisterBusinessActionPayload =
  | RegisterBusinessErrorPayload
  | RegisterBusinessRequestPayload
  | RegisterBusinessSuccessPayload
  | null;

export type RegisterBusinessAction = StoreAction<
  RegisterBusinessActionType,
  RegisterBusinessActionPayload
>;

export class RegisterBusinessActions {
  public static registerBusinessRequest(data: RegisterBusinessRequestPayload) {
    return { type: RegisterBusinessActionType.RegisterBusinessRequest, data };
  }

  public static registerBusinessSuccess(data: RegisterBusinessSuccessPayload) {
    return { type: RegisterBusinessActionType.RegisterBusinessSuccess, data };
  }

  public static registerBusinessError(data: RegisterBusinessErrorPayload) {
    return { type: RegisterBusinessActionType.RegisterBusinessError, data };
  }
}

//  Update Business
export enum UpdateBusinessActionType {
  UpdateBusinessRequest = "UPDATE_BUSINESS_REQUEST",
  UpdateBusinessSuccess = "UPDATE_BUSINESS_SUCCESS",
}

export type UpdateBusinessActionPayload =
  | UpdateBusinessRequest
  | UpdateBusinessSuccessPayload
  | null;

export type UpdateBusinessAction = StoreAction<
  UpdateBusinessActionType,
  UpdateBusinessActionPayload
>;

// tslint:disable-next-line:max-classes-per-file
export class UpdateBusinessActions {
  public static updateBusinessRequest(data: UpdateBusinessRequest) {
    return { type: UpdateBusinessActionType.UpdateBusinessRequest, data };
  }

  public static updateBusinessSuccess(data: UpdateBusinessSuccessPayload) {
    return { type: UpdateBusinessActionType.UpdateBusinessSuccess, data };
  }
}
