import * as Yup from "yup";
import { EMAIL_PATTERN } from "../../constants/AppConstants";
import { passwordRex } from "../../helper/Validation";

export const LABELS = {
  heading: "Okay great! Now let’s complete your user profile",
  subheading:
    "You will be the primary user for your business. So add your details first and we can add more team members later.",
  yourDetails: "Your details",
  firstName: "First name",
  lastName: "Last name",
  email: "Email",
  mobileNumber: "Mobile number",
  image: "Add Image",
  roles: "Roles",
  tradeAdmin: "Trade Admin",
  tradeEmployee: "Trade Employee",
  password: "Password",
  confirm: "Confirm Password",
  inviteHeading: "Welcome to the team! Let’s complete your profile",
  inviteSubheading:
    "This information will be used for your personal profile in your new team",
};

export const ERRORS = {
  invalidEmailFormat: "Invalid email format",
  invalidMobileNumber: "Invalid mobile number",
  requiredMessage: (fieldName) => `${fieldName} is required`,
};

export function VALIDATION_SCHEMA(isInvited) {
  return Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(ERRORS.requiredMessage(LABELS.firstName))
      .max(50),
    lastName: Yup.string()
      .trim()
      .required(ERRORS.requiredMessage(LABELS.lastName))
      .max(50),
    email: Yup.string()
      .required(ERRORS.requiredMessage(LABELS.email))
      .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
    password:
      isInvited &&
      Yup.string()
        .required("This field is required")
        .matches(passwordRex, "Password doesn't fulfil the requirements"),
    confirmPassword:
      isInvited &&
      Yup.string()
        .required("This field is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
  });
}
