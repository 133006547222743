import { makeStyles } from "@material-ui/core";
import { color } from "../../../../theme/Theme";

export const jobCostUpdateFormStyles = makeStyles({
  formHeading: {
    fontSize: "18.1px",
    fontWeight: 500,
  },
  quoteFormValue: {
    color: color.teal,
    fontSize: "20px",
    display: "inline",
    maxWidth: "200px",
  },
  inline: {
    display: "inline",
  },
  pointer: {
    cursor: "pointer",
  },
  note: {
    color: color.charcoal,
  },
  submitQuoteButton: {
    fontSize: "13.6px",
    padding: "12px 0",
    width: "152px",
    borderRadius: "4px",
  },
  listItemBox: {
    padding: ".5em 1em .5em 0 !important",
  },
  specificItemNameInputContainer: {
    flexGrow: 1,
    color: "rgba(0,0,0,.26) !important",
    marginRight: "20px",
  },
  commentsTextArea: {
    background: color.secondary,
    fontSize: "14px",
    padding: "1em",
    fontFamily: "GT Walsheim light",
  },
  notesHeadingMargin: {
    marginBottom: ".5em",
  },
  includesGSTLabel: {
    fontWeight: 600,
    marginLeft: ".5em",
  },
});
