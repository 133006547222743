import { makeStyles } from "@material-ui/core";
import { color } from "../../theme/Theme";

export const useStyles = makeStyles({
  dropBox: {
    width: "100%",
    border: `2px dashed ${color.grey}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "150px",
    cursor: "pointer",
    padding: "0 1em",
  },
});
