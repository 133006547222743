export const LABELS = {
  yourTeam: "Your team",
  registeredAgents: "Registered Agents",
  pendingAgents: "Pending Agents",
  addAgent: 'Add agent'
};

export enum AgentFilter {
  all = 'All',
  registered = 'Registered',
  pending = 'Pending'
}

export const agentFilters = [{value: AgentFilter.all, label: "All"} ,{value: AgentFilter.registered, label: 'Registered'}, {value: AgentFilter.pending, label: "Pending"} ];