import { routes } from "../../Routes";
import { TopNavTab } from "./TopNav.data";

// Uncomment billing and setting nav links when screens are ready
export enum TopNavSettingsLink {
  Account = "Account",
  Billing = "Billing",
  Profile = "Profile",
  // Settings = "Settings",
}

export interface TopNavSettingsTab extends TopNavTab {
  text: TopNavSettingsLink;
}

export const topNavSettingsTabs: TopNavSettingsTab[] = [
  {
    text: TopNavSettingsLink.Account,
    route: routes.dashboard.settings.account,
  },
  {
    text: TopNavSettingsLink.Billing,
    route: routes.dashboard.settings.billing,
  },
  {
    text: TopNavSettingsLink.Profile,
    route: routes.dashboard.settings.profile,
  },
  // {
  //   text: TopNavSettingsLink.Settings,
  //   route: "route",
  // },
];
