import React, { FC, useContext } from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import ImageGrid from "../../../../docGrid/DocGrid";
import { JobArtefactFiles } from "../../../../../services/jobs/JobsData.data";
import StyledExpansionPanel from "../../../../styledExpansionPanel/StyledExpansionPanel";
import { S3File } from "../../../../../models/file/S3File";
import { ArtefactsSectionStyles } from "./ArtefactsSectionStyles";
import { ImageViewerContext } from "../../../../../App";
import {
  getFileType,
  viewInNewTab,
} from "../../../../../helper/HelperFunctions";
import { FileTypes } from "../../../../../constants/AppConstants";
import UploadedFileListItemIcon from "../../../../UploadedFileListItemIcon/UploadedFileListItemIcon";

interface Props {
  jobArtefactFiles: JobArtefactFiles;
}

export type ImageSourceArray = Array<File | S3File>;

const ArtefactsSection: FC<Props> = ({
  jobArtefactFiles: {
    jobImageFileUploadDetails: jobImages,
    specialMaterialReceiptFileUploadDetails: specialMaterialReceipts,
    invoiceFileUploadDetails: invoices,
  },
}) => {
  const classes = ArtefactsSectionStyles();
  const { setImages } = useContext(ImageViewerContext);
  return (
    <StyledExpansionPanel heading={"Job artefacts"}>
      <Box pl={2}>
        <Divider />
        <Box mt={2} pr={2}>
          <Typography variant="h4">Job images</Typography>
          {/* Images */}
          <Box my={2} mb={4}>
            <ImageGrid docs={jobImages} />
          </Box>

          {specialMaterialReceipts.length > 0 && (
            <>
              <Typography className={classes.categoryHeading} variant="h4">
                Special material Receipts
              </Typography>
              <Divider />
              <Box my={2}>
                {specialMaterialReceipts.map((receipt, index) => {
                  const fileType = getFileType(receipt.contentType);
                  return (
                    <Box
                      key={`${index}-special-material-receipts-${receipt.name}`}
                      className={classes.documentListItem}
                      onClick={() =>
                        fileType === FileTypes.Image
                          ? setImages([
                              {
                                src: receipt.link,
                                alt: receipt.name,
                                downloadUrl: receipt.link,
                              },
                            ])
                          : window.open(receipt.link)
                      }>
                      <UploadedFileListItemIcon fileType={fileType} />
                      <Typography variant="h4">{receipt.name}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </>
          )}

          {/* Invoice */}
          {invoices.length > 0 && (
            <>
              <Box mt={3} mb={2}>
                <Typography className={classes.categoryHeading} variant="h4">
                  Invoices
                </Typography>
                <Divider />
              </Box>
              <Box my={2}>
                {invoices.map((inv, index) => {
                  const fileType = getFileType(inv.contentType);
                  return (
                    <Box
                      key={`${index}-invoices-${inv.name}`}
                      className={classes.documentListItem}
                      onClick={() =>
                        fileType === FileTypes.Image
                          ? setImages([
                              {
                                src: inv.link,
                                alt: inv.name,
                                downloadUrl: inv.link,
                              },
                            ])
                          : viewInNewTab(inv.link)
                      }>
                      <UploadedFileListItemIcon fileType={fileType} />
                      <Typography
                        className={classes.pdfNumberDisplay}
                        variant="h4"
                        display="inline"
                        align="left">
                        {inv.name}
                      </Typography>
                      <Typography
                        className={classes.pdfNumberDisplay}
                        variant="caption"
                        display="inline"
                        align="right"
                        color="textSecondary">
                        {inv.documentNumber
                          ? inv.documentNumber
                          : "Not Available"}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </StyledExpansionPanel>
  );
};

export default ArtefactsSection;
