import React, { FC } from "react";
import { LABELS } from "./WorkorderDetailsConstants.data";
import { Grid, List, ListItem, Typography } from "@material-ui/core";
import { WorkorderDetailsStyles } from "./WorkorderDetailsStyles.data";

interface WorkorderDetailsProps {
  title: string;
  description: string;
  workOrderId: string;
  notes?: string;
  scopeOfWork?: string;
  workorderKeyNumber?: string;
}

const WorkorderDetails: FC<WorkorderDetailsProps> = ({
  title,
  workOrderId,
  description,
  notes,
  scopeOfWork,
  workorderKeyNumber,
}) => {
  const classes = WorkorderDetailsStyles();

  return (
    <Grid container>
      <Grid item sm={12} xs={12}>
        <List className={classes.list}>
          <ListItem className={classes.workOrderDetailsListItem}>
            <Typography
              className={`${classes.title} ${classes.workOrderDetailsKey}`}
              variant="body2">
              {title}
            </Typography>
          </ListItem>
          <ListItem
            className={`${classes.workOrderDetailsListItem} ${classes.description}`}>
            <Typography variant="body2">{description}</Typography>
          </ListItem>

          <ListItem className={classes.workOrderDetailsListItem}>
            <Typography
              className={classes.workOrderDetailsKey}
              variant="body2">{`${LABELS.workorderId}:`}</Typography>
            <Typography variant="body2">{workOrderId}</Typography>
          </ListItem>

          {/* Key number */}
          {workorderKeyNumber && (
            <ListItem className={classes.workOrderDetailsListItem}>
              <Typography
                className={classes.workOrderDetailsKey}
                variant="body2">{`${LABELS.keyNumber}:`}</Typography>
              <Typography variant="body2">{workorderKeyNumber}</Typography>
            </ListItem>
          )}

          {/* Notes from PM in MR request */}
          {notes && (
            <ListItem className={classes.workOrderDetailsListItem}>
              <Typography
                className={classes.workOrderDetailsKey}
                variant="body2">{`${LABELS.notes}:`}</Typography>
              <Typography variant="body2">{notes}</Typography>
            </ListItem>
          )}

          {/* Scope of work as added in quote by trade user */}
          {scopeOfWork && (
            <ListItem className={classes.workOrderDetailsListItem}>
              <Typography
                className={classes.workOrderDetailsKey}
                variant="body2">{`${LABELS.scopeOfWork}:`}</Typography>
              <Typography variant="body2">{scopeOfWork}</Typography>
            </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default WorkorderDetails;
