import { color } from "../../theme/Theme";

export interface AddressPickerStyles {  
  readonly startAdornmentIcon: any;
  readonly outlined: any;
}

export const addressPickerStyles: AddressPickerStyles = {
  startAdornmentIcon: {
    margin: 10,
    marginRight: 20,
    opacity: ".6"
  },
  outlined: {
    border: `1px solid ${color.lightGrey}`,
    backgroundColor: color.light
  },
};
