import { makeStyles } from "@material-ui/core/styles";

export const HeaderStyles = makeStyles({
  wrapper: {
    height: "60px",
    backgroundImage: "linear-gradient(89deg, #2d1b49 56%, #412868 122%)",
  },

  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "19px"
  }
});
