import React, { FC } from "react";
import {
  withStyles,
  ListItemIcon,
  ListItem,
  ListItemText,
  Typography,
  Avatar,
  makeStyles,
  Box,
} from "@material-ui/core";
import theme, { color } from "../../theme/Theme";

interface Props {
  primary: string;
  secondary?: string;
  actionsSection?: JSX.Element;
  image?: any;
  secondaryData?: JSX.Element;
  greyBackground?: boolean;
  pointer?: boolean;
  avatarColor?: string;
}

const useStyles = makeStyles({
  detailsContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "nowrap",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  listItemRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  listItemRootGrey: {
    paddingLeft: 0,
    paddingRight: 0,
    background: color.secondary,
  },
  avatarRoot: {
    background: color.light,
    color: color.secondary500,
  },
  secondaryDataContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "1em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
    },
  },
});

const RequestIcon = withStyles({
  root: {
    minWidth: "40px",
    marginRight: "1em",
  },
})(ListItemIcon);

const DashboardListItem: FC<Props> = (props) => {
  const {
    image,
    secondary,
    primary,
    secondaryData,
    actionsSection,
    greyBackground,
    pointer,
    avatarColor,
  } = props;
  const classes = useStyles();

  return (
    <Box
      style={{ cursor: pointer ? "pointer" : "" }}
      width="100%"
      display="flex">
      <ListItem
        classes={{
          root: greyBackground
            ? classes.listItemRootGrey
            : classes.listItemRoot,
        }}
        key={name}>
        <RequestIcon>
          {image && typeof image === "string" && image.length > 2 ? (
            <Avatar classes={{ root: classes.avatarRoot }} src={image} />
          ) : (
            <Avatar
              classes={{
                root: typeof image === "string" ? "" : classes.avatarRoot,
              }}
              style={{ backgroundColor: avatarColor }}>
              {image}
            </Avatar>
          )}
        </RequestIcon>
        <div className={classes.detailsContainer}>
          <ListItemText
            primary={<Typography variant="button">{primary}</Typography>}
            secondary={
              secondary && (
                <Typography color="textSecondary" variant="body2">
                  {secondary}
                </Typography>
              )
            }
          />
          {secondaryData && (
            <div className={classes.secondaryDataContainer}>
              {secondaryData}
            </div>
          )}
        </div>
      </ListItem>
      {actionsSection ? <>{actionsSection}</> : null}
    </Box>
  );
};

export default DashboardListItem;
