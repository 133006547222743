import { takeEvery, put } from "redux-saga/effects";
import {
  TradeCategoriesAction,
  TradeCategoriesActions,
  TradeCategoriesActionTypes,
} from "../actions/TradeCategoriesActions";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import { GetTradeCategoriesResponse } from "../../services/tradeCategories/TradeCategories.data";
import TradeCategoriesService from "../../services/tradeCategories/TradeCategoriesService";
import UserTradeService from "../../services/userTrades/UserTradeService";
import {
  PostUserTradeCategoriesResponse,
  UserTradeDocumentsUploadData,
  UserTradeCategoriesResponse,
  PostUserTradeCategoriesRequestPayload,
  UserTradeDocumentUploadResponse,
  DeleteUserTradeCategoryRequest,
  DeleteUserTradeCategoryDocumentRequest,
} from "../../services/userTrades/UserTradeService.data";

export function* getTradeCategoriesWorker(
  action: TradeCategoriesAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const response: GetTradeCategoriesResponse = (yield TradeCategoriesService.getTradeCategories())!;
    yield put(TradeCategoriesActions.getTradeCategoriesSuccess(response));
  } catch (error) {
    yield put(TradeCategoriesActions.getTradeCategoriesError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postUserTradeCategoriesWorker(
  action: TradeCategoriesAction
): IterableIterator<any> {
  const userId = localStorage.getItem("userId")!;
  const { onSuccess } = action.data as PostUserTradeCategoriesRequestPayload;
  try {
    yield put(LoadingIndicatorActions.show());
    const response: PostUserTradeCategoriesResponse = (yield UserTradeService.postUserTradeCategories(
      userId.toString(),
      action.data as PostUserTradeCategoriesRequestPayload
    ))!;
    yield put(TradeCategoriesActions.postUserTradeCategoriesSuccess(response!));

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    yield put(TradeCategoriesActions.postUserTradeCategoriesError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postUserTradeDocumentsWorker(
  action: TradeCategoriesAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const response: UserTradeDocumentUploadResponse = (yield UserTradeService.postTradeDocs(
      action.data as UserTradeDocumentsUploadData
    ))!;
    const userTradeCategoryId = (action.data! as UserTradeDocumentsUploadData)
      .data.userTradeCategoryId;
    yield put(
      TradeCategoriesActions.postUserTradeDocumentSuccess(
        response!,
        userTradeCategoryId
      )
    );
  } catch (error) {
    yield put(TradeCategoriesActions.postUserTradeDocumentError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getUserTradeCategoriesWorker(
  action: TradeCategoriesAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const response: UserTradeCategoriesResponse = (yield UserTradeService.getUserTradeCategories())!;
    yield put(TradeCategoriesActions.getUserTradeCategoriesSuccess(response!));
  } catch (error) {
    yield put(TradeCategoriesActions.getUserTradeCategoriesError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* deleteUserTradeCategoryWorker(
  action: TradeCategoriesAction
): IterableIterator<any> {
  try {
    const { data } = action;
    yield put(LoadingIndicatorActions.show());
    yield UserTradeService.deleteUserTradeCategory(
      (data as DeleteUserTradeCategoryRequest).request
    )!;
    yield put(
      TradeCategoriesActions.deleteUserTradeCategorySuccess({
        tradeCategoryId: (data as DeleteUserTradeCategoryRequest).request
          .tradeCategoryId,
      })
    );
  } catch (error) {
    yield put(TradeCategoriesActions.deleteUserTradeCategoryError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* deleteUserTradeDocumentWorker(
  action: TradeCategoriesAction
): IterableIterator<any> {
  try {
    const {
      userTradeCategoryDocumentId,
      userTradeCategoryId,
    } = action.data as DeleteUserTradeCategoryDocumentRequest;
    yield put(LoadingIndicatorActions.show());
    yield UserTradeService.deleteUserTradeDocument({
      userTradeCategoryDocumentId,
      userTradeCategoryId,
    })!;
    yield put(
      TradeCategoriesActions.deleteUserTradeCategoryDocumentSuccess({
        userTradeCategoryDocumentId,
        userTradeCategoryId
      })
    );
  } catch (error) {
    yield put(TradeCategoriesActions.deleteUserTradeCategoryDocumentError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const tradeCategoriesWatcher = [
  takeEvery(TradeCategoriesActionTypes.GET_CATEGORIES_REQUEST, (action) =>
    getTradeCategoriesWorker({
      type: action.type,
      data: (action as TradeCategoriesAction).data,
    })
  ),
  takeEvery(
    TradeCategoriesActionTypes.POST_USER_TRADE_CATEGORIES_REQUEST,
    (action) =>
      postUserTradeCategoriesWorker({
        type: action.type,
        data: (action as TradeCategoriesAction).data,
      })
  ),
  takeEvery(
    TradeCategoriesActionTypes.POST_USER_TRADE_DOCUMENT_REQUEST,
    (action) =>
      postUserTradeDocumentsWorker({
        type: action.type,
        data: (action as TradeCategoriesAction).data,
      })
  ),

  takeEvery(
    TradeCategoriesActionTypes.GET_USER_TRADE_CATEGORIES_REQUEST,
    (action) =>
      getUserTradeCategoriesWorker({
        type: action.type,
        data: (action as TradeCategoriesAction).data,
      })
  ),

  takeEvery(
    TradeCategoriesActionTypes.DELETE_USER_TRADE_CATEGORY_REQUEST,
    (action) =>
      deleteUserTradeCategoryWorker({
        type: action.type,
        data: (action as TradeCategoriesAction).data,
      })
  ),

  takeEvery(
    TradeCategoriesActionTypes.DELETE_USER_TRADE_DOCUMENT_REQUEST,
    (action) =>
      deleteUserTradeDocumentWorker({
        type: action.type,
        data: (action as TradeCategoriesAction).data,
      })
  ),
];

export default tradeCategoriesWatcher;
