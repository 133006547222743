import { makeStyles } from "@material-ui/core/styles";

export const SelectedRegionsStyles = makeStyles({
  container: {
    padding: "1.5em 0",
    marginLeft: "-.5em",
  },
  regionChip: {
    marginBottom: "1.125em",
    width: "100%",
    fontSize: "1em",
    fontWeight: 500,
    textOverflow: "ellipsis",
  },
  regionChipGridItem: {
    padding: "0 .5em",
  },
  listItemSecondaryAction: {
    display: "none",
  },
  listItem: {
    "&:hover $listItemSecondaryAction": {
      display: "block",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  pointer: {
    cursor: "pointer",
  },
});
