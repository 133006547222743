import { MaskDetails } from "../../../src/component/addPaymentMethod/AddPaymentMethodConstants.data";
import {
  BankAccountRequest,
  CreditCardRequest,
} from "../../../src/models/payment/PaymentRequest.data";
import {
  GetPaymentAuthKey,
  GetPaymentMethodsResponse,
  PayIdPayLoad,
  PostPaymentToken,
  PostPaymentTokenResponse,
} from "../../../src/services/payment/PaymentService.data";

export enum PaymentActionTypes {
  GetPaymentMethodsStart = "GET_SAVED_PAYMENT_METHODS_START",
  GetPaymentMethodsSuccess = "GET_SAVED_PAYMENT_METHODS_SUCCESS",
  GetPaymentMethodsError = "GET_SAVED_PAYMENT_METHODS_ERROR",
  SaveSelectedPaymentMethod = "SAVE_SELECTED_PAYMENT_METHOD",
  GetPaymentAuthKeyStart = "GET_PAYMENT_AUTH_KEY_START",
  GetPaymentAuthKeySuccess = "GET_PAYMENT_AUTH_KEY_SUCCESS",
  GetPaymentAuthKeyError = "GET_PAYMENT_AUTH_KEY_ERROR",
  PostPaymentTokenStart = "POST_PAYMENT_TOKEN_START",
  PostPaymentTokenSuccess = "POST_PAYMENT_TOKEN_SUCCESS",
  PostPaymentTokenSuccessReset = "POST_PAYMENT_TOKEN_SUCCESS_RESET",
  PostPaymentTokenError = "POST_PAYMENT_TOKEN_ERROR",
  DeletePaymentStart = "DELETE_PAYMENT_START",
  DeletePaymentSuccess = "DELETE_PAYMENT_SUCCESS",
  DeletePaymentError = "DELETE_PAYMENT_ERROR",
}

export interface PaymentErrorPayload {
  readonly error: Error | any;
}

export interface PaymentAction {
  type: PaymentActionTypes;
  data?:
    | SaveSelectedPaymentMethodPayload
    | GetPaymentAuthKeyRequest
    | PostPaymentRequest
    | PaymentErrorPayload
    | GetPaymentAuthKey
    | PostPaymentTokenResponse
    | PayIdPayLoad
    | GetPaymentMethodsPayLoad
    | GetPaymentMethodsResponse[]
    | DeletePaymentRequest
    | string[]
    | Error;
}

export interface DeletePaymentRequest {
  paymentRefId: string;
  onSuccess: (response: string[]) => void;
}

export interface GetPaymentMethodsPayLoad {
  tradeId: number;
  onSuccess?: () => void;
}

export interface GetPaymentAuthKeyRequest {
  data: CreditCardRequest | BankAccountRequest;
  providerId?: string;
  onSuccess: (token: string) => void;
  onError: (error: PaymentErrorPayload) => void;
}

export interface PostPaymentRequest {
  data: PostPaymentToken;
  onSuccess?: (response: GetPaymentMethodsResponse) => void;
}

export interface SaveSelectedPaymentMethodPayload {
  maskedDetails: MaskDetails;
  paymentMethodId: string;
}

export class PaymentActions {
  public static saveSelectedPaymentMethod(
    data: SaveSelectedPaymentMethodPayload
  ): PaymentAction {
    return {
      type: PaymentActionTypes.SaveSelectedPaymentMethod,
      data,
    };
  }

  public static getPaymentAuthKeyStart(
    data: GetPaymentAuthKeyRequest
  ): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentAuthKeyStart,
      data,
    };
  }

  public static getPaymentAuthKeySuccess(
    data: GetPaymentAuthKey
  ): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentAuthKeySuccess,
      data,
    };
  }

  public static getPaymentAuthKeyError(
    error: PaymentErrorPayload
  ): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentAuthKeyError,
      data: error,
    };
  }

  public static postPaymentTokenStart(data: PostPaymentRequest): PaymentAction {
    return {
      type: PaymentActionTypes.PostPaymentTokenStart,
      data,
    };
  }

  public static postPaymentTokenSuccess(
    data: PostPaymentTokenResponse
  ): PaymentAction {
    return {
      type: PaymentActionTypes.PostPaymentTokenSuccess,
      data,
    };
  }

  public static postPaymentTokenSuccessReset(): PaymentAction {
    return {
      type: PaymentActionTypes.PostPaymentTokenSuccessReset,
    };
  }

  public static postPaymentTokenError(
    error: PaymentErrorPayload
  ): PaymentAction {
    return {
      type: PaymentActionTypes.PostPaymentTokenError,
      data: error,
    };
  }

  public static getPaymentMethodsStart(
    data: GetPaymentMethodsPayLoad
  ): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentMethodsStart,
      data,
    };
  }

  public static getPaymentMethodsSuccess(
    data: GetPaymentMethodsResponse[]
  ): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentMethodsSuccess,
      data,
    };
  }

  public static getPaymentMethodsError(
    error: PaymentErrorPayload
  ): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentMethodsError,
      data: error,
    };
  }

  public static deletePaymentStart(data: DeletePaymentRequest): PaymentAction {
    return {
      type: PaymentActionTypes.DeletePaymentStart,
      data,
    };
  }
  public static deletePaymentSuccess(data: string[]): PaymentAction {
    return {
      type: PaymentActionTypes.DeletePaymentSuccess,
      data,
    };
  }
  public static deletePaymentError(data: PaymentErrorPayload): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentMethodsError,
      data,
    };
  }
}
