import { makeStyles } from "@material-ui/core";
import { color } from "../../../../theme/Theme";

export const JobCardStyles = makeStyles({
  listRoot: {
    padding: "0",
  },
  tradeIconContainer: {
    opacity: ".3",
  },
  cards: {
    margin: "10px 0px",
  },
  confirmBookingButton: {
    borderRadius: "4px",
    color: color.teal,
    padding: ".8em",
  },
  confirmBookingButtonContainer: {
    marginRight: "21px",
  },
});
