export const LABELS = {
  welcomeBack: (name: string) => `Welcome back ${name}!`,
  monthJobCount: (count) => `You have done ${count} jobs this month!`,
  activity: "Activity",
  latestJob: "Latest job",
  latestReviews: "Latest reviews",
  // counter labels
  bookingRequired: "Booking required",
  pendingApproval: "Pending approval",
  quoteRequests: "Quote requests",
  submittedQuotes: "Submitted quotes",
  // upcoming jobs
  yourScheduledJobs: "Your scheduled jobs",
  // section headings
  completedJobs: "Jobs completed this month",
  yourTeam: "Your team",
  locationNotAvailable: "Sorry, location is not available for this property",
};
