import React, { FC, useState } from "react";
import { Property } from "../../../../models/property/Property.data";
import PropertyListItem from "../../../lisItems/PropertyListItem";
import {
  Box,
  Typography,
  Divider,
  List,
  Button,
  withStyles,
} from "@material-ui/core";
import DashboardSection from "../../dashboardSection/DashboardSection";
import {
  Add,
  PanToolOutlined,
  DescriptionOutlined,
  CreateOutlined,
} from "@material-ui/icons";
import { LABELS } from "./QuoteDetailsSidebarConstants.data";
import DashboardListItem from "../../../lisItems/DashboardListItem";
import { Quote } from "../../../../models/quote/Quote.data";
import DetailListItem from "./DetailListItem";
import { avatarColors, color } from "../../../../theme/Theme";
import {
  getAddressString,
  getDate,
  getFirstCharactersFromFirstTwoWords,
  titleCase,
  getContactDetails,
} from "../../../../helper/HelperFunctions";
// import TradeCategoryIcon from "../../../tradeCategoryIcon/TradeCategoryIcon";
import { QuoteStatus } from "../../../../models/quoteStatus/QuoteStatus.data";
import { MOMENT_DATE_FORMATS } from "../../../../constants/AppConstants";
import ImageGrid from "../../../docGrid/DocGrid";
import ContactCard from "../../../contactCard/ContactCard";
import { quoteDetailsSidebarStyles } from "./QuoteDetailsSidebarStyles.data";
import QuoteAdditionalDetails from "../../quoteAdditionalDetails/QuoteAdditionalDetails";
import { routes } from "../../../../Routes";
import { Link, useLocation } from "react-router-dom";
import { LocalMarketplaceSummary } from "../../../../models/maintenance-request/MaintenanceRequest.data";
import { extractLocalMarketplaceImages } from "./QuoteDetailsSidebarUtils";

interface QuoteDetailsSidebarProps {
  quote: Quote;
  property: Property;
  onPassQuote: (quoteId: string) => void;
}

const QuoteSidebarActionButton = withStyles({
  root: {
    borderRadius: "4px",
    padding: ".75em 1em",
    color: color.light,
    width: "100%",
  },
})(Button);

// tslint:disable-next-line:cyclomatic-complexity
const QuoteDetailsSideBar: FC<QuoteDetailsSidebarProps> = (props) => {
  const { property, quote, onPassQuote } = props;

  // hooks
  const classes = quoteDetailsSidebarStyles();
  const location = useLocation();

  // state
  // show/hide quote button based on whether submitQuote page is already open
  // PS: "submitQuote" will need to be changed if the route changes for this to work properly
  const [showAddQuoteButton] = useState<boolean>(
    !location.pathname.includes("submitQuote")
  );

  // variables and functions
  const {
    id: quoteId,
    status,
    maintenanceRequestResponse: {
      details: {
        title,
        description: quoteDescription,
        images,
        appliance,
        extendedData,
      },
      audit: { createdDate },
      workOrders,
    },
    workOrderId,
  } = quote;

  const summaryData: LocalMarketplaceSummary[] =
    extendedData && extendedData["featureDetails"]
      ? (extendedData["featureDetails"] as LocalMarketplaceSummary[]).filter(
          (data) => data.type === "answers"
        )
      : [];

  const localmarketplaceImages = extractLocalMarketplaceImages(summaryData);

  const {
    state,
    suburb,
    postcode,
    address,
    managingAgent,
    branch: { name: agencyName, suburb: agencySuburb },
    tenants: { allAssignedTenants },
  } = property;

  const workOrder = workOrders.find(
    (obj) => obj.referenceNumber === workOrderId
  )!;

  const { jobDueDate, quoteDueDate, tenants, alternateContact } = workOrder;

  const additionalContactList = getContactDetails(
    tenants || [],
    allAssignedTenants
  );

  const isPrivate =
    quote.maintenanceRequestResponse.privateWorksDetails &&
    quote.maintenanceRequestResponse.privateWorksDetails.isPrivate;

  return (
    <Box pt={2} pb={10}>
      <DashboardSection>
        <PropertyListItem
          name={address ? getAddressString(property) : LABELS.addressOnApproval}
          location={`${suburb && titleCase(suburb)}${suburb && ","} ${state}${
            state && ","
          } ${postcode}`}
          noPadding
          map={!!address}
        />
      </DashboardSection>

      <Box mt={8}>
        <DashboardSection paperClass={classes.paperClass}>
          <Box px={2}>
            <Box mb={2}>
              <Typography variant="h4">{LABELS.contactDetails}</Typography>
            </Box>
            <Divider />
            <Box py={2}>
              <Box>
                <DashboardListItem
                  image={getFirstCharactersFromFirstTwoWords(agencyName)}
                  primary={agencyName}
                  secondary={agencySuburb}
                  avatarColor={avatarColors.purple}
                />
              </Box>

              {/* Managing agent */}
              {managingAgent && !isPrivate && (
                <Box mb={2}>
                  <ContactCard
                    underlined
                    disableSummaryPadding
                    noElevation
                    collapsible
                    avatarColor={avatarColors.blue}
                    designation={"Property manager"}
                    person={managingAgent}
                    detailsListItemClass={classes.contactCardDetailsListItem}
                  />
                </Box>
              )}

              {additionalContactList.length > 0
                ? additionalContactList.map((contact) => (
                    <Box mb={2}>
                      <ContactCard
                        underlined
                        disableSummaryPadding
                        noElevation
                        collapsible
                        designation={"Contact"}
                        person={contact}
                        avatarColor={avatarColors.blue}
                        detailsListItemClass={
                          classes.contactCardDetailsListItem
                        }
                      />
                    </Box>
                  ))
                : null}

              {alternateContact ? (
                <Box mb={2}>
                  <ContactCard
                    underlined
                    disableSummaryPadding
                    noElevation
                    collapsible
                    designation="Alternate contact"
                    person={{
                      name: alternateContact.name,
                      phone: alternateContact.contact,
                      email: alternateContact.email || "N/A",
                      surname: "",
                    }}
                    avatarColor={avatarColors.blue}
                    detailsListItemClass={classes.contactCardDetailsListItem}
                  />
                </Box>
              ) : null}
            </Box>
            {/* Request details */}
            <Box mb={2}>
              <Typography variant="h4">
                {isPrivate ? LABELS.bookingDetails : LABELS.requestDetails}
              </Typography>
            </Box>
            <Divider />
            <Box mt={2}>
              <DashboardListItem
                image={<DescriptionOutlined />}
                primary={title}
                secondary={quoteDescription}
              />
            </Box>

            <Box mt={2}>
              {localmarketplaceImages.length ? (
                <ImageGrid docs={localmarketplaceImages} />
              ) : (
                !!images.length && <ImageGrid docs={images} />
              )}
            </Box>

            {/* Request details */}
            <Box my={2}>
              <List>
                <DetailListItem
                  primary={LABELS.applianceRelated}
                  secondary={appliance && appliance.present ? "Yes" : "No"}
                />
                {appliance && appliance.present && (
                  <>
                    <DetailListItem
                      primary={LABELS.make}
                      secondary={appliance.make}
                    />
                    <DetailListItem
                      primary={LABELS.model}
                      secondary={appliance.model}
                    />
                    <DetailListItem
                      primary={LABELS.warrantyAvailable}
                      secondary={appliance.warranty ? "Yes" : "No"}
                    />
                  </>
                )}
              </List>
            </Box>
          </Box>
          {/* Additional Details */}
          <QuoteAdditionalDetails
            maintenanceRequest={quote.maintenanceRequestResponse}
            workorderDetails={workOrder}
          />

          {/* Dates */}
          <Box p={2} pb={0}>
            <DetailListItem
              primary={LABELS.requested}
              secondary={getDate(createdDate, MOMENT_DATE_FORMATS.DD_MM_YYYY)}
            />
            <DetailListItem
              primary={LABELS.quoteDueDate}
              secondary={getDate(quoteDueDate, MOMENT_DATE_FORMATS.DD_MM_YYYY)}
            />
            <DetailListItem
              primary={LABELS.jobDueDate}
              secondary={getDate(jobDueDate, MOMENT_DATE_FORMATS.DD_MM_YYYY)}
            />
          </Box>

          {/* Buttons */}
          {(status === QuoteStatus.Pending || status === QuoteStatus.Sent) && (
            <Box
              px={2}
              display="flex"
              justifyContent={showAddQuoteButton ? "space-between" : "center"}
              mt={4}>
              {status === QuoteStatus.Pending && (
                <Box flexBasis={showAddQuoteButton ? "45%" : "100%"}>
                  <QuoteSidebarActionButton
                    onClick={() => onPassQuote(quoteId)}
                    variant="outlined"
                    endIcon={<PanToolOutlined color="secondary" />}>
                    <Typography variant="caption">{LABELS.pass}</Typography>
                  </QuoteSidebarActionButton>
                </Box>
              )}
              {showAddQuoteButton && (
                <Box
                  flexBasis={status === QuoteStatus.Pending ? "45%" : "100%"}>
                  <Link
                    to={{
                      pathname: routes.dashboard.submitQuote(quoteId),
                      state: { quote, property },
                    }}>
                    <QuoteSidebarActionButton
                      className={classes.quoteActionButton}
                      endIcon={
                        status === QuoteStatus.Pending ? (
                          <Add />
                        ) : (
                          <CreateOutlined />
                        )
                      }>
                      <Typography color="inherit" variant="caption">
                        {status === QuoteStatus.Pending
                          ? LABELS.quote
                          : LABELS.edit}
                      </Typography>
                    </QuoteSidebarActionButton>
                  </Link>
                </Box>
              )}
            </Box>
          )}
        </DashboardSection>
      </Box>
    </Box>
  );
};

export default QuoteDetailsSideBar;
