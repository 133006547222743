import { makeStyles } from "@material-ui/core";
import { color } from "../../../../theme/Theme";

export const jobDetailsSidebarStyles = makeStyles({
  paperClass: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  imageWrapper: {
    cursor: "pointer",
    display: "inline-block",
  },
  contactCardDetailsListItem: {
    // Important is required because MUI's ListItem implementation overrides the properties
    paddingLeft: "8px !important",
  },
  additionalDetailsPanelSummary: {
    background: color.wildSand,
  },
  additionalDetails: {
    background: color.light,
  },
  actionButtonsContainer: {
    "& > * + *": {
      marginTop: "1em",
    },
  },
});
