import React, { FC, useEffect, useState } from "react";
import { Property } from "../../../../models/property/Property.data";
import PropertyListItem from "../../../lisItems/PropertyListItem";
import {
  Box,
  Typography,
  Divider,
  Button,
  withStyles,
  List,
} from "@material-ui/core";
import DashboardSection from "../../dashboardSection/DashboardSection";
import { LABELS } from "./JobDetailsSidebarConstants.data";
import DetailListItem from "../../quotes/quoteDetailsSidebar/DetailListItem";
import { avatarColors, color } from "../../../../theme/Theme";
import {
  titleCase,
  getFirstCharactersFromFirstTwoWords,
  getDate,
  getAddressString,
  getContactDetails,
  getContactDetailsFromOpenRequestUserDetails,
} from "../../../../helper/HelperFunctions";
import { JobStatus } from "../../../../models/jobs/JobData";
import { useHistory } from "react-router";
import { routes } from "../../../../Routes";
import { MOMENT_DATE_FORMATS } from "../../../../constants/AppConstants";
import { Job } from "../../../../services/jobs/JobsData.data";
import DashboardListItem from "../../../lisItems/DashboardListItem";
import ContactCard from "../../../contactCard/ContactCard";
import { DescriptionOutlined } from "@material-ui/icons";
import ImageGrid from "../../../docGrid/DocGrid";
import QuoteAdditionalDetails from "../../quoteAdditionalDetails/QuoteAdditionalDetails";
import { jobDetailsSidebarStyles } from "./JobDetailsSidebarStyles.data";
import ConfirmJobCancelDialog from "./confirmJobCancel/ConfirmJobCancelDialog";
import { useDispatch } from "react-redux";
import { JobsActions } from "../../../../store/actions/JobsActions";
import { useAppSelector } from "../../../../store/RootReducers";

interface Props {
  job: Job;
  property: Property;
  hideButton?: boolean;
}

const JobSidebarActionButton = withStyles({
  root: {
    borderRadius: "4px",
    padding: ".75em 1em",
    color: `${color.light} !important`,
    width: "100%",
  },
})(Button);

// tslint:disable-next-line: cyclomatic-complexity
const JobDetailsSideBar: FC<Props> = (props) => {
  // state
  const [showCancelJobDialog, setShowCancelJobDialog] =
    useState<boolean>(false);

  // other hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useAppSelector((state) => state.jobs);

  // variables and functions
  const {
    property,
    job: {
      id,
      status,
      quote: {
        maintenanceRequestResponse: {
          details: maintenanceRequestDetails,
          audit: { createdDate },
          workOrders,
          openRequestDetail,
          privateWorksDetails,
        },
        workOrderId,
      },
      parentId,
    },
    hideButton,
  } = props;

  const {
    state,
    suburb,
    postcode,
    photos,
    managingAgent,
    branch: { name: agencyName, suburb: agencySuburb },
    tenants: { allAssignedTenants },
  } = property;

  const {
    title,
    description: quoteDescription,
    images,
    appliance,
  } = maintenanceRequestDetails;

  const classes = jobDetailsSidebarStyles();

  // get workorder
  const workOrder = workOrders.find(
    (obj) => obj.referenceNumber === workOrderId
  )!;
  const { jobDueDate, quoteDueDate, tenants, alternateContact } = workOrder;

  const additionalContactList = getContactDetails(
    tenants || [],
    allAssignedTenants
  );

  // dispatcher
  const dispatcher = {
    cancelJob: (notes: string) => {
      dispatch(
        JobsActions.ChangeJobStatusRequest({
          id,
          status: JobStatus.TradeCancellation,
          notes,
        })
      );
    },
  };

  // effects
  useEffect(() => {
    if (!loading && showCancelJobDialog) {
      setShowCancelJobDialog(false);
    }
  }, [loading]);

  const getAttentionMessage = (status: JobStatus | string) => {
    const isPrivate = privateWorksDetails && privateWorksDetails.isPrivate;
    if (status === JobStatus.BookingRequired) {
      return isPrivate
        ? LABELS.notBookedCancelWarningCustomer
        : LABELS.notBookedCancelWarning;
    } else {
      return isPrivate
        ? LABELS.bookedCancelWarningCustomer
        : LABELS.bookedCancelWarning;
    }
  };

  const isPrivate = privateWorksDetails && privateWorksDetails.isPrivate;

  return (
    <Box pt={0} pb={10}>
      {showCancelJobDialog && (
        <ConfirmJobCancelDialog
          job={props.job}
          onSubmit={(notes: string) => {
            dispatcher.cancelJob(notes);
          }}
          onCancel={() => {
            setShowCancelJobDialog(false);
          }}
          attentionMessage={getAttentionMessage(status)}
        />
      )}
      <DashboardSection>
        <PropertyListItem
          image={(photos && photos[0]) || undefined}
          name={getAddressString(property)}
          location={`${suburb && titleCase(suburb)}${suburb && ","} ${state}${
            state && ","
          } ${postcode}`}
          noPadding
          map
        />
      </DashboardSection>

      <Box mt={8}>
        <DashboardSection paperClass={classes.paperClass}>
          <Box px={2}>
            <Box mb={2}>
              <Typography variant="h4">{LABELS.contactDetails}</Typography>
            </Box>
            <Divider />
            <Box py={2}>
              <Box>
                <DashboardListItem
                  image={getFirstCharactersFromFirstTwoWords(agencyName)}
                  primary={agencyName}
                  secondary={agencySuburb}
                  avatarColor={avatarColors.purple}
                />
              </Box>

              {/* Managing agent */}
              {managingAgent && !isPrivate && (
                <Box mb={2}>
                  <ContactCard
                    underlined
                    disableSummaryPadding
                    noElevation
                    collapsible
                    person={managingAgent}
                    designation={"Property manager"}
                    avatarColor={avatarColors.blue}
                    detailsListItemClass={classes.contactCardDetailsListItem}
                  />
                </Box>
              )}

              {additionalContactList.length > -1
                ? additionalContactList.map((contact) => (
                    <Box mb={2}>
                      <ContactCard
                        underlined
                        disableSummaryPadding
                        noElevation
                        collapsible
                        designation={"Contact"}
                        person={contact}
                        avatarColor={avatarColors.blue}
                        detailsListItemClass={
                          classes.contactCardDetailsListItem
                        }
                      />
                    </Box>
                  ))
                : null}

              {alternateContact ? (
                <Box mb={2}>
                  <ContactCard
                    underlined
                    disableSummaryPadding
                    noElevation
                    collapsible
                    designation="Alternate contact"
                    person={{
                      name: alternateContact.name,
                      phone: alternateContact.contact,
                      email: alternateContact.email || "N/A",
                      surname: "",
                    }}
                    avatarColor={avatarColors.blue}
                    detailsListItemClass={classes.contactCardDetailsListItem}
                  />
                </Box>
              ) : null}

              {openRequestDetail && (
                <ContactCard
                  underlined
                  disableSummaryPadding
                  noElevation
                  collapsible
                  avatarColor={avatarColors.orange}
                  designation="Tenant"
                  person={getContactDetailsFromOpenRequestUserDetails(
                    openRequestDetail.requestingUser
                  )}
                  detailsListItemClass={classes.contactCardDetailsListItem}
                />
              )}
            </Box>

            {/* Request details */}
            <Box mb={2}>
              <Typography variant="h4">
                {isPrivate ? LABELS.bookingDetails : LABELS.requestDetails}
              </Typography>
            </Box>
            <Divider />
            <Box mt={2}>
              <DashboardListItem
                image={<DescriptionOutlined />}
                primary={title}
                secondary={quoteDescription}
              />
            </Box>

            <Box mt={2}>{!!images.length && <ImageGrid docs={images} />}</Box>

            {/* Request details */}
            <Box my={2}>
              <List>
                <DetailListItem
                  primary={LABELS.applianceRelated}
                  secondary={appliance && appliance.present ? "Yes" : "No"}
                />
                {appliance && appliance.present && (
                  <>
                    <DetailListItem
                      primary={LABELS.make}
                      secondary={appliance.make}
                    />
                    <DetailListItem
                      primary={LABELS.model}
                      secondary={appliance.model}
                    />
                    <DetailListItem
                      primary={LABELS.warrantyAvailable}
                      secondary={appliance.warranty ? "Yes" : "No"}
                    />
                  </>
                )}
              </List>
            </Box>
          </Box>
          {/* Additional Details */}
          <QuoteAdditionalDetails
            maintenanceRequest={props.job.quote.maintenanceRequestResponse}
            workorderDetails={workOrder}
          />

          {/* Dates */}
          <Box p={2} pb={0}>
            <DetailListItem
              primary={LABELS.requested}
              secondary={getDate(createdDate, MOMENT_DATE_FORMATS.DD_MM_YYYY)}
            />
            <DetailListItem
              primary={LABELS.quoteDueDate}
              secondary={getDate(quoteDueDate, MOMENT_DATE_FORMATS.DD_MM_YYYY)}
            />
            <DetailListItem
              primary={LABELS.jobDueDate}
              secondary={getDate(jobDueDate, MOMENT_DATE_FORMATS.DD_MM_YYYY)}
            />
          </Box>

          <Box display="flex" justifyContent="center" mt={3} mb={3} px={2}>
            <Box className={classes.actionButtonsContainer} flexBasis="100%">
              {!hideButton && (
                <JobSidebarActionButton
                  variant="contained"
                  onClick={() => {
                    parentId
                      ? history.push(
                          routes.dashboard.viewVariationJob(parentId, id)
                        )
                      : history.push(routes.dashboard.viewJob(id));
                  }}>
                  {status === JobStatus.BookingRequired
                    ? LABELS.confirmBooking
                    : LABELS.viewJob}
                </JobSidebarActionButton>
              )}

              {[JobStatus.BookingRequired, JobStatus.Scheduled].includes(
                status as JobStatus
              ) && (
                <JobSidebarActionButton
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setShowCancelJobDialog(true);
                  }}>
                  {LABELS.cancelJob}
                </JobSidebarActionButton>
              )}
            </Box>
          </Box>
        </DashboardSection>
      </Box>
    </Box>
  );
};

export default JobDetailsSideBar;
