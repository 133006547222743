import { BUSINESS_TYPES} from "../component/registrationForm/RegistrationFormData.data";
import { Branch } from "src/container/listBranches/ListBranches.data";
import { BranchResponse } from "src/services/branch/Branch.data";

export function getBusinessType(index: number) {
  return BUSINESS_TYPES.find(it => it.value === index);
}

export const getAddress = (branch: Branch | BranchResponse) => {
  return `${branch.streetNumber} ${branch.streetName}, ${branch.suburb}, ${branch.state} ${branch.postcode}`.replace(/null/g, "").replace(", ,", ",")
}

export enum ROLE_TYPES {
  trade_admin = "Trade admin",
  trade_staff = "Trade staff",
  trade_employee = "Trade employee"
}

export function getRole(key: string) {
  return ROLE_TYPES[key]
}