import { ListItemText, Box } from "@material-ui/core";
import React, { FC } from "react";
import { isNull } from "lodash";
import StyledExpansionPanel from "../../styledExpansionPanel/StyledExpansionPanel";
import { LABELS } from "./QuoteAdditionalDetailsConstants.data";
import {
  MaintenanceRequest,
  PROPERTY_ACCESS_LABELS,
} from "../../../models/maintenance-request/MaintenanceRequest.data";
import { quoteAdditionalDetailsStyles } from "./QuoteAdditionalDetailsStyles.data";
import DetailListItem from "../quotes/quoteDetailsSidebar/DetailListItem";
import { titleCase } from "../../../helper/HelperFunctions";
import { WorkOrder } from "../../../models/workOrder/Workorder.data";

interface QuoteAdditionalDetailsProps {
  maintenanceRequest: MaintenanceRequest;
  workorderDetails: WorkOrder;
}

// tslint:disable-next-line:cyclomatic-complexity
const QuoteAdditionalDetails: FC<QuoteAdditionalDetailsProps> = ({
  maintenanceRequest,
  workorderDetails,
}) => {
  // hooks
  const classes = quoteAdditionalDetailsStyles();

  // variables and functions
  const { privateWorksDetails, details } = maintenanceRequest;
  const {
    preferredAvailability,
    unSupervisedMinors,
    pet,
    clearAccess,
    stairs,
    parkingAvailability,
    extendedData,
  } = details;

  const { propertyAccess } = workorderDetails;

  return (
    <StyledExpansionPanel
      noElevation
      underlined
      summaryContent={<ListItemText primary={LABELS.additionalDetails} />}>
      <Box className={classes.expansionPanelContent} p={2} pb={0}>
        {!isNull(propertyAccess) && !privateWorksDetails?.privateName && (
          <>
            <DetailListItem
              primary={LABELS.propertyAccess}
              secondary={
                propertyAccess.propertyAccessType.toUpperCase() ===
                PROPERTY_ACCESS_LABELS.OTHER.toUpperCase()
                  ? workorderDetails.comments
                  : PROPERTY_ACCESS_LABELS[propertyAccess.propertyAccessType]
              }
            />
            {propertyAccess.propertyAccessType === "COLLECT_KEY" &&
              propertyAccess.accessKeyNumber && (
                <DetailListItem
                  primary={LABELS.keyNumber}
                  secondary={propertyAccess.accessKeyNumber!}
                />
              )}
          </>
        )}

        {!isNull(preferredAvailability) && (
          <>
            <DetailListItem
              primary={LABELS.preferredTime}
              secondary={`${preferredAvailability.timeSlot}${
                preferredAvailability.otherTime
                  ? `(${preferredAvailability.otherTime})`
                  : ""
              }`}
            />
            {preferredAvailability.days.length > 1 && (
              <DetailListItem
                primary={LABELS.preferredDays}
                secondary={preferredAvailability.days
                  .map((day) => titleCase(day).substring(0, 3))
                  .join(", ")}
              />
            )}
          </>
        )}

        <DetailListItem
          primary={LABELS.noUnaccompaniedMinors}
          secondary={unSupervisedMinors ? "Confirmed" : "Not confirmed"}
        />

        <DetailListItem
          primary={LABELS.pet}
          secondary={
            isNull(pet) ? "No" : pet!.present ? pet!.description || "Yes" : "No"
          }
        />

        {!isNull(clearAccess) && (
          <DetailListItem
            primary={LABELS.clearAccess}
            secondary={clearAccess ? "Yes" : "No"}
          />
        )}

        {!isNull(stairs) && (
          <DetailListItem
            primary={LABELS.stairsAccess}
            secondary={stairs.present ? stairs.description || "Yes" : "No"}
          />
        )}

        <DetailListItem
          primary={LABELS.parkingOnSite}
          secondary={parkingAvailability ? "Yes" : "No"}
        />

        {privateWorksDetails?.privateName && (
          <>
            <DetailListItem
              primary={LABELS.ageOfHouse}
              secondary={extendedData?.["ageOfHouse"] || "Not confirmed"}
            />
            <DetailListItem
              primary={LABELS.typeOfRoof}
              secondary={extendedData?.["typeOfRoof"] || "Not confirmed"}
            />
            <DetailListItem
              primary={LABELS.typeOfWalls}
              secondary={extendedData?.["typeOfWalls"] || "Not confirmed"}
            />
          </>
        )}
      </Box>
    </StyledExpansionPanel>
  );
};

export default QuoteAdditionalDetails;
