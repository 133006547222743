import { makeStyles } from "@material-ui/core";

export const WorkorderDetailsStyles = makeStyles({
  workOrderDetailsKey: {
    fontFamily: "GT Walsheim",
    marginRight: "1em",
  },
  workOrderDetailsListItem: {
    padding: "0",
  },
  title: {
    fontSize: "18px",
  },
  description: {
    marginBottom: "1em",
  },
  list: {
    padding: "8px",
    paddingLeft: "0",
  },
});
