import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import { ServiceType } from "../ServiceType.data";
import { LoginImpersonatePayload, LoginResponse } from "./LoginData";

export class LoginImpersonateService {
  private readonly serviceType = ServiceType.Auth;
  private readonly service: ApiServiceBase = new ApiService(this.serviceType);

  public postLogin(
    payload: LoginImpersonatePayload
  ): Promise<LoginResponse> | LoginResponse {
    return this.service.post(
      { route: ["user-impersonation", "confirm"] },
      payload
    );
  }
}

export default new LoginImpersonateService();
