import { QueryPath } from "../QueryPath.data";
import { MultipartFormData } from "../MultipartFormData.data";

/// <summary>
/// Base class for service mocks. Default implementations do nothing, just returns success and 'null' result.
/// We return T instead of Promise<T> otherwise we can't wait for success in out tests.
/// </summary>
export class ApiServiceMockBase {

    public get<T = void>(path: QueryPath): T {
        throw Error("ApiServiceMockBase.get: Not implemented");
    }

    public post<T = void>(path: QueryPath, body: any): T {
        throw Error("ApiServiceMockBase.post: Not implemented");
    }

    public put<T = void>(path: QueryPath, body: any): T {
        throw Error("ApiServiceMockBase.put: Not implemented");
    }

    public patch<T = void>(path: QueryPath, body: any): T {
        throw Error("ApiServiceMockBase.put: Not implemented");
    }

    public delete<T = void>(path: QueryPath): T {
        throw Error("ApiServiceMockBase.delete: Not implemented");
    }

    public postMultipart<T =void>(path: QueryPath, data: MultipartFormData[]): T {
        throw Error("ApiServiceMockBase.postMultipart: Not implemented");
    }

    public putMultipart<T =void>(path: QueryPath, data: MultipartFormData[]): T {
        throw Error("ApiServiceMockBase.putMultipart: Not implemented");
    }
}
