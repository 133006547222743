import { WorkOrder } from "../workOrder/Workorder.data";
import { QuoteImage } from "../quote/Quote.data";
import { S3File } from "../file/S3File";

export interface PropertyAccess {
  description: string;
  propertyAccessType: "COLLECT_KEY" | "TENANT_AVAILABLE" | "OTHER";
  accessKeyNumber?: string;
}

export const PROPERTY_ACCESS_LABELS = {
  COLLECT_KEY: "Collect key from property manager",
  TENANT_AVAILABLE: "Contact the tenant",
  OTHER: "Other",
  OWNER_OCCUPIED: "Owner occupied",
};

export interface MaintenanceRequestDetails {
  title: string;
  clearAccess: boolean | null;
  description: string;
  images: S3File[];
  key: {
    description: string;
    present: boolean;
  };
  parkingAvailability: boolean | null;
  pet: {
    breed: string;
    present: boolean;
    description: string;
    size: string;
    type: string;
    images: QuoteImage[];
  } | null;
  appliance: {
    present: boolean;
    make: string;
    warranty: boolean;
    model: string;
  } | null;
  stairs: {
    present: boolean;
    description: string;
  } | null;
  preferredAvailability: {
    timeSlot: string;
    otherTime: string;
    days: string[];
  } | null;
  propertyAccess: PropertyAccess | null;
  sendEmailToPropertyManager: boolean;
  unSupervisedMinors: boolean;
  extendedData?: Map<string, string | LocalMarketplaceSummary[]>;
}

export interface LocalMarketplaceSummary {
  label: string;
  labelId: string;
  type: string;
  value: string;
  valueId: string;
}

export interface OpenRequestUserDetails {
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
}
export interface OpenRequestDetail {
  notes: string;
  requestingUser: OpenRequestUserDetails;
}

export enum PrivateWorkflow {
  PACKAGE = "PACKAGE",
  SOLAR = "SOLAR",
  ELECTRICITY = "ELECTRICITY",
  OTHER = "OTHER",
}

export interface PrivateWorksDetails {
  isPrivate: boolean;
  privateName?: string;
  workflowType: PrivateWorkflow;
  storeName?: string;
}
export interface MaintenanceRequest {
  agencyBranchId: number;
  agencyId: number;
  approved: boolean;
  id: string;
  propertyId: number;
  status: string;
  leaseId: number;
  workOrders: WorkOrder[];
  category: string;
  serviceType: string;
  audit: {
    createdBy: string;
    createdDate: string;
    modifiedBy: string;
    modifiedDate: string;
  };
  details: MaintenanceRequestDetails;
  tenantIds: number[];
  openRequestDetail: OpenRequestDetail | null;
  privateWorksDetails?: PrivateWorksDetails | null;
}
