import {
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Formik, Field } from "formik";
import React, { FC, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { routes } from "../../Routes";
import AuthPagesWrapper from "../authPagesWrapper/AuthPagesWrapper";
import { LABELS, VALIDATION_SCHEMA } from "./ForgotPasswordConstants";
import { ForgotPasswordPayload } from "../../services/login/LoginData";
import RenderInputField from "../FormikComponents/RenderInputField";
import { forgotPasswordStyles } from "./ForgotPasswordStyles";
import { CheckCircleOutline } from "@material-ui/icons";
import { color } from "../../theme/Theme";
import ErrorText from "../registrationStepsComponents/errorText/ErrorText";

interface ForgotPasswordComponentProps {
  postForgotPasswordRequest: (data: ForgotPasswordPayload) => void;
}

const ForgotPasswordComponent: FC<ForgotPasswordComponentProps> = ({
  postForgotPasswordRequest,
}) => {
  // state
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [linkSentTo, setLinkSentTo] = useState<string>("");

  // hooks
  const classes = forgotPasswordStyles();
  const history = useHistory();

  // functions and variables
  const initialValues: ForgotPasswordPayload = useMemo(
    () => ({
      username: "",
    }),
    []
  );

  return (
    <AuthPagesWrapper includeLogo containerClassName={"login-fields"}>
      <Formik
        initialValues={initialValues}
        onSubmit={(data: ForgotPasswordPayload) => {
          postForgotPasswordRequest({
            ...data,
            onSuccess: (username) => {
              setSuccess(true);
              setLinkSentTo(username);
            },
            onError: (err: string) => setError(err),
          });
        }}
        validateOnChange
        validationSchema={VALIDATION_SCHEMA}
        render={({ handleSubmit, isValid, dirty, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.titleStyle}>
                <h2 className={classes.labelStyle}>
                  {success ? LABELS.success : LABELS.resetPassword}
                </h2>
                <p style={{ marginTop: "9px" }}>
                  {success
                    ? LABELS.checkInboxMessage
                    : LABELS.resetPasswordMessage}
                </p>
              </div>

              {/* Form fields */}
              {success ? (
                <>
                  <Box className={classes.resetLinkMessageContainer}>
                    <ListItem>
                      <ListItemAvatar className={classes.listItemAvatar}>
                        <Box color={color.teal} pr={2}>
                          <CheckCircleOutline />
                        </Box>
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            className={classes.resetLinkMessage}
                            variant="caption">
                            {LABELS.resetLinkMessage}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="caption">
                            {linkSentTo}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Box>

                  <Button
                    fullWidth={true}
                    onClick={() => history.push(routes.login.viewGeneric())}
                    className={classes.loginButton}>
                    {LABELS.backToLogIn.toUpperCase()}
                  </Button>
                </>
              ) : (
                <>
                  <Field name={"username"}>
                    {({ field }) => (
                      <RenderInputField
                        label={LABELS.emailPlaceholder}
                        {...field}
                      />
                    )}
                  </Field>

                  {error && (
                    <Box mt={1}>
                      <ErrorText text={error} />
                    </Box>
                  )}

                  <div className={classes.buttonContainer}>
                    <Button
                      fullWidth={true}
                      type="submit"
                      variant={!dirty || !isValid ? "outlined" : "contained"}
                      className={
                        !dirty || !isValid
                          ? classes.loginButtonDisabled
                          : classes.loginButton
                      }
                      disabled={!dirty || !isValid}>
                      {LABELS.resetPassword.toUpperCase()}
                    </Button>

                    <p className={`${classes.bottomLink} paragraph-small`}>
                      <Link
                        to={routes.login.viewGeneric()}
                        className={classes.loginLink}>
                        {LABELS.return}
                      </Link>
                    </p>
                  </div>
                </>
              )}
            </form>
          );
        }}
      />
    </AuthPagesWrapper>
  );
};

export default ForgotPasswordComponent;
