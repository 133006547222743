import {
  ShowVideoData,
  VideoPlayerAction,
  VideoPlayerActionTypes,
} from "../actions/VideoPlayerActions";
import { VideoPlayerState } from "../state/VideoPlayer";

const initialState: VideoPlayerState = {
  src: null,
  visible: false,
};

export const videoPlayerReducer = (
  state = initialState,
  action: VideoPlayerAction
) => {
  switch (action.type) {
    case VideoPlayerActionTypes.Show:
      const { src } = action.data as ShowVideoData;
      return {
        src,
        visible: true,
      };

    case VideoPlayerActionTypes.Hide:
      return {
        src: null,
        visible: false,
      };

    default:
      return state;
  }
};
