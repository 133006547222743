import { makeStyles } from "@material-ui/core/styles";
import * as loginBackground from "../../assets/images/img-bg-loginblur.jpg";
import { pxToem } from "../../helper/Validation";
import { color } from "../../theme/Theme";

export const AuthPagesWrapperStyles = makeStyles({
  loginComponent: {
    width: "100%",
    height: "100vh",
    position: "relative",
    fontFamily: "GT Walsheim",
  },
  glassPanel: {
    position: "relative",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  imageContainer: {
    backgroundImage: `url(${loginBackground})`,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "center center",
  },
  fullHeight: {
    height: "100%",
    overflow: "auto",
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    padding: "35px 15%",
    boxShadow:
      "0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "white",
    height: "100%",
    "@media all and (max-width: 1023px)": {
      overflowX: "auto",
    },
    "@media all and  (max-width: 1280px)": {
      padding: "35px 10%",
    },
  },
  formStyleDark: {
    background: 'rgba(0,0,0,.7)',
    color: color.light,    
    padding: "10%",
  },
  headerLogo: {
    paddingBottom: pxToem(60),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  labelStyle: {
    color: color.primary,
    margin: 0,
    fontWeight: 500,
  },
  labelStyleDark: {
    color: color.light,
  },
  fieldsContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media only screen and (max-width: 480px)": {
      justifyContent: "flex-start",
    },
  },
  logo: {
    maxWidth: 90,
    paddingRight: "20px",
    marginRight: "20px",
    borderRight: `2px solid ${color.primary}`,
  },
  logoDark: {
    maxWidth: 120,
    borderRight: `2px solid ${color.light}`,
  },
  agencyLogo: {
    maxWidth: "150px",
    marginBottom: "57px",
  },
});
