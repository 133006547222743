export interface Author {
  authId: number;
  firstName: string;
  lastName: string;
  userId: number;
}

export enum CommentResourceType {
  Job = "JOB",
  WorkOrder = "WORK_ORDER",
}

export interface TradesComment {
  author: Author;
  child: TradesComment[];
  commentResourceType: CommentResourceType;
  content: string;
  id: string;
  parentId: string;
  resourceId: string;
  createdDate: string;
  modifiedDate: string;
}
