import React, { FC, useState, useEffect } from "react";
import { ApplicationState } from "../../store/RootReducers";
import { ConfirmRegistrationComponent } from "../../component/confirmRegistration/ConfirmRegistration";
import { Trade } from "./ConfirmRegistration.data";
import { History } from "history";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ListBranchesActions } from "../../store/actions/ListBranchesActions";
import { GetTradeRequest } from "../../services/listBranches/ListBranchesServiceData.data";
import { errorMessages } from "../../constants/AppConstants";
import { routes } from "../../Routes";
import { GetTradeUsersRequest, GetTradeUsersResponse } from "../../services/tradeUsers/TradeUsers.data";
import { GetTradeUsersActions } from "../../store/actions/TradeUsersActions";
import { getTradeId } from "../../helper/Auth";
import { GetBranchRequest, GetBranchSuccessPayload } from "../../services/branch/Branch.data";
import { GetBranchActions } from "../../store/actions/BranchActions";
import { onboardingStageUpdater } from "../../helper/OnboardingStageUpdater/OnboardingStageUpdater";
import { OnboardingStage } from "../../helper/OnboardingStages";

interface Props {
  history: History;
}

type OwnProps = PropsFromState & Props; 

interface PropsFromState {
  trade: Trade | undefined | null;
  tradeUsers: GetTradeUsersResponse | undefined;
  branch: GetBranchSuccessPayload | undefined;
  tradeDetailsError: any;
  tradeUsersError: any;
  branchDetailsError: any;
}

const ConfirmRegistration: FC<OwnProps> = (props: OwnProps) => {

  const tradeId  = getTradeId();

  // State
  const [tradeDetail, setTradeDetail] = useState<Trade>();
  const [tradeUsersDetail, setTradeUsersDetail] = useState<GetTradeUsersResponse>();
  const [branchDetail, setBranchDetail] = useState<GetBranchSuccessPayload>();
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyError, setverifyError] = useState("");

  // dispatcher
  const dispatch = useDispatch();

  const dispatcher = {
    getTradeDetail: (data: GetTradeRequest) => {
      dispatch(
        ListBranchesActions.listBranchesRequest({
          request: data,
        })
      );
    },
    getTradeUsersDetail: (data: GetTradeUsersRequest) => {
      dispatch(
        GetTradeUsersActions.getTradeUsersRequest({
          request: data,
        })
      );
    },
    getBranchDetail: (data: GetBranchRequest) => {
      dispatch(
        GetBranchActions.getBranchRequest({
          request: data,
        })
      );
    },
  };

  const history = useHistory();
  
  // ReduxProps(StateToProps)
  const { trade, tradeDetailsError, tradeUsers, tradeUsersError, branch, branchDetailsError } = useSelector<
    ApplicationState,
    PropsFromState
  >((state: ApplicationState) => ({
    tradeDetailsError: state.listBranch.error,
    trade: state.listBranch.trade,
    tradeUsers: state.tradeUsers.tradeUsers,
    tradeUsersError: state.tradeUsers.error,
    branch: state.branchDetail.branchDetails,
    branchDetailsError: state.branchDetail.error,
  }));

  // Effects
  useEffect(() => {
    getTradeDetail()
  }, []);

  useEffect(() => {
    getTradeUsersDetail()
  }, []);

  useEffect(() => {
    if (trade) {
      let branchId = findPrimaryBranchId(trade)
      if (branchId) {
        getPrimaryBranchDetail(branchId.toString())
      }
      setTradeDetail(trade)
    }
  }, [trade]);

  useEffect(() => {
    if (tradeUsers) {
      setTradeUsersDetail(tradeUsers)
    }
  }, [tradeUsers]);

  useEffect(() => {
    if (branch) {
      setBranchDetail(branch)
    }
  }, [branch]);

  useEffect(() => {
    if (tradeUsersError && tradeUsersError.response) {
      if (tradeUsersError.response.status === 401) {
        setErrorMessage(errorMessages.loginPage.incorrectCredentials);
      } else if (tradeUsersError.response.status === 400) {
        setverifyError(errorMessages.loginPage.emailVerificationFailed);
      }
    }
  }, [tradeUsersError]);

  useEffect(() => {
    if (tradeDetailsError && tradeDetailsError.response) {
      if (tradeDetailsError.response.status === 401) {
        setErrorMessage(errorMessages.loginPage.incorrectCredentials);
      } else if (tradeDetailsError.response.status === 400) {
        setverifyError(errorMessages.loginPage.emailVerificationFailed);
      }
    }
  }, [tradeDetailsError]);

  useEffect(() => {
    if (branchDetailsError && branchDetailsError.response) {
      if (branchDetailsError.response.status === 401) {
        setErrorMessage(errorMessages.loginPage.incorrectCredentials);
      } else if (branchDetailsError.response.status === 400) {
        setverifyError(errorMessages.loginPage.emailVerificationFailed);
      }
    }
  }, [branchDetailsError]);

  const getTradeDetail = (): void => {
    const request: GetTradeRequest = {
      tradeId: tradeId!,
    };
    dispatcher.getTradeDetail(request);
  };

  const getTradeUsersDetail = (): void => {
    const request: GetTradeUsersRequest = {
      tradeId: tradeId!,
    };
    dispatcher.getTradeUsersDetail(request)
  };

  const findPrimaryBranchId = (trade: Trade): number | null => {
    let branch = trade.offices.find(o => {
      return o.primary === true
    })
    return (branch) ? branch.id : null;
  };

  const getPrimaryBranchDetail = (primaryBranchId: string): void => {
    const request: GetBranchRequest = {
      officeId: primaryBranchId,
    };
    dispatcher.getBranchDetail(request)
  }

  const onConfirm = async() => {
    await onboardingStageUpdater(OnboardingStage.Completed)
    history.push(routes.dashboard.view);
  };
   
  return (
    <ConfirmRegistrationComponent
      trade={tradeDetail}
      tradeUsers={tradeUsersDetail}
      primaryBranch={branchDetail}
      errorMessage={errorMessage}
      verifyError={verifyError}
      onConfirm={onConfirm}
    />
  );
};

export default ConfirmRegistration;
