import React, { FC, useState } from "react";
import { AddServiceableRegions } from "../../component/addServiceableRegions/AddServiceableRegions";
import {
  ServiceableRegionFields,
  Region,
} from "./AddServiceableRegionsFormdata.data";
import { AddressComponent } from "../../component/AddressPicker/AddressPicker.data";
import { LABELS } from "../../component/addServiceableRegions/AddServicableRegionsConstants";
import { useDispatch } from "react-redux";
import { ServiceableRegionsActions } from "../../store/actions/ServiceableRegionsActions";
import { useParams } from "react-router";
import {
  AddServiceableRegionsRequest,
  AddServiceableRegionsSuccessPayload,
} from "../../services/serviceableRegions/serviceableRegionsServiceData.data";
// import {AddServiceableRegionsRequest} from "../../services/serviceableRegions/serviceableRegionsServiceData.data"
import { History } from "history";
import { routes } from "../../Routes";
import localStorage from "../../services/LocalStorage";
import { ServiceableRegion } from "../../services/branch/Branch.data";

interface Props {
  history: History;
}

type OwnProps = Props;

// Helper
export const getRegion = (
  placeAddressComponents: AddressComponent[]
): Region => {
  const address = {};
  for (const component of placeAddressComponents) {
    for (const type of component.types) {
      if (ServiceableRegionFields[type]) {
        address[ServiceableRegionFields[type]] = component.long_name;
      }
    }
  }
  return address as Region;
};

const AddServiceableRegionsForm: FC<OwnProps> = (props: OwnProps) => {
  // State
  const [regions, setRegions] = useState<Region[]>([]);
  const [addressError, setAddressError] = useState("");

  // dispatcher
  const dispatch = useDispatch();
  const dispatcher = {
    postAddServiceableRegions: (data: AddServiceableRegionsRequest) => {
      dispatch(
        ServiceableRegionsActions.addServiceableRegionsRequest({
          request: data,
          onSuccess: (response: AddServiceableRegionsSuccessPayload) => {
            if (localStorage.getItem("tradeId")) {
              props.history!.push(routes.addPaymentMethods.view);
            } else {
              props.history!.push(routes.error.view());
            }
          },
        })
      );
    },
  };

  // fetching office id
  const { officeId } = useParams();

  const removeRegion = (regionToRemove: Partial<ServiceableRegion>): void => {
    const newRegionsList = regions.filter(
      (region) =>
        region.postcode !== regionToRemove.postcode &&
        region.locality !== regionToRemove.locality
    );
    setRegions(newRegionsList);
  };

  const addRegion = (addressComponents: AddressComponent[]): void => {
    const region = getRegion(addressComponents);
    const { postcode, locality } = region;
    if (postcode) {
      if (uniquePostalCode(postcode, locality)) {
        setRegions([...regions, getRegion(addressComponents)]);
        setAddressError("");
      } else {
        setAddressError(LABELS.errors.uniqueAddress);
      }
    } else {
      setAddressError(LABELS.errors.noPostcode);
    }
  };

  const addSuggestionRegions = (suggestions: Region[]): void => {
    setRegions([...regions, ...suggestions]);
  };

  const uniquePostalCode = (postcode: string, locality: string): boolean => {
    let unique = true;
    regions.map((region) => {
      if (region.postcode === postcode && region.locality === locality) {
        unique = false;
      }
    });
    return unique;
  };

  const submitForm = () => {
    if (regions.length > 0) {
      const request: AddServiceableRegionsRequest = {
        regions,
        officeId: officeId!,
      };
      dispatcher.postAddServiceableRegions(request);
    }
  };

  return (
    <AddServiceableRegions
      regions={regions}
      removeRegion={removeRegion}
      addRegion={addRegion}
      addressError={addressError}
      onSubmit={submitForm}
      addSuggestionRegions={addSuggestionRegions}
    />
  );
};

export default AddServiceableRegionsForm;
