import { OfficeDetails } from "../../../../../services/registerBusiness/RegistrationFormRequest.data";

export const UpdateBranchFormFieldLabels = {
  branchName: "Office Name",
  branchPhone: "Office Phone",
  branchMail: "Office email",
  branchAddress: "Office Address",
  covidVaccinated: "All members are COVID vaccinated or have an exemption",
};

export const UpdateBranchFormFieldNames = {
  branchName: "name",
  branchPhone: "phone",
  branchMail: "email",
  branchAddress: "branchAddress",
  covidApproved: "covidApproved",
};

export type UpdateBranchFormFields = Partial<OfficeDetails>;
