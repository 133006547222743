import { makeStyles } from "@material-ui/core";
import { scrollStyles, color } from "../../theme/Theme";

const IMAGE_SIZE = 80;

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "1rem",
    ...scrollStyles,
  },
  gridList: {
    transform: "translateZ(0)",
    overflowY: "inherit",
    "& > li": {
      listStyleType: "none",
    },
  },
  image: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    borderRadius: "4px",
  },
  pointerCursor: {
    cursor: "pointer",
  },
  zoomInCursor: {
    cursor: "zoom-in",
  },
  titleBarRoot: {
    background: "transparent",
    top: "2px",
    height: "auto",
    alignItems: "flex-start",
  },
  removeIconButton: {
    padding: "2px",
    background: color.light,
    borderRadius: "50%",
  },
  gridTileRoot: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE + 30,
    marginRight: "1em",
    borderRadius: "4px",
    position: "relative",
  },
  addImageTileContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: `1px solid ${color.lightGrey}`,
  },
  removeIconButtonRoot: {
    "&:hover": {
      background: "white",
    },
  },
  videoItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0,0,0,0.5)",
    cursor: "pointer",
  },
  addImage: {
    height: IMAGE_SIZE,
  },
  removeBar: {
    position: "absolute",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    top: 2,
    right: 2,
  },
  docName: {
    height: 30,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});
