import React, { FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

interface Props {
  primaryText: string;
  secondaryText?: string;
  action?: () => void;
  open: boolean;
  handleConfirmationDialogClose: () => void;
  cancelText: string;
  acceptText: string;
  topIcon?: any;
}

const useStyles = makeStyles({
  buttonsSection: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1.5em",
  },
  navButton: {
    width: "124px",
    height: "32px",
  },
  textCentered: {
    textAlign: "center",
  },
});

const ConfirmationDialog: FC<Props> = (props) => {
  const {
    open,
    primaryText,
    action,
    secondaryText,
    handleConfirmationDialogClose,
    cancelText,
    acceptText,
    topIcon,
    children,
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Dialog open={open}>
      <Box display="flex" justifyContent="center" p={2}>
        <Box width={smallScreen ? "100%" : "80%"}>
          {topIcon && (
            <Box display="flex" justifyContent="center" width={"100%"}>
              {topIcon}
            </Box>
          )}
          <DialogTitle disableTypography className={`${classes.textCentered}`}>
            <Typography color="textPrimary" variant="h5">
              {primaryText}
            </Typography>
          </DialogTitle>
          <DialogContent>
            {secondaryText && (
              <Box mb={4}>
                <Typography
                  color="textSecondary"
                  className={`${classes.textCentered}`}
                  variant="h4">
                  {secondaryText}
                </Typography>
              </Box>
            )}
            {children}
          </DialogContent>
          <section className={classes.buttonsSection}>
            <Box mr={2}>
              <Button
                variant="outlined"
                onClick={handleConfirmationDialogClose}
                className={classes.navButton}>
                {cancelText}
              </Button>
            </Box>
            <Button
              color="secondary"
              variant="contained"
              onClick={action}
              className={classes.navButton}>
              {acceptText}
            </Button>
          </section>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
