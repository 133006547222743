import React, { FC } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { color } from "../../../theme/Theme";

interface Props {
  text: string;
  greyText?: boolean;
  lightText?: boolean;
  tealText?: boolean;
  action?: any;
  centered?: boolean;
  variant?: "outlined" | "contained" | "text";
  color?: "default" | "inherit" | "primary" | "secondary";
  startIcon?: any;
  endIcon?: any;
  fullWidth?: any;
  disabled?: boolean;
  standardSize?: boolean;
  textButton?: boolean;
}

const useStyles = makeStyles({
  dashboardButtonRoot: {
    maxWidth: "250px",
    padding: "8px 12px",
    overflow: "hidden",
    borderRadius: "4px",
  },
  textButton: {
    background: "transparent",
    border: "none",
  },
  standardSize: {
    width: "128px",
    height: "32px",
  },
  dashboardButtonText: {
    color: `${color.secondary200}`,
  },
  dashboardButtonTextGrey: {
    color: `${color.greyText}`,
  },
  dashboardButtonLightText: {
    color: `${color.light}`,
  },
  dashboardButtonTealText: {
    color: `${color.teal}`,
  },
  dashboardButtonLabel: {
    justifyContent: "flex-start",
  },
  dashboarButtonLabelCentered: {
    justifyContent: "center",
  },
});

// tslint:disable-next-line:cyclomatic-complexity
const DashboardButton: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    text,
    greyText,
    centered,
    action,
    variant,
    color,
    lightText,
    startIcon,
    endIcon,
    fullWidth,
    tealText,
    disabled,
    standardSize,
    textButton,
  } = props;

  return (
    <Button
      classes={{
        root: classes.dashboardButtonRoot,
        label: centered
          ? classes.dashboarButtonLabelCentered
          : classes.dashboardButtonLabel,
      }}
      className={`${standardSize ? classes.standardSize : ""} ${
        textButton ? classes.textButton : ""
      }`}
      onClick={action ? action : null}
      variant={variant || "outlined"}
      color={color || "default"}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={!!disabled}
      fullWidth={!!fullWidth}>
      <Typography
        className={`${
          greyText
            ? classes.dashboardButtonTextGrey
            : classes.dashboardButtonText
        } ${lightText && classes.dashboardButtonLightText} ${
          tealText && classes.dashboardButtonTealText
        }`}
        variant="caption">
        {text}
      </Typography>
    </Button>
  );
};

export default DashboardButton;
