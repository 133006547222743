import React, { FC, useEffect, useState } from "react";
import UserDetailsComponent from "../../component/userDetails/UserDetailsComponent";
import { useDispatch } from "react-redux";
import { UserDetailsFormData } from "../../component/userDetails/UserDetailsData.data";
import { userDetailsUpdateFormConverter } from "./UserDetailsUpdateFormConverter";
import { useAppSelector } from "../../store/RootReducers";
import { LoginActions } from "../../store/actions/LoginActions";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../Routes";
import Header from "../../component/header/Header";
import { get, isUndefined } from "lodash";
import { ValidateTokenPayload } from "../../services/login/LoginData";
import { PostUserDocumentData } from "../../services/userDocumentsService/UserDocuments.data";
import { UserDocumentsActions } from "../../store/actions/UserDocumentsActions";
import { AddUserDocData } from "../../component/dashboard/settings/profile/userDocuments/UserDocumentsData.data";
import qs from "query-string";
import { isLoggedIn } from "../../helper/Auth";

const UserDetails: FC<{}> = (props) => {
  // state
  // undefined will be used for initial render, post which useEffect will verify if token exists or not
  // if the token doesn't exist it will be set to null otherwise to the string value
  const [inviteToken, setInviteToken] = useState<string | undefined | null>(
    undefined
  );

  // hooks
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { userDetails, userDocuments } = useAppSelector((state) => ({
    userDetails: state.login.userDetails,
    userDocuments:
      state.login.userDetails && state.login.userDetails.userDocuments,
  }));

  // effects
  useEffect(() => {
    const { token } = qs.parse(location.search);
    if (token) {
      localStorage.setItem("inviteToken", token as string);
      setInviteToken(token as string);
    } else {
      localStorage.removeItem("inviteToken");
      setInviteToken(null);

      // If there is no invite token, as well as the user is not logged in
      // it means the user is trying to access the page with URL directly and is not authenticated, so redirecting to login page
      if (!isLoggedIn()) {
        history.push(routes.login.viewGeneric());
      }
    }
  }, []);

  // Fetches user details if not present
  useEffect(() => {
    if (!userDetails) {
      if (!!inviteToken) {
        dispatcher.validateUserToken({ token: inviteToken });
      } else {
        // as undefined invite token means setInvite token has been called by the onMount effect but hasn't changed the value to null or the string yet
        // so in that case we need to hold the get userDetails call
        if (!isUndefined(inviteToken)) {
          dispatcher.getUserDetails();
        }
      }
    } else {
      localStorage.setItem("userId", userDetails!.id.toString());
      localStorage.setItem(
        "tradeId",
        userDetails!.userTradeEntitlements[0]!.tradeId!.toString()
      );
      if (get(userDetails, "authToken", false)) {
        localStorage.setItem("authToken", userDetails!.authToken!);
        localStorage.setItem("refreshToken", userDetails!.refreshToken!);
      }
    }
  }, [userDetails, inviteToken]);

  // dispatcher
  const dispatcher = {
    putUserDetails: (details: UserDetailsFormData) =>
      dispatch(
        LoginActions.putUserDetailsStart({
          request: userDetailsUpdateFormConverter(
            details,
            userDetails!,
            inviteToken as string
          ),
          onSuccess: (data) => {
            history.push(routes.addTrades.view);
          },
        })
      ),
    getUserDetails: () => dispatch(LoginActions.getUserDetails()),
    validateUserToken: (data: ValidateTokenPayload) =>
      dispatch(LoginActions.postValidateTokenStart(data)),
    postUserDocument: (uploadDocumentData: PostUserDocumentData) =>
      dispatch(
        UserDocumentsActions.PostUserDocumentRequest(uploadDocumentData)
      ),
    deleteUserDocument: (userDocumentId: number) =>
      dispatch(
        UserDocumentsActions.DeleteUserDocumentRequest({ userDocumentId })
      ),
  };

  const onRemoveUserDocument = (userDocumentId: number) => {
    dispatcher.deleteUserDocument(userDocumentId);
  };

  const onUserDocSubmit = (uploadDocData: AddUserDocData) => {
    const { docName, file, expiryDate, type } = uploadDocData;

    const requestData: PostUserDocumentData = {
      file,
      data: {
        expiryDate: expiryDate ? expiryDate.format("DD/MM/YYYY") : null,
        name: docName,
        type,
      },
    };

    dispatcher.postUserDocument(requestData);
  };

  return isUndefined(inviteToken) ? null : (
    <>
      <Header />
      <UserDetailsComponent
        userDetails={userDetails}
        onSubmit={(details) => dispatcher.putUserDetails(details)}
        isInvited={!!inviteToken}
        userDocuments={userDocuments}
        onUserDocSubmit={onUserDocSubmit}
        onRemoveUserDocument={onRemoveUserDocument}
      />
    </>
  );
};

export default UserDetails;
