import React, { FC } from "react";
import DashboardListItem from "../lisItems/DashboardListItem";
import { GetUserResponse } from "../../services/login/LoginData";
import { KeyboardArrowDown, Person } from "@material-ui/icons";
import DashboardListItemAction from "../lisItems/DashboardListItemAction";
import { Box } from "@material-ui/core";
import { DetailsSidebarStyles } from "./DetailsSidebarStyles.data";

interface Props {
  children: JSX.Element | null;
  userDetails: GetUserResponse;
  collapsed?: boolean;
}

const DetailsSidebar: FC<Props> = (props) => {
  const classes = DetailsSidebarStyles();
  const {
    children,
    userDetails: { firstName, lastName, email, logo },
  } = props;
  return (
    <Box className={classes.sidebarContainer} px={2} pb={2}>
      <section className={classes.sidebarUserDetailsContainer}>
        <Box width="358px">
          <DashboardListItem
            primary={`${firstName} ${lastName}`}
            secondary={email}
            image={
              logo ? (
                <img width={"40px"} height={"40px"} src={logo} />
              ) : (
                <Person />
              )
            }
            secondaryData={
              <>
                <DashboardListItemAction>
                  <KeyboardArrowDown />
                </DashboardListItemAction>
              </>
            }
          />
        </Box>
      </section>
      <section className={classes.container}>{children}</section>
    </Box>
  );
};

export default DetailsSidebar;
