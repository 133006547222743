import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/RootReducers";
import { match } from "react-router";
import { Forms } from "../../helper/Forms";
import { History } from "history";
import localStorage from "../../services/LocalStorage";
import LoginBackground from "../../component/loginBackground/LoginBackground";
import RegistrationFormComponent from "../../component/registrationForm/RegistrationFormComponent";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { RegistrationFormData } from "../../component/registrationForm/RegistrationFormData.data";
import { StoreHelper } from "../../store/StoreHelper";
import { AnyAction } from "redux";
import {
  RegisterBusinessRequest,
  OfficeDetails,
  RegisterBusinessSuccessPayload,
} from "../../services/registerBusiness/RegistrationFormRequest.data";
import {
  RegisterBusinessActions,
  RegisterBusinessRequestPayload,
} from "../../store/actions/RegisterBusinessActions";
import {
  ConvertFormDataToRequest,
  ConvertFormDataToAddBranchRequest,
} from "./RegistrationFormConverter";
import { routes } from "../../Routes";
import { Trade } from "../listBranches/ListBranches.data";
import { ListBranchesActions } from "../../store/actions/ListBranchesActions";
import { GetTradeRequest } from "../../services/listBranches/ListBranchesServiceData.data";
import {
  AddBranchActions,
  AddBranchRequestPayload,
} from "../../store/actions/BranchActions";
import { Office as AddBranchSuccessPayload } from "./AddBranch.data";
import { getTradeId } from "../../helper/Auth";
// import CustomAddressForm from "../../component/registrationForm/CustomAddress"

const form = Forms.RegistrationForm;

interface Props {
  history?: History;
  location: any;
  match: match;
}

interface PropsFromRegistrationState {
  isLoading: boolean;
  reduxFormState: RegistrationFormData;
  userInputs: any | null;
  trade: Trade | null;
}

interface PropsFromDispatch {
  readonly onSubmit: (
    businessData: RegisterBusinessRequest | OfficeDetails
  ) => void;
  readonly getTradeDetails: (request: GetTradeRequest) => void;
}

interface ReduxFormProps {
  invalid: boolean;
  change: () => void;
}

interface StateProps {
  keySearch: string | undefined | null;
  lookupError: string | undefined | null;
}

type OwnProps = PropsFromRegistrationState &
  Props &
  ReduxFormProps &
  PropsFromDispatch;

class RegistrationForm extends React.Component<OwnProps, StateProps> {
  public delayTimer: any;
  public tradeId: string | null;
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      keySearch: undefined,
      lookupError: undefined,
    };
    this.tradeId = getTradeId();
  }

  public componentDidMount() {
    if (this.tradeId) {
      const request: GetTradeRequest = {
        tradeId: this.tradeId!,
      };
      this.props.getTradeDetails(request);
    }
  }

  public handleFormSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    logo: any
  ) => {
    const data = this.props.userInputs;
    data.logo = logo;
    let requestData: OfficeDetails | RegisterBusinessRequest;

    if (this.tradeId) {
      requestData = ConvertFormDataToAddBranchRequest(
        data,
        Number(this.tradeId)
      );
    } else {
      requestData = ConvertFormDataToRequest(data);
    }
    this.props.onSubmit(requestData);
  };

  public render() {
    const { reduxFormState, invalid, change } = this.props;
    const firstName: string = localStorage.getItem("firstName")!;
    return !this.tradeId || this.props.trade ? (
      <LoginBackground>
        <form
          name={form}
          style={{ width: "100%", height: "100%" }}
          className="Registration-form">
          <RegistrationFormComponent
            reduxFormState={reduxFormState}
            invalid={invalid}
            handleSubmit={this.handleFormSubmit}
            firstName={firstName}
            tradeDetails={this.props.trade}
            change={change}
          />
        </form>
      </LoginBackground>
    ) : null;
  }
}

const mapStateToProps = (
  state: ApplicationState
): PropsFromRegistrationState => ({
  isLoading: state.loadingIndicator.isLoading,
  reduxFormState: StoreHelper.getFormState<RegistrationFormData>(
    form,
    new RegistrationFormData()
  ),
  userInputs: state.form.registrationForm
    ? state.form.registrationForm.values
    : null,
  trade: state.listBranch.trade,
});

const mapDispatchToProps: (
  dispatch: React.Dispatch<AnyAction>,
  ownProps: OwnProps
) => PropsFromDispatch = (dispatch, ownProps: OwnProps) => ({
  onSubmit: (
    formData: AddBranchRequestPayload | RegisterBusinessRequestPayload | any
  ) => {
    const tradeId = getTradeId();
    if (tradeId) {
      dispatch(
        AddBranchActions.addBranchRequest({
          request: formData,
          onSuccess: (response: AddBranchSuccessPayload) => {
            ownProps.history!.push(
              routes.addServiceableRegion.view(response.id.toString())
            );
          },
        })
      );
    } else {
      dispatch(
        RegisterBusinessActions.registerBusinessRequest({
          request: formData,
          onSuccess: (response: RegisterBusinessSuccessPayload) => {
            if (!tradeId) {
              localStorage.setItem("tradeId", response.id.toString());
            }
            ownProps.history!.push(
              routes.addServiceableRegion.view(
                response.offices[0].id.toString()
              )
            );
          },
        })
      );
    }
  },
  getTradeDetails: (request: GetTradeRequest) => {
    dispatch(ListBranchesActions.listBranchesRequest({ request }));
  },
});

export const RegistrationReduxForm = reduxForm({
  form,
})(RegistrationForm as any);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistrationReduxForm as any) as any
);
