import MASTER_CARD_LOGO from "../../assets/images/payment/mc-symbol.svg";
import VISA_CARD_LOGO from "../../assets/images/payment/visa-credit-card.svg";
import CARD_LOGO from "../../assets/images/payment/credit-card-24-px.svg";
// import BankIcon from "../../assets/images/payment/account-balance-24-px.svg";
import CardIcon from "../../assets/images/payment/credit-card-24-px.svg";
import bankLogo from "../../assets/images/payment/bank-account.png";
import {
  PaymentMethodType,
  PaymentMethodTypeTitle,
} from "../dashboard/settings/payment/SavedPaymentMethodsConstants.data";

export interface MaskDetails {
  paymentDetails: string;
  logo: any;
}

export interface BankValues {
  accountName: string;
  bsbNumber: string;
  accountNumber: string;
  maskedBankAccountDetails: MaskDetails;
}

export const initialBankValues: BankValues = {
  accountName: "",
  accountNumber: "",
  bsbNumber: "",
  maskedBankAccountDetails: {
    paymentDetails: "",
    logo: "",
  },
};

export const errorMessages = {
  required: "Required field",
  bsbFormat: "Should match format [xxx-xxx]",
  invalidBsb: "Invalid BSB",
  accNumMin: "Should be minimum 6 characters",
  accNumMax: "Should not exceed 10 characters",
  accName: "Only alphabets allowed",
  accNameLength: "Cannot exceed 30 characters",
};

export const lengths = {
  accNumMin: 6,
  accNumMax: 10,
  accNameMax: 30,
};

export const bsbValidatorPattern = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  "-",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export const bsbNumberRegex = /^\d{3}-?\d{3}$/;
export const accountNameRegex = /^[a-zA-Z\s]*$/;
export const accountNumberRegex = /[!@#$%^&*(),.?":{}|<>\s]/g;

export const expiryDateError = "Invalid date";
export const expiryDateLength = 5;
export const cardHolderNameRegex = /^[a-zA-Z]+((\s[a-zA-Z]+)+)?$/;
export const cardHolderNameMaxLength = 20;
export const cardNumberLength = 19;
export const nameMaxLengthError = "Cannot exceed 20 characters";
export const nameFormatError = "Only alphabets allowed";
export const invalidCardError = "Invalid card number";
export const cardTypeNotSupportedError = "Card type is not supported";
export const requiredFieldError = "Required field";
export const expiryDatePattern = [/[0-1]/, /[0-9]/, "/", /[0-9]/, /[0-9]/];
export const cardNumberPattern = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export enum CardType {
  Visa = "visa",
  MasterCard = "mastercard",
}

export interface CardValues {
  cardHolderName: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
}

export const initialCardValues: CardValues = {
  cardHolderName: "",
  cardNumber: "",
  expiryDate: "",
  cvv: "",
};

export interface CreditCardRequest {
  type: string;
  cardHolderName: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  email: string;
}

export const renderCardIcon = (cardType: string) => {
  switch (cardType) {
    case "visa":
      return VISA_CARD_LOGO;
    case "mastercard":
      return MASTER_CARD_LOGO;
    default:
      return CARD_LOGO;
  }
};

export const maskCardDetails = (
  cardNumber: string,
  cardType: string
): MaskDetails => {
  return {
    paymentDetails: `Card **** **** **** ${cardNumber.slice(-4)}`,
    logo: renderCardIcon(cardType),
  };
};

export const labels = {
  accountName: "Account name",
  bsbNumber: "BSB number",
  accountNumber: "Account number",
  save: "Save",
  back: "Back",
  cardHolderName: "Card holder name",
  cardNumber: "Card number",
  expiry: "Expiry",
  cvv: "CVV",
  secureConnection: "Secure connection",
  termsLabel: "I accept terms and conditions.",
  termsAndConditions: "Terms and conditions",
  tncLine1:
    "The credit card details entered will serve as your nominated account for Sorted’s platform fee. This fee is for ongoing support and improvements to the Sorted platform.",
  tncLine2: "A 1% transaction fee applies to each credit card transaction",
  tncLine3:
    "By continuing you acknowledge and accept the Direct debit service agreement available below",
  tncLinkLabelCard: "Direct debit service agreement",
  addCreditCard: "Add credit card",
};

export const getPopupLabels = (step: AddPaymentMethodSteps) => {
  switch (step) {
    case AddPaymentMethodSteps.ADD_CARD_DETAILS:
      return {
        title: "Add credit card",
        subtitle:
          "Please add in your card details below —  we’ll safely store these details for you.",
        primaryButtonText: "Save",
      };
    case AddPaymentMethodSteps.SELECT_METHOD_TYPE:
      return {
        title: "Add payment method",
        subtitle:
          "Please add in your preferred payment method —  we’ll use these details for when your bills are due.",
        primaryButtonText: "Next",
      };
    case AddPaymentMethodSteps.ADD_BANK_DETAILS:
      return {
        title: "Add bank account",
        subtitle:
          "Please add in your bank account details below —  we’ll safely store these details for you.",
        primaryButtonText: "Save",
      };
  }
};

export enum AddPaymentMethodSteps {
  SELECT_METHOD_TYPE,
  ADD_BANK_DETAILS,
  ADD_CARD_DETAILS,
}

interface PaymentMethodTypeDetails {
  type: PaymentMethodType;
  title: PaymentMethodTypeTitle;
  icon: string;
}

export const paymentMethodTypes: PaymentMethodTypeDetails[] = [
  // Commented for now, uncomment when billing allows bank
  // {
  //   type: PaymentMethodType.Bank,
  //   title: PaymentMethodTypeTitle.Bank,
  //   icon: BankIcon,
  // },
  {
    type: PaymentMethodType.Credit,
    title: PaymentMethodTypeTitle.Credit,
    icon: CardIcon,
  },
];

export const getStep = (
  methodType: PaymentMethodType
): AddPaymentMethodSteps => {
  switch (methodType) {
    case PaymentMethodType.Bank:
      return AddPaymentMethodSteps.ADD_BANK_DETAILS;
    case PaymentMethodType.Credit:
      return AddPaymentMethodSteps.ADD_CARD_DETAILS;
  }
};

export interface MaskDetails {
  paymentDetails: string;
  logo: any;
}

export const maskBankAccountDetails = (
  accountNumber: string,
  bsbNumber: string
): MaskDetails => {
  return {
    paymentDetails: `Acc ${accountNumber
      .toString()
      .slice(0, 4)} ** BSB ${bsbNumber.slice(0, 3)} **`,
    logo: bankLogo,
  };
};
