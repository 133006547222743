import { routes } from "../../Routes";
import { TopNavTab } from "./TopNav.data";

export enum TopNavDashboardLink {
  Overview = "Overview",
  Jobs = "Jobs",
  Quotes = "Quotes",
}

export interface TopNavDashboardTab extends TopNavTab {
  text: TopNavDashboardLink;
}

export const topNavDashboardTabs: TopNavDashboardTab[] = [
  {
    text: TopNavDashboardLink.Overview,
    route: routes.dashboard.view,
  },
  {
    text: TopNavDashboardLink.Quotes,
    route: routes.dashboard.quotes(),
  },
  {
    text: TopNavDashboardLink.Jobs,
    route: routes.dashboard.jobs(),
  },
];
