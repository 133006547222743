import { ServiceType } from "../ServiceType.data";
import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import {
  PostCommentRequestPayload,
  GetCommentsRequestPayload,
} from "./CommentServiceData.data";
import { TradesComment } from "../../models/comment/Comment";

const serviceType = ServiceType.Comment;
const RESOURCE = "resource";

class CommentService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public postComment(
    data: PostCommentRequestPayload
  ): Promise<Comment> | Comment {
    return this.service.post({}, data);
  }

  public getComments(
    data: GetCommentsRequestPayload
  ): Promise<TradesComment> | TradesComment {
    const { resourceId } = data;
    return this.service.get({ route: [RESOURCE, resourceId] });
  }
}

const commentService = new CommentService();
export default commentService;
