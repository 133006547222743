import * as React from "react";
import { Checkbox, FormHelperText, FormControlLabel } from "@material-ui/core";

/// <summary>
/// Value is string
/// </summary>
const RenderCheckbox = (field: any) => {
  const hasError = !!field.meta.error && !!field.meta.touched;
  // const hasSecondaryError = !!field.secondaryValidationMessage;
  return (
    <div className="item-render" style={field.itemRenderStyle}>
      <FormControlLabel
        classes={{ label: field.labelStyle }}
        labelPlacement={field.labelPlacement ? field.labelPlacement : "end"}
        control={
          <Checkbox
            onChange={field.input.onChange}
            checked={field.input.value ? true : false} // string
            id={field.input.name}
            disabled={field.disabled}
            icon={field.icon}
            checkedIcon={field.checkedIcon}
            color={field.color}
            style={field.style}
          />
        }
        label={field.label}/>
        {hasError && (
          <FormHelperText id={`${field.input.name}-error-text`}>
            {field.meta.error}
          </FormHelperText>
        )}
        {/* {hasSecondaryError && <FormHelperText id={`${field.input.name}-error2-text`} error={false} required={true}>{field.secondaryValidationMessage}</FormHelperText>} */}
        {/* {!!field.helperText && <FormHelperText id={`${field.input.name}-helper-text`}>{field.helperText}</FormHelperText>} */} 
    </div>
  );
};

export default RenderCheckbox;

