import { makeStyles } from "@material-ui/core";

export const CommentsSectionStyles = makeStyles({
  wrapper: {
    padding: "1em 0 1em 1em",
  },
  sectionHeading: {
    marginBottom: "1em",
  },
  loadingBox: {
    width: "100%",
    padding: "2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  newCommentListItemMargin: {
    marginTop: "16px",
  },
});
