import { makeStyles } from "@material-ui/core";
import { color, scrollStyles } from "../../../../theme/Theme";

export const SelectAgencyStyles = makeStyles({
  listItemSecondaryAction: {
    background: color.wildSand,
    display: "none",
  },
  listItem: {
    "&:hover $listItemSecondaryAction": {
      display: "block",
      background: color.wildSand,
    },
    "&:hover": {
      background: color.wildSand,
      cursor: "pointer",
    },
  },
  pointer: {
    cursor: "pointer",
  },
  scroll: { ...scrollStyles },
});