import React, { FC, useState, useEffect } from "react";
import { ApplicationState } from "../../store/RootReducers";
import { ListBranchesComponent } from "../../component/listBranches/ListBranches";
import { Trade } from "./ListBranches.data";
import { History } from "history";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ListBranchesActions } from "../../store/actions/ListBranchesActions";
import { GetTradeRequest } from "../../services/listBranches/ListBranchesServiceData.data";
import { errorMessages } from "../../constants/AppConstants";
import { routes } from "../../Routes";
import { OnboardingStage } from "../../helper/OnboardingStages";
import { onboardingStageUpdater } from "../../helper/OnboardingStageUpdater/OnboardingStageUpdater";

interface Props {
  history: History;
}

type OwnProps = PropsFromState & Props;

interface PropsFromState {
  trade: Trade | undefined | null;
  branchListError: any; //Error object
}

const ListBranches: FC<OwnProps> = (props: OwnProps) => {
  // fetching tradeId
  const { tradeId } = useParams();

  // State
  const [tradeDetail, setTradeDetail] = useState<Trade>();
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyError, setverifyError] = useState("");

  // dispatcher
  const dispatch = useDispatch();

  const dispatcher = {
    getTradeDetail: (data: GetTradeRequest) => {
      dispatch(
        ListBranchesActions.listBranchesRequest({
          request: data,
        })
      );
    },
  };

  const history = useHistory();

  // ReduxProps(StateToProps)
  const { trade, branchListError } = useSelector<
    ApplicationState,
    PropsFromState
  >((state: ApplicationState) => ({
    branchListError: state.listBranch.error,
    trade: state.listBranch.trade,
  }));

  // Effects
  useEffect(() => {
    getTradeDetail();
  }, []);

  useEffect(() => {
    if (trade) {
      setTradeDetail(trade);
    }
  }, [trade]);

  useEffect(() => {
    if (branchListError && branchListError.response) {
      if (branchListError.response.status === 401) {
        setErrorMessage(errorMessages.loginPage.incorrectCredentials);
      } else if (branchListError.response.status === 400) {
        setverifyError(errorMessages.loginPage.emailVerificationFailed);
      }
    }
  }, [branchListError]);

  const getTradeDetail = (): void => {
    const request: GetTradeRequest = {
      tradeId: tradeId!,
    };
    dispatcher.getTradeDetail(request);
  };

  const submitForm = async () => {
    await onboardingStageUpdater(OnboardingStage.AddAdminTradeCategories);
    history.push(routes.addUserDetails.view);
  };

  const onAddNew = () => {
    history.push(routes.registrationForm.viewGeneric());
  };

  return (
    <ListBranchesComponent
      trade={tradeDetail}
      errorMessage={errorMessage}
      verifyError={verifyError}
      onNextAndSkip={submitForm}
      onAddNew={onAddNew}
    />
  );
};

export default ListBranches;
