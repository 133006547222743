import { call, put, takeEvery } from "redux-saga/effects";
import {
  GetPaymentAuthKey,
  GetPaymentMethodsResponse,
} from "../../../src/services/payment/PaymentService.data";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import {
  DeletePaymentRequest,
  GetPaymentAuthKeyRequest,
  GetPaymentMethodsPayLoad,
  PaymentAction,
  PaymentActions,
  PaymentActionTypes,
  PaymentErrorPayload,
  PostPaymentRequest,
} from "../actions/PaymentActions";
import PaymentService from "../../services/payment/PaymentService";
import {
  BankAccountRequest,
  CreditCardRequest,
} from "../../../src/models/payment/PaymentRequest.data";

export function* getPaymentAuthKeySaga(
  action: PaymentAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const actionData = action.data as GetPaymentAuthKeyRequest;
    const response: GetPaymentAuthKey =
      (yield PaymentService.getPaymentAuthKey())!;
    if (response.status === 0) {
      let requestObject = {};
      if (actionData.data.type === "CREDITCARD") {
        const paymentDetails = actionData.data as CreditCardRequest;
        requestObject = {
          Type: paymentDetails.type,
          CardHolderName: paymentDetails.cardHolderName,
          CardNumber: paymentDetails.cardNumber.replace(/\s+/g, ""),
          ExpiryMonth: paymentDetails.expiryMonth,
          ExpiryYear: paymentDetails.expiryYear,
        };
      } else {
        const paymentDetails = actionData.data as BankAccountRequest;
        requestObject = {
          Type: paymentDetails.type,
          BankAccountName: paymentDetails.accountName,
          BankAccountNumber: paymentDetails.accountNumber,
          BSBNumber: paymentDetails.bsbNumber.replace("-", ""),
          AcceptBankAccountTerms: true,
        };
      }
      const paymentDetails = actionData.data;
      // @ts-ignore
      CBA.ProcessAddToken({
        AuthKey: response.authKey,
        Crn1: paymentDetails.email,
        EmailAddress: paymentDetails.email,
        ...requestObject,
        CallbackFunction: (result: any) =>
          creditCardCallBackFunction(
            result,
            actionData.onSuccess,
            actionData.onError
          ),
      });
    } else {
      yield put(LoadingIndicatorActions.hide());
      yield put(
        PaymentActions.getPaymentAuthKeyError({
          error: "error in fetching auth key",
        })
      );
    }
    yield put(PaymentActions.getPaymentAuthKeySuccess(response));
  } catch (error) {
    yield put(LoadingIndicatorActions.hide());
    yield call(console.log, error);
    yield put(PaymentActions.getPaymentAuthKeyError({ error }));
    yield put(LoadingIndicatorActions.hide());
  }
}

const creditCardCallBackFunction = (
  response: any,
  onSuccess: (token: string) => void,
  onError: (error: PaymentErrorPayload) => void
) => {
  if (response.AjaxResponseType === 0 && response.ApiResponseCode === 0) {
    const token = response.ResultKey;
    onSuccess(token);
  } else {
    console.log("error: ", response);
    onError({ error: response.Errors[0].Message });
  }
};

export function* postPaymentTokenSaga(
  action: PaymentAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const actionData = action.data as PostPaymentRequest;
    const response = (yield PaymentService.postPaymentToken(actionData.data))!;
    yield put(PaymentActions.postPaymentTokenSuccess(response));
    if (actionData.onSuccess) {
      actionData.onSuccess(response);
    }
  } catch (error) {
    yield put(LoadingIndicatorActions.hide());
    yield call(console.log, error);
    yield put(PaymentActions.postPaymentTokenError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getPaymentMethodsWorker(
  action: PaymentAction
): IterableIterator<any> {
  try {
    const { tradeId } = action.data as GetPaymentMethodsPayLoad;
    yield put(LoadingIndicatorActions.show());
    const response: GetPaymentMethodsResponse[] =
      (yield PaymentService.getPaymentMethods(tradeId))!;
    yield put(PaymentActions.getPaymentMethodsSuccess(response));
  } catch (error) {
    yield put(LoadingIndicatorActions.hide());
    yield put(PaymentActions.getPaymentMethodsError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* deletePaymentSaga(
  action: PaymentAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const { paymentRefId, onSuccess } = action.data as DeletePaymentRequest;
    const response: string[] = (yield PaymentService.deletePayment(
      paymentRefId
    ))!;
    yield put(PaymentActions.deletePaymentSuccess(response));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    yield put(LoadingIndicatorActions.hide());
    yield put(PaymentActions.deletePaymentError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const paymentWatcher = [
  takeEvery(PaymentActionTypes.GetPaymentAuthKeyStart, (action) =>
    getPaymentAuthKeySaga({
      type: action.type,
      data: (action as PaymentAction).data,
    })
  ),
  takeEvery(PaymentActionTypes.PostPaymentTokenStart, (action) =>
    postPaymentTokenSaga({
      type: action.type,
      data: (action as PaymentAction).data,
    })
  ),
  takeEvery(PaymentActionTypes.GetPaymentMethodsStart, (action) =>
    getPaymentMethodsWorker({
      type: action.type,
      data: (action as PaymentAction).data,
    })
  ),
  takeEvery(PaymentActionTypes.DeletePaymentStart, (action) =>
    deletePaymentSaga({
      type: action.type,
      data: (action as PaymentAction).data,
    })
  ),
];

export default paymentWatcher;
