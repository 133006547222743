import * as React from "react"

export enum AppIconType {
    AddCircle = "add-circle",
    AddUser = "add-user",
    Add = "add",
    Attention = "attention",
    Avatar = "avatar",
    BarGraph = "bar-graph",
    Bath = "bath",
    Bed = "bed",
    Calendar = "calendar",
    CameraAdd = "camera-add",
    Camera = "camera",
    Chat = "chat",
    Check = "check",
    Children = "children",
    City = "city",
    Cloud = "cloud",
    Cross = "cross",
    Dash = "dash",
    Docs = "docs",
    Document = "document",
    Domain = "domain",
    Donut = "donut",
    Download = "download",
    Edit = "edit",
    Email = "email",
    Error = "error",
    Excalamation = "exclamation",
    Filter = "filter",
    Flag = "flag",
    Flower = "flower",
    Furnished = "furnished",
    Gym = "gym",
    Home = "home",
    HouseAdd = "house-add",
    Income = "income",
    Light = "light",
    MapMarker = "map-marker",
    NavArrowDown = "nav--arrow-down",
    NavArrowUpDown = "nav--arrow-up-down",
    NavArrowUp = "nav--arrow-up",
    NavCaretDown = "nav--caret-down",
    NavCaretLeft = "nav--caret-left",
    NavCaretRight = "nav--caret-right",
    NavCaretUp = "nav--caret-up",
    NavChevronDown = "nav--chevron-down",
    NavChevronLeft = "nav--chevron-left",
    NavChevronRight = "nav--chevron-right",
    NavChevronUp = "nav--chevron-up",
    NavHamburger = "nav--hamburger",
    Parking = "parking",
    Password = "password",
    Paw = "paw",
    People = "people",
    Phone = "phone",
    Print = "print",
    Return = "return",
    Search = "search",
    SocialScore = "social-score",
    Star = "star",
    ToggleCheckBoxDeselect = "toggle--checkbox-deselect",
    ToggleCheckBoxNeutral = "toggle--checkbox-neutral",
    ToggleCheckBoxSelect = "toggle--checkbox-select",
    ToggleCheckBoxWarning = "toggle--checkbox-warning",
    Trash = "trash"
}

const paths: { [key: string]: string } = {};
paths[AppIconType.AddCircle] = "M13.208 5.972v4.845h4.778v2.366h-4.778v4.845h-2.417v-4.845h-4.778v-2.366h4.778v-4.845h2.417zM11.972 0c3.297 0 6.126 1.174 8.487 3.521s3.541 5.174 3.541 8.479c0 3.305-1.18 6.131-3.541 8.479s-5.19 3.521-8.487 3.521c-3.297 0-6.117-1.174-8.459-3.521s-3.513-5.174-3.513-8.479c0-3.305 1.171-6.131 3.513-8.479s5.162-3.521 8.459-3.521zM12 21.634c2.642 0 4.899-0.939 6.773-2.817s2.81-4.15 2.81-6.817c0-2.667-0.937-4.939-2.81-6.817s-4.131-2.817-6.773-2.817c-2.642 0-4.899 0.939-6.773 2.817s-2.81 4.15-2.81 6.817c0 2.667 0.937 4.939 2.81 6.817s4.131 2.817 6.773 2.817z";
paths[AppIconType.AddUser] = "M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4c-2.21 0-4 1.79-4 4s1.79 4 4 4zM6 10v-3h-2v3h-3v2h3v3h2v-3h3v-2h-3zM15 14c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z";
paths[AppIconType.Add] = "M10.286 0v10.286h-10.286v3.429h10.286v10.286h3.429v-10.286h10.286v-3.429h-10.286v-10.286z";
paths[AppIconType.Attention] = "M23.288 17.506c0.847 1.513 0.941 2.986 0.265 4.146s-1.999 1.799-3.729 1.799h-15.648c-1.729 0-3.054-0.639-3.73-1.799-0.676-1.161-0.582-2.633 0.265-4.146l7.927-14.151c0.851-1.519 2.045-2.355 3.362-2.355 1.316 0 2.51 0.836 3.361 2.355l7.926 14.151zM21.961 20.717c0.326-0.561 0.223-1.401-0.282-2.303l-7.926-14.151c-0.502-0.896-1.141-1.41-1.753-1.41s-1.252 0.514-1.755 1.41l-7.926 14.151c-0.505 0.902-0.607 1.741-0.28 2.303 0.326 0.559 1.106 0.881 2.137 0.881h15.648c1.031 0 1.81-0.32 2.137-0.881zM11.999 16.484c0.642 0 1.162 0.52 1.162 1.16 0 0.642-0.52 1.162-1.162 1.162-0.641 0-1.161-0.52-1.161-1.162 0-0.641 0.52-1.16 1.161-1.16zM11.999 7.968c0.642 0 1.162 0.535 1.162 1.195 0 0.035-0.006 0.067-0.009 0.101h0.006l-0.463 5.788h-0.004c-0.030 0.367-0.326 0.658-0.691 0.658-0.364 0-0.661-0.291-0.691-0.658h-0.004l-0.463-5.788h0.006c-0.003-0.034-0.009-0.066-0.009-0.101 0-0.66 0.52-1.195 1.161-1.195z";
paths[AppIconType.Avatar] = "M12 0c-6.624 0-12 5.376-12 12s5.376 12 12 12c6.624 0 12-5.376 12-12s-5.376-12-12-12zM12 3.6c1.992 0 3.6 1.608 3.6 3.6s-1.608 3.6-3.6 3.6c-1.992 0-3.6-1.608-3.6-3.6s1.608-3.6 3.6-3.6zM12 20.64c-3 0-5.652-1.536-7.2-3.864 0.036-2.388 4.8-3.696 7.2-3.696 2.388 0 7.164 1.308 7.2 3.696-1.548 2.328-4.2 3.864-7.2 3.864z";
paths[AppIconType.BarGraph] = "M21.333 0h-18.667c-1.467 0-2.667 1.2-2.667 2.667v18.667c0 1.467 1.2 2.667 2.667 2.667h18.667c1.467 0 2.667-1.2 2.667-2.667v-18.667c0-1.467-1.2-2.667-2.667-2.667zM8 18.667h-2.667v-9.333h2.667v9.333zM13.333 18.667h-2.667v-13.333h2.667v13.333zM18.667 18.667h-2.667v-5.333h2.667v5.333z";
paths[AppIconType.Bath] = "M22.8 14.4v1.2c0 2.292-1.284 4.284-3.18 5.292l0.78 3.108h-2.4l-0.6-2.4c-0.204 0-0.396 0-0.6 0h-9.6c-0.204 0-0.396 0-0.6 0l-0.6 2.4h-2.4l0.78-3.108c-1.896-1.008-3.18-3-3.18-5.292v-1.2h-1.2v-2.4h21.6v-8.4c0-0.663-0.537-1.2-1.2-1.2-0.6 0-1.056 0.408-1.2 0.948 0.756 0.648 1.2 1.608 1.2 2.652h-7.2c0-1.988 1.612-3.6 3.6-3.6 0.072 0 0.132 0 0.204 0 0.492-1.392 1.824-2.4 3.396-2.4 1.988 0 3.6 1.612 3.6 3.6v10.8h-1.2zM20.4 14.4h-16.8v1.2c0 1.988 1.612 3.6 3.6 3.6h9.6c1.988 0 3.6-1.612 3.6-3.6v-1.2z";
paths[AppIconType.Bed] = "M19.636 6.182h-15.273v7.636h-2.182v-9.818h-2.182v16.364h2.182v-3.273h19.636v3.273h2.182v-9.818c0-2.41-1.954-4.364-4.364-4.364z";
paths[AppIconType.Calendar] = "M17.8 13.2h-6v6h6v-6zM16.6 0v2.4h-9.6v-2.4h-2.4v2.4h-1.2c-1.332 0-2.388 1.080-2.388 2.4l-0.012 16.8c0 1.32 1.068 2.4 2.4 2.4h16.8c1.32 0 2.4-1.080 2.4-2.4v-16.8c0-1.32-1.080-2.4-2.4-2.4h-1.2v-2.4h-2.4zM20.2 21.6h-16.8v-13.2h16.8v13.2z";
paths[AppIconType.CameraAdd] = "M3.13 4.13v-3.13h2.087v3.13h3.13v2.087h-3.13v3.13h-2.087v-3.13h-3.13v-2.087h3.13zM6.261 10.391v-3.13h3.13v-3.13h7.304l1.91 2.087h3.308c1.148 0 2.087 0.939 2.087 2.087v12.522c0 1.148-0.939 2.087-2.087 2.087h-16.696c-1.148 0-2.087-0.939-2.087-2.087v-10.435h3.13zM13.565 19.783c2.88 0 5.217-2.337 5.217-5.217s-2.337-5.217-5.217-5.217c-2.88 0-5.217 2.337-5.217 5.217s2.337 5.217 5.217 5.217zM10.435 14.565c0 1.732 1.398 3.13 3.13 3.13s3.13-1.398 3.13-3.13c0-1.732-1.398-3.13-3.13-3.13s-3.13 1.398-3.13 3.13z";
paths[AppIconType.Camera] = "M12 16.84c2.121 0 3.84-1.719 3.84-3.84s-1.719-3.84-3.84-3.84c-2.121 0-3.84 1.719-3.84 3.84s1.719 3.84 3.84 3.84zM21.6 3.4h-3.804l-2.196-2.4h-7.2l-2.196 2.4h-3.804c-1.32 0-2.4 1.080-2.4 2.4v14.4c0 1.32 1.080 2.4 2.4 2.4h19.2c1.32 0 2.4-1.080 2.4-2.4v-14.4c0-1.32-1.080-2.4-2.4-2.4zM12 19c-3.312 0-6-2.688-6-6s2.688-6 6-6c3.312 0 6 2.688 6 6s-2.688 6-6 6z";
paths[AppIconType.Chat] = "M21.6 0h-19.2c-1.32 0-2.4 1.080-2.4 2.4v21.6l4.8-4.8h16.8c1.32 0 2.4-1.080 2.4-2.4v-14.4c0-1.32-1.080-2.4-2.4-2.4zM8.4 10.8h-2.4v-2.4h2.4v2.4zM13.2 10.8h-2.4v-2.4h2.4v2.4zM18 10.8h-2.4v-2.4h2.4v2.4z";
paths[AppIconType.Check] = "M7.622 17.446l-5.691-5.691-1.931 1.931 7.622 7.622 16.378-16.378-1.931-1.931z";
paths[AppIconType.Children] = "M9 11.75c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25zM15 11.75c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10c5.52 0 10-4.48 10-10s-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8 0-0.29 0.020-0.58 0.050-0.86 2.36-1.050 4.23-2.98 5.21-5.37 1.81 2.56 4.79 4.23 8.16 4.23 0.78 0 1.53-0.090 2.25-0.26 0.21 0.71 0.33 1.47 0.33 2.26 0 4.41-3.59 8-8 8z";
paths[AppIconType.City] = "M12 6.444v-4.444h-11v20h22v-15.556h-11zM5.4 19.778h-2.2v-2.222h2.2v2.222zM5.4 15.333h-2.2v-2.222h2.2v2.222zM5.4 10.889h-2.2v-2.222h2.2v2.222zM5.4 6.444h-2.2v-2.222h2.2v2.222zM9.8 19.778h-2.2v-2.222h2.2v2.222zM9.8 15.333h-2.2v-2.222h2.2v2.222zM9.8 10.889h-2.2v-2.222h2.2v2.222zM9.8 6.444h-2.2v-2.222h2.2v2.222zM20.8 19.778h-8.8v-2.222h2.2v-2.222h-2.2v-2.222h2.2v-2.222h-2.2v-2.222h8.8v11.111zM18.6 10.889h-2.2v2.222h2.2v-2.222zM18.6 15.333h-2.2v2.222h2.2v-2.222z";
paths[AppIconType.Cloud] = "M19.35 10.040c-0.68-3.45-3.71-6.040-7.35-6.040-2.89 0-5.39 1.64-6.64 4.040-3.010 0.32-5.36 2.86-5.36 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.050-4.78-4.65-4.96zM19 18h-13c-2.21 0-4-1.79-4-4s1.79-4 4-4c2.21 0 4 1.79 4 4h2c0-2.76-1.86-5.080-4.4-5.78 1.010-1.34 2.6-2.22 4.4-2.22 3.030 0 5.5 2.47 5.5 5.5v0.5h1.5c1.65 0 3 1.35 3 3s-1.35 3-3 3z";
paths[AppIconType.Cross] = "M24 2.4l-2.4-2.4-9.6 9.6-9.6-9.6-2.4 2.4 9.6 9.6-9.6 9.6 2.4 2.4 9.6-9.6 9.6 9.6 2.4-2.4-9.6-9.6z";
paths[AppIconType.Dash] = "M19.907 11h-15.814c-0.604 0-1.093 0.672-1.093 1.5s0.489 1.5 1.093 1.5h15.814c0.604 0 1.093-0.672 1.093-1.5s-0.489-1.5-1.093-1.5z";
paths[AppIconType.Docs] = "M2.182 4.364h-2.182v17.455c0 1.2 0.982 2.182 2.182 2.182h17.455v-2.182h-17.455v-17.455zM21.818 0h-15.273c-1.2 0-2.182 0.982-2.182 2.182v15.273c0 1.2 0.982 2.182 2.182 2.182h15.273c1.2 0 2.182-0.982 2.182-2.182v-15.273c0-1.2-0.982-2.182-2.182-2.182zM21.818 17.455h-15.273v-15.273h15.273v15.273z";
paths[AppIconType.Document] = "M4.4 0c-1.32 0-2.388 1.080-2.388 2.4l-0.012 19.2c0 1.32 1.068 2.4 2.388 2.4h14.412c1.32 0 2.4-1.080 2.4-2.4v-14.4l-7.2-7.2h-9.6zM12.8 8.4v-6.6l6.6 6.6h-6.6z";
paths[AppIconType.Domain] = "M15 11v-6l-3-3-3 3v2h-6v14h18v-10h-6zM7 19h-2v-2h2v2zM7 15h-2v-2h2v2zM7 11h-2v-2h2v2zM13 19h-2v-2h2v2zM13 15h-2v-2h2v2zM13 11h-2v-2h2v2zM13 7h-2v-2h2v2zM19 19h-2v-2h2v2zM19 15h-2v-2h2v2z";
paths[AppIconType.Donut] = "M0 12c0-6.624 5.376-12 12-12s12 5.376 12 12c0 6.624-5.376 12-12 12s-12-5.376-12-12zM12 19.2c3.972 0 7.2-3.228 7.2-7.2s-3.228-7.2-7.2-7.2c-3.972 0-7.2 3.228-7.2 7.2s3.228 7.2 7.2 7.2z";
paths[AppIconType.Download] = "M21.769 8.463l-9.917 9.851-9.851-9.851h5.62v-8.463h8.463v8.463h5.686zM2 21.157h19.769v2.843h-19.769v-2.843z";
paths[AppIconType.Edit] = "M0 19l14.75-14.75 5 5-14.75 14.75h-5v-5zM23.625 5.375l-2.438 2.437-5-5 2.438-2.438c0.25-0.25 0.563-0.375 0.938-0.375s0.687 0.125 0.938 0.375l3.125 3.125c0.25 0.25 0.375 0.563 0.375 0.938s-0.125 0.687-0.375 0.938z";
paths[AppIconType.Email] = "M21.6 2h-19.2c-1.32 0-2.388 1.080-2.388 2.4l-0.012 14.4c0 1.32 1.080 2.4 2.4 2.4h19.2c1.32 0 2.4-1.080 2.4-2.4v-14.4c0-1.32-1.080-2.4-2.4-2.4zM21.6 6.8l-9.6 6-9.6-6v-2.4l9.6 6 9.6-6v2.4z";
paths[AppIconType.Error] = "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z";
paths[AppIconType.Excalamation] = "M11.667 24c1.473 0 2.667-1.194 2.667-2.667s-1.194-2.667-2.667-2.667c-1.473 0-2.667 1.194-2.667 2.667s1.194 2.667 2.667 2.667zM9 0h5.333v16h-5.333v-16z";
paths[AppIconType.Filter] = "M9 20v-3h5v3h-5zM0 4h24v3h-24v-3zM4 14v-3h16v3h-16z";
paths[AppIconType.Flag] = "M14.289 2.843h7.868v14.083h-9.851l-0.595-2.777h-7.868v9.851h-2.843v-24h12.694z";
paths[AppIconType.Flower] = "M12.286 24c5.68 0 10.286-4.606 10.286-10.286-5.68 0-10.286 4.606-10.286 10.286zM4.971 10.571c0 1.577 1.28 2.857 2.857 2.857 0.606 0 1.154-0.183 1.623-0.503l-0.023 0.217c0 1.577 1.28 2.857 2.857 2.857s2.857-1.28 2.857-2.857l-0.023-0.217c0.457 0.32 1.017 0.503 1.623 0.503 1.577 0 2.857-1.28 2.857-2.857 0-1.143-0.674-2.114-1.634-2.571 0.96-0.457 1.634-1.429 1.634-2.571 0-1.577-1.28-2.857-2.857-2.857-0.606 0-1.154 0.183-1.623 0.503l0.023-0.217c0-1.577-1.28-2.857-2.857-2.857s-2.857 1.28-2.857 2.857l0.023 0.217c-0.457-0.32-1.017-0.503-1.623-0.503-1.577 0-2.857 1.28-2.857 2.857 0 1.143 0.674 2.114 1.634 2.571-0.96 0.457-1.634 1.429-1.634 2.571zM12.286 5.143c1.577 0 2.857 1.28 2.857 2.857s-1.28 2.857-2.857 2.857c-1.577 0-2.857-1.28-2.857-2.857s1.28-2.857 2.857-2.857zM2 13.714c0 5.68 4.606 10.286 10.286 10.286 0-5.68-4.606-10.286-10.286-10.286z";
paths[AppIconType.Furnished] = "M4 18v3h3v-3h10v3h3v-6h-16v3zM19 10h3v3h-3v-3zM2 10h3v3h-3v-3zM17 13h-10v-8c0-1.1 0.9-2 2-2h6c1.1 0 2 0.9 2 2v8z";
paths[AppIconType.Gym] = "M20.57 14.86l1.43-1.43-1.43-1.43-3.57 3.57-8.57-8.57 3.57-3.57-1.43-1.43-1.43 1.43-1.43-1.43-2.14 2.14-1.43-1.43-1.43 1.43 1.43 1.43-2.14 2.14 1.43 1.43-1.43 1.43 1.43 1.43 3.57-3.57 8.57 8.57-3.57 3.57 1.43 1.43 1.43-1.43 1.43 1.43 2.14-2.14 1.43 1.43 1.43-1.43-1.43-1.43 2.14-2.14z";
paths[AppIconType.Home] = "M10 20v-6h4v6h5v-8h3l-10-9-10 9h3v8z";
paths[AppIconType.HouseAdd] = "M12.4 0c-4.632 0-8.4 3.768-8.4 8.4 0 6.3 8.4 15.6 8.4 15.6s8.4-9.3 8.4-15.6c0-4.632-3.768-8.4-8.4-8.4zM17.2 9.6h-3.6v3.6h-2.4v-3.6h-3.6v-2.4h3.6v-3.6h2.4v3.6h3.6v2.4z";
paths[AppIconType.Income] = "M11.304 9.311l-0 0c-0.154 0.124-0.216 0.248-0.247 0.401 0 0.031 0.030 0.465 1.605 1.179 1.081 0.497 1.821 0.962 2.284 1.489 0.525 0.558 0.772 1.272 0.772 2.109 0 0.775-0.277 1.489-0.833 2.109-0.432 0.497-1.018 0.869-1.667 1.085v1.767h-2.563v-1.674c-0.865-0.155-1.636-0.465-2.254-0.962l-0.402-0.342 1.235-2.171 0.556 0.465c0.555 0.435 1.203 0.652 1.975 0.652 0.463 0 0.802-0.092 1.049-0.279 0.216-0.155 0.309-0.34 0.309-0.589 0-0.558-0.926-1.024-1.451-1.24-2.13-0.93-3.21-2.079-3.21-3.443 0-0.806 0.279-1.489 0.803-2.077 0.402-0.465 0.958-0.775 1.575-0.992v-1.799h2.563v1.737c0.617 0.125 1.203 0.372 1.821 0.713l0.556 0.31-1.389 2.264-0.556-0.465c-0.37-0.342-0.926-0.497-1.667-0.497-0.34 0-0.649 0.093-0.865 0.248zM2.296 9.306l0 0c-0.53 2.016-0.413 4.122 0.294 6.050l-1.708 1.008c-2.208-5.396-0.177-11.771 5.006-14.767 4.947-2.876 11.071-1.749 14.693 2.431l1.236-0.711-0.472 3.914-3.622-1.542 1.119-0.652c-1.236-1.305-2.767-2.224-4.534-2.699-2.533-0.682-5.183-0.325-7.45 0.979s-3.886 3.439-4.564 5.989zM23.116 7.636l-0 0c2.211 5.386 0.177 11.75-5.043 14.768-4.924 2.871-11.086 1.746-14.713-2.398l-1.179 0.681 0.471-3.937 3.627 1.539-1.149 0.681c1.238 1.303 2.771 2.22 4.54 2.694 2.536 0.681 5.19 0.326 7.46-0.977 2.27-1.302 3.892-3.432 4.57-5.978 0.56-2.042 0.442-4.144-0.295-6.067l1.71-1.006z";
paths[AppIconType.Light] = "M2.782 19.589l1.538 1.538 1.953-1.964-1.538-1.538-1.953 1.964zM10.909 23.855h2.182v-3.218h-2.182v3.218zM3.273 10.818h-3.273v2.182h3.273v-2.182zM15.273 6.247v-5.247h-6.545v5.247c-1.953 1.135-3.273 3.24-3.273 5.662 0 3.611 2.935 6.545 6.545 6.545s6.545-2.935 6.545-6.545c0-2.422-1.32-4.527-3.273-5.662zM20.727 10.818v2.182h3.273v-2.182h-3.273zM17.716 19.175l1.953 1.964 1.538-1.538-1.964-1.953-1.527 1.527z";
paths[AppIconType.MapMarker] = "M12.4 0c-4.644 0-8.4 3.756-8.4 8.4 0 6.3 8.4 15.6 8.4 15.6s8.4-9.3 8.4-15.6c0-4.644-3.756-8.4-8.4-8.4zM12.4 11.4c-1.656 0-3-1.344-3-3s1.344-3 3-3c1.656 0 3 1.344 3 3s-1.344 3-3 3z";
paths[AppIconType.NavArrowDown] = "M10.688 0h2.625v18.875l4.812-4.75 1.875 1.875-8 8-8-8 1.875-1.875 4.812 4.75z";
paths[AppIconType.NavArrowUpDown] = "M17.688 18.687h4l-5.375 5.313-5.313-5.313h4v-9.375h2.688v9.375zM8.313 0l5.375 5.313h-4v9.375h-2.688v-9.375h-4l5.313-5.313z";
paths[AppIconType.NavArrowUp] = "M10.688 24v-18.875l-4.812 4.75-1.875-1.875 8-8 8 8-1.875 1.875-4.812-4.75v18.875z";
paths[AppIconType.NavCaretDown] = "M5 9h14l-6.967 6z";
paths[AppIconType.NavCaretLeft] = "M15 5v14l-6-6.967z";
paths[AppIconType.NavCaretRight] = "M9 19v-14l6 6.967z";
paths[AppIconType.NavCaretUp] = "M5 16l7-7 7 7z";
paths[AppIconType.NavChevronDown] = "M2.82 5l9.18 9.16 9.18-9.16 2.82 2.82-12 12-12-12z";
paths[AppIconType.NavChevronLeft] = "M19.82 2.82l-2.82-2.82-12 12 12 12 2.82-2.82-9.16-9.18z";
paths[AppIconType.NavChevronRight] = "M5 21.18l9.16-9.18-9.16-9.18 2.82-2.82 12 12-12 12z";
paths[AppIconType.NavChevronUp] = "M21.18 19.41l-9.18-9.16-9.18 9.16-2.82-2.82 12-12 12 12z";
paths[AppIconType.NavHamburger] = "M0 20h24v-2h-24v2zM0 13h24v-2h-24v2zM0 4v2h24v-2h-24z";
paths[AppIconType.Parking] = "M21.227 2.347c-0.267-0.787-1.013-1.347-1.893-1.347h-14.667c-0.88 0-1.613 0.56-1.893 1.347l-2.773 7.987v10.667c0 0.733 0.6 1.333 1.333 1.333h1.333c0.733 0 1.333-0.6 1.333-1.333v-1.333h16v1.333c0 0.733 0.6 1.333 1.333 1.333h1.333c0.733 0 1.333-0.6 1.333-1.333v-10.667l-2.773-7.987zM4.667 15.667c-1.107 0-2-0.893-2-2s0.893-2 2-2c1.107 0 2 0.893 2 2s-0.893 2-2 2zM19.333 15.667c-1.107 0-2-0.893-2-2s0.893-2 2-2c1.107 0 2 0.893 2 2s-0.893 2-2 2zM2.667 9l2-6h14.667l2 6h-18.667z";
paths[AppIconType.Password] = "M17.81 4.47c-0.080 0-0.16-0.020-0.23-0.060-1.92-0.99-3.58-1.41-5.57-1.41-1.98 0-3.86 0.47-5.57 1.41-0.24 0.13-0.54 0.040-0.68-0.2-0.13-0.24-0.040-0.55 0.2-0.68 1.86-1.010 3.9-1.53 6.050-1.53 2.13 0 3.99 0.47 6.030 1.52 0.25 0.13 0.34 0.43 0.21 0.67-0.090 0.18-0.26 0.28-0.44 0.28zM3.5 9.72c-0.1 0-0.2-0.030-0.29-0.090-0.23-0.16-0.28-0.47-0.12-0.7 0.99-1.4 2.25-2.5 3.75-3.27 3.14-1.62 7.16-1.63 10.31-0.010 1.5 0.77 2.76 1.86 3.75 3.25 0.16 0.22 0.11 0.54-0.12 0.7s-0.54 0.11-0.7-0.12c-0.9-1.26-2.040-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4 0.010-1.36 0.7-2.5 1.7-3.4 2.96-0.080 0.14-0.23 0.21-0.39 0.21zM9.75 21.79c-0.13 0-0.26-0.050-0.35-0.15-0.87-0.87-1.34-1.43-2.010-2.64-0.69-1.23-1.050-2.73-1.050-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 0.28-0.22 0.5-0.5 0.5s-0.5-0.22-0.5-0.5c0-2.42-2.090-4.39-4.66-4.39s-4.66 1.97-4.66 4.39c0 1.44 0.32 2.77 0.93 3.85 0.64 1.15 1.080 1.64 1.85 2.42 0.19 0.2 0.19 0.51 0 0.71-0.11 0.1-0.24 0.15-0.37 0.15zM16.92 19.94c-1.19 0-2.24-0.3-3.1-0.89-1.49-1.010-2.38-2.65-2.38-4.39 0-0.28 0.22-0.5 0.5-0.5s0.5 0.22 0.5 0.5c0 1.41 0.72 2.74 1.94 3.56 0.71 0.48 1.54 0.71 2.54 0.71 0.24 0 0.64-0.030 1.040-0.1 0.27-0.050 0.53 0.13 0.58 0.41 0.050 0.27-0.13 0.53-0.41 0.58-0.57 0.11-1.070 0.12-1.21 0.12zM14.91 22c-0.040 0-0.090-0.010-0.13-0.020-1.59-0.44-2.63-1.030-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.080-2.94s3.080 1.32 3.080 2.94c0 1.070 0.93 1.94 2.080 1.94s2.080-0.87 2.080-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.030-0.39 0.81-0.59 1.76-0.59 2.8 0 0.78 0.070 2.010 0.67 3.61 0.1 0.26-0.030 0.55-0.29 0.64-0.26 0.1-0.55-0.040-0.64-0.29-0.49-1.31-0.73-2.61-0.73-3.96 0-1.2 0.23-2.29 0.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.080 2.94s-3.080-1.32-3.080-2.94c0-1.070-0.93-1.94-2.080-1.94s-2.080 0.87-2.080 1.94c0 1.71 0.66 3.31 1.87 4.51 0.95 0.94 1.86 1.46 3.27 1.85 0.27 0.070 0.42 0.35 0.35 0.61-0.050 0.23-0.26 0.38-0.47 0.38z";
paths[AppIconType.Paw] = "M3 11.85c-1.657 0-3-1.349-3-3.014s1.343-3.014 3-3.014c1.657 0 3 1.349 3 3.014s-1.343 3.014-3 3.014zM8.4 7.028c-1.657 0-3-1.349-3-3.014s1.343-3.014 3-3.014c1.657 0 3 1.349 3 3.014s-1.343 3.014-3 3.014zM15.6 7.028c-1.657 0-3-1.349-3-3.014s1.343-3.014 3-3.014c1.657 0 3 1.349 3 3.014s-1.343 3.014-3 3.014zM21 11.85c-1.657 0-3-1.349-3-3.014s1.343-3.014 3-3.014c1.657 0 3 1.349 3 3.014s-1.343 3.014-3 3.014zM18.408 15.298c1.578 1.573 3.498 3.327 3.15 5.781-0.354 1.23-1.23 2.453-2.802 2.803-0.876 0.175-3.672-0.524-6.648-0.524-0.036 0-0.072 0.006-0.108 0.006s-0.072-0.006-0.108-0.006c-2.976 0-5.772 0.699-6.648 0.524-1.578-0.35-2.454-1.579-2.802-2.803-0.348-2.453 1.578-4.207 3.15-5.781 1.050-1.224 1.926-2.278 2.976-3.502 0.558-0.651 1.26-1.308 2.094-1.591 0.132-0.042 0.264-0.078 0.396-0.102 0.306-0.060 0.63-0.060 0.942-0.060s0.636 0 0.942 0.060c0.138 0.024 0.27 0.060 0.396 0.102 0.834 0.283 1.536 0.94 2.094 1.591 1.050 1.224 1.926 2.278 2.976 3.502z";
paths[AppIconType.People] = "M16.364 10.545c1.811 0 3.262-1.462 3.262-3.273s-1.451-3.273-3.262-3.273c-1.811 0-3.273 1.462-3.273 3.273s1.462 3.273 3.273 3.273zM7.636 10.545c1.811 0 3.262-1.462 3.262-3.273s-1.451-3.273-3.262-3.273c-1.811 0-3.273 1.462-3.273 3.273s1.462 3.273 3.273 3.273zM7.636 12.727c-2.542 0-7.636 1.276-7.636 3.818v2.727h15.273v-2.727c0-2.542-5.095-3.818-7.636-3.818zM16.364 12.727c-0.316 0-0.676 0.022-1.058 0.055 1.265 0.916 2.149 2.149 2.149 3.764v2.727h6.545v-2.727c0-2.542-5.095-3.818-7.636-3.818z";
paths[AppIconType.Phone] = "M4.827 10.387c1.92 3.773 5.013 6.853 8.787 8.787l2.933-2.933c0.36-0.36 0.893-0.48 1.36-0.32 1.493 0.493 3.107 0.76 4.76 0.76 0.733 0 1.333 0.6 1.333 1.333v4.653c0 0.733-0.6 1.333-1.333 1.333-12.52 0-22.667-10.147-22.667-22.667 0-0.733 0.6-1.333 1.333-1.333h4.667c0.733 0 1.333 0.6 1.333 1.333 0 1.667 0.267 3.267 0.76 4.76 0.147 0.467 0.040 0.987-0.333 1.36l-2.933 2.933z";
paths[AppIconType.Print] = "M20.403 7.014c0.974 0 1.817 0.347 2.529 1.040s1.068 1.546 1.068 2.557v7.194h-4.834v4.778h-14.389v-4.778h-4.778v-7.194c0-1.012 0.347-1.864 1.040-2.557s1.546-1.040 2.557-1.040h16.806zM16.806 20.166v-5.958h-9.611v5.958h9.611zM20.375 11.792c0.318 0 0.6-0.122 0.843-0.365s0.365-0.525 0.365-0.843c0-0.318-0.122-0.6-0.365-0.843s-0.525-0.365-0.843-0.365c-0.318 0-0.6 0.122-0.843 0.365s-0.365 0.525-0.365 0.843c0 0.318 0.122 0.6 0.365 0.843s0.525 0.365 0.843 0.365zM19.166 1v4.778h-14.389v-4.778h14.389z";
paths[AppIconType.Return] = "M9.471 7.059l2.005 2.005-5.068 5.054h12.946v-14.118h2.824v16.941h-15.769l5.068 5.054-2.005 2.005-8.471-8.471z";
paths[AppIconType.Search] = "M17.154 15.093h-1.089l-0.379-0.376c1.338-1.56 2.152-3.583 2.152-5.799 0-4.926-3.993-8.919-8.919-8.919s-8.919 3.993-8.919 8.919c0 4.926 3.993 8.919 8.919 8.919 2.216 0 4.237-0.812 5.797-2.149l0.379 0.376v1.087l6.858 6.848 2.046-2.046-6.844-6.861zM8.919 15.093c-3.411 0-6.175-2.763-6.175-6.175 0-3.41 2.763-6.175 6.175-6.175 3.41 0 6.175 2.765 6.175 6.175 0 3.411-2.765 6.175-6.175 6.175v0z";
paths[AppIconType.SocialScore] = "M17.751 0h-11.824c-2.721 0-4.927 2.224-4.927 4.968v8.703c0 2.744 2.206 4.969 4.927 4.969h2.844l3.068 5.36 3.067-5.36h2.844c2.722 0 4.927-2.225 4.927-4.969l0.001-8.703c0-2.744-2.206-4.968-4.927-4.968h0zM7.967 13.325c0 0.765-0.519 1.384-1.161 1.384s-1.161-0.619-1.161-1.384l0.001-4.199c0-0.766 0.52-1.384 1.161-1.384s1.161 0.618 1.161 1.384l-0.001 4.199zM18.032 12.585c0 1.174-0.919 2.125-2.050 2.125h-5.191c-1.133 0-2.050-0.951-2.050-2.125v-2.749c0-1.029 0.705-1.887 1.641-2.084 0.895-1.365 1.316-2.571 1.316-2.571s0.121-0.533 0.328-1.141c0.022-0.093 0.054-0.182 0.094-0.266 0.196-0.402 0.597-0.677 1.061-0.677 0.551 0 1.015 0.388 1.149 0.918 0.015 0.058 0.026 0.115 0.032 0.176 0.219 1.391-0.245 2.735-0.606 3.521h2.225c1.132 0 2.050 0.951 2.050 2.125l0.001 2.748z";
paths[AppIconType.Star] = "M12 17.27l6.18 3.73-1.64-7.030 5.46-4.73-7.19-0.61-2.81-6.63-2.81 6.63-7.19 0.61 5.46 4.73-1.64 7.030z";
paths[AppIconType.ToggleCheckBoxDeselect] = "M21.333 2.667v18.667h-18.667v-18.667h18.667zM21.333 0h-18.667c-1.467 0-2.667 1.2-2.667 2.667v18.667c0 1.467 1.2 2.667 2.667 2.667h18.667c1.467 0 2.667-1.2 2.667-2.667v-18.667c0-1.467-1.2-2.667-2.667-2.667z";
paths[AppIconType.ToggleCheckBoxNeutral] = "M21.333 0h-18.667c-1.467 0-2.667 1.2-2.667 2.667v18.667c0 1.467 1.2 2.667 2.667 2.667h18.667c1.467 0 2.667-1.2 2.667-2.667v-18.667c0-1.467-1.2-2.667-2.667-2.667zM18.667 13.333h-13.333v-2.667h13.333v2.667z";
paths[AppIconType.ToggleCheckBoxSelect] = "M21.333 0h-18.667c-1.48 0-2.667 1.2-2.667 2.667v18.667c0 1.467 1.187 2.667 2.667 2.667h18.667c1.48 0 2.667-1.2 2.667-2.667v-18.667c0-1.467-1.187-2.667-2.667-2.667zM9.333 18.667l-6.667-6.667 1.88-1.88 4.787 4.773 10.12-10.12 1.88 1.893-12 12z";
paths[AppIconType.ToggleCheckBoxWarning] = "M21.333 0c1.467 0 2.667 1.2 2.667 2.667v18.667c0 1.467-1.2 2.667-2.667 2.667h-18.667c-1.467 0-2.667-1.2-2.667-2.667v-18.667c0-1.467 1.2-2.667 2.667-2.667h18.667zM11.998 20c0.982 0 1.778-0.796 1.778-1.778s-0.796-1.778-1.778-1.778c-0.982 0-1.778 0.796-1.778 1.778s0.796 1.778 1.778 1.778zM10.22 4v10.667h3.556v-10.667h-3.556z";
paths[AppIconType.Trash] = "M4.313 21.312v-16h16v16c0 0.75-0.26 1.385-0.781 1.906s-1.135 0.781-1.844 0.781h-10.688c-0.75 0-1.385-0.26-1.906-0.781s-0.781-1.156-0.781-1.906zM21.687 1.313v2.688h-18.687v-2.688h4.688l1.313-1.313h6.687l1.313 1.313h4.688z";

export enum AppIconSize {
    Small = 12,
    Smaller = 14,
    Normal = 18,
    Larger = 24,
    Large = 30,
}

export interface AppIconProps {
    readonly type: AppIconType;
    readonly size: AppIconSize;
    readonly color?: string | undefined; // default: black
    readonly style?: React.CSSProperties | undefined;
    readonly className?: string | undefined;
}

/// <summary>
/// Creating an SVG Icon System with React 
/// https://css-tricks.com/creating-svg-icon-system-react/
/// </summary>
export class AppIcon extends React.Component<AppIconProps, {}> {

    public constructor(props: AppIconProps) {
        super(props);
    }

    public render(): JSX.Element | null {
        const { size, type, color, style, className } = { ...this.props };

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" aria-labelledby="{type}" style={style} className={className}>
                <title>{type}</title>
                <path d={paths[type]} fill={color} />
            </svg>
        );
    }
}
