import { makeStyles } from "@material-ui/core";
import { color } from "../../theme/Theme";

export const VideoPlayerStyles = makeStyles({
  playerWrapper: {
    position: "fixed",
    zIndex: 10000,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.25)",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  reactPlayer: {
    height: "auto !important",
    maxHeight: '80vh',
    width: "80% !important",
    marginBottom: "1em",
  },
  closeIconContainer: {
    background: "rgba(0,0,0,.8)",
    color: color.light,
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
});
