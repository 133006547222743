import { makeStyles } from "@material-ui/core";
import { pxToem } from "../../helper/Validation";
import { color } from "../../theme/Theme";

export const forgotPasswordStyles = makeStyles({
  titleStyle: {
    paddingBottom: "2em",
  },
  labelStyle: {
    color: color.primary,
    margin: 0,
    fontWeight: 500,
  },
  loginButton: {
    padding: "16px 0",
    marginTop: "15px",
    borderRadius: "0px",

    backgroundColor: color.teal,
    color: color.light,
    textAlign: "center",
    "&:hover": {
      backgroundColor: color.teal,
    },
  },
  loginButtonDisabled: {
    padding: "16px 0",
    marginTop: "15px",
    borderRadius: "0px",

    color: color.secondary200,
    backgroundColor: color.light,
  },
  loginLink: {
    fontSize: "1em",
    fontWeight: 500,
    color: color.primary,
  },
  bottomLink: {
    margin: 0,
    paddingTop: "24px",
    fontWeight: 300,
    textAlign: "center",
    fontColor: "black",
    marginLeft: pxToem(10),
  },
  buttonContainer: {
    marginTop: "3em",
    textAlign: "center",
  },
  resetLinkMessageContainer: {
    borderRadius: "4px",
    background: color.lightCyan,
    display: "flex",
    alignItems: "center",
    marginBottom: "2em",
    padding: ".5em .7em",
  },
  listItemAvatar: {
    minWidth: "40px",
  },
  resetLinkMessage: {
    display: "block",
  },
});
