import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";
import { AbnDetails } from "./AbnDetails.data";

export class AbnService {
  private static service = new ApiService(ServiceType.Abn);
  public static getAbnDetails = (abn: number) => {
    return AbnService.service.get<AbnDetails>({ query: { q: abn } });
  };
}
