/// <summary>
/// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
export enum ServiceType {
  RefreshToken = "auth/refresh",
  Auth = "auth",
  Trades = "api/trades",
  Trade = "api/trade",
  OnboardingStage = "api/user",
  Offices = "api/offices",
  TradeCategories = "api/trade-categories",
  Users = "api/users",
  Quotes = "api/quotes",
  QuotesBff = "bff/api/quotes",
  ServiceFee = "api/service-fee",
  TradesBff = "bff/api/trades",
  TradeBff = "bff/api/trade",
  User = "api/user",
  UserTradeCategories = "api/user-trade-categories",
  Tenancy = "tenancy/api",
  Terms = "api/terms",
  TermsBff = "bff/api/terms",
  ValidateUser = "auth/user",
  JobsBff = "bff/api/jobs",
  Jobs = "api/jobs",
  QuoteVariance = "api/quotes/variance",
  Comment = "api/comment",
  Abn = "api/abn",
  Dashboard = "api/dashboard",
  Payment = "api/sorted",
}
