export class TypeHelper {
  public static tryParseInt<T>(
    s: string | number | boolean | undefined | null,
    defaultValue: T
  ): number | T {
    if (!TypeHelper.isNullOrUndefined(s)) {
      if (typeof s === "number") {
        return Math.floor(s as number);
      } else if (typeof s === "boolean") {
        return (s as boolean) ? 1 : 0;
      } else {
        try {
          const i = parseInt(s!, 10);

          return !isNaN(i) ? i : defaultValue;
        } catch {
          return defaultValue;
        }
      }
    } else {
      return defaultValue;
    }
  }

  public static tryParseFloat<T>(
    s: string | number | boolean | undefined | null,
    defaultValue: T
  ): number | T {
    if (!TypeHelper.isNullOrUndefined(s)) {
      if (typeof s === "number") {
        return s as number;
      } else if (typeof s === "boolean") {
        return (s as boolean) ? 1 : 0;
      } else {
        try {
          const i = parseFloat(s!);
          return !isNaN(i) ? i : defaultValue;
        } catch {
          return defaultValue;
        }
      }
    } else {
      return defaultValue;
    }
  }

  public static toString<T>(obj: any, emptyValue: T): string | T {
    return TypeHelper.isNullOrUndefined(obj)
      ? emptyValue
      : obj.toString() || emptyValue;
  }

  public static isNullOrUndefined(obj: any): boolean {
    return obj == null || obj === undefined;
  }

  public static eliminateNullOrUndefined<T1, T2>(
    obj: T1 | undefined | null,
    valueIfNullOrUndefined: T2
  ): T1 | T2 {
    return obj !== null && obj !== undefined ? obj : valueIfNullOrUndefined;
  }

  public static eliminateNullOrUndefinedMember<T1, T2, T3>(
    obj: T1 | undefined | null,
    getMember: (obj: T1) => T2,
    valueIfNullOrUndefined: T3
  ): T2 | T3 {
    return obj !== null && obj !== undefined
      ? TypeHelper.eliminateNullOrUndefined(
          getMember(obj),
          valueIfNullOrUndefined
        )
      : valueIfNullOrUndefined;
  }

  /// <summary>
  /// Merge arrays by keys. Overwrite or append, no deletion.
  /// </summary>
  public static arrayMerge<T>(
    array1: T[] | undefined | null,
    array2: T[] | undefined | null,
    match: (item1: T, item2: T) => boolean
  ): T[] {
    if (!array1 || array1.length === 0) {
      return array2 || [];
    } else if (!array2 || array2.length === 0) {
      return array1 || [];
    } else {
      const result = [...array1];
      const notFound: T[] = [];

      for (const item2 of array2) {
        const i = array1.findIndex(item1 => match(item1, item2));

        if (i >= 0) {
          result[i] = item2; // overwrite
        } else {
          notFound.push(item2); // append
        }
      }

      return [...result, ...notFound];
    }
  }

  public static safeArrayJoin<T>(
    separator: string,
    array: T[] | undefined | null
  ): string {
    return array ? array.join(separator) : "";
  }

  /* tslint:disable:cyclomatic-complexity */
  public static arrayEquals<T>(
    array1: T[] | undefined | null,
    array2: T[] | undefined | null,
    compare?: ((item1: T, item2: T) => boolean) | undefined | null
  ): boolean {
    // will be fast, if the references are equal
    if (array1 === array2) {
      return true;
    } else if (!array1 || array1.length === 0) {
      return !array2 || array2.length === 0;
    } else if (
      !array2 ||
      array2.length === 0 ||
      array1.length !== array2.length
    ) {
      return false;
    } else {
      // same length, different instance
      const length = array1.length;

      if (!compare) {
        compare = (t1, t2) => t1 === t2;
      }

      for (let i = 0; i < length; i++) {
        if (!compare(array1[i], array2[i])) {
          return false;
        }
      }

      return true;
    }
  }
  /* tslint:enable */

  public static safeStringCompare(
    s1: string | undefined | null,
    s2: string | undefined | null,
    ignoreCase?: boolean | undefined | null,
    trim?: boolean | undefined | null
  ): number {
    if (ignoreCase) {
      if (trim) {
        return TypeHelper.toString(s1, "")
          .trim()
          .toLocaleLowerCase()
          .localeCompare(
            TypeHelper.toString(s2, "")
              .trim()
              .toLocaleLowerCase()
          );
      } else {
        return TypeHelper.toString(s1, "")
          .toLocaleLowerCase()
          .localeCompare(TypeHelper.toString(s2, "").toLocaleLowerCase());
      }
    } else if (trim) {
      return TypeHelper.toString(s1, "")
        .trim()
        .localeCompare(TypeHelper.toString(s2, "").trim());
    } else {
      return TypeHelper.toString(s1, "").localeCompare(
        TypeHelper.toString(s2, "")
      );
    }
  }

  public static safeStringEquals(
    s1: string | undefined | null,
    s2: string | undefined | null,
    ignoreCase?: boolean | undefined | null,
    trim?: boolean | undefined | null
  ): boolean {
    return TypeHelper.safeStringCompare(s1, s2, ignoreCase, trim) === 0;
  }

  public static arrayEliminateEmpty<T, T2>(
    array: T[] | undefined | null,
    valueIfNullUndefinedOrEmpty: T2
  ): T[] | T2 {
    return array && array.length > 0 ? array : valueIfNullUndefinedOrEmpty;
  }

  public static arrayElementAtOrDefault<T, T2>(
    array: T[] | undefined | null,
    index: number,
    defaultValue: T2
  ): T | T2 {
    return array && index >= 0 && index < array.length
      ? array[index]
      : defaultValue;
  }

  public static arrayRemoveAt<T>(array: T[], index: number): T[] {
    if (!array) {
      return [];
    } else if (index < 0 || index >= array.length) {
      return array;
    } else {
      return [...array.slice(0, index), ...array.slice(index + 1)];
    }
  }

  public static arrayInsertAt<T>(array: T[], index: number, item: T): T[] {
    return [...array.slice(0, index), item, ...array.slice(index)];
  }

  public static arrayReplaceAt<T>(array: T[], index: number, newItem: T): T[] {
    if (!array) {
      return [];
    } else if (index < 0 || index >= array.length) {
      return array;
    } else {
      return [...array.slice(0, index), newItem, ...array.slice(index + 1)];
    }
  }

  /// <summary>
  /// Shallow clone
  /// </summary>
  public static objectClone<T>(obj: T): T {
    return obj ? { ...(obj as any) } : obj;
  }

  /// <summary>
  /// More than a shallow copy, by default clone the objects, but not their subobjects (so the array + 1st level).
  /// Specify clone parameter to implement deeper cloning.
  /// </summary>
  public static arrayClone<T>(
    array: T[] | undefined | null,
    clone?: ((item: T) => T) | undefined | null
  ): T[] {
    if (!array) {
      return [];
    } else if (!clone) {
      return array.map(t => TypeHelper.objectClone(t));
    } else {
      return array.map(t => clone(t));
    }
  }

  public static safeObjectEquals<T1, T2>(
    obj1: T1 | undefined | null,
    obj2: T2 | undefined | null,
    compare: (obj1: T1, obj2: T2) => boolean
  ): boolean {
    if (!obj1) {
      return !obj2;
    } else if (!obj2) {
      return false;
    } else {
      return compare(obj1, obj2);
    }
  }

  public static safeStringContains(
    str: string | undefined | null,
    search: string | undefined | null,
    ignoreCase?: boolean | undefined | null,
    trim?: boolean | undefined | null
  ): boolean {
    if (!search) {
      return true;
    } else if (!str) {
      return false;
    } else if (ignoreCase) {
      if (trim) {
        return str.toLowerCase().includes(search.toLowerCase());
      } else {
        return str.toLowerCase().includes(search.trim().toLowerCase());
      }
    } else if (trim) {
      return str.includes(search.trim());
    } else {
      return str.includes(search);
    }
  }

  public static stringRandomChar(s: string): string {
    return s ? s[Math.floor(Math.random() * s.length)] : "";
  }

  /// <summary>
  /// Returns random item from array
  /// </summary>
  public static arrayRandomItem<T>(array: T[]): T | undefined {
    return array && array.length > 0
      ? array[Math.floor(Math.random() * array.length)]
      : undefined;
  }

  /// <summary>
  /// Returns random items from array
  /// </summary>
  public static arrayPermutation<T>(array: T[]): T[] {
    if (!array || array.length === 0) {
      return [];
    } else {
      const length = array.length;
      const result = new Array<T>(length);
      const done = new Array<boolean>(length);

      for (const item of array) {
        let ndx = Math.floor(Math.random() * length);

        while (done[ndx]) {
          ndx = (ndx + 1) % length;
        }

        result[ndx] = item;
        done[ndx] = true;
      }

      return result;
    }
  }

  public static arraySelectAll<T, T2>(
    array: T[] | undefined | null,
    select: (item: T) => T2[] | undefined | null
  ): T2[] {
    if (!array) {
      return [];
    } else {
      const result: T2[] = [];

      for (const item of array) {
        const children = select(item);

        if (children) {
          for (const child of children) {
            result.push(child);
          }
        }
      }

      return result;
    }
  }

  public static arrayCount<T>(
    array: T[] | undefined | null,
    predicate: (item: T) => boolean
  ): number {
    if (!array) {
      return 0;
    } else {
      let count = 0;

      for (const item of array) {
        if (predicate(item)) {
          count++;
        }
      }

      return count;
    }
  }

  public static capitalize(s: string | undefined | null): string {
    return s && s.length > 0 ? s[0].toUpperCase() + s.substring(1) : "";
  }

  /// <summary>
  /// Ignores empty arrays. Ignore empty items.
  /// </summary>
  public static safeArrayConcat<T>(
    ...args: Array<Array<T | undefined | null> | undefined | null>
  ): T[] {
    let result = new Array<T>();

    for (const array of args) {
      if (array && array.length > 0) {
        result = result.concat(array.filter(
          t => t !== null && t !== undefined
        ) as T[]);
      }
    }

    return result;
  }

  public static addEllipses(s: string, maxLength: number): string {
    return s && s.length > maxLength
      ? s.substr(0, s.lastIndexOf(" ", maxLength)) + "..."
      : s;
    // return s && s.length > maxLength ? s.substr(0, Math.max(s.lastIndexOf(" "), maxLength / 2)) + "..." : s;
  }

  public static cast<T>(item: T): T {
    return item;
  }

  public static numberFormat(
    value: number | undefined | null,
    digits: number,
    leadingZeros?: number | undefined | null
  ): string {
    if (value === null || value === undefined) {
      return "";
    } else {
      const whole = Math.floor(Math.abs(value)).toString();

      if (leadingZeros && whole.length < leadingZeros) {
        return (
          "00000000000000000000000000000000000".substr(
            0,
            leadingZeros - whole.length
          ) + value.toFixed(digits)
        );
      } else {
        return value.toFixed(digits);
      }
    }
  }

  public static lookupToArray<T>(
    lookup: { [key: number]: T } | undefined | null,
    predicate?: ((item: T) => boolean) | undefined | null
  ): T[] {
    if (!lookup) {
      return [];
    }

    const result: T[] = [];

    for (const key in lookup) {
      if (key !== undefined && key !== null) {
        const item = lookup[key];

        if (!predicate || predicate(item)) {
          result.push(item);
        }
      }
    }

    return result;
  }

  /// <summary>
  /// Removes header before comma. Returns content after comma.
  /// Example: data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALUAAACWCAYAAACY...
  /// </summary>
  public static base64RemoveHeader(base64: string): string {
    const i = base64.indexOf(",");
    return i >= 0 ? base64.substr(i + 1) : base64;
  }

  public static max<T>(
    array: T[] | undefined | null,
    getValue: (item: T) => number
  ): number | undefined {
    if (!array || !array.length) {
      return undefined;
    }

    let max = getValue(array[0]);

    for (const item of array) {
      const value = getValue(item);

      if (value > max) {
        max = value;
      }
    }

    return max;
  }

  public static formatIncomeNumber(index: number) {
    let suffix = "";
    if (index % 10 === 1 && parseInt(String(index / 10), 10) % 10 !== 1) {
      suffix = "st ";
    } else if (
      index % 10 === 2 &&
      parseInt(String(index / 10), 10) % 10 !== 1
    ) {
      suffix = "nd ";
    } else if (
      index % 10 === 3 &&
      parseInt(String(index / 10), 10) % 10 !== 1
    ) {
      suffix = "rd ";
    } else {
      suffix = "th ";
    }
    return index + suffix;
  }

  public static formatDate = (date: any) => {
    let selectedDate = date.getDate();
    let selectedMonth = date.getMonth() + 1;
    const selectedYear = date.getFullYear();
    selectedDate = selectedDate <= 9 ? "0" + selectedDate : selectedDate;
    selectedMonth = selectedMonth <= 9 ? "0" + selectedMonth : selectedMonth;
    return selectedDate + "/" + selectedMonth + "/" + selectedYear;
  };

  public static getOpenToDateForDateOfBirth() {
    let openDate = new Date();

    // Setting default year for DatePicker to 20 years ago
    openDate.setFullYear(openDate.getFullYear() - 20);

    return openDate;
  }
  public static isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }
}
