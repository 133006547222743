import React, { FC, useMemo, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Paper,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Divider,
  Tooltip,
  Chip,
  InputAdornment,
} from "@material-ui/core";
import { LABELS, VALIDATION_SCHEMA } from "./SubmitQuoteFormConstants.data";
import {
  ListItemBoxProps,
  SUBMIT_QUOTE_FORM_FIELDS,
} from "../quotes/submitQuote/SubmitQuoteComponentConstants.data";
import InputField, { StyledInput } from "../quotes/submitQuote/InputField";
import {
  Add,
  AttachMoney,
  HelpOutlined,
  SubdirectoryArrowLeft,
} from "@material-ui/icons";
import { color } from "../../../theme/Theme";
import { Quote } from "../../../models/quote/Quote.data";
import {
  submitQuoteFormHelpers,
  getDateISOString,
} from "../../../helper/HelperFunctions";
import { SubmitQuoteFormStyles } from "./SubmitQuoteFormStyles.data";
import { QuoteType } from "../../../models/quote/QuoteType.data";
import { SubmitQuoteRequest } from "../../../services/quotes/QuotesData.data";
import { cloneDeep, isNull } from "lodash";
import AvailabilitySection from "./availabilitySection/AvailabilitySection";
import moment, { Moment } from "moment";
import { QuoteStatus } from "../../../models/quoteStatus/QuoteStatus.data";
import { LineAmountType } from "../../../models/payment/PaymentLineAmountType";
import { AttachmentsSection } from "./attachmentsSection/AttachmentsSection";
import { NamedFile } from "../../../models/file/NamedFile";
import { S3File } from "../../../models/file/S3File";
import useFetch from "../../../hooks/useFetch";
import ServiceFeeService from "../../../services/serviceFee/ServiceFeeService";

interface SubmitQuoteFormProps {
  onSubmit: (request: SubmitQuoteRequest) => void;
  quote: Quote;
  heading: string;
  // For quotes where on site is not an option for example: variation quote, we can hide On site chekbox using this prop
  // The component has default quote type set to regular
  hideOnSite?: boolean;
  setStartDateToSystemDate?: boolean;
  submitQuoteButtonText?: string;

  // to be set true in case we require to fill existing quote values (i.e. edit quote)
  // this prop is required since for variation quote, it will fill the existing quote values toom, which is not needed
  fillExisting?: boolean;
}

export interface SubmitQuoteFormSpecificCost {
  description: string;
  charge: string;
  includedMaterial?: boolean | null;
}

export interface SubmitQuoteRequestFormData {
  callOutCost: string;
  labourCost: string;
  materialCost: string;
  workOrderId: string;
  officeId: number;
  miscellaneousCost: number;
  remarks: string;
  jobMinCost: number;
  jobMaxCost: number;
  status: string;
  specificMaterials: SubmitQuoteFormSpecificCost[];
  type: QuoteType;
  quoteId: string;
  endDate: Moment | null;
  startDate: Moment | null;
  sameDay: boolean;
  deletedAttachments: string[];
  files: NamedFile[];
}

const { formatField, getGST } = submitQuoteFormHelpers;

const SubmitQuoteForm: FC<SubmitQuoteFormProps> = ({
  onSubmit,
  heading,
  hideOnSite,
  setStartDateToSystemDate,
  submitQuoteButtonText,
  fillExisting,
  quote,
}) => {
  const {
    workOrderId,
    officeId,
    id,
    callOutCost: existingCallOutCost,
    labourCost: existingLabourCost,
    materialCost: existingMaterialCost,
    miscellaneousCost: existingMiscellaneousCost,
    jobMaxCost,
    jobMinCost,
    specificMaterials: existingSpecificMaterials,
    type: existingType,
    availability: existingAvailablility,
    remarks: existingRemarks,
    serviceFeePercent,
    lineAmountType,
    attachments: existingQuoteAttachments,
    maintenanceRequestResponse,
  } = quote;
  // state
  const [specificCostItemName, setSpecificCostItemName] = useState<string>("");
  const [existingAttachments, setExistingAttachments] = useState<S3File[]>(
    existingQuoteAttachments
  );
  const [preservedValues, setPreservedValues] = useState<
    Partial<SubmitQuoteRequestFormData> | undefined
  >(undefined);
  const {
    state: { data: serviceFee },
    fetchData: fetchServiceFee,
  } = useFetch(ServiceFeeService.getServiceFees, undefined, true);

  // tslint:disable-next-line:cyclomatic-complexity
  const initialValues: SubmitQuoteRequestFormData = useMemo(() => {
    if (!fillExisting) {
      return {
        callOutCost: "0",
        labourCost: "0",
        materialCost: "0",
        workOrderId,
        officeId,
        miscellaneousCost: 0,
        remarks: "",
        jobMinCost: 0,
        jobMaxCost: 0,
        specificMaterials: [],
        status: QuoteStatus.Sent,
        type: QuoteType.Regular,
        quoteId: id,
        endDate: setStartDateToSystemDate ? moment() : null,
        startDate: setStartDateToSystemDate ? moment() : null,
        sameDay: true,
        deletedAttachments: [],
        files: [],
      };
    } else {
      const initialSpecificMaterials: SubmitQuoteFormSpecificCost[] =
        existingSpecificMaterials.map(
          ({ description, charge, includedMaterial }) => ({
            description,
            charge: "" + charge,
            includedMaterial,
          })
        );

      const { startDate: existingStartDate, endDate: existingEndDate } =
        existingAvailablility;

      return {
        callOutCost: "" + existingCallOutCost,
        labourCost: "" + existingLabourCost,
        materialCost: "" + existingMaterialCost,
        workOrderId,
        officeId,
        miscellaneousCost: existingMiscellaneousCost,
        remarks: existingRemarks,
        jobMinCost,
        jobMaxCost,
        specificMaterials: initialSpecificMaterials,
        status: QuoteStatus.Sent,
        type: existingType,
        quoteId: id,
        endDate: moment(existingEndDate) || null,
        startDate: moment(existingStartDate),
        sameDay:
          moment(existingEndDate)
            .startOf("day")
            .isSame(moment(existingStartDate).startOf("day")) ||
          isNull(existingEndDate),
        // for file ids of deleted existing attachments
        deletedAttachments: [],
        // this is for new attachments, so initialising to empty, existing ones are directly sent to Attachments component
        files: [],
      };
    }
  }, []);

  // other hooks
  const classes = SubmitQuoteFormStyles();

  // functions
  const submitQuoteFormDataToRequestConverter = (
    values: SubmitQuoteRequestFormData
  ): SubmitQuoteRequest => {
    const { sameDay, ...requestValues }: any = { ...cloneDeep(values) };
    ["callOutCost", "labourCost", "materialCost"].forEach((cost) => {
      requestValues[cost] = parseFloat(requestValues[cost]);
    });

    const { callOutCost, labourCost, materialCost, specificMaterials } =
      requestValues;

    requestValues.specificMaterials = requestValues.specificMaterials.map(
      (item) => ({
        ...item,
        charge: parseFloat(item.charge),
      })
    );

    let total =
      (parseFloat(callOutCost) || 0) +
      (parseFloat(materialCost) || 0) +
      (parseFloat(labourCost) || 0) +
      specificMaterials
        .map((cost) => parseFloat(cost.charge) || 0)
        .reduce((a, b) => a + b, 0);
    const gst = getGST(total, lineAmountType);

    if (lineAmountType === LineAmountType.EXCLUSIVE) {
      total += gst;
    }

    // creating availability details object
    requestValues.availability = {
      endDate: getDateISOString(requestValues.endDate),
      startDate: getDateISOString(requestValues.startDate),
    };

    // removing the keys
    delete requestValues.startDate;
    delete requestValues.endDate;

    // Adding all costs and calculating processing fee
    requestValues.serviceFee = serviceFee?.fee || 0;

    requestValues.lineAmountType = lineAmountType;

    if (values.type === QuoteType.OnSite) {
      requestValues.labourCost = 0;
      requestValues.materialCost = 0;
      requestValues.specificMaterials = [];
    }

    return requestValues as SubmitQuoteRequest;
  };

  if (!initialValues) return null;

  const formik = useFormik({
    validateOnChange: true,
    validationSchema: VALIDATION_SCHEMA,
    initialValues: initialValues!,
    onSubmit: (values) =>
      onSubmit(submitQuoteFormDataToRequestConverter(values)),
  });

  const { values, setFieldValue, handleSubmit, handleChange, setValues } =
    formik;

  const getServiceFee = (total: number) => {
    fetchServiceFee(
      total,
      maintenanceRequestResponse.agencyId,
      maintenanceRequestResponse.privateWorksDetails?.privateName
    );
  };

  const {
    callOutCost,
    materialCost,
    labourCost,
    specificMaterials,
    type,
    sameDay,
    remarks,
    files,
    deletedAttachments,
  } = values;

  const { total, gst, jobCost, serviceFeeGST } = useMemo(() => {
    let total =
      (parseFloat(callOutCost) || 0) +
      (parseFloat(materialCost) || 0) +
      (parseFloat(labourCost) || 0) +
      specificMaterials
        .map((cost) => parseFloat(cost.charge) || 0)
        .reduce((a, b) => a + b, 0);
    const gst = getGST(total, lineAmountType);
    const jobCost =
      lineAmountType === LineAmountType.EXCLUSIVE ? total : total - gst;

    if (lineAmountType === LineAmountType.EXCLUSIVE) {
      total += gst;
    }

    getServiceFee(total);
    const serviceFeeGST = getGST(serviceFee?.fee || 0, lineAmountType);

    return { total, gst, jobCost, serviceFeeGST };
  }, [
    values.callOutCost,
    values.materialCost,
    values.labourCost,
    values.specificMaterials,
  ]);

  const isPrivate =
    quote.maintenanceRequestResponse.privateWorksDetails &&
    quote.maintenanceRequestResponse.privateWorksDetails.isPrivate;

  return (
    <Box mb={2}>
      {/* Checkbox for changing quote type to On Site */}
      {!hideOnSite && (
        <Box my={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={type === QuoteType.OnSite}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValue("type", QuoteType.OnSite);
                    // preserving existing values
                    setPreservedValues({
                      labourCost,
                      materialCost,
                      specificMaterials,
                    });

                    // setting costs to 0 for form values
                    ["labourCost", "materialCost"].map((cost) =>
                      setFieldValue(cost, 0)
                    );
                    setFieldValue("specificMaterials", []);
                  } else {
                    // Setting preserved values and changing type
                    setValues({
                      ...values,
                      type: QuoteType.Regular,
                      ...preservedValues,
                    });
                  }
                }}
                name="checkedB"
                color="primary"
              />
            }
            label={LABELS.onSiteQuote}
          />
        </Box>
      )}

      <Paper variant="outlined">
        <Box p={2} pr={0}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.formHeading}>{heading}</Typography>
            <Box ml={2}>
              <Typography variant="caption" color="textSecondary">
                {`( ${
                  lineAmountType === LineAmountType.INCLUSIVE
                    ? LABELS.inclusiveGST
                    : LABELS.exclusiveGST
                } )`}
              </Typography>
            </Box>
          </Box>
          {/* quote details fields */}
          <Box mt={1} mb={3}>
            {type === QuoteType.Regular && (
              <>
                <Box {...ListItemBoxProps} className={classes.listItemBox}>
                  <Typography variant="h4">{LABELS.costOfLabour}</Typography>
                  <Typography
                    component="span"
                    className={classes.quoteFormValue}>
                    <StyledInput
                      placeholder={LABELS.enterAmount}
                      name={SUBMIT_QUOTE_FORM_FIELDS.labourCost}
                      disableUnderline
                      onChange={handleChange}
                      value={labourCost}
                      startAdornment={
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      }
                    />
                  </Typography>
                </Box>

                <Box {...ListItemBoxProps} className={classes.listItemBox}>
                  <Typography variant="h4">
                    {LABELS.includedMaterials}
                  </Typography>
                  <Typography
                    component="span"
                    className={classes.quoteFormValue}>
                    <StyledInput
                      placeholder={LABELS.enterAmount}
                      name={SUBMIT_QUOTE_FORM_FIELDS.materialCost}
                      disableUnderline
                      onChange={handleChange}
                      value={materialCost}
                      startAdornment={
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      }
                    />
                  </Typography>
                </Box>
              </>
            )}
            <Box {...ListItemBoxProps} className={classes.listItemBox}>
              <Typography variant="h4">{LABELS.callOut}</Typography>
              <Typography component="span" className={classes.quoteFormValue}>
                <StyledInput
                  placeholder={LABELS.enterAmount}
                  name={SUBMIT_QUOTE_FORM_FIELDS.callOutCost}
                  disableUnderline
                  onChange={handleChange}
                  value={callOutCost}
                  startAdornment={
                    <InputAdornment position="start">
                      <AttachMoney />
                    </InputAdornment>
                  }
                />
              </Typography>
            </Box>
          </Box>

          {/* Specific Cost section */}
          {type === QuoteType.Regular && (
            <>
              <Typography className={`${classes.formHeading}`}>
                {LABELS.specificMaterial}
              </Typography>

              {/* Enter specific cost form*/}
              <Box>
                {specificMaterials.map((specificCostItem, index) => (
                  <Box
                    key={index}
                    className={`${classes.listItemBox}`}
                    {...ListItemBoxProps}>
                    <Box className={classes.specificItemNameInputContainer}>
                      <InputField
                        placeholder={LABELS.enterItemName}
                        endAdornment={
                          specificCostItem.includedMaterial && (
                            <Chip color="primary" label="Included" />
                          )
                        }
                        disableUnderline
                        onChange={(e) =>
                          setFieldValue(
                            `specificMaterials[${index}].description`,
                            e.currentTarget.value
                          )
                        }
                        style={{ padding: ".6em" }}
                        field={{
                          value: specificCostItem.description,
                        }}
                      />
                    </Box>
                    {!specificCostItem.includedMaterial && (
                      <Box className={classes.quoteFormValue}>
                        <InputField
                          placeholder={LABELS.enterAmount}
                          disableUnderline
                          field={{ value: `$${specificCostItem.charge}` }}
                          onChange={(e) => {
                            setFieldValue(
                              `specificMaterials[${index}].charge`,
                              formatField(e.currentTarget.value).replace(
                                "$",
                                ""
                              )
                            );
                          }}
                          onBlur={(e) => {
                            setFieldValue(
                              `specificMaterials[${index}].charge`,
                              formatField(e.currentTarget.value, true).replace(
                                "$",
                                ""
                              )
                            );
                          }}
                          teal
                        />
                      </Box>
                    )}
                  </Box>
                ))}
                <Box {...ListItemBoxProps} alignItems="center" pr={2} py={1}>
                  <Box className={classes.specificItemNameInputContainer}>
                    <InputField
                      placeholder={LABELS.enterItemName}
                      disableUnderline
                      onChange={(e) =>
                        setSpecificCostItemName(e.currentTarget.value)
                      }
                      field={{ value: specificCostItemName }}
                    />
                  </Box>

                  <Add
                    className={classes.pointer}
                    onClick={() => {
                      if (specificCostItemName) {
                        setFieldValue("specificMaterials", [
                          ...specificMaterials,
                          {
                            charge: 0,
                            description: specificCostItemName,
                          },
                        ]);
                      }
                      setSpecificCostItemName("");
                    }}
                    color="disabled"
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
        {/* Sub Total Section */}
        <Box p={2} pr={0} mt={2} bgcolor={color.wildSand}>
          <Box pr={2} pb={1} {...ListItemBoxProps}>
            <Typography variant="h4">{LABELS.jobCost}</Typography>
            <Typography className={classes.formHeading} color="primary">
              {`$${jobCost.toFixed(2)}`}
            </Typography>
          </Box>

          <Box pr={2} pb={1} {...ListItemBoxProps}>
            <Typography variant="h4">{LABELS.gst}</Typography>
            <Typography className={classes.formHeading} color="primary">
              {`$${gst.toFixed(2)}`}
            </Typography>
          </Box>

          <Box pr={2} pb={1} {...ListItemBoxProps}>
            <Typography variant="h4">{LABELS.total}</Typography>
            <Typography className={classes.formHeading} color="primary">
              {`$${total.toFixed(2)}`}
            </Typography>
          </Box>

          {serviceFeePercent > 0 && (
            <Box pr={2} display="flex" alignItems="center" width="100%">
              <Box pr={2} py={1} {...ListItemBoxProps} alignItems="center">
                <Box display="flex" alignItems="center" flexGrow="1">
                  <Tooltip title={LABELS.sortedFeeHelperText}>
                    <HelpOutlined color="disabled" />
                  </Tooltip>
                  <Box ml={2}>
                    <Typography className={classes.inline} variant="h4">
                      {LABELS.sortedFee} {serviceFeePercent}%
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="h4">
                  {/* tslint:disable-next-line:ban-comma-operator */}
                  {`$${serviceFee?.fee || 0}`}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.includesGSTLabel}>
                  {LABELS.includesGstOf} {`$${serviceFeeGST.toFixed(2)}`}
                </Typography>
              </Box>
              <SubdirectoryArrowLeft color="disabled" />
            </Box>
          )}
        </Box>
      </Paper>

      {/* Attachments */}
      <AttachmentsSection
        setFieldValue={setFieldValue}
        attachments={files}
        deletedAttachments={deletedAttachments}
        existingAttachments={existingAttachments}
        setExistingAttachments={setExistingAttachments}
      />

      {/* Notes section */}
      <Box mt={2}>
        <Paper variant="outlined">
          <Box p={2}>
            <Typography
              className={`${classes.formHeading} ${classes.notesHeadingMargin}`}>
              {isPrivate
                ? LABELS.scopeDescriptionPrivate
                : LABELS.scopeDescription}
            </Typography>
            <Divider color="disabled" />
            <Box mt={2}>
              <TextField
                name="remarks"
                rows={4}
                multiline
                fullWidth
                variant="filled"
                onChange={handleChange}
                InputProps={{
                  disableUnderline: true,
                  className: classes.commentsTextArea,
                }}
                value={remarks}
                placeholder={isPrivate ? LABELS.addNotePrivate : LABELS.addNote}
              />
            </Box>
          </Box>
        </Paper>
      </Box>

      {/* Availability Section */}
      <Box mt={2}>
        <AvailabilitySection oneDayTask={sameDay} formikProps={formik} />
      </Box>

      {/* Submit button */}
      <Box my={2}>
        <Typography className={classes.note} variant="body2">
          {maintenanceRequestResponse.privateWorksDetails &&
          maintenanceRequestResponse.privateWorksDetails.isPrivate
            ? LABELS.noteCustomerConfirmation
            : LABELS.noteAgentConfirmation}
        </Typography>
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          onClick={() => handleSubmit()}
          className={classes.submitQuoteButton}>
          {submitQuoteButtonText || LABELS.submitQuote}
        </Button>
      </Box>
    </Box>
  );
};

export default SubmitQuoteForm;
