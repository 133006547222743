import { makeStyles } from "@material-ui/core";
import theme, { color } from "../../../../theme/Theme";
const BORDER = `1px solid ${color.lightGrey}`;

export const ViewJobStyles = makeStyles({
  sectionPaper: {
    padding: "1rem",
  },
  propertyPaper: {
    padding: ".5rem",
  },
  workorderSection: {
    paddingBottom: 0,
  },
  wrapper: {
    paddingBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 1rem",
    },
  },
  selectContainer: {
    marginTop: 0,
  },
  pointer: {
    cursor: "pointer",
  },
  expansionSection: {
    marginTop: "2rem",
  },
  tradesManNamesSummary: {
    display: "flex",
    alignItems: "center",
    borderBottom: BORDER,
    paddingLeft: "2rem",
    flexGrow: 1,
  },
  buttonSection: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    "& > * + *": {
      marginLeft: "1em",
    },
    "@media all and (max-width: 580px)": {
      flexWrap: "wrap",
    },
    "@media all and (max-width: 480px)": {
      "& > * + *": {
        marginLeft: 0,
      },
    },
  },
  button: {
    marginTop: "1rem",
    width: "160px",
    height: "40px",
    borderRadius: "4px",
    "@media all and (max-width: 480px)": {
      flexBasis: "100%",
    },
  },
  scheduleButton: {
    backgroundColor: color.teal,
    color: color.light,
    textAlign: "center",
    "&:hover": {
      backgroundColor: color.teal,
    },
  },
  sendForPaymentDiv: {
    textAlign: "right",
  },
  variationQuoteHeading: {
    marginBottom: "1em",
  },
  hide: {
    display: "none",
  },
  moreDetailsButtonContainer: {
    marginRight: "10px",
  },
  summaryDetailsClickableItem: {
    "&:hover": {
      background: color.wildSand,
    },
  },
  summaryContent: {
    fontWeight: 600,
  },
  summaryItemIconMargin: {
    marginRight: ".5em",
  },
  address: {
    fontSize: "19px",
    textDecoration: "underline",
  },
  buttonsContainer: {
    "& > * + *": {
      marginLeft: "1em",
    },
  },
  summaryListStyle: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 0,
    paddingLeft: 0,
    alignItems: "flex-start",
  },
});
