import {
  GetPaymentAuthKey,
  GetPaymentMethodsResponse,
  PostPaymentTokenResponse,
} from "../../../src/services/payment/PaymentService.data";
import {
  PaymentAction,
  PaymentActionTypes,
  SaveSelectedPaymentMethodPayload,
} from "../actions/PaymentActions";
import { PaymentState } from "../state/PaymentState";

export const initialState: PaymentState = {
  loading: false,
  paymentMethods: [],
  selectedPaymentMethod: {
    paymentMethodId: "",
    maskedDetails: {
      paymentDetails: "",
      logo: "",
    },
  },
  paymentAuthKey: {
    authKey: "",
    status: 1,
  },
  postPaymentTokenResponse: null,
  success: false,
};

export function paymentReducer(state = initialState, action: PaymentAction) {
  state = getPaymentMethodsReducer(state, action);
  state = getPaymentAuthKeyReducer(state, action);
  state = postPaymentTokenReducer(state, action);

  return state;
}

function getPaymentMethodsReducer(state = initialState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionTypes.GetPaymentMethodsStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PaymentActionTypes.GetPaymentMethodsSuccess:
      return {
        ...state,
        loading: false,
        paymentMethods: action.data as GetPaymentMethodsResponse[],
      };

    case PaymentActionTypes.GetPaymentMethodsError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case PaymentActionTypes.SaveSelectedPaymentMethod:
      return {
        ...state,
        selectedPaymentMethod: action.data as SaveSelectedPaymentMethodPayload,
      };
    default:
      return state;
  }
}

function getPaymentAuthKeyReducer(state = initialState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionTypes.GetPaymentAuthKeyStart:
      return {
        ...state,
        error: undefined,
        loading: true,
        success: false,
      };
    case PaymentActionTypes.GetPaymentAuthKeySuccess:
      return {
        ...state,
        loading: false,
        paymentAuthKey: action.data as GetPaymentAuthKey,
      };

    case PaymentActionTypes.GetPaymentAuthKeyError:
      return {
        ...state,
        error: action.data,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
}

function postPaymentTokenReducer(state = initialState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionTypes.PostPaymentTokenStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PaymentActionTypes.PostPaymentTokenSuccess:
      return {
        ...state,
        loading: false,
        postPaymentTokenResponse: action.data as PostPaymentTokenResponse,
        success: true,
      };
    case PaymentActionTypes.PostPaymentTokenSuccessReset:
      return {
        ...state,
        success: false,
      };
    case PaymentActionTypes.PostPaymentTokenError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    default:
      return state;
  }
}
