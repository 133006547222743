import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import {
  AddServiceableRegionsRequest,
  DeleteServiceableRegionRequestPayload,
} from "./serviceableRegionsServiceData.data";

const serviceType = ServiceType.Offices;
const SERVICEABLE_REGIONS = "serviceable-regions";

class ServiceableRegionsService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public post(payload: AddServiceableRegionsRequest): Promise<any> | any {
    const { regions, officeId } = payload;
    // changing locality to suburb
    const updatedRegions = regions.map(({ locality, ...rest }) => ({
      suburb: locality,
      ...rest,
    }));
    return this.service.post(
      { route: [officeId, SERVICEABLE_REGIONS] },
      JSON.stringify(updatedRegions)
    );
  }

  public delete(
    payload: DeleteServiceableRegionRequestPayload
  ): Promise<any> | any {
    const { officeId, serviceableRegionId } = payload;
    return this.service.delete({
      route: [
        officeId.toString(),
        SERVICEABLE_REGIONS,
        serviceableRegionId.toString(),
      ],
    });
  }
}

const serviceableRegionService = new ServiceableRegionsService();
export default serviceableRegionService;
