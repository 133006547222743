import * as Yup from "yup";

export const LABELS = {
  documentName: "Document name",
  expiryDate: "Expiry date (for Tracked documents)",
  trackedDocument: "Tracked document",
  addDocument: "Add document",
  cancel: "Cancel",
  save: "Save",
};

export const ERRORS = {
  requiredMessage: (fieldName) => `${fieldName} is required`,
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  docName: Yup.string()
    .trim()
    .required(ERRORS.requiredMessage(LABELS.documentName))
    .max(50)
    .min(2, "Document Name should be at least 2 characters long"),
  tracker: Yup.boolean(),
  expiryDate: Yup.string()
    .nullable()
    .when("tracker", {
      is: true,
      then: Yup.string().required(
        `${ERRORS.requiredMessage("Expiry date")} for Tracked documents`
      ),
    }),
});
