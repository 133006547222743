import { takeEvery, put } from "redux-saga/effects";
import { Logging } from "../../helper/Logging";
import BranchService from "../../services/branch/BranchService";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import {
  AddBranchAction,
  AddBranchActions,
  AddBranchActionType,
  AddBranchRequestPayload,
} from "../actions/BranchActions";
import { Office as AddBranchSuccessPayload } from "../../container/registrationForm/AddBranch.data"
import OnboardingStageService from "../../services/onboardingStage/OnboardingStageService";
import { OnboardingStage } from "../../helper/OnboardingStages";
import { OfficeDetails as AddBranchRequest } from "../../services/registerBusiness/RegistrationFormRequest.data";

export function* postAddBranchWorker(
  action: AddBranchAction
): IterableIterator<any> {
  const data = action.data as AddBranchRequestPayload;
  const { request, onSuccess } = { ...data };
  try {
    Logging.debug("AddBranchSagas.addBranchWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());
    Logging.debug("AddBranchSagas.addBranchWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    const response: AddBranchSuccessPayload = (yield BranchService.post(
      request! as AddBranchRequest
    ))!;

    yield put(AddBranchActions.addBranchSuccess(response));

    // Updating onboarding stage
    const onboardingRequest = {
      id: localStorage.getItem("userId")!,
      adminOnBoardingStage: OnboardingStage.UpdateAdminDetails,
    };

    yield OnboardingStageService.put(onboardingRequest);

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    Logging.error("AddBranchSagas.addBranchWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(LoadingIndicatorActions.hide());
    yield put(AddBranchActions.addBranchError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const addBranchWatcher = [
  takeEvery(AddBranchActionType.AddBranchRequest, (action) =>
    postAddBranchWorker({
      type: action.type,
      data: (action as AddBranchAction).data,
    })
  ),
];

export default addBranchWatcher;
