import { makeStyles } from "@material-ui/core";
import { color } from "../../theme/Theme";

export const StyledExpansionPanelStyles = makeStyles({
  expansionPanelDetailsSection: {
    display: "block",
    padding: "0",
  },
  expansionPanelSummarySection: {
    padding: "0 1rem",
  },
  expansionPanelSummarySectionDisablePadding: {
    padding: "0 !important",
  },
  expanded: {
    "&$expanded": {
      margin: 0,
    },
  },
  underlinedAvatar: {
    "&::after": {
      content: `""`,
      width: "calc(100% - 56px)",
      position: "absolute",
      bottom: 0,
      right: 0,
      marginBottom: "5px",
      borderBottom: `1px solid ${color.secondary100}`,
    },
  },
  underlined: {
    "&::after": {
      content: `""`,
      width: "100%",
      position: "absolute",
      bottom: 0,
      right: 0,
      borderBottom: `1px solid ${color.secondary100}`,
    },
  },
});
