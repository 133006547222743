import React, { FC } from "react";
import {
  Chip,
  Typography,
  withStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  Box,
  Tabs,
  Tab,
  Badge,
} from "@material-ui/core";
import { color } from "../../../../theme/Theme";
import { JobFilterStatus, JobFilterStatusChips } from "./JobFilterChips.data";
import { JobStatus } from "../../../../models/jobs/JobData";
import { BadgeData } from "../../quotes/quotesFilterChipsSection/QuotesFilterChipsSection";

interface Props {
  activeChip: JobFilterStatus;
  badgeData: BadgeData[];
  setActiveFilterChip: (filter: JobStatus[]) => void;
}

const FilterChip = withStyles({
  root: {
    minWidth: "120px",
    cursor: "pointer",
  },
})(Chip);

const FilterChipsSectionStyles = makeStyles({
  wrapper: {
    marginTop: "2em",
    "& > *": {
      marginLeft: "20px",
    },
  },
  activeChip: {
    background: `${color.wildSand} !important`,
  },
  inactiveChip: {
    background: color.light,
    opacity: ".5",
  },
  navLinks: {
    textTransform: "capitalize",
    fontSize: "1em",
    color: color.light,
    opacity: ".5",
  },
  navLinkActive: {
    color: `${color.light} !important`,
    opacity: 1,
    border: "0 !important",
  },
  noBorder: {
    border: "none",
  },
});

const FilterChipsSection: FC<Props> = (props) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const classes = FilterChipsSectionStyles();
  const { activeChip, setActiveFilterChip, badgeData } = props;
  return !smallScreen ? (
    <section className={classes.wrapper}>
      {JobFilterStatusChips.map((filter) => {
        let badgeContent = 0;

        badgeData.forEach((data) => {
          if (filter.value.includes(data.name as JobStatus)) {
            badgeContent = data.count;
          }
        });

        return (
          <Badge color="primary" badgeContent={badgeContent || 0}>
            <FilterChip
              key={filter.text}
              className={
                activeChip === filter.text
                  ? classes.activeChip
                  : classes.inactiveChip
              }
              label={<Typography variant="caption">{filter.text}</Typography>}
              onClick={() => setActiveFilterChip(filter.value)}
            />
          </Badge>
        );
      })}
    </section>
  ) : (
    <Box bgcolor={color.primary} height={"3em"} width="100vw">
      <Tabs
        value={activeChip}
        onChange={(e, text) => {
          const newFilter = JobFilterStatusChips.find(
            (filter) => filter.text === text
          )!;
          setActiveFilterChip(newFilter.value);
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable">
        {JobFilterStatusChips.map((filter) => (
          <Tab
            disableRipple
            key={filter.text}
            label={filter.text}
            value={filter.text}
            className={classes.navLinks}
            classes={{ selected: smallScreen ? classes.navLinkActive : "" }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default FilterChipsSection;
