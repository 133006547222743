import React, { useState } from "react";
import { Logging } from "../helper/Logging";
import { LoadingIndicatorActions } from "../store/actions/LoadingIndicatorAction";
import { store } from "../store/Store";
interface FetchState<T> {
  loading: boolean;
  success: boolean;
  error?: Error | any;
  data?: T;
}

type unBoxPromise<T extends Promise<any> | any> = T extends Promise<infer U>
  ? U
  : never;

/**
 * The purpose of this hook is to avoid fetching api through redux saga when we don't need to
 * store the response in redux store. Redux store should be used only when we need a shared state
 * for components which are deeply nested. Otherwise we're using it in a wrong way. This also takes away
 * the trouble of cleaning up the state on component unmount
 * @param initialValue pass the initial value of the data.
 * @param asyncCallback pass the async callback for fetching data asynchronously
 * @param disableLoader for disabling the blanket loader
 * @typeParam T describes the type of data it is trying fetch also is the type of initial value
 */
const useFetch = <R extends (...args: any[]) => Promise<any> | any>(
  asyncCallback: R,
  initialValue?: unBoxPromise<ReturnType<R>>,
  disableLoader = false
): {
  state: FetchState<unBoxPromise<ReturnType<R>>>;
  fetchData: (...funcArgs: Parameters<R>) => void;
} => {
  const initState: FetchState<unBoxPromise<ReturnType<R>>> = {
    loading: false,
    success: false,
    error: undefined,
    data: initialValue,
  };
  const [state, setState] = useState(initState);

  /**
   * @param disableLoader optional param to disable the loader
   */

  const fetchData = async (...args: Parameters<R>) => {
    try {
      if (!disableLoader) {
        store.dispatch(LoadingIndicatorActions.show());
      }
      setState({
        ...state,
        loading: true,
        success: false,
      });

      const response = await asyncCallback(...args);
      setState({
        data: response,
        loading: false,
        success: true,
        error: undefined,
      });
    } catch (error) {
      Logging.error("error fetching data", {
        error: error.message,
      });
      setState({
        ...initState,
        error,
      });
    } finally {
      if (!disableLoader) store.dispatch(LoadingIndicatorActions.hide());
    }
  };

  return { state, fetchData };
};

export const withFetch =
  <R extends (...args: any[]) => Promise<any> | any>(
    asyncCallback: R,
    name: string,
    initialValue?: unBoxPromise<ReturnType<R>>,
    disableLoader = false
  ) =>
  (Component: React.ComponentType) => {
    return (props) => {
      const fetchObject = useFetch(asyncCallback, initialValue, disableLoader);
      const fetchProps = { [name]: fetchObject };
      return <Component {...props} {...fetchProps} />;
    };
  };

export interface WithFetchProps<
  T extends (...args: any[]) => Promise<any> | any
> {
  state: FetchState<unBoxPromise<ReturnType<T>>>;
  fetchData: (...funcArgs: Parameters<T>) => void;
}

export default useFetch;
