import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetTradeRequest } from "../../services/listBranches/ListBranchesServiceData.data";
import { ListBranchesActions } from "../../store/actions/ListBranchesActions";
import { ApplicationState, useAppSelector } from "../../store/RootReducers";
import { getTradeId } from "../../helper/Auth";
import AccountSettingsComponent from "../../component/dashboard/settings/account/AccountSettingsComponent";
import {
  GetBranchRequest,
  ServiceableRegion,
} from "../../services/branch/Branch.data";
import {
  GetBranchActions,
  UpdateBranchActions,
} from "../../store/actions/BranchActions";
import {
  AddServiceableRegionsRequest,
  DeleteServiceableRegionRequestPayload,
  UpdateBranchDetailsRequestData,
} from "../../services/serviceableRegions/serviceableRegionsServiceData.data";
import { ServiceableRegionsActions } from "../../store/actions/ServiceableRegionsActions";
import {
  UpdateBusinessRequestData,
  UpdateBusinessRequest,
  OfficeDetails,
} from "../../services/registerBusiness/RegistrationFormRequest.data";
import { UpdateBusinessActions } from "../../store/actions/RegisterBusinessActions";
import { TradeInfo } from "../../models/trade/Trade.data";
import { omit, isNull, isUndefined } from "lodash";

interface Props {}

const AccountSettings: FC<Props> = (props) => {
  // State
  const [officeId, setOfficeId] = useState<number | null>(null);

  const dispatch = useDispatch();

  const dispatcher = {
    getTradeDetail: (data: GetTradeRequest) => {
      dispatch(
        ListBranchesActions.listBranchesRequest({
          request: data,
        })
      );
    },
    getBranchDetail: (data: GetBranchRequest) => {
      dispatch(
        GetBranchActions.getBranchRequest({
          request: data,
        })
      );
    },
    postAddServiceableRegions: (data: AddServiceableRegionsRequest) => {
      dispatch(
        ServiceableRegionsActions.addServiceableRegionsRequest({
          request: data,
        })
      );
    },
    deleteServiceableRegion: (data: DeleteServiceableRegionRequestPayload) => {
      dispatch(ServiceableRegionsActions.deleteServiceableRegionRequest(data));
    },
    updateTradeDetails: (data: UpdateBusinessRequestData) => {
      const request: UpdateBusinessRequest = {
        tradeRequest: JSON.stringify({
          name: data.name,
          tradeTypeId: data.tradeTypeId,
        }),
        file: data.file,
      };
      dispatch(UpdateBusinessActions.updateBusinessRequest(request));
    },
    updateBranchDetails: (request: UpdateBranchDetailsRequestData) =>
      dispatch(UpdateBranchActions.updateBranchDetails(request)),
  };

  // Effects
  useEffect(() => {
    getTradeDetail();
  }, []);

  const { trade, branchDetails } = useAppSelector(
    (state: ApplicationState) => ({
      trade: state.listBranch.trade,
      branchDetails: state.branchDetail.branchDetails,
    })
  );

  useEffect(() => {
    if (
      (trade && isNull(officeId)) ||
      (trade &&
        isUndefined(trade.offices.find((office) => office.id === officeId)))
    ) {
      getBranchDetail(trade.offices[0].id);
      setOfficeId(trade.offices[0].id);
    }
  }, [trade]);

  // Helper functions
  const getTradeDetail = (): void => {
    const request: GetTradeRequest = {
      tradeId: getTradeId()!,
    };
    dispatcher.getTradeDetail(request);
  };

  const getBranchDetail = (id: number) => {
    const request: GetBranchRequest = {
      officeId: id.toString(),
    };
    dispatcher.getBranchDetail(request);
  };

  const onBranchChange = (id: number) => {
    setOfficeId(id);
    getBranchDetail(id);
  };

  const onRemoveServiceableRegion = (region: Partial<ServiceableRegion>) => {
    const request = {
      officeId: officeId!,
      serviceableRegionId: region.id!,
    };
    dispatcher.deleteServiceableRegion(request);
  };

  const onUpdateTradeDetails = (data: TradeInfo) => {
    const { logoKey, name, tradeTypeId } = data;
    const file = typeof logoKey === "string" ? null : logoKey;
    const request: UpdateBusinessRequestData = {
      name,
      tradeTypeId,
      file,
    };
    dispatcher.updateTradeDetails(request);
  };

  const onUpdateBranchDetails = (data: Partial<OfficeDetails>) => {
    const payload = omit(data, ["id", "serviceableRegions"]);
    if (officeId) {
      dispatcher.updateBranchDetails({ payload, officeId });
    }
  };

  return trade && branchDetails ? (
    <AccountSettingsComponent
      branchDetails={branchDetails}
      onBranchChange={onBranchChange}
      tradeDetails={trade}
      postAddServiceableRegions={dispatcher.postAddServiceableRegions}
      onRemoveServiceableRegion={onRemoveServiceableRegion}
      onUpdateTradeDetails={onUpdateTradeDetails}
      onUpdateBranchDetails={onUpdateBranchDetails}
    />
  ) : null;
};

export default AccountSettings;
