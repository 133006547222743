import { StoreAction } from "../StoreHelper";
import { ToastType } from "../../models/toastTypes/ToastTypesData.data";

export enum ToastActionType {
  SHOW_TOAST = "SHOW_TOAST",
  HIDE_TOAST = "HIDE_TOAST",
}

export interface ToastPayload {
  open: boolean;
  message?: string;
  type?: ToastType;
}

export type ToastAction = StoreAction<ToastActionType, ToastPayload>;

export class ToastActions {
  public static showToast(data: ToastPayload) {
    return { type: ToastActionType.SHOW_TOAST, data };
  }

  public static hideToast(data: ToastPayload) {
    return { type: ToastActionType.HIDE_TOAST, data: { open: false } };
  }
}
