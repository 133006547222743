import * as React from "react";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { Dispatch, AnyAction } from "redux";
import { ApplicationState } from "../../store/RootReducers";
import { Forms } from "../../helper/Forms";
import {
  SignUpActions,
  SignUpSuccessPayload,
} from "../../store/actions/SignUpAction";
import { connect } from "react-redux";
import {
  SignUpRequest,
  ADMIN_ROLE,
} from "../../services/signUp/SignUpRequest.data";
import SignUp from "../../component/signUp/SignUp";
import { reduxForm } from "redux-form";
import { routes } from "../../Routes";
import { agencies } from "../../constants/AppConstants";
import { LoginActions } from "../../store/actions/LoginActions";
import { RegisterClientResponse } from "../../services/login/LoginData";
import { isLoggedIn, onboardingStage } from "../../helper/Auth";
import { OnboardingStage } from "../../helper/OnboardingStages";
import { onboardingRouteMapper } from "../../helper/HelperFunctions";
import {get} from "lodash";

const form = Forms.SignUpForm;

export interface PropsFromSignUpState {
  readonly loading?: boolean;
  readonly success?: boolean;
  readonly signUpError?: boolean;
  readonly registerClientDetails?: RegisterClientResponse | undefined;
}

interface StateProps {
  errorMessage: string | null;
}

interface PropsFromLoginState {
  readonly userInputs?: any | null;
}
interface PropsFromSignUpForm {
  readonly data?: SignUpSuccessPayload | null;
}
interface PropsFromDispatch {
  readonly onSubmit: (userCredentials: SignUpRequest) => void;
  readonly getClientToken: () => void;
}
interface PropsForm {
  handleSubmit: any;
  invalid: boolean;
  success: boolean;
}

interface SignUpPropsFromRoute {
  readonly agencyCode: string | undefined;
}

type PropsFromRoute = RouteComponentProps<SignUpPropsFromRoute>;

type OwnProps = PropsFromRoute &
  PropsFromSignUpState &
  PropsFromDispatch &
  PropsForm &
  PropsFromSignUpForm &
  PropsFromLoginState;

export class SignUpComponent extends React.Component<OwnProps, StateProps> {
  public constructor(props: OwnProps) {
    super(props);
  }

  public handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { agencyCode } = this.props.match.params;
    if (agencyCode) {
      localStorage.setItem("agencyCode", agencyCode.toUpperCase());
    }
    const credentials = this.props.userInputs;
    let signUpRequest: SignUpRequest = {      
      username: credentials.email,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      email: credentials.email,
      password: credentials.password,
      userTradeEntitlements: [
        {
          userId: null,
          role: ADMIN_ROLE,
        },
      ],
    };
    if (agencyCode) {
      signUpRequest = { ...signUpRequest, agency: agencyCode };
    }
    if (credentials) {
      this.props.onSubmit(signUpRequest);
      this.setState({
        errorMessage: null,
      });
    }
  };

  public render() {
    const { success, invalid, signUpError } = this.props;
    const { agencyCode } = this.props.match.params;
    const errorMessage = signUpError ? signUpError.toString() : null;
    const from = get(this.props.location, "state.from", routes.dashboard.view);
    /* istanbul ignore else*/
    if (agencyCode && !(agencyCode.toLowerCase() in agencies)) {
      this.props.history.push(routes.signUp.view(agencyCode));
    }
     return isLoggedIn() ? (
      onboardingStage() === OnboardingStage.Completed ? (
        <Redirect to={from} />
      ) : (
        <Redirect to={onboardingRouteMapper(onboardingStage() as string)} />
      )
    ) : (
      <form name={form} className="SignUp-form">
        <SignUp
          handleFormSubmit={this.handleFormSubmit}
          errorMessage={errorMessage}
          agencyCode={agencyCode}
          success={success}
          invalid={invalid}
        />
      </form>
    );
  }
}

export const mapStateToProps: (
  state: ApplicationState
) => PropsFromSignUpState = (state) => ({
  loading: state.signUp.loading,
  success: state.signUp.success,
  signUpError: state.signUp.error,
  userInputs: state.form.signUpForm ? state.form.signUpForm.values : null,
  data: state.signUp.signUpDetails,
  registerClientDetails: state.login.registerClientDetails,
});

export const mapDispatchToProps: (
  dispatch: Dispatch<AnyAction>
) => PropsFromDispatch = (dispatch) => ({
  onSubmit: (userCredentials: SignUpRequest) => {
    dispatch(
      SignUpActions.signUpStart({
        request: userCredentials,
        onSuccess: (response: SignUpSuccessPayload) => {
          const {id, firstName, lastName, authToken, refreshToken, adminOnBoardingStage} = response;
          localStorage.setItem("userId", id.toString());
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("lastName", lastName);
          localStorage.setItem("authToken", authToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("onboardingStage", adminOnBoardingStage);
        },
      })
    );
  },
  getClientToken: () => {
    dispatch(LoginActions.getClientTokenStart());
  },
});

export const SignUpReduxForm = reduxForm({
  form,
})(SignUpComponent as any);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignUpReduxForm as any) as any
);
