import * as React from "react";
import { Checkbox, FormControlLabel, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps } from "formik";
import { RenderInputFieldStyles } from "./RenderInputFieldStyle";

interface Props {
  label: string;
  icon?: any;
  color?: "primary" | "secondary" | "default";
  style?: any;
  checkedIcon?: AnalyserNode;
  disabled?: boolean;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  labelStyle?: any;
  forceCheck?: boolean;
  containerClass?: string;
}

type OwnProps = Props & FieldProps<boolean>;

const RenderCheckbox = (props: OwnProps) => {
  const {
    labelStyle,
    labelPlacement,
    disabled,
    icon,
    checkedIcon,
    color,
    style,
    label,
    field,
    forceCheck,
    containerClass,
  } = props;
  const { name, checked } = props.field;
  const { touched, error } = props.meta;

  const defaultLabelStyle = makeStyles({
    label: {
      fontSize: "16px",
      lineHeight: 1.5,
      letterSpacing: "0.1px",
      color: "rgba(0, 0, 0, 0.87)",
    },
  })();
  const styles = RenderInputFieldStyles();
  return (
    <div className={`item-render ${containerClass || ""}`}>
      <FormControlLabel
        classes={{ label: labelStyle || defaultLabelStyle.label }}
        labelPlacement={labelPlacement ? labelPlacement : "end"}
        control={
          <Checkbox
            {...field}
            checked={checked || !!forceCheck}
            id={name}
            disabled={disabled}
            icon={icon}
            checkedIcon={checkedIcon}
            color={color}
            style={style}
          />
        }
        label={label}></FormControlLabel>
      {touched && error && (
        <FormHelperText
          id={`${name}-error-text`}
          className={styles.errorMessageStyles}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

export default RenderCheckbox;
