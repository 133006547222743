import { takeEvery, put } from "redux-saga/effects";
import { Logging } from "../../helper/Logging";
import TradeUsersService from "../../services/tradeUsers/TradeUsersService";
import {
  GetTradeUsersRequestPayload,
  GetTradeUsersResponse,
  GetTradeUsersRequest,
  PostTradeUserRequestPayload,
  PostTradeUserSuccessPayload,
} from "../../services/tradeUsers/TradeUsers.data";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import {
  GetTradeUsersActions,
  GetTradeUsersActionType,
  GetTradeUsersAction,
  PostTradeUserAction,
  PostTradeUserActions,
  PostTradeUserActionType,
} from "../actions/TradeUsersActions";

export function* getTradeUsersWorker(action: GetTradeUsersAction) {
  const data = action.data as GetTradeUsersRequestPayload;

  try {
    Logging.debug("TradeSaga.getTradeUsersWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    const response: any = (yield TradeUsersService.get(
      data!.request as GetTradeUsersRequest
    ))!;

    yield put(
      GetTradeUsersActions.getTradeUsersSuccess(
        response as GetTradeUsersResponse
      )
    );
  } catch (error) {
    Logging.error("TradeSaga.getTradeUsersWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(GetTradeUsersActions.getTradeUsersError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postTradeUserWorker(action: PostTradeUserAction) {
  const { request, onSuccess } = action.data as PostTradeUserRequestPayload;

  try {
    Logging.debug("TradeSaga.postTradeUserWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    const response: PostTradeUserSuccessPayload = (yield TradeUsersService.postUser(
      { request }
    ))!;

    yield put(PostTradeUserActions.postTradeUserSuccess(response));

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    Logging.error("TradeSaga.postTradeUserWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(PostTradeUserActions.postTradeUserError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const tradeUsersWatcher = [
  takeEvery(GetTradeUsersActionType.GetTradeUsersRequest, (action) =>
    getTradeUsersWorker({
      type: action.type,
      data: (action as GetTradeUsersAction).data,
    })
  ),
  takeEvery(PostTradeUserActionType.PostTradeUserRequest, (action) =>
    postTradeUserWorker({
      type: action.type,
      data: (action as PostTradeUserAction).data,
    })
  ),
];

export default tradeUsersWatcher;
