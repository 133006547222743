import React, { FC } from "react";
import { TradeCategory } from "../../../services/tradeCategories/TradeCategories.data";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { styled, makeStyles } from "@material-ui/core/styles";
import { color } from "../../../theme/Theme";
import TradeCategoryIcon from "../../tradeCategoryIcon/TradeCategoryIcon";
import { titleCase } from "../../../helper/HelperFunctions";

const useStyles = makeStyles({
  noPadding: {
    padding: "0",
  },
});

const StyledAvatar = styled(Avatar)({
  backgroundColor: color.light,
});

interface Props {
  selectedTradeCategories: TradeCategory[];
  removeCategory: (id: number) => void;
  noListPadding?: boolean;
}

const YourTrades: FC<Props> = (props) => {
  const { selectedTradeCategories, removeCategory, noListPadding } = props;
  const classes = useStyles();
  return (
    <List classes={{ padding: noListPadding ? classes.noPadding : "" }}>
      {selectedTradeCategories.map((trade) => (
        <ListItem button key={trade.id} divider>
          <ListItemAvatar>
            <StyledAvatar>
              <TradeCategoryIcon tradeName={trade.name} />
            </StyledAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="h4">
                {titleCase(trade.description)}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <Cancel
              style={{ cursor: "pointer" }}
              onClick={() => removeCategory(trade.id)}
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default YourTrades;
