import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { ContentBoxSectionStyles } from "./ContentBoxSectionStyle.data";

interface Props {
  text: string;
  noBorder?: boolean;
  variant?:
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "overline"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "srOnly"
    | undefined;
    containerClass?: string
}

type OwnProps = Props;

const ContentBoxSubheading: FC<OwnProps> = (props) => {
  const classes = ContentBoxSectionStyles();
  const { text, noBorder, variant, containerClass } = props;
  return (
    <div className={containerClass}>
      <Typography
        className={`${classes.subheading} ${noBorder ? classes.noBorder : ""}`}        
        variant={variant ? variant : "h4"}>
        {text}
      </Typography>
    </div>
  );
};

export default ContentBoxSubheading;
