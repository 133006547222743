import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import { ServiceType } from "../ServiceType.data";
import {
  GetPaymentAuthKey,
  GetPaymentMethodsResponse,
  PostPaymentToken,
  PostPaymentTokenResponse,
} from "./PaymentService.data";

const service = ServiceType.Payment;

export class PaymentService {
  private readonly service: ApiServiceBase = new ApiService(service);

  public getPaymentAuthKey = ():
    | Promise<GetPaymentAuthKey>
    | GetPaymentAuthKey => {
    return this.service.get({
      route: ["payment-methods/auth-key"],
    });
  };

  public postPaymentToken(
    data: PostPaymentToken
  ): Promise<PostPaymentTokenResponse> | PostPaymentTokenResponse {
    return this.service.post(
      {
        route: [`trade/payment-methods`],
      },
      data
    );
  }

  public getPaymentMethods = (
    tradeId: number
  ): Promise<GetPaymentMethodsResponse> | GetPaymentMethodsResponse => {
    return this.service.get({
      route: [`trade/${tradeId}/payment-methods`],
    });
  };

  public deletePayment = (
    paymentRefId: string
  ): Promise<string[]> | string[] => {
    return this.service.delete({
      route: ["trade/payment-methods", paymentRefId],
    });
  };

  public setDefaultPaymentType = (
    paymentRefId: string
  ): Promise<void> | void => {
    return this.service.post(
      {
        route: ["trade/default-payment-method"],
      },
      {
        refId: paymentRefId,
      }
    );
  };
}

const paymentService = new PaymentService();
export default paymentService;
