import { UpdateTeamMemberAction, UpdateTeamMemberActions, UpdateTeamMemberActionTypes } from "../actions/UpdateTeamMembersAction";
import { put, takeEvery } from "redux-saga/effects";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import { UpdateUserPayload, UpdateUserResponse } from "../../services/user/UserData";
import userService from "../../services/user/UserService";

export function* updateTeamMemberDetailsWorker(
    action: UpdateTeamMemberAction
  ): IterableIterator<any> {
    try {
      yield put(LoadingIndicatorActions.show());
      const data = action.data! as UpdateUserPayload;
      const { request, onSuccess } = { ...data };
      const response = yield userService.putUserDetails(request, true);
      yield put(
        UpdateTeamMemberActions.putTeamMemberDetailsSuccess(response! as UpdateUserResponse)
      );
      // OnSuccess
      if (onSuccess) {
        onSuccess(response!);
      }
    } catch (error) {
      yield put(UpdateTeamMemberActions.putTeamMemberDetailsError(error));
    } finally {
      yield put(LoadingIndicatorActions.hide());
    }
  }

  const updateTeamMemberDetailsWatcher = [
    takeEvery(UpdateTeamMemberActionTypes.UPDATE_TEAM_MEMBER_DETAILS_REQUEST, (action) =>
      updateTeamMemberDetailsWorker({
        type: action.type,
        data: (action as UpdateTeamMemberAction).data,
      })
    ),
  ];
  
  export default updateTeamMemberDetailsWatcher;
  