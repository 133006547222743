import { makeStyles } from "@material-ui/core";
import { color } from "../../../../../theme/Theme";

export const useStyles = makeStyles({
  headerLogo: {
    paddingBottom: "15px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logo: {
    maxWidth: 90,
    paddingRight: "20px",
    marginRight: "20px",
    borderRight: `2px solid ${color.primary}`,
  },
  labelStyle: {
    color: color.primary,
    margin: 0,
    fontWeight: 500,
  },
  propertyAvatarRoot: {
    width: "64px",
    height: "64px",
    marginRight: "1em",
  },
  propertyJobDetailLabels: {
    marginBottom: ".1em",
  },
  propertyDetailsHeading: {
    marginBottom: ".5em",
  },
  labelMarginRight: {
    marginRight: "5px",
  },
  additionalDetailKey: {
    color: color.secondary200,
  },
  contactCardDetailsListItem: {
    // Important is required because MUI's ListItem implementation overrides the properties
    paddingLeft: "8px !important",
    paddingTop: 0,
    paddingBottom: 0,
  },
  contactCardDetailsListItemText: {
    fontSize: "16px",
  },
  image: {
    width: "80px",
    height: "80px",
    borderRadius: "4px",
    marginRight: "20px",
  },
  agencyName: {
    fontSize: "15px",
    color: color.secondary500,
  },
});
