import React, { FC } from "react";
import { ListItemText, Typography, makeStyles, Box } from "@material-ui/core";
import theme, { color } from "../../../theme/Theme";

interface Props {
  primary: string;
  secondary?: string;
  primaryColor?: string;
  secondaryColor?: string;
}

const useStyles = makeStyles({
  listItem: {
    paddingRight: "20px",
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  date: {
    whiteSpace: "nowrap",
  },
});

const ListItemSecondaryDetailComponent: FC<Props> = (props) => {
  const { primary, secondary, primaryColor, secondaryColor } = props;
  const classes = useStyles();
  return (
    <ListItemText
      className={classes.listItem}
      primary={
        <Box display="inline-block" color={primaryColor || color.secondary200}>
          <Typography component="h4" variant="caption" color="inherit">
            {primary}
          </Typography>
        </Box>
      }
      primaryTypographyProps={{ variant: "caption" }}
      secondary={
        <Box
          display="inline-block"
          color={secondaryColor || color.secondary500}>
          <Typography
            className={classes.date}
            color="textSecondary"
            variant="caption">
            {secondary}
          </Typography>
        </Box>
      }
    />
  );
};

export default ListItemSecondaryDetailComponent;
