import React, { useState, useEffect } from "react";
import { ApplicationState } from "../../store/RootReducers";
import { useSelector, useDispatch } from "react-redux";
import Login from "../../component/login/Login";
import { History } from "history";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import {
  LoginResponse,
  LoginPayload,
  VerificationLinkPayload,
} from "../../services/login/LoginData";
import localStorage from "../../services/LocalStorage";
import { errorMessages } from "../../constants/AppConstants";
import { LoginActions } from "../../store/actions/LoginActions";
import { routes } from "../../Routes";
import { isLoggedIn, onboardingStage } from "../../helper/Auth";
import { OnboardingStage } from "../../helper/OnboardingStages";
import { onboardingRouteMapper } from "../../helper/HelperFunctions";

interface Props {
  history: History;
}

interface PropsFromState {
  loginDetails: LoginResponse | undefined;
  loginError: any; //Error object
}

interface PropsFromDispatch {
  readonly postLogin: (data: LoginPayload) => void;
  readonly sendVerificationEmail: (data: VerificationLinkPayload) => void;
}

type OwnProps = PropsFromState & Props & PropsFromDispatch;

const LoginContainer: React.FC<OwnProps> = (props) => {
  // State
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyError, setVerifyError] = useState("");

  // dispatcher
  const dispatch = useDispatch();
  const dispatcher = {
    postLogin: (data: LoginPayload) => {
      dispatch(LoginActions.postLoginStart(data));
    },

    sendVerificationEmail: (data: VerificationLinkPayload) => {
      dispatch(LoginActions.postVerificationLinkStart(data));
    },

    postLoginSuccess: (data: LoginResponse) => {
      dispatch(LoginActions.postLoginSuccess(data));
    },
  };

  // React Router Props
  const location = useLocation();
  let fromPage = routes.dashboard.view;
  if (location.state) {
    const { from } = location.state as any;
    fromPage = from.pathname;
  }

  const history = useHistory();
  const userEmail =
    (props as any).location.state && (props as any).location.state.userEmail;

  // ReduxProps
  const { loginError, loginDetails } = useSelector<
    ApplicationState,
    PropsFromState
  >((state: ApplicationState) => ({
    loginError: state.login.error,
    loginDetails: state.login.loginDetails,
  }));

  // Effects
  useEffect(() => {
    if (!isLoggedIn()) {
      var authTokenBasic = localStorage.getItem("authTokenBasic");
      localStorage.clearAll();
      localStorage.setItem("authTokenBasic", authTokenBasic!);
    }
  }, []);

  useEffect(() => {
    if (loginError && loginError.response) {
      if (
        loginError.response.status === 401 ||
        loginError.response.status === 403
      ) {
        setErrorMessage(errorMessages.loginPage.incorrectCredentials);
      } else if (loginError.response.status === 400) {
        setVerifyError(errorMessages.loginPage.emailVerificationFailed);
      }
    }
  }, [loginError]);

  useEffect(() => {
    if (loginDetails && loginDetails.authToken) {
      const {
        authToken,
        refreshToken,
        firstName,
        lastName,
        email,
        id,
        adminOnBoardingStage,
        userTradeEntitlements,
      } = loginDetails!;

      localStorage.setItem("authToken", authToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("lastName", lastName);
      localStorage.setItem("email", email);
      localStorage.setItem("userId", id.toString());
      localStorage.setItem("onboardingStage", adminOnBoardingStage);
      if (
        userTradeEntitlements &&
        userTradeEntitlements.length > 0 &&
        userTradeEntitlements[0].tradeId
      ) {
        localStorage.setItem(
          "tradeId",
          userTradeEntitlements[0].tradeId.toString()
        );
        localStorage.setItem("role", userTradeEntitlements[0].role);
        if (userTradeEntitlements[0].officeId) {
          localStorage.setItem(
            "firstOfficeId",
            userTradeEntitlements[0].officeId!.toString()
          );
        }
      }
    }
  }, [loginDetails]);

  return isLoggedIn() ? (
    onboardingStage() === OnboardingStage.Completed ||
    onboardingStage() === "null" ? (
      <Redirect to={fromPage} />
    ) : (
      <Redirect to={onboardingRouteMapper(onboardingStage() as string)} />
    )
  ) : (
    <Login
      history={history}
      postLogin={dispatcher.postLogin}
      errorMessage={errorMessage}
      verifyError={verifyError}
      sendVerificationEmail={dispatcher.sendVerificationEmail}
      userEmail={userEmail}
    />
  );
};

export default LoginContainer;
