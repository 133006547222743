import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { Job } from "src/services/jobs/JobsData.data";
import DashboardButton from "../../../dashboardButton/DashboardButton";
import { LABELS } from "./ConfirmJobCancelDialogConstants";
import { ConfirmCancelJobDialogStyles } from "./ConfirmJobCancelDialogStyles";

interface ConfirmJobCancelDialogProps {
  onSubmit: (notes: string) => void;
  onCancel: () => void;
  attentionMessage: string;
  job: Job;
}

const ConfirmJobCancelDialog: FC<ConfirmJobCancelDialogProps> = ({
  onSubmit,
  onCancel,
  attentionMessage,
}) => {
  const classes = ConfirmCancelJobDialogStyles();
  const [note, setNote] = useState<string>("");
  const disabled = note.trim() === "";
  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open>
      <DialogTitle>{LABELS.message}</DialogTitle>
      <DialogContent>
        <TextField
          InputProps={{ classes: { input: classes.textField } }}
          fullWidth
          rows={4}
          value={note}
          onChange={({ target: { value } }) => setNote(value)}
          variant="outlined"
          multiline
        />

        <Paper
          className={classes.attentionMessageContainer}
          variant="outlined"
          elevation={0}>
          <Typography variant="subtitle2">{LABELS.attention}</Typography>
          <Typography variant="body2">{attentionMessage}</Typography>
        </Paper>

        <Box mt={2} className={classes.buttonsContainer}>
          <DashboardButton
            centered
            action={onCancel}
            standardSize
            text="Cancel"
          />
          <DashboardButton
            centered
            action={() => onSubmit(note)}
            standardSize
            disabled={disabled}
            text="Confirm"
            variant={disabled ? "outlined" : "contained"}
            color="secondary"
            lightText={!disabled}
            greyText={disabled}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmJobCancelDialog;
