import {
  UpdateUserPayload,
  UpdateUserResponse,
} from "../../services/user/UserData";
  
  export enum UpdateTeamMemberActionTypes {
  
    UPDATE_TEAM_MEMBER_DETAILS_REQUEST = "UPDATE_TEAM_MEMBER_DETAILS_REQUEST",
    UPDATE_TEAM_MEMBER_DETAILS_SUCCESS = "UPDATE_TEAM_MEMBER_DETAILS_SUCCESS",
    UPDATE_TEAM_MEMBER_DETAILS_RESET = "UPDATE_TEAM_MEMBER_DETAILS_RESET",
    UPDATE_TEAM_MEMBER_DETAILS_ERROR = "UPDATE_TEAM_MEMBER_DETAILS_ERROR",
  }
  
  export interface UpdateTeamMemberAction {
    type: UpdateTeamMemberActionTypes;
    data?:
    UpdateUserPayload
    | Error;
  }
  
  export class UpdateTeamMemberActions {
  
    public static putTeamMemberDetailsStart(data: UpdateUserPayload): UpdateTeamMemberAction {
      return {
        type: UpdateTeamMemberActionTypes.UPDATE_TEAM_MEMBER_DETAILS_REQUEST,
        data,
      };
    }
    public static putTeamMemberDetailsSuccess(data: UpdateUserResponse): UpdateTeamMemberAction {
      return {
        type: UpdateTeamMemberActionTypes.UPDATE_TEAM_MEMBER_DETAILS_SUCCESS,
      };
    }
    public static putTeamMemberDetailsError(error: Error): UpdateTeamMemberAction {
      return {
        type: UpdateTeamMemberActionTypes.UPDATE_TEAM_MEMBER_DETAILS_ERROR,
        data: error,
      };
    }
    public static putTeamMemberDetailsReset(): UpdateTeamMemberAction {
      return {
        type: UpdateTeamMemberActionTypes.UPDATE_TEAM_MEMBER_DETAILS_RESET,
      };
    }
  }
  