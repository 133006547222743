import OnboardingStageService from "../../services/onboardingStage/OnboardingStageService";
import { OnboardingStage } from "../OnboardingStages";
import { getUserId } from "../Auth";

export const onboardingStageUpdater = async (stage: OnboardingStage) => {
  const id = getUserId()!;
  try {
    await OnboardingStageService.put({
      id,
      adminOnBoardingStage: stage,
    });
    localStorage.setItem("onboardingStage", stage);
  } catch (error) {
    return;
  }
};
