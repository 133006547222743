import React, { FC } from "react";
import { LABELS } from "./JobsComponentConstants.data";
import ContentHeadingSection from "../contentHeadingSection/ContentHeadingSection";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import DashboardWrapperComponent from "../DashboardWrapperComponent";
import { TopNavType } from "../../topNav/TopNav.data";
import { TopNavDashboardLink } from "../../topNav/TopNavDashboardLink.data";
import DetailsSidebar from "../../detailsSidebar/DetailsSideBar";
import { GetUserResponse } from "../../../services/login/LoginData";
import Jobs from "./jobsList/Jobs";
import JobDetailsSidebar from "./jobDetailsSidebar/JobDetailsSidebar";
import { Property } from "../../../models/property/Property.data";
import { getJobProperty } from "../../../helper/HelperFunctions";
import { ArrowBack } from "@material-ui/icons";
import { cancelledJobTypes, JobStatus } from "../../../models/jobs/JobData";
import {
  Job,
  JobStatusCount,
  PaginatedJobResponse,
} from "../../../services/jobs/JobsData.data";
import { JobsComponentStyles } from "./JobsComponentStyles.data";

interface JobComponentProps {
  userDetails: GetUserResponse;
  onFilterChange: (filter: JobStatus[]) => void;
  jobsResponse: PaginatedJobResponse;
  propertyList: Property[];
  // This job status corresponds to filter status and thereby can be an array of status
  jobStatus: JobStatus[];
  setSidebarJob: React.Dispatch<React.SetStateAction<Job | null>>;
  sidebarJob: Job | null;
  loading: boolean;
  onPageSizeChange: (newPageSize: number) => void;
  onPageChange: (page: number, size: number) => void;
  jobStatusCount: JobStatusCount;
  onAddressSearch: (address: string) => void;
}

const JobComponent: FC<JobComponentProps> = (props) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    userDetails,
    onFilterChange,
    jobStatus,
    propertyList,
    setSidebarJob,
    sidebarJob,
    loading,
    jobsResponse: { content: jobs, pageable, totalElements },
    onPageChange,
    onPageSizeChange,
    jobStatusCount,
    onAddressSearch,
  } = props;

  const classes = JobsComponentStyles();

  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow="1">
        <DashboardWrapperComponent
          navType={TopNavType.Dashboard}
          activeLink={TopNavDashboardLink.Jobs}
          userDetails={userDetails}>
          <>
            {downSm && sidebarJob ? (
              <Box p={2}>
                <Box display="flex" alignItems="center">
                  <Box
                    onClick={() => setSidebarJob(null)}
                    className={classes.pointer}>
                    <ArrowBack />
                  </Box>
                  <Box ml={2} display="inline-block">
                    <ContentHeadingSection
                      hideBack
                      heading={LABELS.jobDetails}
                    />
                  </Box>
                </Box>
                <br />
                <JobDetailsSidebar
                  property={getJobProperty(sidebarJob, propertyList)}
                  job={sidebarJob}
                  hideButton={jobStatus === cancelledJobTypes}
                />
              </Box>
            ) : (
              <>
                <ContentHeadingSection
                  heading={LABELS.jobs}
                  hideOnSmallScreens
                  hideBack
                />
                <Jobs
                  {...props}
                  loading={loading}
                  userDetails={userDetails}
                  onFilterChange={onFilterChange}
                  jobs={jobs}
                  jobStatusCount={jobStatusCount}
                  count={totalElements}
                  propertyList={propertyList}
                  status={jobStatus}
                  showJobDetailsInSidebar={setSidebarJob}
                  sidebarJobId={!!sidebarJob ? sidebarJob.id : undefined}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  pageable={pageable}
                  onAddressSearch={onAddressSearch}
                />
              </>
            )}
          </>
        </DashboardWrapperComponent>
      </Box>
      <Box maxWidth="390px">
        <DetailsSidebar userDetails={userDetails}>
          {sidebarJob && (
            <JobDetailsSidebar
              property={getJobProperty(sidebarJob, propertyList)}
              job={sidebarJob}
              hideButton={jobStatus === cancelledJobTypes}
            />
          )}
        </DetailsSidebar>
      </Box>
    </Box>
  );
};

export default JobComponent;
