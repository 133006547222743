import * as React from "react";
import { Button, Grid, withStyles } from "@material-ui/core";
import { Field } from "redux-form";
import { Link } from "react-router-dom";
import { routes } from "../../Routes";
import RenderInput from "../RenderInput/RenderInput";
import { isRequired, email } from "../../helper/Validation";
import {
  styles,
  inputsFields,
  loginLabels,
  SignUpStyles,
} from "./SignUpStyles.data";
import { AgencyCodeImgHelper } from "../../helper/AgencyCodeImgHelper";
import { ResetPasswordData } from "./signUpData.data";
import "./SignUp.scss";
import * as imageGeneric from "../../assets/sorted-logo.jpg";
import PasswordInstructions from "../passwordInstructions/PasswordInstructions";

interface SignUpStylesProps {
  readonly classes: SignUpStyles;
}

interface UserInput {
  readonly firstName?: string | undefined;
  readonly lastName?: string | undefined;
  readonly email: string;
  readonly password: string;
}
const { validation } = { ...ResetPasswordData.metadata };

interface PropsFromParent {
  success: boolean | undefined | null;
  errorMessage: string | null;
  agencyCode: string | undefined;
  userInputs?: UserInput | null;
  handleFormSubmit: (e: any) => void;
  invalid: boolean;
}

interface StateProps {
  showPassword: boolean;
}

type OwnProps = PropsFromParent & SignUpStylesProps;

export class SignUp extends React.Component<OwnProps, StateProps> {
  // Email is required
  private readonly isRequired = {
    email: isRequired("Email Address is required"),
  };
  // Email is valid
  private readonly isValidEmail = {
    email: email("Check the email format (e.g. name@domain.com)"),
  };

  constructor(props: OwnProps) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }
  public handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  public render(): JSX.Element {
    const { classes, errorMessage, agencyCode, handleFormSubmit, invalid } =
      this.props;
    // get header logo image
    const imgSrc = AgencyCodeImgHelper.getImgSrc(agencyCode);
    const { showPassword } = this.state;

    return (
      <div className="signUp-fields" onKeyDown={this.handleKeyPress}>
        <div className={classes.loginComponent}>
          <div className={classes.imageContainer}>
            <div className={classes.glassPanel}>
              <Grid container className={classes.fullHeight}>
                <Grid item md={5} xs={12} sm={7} lg={4}>
                  <div className={classes.formStyle}>
                    <div className={classes.headerLogo}>
                      {
                        <img
                          src={imageGeneric}
                          alt="sorted logo"
                          className={classes.logo}
                        />
                      }
                      <h4 className={`${classes.labelStyle}`}>Trades Portal</h4>
                    </div>
                    <div className={classes.fieldsContainer}>
                      <div>
                        {imgSrc && (
                          <img
                            src={imgSrc}
                            alt="agency logo"
                            className={classes.agencyLogo}
                          />
                        )}
                      </div>
                      <div className={classes.titleStyle}>
                        <h2 className={classes.labelStyle}>
                          Sign up to Sorted
                        </h2>
                        <p style={{ marginTop: "9px" }}>
                          Provide some basic details to get started
                        </p>
                      </div>
                      <Field
                        id="firstName"
                        name="firstName"
                        label="First name"
                        type="text"
                        component={RenderInput}
                        style={inputsFields}
                        overrideInputStyles={{ input: classes.input }}
                        labelStyle={loginLabels}
                        overrideLabelStyles={{ shrink: classes.labels }}
                        validate={validation.firstName}
                      />
                      <Field
                        id="lastName"
                        name="lastName"
                        label="Last name"
                        type="text"
                        component={RenderInput}
                        style={inputsFields}
                        overrideInputStyles={{ input: classes.input }}
                        labelStyle={loginLabels}
                        overrideLabelStyles={{ shrink: classes.labels }}
                        validate={validation.lastName}
                      />
                      <Field
                        id="signUpEmail"
                        name="email"
                        label="Your email address"
                        type="Email"
                        component={RenderInput}
                        validate={[
                          this.isRequired.email,
                          this.isValidEmail.email,
                        ]}
                        style={inputsFields}
                        overrideInputStyles={{ input: classes.input }}
                        labelStyle={loginLabels}
                        overrideLabelStyles={{ shrink: classes.labels }}
                      />
                      <Field
                        name="password"
                        label="Enter a password"
                        style={inputsFields}
                        labelStyle={loginLabels}
                        component={RenderInput}
                        type={showPassword ? "text" : "password"}
                        overrideInputStyles={{ input: classes.input }}
                        overrideLabelStyles={{ shrink: classes.labels }}
                        validate={validation.password}
                      />
                      <PasswordInstructions />
                      <Field
                        name="confirmPassword"
                        label="Confirm Password"
                        style={inputsFields}
                        labelStyle={loginLabels}
                        component={RenderInput}
                        type={showPassword ? "text" : "password"}
                        overrideInputStyles={{ input: classes.input }}
                        overrideLabelStyles={{ shrink: classes.labels }}
                        validate={validation.confirmPassword}
                      />
                      {errorMessage && (
                        <div className={classes.errorText}>{errorMessage}</div>
                      )}
                      <div className={classes.buttonContainer}>
                        <Button
                          id="signUpButton"
                          fullWidth={true}
                          variant={invalid ? "outlined" : "text"}
                          className={`${classes.loginButton} ${
                            !invalid && classes.loginButtonActive
                          }`}
                          onClick={handleFormSubmit}
                          disabled={invalid}>
                          SIGN UP
                        </Button>

                        <p className={`${classes.bottomLink} paragraph-small`}>
                          Already have an account?
                          <Link
                            to={
                              agencyCode
                                ? routes.login.view(agencyCode)
                                : routes.login.viewGeneric()
                            }
                            className={classes.loginLink}>
                            {" "}
                            Log in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Function to handle form key press event
  private handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13) {
      this.props.handleFormSubmit(e);
    }
  };
}

export default withStyles(styles)(SignUp);
