import { takeEvery, put } from "redux-saga/effects";
import { Logging } from "../../helper/Logging";
import {
  GetBranchRequestPayload,
  GetBranchSuccessPayload,
  GetBranchRequest,
} from "../../services/branch/Branch.data";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import {
  GetBranchActionType,
  GetBranchAction,
  GetBranchActions,
} from "../actions/BranchActions";
import BranchService from "../../services/branch/BranchService";

export function* getBranchWorker(action: GetBranchAction) {
  const data = action.data as GetBranchRequestPayload;

  try {
    Logging.debug("TradeSaga.getBranchWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    const response: GetBranchSuccessPayload = (yield BranchService.get(
      data!.request as GetBranchRequest
    ))!;

    yield put(
      GetBranchActions.getBranchSuccess(response as GetBranchSuccessPayload)
    );
  } catch (error) {
    Logging.error("TradeSaga.getBranchWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(GetBranchActions.getBranchError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const branchWatcher = [
  takeEvery(GetBranchActionType.GetBranchRequest, (action) =>
    getBranchWorker({
      type: action.type,
      data: (action as GetBranchAction).data,
    })
  ),
];

export default branchWatcher;
