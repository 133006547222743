import { Box, Button, Typography } from "@material-ui/core";
import { CheckCircleRounded } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { PaymentMethodType } from "../dashboard/settings/payment/SavedPaymentMethodsConstants.data";
import AddBank from "./addBank/AddBank";
import AddCreditCard from "./addCreditCard/AddCreditCard";
import {
  AddPaymentMethodSteps,
  getStep,
  paymentMethodTypes,
} from "./AddPaymentMethodConstants.data";
import { addPaymentMethodStyles } from "./AddPaymentMethodStyles.data";

interface Props {
  secondaryButtonText?: string;
}

const AddPaymentMethod: FC<Props> = () => {
  const [selectedMethodType, setSelectedMethodType] = useState<
    PaymentMethodType | undefined
  >();
  const [currentStep, setCurrentStep] = useState<AddPaymentMethodSteps>(
    AddPaymentMethodSteps.SELECT_METHOD_TYPE
  );

  const classes = addPaymentMethodStyles();

  const handleSubmit = (methodType: PaymentMethodType) => {
    setCurrentStep(getStep(methodType));
  };

  const resetForm = () => {
    setSelectedMethodType(undefined);
  };

  const renderForm = () => {
    switch (currentStep) {
      case AddPaymentMethodSteps.SELECT_METHOD_TYPE:
        return (
          <Box>
            <Box>
              {Object.values(paymentMethodTypes).map(
                ({ type, title, icon }) => {
                  return (
                    <Button
                      fullWidth
                      onClick={() => handleSubmit(PaymentMethodType.Credit)}
                      className={classes.menuItem}
                      startIcon={<img src={icon} />}
                      endIcon={
                        selectedMethodType === type ? (
                          <CheckCircleRounded
                            className={classes.selectedIcon}
                          />
                        ) : (
                          ""
                        )
                      }>
                      <Typography className={classes.menuText}>
                        {title}
                      </Typography>
                    </Button>
                  );
                }
              )}
            </Box>
            {/* Buttons not needed for now since the only payment method that is supported is credit card */}
            {/* <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.mainContainer}>
              {secondaryButtonText && (
                <Button
                  fullWidth
                  className={classes.secondaryButton}
                  onClick={secondaryButtonAction}>
                  {secondaryButtonText}
                </Button>
              )}
              <Button
                fullWidth
                variant="contained"
                className={classes.primaryButton}
                disabled={!selectedMethodType}
                onClick={handleSubmit}>
                {PopupLabels.primaryButtonText}
              </Button>
            </Box> */}
          </Box>
        );
      case AddPaymentMethodSteps.ADD_BANK_DETAILS:
        return (
          <Box>
            <AddBank
              onBack={() => {
                resetForm();
                setCurrentStep(AddPaymentMethodSteps.SELECT_METHOD_TYPE);
              }}
              onSubmit={() => {}}
            />
          </Box>
        );
      case AddPaymentMethodSteps.ADD_CARD_DETAILS:
        return (
          <Box>
            <AddCreditCard
              onBack={() => {
                resetForm();
                setCurrentStep(AddPaymentMethodSteps.SELECT_METHOD_TYPE);
              }}
              onSubmit={() =>
                setCurrentStep(AddPaymentMethodSteps.SELECT_METHOD_TYPE)
              }
            />
          </Box>
        );
      default:
        return "";
    }
  };

  return (
    <Box className={classes.mainContainer}>
      {/* Uncomment when we allow banks */}
      {/* <Typography variant="caption" className={classes.popupTitle}>
        {PopupLabels.title}
      </Typography>
      <Typography variant="subtitle1" className={classes.popupSubtitle}>
        {PopupLabels.subtitle}
      </Typography> */}
      {renderForm()}
    </Box>
  );
};

export default AddPaymentMethod;
