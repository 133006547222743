import React, { FC, useState } from "react";
import { debounce } from "lodash";
import { GetUserResponse } from "../../../../services/login/LoginData";
import FilterChipsSection from "../jobFilterChipsSection/JobFilterChipsSection";
import JobCard from "../jobCard/JobCard";
import {
  JobFilterStatus,
  JobFilterStatusChips,
} from "../jobFilterChipsSection/JobFilterChips.data";
import { getJobProperty } from "../../../../helper/HelperFunctions";
import { Property } from "../../../../models/property/Property.data";
import { Box, LinearProgress, Paper, TablePagination } from "@material-ui/core";
import { JobStatus } from "../../../../models/jobs/JobData";
import { Job, JobStatusCount } from "../../../../services/jobs/JobsData.data";
import { paginationRowsPerPageOptions } from "../../../../constants/AppConstants";
import { Pageable } from "../../../../services/quotes/QuotesData.data";
import { LABELS } from "./JobsListConstants.data";
import { BadgeData } from "../../quotes/quotesFilterChipsSection/QuotesFilterChipsSection";
import SearchBox from "../../../searchBox/SearchBox";

interface JobsProps {
  userDetails: GetUserResponse;
  onFilterChange: (filter: JobStatus[]) => void;
  jobs: Job[];
  count: number;
  propertyList: Property[];
  status: JobStatus[];
  showJobDetailsInSidebar: React.Dispatch<React.SetStateAction<Job | null>>;
  sidebarJobId?: string;
  loading: boolean;
  onPageSizeChange: (newPageSize: number) => void;
  onPageChange: (page: number, size: number) => void;
  pageable: Pageable;
  jobStatusCount: JobStatusCount;
  onAddressSearch: (address: string) => void;
}

const Jobs: FC<JobsProps> = (props) => {
  const {
    onFilterChange,
    status,
    showJobDetailsInSidebar,
    jobs,
    propertyList,
    sidebarJobId,
    count,
    onPageChange,
    loading,
    onPageSizeChange,
    pageable: { pageSize, pageNumber },
    jobStatusCount,
    onAddressSearch,
  } = props;

  // state
  const [searchRefreshCounter, setSearchRefreshCounter] = useState<number>(0);

  // Active filter chip
  let activeChip: JobFilterStatus;
  const isArrayStatus = Array.isArray(status);

  if (isArrayStatus) {
    JobFilterStatusChips.forEach((chip) => {
      if (
        Array.isArray(chip.value) &&
        JSON.stringify(chip.value) === JSON.stringify(status)
      ) {
        activeChip = chip.text;
      }
    });
  } else {
    JobFilterStatusChips.forEach((chip) => {
      if (chip.value === status) {
        activeChip = chip.text;
      }
    });
  }

  const badgeData: BadgeData[] = [
    {
      name: JobStatus.BookingRequired,
      count: jobStatusCount.bookingRequiredCount,
    },
    {
      name: JobStatus.Scheduled,
      count: jobStatusCount.scheduledCount,
    },
  ];

  // debounced function for search box
  const searchByAddress = debounce((address: string) => {
    onAddressSearch(address);
  }, 1500);

  return (
    <>
      <FilterChipsSection
        activeChip={activeChip!}
        badgeData={badgeData}
        setActiveFilterChip={(newFilterValue: JobStatus[]) => {
          setSearchRefreshCounter(searchRefreshCounter + 1);
          onFilterChange(newFilterValue);
        }}
      />
      <Box pt={2} px={2}>
        <SearchBox
          parentRefreshCounter={searchRefreshCounter}
          minChars={4}
          onChange={searchByAddress}
          placeHolder={"Search by address (4 characters at least)"}
        />
      </Box>
      {loading ? (
        <Box width="100%" paddingX="1em" mt={3}>
          <LinearProgress variant="indeterminate" />
        </Box>
      ) : (
        <Box mt={4} paddingX={"1em"}>
          {!!jobs.length ? (
            <>
              {jobs.map((job, index) => {
                const property = getJobProperty(job, propertyList);
                return (
                  <JobCard
                    key={`Quote-${index}`}
                    showJobDetailsInSidebar={showJobDetailsInSidebar}
                    status={status}
                    job={job}
                    property={property}
                    active={!!sidebarJobId && sidebarJobId === job.id}
                  />
                );
              })}

              <Box mb={2}>
                <TablePagination
                  component={"div"}
                  count={count}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={paginationRowsPerPageOptions}
                  page={pageNumber}
                  onChangePage={(e, page) => onPageChange(page, pageSize)}
                  onChangeRowsPerPage={({ target: { value } }) =>
                    onPageSizeChange(parseInt(value, 10))
                  }
                />
              </Box>
            </>
          ) : (
            <Paper elevation={0} variant="outlined">
              <Box py={2} px={2}>
                {LABELS.noJobs}
              </Box>
            </Paper>
          )}
        </Box>
      )}
    </>
  );
};

export default Jobs;
