import {
  TradeAction,
  TradeActions,
  TradeActionType,
} from "../actions/TradeActions";
import { Logging } from "../../helper/Logging";
import { put, takeEvery } from "redux-saga/effects";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import {
  GetTradeAgenciesResponse,
  PatchTermsResponseRequest,
  GetTradeAgenciesRequest
} from "../../services/tradeService/TradeServiceData.data";
import TradeService from "../../services/tradeService/TradeService";

export function* getTradeAgenciesWorker(action: TradeAction) {
  const data = action.data as GetTradeAgenciesRequest;
  try {
    Logging.debug("TradeSaga.getTradeAgenciesWorker", {
      type: action.type,
      data: Logging.traceData(data),
    });

    yield put(LoadingIndicatorActions.show());

    const response: GetTradeAgenciesResponse = yield TradeService.getTradeAgencies(data);

    yield put(TradeActions.GetTradeAgenciesSuccess(response));
  } catch (error) {
    yield put(TradeActions.GetTradeAgenciesError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* updateTermsResponseWorker(action: TradeAction) {
  try {
    Logging.debug("Trade.updateTermsResponseWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    const data = action.data as PatchTermsResponseRequest;

    yield put(LoadingIndicatorActions.show());

    yield TradeService.patchTermsAcceptance(data);
    yield put(TradeActions.updateTermsResponseSuccess(data));
  } catch (error) {
    yield put(TradeActions.updateTermsResponseError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const tradesWatcher = [
  takeEvery(TradeActionType.GetTradeAgenciesRequest, (action: TradeAction) =>
    getTradeAgenciesWorker({
      type: TradeActionType.GetTradeAgenciesRequest,
      data: action.data,
    })
  ),
  takeEvery(TradeActionType.UpdateTermsResponseRequest, (action) =>
    updateTermsResponseWorker({
      type: action.type,
      data: (action as TradeAction).data,
    })
  ),
];

export default tradesWatcher;
