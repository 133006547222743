import React, { FC } from "react";
import ContactCard, { Contact } from "../../../../contactCard/ContactCard";
import { useStyles } from "./PrintQuotePdfStyles";
import { Box } from "@material-ui/core";
import { color } from "../../../../../theme/Theme";

interface PDFContactCardProps {
  person: Contact;
  designation: string;
  avatarColor: string;
}

/**
 * @author
 * @function @PDFContactCard
 **/

export const PDFContactCard: FC<PDFContactCardProps> = (props) => {
  const classes = useStyles();
  return (
    <Box border={`1px solid ${color.borderColor}`} borderRadius="8px" p={1}>
      <ContactCard
        {...props}
        underlined
        disableSummaryPadding
        noElevation
        detailsListItemClass={classes.contactCardDetailsListItem}
        detailsListItemTextClass={classes.contactCardDetailsListItemText}
        noMessage
      />
    </Box>
  );
};
