import {
  ListBranchesAction,
  ListBranchesActionType,
} from "../actions/ListBranchesActions";
import {
  GetTradeSuccessPayload,
  GetTradeErrorPayload,
} from "../../services/listBranches/ListBranchesServiceData.data";
import { ListBranchesState } from "../state/ListBranchesState";
import { BranchResponse } from "../../services/branch/Branch.data";
import { cloneDeep } from "lodash";
import { Branch } from "../../container/listBranches/ListBranches.data";
import {
  UpdateBusinessActionType,
  UpdateBusinessAction,
} from "../actions/RegisterBusinessActions";
import { UpdateBusinessSuccessPayload } from "../../services/registerBusiness/RegistrationFormRequest.data";

const initialState: ListBranchesState = {
  loading: false,
  success: false,
  error: "",
  trade: null,
};

export const listBranchesReducer = (
  state = initialState,
  action: ListBranchesAction | UpdateBusinessAction
) => {
  switch (action.type) {
    case ListBranchesActionType.ListBranchesRequest: {
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    }

    case ListBranchesActionType.ListBranchesSuccess: {
      const tradeResponse = action.data as GetTradeSuccessPayload;
      return {
        ...state,
        trade: tradeResponse,
        loading: false,
        success: true,
        error: null,
      };
    }

    case ListBranchesActionType.ListBranchesError: {
      const data = action.data as GetTradeErrorPayload;
      return {
        ...state,
        loading: false,
        success: false,
        error: data ? data.error : null,
      };
    }

    case ListBranchesActionType.UpdateBranch: {
      const data = action.data as BranchResponse;
      const updatedTrade = cloneDeep(state.trade);

      updatedTrade!.offices = updatedTrade!.offices.map((office) => {
        if (office.id === data.id) {
          return data as Branch;
        }
        return office as Branch;
      });

      return {
        ...state,
        trade: updatedTrade,
      };
    }

    case UpdateBusinessActionType.UpdateBusinessSuccess: {
      const data = action.data as UpdateBusinessSuccessPayload;
      const { name, tradeTypeId, logoKey } = data;
      let updatedBusinessDetails = cloneDeep(state.trade);
      updatedBusinessDetails = {
        ...updatedBusinessDetails!,
        name,
        tradeTypeId,
        logoKey,
      };
      return {
        ...state,
        trade: updatedBusinessDetails,
      };
    }

    default:
      return state;
  }
};
