import React, { FC } from "react";
import RegistrationStepsWrapper from "../registrationStepsComponents/registrationStepsWrapper/RegistrationStepsWrapper";
import { LABELS } from "./ListBranchesConstants";
import { Trade, Branch } from "../../container/listBranches/ListBranches.data";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SkipText from "../skipText/SkipText";
import { ListBranchesStyles } from "./ListBranchesStyle.data";

interface PropsFromParent {
  trade: Trade | undefined | null;
  onNextAndSkip: () => void;
  errorMessage: string | null;
  verifyError: string | null;
  onAddNew: () => void;
}

type OwnProps = PropsFromParent;

const getAddress = (branch: Branch) =>
  `${branch.name} . ${branch.streetNumber} ${branch.streetName}, ${branch.suburb}, ${branch.state}`;

const getPrimaryText = (trade: Trade, branch: Branch) =>
  `${trade.name} – ${branch.name}`;

export const ListBranchesComponent: FC<OwnProps> = (props) => {
  const { trade, onNextAndSkip, onAddNew } = props;
  const classes = ListBranchesStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <RegistrationStepsWrapper
      heading={LABELS.heading}
      subheading={LABELS.subheading}
      primaryButton={{ text: "Next", action: onNextAndSkip }}
      noPaddingContentBox
      noPaddingButtonsSection
      footerComponent={
        <SkipText action={() => onNextAndSkip()}>
          {LABELS.skipAddLater}
        </SkipText>
      }>
      <List>
        {trade
          ? trade.offices.map((branch) => (
              <ListItem
                key={branch.id}
                className={`${classes.listItem} ${classes.listItemBox}`}>
                <ListItemAvatar>
                  <Avatar src={trade.logoKey}></Avatar>
                </ListItemAvatar>
                <div className={classes.textContainer}>
                  <ListItemText
                    primary={getPrimaryText(trade, branch)}
                    secondary={getAddress(branch)}
                    className={matches ? classes.listItemText : ""}
                  />
                  {branch.primary ? (
                    <div className={classes.primaryBranch}>
                      <div className={classes.primaryBranchText}>
                        {LABELS.primaryBranch}
                      </div>
                    </div>
                  ) : null}
                </div>
              </ListItem>
            ))
          : null}
        <ListItem
          onClick={() => {
            onAddNew();
          }}
          className={`${classes.listItem} ${classes.pointer}`}>
          <ListItemText primary="New office branch" />
          <div>
            <IconButton className={classes.addIcon}>
              <AddIcon />
            </IconButton>
          </div>
        </ListItem>
      </List>
    </RegistrationStepsWrapper>
  );
};
