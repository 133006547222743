import {makeStyles} from "@material-ui/core/styles";
import { color } from "../../../theme/Theme";

export const MostUsedCategoriesStyles = makeStyles({
  tradeCard: {
    borderRadius: "8px",
    border: `solid 1px ${color.lightGrey}`,
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.5em',
    cursor: 'pointer',
    height: "100%",
    justifyContent: "center"
  },
  selectedTradeCard: {
    backgroundColor: color.grey
  },
  container: {
    paddingTop: "1em",
    paddingBottom: "1em"
  },
  tradeImage: {
    marginBottom: ".7em"
  },
})