import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../theme/Theme";

export const SidebarStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: "linear-gradient(12deg, #2d1b49 76%, #412868 108%)",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      minHeight: "100vh",
      zIndex: "1000",
      padding: "10vh 0",
    },
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer: {
    marginTop: "2em",
    marginBottom: "2em",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    flexDirection: "column",
  },
  logoWrapper: {
    width: "64px",
    height: "64px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1em",
    [theme.breakpoints.down("md")]: {
      background: "transparent",
      border: "none",
    },
  },
  linksContainer: {
    flexGrow: 1,
    color: color.light,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  logo: {
    width: "60px",
    height: "60px",
    [theme.breakpoints.down("md")]: {
      width: "35px",
      height: "35px",
    },
  },
  sideBarText: {
    [theme.breakpoints.between("sm", "md")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      flexGrow: 0,
    },
  },
  sidebarCloseButton: {
    color: color.light,
    position: "absolute",
    top: "15px",
    right: "15px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    cursor: "pointer",
  },
}));
