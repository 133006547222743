import {
  Home,
  People,
  Settings,
  SvgIconComponent,
  // StoreOutlined,
} from "@material-ui/icons";
import { ReactComponent as HelpCenter } from "../../assets/help-center.svg";
import { links } from "../../constants/AppConstants";
import { routes } from "../../Routes";

export interface Link {
  text: string;
  route: string;
  logo: SvgIconComponent;
  absolute?: boolean;
}

export const Links: Link[] = [
  {
    text: "Home",
    route: routes.dashboard.view,
    logo: Home,
  },
  {
    text: "Team",
    route: routes.dashboard.team.agents,
    logo: People,
  },
  // {
  //   text: "Agents",
  //   route: "route",
  //   logo: StoreOutlined,
  // },
  {
    text: "Settings",
    route: routes.dashboard.settings.profile,
    logo: Settings,
  },
  {
    text: "Help center",
    route: links.helpCenter,
    logo: HelpCenter as SvgIconComponent,
    absolute: true,
  },
];
