import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import * as imageGeneric from "../../assets/sorted-logo.jpg";
import imageGenericDark from "../../assets/sorted_logo.svg";
import { AgencyCodeImgHelper } from "../../helper/AgencyCodeImgHelper";
import { useParams } from "react-router-dom";
import { AuthPagesWrapperStyles } from "./AuthPagesWrapperStyles.data";

interface Props {
  containerClassName: string;
  children: any;
  dark?: boolean;
  includeLogo?: boolean;
  occupyHalf?: boolean;
}

type OwnProps = Props;

const AuthPagesWrapper: FC<OwnProps> = props => {
  const { containerClassName, children, includeLogo, dark, occupyHalf } = props;
  const classes = AuthPagesWrapperStyles();
  const { agencyCode } = useParams();
  const imgSrc = AgencyCodeImgHelper.getImgSrc(agencyCode);

  return (
    <div className={containerClassName}>
      <div className={classes.loginComponent}>
        <div className={classes.imageContainer}>
          <div className={classes.glassPanel}>
            <Grid container className={classes.fullHeight}>
              <Grid item md={occupyHalf ? 6 : 5} xs={12} sm={occupyHalf ? 8 :7} lg={occupyHalf ? 6 : 4}>
                <div
                  className={
                    `${classes.formStyle}` +
                    (dark ? ` ${classes.formStyleDark}` : "")
                  }>
                  <div className={classes.headerLogo}>
                    {
                      <img
                        src={dark ? imageGenericDark :imageGeneric}
                        alt="sorted logo"
                        className={`${classes.logo} ${dark ? classes.logoDark : ''}`}
                      />
                    }
                    <h4
                      className={
                        `${classes.labelStyle}` +
                        (dark ? ` ${classes.labelStyleDark}` : "")
                      }>
                      Trades Portal
                    </h4>
                  </div>
                  <div className={classes.fieldsContainer}>
                    {includeLogo ? (
                      <div>
                        {imgSrc && (
                          <img
                            src={imgSrc}
                            alt="agency logo"
                            className={classes.agencyLogo}
                          />
                        )}
                      </div>
                    ) : null}
                    {children}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPagesWrapper;
