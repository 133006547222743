import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../../theme/Theme";

export const QuoteComponentStyles = makeStyles({
  pointer: {
    cursor: "pointer",
  },
  addAgentButton: {
    borderRadius: "4px",
    padding: ".5em 1em",

    backgroundColor: color.teal,
    color: color.light,
    textAlign: "center",
    "&:hover": {
      backgroundColor: color.teal,
    },
  },
});
