import React, { FC, useEffect, useState } from "react";
import ViewJobComponent from "../../component/dashboard/jobs/viewJob/ViewJobComponent";
import DetailsSidebar from "../../component/detailsSidebar/DetailsSideBar";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import JobDetailsSideBar from "../../component/dashboard/jobs/jobDetailsSidebar/JobDetailsSidebar";
import { GetUserResponse } from "../../services/login/LoginData";
import { useAppSelector } from "../../store/RootReducers";
import { useDispatch } from "react-redux";
import { isNull } from "lodash";
import { JobsActions } from "../../store/actions/JobsActions";
import { useParams } from "react-router";
import DashboardWrapperComponent from "../../component/dashboard/DashboardWrapperComponent";
import { TopNavType } from "../../component/topNav/TopNav.data";
import { TopNavDashboardLink } from "../../component/topNav/TopNavDashboardLink.data";
import { getWorkOrderById } from "../../helper/HelperFunctions";
import {
  ConfirmJobBookingRequestPayload,
  ChangeJobStatusRequestPayload,
  AddArtefactsRequest,
  AddJobVariationRequest,
  JobCostUpdateRequest,
} from "../../services/jobs/JobsData.data";
import { ConfirmJobBookingFormValues } from "../../component/dashboard/jobs/viewJob/ViewJobComponentConstants.data";
import { ArtefactsFormValues } from "../../component/dashboard/jobs/addArtefactsModal/AddArtefactsModal";
import { Quote } from "../../models/quote/Quote.data";
import { ArrowBack } from "@material-ui/icons";
import ContentHeadingSection from "../../component/dashboard/contentHeadingSection/ContentHeadingSection";

interface ViewJobProps {
  userDetails: GetUserResponse;
}

interface ViewJobPathParams {
  jobId: string;
  variationJobId?: string;
}

const ViewJob: FC<ViewJobProps> = ({ userDetails }) => {
  const { jobData, users } = useAppSelector((state) => ({
    jobData: state.jobs.viewJobData,
    users: state.jobs.jobUsers,
  }));

  const [showSidebarJob, setShowSidebarJob] = useState<boolean>(false);

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();

  const dispatcher = {
    getJobById: (id: string) =>
      dispatch(JobsActions.GetJobUsingId({ jobId: id })),

    getUsers: (workorderTradeCategory: string) =>
      dispatch(
        JobsActions.GetUsersForWorkorderRequest({ workorderTradeCategory })
      ),

    confirmJobBooking: (requestPayload: ConfirmJobBookingRequestPayload) =>
      dispatch(JobsActions.ConfirmJobBookingRequest(requestPayload)),

    changeJobStatus: (requestPayload: ChangeJobStatusRequestPayload) =>
      dispatch(JobsActions.ChangeJobStatusRequest(requestPayload)),

    addArtefacts: (request: AddArtefactsRequest) =>
      dispatch(JobsActions.AddArtefactsRequest(request)),

    submitJobVariationQuote: (request: AddJobVariationRequest) => {
      dispatch(
        JobsActions.AddVariationRequest({
          request,
          onSuccess: (quote: Quote) => {
            dispatcher.getJobById(request.parentJobId);
          },
        })
      );
    },

    updateJobCost: (request: JobCostUpdateRequest) => {
      dispatch(JobsActions.UpdateJobCostRequest(request));
    },
  };

  const { jobId, variationJobId } = useParams<ViewJobPathParams>();

  useEffect(() => {
    dispatcher.getJobById(jobId!);
  }, []);

  useEffect(() => {
    if (!isNull(jobData) && jobData.jobResponse.id === jobId) {
      const {
        jobResponse: {
          quote: {
            workOrderId,
            maintenanceRequestResponse: { workOrders },
          },
        },
      } = jobData;
      const { category } = getWorkOrderById(workOrders, workOrderId);
      dispatcher.getUsers(category);
    }
  }, [jobData]);

  const confirmJobBooking = (
    formValues: ConfirmJobBookingFormValues,
    id: string
  ) => {
    const { date, assignedUserId, startTime, endTime } = formValues;
    const [startHour, startMin] = [
      startTime!.get("hours"),
      startTime!.get("minutes"),
    ];
    const [endHour, endMin] = [endTime!.get("hours"), endTime!.get("minutes")];
    const dateStringified = date!.format("DD/MM/YYYY");

    const request: ConfirmJobBookingRequestPayload = {
      id,
      assignedUserId,
      keyDates: {
        startDate: `${dateStringified}T${startHour}:${startMin}`,
        expectedCompletion: `${dateStringified}T${endHour}:${endMin}`,
        timeSlot: "Morning",
      },
    };

    dispatcher.confirmJobBooking(request);
  };

  const changeStatus = (data: ChangeJobStatusRequestPayload) => {
    dispatcher.changeJobStatus(data);
  };

  const addArtefacts = (data: ArtefactsFormValues, id: string) => {
    const request: AddArtefactsRequest = {
      ...data,
      jobId: id,
    };
    dispatcher.addArtefacts(request);
  };

  return jobData && jobData.jobResponse.id === jobId && users ? (
    <Box display="flex" flexDirection="row">
      <Box flexGrow="1">
        <DashboardWrapperComponent
          navType={TopNavType.Dashboard}
          activeLink={TopNavDashboardLink.Jobs}
          userDetails={userDetails}>
          {downSm && showSidebarJob ? (
            <Box p={2}>
              <Box display="flex" alignItems="center">
                <Box
                  onClick={() => setShowSidebarJob(false)}
                  style={{ cursor: "pointer" }}>
                  <ArrowBack />
                </Box>
                <Box ml={2} display="inline-block">
                  <ContentHeadingSection hideBack heading="Job details" />
                </Box>
              </Box>
              <br />
              <JobDetailsSideBar
                job={jobData.jobResponse}
                property={jobData.property.data}
                hideButton
              />
            </Box>
          ) : (
            <ViewJobComponent
              parentJobVariationIds={
                variationJobId
                  ? jobData.jobResponse.jobVariations.map(
                      (variation) => variation.id
                    )
                  : undefined
              }
              isVariationJob={!!variationJobId}
              onVariationQuoteSubmit={dispatcher.submitJobVariationQuote}
              confirmJobBooking={confirmJobBooking}
              updateJobCost={dispatcher.updateJobCost}
              users={users}
              job={
                variationJobId
                  ? jobData.jobResponse.jobVariations.find(
                      (variation) => variation.id === variationJobId
                    )!
                  : jobData.jobResponse
              }
              property={jobData.property.data}
              changeStatus={changeStatus}
              addArtefacts={addArtefacts}
              showSidebarJob={() => {
                setShowSidebarJob(true);
                window.scrollTo(0, 0);
              }}
            />
          )}
        </DashboardWrapperComponent>
      </Box>

      {/* Sidebar */}
      <Box maxWidth="390px">
        <DetailsSidebar userDetails={userDetails}>
          <JobDetailsSideBar
            job={jobData.jobResponse}
            property={jobData.property.data}
            hideButton
          />
        </DetailsSidebar>
      </Box>
    </Box>
  ) : null;
};

export default ViewJob;
