import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../theme/Theme";

export const ConfirmRegistrationStyles = makeStyles({
  subHeading: {
    padding: "13px 0",
    fontSize: "13.6px",
    letterSpacing: "2px",
  },
  textContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    backgroundColor: color.light,
    borderRadius: "6px",
    border: "solid 1px #eeeeee",
    margin: "16px 0",
    padding: "0px 10px",
  },
  paper: {
    padding: "4px 16px 4px 0px",
    fontSize: "16px",
    marginBottom: "10px",
    letterSpacing: "0.1px",
  },
  imageContainer: {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    "@media (max-width: 480px)": {
      marginBottom: "10px",
    },
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    fontSize: "12px",
    letterSpacing: "0.88px",
    color: "#adabab",
  },
  gridLeft: {
    marginLeft: "20px",
  },
  listItem: {
    width: "100%",
    maxWidth: "inherit",
  },
  list: {
    width: "100%",
    maxWidth: "inherit",
    paddingBottom: "0px",
  },
  listTextContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  gridItem: {
    padding: ".5em 1em !important",
  },
  noPadding: {
    padding: "0px !important",
  },
  listItemPadding: {
    padding: "4px 16px",
  },
  noBusinessImageAvatar: {
    width: "60px",
    height: "60px",
  },
});
