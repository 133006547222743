import React, { FC } from "react";
import RegistrationStepsWrapper from "../registrationStepsComponents/registrationStepsWrapper/RegistrationStepsWrapper";
import { LABELS } from "./ListUsersConstants";
import { ListUsersStyle } from "./ListUsersStyle.data";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { UserData } from "../../services/tradeService/TradeServiceData.data";
import SkipText from "../skipText/SkipText";
import { roleToLabel, UserRole } from "../userDetails/UserDetailsData.data";

interface PropsFromParent {
  users: UserData[];
  onNextAndSkip: () => void;
  error: Error | null;
  onAddNew: () => void;
}

type OwnProps = PropsFromParent;

const getUserRoles = (user: UserData) => {
  const roles = user.userTradeEntitlements.map((tradeEntitlement) =>
    roleToLabel(tradeEntitlement.role as UserRole)
  );
  return `${roles.join("・")}`;
};

const getPrimaryText = (user: UserData) => `${user.firstName} ${user.lastName}`;

export const ListUsersComponent: FC<OwnProps> = (props) => {
  const { users, onNextAndSkip, onAddNew } = props;
  const classes = ListUsersStyle();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <RegistrationStepsWrapper
      heading={LABELS.heading}
      subheading={LABELS.subheading}
      primaryButton={{ text: "Next", action: onNextAndSkip }}
      noPaddingContentBox
      noPaddingButtonsSection
      footerComponent={
        <SkipText action={() => onNextAndSkip()}>
          {LABELS.skipThisAddLater}
        </SkipText>
      }>
      <List>
        {users
          ? users.map((user) => (
              <ListItem
                key={user.id}
                className={`${classes.listItem} ${classes.listItemBox}`}>
                <ListItemAvatar>
                  <Avatar src={user.logo}></Avatar>
                </ListItemAvatar>
                <div className={classes.textContainer}>
                  <ListItemText
                    primary={getPrimaryText(user)}
                    secondary={getUserRoles(user)}
                    className={matches ? classes.listItemText : ""}
                  />
                </div>
              </ListItem>
            ))
          : null}
        <ListItem
          onClick={() => {
            onAddNew();
          }}
          className={`${classes.listItem} ${classes.pointer}`}>
          <ListItemText primary="New User" />
          <div>
            <IconButton className={classes.addIcon}>
              <AddIcon />
            </IconButton>
          </div>
        </ListItem>
      </List>
    </RegistrationStepsWrapper>
  );
};
