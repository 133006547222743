import * as React from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";

export class SelectItem {
  public readonly value: any;
  public readonly label: any;

  public constructor(value: any, label: any) {
    this.value = value;
    this.label = label;
  }
}

/// <summary>
/// Value is string | SelectItem
/// </summary>
const RenderSelect = (field: any) => {
  const hasError = !!field.meta.error && !!field.meta.touched;
  const hasSecondaryError = !!field.secondaryValidationMessage;
  const highlightSecondaryError = !!field.secondaryValidationHighlight;

  return (
    <div className="item-render" style={field.itemRenderStyle}>
      <FormControl error={hasError} fullWidth>
        {field.label && (
          <InputLabel htmlFor={field.input.name}>{field.label}</InputLabel>
        )}
        <Select
          id={field.input.name}
          value={field.input.value || ""}
          onChange={(e: any) => {
            field.input.onChange(e);

            if (field.onChangeValue) {
              field.onChangeValue(e.target.value);
            }
          }}
          disabled={field.disabled}
          onBlur={field.input.onBlur}
          input={
            <Input
              name={field.input.name}
              id={`${field.input.name}_input`}
              disabled={field.disabled}      
              classes={field.overrideInputStyles}        
              inputProps={{
                placeholder: field.placeholder,
              }}
              onKeyPress={(e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          }>
          {field.data &&
            field.data.map((item: string | SelectItem, index: number) =>
              renderItem(item, index)
            )}
        </Select>
        {hasError && (
          <FormHelperText id={`${field.input.name}-error-text`}>
            {field.meta.error}
          </FormHelperText>
        )}
        {hasSecondaryError && (
          <FormHelperText
            id={`${field.input.name}-error2-text`}
            error={highlightSecondaryError}
            required={true}>
            {field.secondaryValidationMessage}
          </FormHelperText>
        )}
        {!!field.helperText && (
          <FormHelperText
            id={`${field.input.name}-helper-text`}
            error={false}
            required={false}>
            {field.helperText}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

function renderItem(item: string | SelectItem, index: number): JSX.Element {
  if (typeof item === "string") {
    return (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    );
  } else {
    return (
      <MenuItem key={index} value={item.value ? item.value : undefined}>
        {item.label}
      </MenuItem>
    );
  }
}

export default RenderSelect;
