import { JobStatus } from "../../../../models/jobs/JobData";
import { ArtefactsFormValues } from "./AddArtefactsModal";

export const LABELS = {
  addArtefacts: "Add artefacts",
  cancel: "Cancel",
  confirm: "Confirm",
  addJobImages: "Job artefacts (images/video/pdf allowed)",
  addJobImage: "Add",
  specialMaterials: "Receipts for special materials",
  addReceipt: "Add Receipt",
  invoiceMessage:
    "Your invoice will be submitted for the agency to approve (max 1 PDF allowed)",
  invoiceMessageCustomer: "Upload a copy of your invoice",
  addInvoice: "Add invoice",
  updateInvoice: "Update invoice",
  addComments: "Add comments for property manager",
  addCommentsCustomer: "Add comments",
  upload: "Upload",
  replace: "Replace",
  jobImagesMessage:
    "We recommend you upload a minimum of three (3) before and after photos/videos to show the condition on arrival and when the job is complete. However only one (1) photo is required to submit for payment.",
  jobImagesCustomer:
    "We recommend you upload a minimum of three (3) before and after photos/videos to show the condition on arrival and when the job is complete. However only one (1) photo is required to close the job.",
  attention: "Attention: ",
  attentionMessage:
    "By completing and submitting your job for payment you are confirming the job has been completed, the site has been left in a clean and safe condition, and that you have provided all relevant artefacts",
  attentionMessageCustomer:
    "By completing and submitting your job, you are confirming the job has been completed, the site has been left in a clean and safe condition, and that you have provided all relevant artefacts",
  instructions:
    "Please add all job images, receipts, warranties and relevant artefacts to the job so they can be reviewed for approval and payment.",
  instructionsPrivateWorks:
    "Please add all job images, receipts, warranties and relevant artefacts to the job.",
  documentName: "Document name",
  addDocumentNameDisabledButtonTitle:
    "Please add document name, then upload image",
  jobInvoice: "Job invoice",
  commentPlaceholder: "Add notes here",
  extractInvoiceDataLabel: "Extract invoice number and cost from invoice",
  invoiceIdRequiredError:
    "Please add invoice IDs for all newly added invoices or select extract from PDF option if there is only one invoice",
};

export const invoiceIdValidation = ({
  invoices,
  invoicesNumber,
  extractInvoiceData,
}: ArtefactsFormValues) => {
  let flag = true;
  if (
    invoices.length > 0 &&
    !extractInvoiceData &&
    invoicesNumber.length !== invoices.length
  ) {
    flag = false;
  }
  return flag;
};

export const allowExtractPdf = (status: JobStatus) =>
  [JobStatus.Scheduled, JobStatus.BookingRequired].includes(status);
