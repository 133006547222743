import { GetPaymentMethodsResponse } from "../../../../services/payment/PaymentService.data";
import BANK_ACCOUNT_LOGO from "../../../../assets/images/payment/account-balance-24-px.svg";
import MASTER_CARD_LOGO from "../../../../assets/images/payment/mc-symbol.svg";
import VISA_CARD_LOGO from "../../../../assets/images/payment/visa-credit-card.svg";

export const LABELS = {
  heading: "Billing",
  paymentMethods: "Payment Methods",
  accountName: "Account Name",
  bsbNumber: "BSB Number",
  accountNumber: "Account Number",
  cardNumber: "CardNumber",
  expiry: "Expiry",
  cvv: "CVV",
  addNew: "Add new payment method",
  deletePaymentMethod: "Delete payment method",
  deleteConfirmation: "Are you sure you want to delete this payment method?",
  cancel: "Cancel",
  delete: "Delete",
  setAsDefault: "Set as default",
  deletingDefaultPayment:
    "You are deleting your default payment method. Doing so will set another available payment method as your default. You can change this at any time.",
  defaultPaymentMethodInfo:
    "Payment for Sorted's platform fee will be automatically drawn via your nominated default credit card",
};

export enum PaymentMethodType {
  Bank = "Bank",
  Credit = "Credit",
}

export enum PaymentMethodTypeTitle {
  Bank = "Bank Account",
  Credit = "Add Credit Card",
}

export interface PaymentMethod {
  name: PaymentMethodTypeTitle;
  cardType?: string;
  logo: any;
}

export interface PaymentMethods {
  Bank: PaymentMethod;
  Credit: PaymentMethod[];
}

export const paymentMethod: PaymentMethods = {
  Bank: {
    name: PaymentMethodTypeTitle.Bank,
    logo: BANK_ACCOUNT_LOGO,
  },
  Credit: [
    {
      name: PaymentMethodTypeTitle.Credit,
      cardType: "Mastercard",
      logo: MASTER_CARD_LOGO,
    },
    {
      name: PaymentMethodTypeTitle.Credit,
      cardType: "Visa",
      logo: VISA_CARD_LOGO,
    },
  ],
};

export const getPaymentMethodSubtitle = (
  payment: GetPaymentMethodsResponse
) => {
  switch (payment.paymentMethod) {
    case PaymentMethodType.Bank:
      return `${payment.bsb} - ${payment.accountNumber}`;
    case PaymentMethodType.Credit:
      return payment.cardNumber.replace(/\./g, "*");
    default:
      return "";
  }
};

export const getPaymentMethodTitle = (payment: GetPaymentMethodsResponse) => {
  switch (payment.paymentMethod) {
    case PaymentMethodType.Bank:
      return "Bank Account";
    case PaymentMethodType.Credit:
      return "Credit Card";
    default:
      return "";
  }
};

export const getPaymentMethodLogo = (payment: GetPaymentMethodsResponse) => {
  switch (payment.paymentMethod) {
    case PaymentMethodType.Bank:
      return BANK_ACCOUNT_LOGO;
    case PaymentMethodType.Credit:
      return getCardLogo(payment.cardType);
    default:
      return "";
  }
};

const getCardLogo = (cardType: string) => {
  switch (cardType) {
    case "Mastercard":
      return MASTER_CARD_LOGO;
    case "Visa":
      return VISA_CARD_LOGO;
    default:
      return "";
  }
};
