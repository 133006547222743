import {makeStyles} from "@material-ui/core/styles";

export const InviteUserComponentStyles = makeStyles({
  container: {
    padding: '1em'
  },
  imageContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  roleContainer: {
    paddingTop: '3.7em !important'
  }
})