import { Dialog, withStyles } from "@material-ui/core";

export const StyledDialog = withStyles({
  paper: {
    margin: 0,
    minWidth: "50vw",
    minHeight: "60vh",
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    maxWidth: "800px",
    "@media all and (max-width: 768px)": {
      width: "80vw",
    },
    "@media all and (max-width: 480px)": {
      width: "100vw",
      height: "100vh",
    },
  },
  root: {
    overflow: "auto",
  },
  container: {
    padding: "2em 0",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
})(Dialog);
