import * as Yup from "yup";
import { EMAIL_PATTERN } from "../../constants/AppConstants";

export const LABELS = {
  heading: "Okay great! Now, let’s invite and register some other users",
  subheading:
    "Add more team members here. An invite will be emailed to them to sign up and complete their full profile.",
  nweUserDetails: "New user details",
  role: "Role",
  tradeEmployee: "Trade Employee",
  tradeAdmin: "Trade Admin",
  image: "Add image",
  firstName: "First name",
  lastName: "Last name",
  email: "Email",
  roles: "Roles",
  inviteSuccessful: "Invitation sent to ",
  roleErrorMessage: "One role must be selected",
};

export const ERRORS = {
  invalidEmailFormat: "Invalid email format",
  requiredMessage: (fieldName) => `${fieldName} is required`,
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required(ERRORS.requiredMessage(LABELS.firstName))
    .max(50),
  lastName: Yup.string()
    .trim()
    .required(ERRORS.requiredMessage(LABELS.lastName))
    .max(50),
  email: Yup.string()
    .required(ERRORS.requiredMessage(LABELS.email))
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
});

export const INVITE_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required(ERRORS.requiredMessage(LABELS.firstName))
    .max(50),
  lastName: Yup.string()
    .trim()
    .required(ERRORS.requiredMessage(LABELS.lastName))
    .max(50),
  email: Yup.string()
    .required(ERRORS.requiredMessage(LABELS.email))
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
  role: Yup.array().required(LABELS.roleErrorMessage).min(1),
});
