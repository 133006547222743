import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import { UpdateRequest, UpdatePasswordPayload } from "./UserData";
import { MultipartFormData } from "../MultipartFormData.data";
import { ValidateTokenPayload } from "../login/LoginData";

const serviceType = ServiceType.Users;
const serviceAuthType = ServiceType.ValidateUser;

export class UserService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);
  private readonly authService: ApiServiceBase = new ApiService(
    serviceAuthType
  );

  public putUserDetails(
    request: UpdateRequest,
    forUpdatingTeamMember = false
  ): Promise<void> | void {
    let userId = localStorage.getItem("userId")!;

    if (forUpdatingTeamMember) {
      const teamMemberDetails = JSON.parse(request.userRequest || "");
      const teamMemberId = teamMemberDetails.userTradeEntitlements[0].userId;
      // For editing a user or invited user, we need nt use logged in user details
      userId = teamMemberId;
    }

    const data: MultipartFormData[] = [];
    for (const key in request) {
      if (request.hasOwnProperty(key)) {
        data.push({
          name: key,
          content: request[key],
        });
      }
    }
    return request.token
      ? this.authService.putMultipart<void>({ route: ["invite"] }, data)
      : this.service.putMultipart<void>({ route: [userId] }, data);
  }

  public getUserDetails(): Promise<void> | void {
    const userId: string = localStorage.getItem("userId")!;
    return this.service.get<void>({ route: [userId] });
  }

  public validateTokenDetails(
    payload: ValidateTokenPayload
  ): Promise<void> | void {
    return this.authService.post<void>(
      { route: ["invite", "validation"] },
      payload.token
    );
  }

  public updatePassword(
    request: UpdatePasswordPayload
  ): Promise<void> | void {
    const userId: string = localStorage.getItem("userId")!;
    const { password } = request;
    return this.service.patch(
      { route: [userId, "update-password"] },
      password
    )
  }
}

const userService = new UserService();
export default userService;
