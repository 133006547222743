import React, { FC, useContext } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  withStyles,
  ListItemIcon,
} from "@material-ui/core";
import { Add, InsertDriveFileOutlined, Clear } from "@material-ui/icons";
import { TradeCategory } from "../../../services/tradeCategories/TradeCategories.data";
import TradeCategoryIcon from "../../tradeCategoryIcon/TradeCategoryIcon";
import {
  getFileType,
  titleCase,
  viewInNewTab,
} from "../../../helper/HelperFunctions";
import { LABELS } from "./TradeDocsCardConstants.data";
import { TradeDocument } from "../../../services/userTrades/UserTradeService.data";
import { DocType } from "../addDocModal/AddDocForm.data";
import { color } from "../../../theme/Theme";
import { TypeHelper } from "../../../helper/TypeHelper";
import { TradeDocsStyles } from "./TradeDocsCardStyles.data";
import { FileTypes } from "../../../constants/AppConstants";
import { ImageViewerContext } from "../../../App";

interface Props {
  trade: TradeCategory;
  docs: TradeDocument[];
  uploadDoc: () => void;
  userTradeCategoryId?: number;
  onRemoveTradeCategory?: (tradeId: number) => void;
  onRemoveUserTradeCategoryDocument?: (
    tradeCategoryId: number,
    documentId: number
  ) => void;
}

// File Icon for documents
const FileIcon = withStyles({
  root: {
    color: color.secondary200,
  },
})(InsertDriveFileOutlined);

// File Icon for Add document list item
const AddFileIcon = withStyles({
  root: {
    color: color.secondary400,
  },
})(InsertDriveFileOutlined);

const AddIcon = withStyles({
  root: {
    color: color.secondary400,
  },
})(Add);

const StyledPaper = withStyles({
  outlined: {
    borderRadius: "6px",
    border: `1px solid ${color.lightGrey}`,
  },
})(Paper);

// List item overrides
const ListTradeCategoryIcon = withStyles({
  root: {
    minWidth: "40px",
  },
})(ListItemIcon);

const TradeDocsCard: FC<Props> = (props) => {
  const {
    trade,
    uploadDoc,
    docs,
    onRemoveTradeCategory,
    userTradeCategoryId,
    onRemoveUserTradeCategoryDocument,
  } = props;
  const classes = TradeDocsStyles();
  const { setImages } = useContext(ImageViewerContext);
  return (
    <StyledPaper
      className={classes.tradeCategoryDocsContainer}
      variant="outlined">
      <List disablePadding>
        <ListItem
          className={`${classes.fixedListItems} ${classes.listItemDivider}`}>
          <ListTradeCategoryIcon>
            <TradeCategoryIcon tradeName={trade.name} />
          </ListTradeCategoryIcon>
          <ListItemText
            primary={
              <Typography variant="h4">
                {titleCase(trade.description)}
              </Typography>
            }
          />
          {onRemoveTradeCategory &&
          !TypeHelper.isNullOrUndefined(userTradeCategoryId) ? (
            <ListItemSecondaryAction
              onClick={() => onRemoveTradeCategory(userTradeCategoryId!)}
              className={classes.pointer}>
              <Clear />
            </ListItemSecondaryAction>
          ) : null}
        </ListItem>
        {docs &&
          docs.map((doc) => {
            const fileType = getFileType(doc.contentType);
            return (
              <ListItem
                button
                onClick={() => {
                  fileType === FileTypes.Image
                    ? setImages([
                        {
                          src: doc.link,
                          alt: doc.name,
                          downloadUrl: doc.link,
                        },
                      ])
                    : viewInNewTab(doc.link);
                }}
                classes={{ container: classes.listItem }}
                key={`${trade.id}-${doc.id}`}>
                <ListTradeCategoryIcon>
                  <FileIcon />
                </ListTradeCategoryIcon>
                <div className={classes.detailsContainer}>
                  <ListItemText
                    className={classes.documentName}
                    primary={
                      <Typography variant="caption">
                        {LABELS.document}
                      </Typography>
                    }
                    secondary={<Typography variant="h4">{doc.name}</Typography>}
                  />
                  {doc.tradeCategoryDocumentType === DocType.Tracker && (
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="caption">
                          {LABELS.expiryDate}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="h4">{doc.expiryDate}</Typography>
                      }
                    />
                  )}
                </div>
                <ListItemSecondaryAction
                  onClick={
                    onRemoveUserTradeCategoryDocument
                      ? () =>
                          onRemoveUserTradeCategoryDocument(
                            userTradeCategoryId!,
                            doc.id
                          )
                      : undefined
                  }
                  className={`${classes.pointer} ${classes.listItemSecondaryAction}`}>
                  <Clear />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        <ListItem
          className={`${classes.fixedListItems} ${classes.pointer}`}
          onClick={uploadDoc}>
          <ListTradeCategoryIcon>
            <AddFileIcon />
          </ListTradeCategoryIcon>
          <ListItemText
            secondaryTypographyProps={{ variant: "h4" }}
            secondary={LABELS.addDocuments}
          />
          <ListItemSecondaryAction
            onClick={uploadDoc}
            className={classes.pointer}>
            <AddIcon />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </StyledPaper>
  );
};

export default TradeDocsCard;
