export const LABELS = {
  confirmApproval: "Confirm Approval",
  topMessage:
    "Please confirm if you have already been given approval to complete job today, or if you will need to arrange for a new booking until variation is approved",
  commentLabelApproved: "Please confirm how approval was communicated",
  commentLabelNew:
    "Your variation will be submitted for the agency to approve. When this happens you will be able to add a new booking. If you need to leave a message for the property manager, you can add it below.",
  commentPlaceholder: "Add notes here",
  invoiceSwitchLabel:
    "Do you want to invoice both jobs together with a single invoice?",
  cancel: "Cancel",
  confirm: "Confirm",
  approvedJobVariation: "Approved Job variation",
  newVariationBooking: "New variation booking",
};
