import React, { FC, useRef } from "react";
import { useStyles } from "./FileUploadStyles";

interface FileUploadProps {
  id: string;
  dropboxHeight: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  onUpload: (files: File[] | FileList) => void;
  multiple?: boolean;
  clearOnChange?: boolean;
}

const FileUpload: FC<FileUploadProps> = ({
  id,
  dropboxHeight,
  inputRef,
  onUpload,
  multiple,
  clearOnChange,
}) => {
  const fileInputRef = useRef<HTMLInputElement>();
  const classes = useStyles();
  return (
    <>
      <div
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onUpload(e.dataTransfer.files);
        }}
        onClick={() => {
          (inputRef || fileInputRef).current!.click();
        }}
        className={classes.dropBox}
        style={dropboxHeight ? { height: `${dropboxHeight}` } : undefined}>
        Drop files here or click to browse
      </div>
      <input
        id={`${id}_input`}
        type="file"
        style={{ display: "none" }}
        multiple={multiple}
        ref={(elem) => {
          if (elem) {
            fileInputRef.current = elem;
            elem.value = "";
          }
        }}
        onChange={(e) => {
          onUpload(e.target.files!);
          if (clearOnChange) e.target.value = "";
        }}
      />
    </>
  );
};

export default FileUpload;
