import { makeStyles } from "@material-ui/core";

export const submitQuoteAttachmentSectionStyles = makeStyles({
  formHeading: {
    fontSize: "18.1px",
    fontWeight: 500,
    marginBottom: ".5em",
  },
  input: {
    display: "none",
  },
});
