import { makeStyles } from "@material-ui/core/styles";
const SIDEBAR_MIN_WIDTH = "60px";
const SIDEBAR_MAX_WIDTH = "165px";

export const DashboardWrapperComponentStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    flexWrap: "nowrap",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "auto",
    },
  },
  sidebarContainer: {
    width: SIDEBAR_MAX_WIDTH,
    [theme.breakpoints.down("md")]: {
      width: SIDEBAR_MIN_WIDTH,
      minWidth: SIDEBAR_MIN_WIDTH,
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  },
  detailsSidebarContainer: {
    flexGrow: 1,
    boxShadow: "-1px 0px 4px 0px rgba(0,0,0,0.2)",
  },
  bodyWrapper: {
    paddingTop: "2.25em",
    height: "100%",
    overflow: "auto",

    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.2)",
      outline: "1px solid slategrey",
      borderRadius: "4px",
      cursor: "pointer",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0,0,0,.3)",
    },

    [theme.breakpoints.down("xs")]: {
      paddingTop: "0",
    },
  },
  pointer: {
    cursor: "pointer",
  },
}));
