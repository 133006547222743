import React, { FC, useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { isLoggedIn, onboardingStage } from "../../helper/Auth";
import LoginImpersonate from "../../component/loginImpersonate/LoginImpersonate";
import { routes } from "../../Routes";
import {
  LoginImpersonatePayload,
  LoginPayload,
  LoginResponse,
} from "../../services/login/LoginData";
import { useDispatch, useSelector } from "react-redux";
import { LoginImpersonateActions } from "../../store/actions/LoginImpersonationAction";
import { ApplicationState } from "src/store/RootReducers";
import { errorMessages } from "../../constants/AppConstants";
import { OnboardingStage } from "../../helper/OnboardingStages";
import { onboardingRouteMapper } from "../../helper/HelperFunctions";

interface PropsFromState {
  loginDetails?: LoginResponse;
  loginError: any;
}

interface LoginImpersonateContainerProps
  extends RouteComponentProps<any, any, Record<string, any>>,
    PropsFromState {
  postLogin: (data: LoginPayload) => void;
}

const LoginImpersonateContainer: FC<LoginImpersonateContainerProps> = ({
  location,
  history,
}) => {
  // State
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyError, setVerifyError] = useState("");

  let fromPage = routes.dashboard.view;
  if (location.state) {
    const { from } = location.state as any;
    fromPage = from.pathname;
  }

  const userEmail = location.state && location.state.userEmail;

  const dispatch = useDispatch();
  const dispatcher = {
    postLogin: (data: LoginImpersonatePayload) => {
      dispatch(LoginImpersonateActions.postLoginStart(data));
    },
  };

  const { loginError, loginDetails } = useSelector<
    ApplicationState,
    PropsFromState
  >((state: ApplicationState) => ({
    loginError: state.login.error,
    loginDetails: state.login.loginDetails,
  }));

  useEffect(() => {
    if (loginDetails && loginDetails.authToken) {
      const {
        authToken,
        refreshToken,
        firstName,
        lastName,
        email,
        id,
        adminOnBoardingStage,
        userTradeEntitlements,
      } = loginDetails!;

      localStorage.setItem("authToken", authToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("lastName", lastName);
      localStorage.setItem("email", email);
      localStorage.setItem("userId", id.toString());
      localStorage.setItem("onboardingStage", adminOnBoardingStage);
      if (
        userTradeEntitlements &&
        userTradeEntitlements.length > 0 &&
        userTradeEntitlements[0].tradeId
      ) {
        localStorage.setItem(
          "tradeId",
          userTradeEntitlements[0].tradeId.toString()
        );
        localStorage.setItem("role", userTradeEntitlements[0].role);
        if (userTradeEntitlements[0].officeId) {
          localStorage.setItem(
            "firstOfficeId",
            userTradeEntitlements[0].officeId!.toString()
          );
        }
      }
    }
  }, [loginDetails]);

  useEffect(() => {
    if (loginError && loginError.response) {
      if (
        loginError.response.status === 401 ||
        loginError.response.status === 403
      ) {
        setErrorMessage(errorMessages.loginPage.incorrectCredentials);
      } else if (loginError.response.status === 400) {
        setVerifyError(errorMessages.loginPage.emailVerificationFailed);
      }
    }
  }, [loginError]);

  return isLoggedIn() ? (
    onboardingStage() === OnboardingStage.Completed ||
    onboardingStage() === "null" ? (
      <Redirect to={fromPage} />
    ) : (
      <Redirect to={onboardingRouteMapper(onboardingStage() as string)} />
    )
  ) : (
    <LoginImpersonate
      history={history}
      postLogin={dispatcher.postLogin}
      errorMessage={errorMessage}
      verifyError={verifyError}
      userEmail={userEmail}
    />
  );
};

export default LoginImpersonateContainer;
