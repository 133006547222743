import {
  AddBranchAction,
  AddBranchActionType,
  AddBranchErrorPayload,
  GetBranchAction,
  GetBranchActionType,
  UpdateBranchActionType,
  UpdateBranchAction,
} from "../actions/BranchActions";
import { RegisterBusinessState } from "../state/RegisterBusinessState";
import { Office as AddBranchSuccessPayload } from "../../container/registrationForm/AddBranch.data";
import { GetBranchState } from "../state/BranchState";
import {
  GetBranchSuccessPayload,
  GetBranchErrorPayload,
  ServiceableRegion,
  BranchResponse,
} from "src/services/branch/Branch.data";
import {
  ServiceableRegionsActionType,
  ServiceableRegionsAction,
} from "../actions/ServiceableRegionsActions";
import { cloneDeep } from "lodash";
import { DeleteServiceableRegionRequestPayload } from "../../services/serviceableRegions/serviceableRegionsServiceData.data";

const initialState: RegisterBusinessState = {
  loading: false,
  success: false,
  error: "",
  businessDetails: null,
};

export const addBranchReducer = (
  state = initialState,
  action: AddBranchAction
) => {
  switch (action.type) {
    case AddBranchActionType.AddBranchRequest: {
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    }

    case AddBranchActionType.AddBranchSuccess: {
      const businessDetails = action.data as AddBranchSuccessPayload;
      return {
        ...state,
        businessDetails,
        loading: false,
        success: true,
        error: null,
      };
    }

    case AddBranchActionType.AddBranchError: {
      const data = action.data as AddBranchErrorPayload;
      return {
        ...state,
        loading: false,
        success: false,
        error: data ? data.error : null,
      };
    }

    default:
      return state;
  }
};

const initialBranchState: GetBranchState = {
  loading: false,
  success: false,
  error: "",
  branchDetails: undefined,
};

export const getBranchReducer = (
  state = initialBranchState,
  action: GetBranchAction | ServiceableRegionsAction | UpdateBranchAction
) => {
  switch (action.type) {
    case GetBranchActionType.GetBranchRequest: {
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    }

    case GetBranchActionType.GetBranchSuccess: {
      const branchDetails = action.data as GetBranchSuccessPayload;
      return {
        ...state,
        branchDetails,
        loading: false,
        success: true,
        error: null,
      };
    }

    case GetBranchActionType.GetBranchError: {
      const data = action.data as GetBranchErrorPayload;
      return {
        ...state,
        loading: false,
        success: false,
        error: data ? data.error : null,
      };
    }

    case ServiceableRegionsActionType.DELETE_SERVICEABLE_REGION_SUCCESS: {
      let { serviceableRegionId } =
        action.data as DeleteServiceableRegionRequestPayload;
      let branchDetails = cloneDeep(state.branchDetails);
      branchDetails!.serviceableRegions =
        branchDetails!.serviceableRegions!.filter(
          (region) => region.id !== serviceableRegionId
        );
      return {
        ...state,
        branchDetails,
      };
    }

    case UpdateBranchActionType.AddBranchServiceableRegion: {
      let regions = action.data as ServiceableRegion[];
      let newBranchDetails = cloneDeep(state.branchDetails);
      newBranchDetails!.serviceableRegions!.push(...regions);
      return {
        ...state,
        branchDetails: newBranchDetails,
      };
    }

    case UpdateBranchActionType.UpdateBranchDetailsSuccess: {
      let newBranchDetails = cloneDeep(action.data as BranchResponse);
      newBranchDetails.serviceableRegions =
        state.branchDetails!.serviceableRegions;
      return {
        ...state,
        branchDetails: newBranchDetails,
      };
    }

    case UpdateBranchActionType.UpdateBranchDetailsError: {
      return {
        ...state,
        error: action.data,
      };
    }

    default:
      return state;
  }
};
