import { UpdateTeamMemberAction, UpdateTeamMemberActionTypes } from "../actions/UpdateTeamMembersAction";
import { UpdateTeamMemberState } from "../state/UpdateTeamMemberState";

export const initialState: UpdateTeamMemberState = {
  error: "",
  loading: false,
  updateTeamMemberSuccessful: false
};

/* tslint:disable:cyclomatic-complexity */
export const updateTeamMemberReducer = (
  state = initialState,
  action: UpdateTeamMemberAction
): UpdateTeamMemberState => {
  switch (action.type) {
    case UpdateTeamMemberActionTypes.UPDATE_TEAM_MEMBER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UpdateTeamMemberActionTypes.UPDATE_TEAM_MEMBER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateTeamMemberSuccessful: true
        // tslint:disable-next-line: no-object-literal-type-assertion       
      };
    case UpdateTeamMemberActionTypes.UPDATE_TEAM_MEMBER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case UpdateTeamMemberActionTypes.UPDATE_TEAM_MEMBER_DETAILS_RESET:
        return {
          ...state,
          updateTeamMemberSuccessful: false
        };
    default:
      return state;
  }
}

