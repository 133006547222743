import React, { FC } from "react";
import { useAppSelector } from "../../store/RootReducers";
import "./LoadingIndicator.css";
import * as loaderImage from "../../assets/sorted-loader-image.png";
import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
  overlay: {
    background: "rgba(0, 0, 0, 0.3)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    zIndex: 99999,
    padding: "1em",
  },
});

interface LoadingIndicatorProps {
  forceShow?: boolean;
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({ forceShow }) => {
  const classes = styles();
  const isLoading = useAppSelector((state) => state.loadingIndicator.isLoading);

  return (
    <div>
      {forceShow || isLoading ? (
        <div className={classes.overlay}>
          <div className={"loaderContainer"}>
            <img
              alt="loaderImage"
              className="loadingIndicator"
              src={loaderImage}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default LoadingIndicator;
