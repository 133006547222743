import { makeStyles } from "@material-ui/core";

export const ConfirmCancelJobDialogStyles = makeStyles({
  textField: {
    fontSize: "16px",
    fontWeight: 300,
  },
  dialogPaper: {
    width: "80vw",
    maxWidth: "600px",
    padding: "1em",
  },
  buttonsContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",

    "& > * + *": {
      marginLeft: "1em",
    },
  },
  attentionMessageContainer: {
    padding: ".7rem",
    background: "rgba(255, 82, 91, 0.1)",
    marginTop: "1em",
  },
});
