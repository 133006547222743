import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { SkipTextStyles } from "./SkipTextStyles.data";

interface Props {
  action: () => void;
  children: string;
}

const SkipText: FC<Props> = (props) => {
  const { children, action } = props;
  const classes = SkipTextStyles();
  return (
    <div onClick={action}>
      <Typography variant="body2" className={classes.skipText}>
        {children}
      </Typography>
    </div>
  );
};

export default SkipText;
