import React, { FC } from "react";
import { ListItem, Typography } from "@material-ui/core";
import { detailListItemStyles } from "./DetailListItemStyles.data";

interface Props {
  primary: string;
  secondary: string;
}

const DetailListItem: FC<Props> = (props) => {
  const { primary, secondary } = props;
  const classes = detailListItemStyles();
  return (
    <ListItem className={classes.listItem}>
      <Typography className={classes.label} variant="h4">
        {primary}
      </Typography>
      <Typography
        className={classes.label}
        color="textSecondary"
        variant="caption">
        {secondary}
      </Typography>
    </ListItem>
  );
};

export default DetailListItem;
