import { RegistrationFormData } from "../../component/registrationForm/RegistrationFormData.data";
import {
  RegisterBusinessRequest,
  RegisterBusinessRequestData,
  OfficeDetails as AddBranchRequest,
} from "../../services/registerBusiness/RegistrationFormRequest.data";

export const ConvertFormDataToRequest = (
  data: RegistrationFormData
): RegisterBusinessRequest => {
  const office = {
    name: data.branchName,
    email: data.branchEmail,
    phone: data.branchPhone,
    primary: false,
    serviceableRegions: [],
    covidApproved: null,
  };
  const { branchAddress } = data;
  for (const key in branchAddress!) {
    if (branchAddress!.hasOwnProperty(key)) {
      office[key] = branchAddress[key];
    }
  }

  if (data.primaryAddress) {
    office.primary = true;
  }

  const requestData: RegisterBusinessRequestData = {
    name: data.businessName,
    tradeTypeId: data.businessType,
    logo: null,
    abn: data.abn,
    offices: [office],
  };

  return {
    file: data.logo,
    tradeRequest: JSON.stringify(requestData),
  };
};

export const ConvertFormDataToAddBranchRequest = (
  data: RegistrationFormData,
  tradeId?: number
): AddBranchRequest => {
  const office = {
    name: data.branchName,
    email: data.branchEmail,
    phone: data.branchPhone,
    primary: false,
    tradeId: tradeId,
    serviceableRegions: [],
    covidApproved: null,
  };
  const { branchAddress } = data;
  for (const key in branchAddress!) {
    if (branchAddress!.hasOwnProperty(key)) {
      office[key] = branchAddress[key];
    }
  }

  if (data.primaryAddress) {
    office.primary = true;
  }
  return office;
};
