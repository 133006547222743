import { ApiServiceMockBase } from "./ApiServiceMockBase";
import { QueryPath } from "../QueryPath.data";
import { TypeHelper } from "../../helper/TypeHelper";
import { Logging } from "../../helper/Logging";
import { User } from "../../models/user/User.data";
import { OnboardingStage } from "../../helper/OnboardingStages";

export const userTestData = {
  allUsers: createTestData(),
  getById: (userId: number) =>
    userTestData.allUsers.find((t) => t.id === userId),
  resetTestData: () => (userTestData.allUsers = createTestData()),
};

function createTestData(): User[] {
  return [
    {
      id: 1,
      firstName: "Test",
      lastName: "Agent 1",
      mobileNumber: "0423 236 923",
      email: "agent1@gmail.com",
      logo: null,
      username: "agent1@gmail.com",
      userDocuments: [],
      userTradeEntitlements: [],
      adminOnBoardingStage: OnboardingStage.Completed,
    },
    {
      id: 2,
      firstName: "Test",
      lastName: "Agent 2",
      mobileNumber: "0423 236 923",
      email: "agent2@gmail.com",
      logo: null,
      username: "agent2@gmail.com",
      userDocuments: [],
      userTradeEntitlements: [],
      adminOnBoardingStage: OnboardingStage.Completed,
    },
    {
      id: 3,
      firstName: "Test",
      lastName: "Agent 3",
      mobileNumber: "0423 236 923",
      email: "agent3@gmail.com",
      logo: null,
      username: "agent3@gmail.com",
      userDocuments: [],
      userTradeEntitlements: [],
      adminOnBoardingStage: OnboardingStage.Completed,
    },
    {
      id: 4,
      firstName: "Test",
      lastName: "Landlord 1",
      mobileNumber: "0423 236 923",
      email: "landlord1@gmail.com",
      logo: null,
      username: "landlord1@gmail.com",
      userDocuments: [],
      userTradeEntitlements: [],
      adminOnBoardingStage: OnboardingStage.Completed,
    },
    {
      id: 5,
      firstName: "Test",
      lastName: "Landlord 2",
      mobileNumber: "0423 236 923",
      email: "landlord2@gmail.com",
      logo: null,
      username: "landlord2@gmail.com",
      userDocuments: [],
      userTradeEntitlements: [],
      adminOnBoardingStage: OnboardingStage.Completed,
    },
    {
      id: 6,
      firstName: "Test",
      lastName: "Landlord 3",
      mobileNumber: "0423 236 923",
      email: "landlord3@gmail.com",
      logo: null,
      username: "landlord3@gmail.com",
      userDocuments: [],
      userTradeEntitlements: [],
      adminOnBoardingStage: OnboardingStage.Completed,
    },
    {
      id: 7,
      firstName: "Test",
      lastName: "Tenant 1",
      mobileNumber: "0423 236 923",
      email: "tenant1@gmail.com",
      logo: null,
      username: "tenant1@gmail.com",
      userDocuments: [],
      userTradeEntitlements: [],
      adminOnBoardingStage: OnboardingStage.Completed,
    },
    {
      id: 8,
      firstName: "Test",
      lastName: "Tenant 2",
      mobileNumber: "0423 236 923",
      email: "tenant2@gmail.com",
      logo: null,
      username: "tenant2@gmail.com",
      userDocuments: [],
      userTradeEntitlements: [],
      adminOnBoardingStage: OnboardingStage.Completed,
    },
    {
      id: 9,
      firstName: "Test",
      lastName: "Tenant 3",
      mobileNumber: "0423 236 923",
      email: "tenant3@gmail.com",
      logo: null,
      username: "tenant3@gmail.com",
      userDocuments: [],
      userTradeEntitlements: [],
      adminOnBoardingStage: OnboardingStage.Completed,
    },
  ];
}

export class UserApiServiceMock extends ApiServiceMockBase {
  public get<T = void>(path: QueryPath): T {
    Logging.debug("UserApiServiceMock.get", {
      route: path.route,
      query: path.query,
    });

    const query = path.query || {};
    const route = path.route || [];
    const userId =
      route.length > 0 ? TypeHelper.tryParseInt(route[0], 0) : null;

    if (route.length > 1 || (userId !== null && userId <= 0)) {
      throw Error(
        `UserApiServiceMock.get: Invalid route Error @path: ${JSON.stringify(
          path
        )}`
      );
    }

    let users = userTestData.allUsers;

    // userId?
    if (userId) {
      users = users.filter((t) => t.id === userId);
    }

    Logging.debug(`UserApiServiceMock.get Result ${users.length} users`, {
      route,
      query,
    });

    return (TypeHelper.arrayClone(users) as any) as T; // return clone, don't allow testData to be modified by callers
  }
}
