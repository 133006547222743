import {
  JobsAction,
  JobsActions,
  JobsActionType,
} from "../actions/JobsActions";
import { put, takeEvery } from "redux-saga/effects";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import {
  GetJobsResponse,
  GetJobsRequest,
  GetJobByIdRequestPayload,
  GetJobByIdResponse,
  GetUsersByWorkorderTradeCategoryRequest,
  ConfirmJobBookingRequestPayload,
  ChangeJobStatusRequestPayload,
  AddArtefactsRequest,
  JobArtefactFiles,
  AddVariationRequestActionPayload,
  JobCostUpdateRequest,
} from "../../services/jobs/JobsData.data";
import JobsService from "../../services/jobs/JobsService";
import { User } from "../../models/user/User.data";
import { Quote } from "../../models/quote/Quote.data";

export function* getJobsWorker(action: JobsAction): IterableIterator<any> {
  const data = action.data as GetJobsRequest;
  const { hideLoader, ...payload } = data;
  try {
    if (!hideLoader) {
      yield put(LoadingIndicatorActions.show());
    }
    const response: GetJobsResponse = (yield JobsService.post(payload))!;
    yield put(JobsActions.GetJobsSuccess(response));
  } catch (error) {
    yield put(JobsActions.GetJobsError(error));
  } finally {
    if (!hideLoader) {
      yield put(LoadingIndicatorActions.hide());
    }
  }
}

export function* getJobUsingIdWorker(
  action: JobsAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data;
    const response: GetJobByIdResponse = (yield JobsService.getUsingId(
      data as GetJobByIdRequestPayload
    ))!;
    yield put(JobsActions.GetJobUsingIdSuccess(response));
  } catch (error) {
    yield put(JobsActions.GetJobUsingIdError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getUsersForWorkorderWorker(
  action: JobsAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data;
    const response: User[] =
      (yield JobsService.getUsersByWorkorderTradeCategory(
        data as GetUsersByWorkorderTradeCategoryRequest
      ))!;
    yield put(JobsActions.GetUsersForWorkorderSuccess(response));
  } catch (error) {
    yield put(JobsActions.GetUsersForWorkorderError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* confirmJobBookingWorker(
  action: JobsAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as ConfirmJobBookingRequestPayload;
    yield JobsService.confirmJobBooking(data)!;
    yield put(JobsActions.ConfirmJobBookingSuccess(data));
  } catch (error) {
    yield put(JobsActions.ConfirmJobBookingError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* changeJobStatusWorker(
  action: JobsAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as ChangeJobStatusRequestPayload;
    yield JobsService.changeJobStatus(data)!;
    yield put(JobsActions.ChangeJobStatusSuccess(data));
  } catch (error) {
    yield put(JobsActions.ChangeJobStatusError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* addVariationWorker(action: JobsAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const { request, onSuccess } =
      action.data as AddVariationRequestActionPayload;

    const response: Quote = yield JobsService.addJobVariance(request);

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    yield put(JobsActions.AddVariationError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* addArtefactsJobWorker(
  action: JobsAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as AddArtefactsRequest;
    const response: JobArtefactFiles = (yield JobsService.addArtefacts(data))!;
    yield put(JobsActions.AddArtefactsSuccess({ ...response, id: data.jobId }));

    // updating cost details for extract pdf cases
    if (data.extractInvoiceData) {
      yield put(JobsActions.GetJobUsingId({ jobId: data.jobId }));
    }
  } catch (error) {
    yield put(JobsActions.AddArtefactsError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

// Update job cost
export function* updateJobCostWorker(action: JobsAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as JobCostUpdateRequest;

    yield JobsService.updateJobCost(data);
    yield put(JobsActions.UpdateJobCostSuccess(data));
  } catch (error) {
    yield put(JobsActions.UpdateJobCostError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const JobsWatcher = [
  takeEvery(JobsActionType.GetJobsRequest, (action) =>
    getJobsWorker({
      type: action.type,
      data: (action as JobsAction).data,
    })
  ),
  takeEvery(JobsActionType.GetJobByIdRequest, (action) =>
    getJobUsingIdWorker({
      type: action.type,
      data: (action as JobsAction).data,
    })
  ),
  takeEvery(JobsActionType.GetJobUsersForWorkorderRequest, (action) =>
    getUsersForWorkorderWorker({
      type: action.type,
      data: (action as JobsAction).data,
    })
  ),
  takeEvery(JobsActionType.ConfirmJobBookingRequest, (action) =>
    confirmJobBookingWorker({
      type: action.type,
      data: (action as JobsAction).data,
    })
  ),
  takeEvery(JobsActionType.ChangeJobStatusRequest, (action) =>
    changeJobStatusWorker({
      type: action.type,
      data: (action as JobsAction).data,
    })
  ),
  takeEvery(JobsActionType.AddArtefactsRequest, (action) =>
    addArtefactsJobWorker({
      type: action.type,
      data: (action as JobsAction).data,
    })
  ),
  takeEvery(JobsActionType.AddJobVariationRequest, (action) =>
    addVariationWorker({
      type: action.type,
      data: (action as JobsAction).data,
    })
  ),
  takeEvery(JobsActionType.UpdateJobCostRequest, (action) =>
    updateJobCostWorker({
      type: action.type,
      data: (action as JobsAction).data,
    })
  ),
];

export default JobsWatcher;
