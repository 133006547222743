import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import {GetTradeCategoriesResponse} from "./TradeCategories.data";

const serviceType = ServiceType.TradeCategories;

export class TradeCategoriesService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public getTradeCategories(): Promise<GetTradeCategoriesResponse> | GetTradeCategoriesResponse {
    return this.service.get({});
  }
}

export default new TradeCategoriesService();
 
