import * as Yup from "yup";

export const LABELS = {
  image: "Image",
  businessName: "Business Name",
  businessType: "Business Type",
};

export const formFields = {
  image: "logoKey",
  name: "name",
  type: "tradeTypeId",
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().trim().required("Business name is required").max(50),
});
