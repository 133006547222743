import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import {
  AgencyBranchTermsRequestPayload,
  PostTermsAcceptanceRequest,
} from "./AgencyServiceData.data";

const TENANCY_BRANCH_TERMS = "branch-trade-terms";

const tenancyServiceType = ServiceType.Tenancy;
const termsServiceType = ServiceType.Terms;

export class AgenciesService {
  private readonly tenancyService: ApiServiceBase = new ApiService(
    tenancyServiceType
  );

  private readonly termsService: ApiServiceBase = new ApiService(
    termsServiceType
  );

  public getAgencybranchTerms(data: AgencyBranchTermsRequestPayload) {
    return this.tenancyService.get({
      route: [TENANCY_BRANCH_TERMS],
      query: { code: data.branchCode },
    });
  }

  public postTermsAcceptance(payload: PostTermsAcceptanceRequest) {
    return this.termsService.post({}, payload);
  }
}

export default new AgenciesService();
