import React, { FC, useState, useEffect, createContext } from "react";
import Agents from "../../component/dashboard/team/agents/Agents";
import { useDispatch } from "react-redux";
import { AgencyActions } from "../../store/actions/AgencyActions";
import {
  AgencyBranchTermsRequestPayload,
  PostTermsAcceptanceActionPayload,
} from "../../services/agencies/AgencyServiceData.data";
import { AgencyBranchTermsResponse } from "../../models/agency/AgencyBranchTermsResponse";
import { ListBranchesActions } from "../../store/actions/ListBranchesActions";
import { getTradeId } from "../../helper/Auth";
import { useAppSelector } from "../../store/RootReducers";
import { Branch } from "../listBranches/ListBranches.data";
import { TradeActions } from "../../store/actions/TradeActions";
import { TradeAgency } from "../../models/trade/TradeAgency.data";
import { GetUserResponse } from "../../services/login/LoginData";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import DashboardWrapperComponent from "../../component/dashboard/DashboardWrapperComponent";
import { TopNavType } from "../../component/topNav/TopNav.data";
import { TopNavTeamLink } from "../../component/topNav/TopNavTeamLink.data";
import ContentHeadingSection from "../../component/dashboard/contentHeadingSection/ContentHeadingSection";
import { LABELS } from "./AgentsContainerConstants";
import DetailsSidebar from "../../component/detailsSidebar/DetailsSideBar";
import AgencyBranchSidebar from "../../component/dashboard/terms/agencyBranchSidebar/AgencyBranchDetailsSidebar";
import {
  GetTradeAgenciesResponse,
  PatchTermsResponseRequest,
} from "../../services/tradeService/TradeServiceData.data";
import { AgentFilter } from "../../component/dashboard/team/agents/AgentsConstants.data";

interface AgentsProps {
  userDetails: GetUserResponse;
}

// Context value interface
export interface TermsAndConditionsContextValue {
  offices: Branch[];
  selectedAgencies: AgencyBranchTermsResponse[];
  setSelectedAgencies: any;
  onGetAgencyBranch: any;
  onAcceptOrDecline: any;
  tradeAgencies: TradeAgency[];
  termsDialogOpen: boolean;
  setTermsDialogOpen: any;
  agencyBranchTerms: AgencyBranchTermsResponse[];
  onUpdateTermsResponse: any;
  // To be used only for pagination details and not for accessing 'content' key
  paginationData: GetTradeAgenciesResponse | null;
  acceptTermsError?: Error;
}

export const TermsAndConditionsContext =
  createContext<TermsAndConditionsContextValue>({
    offices: [],
    selectedAgencies: [],
    setSelectedAgencies: null,
    onGetAgencyBranch: null,
    onAcceptOrDecline: null,
    tradeAgencies: [],
    termsDialogOpen: false,
    setTermsDialogOpen: null,
    agencyBranchTerms: [],
    onUpdateTermsResponse: null,
    paginationData: null,
  });

const AgentsContainer: FC<AgentsProps> = ({ userDetails }) => {
  const dispatch = useDispatch();

  // fetched agency branch terms and details
  const { agencyBranchTerms } = useAppSelector((state) => ({
    agencyBranchTerms: state.agencyBranchTerms.terms,
  }));

  // Agencies selected for getting T&C
  const [selectedAgencies, setSelectedAgencies] = useState<
    AgencyBranchTermsResponse[]
  >([]);

  // Agency to show in sidebar
  const [sidebarAgency, setSidebarAgency] = useState<TradeAgency | null>(null);

  // Terms and conditions modal
  const [termsDialogOpen, setTermsDialogOpen] = useState<boolean>(false);

  // pagination and filters
  const [activeAgentFilter, setActiveAgentFilter] = useState<AgentFilter>(
    AgentFilter.all
  );
  const [pageNumber, setPageNumber] = useState<number>(0);

  // useEffect
  useEffect(() => {
    dispatcher.getTradeAgencies({
      pageNumber,
      agentFilters:
        activeAgentFilter === AgentFilter.all ? undefined : [activeAgentFilter],
    });
  }, [activeAgentFilter, pageNumber]);

  const dispatcher = {
    getBranchTerms: (payload: AgencyBranchTermsRequestPayload) =>
      dispatch(AgencyActions.getAgencyBranchTerms(payload)),

    getTradeDetails: () =>
      dispatch(
        ListBranchesActions.listBranchesRequest({
          request: { tradeId: getTradeId()! },
        })
      ),

    getTradeAgencies: ({
      pageNumber,
      agentFilters,
    }: {
      pageNumber: number;
      agentFilters?: Array<AgentFilter>;
    }) => dispatch(TradeActions.GetTradeAgencies(pageNumber, agentFilters)),

    respondToAgencyTerms: (payload: PostTermsAcceptanceActionPayload) =>
      dispatch(AgencyActions.acceptAgencyBranchTermsRequest(payload)),

    updateTermsResponse: (payload: PatchTermsResponseRequest) =>
      dispatch(TradeActions.updateTermsResponseRequest(payload)),
  };

  useEffect(() => {
    dispatcher.getTradeDetails();
    dispatcher.getTradeAgencies({ pageNumber: 0 });
  }, []);

  const { trade, tradeAgencies, paginationData, acceptTermsError } =
    useAppSelector((state) => ({
      tradeAgencies:
        (state.trade.agencies && state.trade.agencies.content) || [],
      trade: state.listBranch.trade,
      paginationData: state.trade.agencies,
      acceptTermsError: state.agencyBranchTerms.error,
    }));

  const onAcceptOrDecline = (
    accepted: boolean,
    termsNotificationRecipients: number[]
  ) => {
    const {
      branch: { id: agencyBranchId },
      link: termsURL,
      version,
    } = selectedAgencies[0];

    const actionPayload: PostTermsAcceptanceActionPayload = {
      request: {
        // tslint:disable-next-line: radix
        tradeId: parseInt(getTradeId()!),
        accepted,
        version,
        agencyBranchId,
        termsURL,
        termsNotificationRecipients,
      },
      onSuccess: () => {
        const agenciesList = selectedAgencies;
        agenciesList.shift();
        setSelectedAgencies(agenciesList);
        dispatcher.getTradeAgencies({
          pageNumber,
          agentFilters:
            activeAgentFilter === AgentFilter.all
              ? undefined
              : [activeAgentFilter],
        });
      },
    };

    dispatcher.respondToAgencyTerms(actionPayload);
  };

  const onUpdateTermsResponse = (payload: PatchTermsResponseRequest) => {
    dispatcher.updateTermsResponse(payload);
    setSelectedAgencies([]);
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  return trade && tradeAgencies !== null ? (
    <TermsAndConditionsContext.Provider
      value={{
        offices: trade.offices,
        selectedAgencies,
        setSelectedAgencies,
        onGetAgencyBranch: dispatcher.getBranchTerms,
        onUpdateTermsResponse,
        onAcceptOrDecline,
        tradeAgencies,
        termsDialogOpen,
        setTermsDialogOpen,
        agencyBranchTerms,
        paginationData,
        acceptTermsError,
      }}>
      <Box display="flex" flexDirection="row">
        <Box flexGrow="1">
          <DashboardWrapperComponent
            navType={TopNavType.Team}
            activeLink={TopNavTeamLink.Agents}
            userDetails={userDetails}>
            {downSm && sidebarAgency ? (
              <Box p={2}>
                <Box display="flex" alignItems="center">
                  <Box ml={2} display="inline-block">
                    <ContentHeadingSection
                      onBack={() => setSidebarAgency(null)}
                      heading={LABELS.agencyDetails}
                    />
                  </Box>
                </Box>
                <AgencyBranchSidebar agency={sidebarAgency} />
              </Box>
            ) : (
              <>
                <ContentHeadingSection
                  heading={LABELS.yourTeam}
                  hideOnSmallScreens
                />
                <Agents
                  paginationData={paginationData}
                  activeFilter={activeAgentFilter}
                  setActiveFilter={setActiveAgentFilter}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  getAgencies={dispatcher.getTradeAgencies}
                  setSidebarAgency={setSidebarAgency}
                />
              </>
            )}
          </DashboardWrapperComponent>
        </Box>

        {/* Details side bar  */}
        {!downSm ? (
          <Box maxWidth="390px">
            <DetailsSidebar userDetails={userDetails}>
              {sidebarAgency && <AgencyBranchSidebar agency={sidebarAgency} />}
            </DetailsSidebar>
          </Box>
        ) : null}
      </Box>
    </TermsAndConditionsContext.Provider>
  ) : null;
};
export default AgentsContainer;
