import React, { FC } from "react";
import RegistrationStepsWrapper from "../registrationStepsComponents/registrationStepsWrapper/RegistrationStepsWrapper";
import { LABELS } from "./ConfirmRegistrationConstants";
import { Trade } from "../../container/confirmRegistration/ConfirmRegistration.data";
import { GetTradeUsersResponse } from "../../services/tradeUsers/TradeUsers.data";
import {
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  Typography,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  styled,
} from "@material-ui/core";
import { UserTradeEntitlement } from "src/services/signUp/SignUpRequest.data";
import {
  getBusinessType,
  getAddress,
  getRole,
} from "../../helper/TradesHelper";
import { TypeHelper } from "../../helper/TypeHelper";
import {
  GetBranchSuccessPayload,
  ServiceableRegion,
} from "src/services/branch/Branch.data";
import { color } from "../../theme/Theme";
import { HomeOutlined, LocationOnOutlined } from "@material-ui/icons";
import { get } from "lodash";
import { ConfirmRegistrationStyles } from "./ConfirmRegistrationStyle.data";

interface PropsFromParent {
  trade: Trade | undefined | null;
  tradeUsers: GetTradeUsersResponse | undefined;
  primaryBranch: GetBranchSuccessPayload | undefined;
  errorMessage: string | null;
  verifyError: string | null;
  onConfirm: () => void;
}

type OwnProps = PropsFromParent;

const StyledAvatar = styled(Avatar)({
  backgroundColor: color.light,
  color: color.dark1000,
});

const StyledListItemAvatar = styled(ListItemAvatar)({
  minWidth: "40px",
});

const getUserTradeEntitlements = (entitlement: UserTradeEntitlement[]) => {
  return entitlement.map((e) => getRole(e.role)).join(" • ");
};

// tslint:disable-next-line: cyclomatic-complexity
export const ConfirmRegistrationComponent: FC<OwnProps> = (props) => {
  const { trade, tradeUsers, primaryBranch, onConfirm } = props;
  const classes = ConfirmRegistrationStyles();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <RegistrationStepsWrapper
      heading={LABELS.heading}
      subheading={LABELS.subHeading}
      primaryButton={{ text: "Confirm", action: onConfirm }}
      noPaddingContentBox
      greyBackground>
      <Grid
        container
        spacing={isSmall ? 0 : 4}
        className={classes.textContainer}>
        <Grid item xs={12}>
          <Typography className={classes.subHeading} variant="h3">
            {LABELS.officeProfileSubHeading}
          </Typography>
        </Grid>
        <Grid
          className={`${classes.centered} ${classes.noPadding}`}
          item
          xs={12}
          sm={3}
          md={2}
          lg={1}>
          {trade && trade.logoKey ? (
            <img src={trade.logoKey} className={classes.imageContainer} />
          ) : (
            <Avatar classes={{ root: classes.noBusinessImageAvatar }}>
              <HomeOutlined fontSize={"large"} />
            </Avatar>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          md={10}
          lg={11}
          className={!isSmall ? classes.gridItem : ""}>
          <div>
            <Grid container spacing={isSmall ? 1 : 2}>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.inputLabel}>
                  BUSINESS NAME
                </InputLabel>
                <Paper className={classes.paper} elevation={0}>
                  {trade && trade.name}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.inputLabel}>
                  BUSINESS ABN
                </InputLabel>
                <Paper className={classes.paper} elevation={0}>
                  {trade && (trade as any).abn}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          className={classes.centered}
          item
          xs={12}
          sm={3}
          md={2}
          lg={1}></Grid>
        <Grid
          item
          className={!isSmall ? classes.gridItem : ""}
          xs={12}
          sm={9}
          md={10}
          lg={11}>
          <div>
            <Grid container spacing={isSmall ? 1 : 2}>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.inputLabel}>
                  BUSINESS TYPE
                </InputLabel>
                <Paper className={classes.paper} elevation={0}>
                  {trade &&
                  !TypeHelper.isNullOrUndefined(
                    getBusinessType(trade.tradeTypeId)
                  )
                    ? getBusinessType(trade.tradeTypeId)!.label
                    : ""}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={isSmall ? 1 : 2}
        className={classes.textContainer}>
        <Grid item className={!isSmall ? classes.gridItem : ""} xs={12}>
          <Typography className={classes.subHeading} variant="h3">
            {LABELS.branchProfileSubHeading}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={`${!isSmall ? classes.gridLeft : ""} ${
            !isSmall ? classes.gridItem : ""
          }`}>
          <div>
            <Grid container spacing={isSmall ? 1 : 2}>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.inputLabel}>
                  OFFICE NAME
                </InputLabel>
                <Paper className={classes.paper} elevation={0}>
                  {primaryBranch && primaryBranch.name}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.inputLabel}>
                  OFFICE PHONE
                </InputLabel>
                <Paper className={classes.paper} elevation={0}>
                  {primaryBranch && primaryBranch.phone}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={`${!isSmall ? classes.gridLeft : ""} ${
            !isSmall ? classes.gridItem : ""
          }`}>
          <div>
            <Grid container spacing={isSmall ? 1 : 2}>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.inputLabel}>
                  OFFICE ADDRESS
                </InputLabel>
                <Paper className={classes.paper} elevation={0}>
                  {primaryBranch && getAddress(primaryBranch)}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.inputLabel}>
                  OFFICE EMAIL
                </InputLabel>
                <Paper className={classes.paper} elevation={0}>
                  {primaryBranch && primaryBranch.email}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item className={!isSmall ? classes.gridItem : ""} xs={12}>
          <Typography className={classes.subHeading} variant="h3">
            SERVICEABLE AREAS
          </Typography>
        </Grid>
        <List
          className={classes.list}
          style={
            primaryBranch &&
            primaryBranch.serviceableRegions &&
            primaryBranch.serviceableRegions.length > 1 &&
            !isSmall
              ? { columns: "2" }
              : {}
          }>
          {(
            (get(primaryBranch, "serviceableRegions", []) as any).map(
              (sr: ServiceableRegion) => ({
                ...sr,
                locality: sr.suburb,
              })
            ) as ServiceableRegion[]
          ).map((region) => (
            <ListItem key={region.postcode} className={classes.listItemPadding}>
              <StyledListItemAvatar>
                <StyledAvatar>
                  <LocationOnOutlined color={"inherit"} />
                </StyledAvatar>
              </StyledListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h4">{`${region.postcode}, ${region.locality}`}</Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>

      <Grid
        container
        spacing={isSmall ? 1 : 2}
        className={classes.textContainer}>
        <Grid item className={!isSmall ? classes.gridItem : ""} xs={12}>
          <Typography className={classes.subHeading} variant="h3">
            {LABELS.userProfileSubHeading}
          </Typography>
        </Grid>
        <List className={classes.list}>
          {tradeUsers
            ? tradeUsers.content.map((user, i) => (
                <div key={user.id}>
                  <ListItem
                    className={`${classes.listItem} ${classes.listItemPadding}`}>
                    <ListItemAvatar>
                      <Avatar src={user.logo}></Avatar>
                    </ListItemAvatar>
                    <div className={classes.listTextContainer}>
                      <ListItemText
                        primary={`${user.firstName} ${user.lastName}`}
                        secondary={getUserTradeEntitlements(
                          user.userTradeEntitlements
                        )}
                      />
                    </div>
                  </ListItem>
                  <Divider
                    variant="inset"
                    component="li"
                    hidden={i + 1 === tradeUsers.totalElements}
                  />
                </div>
              ))
            : null}
        </List>
      </Grid>
    </RegistrationStepsWrapper>
  );
};
