import {
  ImageOutlined,
  InsertDriveFileOutlined,
  PictureAsPdfOutlined,
} from "@material-ui/icons";
import React, { FC } from "react";
import { FileTypes } from "../../constants/AppConstants";
import { uploadFileListItemIconStyles } from "./UploadFileListItemIconStyles";

interface UploadedFileListItemIconProps {
  fileType: FileTypes | undefined;
}

const UploadedFileListItemIcon: FC<UploadedFileListItemIconProps> = ({
  fileType,
}) => {
  // hooks
  const classes = uploadFileListItemIconStyles();

  switch (fileType) {
    case FileTypes.Image:
      return <ImageOutlined className={`${classes.icon} ${classes.imgIcon}`} />;

    case FileTypes.Pdf:
      return (
        <PictureAsPdfOutlined
          className={`${classes.icon} ${classes.pdfIcon}`}
        />
      );

    default:
      return <InsertDriveFileOutlined className={classes.icon} />;
  }
};

export default UploadedFileListItemIcon;
