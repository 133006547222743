import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { RenderImageInputStyles, RenderImageInputComponentStyles } from "./RenderImageInputStyles";

interface Props {
  label: string;
  src: string | null | undefined;  
  onChange: (file: File | undefined) => void;
  disabled?: boolean | null;
}

type OwnProps = RenderImageInputComponentStyles & Props;

class RenderImageInput extends Component<OwnProps> {
  private inputRef: React.RefObject<any>;
  constructor(props: OwnProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.inputRef = React.createRef();
  }

  public render() {
    const { label, src, classes, disabled } = this.props;
    return (
      <React.Fragment>
        <div>
          {src ? (
            <img
              onClick={this.handleClick}
              src={src}
              className={classes.previewImage}
            />
          ) : (
            <div onClick={this.handleClick} className={classes.addImageDiv}>
              {label}
            </div>
          )}
        </div>
        <div>
          <input
            ref={this.inputRef}
            style={{ display: "none" }}
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={this.onChange}
            disabled={!!disabled}
          />
        </div>
      </React.Fragment>
    );
  }
  private onChange(e) {
    const { onChange } = this.props;
    onChange(e.target.files[0]);
  }

  private handleClick() {
    this.inputRef.current!.click();
  }
}

export default withStyles(RenderImageInputStyles)(RenderImageInput);
