import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../../../../theme/Theme";

export const PersonalInfoFormStyles = makeStyles({
  rolesHeading: {
    height: "46px",
    padding: ".5em",
    marginTop: "26.5px",
    borderBottom: `1px solid ${color.greyText}`,
    display: "flex",
    alignItems: "center",
  },
});
