import { ListItem, ListItemAvatar, Box, ListItemText } from "@material-ui/core";
import {
  PhoneOutlined,
  EmailOutlined,
  MessageOutlined,
} from "@material-ui/icons";
import React, { FC } from "react";
import { color } from "../../theme/Theme";
import { ContactCardsStyles } from "./ContactCardStyles.data";

interface ContactDetailsProps {
  phone: string;
  email: string;
  listItemClass?: string;
  listItemTextClass?: string;
  noMessage?: boolean;
}

const ContactDetails: FC<ContactDetailsProps> = ({
  phone,
  email,
  listItemClass,
  listItemTextClass,
  noMessage,
}) => {
  const classes = ContactCardsStyles();
  return (
    <>
      <ListItem button className={listItemClass}>
        <ListItemAvatar>
          <Box pr={3}>
            <PhoneOutlined />
          </Box>
        </ListItemAvatar>
        <ListItemText className={listItemTextClass}>
          <Box className={listItemTextClass}>
            <a style={{ color: color.secondary200 }} href={`tel:${phone}`}>
              {phone}
            </a>
          </Box>
        </ListItemText>
      </ListItem>
      {!noMessage && (
        <ListItem button className={listItemClass}>
          <ListItemAvatar>
            <Box pr={3}>
              <MessageOutlined />
            </Box>
          </ListItemAvatar>
          <ListItemText className={listItemTextClass}>
            <Box className={listItemTextClass}>
              <a style={{ color: color.secondary200 }} href={`sms:${phone}`}>
                {phone}
              </a>
            </Box>
          </ListItemText>
        </ListItem>
      )}
      <ListItem button className={listItemClass}>
        <ListItemAvatar>
          <Box pr={3}>
            <EmailOutlined />
          </Box>
        </ListItemAvatar>
        <ListItemText className={classes.listItemText}>
          <Box className={listItemTextClass}>
            <a style={{ color: color.secondary200 }} href={`mailto:${email}`}>
              {email}
            </a>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};

export default ContactDetails;
