import React, { FC } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputBase,
  makeStyles,
  Typography,
  Box,
  Tooltip,
} from "@material-ui/core";

import { color } from "../../../../theme/Theme";
import { Branch } from "../../../../container/listBranches/ListBranches.data";
import { CheckCircle } from "@material-ui/icons";
import { UpdateBranchFormFieldLabels } from "../account/branches/UpdateBranchFormData.data";

interface Props {
  selected: string;
  options: Branch[];
  onChange: any;
}

const DropDownInput = withStyles((theme) => ({
  input: {
    border: `1px solid ${color.lightGrey}`,
    borderRadius: "4px",
    fontSize: "16px",
    color: color.secondary200,
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  menuItemRoot: {
    fontSize: "16px",
    color: color.secondary200,
  },
}));

const DropDown: FC<Props> = (props) => {
  const { options, onChange, selected } = props;
  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined">
      <Select
        value={selected}
        onChange={(e) => {
          onChange(e.target.value!);
        }}
        input={<DropDownInput />}
        label="branch">
        {options.map((option) => (
          <MenuItem
            classes={{ root: classes.menuItemRoot }}
            key={option.id}
            value={option.id}>
            <Box display="flex" fontSize="16px" alignItems="center">
              <Typography variant="h4">
                {`${"Office: "}${option.name}`}
              </Typography>
              {!!option.covidApproved && (
                <Tooltip title={UpdateBranchFormFieldLabels.covidVaccinated}>
                  <CheckCircle
                    fontSize="inherit"
                    style={{ marginLeft: ".5em", color: color.teal }}
                    color="inherit"
                  />
                </Tooltip>
              )}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;
