import React, { FC, useState, useContext } from "react";
import {
  Typography,
  Box,
  Input,
  FormControl,
  InputLabel,
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  FormHelperText,
} from "@material-ui/core";
import { LABELS } from "../TermsConstants.data";
import {
  RenderInputFieldStyles,
  inputsFields,
} from "../../../FormikComponents/RenderInputFieldStyle";
import DashboardButton from "../../dashboardButton/DashboardButton";
import { Home, Clear } from "@material-ui/icons";
import { AgencyBranchTermsResponse } from "../../../../models/agency/AgencyBranchTermsResponse";
import { TermsAndConditionsContext } from "../../../../container/team/AgentsContainer";
import { SelectAgencyStyles } from "./SelectAgencyStyles.data";

interface Props {
  setTermsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectAgenciesDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectAgency: FC<Props> = (props) => {
  const { input } = RenderInputFieldStyles();
  const [searchCode, setSearchCode] = useState<string>("");

  const [error, setError] = useState<string>("");
  const { setTermsDialogOpen, setSelectAgenciesDialogOpen } = props;

  const {
    onGetAgencyBranch,
    setSelectedAgencies,
    selectedAgencies,
    tradeAgencies,
  } = useContext(TermsAndConditionsContext);

  const classes = SelectAgencyStyles();

  return (
    <Box
      flexGrow="1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between">
      {/* Content and heading */}
      <Box>
        <Box>
          <Typography color="textPrimary" variant="h5">
            {LABELS.enterBranchCode}
          </Typography>
        </Box>

        {/* Message */}
        <Box mt={3}>
          <Typography color="textSecondary" variant="h4">
            {LABELS.enterCodeMessage}
          </Typography>
        </Box>

        {/* Search input field */}
        <Box mt={4}>
          <FormControl fullWidth>
            <InputLabel>{LABELS.branchCode}</InputLabel>
            <Input
              classes={{ input }}
              onChange={(e) => {
                if (error) {
                  setError("");
                }
                setSearchCode(e.currentTarget.value);
              }}
              value={searchCode}
              style={inputsFields}
              placeholder={
                selectedAgencies.length === 0
                  ? LABELS.addBranchCode
                  : LABELS.addAnotherBranchCode
              }
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
          <Box width="100%" textAlign="right" mt={2}>
            <DashboardButton
              text={"Add"}
              disabled={!searchCode.trim()}
              variant={!searchCode.trim() ? "outlined" : "contained"}
              lightText={!!searchCode.trim()}
              greyText={!searchCode.trim()}
              centered
              action={() => {
                const searchedCode = searchCode.trim();

                // Checking if code has already been selected
                for (const selectedAgency of selectedAgencies) {
                  if (selectedAgency.branch.internalCode === searchedCode) {
                    setError(LABELS.alreadySelected);
                    return;
                  }
                }

                // Checking if terms have already been responded to
                for (const tradeAgency of tradeAgencies) {
                  if (
                    tradeAgency.branchDetail.branchDetail.internalCode ===
                    searchedCode
                  ) {
                    setError(LABELS.alreadyResponded);
                    return;
                  }
                }

                onGetAgencyBranch({
                  branchCode: searchedCode,
                  onSuccess: (response: AgencyBranchTermsResponse) => {
                    setSearchCode("");
                    setSelectedAgencies([...selectedAgencies, response]);
                  },
                  onError: (err: Error) => {
                    setError(err.toString());
                  },
                });
              }}
            />
          </Box>
        </Box>

        {/* Selected Agencies */}
        <Box className={classes.scroll} overflow="auto" maxHeight="50vh" mt={2}>
          <List>
            {selectedAgencies.map((agency) => (
              <ListItem
                key={agency.id}
                classes={{ container: classes.listItem }}
                alignItems="center">
                <ListItemAvatar>
                  <Home />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h4">{agency.branch!.name!}</Typography>
                  }
                  secondary={
                    <Typography
                      component="span"
                      color="textSecondary"
                      variant="caption">
                      {`${agency.branch!.suburb!} - ${agency.branch!
                        .internalCode!}`}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction
                  className={`${classes.listItemSecondaryAction} ${classes.pointer}`}>
                  <Clear
                    onClick={() =>
                      setSelectedAgencies(
                        selectedAgencies.filter(
                          (ag) =>
                            ag.branch.internalCode !==
                            agency.branch.internalCode
                        )
                      )
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>

      {/* Buttons */}
      <Box mt={2} textAlign="right">
        <Box display="inline" mr={2}>
          <DashboardButton
            standardSize
            centered
            text="Cancel"
            variant="outlined"
            color="inherit"
            textButton
            action={() => {
              setSelectedAgencies([]);
              setSelectAgenciesDialogOpen(false);
            }}
          />
        </Box>
        <DashboardButton
          standardSize
          centered
          text="Submit"
          disabled={!selectedAgencies.length}
          variant={!!selectedAgencies.length ? "contained" : "outlined"}
          lightText={!!selectedAgencies.length}
          greyText={!selectedAgencies.length}
          action={() => {
            setTermsDialogOpen(true);
            setSelectAgenciesDialogOpen(false);
          }}
        />
      </Box>
    </Box>
  );
};

export default SelectAgency;
