import React, { FC, useState } from "react";
import { styled } from "@material-ui/core/styles";
import ContentHeadingSection from "../../contentHeadingSection/ContentHeadingSection";
import { GetTradeSuccessPayload } from "../../../../services/listBranches/ListBranchesServiceData.data";
import { LABELS } from "./AccountSettingsConstants.data";
import DashboardSection from "../../dashboardSection/DashboardSection";
import UpdateBranches from "./branches/UpdateBranches";
import DropDown from "../select/Select";
import { useHistory } from "react-router-dom";
import { Box, Divider, Button, Typography } from "@material-ui/core";
import SelectedRegions from "../../../addServiceableRegions/SelectedRegions";
import {
  BranchResponse,
  ServiceableRegion,
} from "../../../../services/branch/Branch.data";
import RenderAddressPicker from "../../../AddressPicker/RenderAddressPicker";
import { AddressValue } from "../../../../helper/GooglePlaces/AddressValue.data";
import { AddressComponent } from "../../../AddressPicker/AddressPicker.data";
import { getRegion } from "../../../../container/addServiceableRegionsForm/AddServiceableRegionsForm";
import { AddServiceableRegionsRequest } from "../../../../services/serviceableRegions/serviceableRegionsServiceData.data";
import ErrorText from "../../../registrationStepsComponents/errorText/ErrorText";
import UpdateTradeForm from "./trade/UpdateTradeForm";
import { TradeInfo } from "../../../../models/trade/Trade.data";
import { UpdateBranchFormFields } from "./branches/UpdateBranchFormData.data";
import { routes } from "../../../../Routes";
import { color } from "../../../../theme/Theme";
import NearbyAreas from "../../../nearbyAreas/NearbyAreas";
import { Region } from "../../../../container/addServiceableRegionsForm/AddServiceableRegionsFormdata.data";

interface Props {
  tradeDetails: GetTradeSuccessPayload;
  onBranchChange: (officeId: number) => void;
  branchDetails: BranchResponse;
  postAddServiceableRegions: (data: AddServiceableRegionsRequest) => void;
  onRemoveServiceableRegion: (region: Partial<ServiceableRegion>) => void;
  onUpdateTradeDetails: (details: TradeInfo) => void;
  onUpdateBranchDetails: (details: UpdateBranchFormFields) => void;
}

const StyledButton = styled(Button)({
  padding: "10px",
  color: color.teal,
});

const AccountSettingsComponent: FC<Props> = (props) => {
  const {
    tradeDetails,
    onBranchChange,
    branchDetails,
    postAddServiceableRegions,
    onRemoveServiceableRegion,
    onUpdateTradeDetails,
    onUpdateBranchDetails,
  } = props;
  const branches = tradeDetails.offices;
  const [addressError, setAddressError] = useState("");
  const history = useHistory();
  // Helper functions
  const addRegion = (addressComponents: AddressComponent[]): void => {
    const region = getRegion(addressComponents);
    const { postcode, locality, state, country } = region;
    if (postcode) {
      if (uniquePostalCode(postcode, locality)) {
        postAddServiceableRegions({
          regions: [
            {
              postcode,
              state,
              country,
              locality,
            },
          ],
          officeId: branchDetails.id.toString(),
        });
        onBranchChange(branchDetails.id);
        setAddressError("");
      } else {
        setAddressError(LABELS.errors.uniqueAddress);
      }
    } else {
      setAddressError(LABELS.errors.noPostcode);
    }
  };

  const uniquePostalCode = (postcode: string, locality: string): boolean => {
    let unique = true;
    branchDetails.serviceableRegions!.forEach((region) => {
      if (region.postcode === postcode && region.locality === locality) {
        unique = false;
      }
    });
    return unique;
  };

  return (
    <Box p={2}>
      <ContentHeadingSection hideOnSmallScreens heading={LABELS.heading} />

      {/* Update Trade Details */}
      <DashboardSection sectionTopMargin innerHeading={LABELS.tradeProfile}>
        <UpdateTradeForm
          onUpdateTradeDetails={onUpdateTradeDetails}
          tradeDetails={tradeDetails}
        />
      </DashboardSection>

      <Box pt={4}>
        <Divider />
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={3} width="100%">
        <StyledButton
          variant="outlined"
          onClick={() => history.push(routes.dashboard.settings.addBranch)}>
          Add office
        </StyledButton>
      </Box>
      {/* Dropdown for changing branch */}
      <Box mt={1}>
        <DropDown
          options={branches}
          onChange={onBranchChange}
          selected={branchDetails.id.toString()}
        />
      </Box>

      {/* Update Branches Section */}
      <DashboardSection sectionTopMargin innerHeading={LABELS.depotProfile}>
        <UpdateBranches
          onUpdateBranchDetails={onUpdateBranchDetails}
          selectedBranch={branchDetails}
        />
      </DashboardSection>

      {/* Serviceable Regions */}
      <DashboardSection
        sectionTopMargin
        innerHeading={LABELS.serviceableRegions}>
        <Box mt={2} px={2}>
          {branchDetails ? (
            <>
              <Box mt={1}>
                <Typography variant="body2">
                  {LABELS.inidiviudalPostcode}
                </Typography>
              </Box>
              <RenderAddressPicker
                onChange={(value: AddressValue) => {
                  if (value.place) {
                    addRegion(
                      value.place.address_components! as AddressComponent[]
                    );
                  }
                }}
                outlined
                placeholder={LABELS.addServiceableRegion}
                endAdornmentPosition="start"
                flushOnSelect
                regionSearch
                pincodeAndSuburb
              />
              <ErrorText text={addressError} />

              <Box my={1}>
                <Typography>OR</Typography>
              </Box>

              <Box mb={2}>
                <Typography variant="body2">{LABELS.addPostCodes}</Typography>
              </Box>

              <NearbyAreas
                center={{
                  lat: +branchDetails.latitude!,
                  lng: +branchDetails.longitude!,
                }}
                addSuggestionRegions={(suggestions: Region[]) => {
                  postAddServiceableRegions({
                    regions: suggestions,
                    officeId: branchDetails.id.toString(),
                  });
                }}
              />

              <SelectedRegions
                regions={branchDetails.serviceableRegions!}
                handleDelete={onRemoveServiceableRegion}
                collapsible
              />
            </>
          ) : null}
        </Box>
      </DashboardSection>
    </Box>
  );
};

export default AccountSettingsComponent;
