import React, { FC } from "react";
import "./shimmer.css";
import { styled } from "@material-ui/core";

const Loader = styled("div")({
  animationDuration: "1s",
  animationFillMode: "forwards",
  animationIterationCount: "infinite",
  animationTimingFunction: "linear",
  animationName: "placeholderShimmer",
});

interface ShimmerProps {
  height: string;
  width: string;
  style?: Record<string, any>;
}

const Shimmer: FC<ShimmerProps> = ({ height, width, style }) => (
  <Loader
    style={{ height, width, margin: "4px", ...style }}
    className="shimmer-shine"
  />
);

export default Shimmer;
