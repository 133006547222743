export const LABELS = {
  costOfLabour: "Cost of labour:",
  callOut: "Call out:",
  includedMaterials: "Included materials:",
  specificMaterial: "Materials",
  total: "Total",
  subTotal: "Sub Total (inc GST):",
  jobCost: "Job cost",
  gst: "+GST",
  sortedFee: "Sorted fee",
  sortedFeeHelperText:
    "A 3.5% processing fee will be automatically applied to the total cost of each job. This is for ongoing support and improvements to the Sorted platform, and will be invoiced to you on a monthly basis.",
  includesGstOf: "Includes GST of",
  processingFee: "Processing fee (ex GST):",
  estimateTotal: "Estimated Total",
  costLimit: (costLimit: number) => `(Preferred cost limit: $${costLimit})`,
};
