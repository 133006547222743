import React, { FC } from "react";
import DashboardSection from "../../dashboardSection/DashboardSection";
import { color } from "../../../../theme/Theme";
import { List, Box, Typography, IconButton } from "@material-ui/core";
import { Property } from "../../../../models/property/Property.data";
import moment from "moment";
import {
  getAddressString,
  getWorkOrderById,
  titleCase,
  truncateText,
} from "../../../../helper/HelperFunctions";
import { MOMENT_DATE_FORMATS } from "../../../../constants/AppConstants";
import { cancelledJobTypes, JobStatus } from "../../../../models/jobs/JobData";
import TradeCategoryIcon from "../../../tradeCategoryIcon/TradeCategoryIcon";
import { routes } from "../../../../Routes";
import { useHistory } from "react-router";
import { Job } from "../../../../services/jobs/JobsData.data";
import DashboardListItem from "../../../lisItems/DashboardListItem";
import { Home, MonetizationOn, ArrowForward, Event } from "@material-ui/icons";
import ListItemSecondaryDetailComponent from "../../quotes/ListItemSecondaryDetailComponent";
import { get } from "lodash";
import { JobCardStyles } from "./JobCardStyles.data";
import UrgentTaskListItem from "../../urgentTaskListItem/UrgentTaskListItem";

interface Props {
  job: Job;
  property: Property;
  status: JobStatus[];
  showJobDetailsInSidebar: React.Dispatch<React.SetStateAction<Job | null>>;
  active?: boolean;
}

// tslint:disable-next-line:cyclomatic-complexity
const JobCard: FC<Props> = (props) => {
  const history = useHistory();
  const classes = JobCardStyles();
  const { job, property, status, showJobDetailsInSidebar, active } = props;

  const {
    quote: {
      maintenanceRequestResponse: {
        details,
        audit: { createdDate },
        workOrders,
      },
      workOrderId,
    },
    id,
    keyDates,
    jobDetail: { totalCost },
    parentId,
  } = job;

  const startDate = get(keyDates, "startDate");
  const timeOfDay = get(keyDates, "timeSlot.timeOfDay");

  const { postcode, suburb, state, photos } = property;

  const workOrder = getWorkOrderById(workOrders, workOrderId);
  const { urgent } = workOrder;

  return (
    <Box
      mb={4}
      style={{ cursor: "pointer" }}
      onClick={() => {
        showJobDetailsInSidebar(job);
        parentId
          ? history.push(routes.dashboard.viewVariationJob(parentId, id))
          : history.push(routes.dashboard.viewJob(id));
      }}>
      <DashboardSection
        borderColor={
          urgent ? color.warning : active ? color.teal : color.lightGrey
        }
        innerHeading={
          <DashboardListItem
            primary={details.title}
            secondary={`${moment(createdDate).fromNow()} • ${titleCase(
              workOrder.category.replaceAll("_", " ")
            )}`}
            image={<TradeCategoryIcon tradeName={workOrder.category} />}
            secondaryData={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gridGap="1rem">
                <MonetizationOn />
                <Typography>{`$${totalCost}`}</Typography>
              </Box>
            }
          />
        }>
        <>
          <List classes={{ root: classes.listRoot }}>
            {urgent && <UrgentTaskListItem />}

            <Box mb="1rem" mt="0.5rem">
              <Typography variant="caption">
                {truncateText(details.description, 200)}
              </Typography>
            </Box>

            <DashboardListItem
              image={photos && photos.length ? photos[0].link : <Home />}
              primary={getAddressString(property)}
              secondary={`${suburb && titleCase(suburb)}${
                suburb && ","
              } ${state}${state && ","} ${postcode}`}
              secondaryData={
                startDate ? (
                  <Box display="flex">
                    <ListItemSecondaryDetailComponent
                      primary={moment(
                        startDate!,
                        MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM
                      ).format(MOMENT_DATE_FORMATS.DO_MMM_YYYY)}
                      secondary={timeOfDay!}
                      primaryColor={color.warning}
                    />
                  </Box>
                ) : undefined
              }
            />
            {status.includes(JobStatus.BookingRequired) && (
              <DashboardListItem
                image={<Event />}
                primary={moment(createdDate!).format(
                  MOMENT_DATE_FORMATS.DO_MMM_YYYY
                )}
                secondary="Requested On"
              />
            )}
          </List>

          {status !== cancelledJobTypes && (
            <Box mt={2} display="flex" justifyContent="flex-end">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  showJobDetailsInSidebar(job);
                }}>
                <ArrowForward />
              </IconButton>
            </Box>
          )}
        </>
      </DashboardSection>
    </Box>
  );
};

export default JobCard;
