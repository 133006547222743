import { JobsAction, JobsActionType } from "../actions/JobsActions";
import { JobsState } from "../state/JobsState";
import {
  GetJobsResponse,
  GetJobByIdResponse,
  ConfirmJobBookingSuccessPayload,
  ChangeJobStatusRequestPayload,
  AddArtefactsSuccessPayload,
  JobCostUpdateRequest,
} from "../../services/jobs/JobsData.data";
import { User } from "../../models/user/User.data";
import { JobStatus } from "../../models/jobs/JobData";
import { BOOKING_TIME_OPTIONS } from "../../component/dashboard/jobs/viewJob/ViewJobComponentConstants.data";
import { getJobsCountKeyNameFromStatus } from "../../helper/HelperFunctions";

const initialState: JobsState = {
  error: null,
  jobsData: null,
  viewJobData: null,
  jobUsers: null,
  loading: false,
};

// tslint:disable-next-line:cyclomatic-complexity
export const jobsReducer = (
  state = initialState,
  action: JobsAction
): JobsState => {
  switch (action.type) {
    case JobsActionType.GetJobsRequest: {
      if (state.jobsData) {
        return {
          ...state,
          error: null,
          jobsData: {
            ...state.jobsData,
            paginatedJobResponse: {
              ...state.jobsData!.paginatedJobResponse!,
              content: [],
              totalElements: 0,
            },
          },
          loading: true,
        };
      } else {
        return state;
      }
    }

    case JobsActionType.GetJobsSuccess: {
      const data = action.data as GetJobsResponse;
      return {
        ...state,
        error: null,
        jobsData: data,
        loading: false,
      };
    }

    case JobsActionType.GetJobsError: {
      const error = action.data as Error;
      return {
        ...state,
        error,
        jobsData: null,
        loading: false,
      };
    }

    case JobsActionType.GetJobByIdSuccess: {
      const jobData = action.data as GetJobByIdResponse;
      return {
        ...state,
        error: null,
        viewJobData: jobData,
      };
    }

    case JobsActionType.GetJobUsersForWorkorderSuccess: {
      const jobUsers = action.data as User[];
      return {
        ...state,
        error: null,
        jobUsers,
      };
    }

    case JobsActionType.ConfirmJobBookingSuccess: {
      const {
        keyDates: { expectedCompletion, startDate, timeSlot: updatedTimeOfDay },
        assignedUserId,
        id,
      } = action.data as ConfirmJobBookingSuccessPayload;
      const { viewJobData } = state;

      let { jobResponse } = viewJobData!;

      // If the booking was confirmed for the parent job that is in the store update it
      // Otherwise update the variation job whose id matches the job id in jobVariations list of parent job
      if (jobResponse.id === id) {
        jobResponse = {
          ...jobResponse,
          status: JobStatus.Scheduled,
          keyDates: {
            expectedCompletion,
            startDate,
            timeSlot: {
              clockTimeRange: BOOKING_TIME_OPTIONS.find(
                (bt) => bt.timeOfDay === updatedTimeOfDay
              )!.clockTimeRange!,
              timeOfDay: updatedTimeOfDay,
            },
          },
          assignedUserId,
        };
      } else {
        jobResponse = {
          ...jobResponse,
          jobVariations: jobResponse.jobVariations.map((variation) => {
            if (variation.id === id) {
              return {
                ...variation,
                status: JobStatus.Scheduled,
                keyDates: {
                  expectedCompletion,
                  startDate,
                  timeSlot: {
                    clockTimeRange: BOOKING_TIME_OPTIONS.find(
                      (bt) => bt.timeOfDay === updatedTimeOfDay
                    )!.clockTimeRange!,
                    timeOfDay: updatedTimeOfDay,
                  },
                },
                assignedUserId,
              };
            } else {
              return variation;
            }
          }),
        };
      }

      return {
        ...state,
        viewJobData: { ...viewJobData!, jobResponse },
        error: null,
      };
    }

    case JobsActionType.ChangeJobStatusRequest: {
      return {
        ...state,
        loading: true,
      };
    }

    case JobsActionType.ChangeJobStatusSuccess: {
      const { status, id } = action.data as ChangeJobStatusRequestPayload;
      const { viewJobData, jobsData } = state;

      if (viewJobData) {
        if (id === viewJobData!.jobResponse.id) {
          return {
            ...state,
            loading: false,
            viewJobData: {
              ...viewJobData!,
              jobResponse: {
                ...viewJobData!.jobResponse,
                status,
              },
            },
          };
        } else {
          return {
            ...state,
            loading: false,
            viewJobData: {
              ...viewJobData!,
              jobResponse: {
                ...viewJobData!.jobResponse,
                jobVariations: viewJobData!.jobResponse.jobVariations.map(
                  (variation) =>
                    id === variation.id ? { ...variation, status } : variation
                ),
              },
            },
          };
        }
      } else {
        const updatedCount = { ...jobsData!.jobStatusCount };
        const updateKey = getJobsCountKeyNameFromStatus(
          jobsData!.paginatedJobResponse.content.find((j) => j.id === id)!
            .status as JobStatus
        );
        if (updateKey) {
          updatedCount[updateKey] = updatedCount[updateKey] - 1;
        }

        return {
          ...state,
          loading: false,
          jobsData: {
            ...jobsData!,
            jobStatusCount: updatedCount,
            paginatedJobResponse: {
              ...jobsData!.paginatedJobResponse,
              totalElements: jobsData!.paginatedJobResponse.totalElements - 1,
              content: jobsData!.paginatedJobResponse.content.filter(
                (j) => j.id !== id
              ),
            },
          },
        };
      }
    }

    case JobsActionType.AddArtefactsError: {
      const error = action.data as Error;
      return {
        ...state,
        error,
      };
    }

    case JobsActionType.AddArtefactsSuccess: {
      const { id, ...files } = action.data as AddArtefactsSuccessPayload;
      const originalJob = state.viewJobData!.jobResponse!;

      // If aretfacts were uploaded for the original/parentjob update it
      // else update the variation with the id same as the id in request
      if (originalJob.id === id) {
        return {
          ...state,
          error: null,
          viewJobData: {
            jobResponse: {
              ...originalJob,
              jobArtefactFiles: files,
            },
            property: state.viewJobData!.property,
          },
        };
      } else {
        const jobVariations = originalJob.jobVariations.map((variation) =>
          variation.id === id
            ? { ...variation, jobArtefactFiles: files }
            : variation
        );
        return {
          ...state,
          error: null,
          viewJobData: {
            jobResponse: { ...originalJob, jobVariations },
            property: state.viewJobData!.property,
          },
        };
      }
    }

    case JobsActionType.ClearViewJobData: {
      return {
        ...state,
        viewJobData: null,
      };
    }

    // Add variation
    case JobsActionType.AddJobVariationError: {
      return {
        ...state,
        error: action.data as Error,
      };
    }

    // update cost
    case JobsActionType.UpdateJobCostSuccess: {
      const {
        callOutCost,
        labourCost,
        includedMaterialCost,
        specificMaterial,
        changeNote,
        totalCost,
      } = action.data as JobCostUpdateRequest;
      const { viewJobData } = state;
      const { jobResponse } = viewJobData!;
      const { jobDetail } = jobResponse;
      return {
        ...state,
        viewJobData: {
          ...viewJobData!,
          jobResponse: {
            ...jobResponse,
            jobDetail: {
              ...jobDetail,
              callOutCost,
              labourCost,
              includedMaterialCost,
              specificMaterial,
              changeNote,
              totalCost,
            },
          },
        },
      };
    }

    case JobsActionType.UpdateJobCostError: {
      const error = action.data as Error;
      return {
        ...state,
        error,
      };
    }

    default:
      return state;
  }
};
