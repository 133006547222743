import * as Yup from "yup";
import { EMAIL_PATTERN } from "../../constants/AppConstants";

export const LABELS = {
  MY_ACCOUNT: "Log in to Sorted",
  ACCOUNT_DESCRIPTION: "",
  USERNAME: "Username / email",
  PASSWORD: "Password",
  LOGIN: "LOG IN",
  SIGNUP: "SIGN UP",
  REMEMBER_ME: "Remember me",
  FORGOT_PASSWORD: "Forgot Password",
  CONTINUE_AS_A_GUEST: "CONTINUE AS A GUEST",
  NOT_HAVE_ACCOUNT: "Don't have an account?",
  RESEND_LINK: "Resend Link",
  ENTER_DETAILS_HEADING: "Enter your log in details below to get started!",

  ENTER_OTP: "OTP",
};

export const ERRORS = {
  requiredEmail: "Please enter your email address",
  invalidEmailFormat: "Invalid email format",
  requiredPassword: "Please enter password",
  minimumRequired: "Minimum 8 characters required",
  requiredOtp: "Please enter an otp",
  otpLength: "OTP should be exactly 6 digits",
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string()
    .required(ERRORS.requiredEmail)
    .max(255)
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
  password: Yup.string()
    .required(ERRORS.requiredPassword)
    .min(8, ERRORS.minimumRequired),
});

export const VALIDATION_SCHEMA_IMPERSONATION = Yup.object().shape({
  username: Yup.string()
    .required(ERRORS.requiredEmail)
    .max(255)
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
  otp: Yup.number()
    .required(ERRORS.requiredOtp)
    .test("length", ERRORS.otpLength, (val) => /^[0-9]{6}$/.test(val)),
});
