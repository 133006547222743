import React, { FC } from "react";
import InviteUserComponent from "../../component/inviteUser/InviteUserComponent";
import { useDispatch } from "react-redux";
import { PostTradeUserActions } from "../../store/actions/TradeUsersActions";
import { InviteUserFormData } from "../../component/inviteUser/InviteUserFomData.data";
import { InviteUserFormDataToRequestConverter } from "./InviteUserFormDataToRequestConverter";
import { showToast } from "../../helper/HelperFunctions";
import { LABELS } from "../../component/inviteUser/InviteUserConstants.data";
import { ToastType } from "../../models/toastTypes/ToastTypesData.data";
import { useHistory } from "react-router";
import { routes } from "../../Routes";

const InviteUser: FC<{}> = (props) => {
  // dispatcher
  const dispatch = useDispatch();

  const history = useHistory();

  const dispatcher = {
    postUser: (formData: InviteUserFormData) => {
      dispatch(
        PostTradeUserActions.postTradeUserRequest({
          request: InviteUserFormDataToRequestConverter(formData),
          onSuccess: (response) => {
            showToast(
              dispatch,
              LABELS.inviteSuccessful + `${response.email}`,
              ToastType.SUCCESS
            );
            history.push(routes.listUsers.view);
          },
        })
      );
    },
  };

  return (
    <InviteUserComponent
      onSubmit={(formData) => dispatcher.postUser(formData)}
    />
  );
};

export default InviteUser;
