import React, { FC, useState } from "react";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
  ListItemText,
  ListItemAvatar,
  styled,
  Avatar,
} from "@material-ui/core";
import { Clear, LocationOnOutlined } from "@material-ui/icons";
import { color } from "../../theme/Theme";
import { ServiceableRegion } from "../../services/branch/Branch.data";
import { SelectedRegionsStyles } from "./SelectedRegionsStyles.data";

interface OwnProps {
  regions: Partial<ServiceableRegion>[];
  handleDelete: (region: Partial<ServiceableRegion>) => void;
  collapsible?: boolean;
  maxItems?: number | null;
}

const StyledAvatar = styled(Avatar)({
  backgroundColor: color.light,
  color: color.dark1000,
});

const StyledListItemAvatar = styled(ListItemAvatar)({
  minWidth: "40px",
});

const SelectedRegions: FC<OwnProps> = (props) => {
  const [collapse, setCollapse] = useState<boolean>(true);

  const classes = SelectedRegionsStyles();
  const { regions, handleDelete, maxItems, collapsible } = props;
  const max = maxItems || 5;
  const displayRegions =
    collapsible && collapse && max < regions.length
      ? regions.slice(0, max)
      : regions;

  return (
    <div className={classes.container}>
      <List>
        {displayRegions.map((region, index) => (
          <ListItem
            button
            key={index}
            divider
            classes={{ container: classes.listItem }}>
            <StyledListItemAvatar>
              <StyledAvatar>
                <LocationOnOutlined color={"inherit"} />
              </StyledAvatar>
            </StyledListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="h4">{`${
                  region.locality || region.suburb
                }, ${region.postcode}`}</Typography>
              }
            />
            <ListItemSecondaryAction
              className={`${classes.pointer} ${classes.listItemSecondaryAction}`}>
              <Clear onClick={() => handleDelete(region)} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {collapsible && max < regions.length && (
          <ListItem button onClick={() => setCollapse(!collapse)}>
            <StyledListItemAvatar>
              <StyledAvatar>
                <LocationOnOutlined color={"disabled"} />
              </StyledAvatar>
            </StyledListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="h4" color="textSecondary">
                  {collapse ? `+ ${regions.length - max} more` : `Show Less`}
                </Typography>
              }
            />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default SelectedRegions;
