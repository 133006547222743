import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import {DashboardSectionInnerHeadingStyles} from "./DashboardSectionInnerHeadingStyles.data"

interface Props {
  text: string
}

const DashboardSectionInnerHeading:FC<Props> = (props) => {
  const {text} = props;
  const classes = DashboardSectionInnerHeadingStyles();
  return (
    <div className={classes.sectionHeadingContainer}>
      <Typography className={classes.innerHeading} variant="caption">{text}</Typography>
    </div>
   )
 }

export default DashboardSectionInnerHeading