import React, { FC, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  useTheme,
  useMediaQuery,
  Switch,
  Paper,
} from "@material-ui/core";
import { Region } from "../../../../../container/addServiceableRegionsForm/AddServiceableRegionsFormdata.data";
import { getRegion } from "../../../../../container/addServiceableRegionsForm/AddServiceableRegionsForm";
import { AddressComponent } from "../../../../AddressPicker/AddressPicker.data";
import RenderAddressPicker from "../../../../AddressPicker/RenderAddressPicker";
import { AddressValue } from "../../../../../helper/GooglePlaces/AddressValue.data";
import { TypeHelper } from "../../../../../helper/TypeHelper";
import { Formik, Field } from "formik";
import { NEW_BRANCH_FORM_VALIDATION_SCHEMA } from "../AccountSettingsConstants.data";
import {
  NewBranchFormFieldNames,
  NewBranchFormFieldLabels,
} from "./NewBranchData.data";
import RenderInput from "../../../../FormikComponents/RenderInputField";
import { getRegistrationFormAddressComponent } from "../../../../../helper/HelperFunctions";
import { OfficeDetails as AddBranchRequest } from "../../../../../services/registerBusiness/RegistrationFormRequest.data";
import {
  inputStylePropsGenerator,
  RenderInputFieldStyles,
} from "../../../../FormikComponents/RenderInputFieldStyle";
import SelectedRegions from "../../../../addServiceableRegions/SelectedRegions";
import DashboardButton from "../../../../dashboard/dashboardButton/DashboardButton";
import { LABELS } from "../../../../addServiceableRegions/AddServicableRegionsConstants";
import { ServiceableRegion } from "../../../../../models/serviceableRegion/ServiceableRegion.data";
import ErrorText from "../../../../registrationStepsComponents/errorText/ErrorText";

interface NewBranchFormProps {
  onSubmit: (values: AddBranchRequest) => void;
}

export const NewBranchForm: FC<NewBranchFormProps> = ({ onSubmit }) => {
  const [addressError, setAddressError] = useState("");
  const initialValues: AddBranchRequest = {
    name: "",
    email: "",
    phone: "",
    country: "",
    postcode: "",
    suburb: "",
    state: "",
    primary: false,
    serviceableRegions: [],
    covidApproved: null,
  };

  const uniquePostalCode = (
    postcode: string,
    suburb: string,
    serviceableRegions: ServiceableRegion[]
  ): boolean => {
    let unique = true;
    const similarRegion = serviceableRegions!.find(
      (region: ServiceableRegion) =>
        region.postcode === postcode || region.suburb === suburb
    );
    if (!TypeHelper.isNullOrUndefined(similarRegion)) {
      unique = false;
    }
    return unique;
  };

  const addNewRegions = (
    addressComponents: AddressComponent[],
    serviceableRegions: ServiceableRegion[]
  ) => {
    const region = getRegion(addressComponents);
    const { postcode, locality, state, country } = region;
    let newRegions = serviceableRegions;
    if (postcode) {
      if (uniquePostalCode(postcode, locality, serviceableRegions)) {
        const newRegion = {
          postcode,
          suburb: locality,
          state,
          country,
        } as ServiceableRegion;
        newRegions = [...newRegions, newRegion];
        setAddressError("");
      } else {
        setAddressError(LABELS.errors.uniqueAddress);
      }
    } else {
      setAddressError(LABELS.errors.noPostcode);
    }
    return newRegions;
  };

  const renderServicableRegions = (
    values: AddBranchRequest,
    setValues: any
  ) => {
    let { serviceableRegions } = values;
    const deleteRegions = (region: Region) => {
      const index = serviceableRegions.indexOf(
        region as unknown as ServiceableRegion
      );
      if (index > -1) {
        serviceableRegions.splice(index, 1);
      }
      setValues({ ...values, serviceableRegions });
    };
    return (
      <Box mt={2}>
        <Typography>Add servicable regions</Typography>
        <RenderAddressPicker
          onChange={(value: AddressValue) => {
            if (value.place) {
              serviceableRegions = addNewRegions(
                value.place!.address_components!,
                serviceableRegions
              );
              setValues({ ...values, serviceableRegions });
            }
          }}
          endAdornmentPosition="start"
          placeholder={NewBranchFormFieldLabels.serviceableRegions}
          flushOnSelect
          regionSearch
          pincodeAndSuburb
        />
        <SelectedRegions
          regions={serviceableRegions}
          handleDelete={deleteRegions}
        />
        <ErrorText text={addressError} />
      </Box>
    );
  };

  const renderInputProps = inputStylePropsGenerator(RenderInputFieldStyles());
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Box m={3}>
      <Paper style={{ padding: "24px" }}>
        <Typography>New Office details</Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          validationSchema={NEW_BRANCH_FORM_VALIDATION_SCHEMA}
          enableReinitialize>
          {({
            setValues,
            handleSubmit,
            values,
            isValid,
            dirty,
            setFieldValue,
          }) => (
            <Box mt={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Field name={NewBranchFormFieldNames.branchName}>
                    {({ form, field }) => (
                      <RenderInput
                        label={NewBranchFormFieldLabels.branchName}
                        {...renderInputProps}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box pr={isSmall ? 0 : 2}>
                    <Field name={NewBranchFormFieldNames.branchMail}>
                      {({ form, field }) => (
                        <RenderInput
                          label={NewBranchFormFieldLabels.branchMail}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box pl={isSmall ? 0 : 2}>
                    <Field name={NewBranchFormFieldNames.branchPhone}>
                      {({ form, field }) => (
                        <RenderInput
                          label={NewBranchFormFieldLabels.branchPhone}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Field name={NewBranchFormFieldNames.branchAddress}>
                    {({ form, field }) => (
                      <RenderAddressPicker
                        onChange={(value: AddressValue) => {
                          if (value.place) {
                            const branchAddress =
                              getRegistrationFormAddressComponent(
                                value.place!
                                  .address_components! as AddressComponent[]
                              );
                            setValues({
                              ...values,
                              ...(branchAddress as Partial<AddBranchRequest>),
                            });
                          }
                        }}
                        endAdornmentPosition="start"
                        placeholder={NewBranchFormFieldLabels.branchAddress}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={5}>
                    <Field name={NewBranchFormFieldNames.serviceableRegions}>
                      {({ form, field }) =>
                        renderServicableRegions(values, setValues)
                      }
                    </Field>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" mt={4}>
                    <Field type="checkbox" name="primary">
                      {({ form, field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              onChange={(e) =>
                                form.setFieldValue(
                                  "primary",
                                  e.currentTarget.checked
                                )
                              }
                              name="tracker"
                              {...field}
                            />
                          }
                          label={
                            <Typography variant="h4">
                              {"This is head office"}
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                    <Box display="flex" justifyContent="flex-end" pr={2}>
                      <DashboardButton
                        variant={"outlined"}
                        color={!dirty || !isValid ? "inherit" : undefined}
                        greyText={!dirty || !isValid}
                        text={"Add office"}
                        action={handleSubmit}
                        disabled={!dirty || !isValid}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default NewBranchForm;
