import { makeStyles } from "@material-ui/core";
import { color } from "../../../../theme/Theme";

export const SubmitQuoteComponentStyles = makeStyles({
  expansionSection: {
    marginTop: "2rem",
  },
  workOrderDetailsKey: {
    fontFamily: "GT Walsheim",
    marginRight: "1em",
  },
  workOrderDetailsListItem: {
    padding: ".25em 1em",
  },
  formHeading: {
    fontSize: "18.1px",
    fontWeight: 500,
  },
  quoteFormValue: {
    color: color.teal,
    fontSize: "20px",
    display: "inline",
  },
  inline: {
    display: "inline",
  },
  pointer: {
    cursor: "pointer",
  },
  note: {
    color: color.charcoal,
  },
  submitQuoteButton: {
    fontSize: "13.6px",
    padding: "12px 0",
    width: "152px",
    borderRadius: "4px",
  },
  hide: {
    display: "none",
  },
  optionListStyle: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 0,
    paddingLeft: 0,
    alignItems: "flex-start",
  },
});
