export enum JobStatus {
  BookingRequired = "booking_required",
  Scheduled = "scheduled",
  ApprovalPending = "approval_pending",
  PaymentOnHold = "payment_on_hold",
  Complete = "complete",
  TradeCancellation = "trade_cancellation",
  AgentCancellation = "agent_cancellation",
}

export const cancelledJobTypes = [
  JobStatus.TradeCancellation,
  JobStatus.AgentCancellation,
];
