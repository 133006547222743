import { Avatar, Box, Typography } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { FC } from "react";
import { color } from "../../../theme/Theme";

const useStyles = makeStyles({
  avatar: {
    background: color.light,
    color: "inherit",
    marginRight: "12px",
  },
  text: {
    fontSize: "15px !important",
  },
});

const UrgentTaskListItem: FC<{}> = () => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" color={color.warning} pb={1}>
      <Avatar className={classes.avatar}>
        <ErrorOutline color="inherit" />
      </Avatar>
      <Typography color="inherit" variant="h4" className={classes.text}>
        Urgent
      </Typography>
    </Box>
  );
};

export default UrgentTaskListItem;
