import {
  AgencyAction,
  AgencyActions,
  AgencyActionType,
} from "../actions/AgencyActions";
import {
  AgencyBranchTermsRequestPayload,
  PostTermsAcceptanceActionPayload,
  PostTermsAcceptanceRequest,
} from "../../services/agencies/AgencyServiceData.data";
import { Logging } from "../../helper/Logging";
import { put, takeEvery } from "redux-saga/effects";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import { AgencyBranchTermsResponse } from "../../models/agency/AgencyBranchTermsResponse";
import AgencyService from "../../services/agencies/AgencyService";

export function* getAgencyBranchTermsWorker(action: AgencyAction) {
  const {
    branchCode,
    onSuccess,
    onError,
  } = action.data as AgencyBranchTermsRequestPayload;

  try {
    Logging.debug("Agency.getAgencyBranchTermsWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    const response: AgencyBranchTermsResponse = (yield AgencyService.getAgencybranchTerms(
      { branchCode }
    ))!;

    yield put(AgencyActions.getAgencyBranchTermsSuccess(response));

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    Logging.error("AgencySaga.getAgencyBranchTermsWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(AgencyActions.getAgencyBranchTermsError(error));
    if (onError) {
      onError(error);
    }
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* respondToAgencyBranchTermsWorker(action: AgencyAction) {
  const {
    request,
    onSuccess,
    onError,
  } = action.data as PostTermsAcceptanceActionPayload;

  try {
    Logging.debug("Agency.respondToAgencyBranchTermsWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    const response: PostTermsAcceptanceRequest = (yield AgencyService.postTermsAcceptance(
      request
    ))!;
    yield put(AgencyActions.acceptAgencyBranchTermsSuccess(response));

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    yield put(AgencyActions.acceptAgencyBranchTermsError(error));
    if (onError) {
      onError(error);
    }
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const agencyWatcher = [
  takeEvery(AgencyActionType.GetAgencyBranchTermsRequest, (action) =>
    getAgencyBranchTermsWorker({
      type: action.type,
      data: (action as AgencyAction).data,
    })
  ),

  takeEvery(AgencyActionType.RespondToAgencyBranchTermsRequest, (action) =>
    respondToAgencyBranchTermsWorker({
      type: action.type,
      data: (action as AgencyAction).data,
    })
  ),
];

export default agencyWatcher;
