import React, { FC, memo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import { TradesComment } from "../../../models/comment/Comment";
import { CommentStyles } from "./CommentStyles.data";
import NewComment from "../newComment/NewComment";
import moment from "moment";
import { MOMENT_DATE_FORMATS } from "../../../constants/AppConstants";
import { avatarColors } from "../../../theme/Theme";

export interface CommentProps {
  comment: TradesComment;
  reply?: boolean;
  replyError: boolean;
  replySuccess: boolean;
  onPostReply: (comment: string) => void;
  postingComment: boolean;
  replyingTo: string | null;
  setReplyParentId: React.Dispatch<React.SetStateAction<string | null>>;
  avatarColor?: string;
}

const Comment: FC<CommentProps> = memo(
  // tslint:disable-next-line:cyclomatic-complexity
  ({
    comment: { id, author, content, child, createdDate },
    reply,
    onPostReply,
    replyingTo,
    postingComment,
    replyError,
    replySuccess,
    setReplyParentId,
    avatarColor,
  }) => {
    const classes = CommentStyles();

    return (
      <>
        <List className={reply ? classes.replyList : ""}>
          {/* Main comment */}
          <ListItem
            disableGutters
            classes={{ root: classes.commentListItem }}
            className={reply ? classes.reply : ""}
            alignItems="flex-start">
            <ListItemAvatar className={classes.commentListItemAvatar}>
              <Avatar
                style={{ backgroundColor: avatarColor || avatarColors.pink }}>
                {`${author.firstName.charAt(0)}${author.lastName.charAt(0)}`}
              </Avatar>
            </ListItemAvatar>

            {/* Wrapper box for name , details, text and replies to top level comment */}
            <Box className={classes.commentAndRepliesWrapper}>
              <ListItemText
                className={classes.commentDetailsText}
                primary={`${author.firstName} ${author.lastName}`}
                primaryTypographyProps={{
                  variant: "h4",
                  className: classes.authorName,
                }}
                secondaryTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                secondary={moment(createdDate).format(
                  MOMENT_DATE_FORMATS.CommentDateAndTime
                )}
              />
              <Typography className={classes.commentContent} variant="body2">
                {content}
              </Typography>
              <Box className={classes.actionsContainer}>
                <Typography
                  onClick={() => setReplyParentId(id)}
                  variant="caption"
                  color="primary">
                  Reply
                </Typography>
              </Box>

              {/* Reply input field for top level comment */}
              {!reply && replyingTo === id && !replySuccess && (
                <Box mt={2}>
                  <NewComment
                    postingComment={postingComment && replyingTo === id}
                    error={replyError}
                    onPost={onPostReply}
                    onCancel={() => setReplyParentId(null)}
                  />
                </Box>
              )}

              {/* Replies for a top level comment should show at indentation of comment content */}
              {!reply && child.length > 0 && (
                <>
                  {/* Replies count and separator */}
                  <Box display="flex" alignItems="center" my={1}>
                    <Typography
                      className={classes.replyCount}
                      variant="caption"
                      color="textSecondary">{`${child.length} ${
                      child.length === 1 ? "reply" : "replies"
                    }`}</Typography>
                    <Divider className={classes.repliesSeparator} />
                  </Box>

                  {/* Child comments/replies */}
                  {child.map((commentReply) => (
                    <Comment
                      replySuccess={replySuccess}
                      key={`reply-${commentReply.id}`}
                      reply
                      setReplyParentId={setReplyParentId}
                      replyError={replyError}
                      postingComment={postingComment}
                      comment={commentReply}
                      avatarColor={avatarColors.orange}
                      onPostReply={onPostReply}
                      replyingTo={replyingTo}
                    />
                  ))}
                </>
              )}
            </Box>
          </ListItem>
        </List>
        {/* Reply to a reply */}
        {reply && replyingTo === id && !replySuccess && (
          <Box mt={2}>
            <NewComment
              postingComment={postingComment && replyingTo === id}
              error={replyError}
              onPost={onPostReply}
              onCancel={() => setReplyParentId(null)}
            />
          </Box>
        )}

        {/* Replies on a reply (non top level comments) should show up as separate comments and divider is not required */}
        {reply && child.length > 0 && (
          <>
            {child.map((commentReply) => (
              <Comment
                replySuccess={replySuccess}
                key={`reply-${commentReply.id}`}
                reply
                setReplyParentId={setReplyParentId}
                replyError={replyError}
                postingComment={postingComment}
                comment={commentReply}
                avatarColor={avatarColors.orange}
                onPostReply={onPostReply}
                replyingTo={replyingTo}
              />
            ))}
          </>
        )}
      </>
    );
  }
);

export default Comment;
