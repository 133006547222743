import { makeStyles } from "@material-ui/core";

export const availabilitySectionStyles = makeStyles({
  formHeading: {
    fontSize: "18.1px",
    fontWeight: 500,
    marginBottom: ".5em",
  },
  dateFieldWrapper: {
    width: "200px",
  },
});
