import React, { FC, useState, useRef, useEffect } from "react";
import {
  ListItem,
  ListItemText,
  withStyles,
  ListItemIcon,
  Avatar,
  styled,
  makeStyles,
  Box,
} from "@material-ui/core";
import { LABELS } from "./TradeCategoriesFormConstants.data";
import { Add, CancelOutlined } from "@material-ui/icons";
import { color } from "../../../../../theme/Theme";
import TradeDocsCard from "../../../../addTradesDocuments/tradeDocsCard/TradeDocsCard";
import {
  UserTradeCategoriesResponse,
  DeleteUserTradeCategoryDocumentRequest,
} from "../../../../../services/userTrades/UserTradeService.data";
import AddDocModal from "../../../../addTradesDocuments/addDocModal/AddDocModal";
import { AddDocFormData } from "../../../../addTradesDocuments/addDocModal/AddDocForm.data";
import { AddDocData } from "../../../../../container/addTradesDocuments/AddTradesDocumentsData.data";
import AddUserTradeCategoriesModal from "./AddUserTradeCategoriesModal";
import {
  GetTradeCategoriesResponse,
  TradeCategory,
} from "../../../../../services/tradeCategories/TradeCategories.data";
import ConfirmationDialog from "../../../../confirmationDialog/ConfirmationDialog";
import { confirmationDialogIconDimensions } from "../../../../../constants/AppConstants";

interface Props {
  onAddTradeCategory: (selectedTradeCategories: TradeCategory[]) => void;
  onRemoveTradeCategory: (tradeCategoryId: number) => void;
  userTradeCategories: UserTradeCategoriesResponse;
  onAddTradeCategoryDocument: (values: AddDocData) => void;
  tradeCategories: GetTradeCategoriesResponse;
  onRemoveUserTradeCategoryDocument: (
    request: DeleteUserTradeCategoryDocumentRequest
  ) => void;
}

// List item overrides
const ListTradeCategoryIcon = withStyles({
  root: {
    minWidth: "40px",
  },
})(ListItemIcon);

const StyledAvatar = styled(Avatar)({
  backgroundColor: color.light,
  marginRight: "14px",
});

const AddIcon = withStyles({
  root: {
    color: color.secondary500,
  },
})(Add);

const useStyles = makeStyles({
  fileInput: {
    display: "none",
  },
  confirmationDialogIcon: {
    width: "100%",
    height: "100%",
  },
});

const TradeCategoriesForm: FC<Props> = (props) => {
  const [file, setFile] = useState<File | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const [userTradeCategoryId, setUserTradeCategoryId] = useState<
    number | undefined
  >(undefined);
  // Trade document modal
  const [open, setOpen] = useState<boolean>(false);

  // Trade category modal
  const [tradeCategoryModalOpen, setTradeCategoryModalOpen] = useState<boolean>(
    false
  );

  // Delete User Trade CategoryModal
  const [
    deleteUserTradeCategoryModal,
    setDeleteUserTradeCategoryModalOpen,
  ] = useState<boolean>(false);
  const [deleteUserTradeCategoryId, setDeleteUserTradeCategoryId] = useState<
    number | null
  >(null);

  const [
    deleteUserTradeCategoryDocumentModal,
    setDeleteUserTradeCategoryDocumentModalOpen,
  ] = useState<boolean>(false);
  const [
    deleteTradeCategoryDocumentId,
    setDeleteTradeCategoryDocumentId,
  ] = useState<number | null>(null);
  const [
    deleteDocumentTradeCategoryId,
    setDeleteDocumentTradeCategoryId,
  ] = useState<number | null>(null);

  // Props
  const {
    userTradeCategories,
    tradeCategories,
    onAddTradeCategoryDocument,
    onRemoveTradeCategory,
    onAddTradeCategory,
    onRemoveUserTradeCategoryDocument,
  } = props;

  // Effects
  useEffect(() => {
    setOpen(false);
    setTradeCategoryModalOpen(false);
    setDeleteUserTradeCategoryModalOpen(false);
    setDeleteUserTradeCategoryDocumentModalOpen(false);
  }, [userTradeCategories]);

  // File Input functions
  const fileChange = () => {
    if (fileRef.current && fileRef.current.files![0]) {
      const newFile = fileRef.current.files![0];
      setFile(newFile);
      openUploadModal(userTradeCategoryId!);
    } else {
      if (open) {
        closeUploadModal();
      }
    }
  };

  const handleInputClick = (id: number) => {
    fileRef.current!.value = "";
    setUserTradeCategoryId(id);
    setFile(null);
    fileRef.current!.click();
  };

  // Modal functions
  const openUploadModal = (id: number) => {
    setOpen(true);
    setUserTradeCategoryId(id);
  };

  const closeUploadModal = () => {
    setOpen(false);
    setUserTradeCategoryId(undefined);
    setFile(null);
  };

  // Trade Category modal functions
  const handleTradeCategoryModalClose = () => {
    setTradeCategoryModalOpen(false);
  };

  // Delete User trade category modal functions
  const openDeleteUserTradeCategoryModal = (tradeCategoryId: number) => {
    setDeleteUserTradeCategoryId(tradeCategoryId);
    setDeleteUserTradeCategoryModalOpen(true);
  };

  const onDeleteUserTradeCategoryId = () => {
    onRemoveTradeCategory(deleteUserTradeCategoryId!);
  };

  // Delete User trade document modal functions
  const openDeleteUserTradeCategoryDocumentModal = (
    tradeCategoryId: number,
    documentId: number
  ) => {
    setDeleteTradeCategoryDocumentId(documentId);
    setDeleteDocumentTradeCategoryId(tradeCategoryId);
    setDeleteUserTradeCategoryDocumentModalOpen(true);
  };

  const onDeleteTradeCategoryDocument = () => {
    onRemoveUserTradeCategoryDocument({
      userTradeCategoryDocumentId: deleteTradeCategoryDocumentId!,
      userTradeCategoryId: deleteDocumentTradeCategoryId!,
    });
  };

  const classes = useStyles();

  return (
    <>
      {userTradeCategories.map((trade) => (
        <TradeDocsCard
          key={`${trade.id}`}
          userTradeCategoryId={trade.id}
          trade={trade.tradeCategoryResponse}
          docs={trade.userTradeCategoryDocuments}
          uploadDoc={() => handleInputClick(trade.id)}
          onRemoveTradeCategory={openDeleteUserTradeCategoryModal}
          onRemoveUserTradeCategoryDocument={
            openDeleteUserTradeCategoryDocumentModal
          }
        />
      ))}

      <ListItem onClick={() => setTradeCategoryModalOpen(true)} button>
        <ListTradeCategoryIcon>
          <StyledAvatar>
            <AddIcon />
          </StyledAvatar>
        </ListTradeCategoryIcon>
        <ListItemText
          secondaryTypographyProps={{ variant: "h4" }}
          secondary={LABELS.addTrade}
        />
      </ListItem>

      <AddDocModal
        onSubmit={(formValues: AddDocFormData) =>
          onAddTradeCategoryDocument({
            ...formValues,
            userTradeCategoryId,
            file,
          } as AddDocData)
        }
        fileName={(file && file.name) || undefined}
        closeModal={closeUploadModal}
        open={open}
      />

      <AddUserTradeCategoriesModal
        open={tradeCategoryModalOpen}
        handleClose={handleTradeCategoryModalClose}
        tradeCategories={tradeCategories}
        addUserTradeCategories={onAddTradeCategory}
      />

      {/* Delete trade category dialog */}
      <ConfirmationDialog
        open={deleteUserTradeCategoryModal}
        cancelText={LABELS.cancel}
        acceptText={LABELS.remove}
        primaryText={LABELS.removeTradeCategory}
        secondaryText={LABELS.removeTradeCategorySubheading}
        action={onDeleteUserTradeCategoryId}
        handleConfirmationDialogClose={() =>
          setDeleteUserTradeCategoryModalOpen(false)
        }
        topIcon={
          <Box color={color.warning}>
            <CancelOutlined
              style={{ ...confirmationDialogIconDimensions }}
              color="inherit"
            />
          </Box>
        }
      />

      {/* Delete trade document dialog */}
      <ConfirmationDialog
        open={deleteUserTradeCategoryDocumentModal}
        cancelText={LABELS.cancel}
        acceptText={LABELS.delete}
        primaryText={LABELS.deleteDocument}
        secondaryText={LABELS.deleteDocumentDescription}
        action={onDeleteTradeCategoryDocument}
        handleConfirmationDialogClose={() =>
          setDeleteUserTradeCategoryDocumentModalOpen(false)
        }
        topIcon={
          <Box color={color.warning}>
            <CancelOutlined
              style={{ ...confirmationDialogIconDimensions }}
              color="inherit"
            />
          </Box>
        }
      />

      <input
        ref={fileRef}
        className={classes.fileInput}
        type="file"
        onChange={fileChange}
      />
    </>
  );
};

export default TradeCategoriesForm;
