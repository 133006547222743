import React, { FC } from "react";
import { TRADE_CATEGORIES_ICON_DIRECTORY } from "../../constants/AppConstants";

interface Props {
  tradeName: string;
  imageClass?: string;
}

const tradeIconMap = new Proxy(
  {
    ELECTRICIAN: "ELECTRICIAN",
    CARPENTER: "CARPENTER",
    LOCKSMITH: "LOCKSMITH",
    PLUMBER: "ROOF_PLUMBER",
    ROOF_PLUMBER: "ROOF_PLUMBER",
    PLUMBER_GASFITTER: "PLUMBER_GASFITTER",
    ELECTRICIAN_MIDDYS_HOME_AUTOMATION: "ELECTRICIAN",
    ELECTRICIAN_MIDDYS_SMART_HOME: "ELECTRICIAN",
    ELECTRICIAN_MIDDYS: "ELECTRICIAN",
    TILER: "TILER",
  },
  {
    get(target, value) {
      return target[value] || "generic";
    },
  }
);

const TradeCategoryIcon: FC<Props> = (props) => {
  const { tradeName, imageClass } = props;
  return (
    <img
      className={imageClass}
      src={`${TRADE_CATEGORIES_ICON_DIRECTORY}/${tradeIconMap[tradeName]}.svg`}
      onError={(e) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = `${TRADE_CATEGORIES_ICON_DIRECTORY}/generic.svg`;
      }}
      alt="Trade Logo"
    />
  );
};

export default TradeCategoryIcon;
