import * as Yup from "yup";
import { EMAIL_PATTERN } from "../../../../../constants/AppConstants";
import { phoneCheck } from "../../../../../helper/Validation";

export const LABELS = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email address",
  role: "Role",
  mobileNumber: "Mobile Number",
  image: "Image",
};

export const formFields = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  role: "role",
  mobileNumber: "mobileNumber",
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().trim().required("First name is required").max(50),
  lastName: Yup.string().trim().required("Last name is required").max(50),
  email: Yup.string()
    .required("Email is required")
    .matches(EMAIL_PATTERN, "Invalid email format"),
  mobileNumber: Yup.string()
    .required("Phone number is required")
    .test("phone-validation", "Invalid phone number", (value) => {
      return phoneCheck(value);
    }),
});
