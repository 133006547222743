import React, { FC, useEffect, useState } from "react";
import AddTradesComponent from "../../component/addTrades/AddTradeCategoriesComponent";
import { useDispatch } from "react-redux";
import { TradeCategoriesActions } from "../../store/actions/TradeCategoriesActions";
import { useAppSelector } from "../../store/RootReducers";
import { TradeCategory } from "../../services/tradeCategories/TradeCategories.data";
import { useHistory } from "react-router-dom";
import { routes } from "../../Routes";
import { onboardingStageUpdater } from "../../helper/OnboardingStageUpdater/OnboardingStageUpdater";
import { OnboardingStage } from "../../helper/OnboardingStages";

const AddTrades: FC<{}> = (props) => {
  // State
  const [selectedTradeCategories, setselectedTradeCategories] = useState<TradeCategory[]>([]);

  // Selector
  const { tradeCategories, error } = useAppSelector((state) => ({
    tradeCategories: state.tradeCategories.tradeCategories,
    error: state.tradeCategories.error,
  }));

  // History for redirection
  const history = useHistory()

  // Dispatcher
  const dispatch = useDispatch();
  const dispatcher = {
    getTradeCategories: () =>
      dispatch(TradeCategoriesActions.getTradeCategoriesRequest()),
    postTradeCategories: () => {
      const request = selectedTradeCategories.map((trade) => ({ tradeCategoryId: trade.id }))
      dispatch(TradeCategoriesActions.postUserTradeCategoriesRequest({
        request, onSuccess: async (response) => {
          if (localStorage.getItem("inviteToken")) {
            history.push(routes.addTradesDocuments.view);
          }
          else {
            await onboardingStageUpdater(OnboardingStage.AddAdminTradeCategoriesDocuments);
            history.push(routes.addTradesDocuments.view);
          }
        }
      }));
    }
  };

  // Effects
  useEffect(() => {
    dispatcher.getTradeCategories();
  }, []);

  // For adding category
  const addCategory = (category: TradeCategory) => {
    if (
      selectedTradeCategories.filter((trade) => trade.id === category.id).length === 0
    ) {
      setselectedTradeCategories([...selectedTradeCategories, category]);
    }
  };

  // For removing category
  const removeCategory = (id: number) => {
    setselectedTradeCategories([...selectedTradeCategories.filter((trade) => trade.id !== id)]);
  };
  return tradeCategories ? (
    <AddTradesComponent
      selectedTradeCategories={selectedTradeCategories}
      addCategory={addCategory}
      removeCategory={removeCategory}
      tradeCategories={tradeCategories}
      error={error}
      onSubmit={dispatcher.postTradeCategories}
    />
  ) : null;
};
export default AddTrades;
