import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import {GetTradeUsersRequest, GetTradeUsersSuccessPayload, PostTradeUserRequestPayload, PostTradeUserSuccessPayload} from "./TradeUsers.data"

const tradeServiceType = ServiceType.Trades;
const userServiceType = ServiceType.Users;

class TradeUsersService {
  private readonly tradeService: ApiServiceBase = new ApiService(tradeServiceType);
  private readonly userService: ApiServiceBase = new ApiService(userServiceType);

  public get(payload: GetTradeUsersRequest): Promise<GetTradeUsersSuccessPayload> | GetTradeUsersSuccessPayload {
    const {tradeId} = payload;
    return this.tradeService.get({ route: [tradeId, "users"] });
  }

  public postUser(data:PostTradeUserRequestPayload): Promise<PostTradeUserSuccessPayload>|PostTradeUserSuccessPayload{
    return this.userService.post({}, data.request);
  }
}

export default new TradeUsersService();
