import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../../src/Routes";
import { onboardingRouteMapper } from "../../../src/helper/HelperFunctions";
import { OnboardingStage } from "../../../src/helper/OnboardingStages";
import { onboardingStageUpdater } from "../../../src/helper/OnboardingStageUpdater/OnboardingStageUpdater";
import SavedPaymentMethods from "../../component/dashboard/settings/payment/SavedPaymentMethods";
import RegistrationStepsWrapper from "../../component/registrationStepsComponents/registrationStepsWrapper/RegistrationStepsWrapper";
import { getTradeId } from "../../../src/helper/Auth";
import SkipText from "../../component/skipText/SkipText";

const AddPayment: FC = () => {
  const history = useHistory();

  const onNextAndSkip = async () => {
    await onboardingStageUpdater(OnboardingStage.AddMoreOffice);
    if (getTradeId()) {
      history.push(onboardingRouteMapper(OnboardingStage.AddMoreOffice));
    } else {
      history!.push(routes.error.view());
    }
  };

  return (
    <RegistrationStepsWrapper
      heading={"Add Payment Methods"}
      subheading={
        "Please add your credit card details below. We'll use this to process Sorted's platform fee on completion of each job (if applicable)"
      }
      primaryButton={{
        text: "Next",
        action: onNextAndSkip,
      }}
      footerComponent={
        <SkipText action={() => onNextAndSkip()}>
          {"Skip this step, I'll add payment methods later"}
        </SkipText>
      }>
      <SavedPaymentMethods />
    </RegistrationStepsWrapper>
  );
};

export default AddPayment;
