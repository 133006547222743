import { takeEvery, put } from "redux-saga/effects";
import { Logging } from "../../helper/Logging";

import UserDocumentsService from "../../services/userDocumentsService/UserDocumentsService";
import {
  UserDocumentsAction,
  UserDocumentsActions,
  UserDocumentsActionType,
} from "../actions/UserDocumentsActions";
import {
  PostUserDocumentSuccessPayload,
  PostUserDocumentData,
  DeleteUserDocumentRequest,
} from "../../services/userDocumentsService/UserDocuments.data";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";

export function* PostUserDocumentWorker(action: UserDocumentsAction) {
  const data = action.data as PostUserDocumentData;

  try {
    Logging.debug("UserDocuments.PostUserDocumentWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    const response: PostUserDocumentSuccessPayload = (yield UserDocumentsService.postUserDocs(
      data
    ))!;

    yield put(
      UserDocumentsActions.PostUserDocumentSuccess(
        response as PostUserDocumentSuccessPayload
      )
    );
  } catch (error) {
    Logging.error("UserDocumentSaga.postDocumentWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(UserDocumentsActions.PostUserDocumentError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* DeleteUserDocumentWorker(action: UserDocumentsAction) {
  const data = action.data as DeleteUserDocumentRequest;

  try {
    Logging.debug("UserDocuments.DeleteUserDocument", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    yield UserDocumentsService.deleteUserDoc(data);

    yield put(UserDocumentsActions.DeleteUserDocumentSuccess(data));
  } catch (error) {
    Logging.error("UserDocumentSaga.DeleteDocumentWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(UserDocumentsActions.DeleteUserDocumentError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const userDocumentsWatcher = [
  takeEvery(UserDocumentsActionType.PostUserDocumentRequest, (action) =>
    PostUserDocumentWorker({
      type: action.type,
      data: (action as UserDocumentsAction).data,
    })
  ),
  takeEvery(UserDocumentsActionType.DeleteUserDocumentRequest, (action) =>
    DeleteUserDocumentWorker({
      type: action.type,
      data: (action as UserDocumentsAction).data,
    })
  ),
];

export default userDocumentsWatcher;
