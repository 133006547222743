import React, { FC, useEffect, useState } from "react";
import RegistrationStepsWrapper from "../registrationStepsComponents/registrationStepsWrapper/RegistrationStepsWrapper";
import { LABELS } from "./AddServicableRegionsConstants";
import ContentBoxSection from "../registrationStepsComponents/contentBox/ContentBoxSection";
import RenderAddressPicker from "../AddressPicker/RenderAddressPicker";
import { AddressValue } from "../../helper/GooglePlaces/AddressValue.data";
import { AddressComponent } from "../AddressPicker/AddressPicker.data";
import ContentBoxSubheading from "../registrationStepsComponents/contentBox/ContentBoxSubheading";
import SelectedRegions from "./SelectedRegions";
import { Region } from "../../container/addServiceableRegionsForm/AddServiceableRegionsFormdata.data";
import ErrorText from "../registrationStepsComponents/errorText/ErrorText";
import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { routes } from "../../Routes";
import { getTradeId } from "../../helper/Auth";
import { ServiceableRegion } from "../../services/branch/Branch.data";
import { AddServiceableRegionsStyles } from "./AddServiceableRegionsStyle.data";
import { LatLng } from "../googleMap/GoogleMap";
import useFetch from "../../hooks/useFetch";
import ListBranchesService from "../../services/listBranches/ListBranchesService";
import { Branch, Trade } from "../../container/listBranches/ListBranches.data";
import NearbyAreas from "../nearbyAreas/NearbyAreas";

interface PropsFromParent {
  regions: Region[];
  addressError: string;
  addRegion: (addressComponents: AddressComponent[]) => void;
  removeRegion: (region: Partial<ServiceableRegion>) => void;
  onSubmit: () => void;
  addSuggestionRegions: (regions: Region[]) => void;
}

type OwnProps = PropsFromParent;

export const AddServiceableRegions: FC<OwnProps> = (props) => {
  const {
    addRegion,
    addressError,
    removeRegion,
    regions,
    onSubmit,
    addSuggestionRegions,
  } = props;
  const classes = AddServiceableRegionsStyles();
  const [branch, setBranch] = useState<Branch | undefined>();

  const history = useHistory();

  const { state: listBranchesState, fetchData: getBranches } = useFetch(
    ListBranchesService.get.bind(ListBranchesService)
  );

  useEffect(() => {
    if (listBranchesState.success) {
      setBranch((listBranchesState.data as Trade).offices[0]);
    }
  }, [listBranchesState.success]);

  useEffect(() => {
    getBranches({ tradeId: localStorage.getItem("tradeId")! });
  }, []);

  const location = branch
    ? { lat: +branch.latitude, lng: +branch.longitude }
    : null;

  return branch ? (
    <RegistrationStepsWrapper
      heading={LABELS.heading}
      subheading={LABELS.subheading}
      contentBox
      primaryButton={{
        text: "Next",
        action: onSubmit,
        disabled: regions.length === 0,
      }}
      secondaryButton={{
        text: "Skip",
        action: () => history!.push(routes.listBranches.view(getTradeId()!)),
        disabled: regions.length === 0,
      }}
      noPaddingButtonsSection>
      <ContentBoxSection heading={LABELS.serviceableRegionHeading}>
        <Box mt={1}>
          <Typography variant="body2">{LABELS.inidiviudalPostcode}</Typography>
        </Box>
        <RenderAddressPicker
          onChange={(value: AddressValue) => {
            if (value.place) {
              addRegion(value.place.address_components! as AddressComponent[]);
            }
          }}
          outlined
          placeholder={LABELS.searchBarPlaceholder}
          endAdornmentPosition="start"
          flushOnSelect
          regionSearch
          pincodeAndSuburb
        />
        <ErrorText text={addressError} />

        <Box my={1}>
          <Typography>OR</Typography>
        </Box>

        <Box mb={2}>
          <Typography variant="body2">{LABELS.addPostCodes}</Typography>
        </Box>

        <NearbyAreas
          center={location as LatLng}
          addSuggestionRegions={addSuggestionRegions}
        />

        <div className={classes.subheading}>
          <ContentBoxSubheading text={LABELS.suburbs} />
        </div>
        <SelectedRegions regions={regions} handleDelete={removeRegion} />
        <Typography variant="body2">{LABELS.addMore}</Typography>
      </ContentBoxSection>
    </RegistrationStepsWrapper>
  ) : null;
};
