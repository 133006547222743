import { QuotesAction, QuotesActionType } from "../actions/QuotesActions";
import { QuotesState } from "../state/QuotesState";
import {
  GetQuoteResponse,
  GetQuotesResponse,
} from "../../services/quotes/QuotesData.data";
import { cloneDeep } from "lodash";
import { Quote } from "../../models/quote/Quote.data";

const initialState: QuotesState = {
  error: null,
  quotesData: null,
  getQuoteByIdResponse: null,
  loading: false,
};

// tslint:disable-next-line: cyclomatic-complexity
export const quotesReducer = (
  state = initialState,
  action: QuotesAction
): QuotesState => {
  switch (action.type) {
    case QuotesActionType.GetQuotesRequest: {
      if (state.quotesData) {
        return {
          ...state,
          error: null,
          loading: true,
          quotesData: {
            ...state.quotesData,
            paginatedQuoteResponse: {
              ...state.quotesData!.paginatedQuoteResponse!,
              content: [],
              totalElements: 0,
            },
          },
        };
      } else {
        return state;
      }
    }

    case QuotesActionType.GetQuotesSuccess: {
      const data = action.data as GetQuotesResponse;
      return {
        ...state,
        error: null,
        quotesData: data,
        loading: false,
      };
    }

    case QuotesActionType.GetQuotesError: {
      const error = action.data as Error;
      return {
        ...state,
        error,
        quotesData: null,
        loading: false,
      };
    }

    // get quote using ID
    case QuotesActionType.GetQuoteSuccess: {
      const quoteData = action.data as GetQuoteResponse;
      return {
        ...state,
        error: null,
        getQuoteByIdResponse: quoteData,
        loading: false,
      };
    }

    case QuotesActionType.GetQuoteError: {
      const error = action.data as Error;
      return {
        ...state,
        error,
      };
    }

    case QuotesActionType.SubmitQuoteError: {
      const error = action.data as Error;
      return {
        ...state,
        error,
      };
    }

    case QuotesActionType.SubmitQuoteSuccess: {
      const data = action.data as Quote;
      const newQuotesData = cloneDeep(state.quotesData);

      // Removing submitted quotes from 'pending' quotes
      newQuotesData!.paginatedQuoteResponse!.content! = newQuotesData!.paginatedQuoteResponse!.content!.filter(
        (quote) => quote.workOrderId === data.workOrderId
      )!;

      return {
        ...state,
        error: null,
        quotesData: newQuotesData,
        getQuoteByIdResponse: { ...state.getQuoteByIdResponse!, quote: data },
      };
    }

    case QuotesActionType.UpdateQuoteStatusSuccess: {
      const { id } = action.data as Quote;

      // Removes the 'passed' quote from current list of quotes to hide it.
      const newContent = state.quotesData!.paginatedQuoteResponse!.content.filter(
        (quote) => quote.id !== id
      );
      return {
        ...state,
        quotesData: {
          ...(state.quotesData as GetQuotesResponse),
          paginatedQuoteResponse: {
            ...state.quotesData!.paginatedQuoteResponse,
            content: newContent,
          },
        },
      };
    }

    case QuotesActionType.UpdateQuoteStatusError: {
      const error = action.data as Error;
      return {
        ...state,
        error,
      };
    }

    default:
      return state;
  }
};
