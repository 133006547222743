import { takeEvery, put } from "redux-saga/effects";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import {
  LoginAction,
  LoginActions,
  LoginActionTypes,
} from "../../store/actions/LoginActions";
import {
  LoginResponse,
  LoginPayload,
  RegisterUserPayload,
  RegisterUserResponse,
  RegisterClientResponse,
  VerificationLinkPayload,
  RefreshTokenPayload,
  RefreshTokenResponse,
  VerifyUserPayload,
  ForgotPasswordPayload,
  ResetPasswordPayload,
  GetUserResponse,
  ValidateTokenPayload,
} from "../../services/login/LoginData";
import LoginService from "../../services/login/LoginService";
import UserService from "../../services/user/UserService";
import {
  UpdateUserPayload,
  UpdateUserResponse,
  UpdatePasswordPayload,
} from "../../services/user/UserData";
import { OnboardingStage } from "../../helper/OnboardingStages";
import OnboardingStageService from "../../services/onboardingStage/OnboardingStageService";
import { onboardingStage } from "../../helper/Auth";
import LogRocket from "logrocket";

const INVALID_FORGOT_TOKEN_ERROR_MESSAGE =
  'Error: {"message":"Incorrect forgot_token"}';

export function* postLoginWorker(action: LoginAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as LoginPayload;
    const response: LoginResponse = (yield LoginService.postLogin(data))!;
    yield put(LoginActions.postLoginSuccess(response));
    LogRocket.identify("trades", {
      email: response.username,
      id: response.id,
    });
  } catch (error) {
    yield put(LoginActions.postLoginError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postRegisterUserWorker(
  action: LoginAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as RegisterUserPayload;
    const response: RegisterUserResponse = (yield LoginService.postRegisterUser(
      data
    ))!;
    yield put(LoginActions.postRegisterUserSuccess(response));
    if (data.onSuccess) {
      data.onSuccess();
    }
  } catch (error) {
    yield put(LoginActions.postRegisterUserError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getClientTokenWorker(
  action: LoginAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const response: RegisterClientResponse = (yield LoginService.getClientToken())!;
    yield put(LoginActions.getClientTokenSuccess(response));
  } catch (error) {
    yield put(LoginActions.getClientTokenError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postVerificationLinkWorker(
  action: LoginAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as VerificationLinkPayload;
    yield LoginService.postVerificationLink(data);
    yield put(LoginActions.postVerificationLinkSuccess());
  } catch (error) {
    yield put(LoginActions.getClientTokenError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postRefreshTokenWorker(
  action: LoginAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as RefreshTokenPayload;
    const response: RefreshTokenResponse = (yield LoginService.postRefreshToken(
      data
    ))!;
    yield put(LoginActions.postRefreshTokenSuccess(response));
  } catch (error) {
    yield put(LoginActions.getClientTokenError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postVerifyUserWorker(
  action: LoginAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as VerifyUserPayload;
    yield LoginService.postVerifyUser(data);
    yield put(LoginActions.postVerifyUserSuccess());
  } catch (error) {
    yield put(LoginActions.postVerifyUserError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getUserDetailsWorker(
  action: LoginAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const response: GetUserResponse = (yield UserService.getUserDetails())!;
    yield put(LoginActions.getUserDetailsSuccess(response));
  } catch (error) {
    yield put(LoginActions.getUserDetailError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postForgotPasswordLinkWorker(
  action: LoginAction
): IterableIterator<any> {
  const data = action.data as ForgotPasswordPayload;
  try {
    yield put(LoadingIndicatorActions.show());
    yield LoginService.postForgotPasswordLink(data);
    yield put(LoginActions.postForgotPasswordSuccess());
    if (data.onSuccess) {
      data.onSuccess(data.username);
    }
  } catch (error) {
    yield put(LoginActions.postForgotPasswordError(error));

    if (data.onError) {
      data.onError(JSON.parse(error.message).message);
    }
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postResetPasswordWorker(
  action: LoginAction
): IterableIterator<any> {
  const data = action.data as ResetPasswordPayload;
  try {
    yield put(LoadingIndicatorActions.show());
    yield LoginService.putResetPassword(data);
    yield put(LoginActions.resetPasswordSuccess());
    if (data.onSuccess) {
      data.onSuccess();
    }
  } catch (error) {
    yield put(LoginActions.resetPasswordError(error));
    let err = "Something went wrong";
    if ((error as Error).toString() === INVALID_FORGOT_TOKEN_ERROR_MESSAGE) {
      err = "The token has expired, please request for a new token";
    }
    const { onError } = data;

    if (onError) {
      onError(err);
    }
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postUpdatePasswordWorker(
  action: LoginAction
): IterableIterator<any> {
  const data = action.data as UpdatePasswordPayload;
  try {
    yield put(LoadingIndicatorActions.show());
    yield UserService.updatePassword(data);
    yield put(LoginActions.updatePasswordSuccess());
  } catch (error) {
    yield put(LoginActions.updatePasswordError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* putUserDetailsWorker(
  action: LoginAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data! as UpdateUserPayload;
    const { request, onSuccess } = { ...data };
    const response = yield UserService.putUserDetails(request);
    yield put(
      LoginActions.putUserDetailsSuccess(response! as UpdateUserResponse)
    );

    // Updating onboarding stage for registration
    if (
      onboardingStage() !== OnboardingStage.Completed &&
      !localStorage.getItem("inviteToken")
    ) {
      const onboardingRequest = {
        id: localStorage.getItem("userId")!,
        adminOnBoardingStage: OnboardingStage.AddAdminTradeCategories,
      };
      yield OnboardingStageService.put(onboardingRequest);
    }

    // OnSuccess
    if (onSuccess) {
      onSuccess(response!);
    }
  } catch (error) {
    yield put(LoginActions.putUserDetailsError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postValidateTokenWorker(
  action: LoginAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data! as ValidateTokenPayload;
    const response: GetUserResponse = (yield UserService.validateTokenDetails(
      data
    ))!;
    yield put(LoginActions.postValidateTokenSuccess(response));
  } catch (error) {
    yield put(LoginActions.postValidateTokenError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const loginWatcher = [
  takeEvery(LoginActionTypes.LOGIN_REQUEST, (action) =>
    postLoginWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
  takeEvery(LoginActionTypes.REFRESH_TOKEN_REQUEST, (action) =>
    postRefreshTokenWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
  takeEvery(LoginActionTypes.REGISTER_USER_REQUEST, (action) =>
    postRegisterUserWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
  takeEvery(LoginActionTypes.REGISTER_CLIENT_REQUEST, (action) =>
    getClientTokenWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
  takeEvery(LoginActionTypes.VERIFY_USER_REQUEST, (action) =>
    postVerifyUserWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
  takeEvery(LoginActionTypes.VERIFICATION_LINK_REQUEST, (action) =>
    postVerificationLinkWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
  takeEvery(LoginActionTypes.GET_USER_REQUEST, (action) =>
    getUserDetailsWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
  takeEvery(LoginActionTypes.FORGOT_PASSWORD_LINK_REQUEST, (action) =>
    postForgotPasswordLinkWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
  takeEvery(LoginActionTypes.RESET_PASSWORD_REQUEST, (action) =>
    postResetPasswordWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
  takeEvery(LoginActionTypes.UPDATE_PASSWORD_REQUEST, (action) =>
    postUpdatePasswordWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
  takeEvery(LoginActionTypes.UPDATE_USER_DETAILS_REQUEST, (action) =>
    putUserDetailsWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),

  takeEvery(LoginActionTypes.VALIDATE_INVITE_TOKEN_REQUEST, (action) =>
    postValidateTokenWorker({
      type: action.type,
      data: (action as LoginAction).data,
    })
  ),
];

export default loginWatcher;
