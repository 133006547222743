import React, { Dispatch, createContext } from "react";
import { Route } from "react-router-dom";
import "./App.css";
import Layout from "./component/Layout/Layout";
import { LoginActions } from "./store/actions/LoginActions";
import { AnyAction } from "redux";
import { ApplicationState } from "./store/RootReducers";
import { RegisterClientResponse } from "./services/login/LoginData";
import { connect } from "react-redux";
import LoadingIndicatorComponent from "./container/loadingIndicator/LoadingIndicator";
import ToastMessage from "./component/toast/ToastMessage";
import ImageViewer from "./component/imageViewer/ImageViewer";
import { Image } from "./component/imageViewer/ImageViewerData.data";
import VideoPlayer from "./component/videoPlayer/VideoPlayer";
import LogRocket from "logrocket";
import { AppSettings } from "./AppSettings";
import { apiSanitizer } from "./helper/LogRocketHelper";

interface PropsFromDispatch {
  readonly getClientToken: () => void;
}

interface PropsFromState {
  registerClientDetails: RegisterClientResponse | undefined;
}

interface ImageViewerContextValues {
  visible: boolean;
  images: Image[];
  closeImageViewer: () => void;
  setImages: (images: Image[], activeIndex?: number) => void;
  activeIndex: number;
}

interface OwnState {
  imageViewer: {
    visible: boolean;
    images: Image[];
    activeIndex: number;
  };
}

export const ImageViewerContext = createContext<ImageViewerContextValues>(
  {} as ImageViewerContextValues
);

type OwnProps = PropsFromDispatch & PropsFromState;

class App extends React.Component<OwnProps, OwnState> {
  constructor(props) {
    super(props);
    this.state = {
      imageViewer: {
        visible: false,
        images: [],
        activeIndex: 0,
      },
    };
    LogRocket.init(AppSettings.logRocket.group, {
      network: {
        requestSanitizer: (request) => {
          const shouldExclude = apiSanitizer(request.url);
          return shouldExclude ? null : request;
        },
      },
    });

    const userEmail = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (userEmail && userId) {
      LogRocket.identify("trades", {
        email: userEmail,
        userId,
      });
    }
  }

  public closeImageViewer = () => {
    this.setState({
      ...this.state,
      imageViewer: {
        visible: false,
        images: [],
        activeIndex: 0,
      },
    });
  };

  public setImages = (images: Image[], activeIndex?: number) => {
    this.setState({
      ...this.state,
      imageViewer: {
        visible: true,
        images,
        activeIndex: activeIndex || 0,
      },
    });
  };

  public componentDidMount() {
    const { getClientToken } = this.props;

    const bpointScript = document.createElement("script");
    const environment = window.location.href.includes("prodau")
      ? process.env.BPOINT_PROD
      : process.env.BPOINT_UAT;
    bpointScript.type = "text/javascript";
    bpointScript.src = environment as string;
    document.head.appendChild(bpointScript);

    const authTokenBasic = localStorage.getItem("authTokenBasic");
    if (
      !authTokenBasic ||
      authTokenBasic === "undefined" ||
      authTokenBasic === "null"
    ) {
      getClientToken();
    }
  }

  public componentWillReceiveProps(nextProps: OwnProps) {
    if (nextProps.registerClientDetails) {
      const { token } = nextProps.registerClientDetails!;
      localStorage.setItem("authTokenBasic", token);
    }
  }

  public isClientRegistered = () => localStorage.getItem("authTokenBasic");

  public render(): JSX.Element {
    return (
      <Route
        path="*"
        render={(routeProps) => {
          if (this.isClientRegistered()) {
            const {
              imageViewer: { visible, images, activeIndex },
            } = this.state;
            return (
              <ImageViewerContext.Provider
                value={{
                  visible,
                  images,
                  setImages: this.setImages,
                  closeImageViewer: this.closeImageViewer,
                  activeIndex,
                }}>
                <Layout {...routeProps} />
                <LoadingIndicatorComponent />
                <ToastMessage />
                <ImageViewer />
                <VideoPlayer />
              </ImageViewerContext.Provider>
            );
          } else {
            return null;
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  registerClientDetails: state.login.registerClientDetails,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): PropsFromDispatch => ({
  getClientToken: () => {
    dispatch(LoginActions.getClientTokenStart());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
