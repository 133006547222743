import { combineReducers } from "redux";
import { reducer as formReducer, FormStateMap } from "redux-form";
import { LoadingIndicatorState } from "./state/LoadingIndicatorState";
import { LoginState } from "./state/LoginState";
import { RegisterBusinessState } from "./state/RegisterBusinessState";
import { TradeCategoriesState } from "./state/TradeCategoriesState";
import { ServiceableRegionsState } from "./state/ServiceableRegionsState";
import { ListBranchesState } from "./state/ListBranchesState";
import { SignUpState } from "./state/SignUpState";
import { TradeUsersState } from "./state/TradeUsersState";
import { QuotesState } from "./state/QuotesState";
import { ToastState } from "./state/ToastState";
import { VideoPlayerState } from "./state/VideoPlayer";
import { loadingIndicatorReducer } from "./reducers/LoadingIndicatorReducer";
import { loginReducer } from "./reducers/LoginReducer";
import { signUpReducer } from "./reducers/SignUpReducer";
import { serviceableRegionsReducer } from "./reducers/ServiceableRegionsReducer";
import { registerBusinessReducer } from "./reducers/RegisterBusinessReducer";
import { tradeCategoriesReducer } from "./reducers/TradeCategoriesReducer";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { listBranchesReducer } from "./reducers/ListBranchesReducer";
import { addBranchReducer, getBranchReducer } from "./reducers/BranchReducer";
import { tradeUsersReducer } from "./reducers/TradeUsersReducer";
import { toastReducer } from "./reducers/ToastReducer";
import { GetBranchState } from "./state/BranchState";
import { quotesReducer } from "./reducers/QuotesReducer";
import { agencyBranchTermsReducer } from "./reducers/AgencyBranchTermsReducer";
import { AgencyBranchTermsState } from "./state/AgencyBranchTerms";
import { TradeState } from "./state/TradeState";
import { tradeReducer } from "./reducers/TradeReducer";
import { JobsState } from "./state/JobsState";
import { jobsReducer } from "./reducers/JobsReducer";
import { commentsReducer } from "./reducers/CommentsReducer";
import { CommentsState } from "./state/CommentsState";
import { UpdateTeamMemberState } from "./state/UpdateTeamMemberState";
import { updateTeamMemberReducer } from "./reducers/UpdateTeamMemberReducer";
import { videoPlayerReducer } from "./reducers/VideoPlayerReducer";
import { PaymentState } from "./state/PaymentState";
import { paymentReducer } from "./reducers/PaymentReducer";

export interface ApplicationState {
  // redux-form
  form: FormStateMap;
  loadingIndicator: LoadingIndicatorState;
  login: LoginState;
  signUp: SignUpState;
  registerBusiness: RegisterBusinessState;
  serviceableRegions: ServiceableRegionsState;
  tradeCategories: TradeCategoriesState;
  listBranch: ListBranchesState;
  addBranch: RegisterBusinessState;
  tradeUsers: TradeUsersState;
  trade: TradeState;
  toast: ToastState;
  branchDetail: GetBranchState;
  quotes: QuotesState;
  agencyBranchTerms: AgencyBranchTermsState;
  jobs: JobsState;
  comments: CommentsState;
  updateTeamMemberDetails: UpdateTeamMemberState;
  videoPlayer: VideoPlayerState;
  payment: PaymentState;
}

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

const appReducer = combineReducers<ApplicationState>({
  // redux-form
  form: formReducer,
  loadingIndicator: loadingIndicatorReducer,
  login: loginReducer,
  signUp: signUpReducer,
  registerBusiness: registerBusinessReducer,
  serviceableRegions: serviceableRegionsReducer,
  tradeCategories: tradeCategoriesReducer,
  listBranch: listBranchesReducer,
  addBranch: addBranchReducer,
  tradeUsers: tradeUsersReducer,
  toast: toastReducer,
  branchDetail: getBranchReducer,
  quotes: quotesReducer,
  agencyBranchTerms: agencyBranchTermsReducer,
  trade: tradeReducer,
  jobs: jobsReducer,
  comments: commentsReducer,
  updateTeamMemberDetails: updateTeamMemberReducer,
  videoPlayer: videoPlayerReducer,
  payment: paymentReducer,
});

export const useAppSelector: TypedUseSelectorHook<ApplicationState> =
  useSelector;

export default rootReducer;
