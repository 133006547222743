export const LABELS = {
  enterAmount: "Enter an amount",

  // Quote Details
  costOfLabour: "Cost of labour:",
  callOut: "Call out:",
  includedMaterials: "Included materials:",

  // Specific material
  specificMaterial: "Materials",
  enterItemName: "Enter an item name",

  // Sub Total
  subTotal: "Sub Total (inc GST):",
  jobCost: "Job cost",
  gst: "+GST",
  total: "Total",
  sortedFee: "Sorted fee",
  sortedFeeHelperText:
    "A 3.5% processing fee will be automatically applied to the total cost of each job. This is for ongoing support and improvements to the Sorted platform, and will be invoiced to you on a monthly basis.",
  includesGstOf: "Includes GST of",
  processingFee: "Processing fee (ex GST):",
  estimateTotal: "Estimated Total",

  submitQuote: "Submit Quote",
  note: "Once submitted it will be passed on to the agent for consideration.",

  addNote: "Add a note",
  notes: "Notes",
  cancel: "Cancel",
  update: "Update",

  costLimit: (costLimit: number) => `(Preferred cost limit: $${costLimit})`,

  inclusiveGST: "Including GST",
  exclusiveGST: "Excluding GST",
};

export const UPDATE_JOB_COST_FORM_FIELDS = {
  callOutCost: "callOutCost",
  jobMinCost: "jobMinCost",
  labourCost: "labourCost",
  miscellaneousCost: "miscellaneousCost",
  includedMaterialCost: "includedMaterialCost",
  changeNote: "changeNote",
};
