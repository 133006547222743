import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { GetUserResponse } from '../../services/login/LoginData';
import { UserData } from '../../services/tradeService/TradeServiceData.data';
import { TopNavType } from "../../component/topNav/TopNav.data";
import { TopNavTeamLink } from "../../component/topNav/TopNavTeamLink.data";
import { ApplicationState, useAppSelector } from "../../store/RootReducers";
import { useParams, useHistory } from "react-router-dom";
import DashboardWrapperComponent from '../../component/dashboard/DashboardWrapperComponent';
import ProfileSettings from '../settings/ProfileSettings';
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { GetTradeUsersActions } from '../../store/actions/TradeUsersActions';
import { getTradeId } from '../../helper/Auth';
import { LoginActions } from '../../store/actions/LoginActions';
import { UpdateTeamMemberActions } from '../../store/actions/UpdateTeamMembersAction';

interface EditMemberProps { 
    userDetails: GetUserResponse
}

export const EditTeamMembers: FC<EditMemberProps> = ({ userDetails }) => {
    const tradeId = getTradeId();

    const { users, updateTeamMemberDetail } = useAppSelector((state: ApplicationState) => {
        return {
          users: state.tradeUsers.tradeUsers,
          updateTeamMemberDetail: state.updateTeamMemberDetails.updateTeamMemberSuccessful,
        }
    });

    const history = useHistory();
    const dispatch = useDispatch();
    const dispatcher = {
      getUsers: (id: string) => {
        dispatch(
          GetTradeUsersActions.getTradeUsersRequest({ request: { tradeId: id } })
        );
      },
      getUserDetails: () => {
        dispatch(
          LoginActions.getUserDetails()
        );
      },
      resetUpdateTeamMemberDetail: () => {
        dispatch(
          UpdateTeamMemberActions.putTeamMemberDetailsReset()
        );
      },
    }

    //effects
    useEffect(() => {
      getUsers();
    }, []);
    
    useEffect(() => {
      if (updateTeamMemberDetail) {
        getCurrentUserDetails();
        resetUpdateTeamUserDetail();
      }
    }, [updateTeamMemberDetail])

    const getUsers = (): void => {
      dispatcher.getUsers(tradeId!);
    };

    const resetUpdateTeamUserDetail = (): void => {
      dispatcher.resetUpdateTeamMemberDetail();
    }

    const getCurrentUserDetails = (): void => {
      if (id === `${userDetails.id}`) {
        dispatcher.getUserDetails()
      }
    }

    const { id } = useParams();
    let editUser: UserData | undefined = undefined;
    let editUserDetails: GetUserResponse | undefined = undefined;
    if (users) {
        editUser = users.content.find((user: UserData) => `${user.id}` === id);
    }
    if (editUser) {
        editUserDetails = {
            ...editUser,
            userDocuments: userDetails.userDocuments
        }
    }
    return (
        editUserDetails ? <DashboardWrapperComponent
          navType={TopNavType.Team}
          activeLink={TopNavTeamLink.People}
          userDetails={editUserDetails}
        > 
            <Box mt={3}>
                <Box onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
                    <ArrowBack />
                </Box>
                <ProfileSettings userDetails={editUserDetails} updatingTeamMembers={true} />
            </Box>
        </DashboardWrapperComponent> : null
    )
}