import { ServiceType } from "../ServiceType.data";
import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import {
  GetJobsRequest,
  GetJobByIdRequestPayload,
  GetUsersByWorkorderTradeCategoryRequest,
  ConfirmJobBookingRequestPayload,
  ChangeJobStatusRequestPayload,
  AddArtefactsRequest,
  AddJobVariationRequest,
  JobCostUpdateRequest,
  GetJobsResponse,
} from "./JobsData.data";
import { getTradeId } from "../../helper/Auth";
import { MultipartFormData } from "../MultipartFormData.data";

const serviceType = ServiceType.TradesBff;
const jobBffServiceType = ServiceType.JobsBff;
const jobServiceType = ServiceType.Jobs;
const tradeServiceType = ServiceType.Trade;
const quoteVarianceType = ServiceType.QuoteVariance;

// /api/trade/{tradeId}/trade-categories/{tradeCategoryName}/users
const TRADE_CATEGORIES = "trade-categories";
const JOB = "job";
const USERS = "users";
const BOOKING = "booking";
const STATUS = "status";
const ARTEFACTS = "artefacts";
const JOB_COST = "job-cost";

export class JobsService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);
  private readonly jobBffService: ApiServiceBase = new ApiService(
    jobBffServiceType
  );
  private readonly jobService: ApiServiceBase = new ApiService(jobServiceType);
  private readonly tradeService: ApiServiceBase = new ApiService(
    tradeServiceType
  );
  private readonly quoteVarianceService = new ApiService(quoteVarianceType);

  public post(
    payload: GetJobsRequest,
    unpaginated?: boolean
  ): Promise<GetJobsResponse> | GetJobsResponse {
    const { paginationFilters, ...body } = payload;
    return this.service.post(
      {
        route: [getTradeId()!, JOB],
        query: unpaginated
          ? { unpaged: true }
          : {
              page: paginationFilters!.page,
              size: paginationFilters!.size,
              sort: paginationFilters!.sort,
            },
      },
      body
    );
  }

  public getUsingId(request: GetJobByIdRequestPayload): Promise<void> | void {
    return this.jobBffService.get({ route: [request.jobId] });
  }

  public getUsersByWorkorderTradeCategory(
    request: GetUsersByWorkorderTradeCategoryRequest
  ): Promise<void> | void {
    const { workorderTradeCategory } = request;
    return this.tradeService.get({
      route: [getTradeId()!, TRADE_CATEGORIES, workorderTradeCategory, USERS],
    });
  }

  public confirmJobBooking(
    request: ConfirmJobBookingRequestPayload
  ): Promise<void> | void {
    const { id, ...body } = request;
    return this.jobService.patch({ route: [id, BOOKING] }, body);
  }

  public changeJobStatus(
    request: ChangeJobStatusRequestPayload
  ): Promise<void> | void {
    const { id, status, notes } = request;
    return this.jobService.patch({ route: [id, STATUS] }, { status, notes });
  }

  public addArtefacts(request: AddArtefactsRequest): Promise<void> | void {
    const { jobId, extractInvoiceData, ...requestData } = request;

    const data: MultipartFormData[] = [];
    for (const key in requestData) {
      if (request.hasOwnProperty(key)) {
        data.push({
          name: key,
          content: request[key],
        });
      }
    }

    return this.jobService.patchMultipart(
      {
        route: [jobId, ARTEFACTS],
        query: extractInvoiceData ? { extractPdf: true } : undefined,
      },
      data
    );
  }

  // Job variance
  public addJobVariance(request: AddJobVariationRequest): Promise<void> | void {
    const { parentJobId, ...requestBody } = request;

    return this.quoteVarianceService.post(
      { route: [parentJobId] },
      requestBody
    );
  }

  // Update job cost
  public updateJobCost(request: JobCostUpdateRequest): Promise<void> | void {
    const { jobId, ...requestBody } = request;

    return this.jobService.patch({ route: [jobId, JOB_COST] }, requestBody);
  }
}

const quotesService = new JobsService();
export default quotesService;
