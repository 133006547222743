import { makeStyles } from "@material-ui/core";
import { color } from "../../../../theme/Theme";

export const AgencyBranchDetailsSidebarStyles = makeStyles({
  agencyImage: {
    width: "4rem",
    height: "4rem",
  },
  listItemText: {
    wordBreak: "break-word",
  },
  agencyName: {
    fontSize: "23.3px",
    color: color.secondary200,
    letterSpacing: '.25px'
  },
});
