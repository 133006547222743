import { LoadingIndicatorState } from "../state/LoadingIndicatorState";
import {
  LoadingIndicatorAction,
  LoadingIndicatorActionTypes,
} from "../actions/LoadingIndicatorAction";

export const initialState: LoadingIndicatorState = {
  isLoading: false,
  counter: 0,
};

export const loadingIndicatorReducer = (
  state = initialState,
  action: LoadingIndicatorAction
) => {
  switch (action.type) {
    case LoadingIndicatorActionTypes.ShowIndicator:
      return {
        ...state,
        isLoading: true,
        counter: state.counter + 1,
      };

    case LoadingIndicatorActionTypes.HideIndicator:
      const updatedCounter = state.counter - 1 >= 0 ? state.counter - 1 : 0;
      return {
        ...state,
        isLoading: updatedCounter !== 0,
        counter: updatedCounter,
      };
    default:
      return state;
  }
};
