import { QuoteStatus } from "../../../../models/quoteStatus/QuoteStatus.data";

export enum QuotesFilterStatus {
  Available = "New quotes",
  Submitted = "Submitted quotes",
  Unsuccessful = "Unsuccessful quotes",
}

export const QuotesFilterStatusChips = [
  {
    text: QuotesFilterStatus.Available,
    value: QuoteStatus.Pending,
  },
  {
    text: QuotesFilterStatus.Submitted,
    value: QuoteStatus.Sent,
  },
  {
    text: QuotesFilterStatus.Unsuccessful,
    value: QuoteStatus.Rejected,
  },
];
