/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable default-case */
// /* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useReducer, useRef, useState } from "react";
import { set } from "lodash";
import { ElasticQueryIndex } from "../services/elasticSearch/ElasticSearch";
import { getInitialState } from "../helper/ElasticSearchHelper";

// Uncomment when needed
// const nestedQueryHighlightObjectInnerHits = {
//   highlight: {
//     fields: {
//       "*": {},
//     },
//     number_of_fragments: 0,
//     pre_tags: ["<b>"],
//     post_tags: ["</b>"],
//   },
// };

export interface ElasticQueryState {
  must: any;
  should: any;
  filter: any;
  size?: number;
  searchAfter?: number;
  must_not?: any;
  sort?: any;
  highlight?: any;
  terms?: any;
  minimum_should_match?: number | string;
}

const reducer = (
  state: ElasticQueryState,
  action: Partial<ElasticQueryState>
): ElasticQueryState => {
  // If action doesn't contain searchAfter, some other parameter has changed so setting it to undefined

  return {
    ...state,
    ...action,
    searchAfter: action.searchAfter,
  };
};

const extractMeta = (objArray: any) => {
  return objArray.map((obj: any) => {
    const { meta, ...data } = obj;
    return { ...data };
  });
};

const generateQuery = (state: ElasticQueryState) => {
  const {
    must,
    should,
    size,
    filter,
    searchAfter,
    highlight,
    sort,
    must_not,
    minimum_should_match,
  } = state;

  const updatedQuery = {
    query: {
      bool: {
        minimum_should_match,
        must: extractMeta(must),
        should: extractMeta(should),
        filter: extractMeta(filter),
        must_not,
      },
    },
    sort,
    highlight,
    size,
  };

  if (searchAfter) {
    set(updatedQuery, "search_after", [searchAfter]);
  }

  return updatedQuery;
};

export const useElasticQuery = ({
  index,
  initialStateValues,
}: {
  index: ElasticQueryIndex;
  // To upate the inital query/state for the index on initial call itself
  // pass the keys to be updated here
  initialStateValues?: Partial<ElasticQueryState>;
}) => {
  const [state, setState] = useReducer(reducer, {
    ...getInitialState(index),
    ...(initialStateValues || {}),
  });
  const [query, setQuery] = useState<any>(
    generateQuery({
      ...getInitialState(index),
      ...(initialStateValues || {}),
    })
  );
  const [totalResponses, setTotalResponses] = useState<number | undefined>(
    undefined
  );
  const [searchedTill, setSearchedTill] = useState<number | undefined>(
    undefined
  );

  // Purpose: to reset query to baseQuery for cases where we don't want to keep adding on condtions
  // instead want a fresh query. This will also be used to check for preventing additional API call
  // since most component will be making api call on change of "query" exported from hook.
  // if this flag is set, we can skip the call on change of query IF we want.
  const [resetFlag, setResetFlag] = useState(false);

  // counter to keep ineer hits' name property unique
  // else for same key in inner hit, it gives an error that key already exists
  // const innerHitNameCounter = useRef(0);

  // for preventing query update on initial effect
  const mounted = useRef(false);

  // make sure that all set: setMust/filter/should create a new array object for effect to trigger
  useEffect(() => {
    if (mounted.current) {
      setQuery(generateQuery(state));
    }
  }, [state]);

  // must stay at last
  useEffect(() => {
    mounted.current = true;
  }, []);

  // Remove query parts, the components will have to pass reference to the query element as argument
  // since the use case will usually be with multiple filters being applied removing is easy with binding reference to filter chip kind of UI
  const removeMust = (remove: any) => {
    const { must } = state;
    setState({ must: must.filter((m: any) => m !== remove) });
  };

  const addToMust = (mustBlock: any) => {
    const { must } = state;
    setState({ must: [...must, mustBlock] });
  };

  const removeFilter = (remove: any) => {
    const { filter } = state;
    setState({ filter: filter.filter((f: any) => f !== remove) });
  };

  const removeShould = (remove: any) => {
    const { should } = state;
    setState({ should: should.filter((s: any) => s !== remove) });
  };

  const setSearchAfter = (id: number) => {
    setState({ searchAfter: id });
  };

  const resetQuery = () => {
    setState({ ...getInitialState(index) });
    setResetFlag(true);
  };

  const removeResetFlag = () => {
    setResetFlag(false);
  };

  const onViewMore = () => {
    setState({
      searchAfter: searchedTill,
    });
  };

  return {
    must: state.must,
    should: state.should,
    filter: state.filter,
    terms: state.terms,
    query,
    removeMust,
    removeFilter,
    removeShould,
    setSearchAfter,
    resetQuery,
    removeResetFlag,
    resetFlag,
    onViewMore,
    totalResponses,
    setTotalResponses,
    setSearchedTill,
    addToMust,
    state,
    setState,
    searchAfter: state.searchAfter,
    searchedTill,
  };
};
