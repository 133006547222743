import React, { FC } from "react";
import RegistrationStepsWrapper from "../registrationStepsComponents/registrationStepsWrapper/RegistrationStepsWrapper";
import { LABELS } from "./AddTradeCategoriesConstants";
import ContentBoxSection from "../registrationStepsComponents/contentBox/ContentBoxSection";
import ContentBoxSubheading from "../registrationStepsComponents/contentBox/ContentBoxSubheading";
import TopTradeCategories from "./topTradeCategories/TopTradeCategories";
import {
  GetTradeCategoriesResponse,
  TradeCategory,
} from "../../services/tradeCategories/TradeCategories.data";
import SelectedTradeCategories from "./selectedTradeCategories/SelectedTradeCategories";
import AllTrades from "./allTrades/AllTradeCategories";
import { Typography } from "@material-ui/core";
import { AddTradeCategoriesStyles } from "./AddTradeCategoriesStyles.data";

interface Props {
  tradeCategories: GetTradeCategoriesResponse;
  error: Error | null;
  selectedTradeCategories: TradeCategory[];
  addCategory: (category: TradeCategory) => void;
  removeCategory: (id: number) => void;
  onSubmit: () => void;
}

const AddTradesComponent: FC<Props> = (props) => {
  const {
    tradeCategories,
    addCategory,
    selectedTradeCategories,
    removeCategory,
    onSubmit,
  } = props;

  const classes = AddTradeCategoriesStyles();
  const mostUsed = tradeCategories.filter((trade) => trade.topCategory);
  return (
    <RegistrationStepsWrapper
      heading={LABELS.heading}
      subheading={LABELS.subheading}
      primaryButton={{
        text: "Next",
        action: onSubmit,
        disabled: selectedTradeCategories.length === 0,
      }}>
      <ContentBoxSection noSidePadding heading={LABELS.addTrades}>
        {/* Most Used Categories */}
        <ContentBoxSubheading
          containerClass={classes.spaceTop}
          variant={"caption"}
          noBorder
          text={LABELS.mostUsed}
        />
        <TopTradeCategories
          tradeCategories={mostUsed}
          onCardClick={addCategory}
          selectedTradeCategories={selectedTradeCategories}
        />

        {/* "All trades" dropdown */}
        <AllTrades
          tradeCategories={tradeCategories}
          addCategory={addCategory}
        />

        {/* Selected Trades */}
        <ContentBoxSubheading
          containerClass={classes.spaceTop}
          variant="caption"
          text={LABELS.yourTrades}
          noBorder
        />
        <SelectedTradeCategories
          removeCategory={removeCategory}
          selectedTradeCategories={selectedTradeCategories}
        />
      </ContentBoxSection>

      {/* Add more trades/ No trades selected message */}
      <Typography variant={"body2"}>
        {selectedTradeCategories.length > 0
          ? LABELS.addMoreTrades
          : LABELS.noTradesSelected}
      </Typography>
    </RegistrationStepsWrapper>
  );
};

export default AddTradesComponent;
