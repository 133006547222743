import * as Yup from "yup";
import { passwordRex } from "../../helper/Validation";

export const LABELS = {
  success: "Password updated!",
  allDoneMessage:
    "All done. Your password has been updated.\n You can now log in.",
  passwordUpdated: "Password successfully updated!",
  resetPassword: "Reset password",
  nearlyDoneMessage: "Nearly done. Just enter a new password and that's it!",
  newPassword: "New password",
  confirmNewPassword: "Confirm new password",
  login: "Log in",
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .required("This field is required")
    .matches(passwordRex, "Password doesn't fulfil the requirements"),
  confirmPassword: Yup.string()
    .required("This field is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export interface ResetPasswordFormData {
  password: string;
  confirmPassword: string;
}
