import React, { FC } from "react";
import { GetTradeSuccessPayload } from "../../../../../services/listBranches/ListBranchesServiceData.data";
import { Formik, Field } from "formik";
import { Grid, Box, useTheme, useMediaQuery } from "@material-ui/core";
import RenderImageInput from "../../../../RenderImageInput/RenderImageInput";
import RenderInput from "../../../../FormikComponents/RenderInputField";
import {
  LABELS,
  formFields,
  VALIDATION_SCHEMA,
} from "./UpdateTradeFormConstants.data";
import DashboardButton from "../../../dashboardButton/DashboardButton";
import { TradeInfo } from "../../../../../models/trade/Trade.data";
import {
  inputStylePropsGenerator,
  RenderInputFieldStyles,
} from "../../../../FormikComponents/RenderInputFieldStyle";
import { BUSINESS_TYPES } from "../../../../registrationForm/RegistrationFormData.data";
import RenderSelect from "../../../../FormikComponents/RenderSelect";

interface Props {
  tradeDetails: GetTradeSuccessPayload;
  onUpdateTradeDetails: (values: TradeInfo) => void;
}

const UpdateTradeForm: FC<Props> = (props) => {
  const { onUpdateTradeDetails } = props;
  const { name, logoKey, tradeTypeId } = props.tradeDetails;

  const renderInputProps = inputStylePropsGenerator(RenderInputFieldStyles());

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.only("xs"));

  const initialValues = {
    name,
    logoKey,
    tradeTypeId,
  };

  return (
    initialValues && (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(formValues, { resetForm }) => {
          onUpdateTradeDetails(formValues);
          resetForm({ values: formValues });
        }}
        validationSchema={VALIDATION_SCHEMA}>
        {({ handleSubmit, setFieldValue, values, isValid, dirty }) => {
          const disabled = !dirty || !isValid;

          return (
            <>
              <Grid container>
                <Grid style={{ width: "100%" }} item md={12}>
                  <Box px={2}>
                    <Grid container>
                      <Grid item sm={12} md={4} lg={3}>
                        <Box mb={2}>
                          <Field
                            name={formFields.image}
                            component={RenderImageInput}
                            label={LABELS.image}
                            onChange={(image) =>
                              setFieldValue("logoKey", image)
                            }
                            {...renderInputProps}
                            src={
                              values.logoKey
                                ? typeof values.logoKey === "string"
                                  ? values.logoKey
                                  : URL.createObjectURL(values.logoKey)
                                : ""
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={8} lg={9}>
                        <Box>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <Box pr={isSmall ? 0 : 2}>
                                <Field name={formFields.name}>
                                  {({ form, field }) => (
                                    <RenderInput
                                      label={LABELS.businessName}
                                      {...renderInputProps}
                                      {...field}
                                    />
                                  )}
                                </Field>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Box mb={isSmall ? 2 : 0} pl={isSmall ? 0 : 2}>
                                <Field name={formFields.type}>
                                  {({ form, field }) => (
                                    <RenderSelect
                                      label="Business type"
                                      options={BUSINESS_TYPES}
                                      {...field}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "tradeTypeId",
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                </Field>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" pr={2}>
                <DashboardButton
                  text="Save"
                  action={handleSubmit}
                  variant={disabled ? "outlined" : "contained"}
                  lightText={!disabled}
                  greyText={disabled}
                  disabled={disabled}
                />
              </Box>
            </>
          );
        }}
      </Formik>
    )
  );
};

export default UpdateTradeForm;
