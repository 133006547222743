export const LABELS = {
  heading: "Profile",
  updateDetails: "Update details",
  updateImage: "Update image",
  personalInformation: "Personal Information",
  security: "Security",
  trades: "Trades",
  update: "Update",
  documents: "Documents",
  onceSubmittedLabel:
    "Once submitted it will be passed on to the agent for consideration.",
  expired: "Expired",
  valid: "Valid",
  expiredTradeWarning:
    "A trade has expired - you must upload valid documents to continue working in this trade",
};
