import React, { FC } from "react";
import { makeStyles } from "@material-ui/core";
import { MouseOrTouchEvent } from "react-select/lib/Select";

const useStyles = makeStyles({
  actionContainer: {
    marginLeft: "1.5em",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
});

interface Props {
  children: JSX.Element;
  onClick?: (e?: MouseOrTouchEvent) => void;
}

const DashboardListItemAction: FC<Props> = (props) => {
  const classes = useStyles();
  const { children, onClick } = props;
  return (
    <div
      onClick={onClick ? onClick : undefined}
      className={classes.actionContainer}>
      {children}
    </div>
  );
};

export default DashboardListItemAction;
