import { makeStyles } from "@material-ui/core";
import { color } from "../../../theme/Theme";

export const QuoteDetailsStyles = makeStyles({
  formHeading: {
    fontSize: "18.1px",
    fontWeight: 500,
  },
  detailsBox: {
    paddingLeft: "1rem",
  },
  inline: {
    display: "inline",
  },
  costListItem: {
    alignItems: "baseline",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${color.lightGrey}`,
    padding: "1rem",
    paddingLeft: "0",
  },
  costListItemFirst: {
    borderTop: `1px solid ${color.lightGrey}`,
  },
  totalCost: {
    paddingLeft: "1rem",
  },
  includesGSTLabel: {
    fontWeight: 600,
    marginLeft: ".5em",
  },
});
