import React, { FC } from "react";
import { useDispatch } from "react-redux";
import ForgotPasswordComponent from "../../../component/forgotPassword/ForgotPassword";
import { ForgotPasswordPayload } from "../../../services/login/LoginData";
import { LoginActions } from "../../../store/actions/LoginActions";

const ForgotPassword: FC<{}> = ({}) => {
  // hooks
  const dispatch = useDispatch();

  // functions and variables
  const dispatcher = {
    postForgotPasswordRequest: (data: ForgotPasswordPayload) => {      
      dispatch(
        LoginActions.postForgotPasswordLinkStart(data)
      );
    },
  };

  return (
    <ForgotPasswordComponent
      postForgotPasswordRequest={dispatcher.postForgotPasswordRequest}
    />
  );
};

export default ForgotPassword;
