import React, { FC } from "react";
import UpdateBranchForm from "./UpdateBranchForm";
import { BranchResponse } from "../../../../../services/branch/Branch.data";
import { OfficeDetails } from "../../../../../services/registerBusiness/RegistrationFormRequest.data";
import { UpdateBranchFormFields } from "./UpdateBranchFormData.data";

interface Props {
  selectedBranch: BranchResponse;
  onUpdateBranchDetails: (data: UpdateBranchFormFields) => void;
}

const UpdateBranches: FC<Props> = (props) => {
  const { selectedBranch, onUpdateBranchDetails } = props;

  return (
    <>
      {/* Branch form */}
      <UpdateBranchForm
        onSubmit={onUpdateBranchDetails}
        initialValues={selectedBranch as OfficeDetails}
      />
    </>
  );
};

export default UpdateBranches;
