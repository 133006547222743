import React, { FC, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import { Grid, useMediaQuery, useTheme, Box } from "@material-ui/core";
import TopNav from "../topNav/TopNav";
import { GetUserResponse } from "../../services/login/LoginData";
import { TopNavType, TopNavLink } from "../topNav/TopNav.data";
import { Menu } from "@material-ui/icons";
import { color } from "../../theme/Theme";
import { DashboardWrapperComponentStyles } from "./DashboardWrapperComponentStyles.data";

interface Props {
  userDetails?: GetUserResponse;
  children?: any;
  activeLink: TopNavLink;
  navType: TopNavType;
}

const DashboardWrapperComponent: FC<Props> = (props) => {
  const classes = DashboardWrapperComponentStyles();
  const { children, activeLink, navType, userDetails } = props;
  const [showSidebar, setShowSidebar] = useState<Boolean>(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return userDetails ? (
    <Grid container className={classes.container}>
      {(!smallScreen || showSidebar) && (
        <Grid className={classes.sidebarContainer} item>
          <Sidebar hideSideBar={() => setShowSidebar(false)} />
        </Grid>
      )}
      {smallScreen && (
        <Box
          display="flex"
          justifyContent="space-between"
          bgcolor={color.primary}
          width="100%"
          color={color.light}
          p={2}
          pb={1}>
          <Menu
            onClick={() => setShowSidebar(true)}
            color="inherit"
            className={classes.pointer}
          />
        </Box>
      )}
      <Grid className={classes.contentContainer} item>
        <TopNav type={navType} activeLink={activeLink} />
        <Grid justify="center" container className={classes.bodyWrapper}>
          <Grid item xs={12} sm={11} md={10} lg={8} xl={7}>
            {children || null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default DashboardWrapperComponent;
