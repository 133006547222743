import makeStyles from "@material-ui/core/styles/makeStyles";

export const TermsAndCondtionsStyles = makeStyles({
  laregIcons: {
    width: "64px",
    height: "64px",
  },
  cancel: {
    "@media all and (max-width: 383px)": {
      margin: "0 0 8px 0 !important",
      display: "block",
    },
  },

  button: {
    display: "inline-block",
    "@media all and (max-width: 530px)": {
      margin: "0 0 8px 0 !important",
      display: "block",
      textAlign: "center",
    },
  },

  buttonContainer: {
    margin: "1rem 0",
    display: "flex",
    justifyContent: "space-between",
    "@media all and (max-width: 530px)": {
      display: "block",
    },
  },

  termsContainer: {
    height: "320px",
    overflow: "auto",
  },
});
