import React, { FC, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  makeStyles,
  Button,
  Typography,
} from "@material-ui/core";
import { LABELS } from "./TradeCategoriesFormConstants.data";
import {
  GetTradeCategoriesResponse,
  TradeCategory,
} from "../../../../../services/tradeCategories/TradeCategories.data";
import AllTrades from "../../../../addTrades/allTrades/AllTradeCategories";
import SelectedTradeCategories from "../../../../addTrades/selectedTradeCategories/SelectedTradeCategories";
import ContentBoxSubheading from "../../../../registrationStepsComponents/contentBox/ContentBoxSubheading";
import { color } from "../../../../../theme/Theme";

interface Props {
  tradeCategories: GetTradeCategoriesResponse;
  open: boolean;
  handleClose: () => void;
  addUserTradeCategories: (selectedTradeCategories: TradeCategory[]) => void;
}

const useStyles = makeStyles({
  buttonsSection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1.5em",
  },
  navButton: {
    width: "124px",
    height: "32px",
  },
  subheading: {
    color: color.secondary500,
  },
});

const AddUserTradeCategoriesModal: FC<Props> = (props) => {
  const { handleClose, addUserTradeCategories, tradeCategories, open } = props;
  const [selectedTradeCategories, setSelectedTradeCategories] = useState<
    TradeCategory[]
  >([]);
  const classes = useStyles();

  // Functions
  const selectTradeCategory = (category: TradeCategory) => {
    if (
      selectedTradeCategories.filter((trade) => trade.id === category.id)
        .length === 0
    ) {
      setSelectedTradeCategories([...selectedTradeCategories, category]);
    }
  };

  const removeTradeCategory = (id: number) => {
    setSelectedTradeCategories([
      ...selectedTradeCategories.filter((trade) => trade.id !== id),
    ]);
  };

  return (
    <Dialog open={open}>
      <Box p={2}>
        <DialogTitle>{LABELS.addTradeDialogHeading}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className={classes.subheading} variant="caption">
              {LABELS.addTradeCategoriesSubheading}
            </Typography>
          </DialogContentText>
          <AllTrades
            tradeCategories={tradeCategories}
            addCategory={selectTradeCategory}
          />
          {/* Selected Trades */}
          <Box mt={2}>
            <ContentBoxSubheading
              variant="caption"
              text={LABELS.selectedTradeCategories}
              noBorder
            />
            <SelectedTradeCategories
              removeCategory={removeTradeCategory}
              selectedTradeCategories={selectedTradeCategories}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <section className={classes.buttonsSection}>
            <Button
              color="secondary"
              onClick={handleClose}
              className={classes.navButton}>
              {"Cancel"}
            </Button>
            <Button
              color="primary"
              onClick={() => addUserTradeCategories(selectedTradeCategories)}
              className={classes.navButton}>
              {"Add"}
            </Button>
          </section>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddUserTradeCategoriesModal;
