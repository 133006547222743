import { TradeState } from "../state/TradeState";
import {
  TradeAction,
  TradeActionType,
  TradeActionPayload,
} from "../actions/TradeActions";
import { GetTradeAgenciesResponse, PatchTermsResponseRequest } from "../../services/tradeService/TradeServiceData.data";

const initialState: TradeState = {
  agencies: null,
  error: null,
};

export function tradeReducer(state = initialState, action: TradeAction) {
  const data = action.data as TradeActionPayload;
  switch (action.type) {
    case TradeActionType.GetTradeAgenciesSuccess:
      return {
        agencies: data as GetTradeAgenciesResponse,
        error: null,
      } as TradeState;

    case TradeActionType.GetTradeAgenciesError:
      return {
        agencies: state.agencies,
        error: data as Error,
      } as TradeState;

    case TradeActionType.UpdateTermsResponseError:
      return {
        ...state,
        error: data as Error,
      } as TradeState;

    case TradeActionType.UpdateTermsResponseSuccess:
      const { termId, accepted } = data as PatchTermsResponseRequest;
      return {
        agencies: {...state.agencies, content: state.agencies!.content.map(ag => {
          if (ag.termsDetail.id === termId) {
            return {
              ...ag,
              termsDetail: { ...ag.termsDetail, accepted },
            };
          } else {
            return ag;
          }
        })} as GetTradeAgenciesResponse
        ,
        error: null,
      } as TradeState;

    default:
      return state;
  }
}
