export const LABELS = {
  agents: "Agents",
  workorderDetails: "Workorder details",
  requested: "Requested",
  dueDate: "Due Date",
  jobId: "Job ID",
  maxBudget: "Max Budget",
  jobRequested: "Trade/job requested",
  pass: "Pass",
  quote: "Quote",
  addressOnApproval: "Address on quote approval",
  contactDetails: "Contact details",
  tenantDetailsOnApproval: "Tenant details on quote approval",
  tenantContact: "Tenant contact",
  requestDetails: "Request details",
  bookingDetails: "Booking details",
  applianceRelated: "Appliance related",
  make: "Make",
  model: "Model",
  warrantyAvailable: "Warranty available",
  additionalDetails: "Additional details",
  quoteDueDate: "Quote due date",
  jobDueDate: "Job due date",
  requestedDate: "Requested date",
  edit: "Edit",
};
