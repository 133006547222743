import React, { FC, useRef, useState } from "react";
import { Quote } from "../../../../models/quote/Quote.data";
import { Property } from "../../../../models/property/Property.data";
import PropertyListItem from "../../../lisItems/PropertyListItem";
import {
  Paper,
  Box,
  IconButton,
  Grid,
  Divider,
  ListItemText,
  ListItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import theme, { color } from "../../../../theme/Theme";
import { LABELS } from "./SubmitQuoteComponentConstants.data";
import { SubmitQuoteRequest } from "../../../../services/quotes/QuotesData.data";
import {
  getAddressString,
  getContactDetails,
  getWorkOrderById,
  titleCase,
} from "../../../../helper/HelperFunctions";
import SubmitQuoteForm from "../../submitQuoteForm/SubmitQuoteForm";
import WorkorderDetails from "../../workorderDetails/WorkorderDetails";
import CommentsSection from "../../../commentsSection/CommentsSection";
import { CommentResourceType } from "../../../../models/comment/Comment";
import StyledExpansionPanel from "../../../../component/styledExpansionPanel/StyledExpansionPanel";
import ContactCard, {
  Contact,
} from "../../../../component/contactCard/ContactCard";

import DetailsSidebar from "../../../detailsSidebar/DetailsSideBar";
import { TopNavType } from "../../../topNav/TopNav.data";
import { TopNavDashboardLink } from "../../../topNav/TopNavDashboardLink.data";
import ContentHeadingSection from "../../contentHeadingSection/ContentHeadingSection";
import DashboardWrapperComponent from "../../DashboardWrapperComponent";
import { GetUserResponse } from "../../../../services/login/LoginData";
import QuoteDetailsSideBar from "../quoteDetailsSidebar/QuoteDetailsSidebar";
import { QuoteStatus } from "../../../../models/quoteStatus/QuoteStatus.data";
import { Print } from "@material-ui/icons";
import { useReactToPrint } from "react-to-print";
import PrintQuotePdfComponent from "./printQuote/PrintQuotePdf";
import { SubmitQuoteComponentStyles } from "./SubmitQuoteComponentStyles.data";
import { LocalMarketplaceSummary } from "../../../../models/maintenance-request/MaintenanceRequest.data";
import DocGrid from "../../../docGrid/DocGrid";
import DashboardButton from "../../dashboardButton/DashboardButton";

interface Props {
  quote: Quote;
  property: Property;
  onSubmit: (values: SubmitQuoteRequest) => void;
  userDetails: GetUserResponse;
  onPassQuote: (quoteId: string) => void;
}

const SubmitQuoteComponent: FC<Props> = (props) => {
  // state
  const [showFullScreenSidebar, setShowFullScreenSidebar] =
    useState<boolean>(false);

  // props
  const {
    quote: {
      workOrderId,
      maintenanceRequestResponse: {
        details: { title, description, extendedData, images },
        workOrders,
        tenantIds,
      },
      status,
    },
    property: { postcode, state, suburb, address },
    quote,
    property,
    onSubmit,
    userDetails,
    onPassQuote,
  } = props;

  const {
    tenants: { allAssignedTenants },
    managingAgent,
    propertyUsers,
  } = property;

  const summaryData: LocalMarketplaceSummary[] =
    extendedData && extendedData["featureDetails"]
      ? (extendedData["featureDetails"] as LocalMarketplaceSummary[]).filter(
          (data) => data.type === "answers"
        )
      : [];

  const {
    comments,
    propertyAccess: { accessKeyNumber },
    tenants,
    alternateContact,
  } = getWorkOrderById(workOrders, workOrderId);

  const additionalContactList = getContactDetails(
    tenants || [],
    allAssignedTenants
  );

  const users: Contact[] = propertyUsers
    .filter((u) => tenantIds.includes(u.id))
    .map((u) => ({
      id: u.id,
      name: u.name,
      email: u.email,
      phone: u.phone,
      surname: u.surname,
    }));

  // hooks
  const docRef = useRef<HTMLDivElement>(null);
  const classes = SubmitQuoteComponentStyles();

  // functions and variables
  const handlePrint = useReactToPrint({
    content: () => docRef.current,
    removeAfterPrint: true,
    onAfterPrint: () => {
      if (docRef.current) docRef.current.className = classes.hide;
    },
    onBeforeGetContent: () => {
      if (docRef.current) docRef.current.className = "";
    },
  });

  const isPrivate =
    quote.maintenanceRequestResponse.privateWorksDetails &&
    quote.maintenanceRequestResponse.privateWorksDetails.isPrivate;

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box display="flex" flexDirection="row">
      {!showFullScreenSidebar ? (
        <>
          <Box flexGrow="1">
            <DashboardWrapperComponent
              navType={TopNavType.Dashboard}
              activeLink={TopNavDashboardLink.Quotes}
              userDetails={userDetails}>
              <>
                <Box mt={downSm ? 2 : 0} px={2}>
                  <ContentHeadingSection
                    heading={LABELS.quote}
                    optionsSection={
                      <Box display="flex" alignItems="center">
                        {/* More details button */}
                        {downSm && (
                          <Box mr={2}>
                            <DashboardButton
                              action={() => setShowFullScreenSidebar(true)}
                              text="More details"
                            />
                          </Box>
                        )}
                        <IconButton
                          onClick={handlePrint}
                          color="primary"
                          component="span">
                          <Print />
                        </IconButton>
                      </Box>
                    }
                  />
                </Box>
                {/* Main content */}
                <Box mt={4} p={2}>
                  {/* Property Card */}
                  <Paper>
                    <Box p={1}>
                      <PropertyListItem
                        name={
                          address
                            ? getAddressString(property)
                            : LABELS.addressOnApproval
                        }
                        location={`${titleCase(suburb)}${
                          suburb && ","
                        } ${state}${state && ","} ${postcode}`}
                        map={!!address}
                      />
                    </Box>
                  </Paper>
                  {/* Workorder Details */}
                  <Box
                    borderRadius="2px"
                    bgcolor={color.wildSand}
                    pl={2}
                    mt={2}>
                    <WorkorderDetails
                      title={title}
                      description={description}
                      workOrderId={workOrderId}
                      notes={comments}
                      workorderKeyNumber={accessKeyNumber}
                    />
                  </Box>

                  <Box mb={1} mt={2}>
                    <StyledExpansionPanel
                      defaultExpanded
                      heading={
                        isPrivate
                          ? LABELS.bookingImages
                          : LABELS.maintenanceRequestImages
                      }>
                      <Box p={2} pt={0}>
                        {!!images.length ? (
                          <DocGrid docs={images} />
                        ) : (
                          <Typography variant="caption" color="textSecondary">
                            No images
                          </Typography>
                        )}
                      </Box>
                    </StyledExpansionPanel>
                  </Box>

                  <Box className={classes.expansionSection}>
                    <StyledExpansionPanel
                      defaultExpanded={true}
                      heading={"Contact Details"}>
                      <Box pl={2}>
                        <Divider />
                        <Box pt={1}>
                          <Grid container>
                            {managingAgent && !isPrivate && (
                              <Grid item xs={12} sm={6}>
                                <ContactCard
                                  person={managingAgent}
                                  designation={"Property manager"}
                                />
                              </Grid>
                            )}

                            {additionalContactList.map((contact) => (
                              <Grid item xs={12} sm={6}>
                                <ContactCard
                                  person={contact}
                                  designation={"Contact"}
                                />
                              </Grid>
                            ))}

                            {alternateContact && (
                              <Grid item xs={12} sm={6}>
                                <ContactCard
                                  person={{
                                    email: alternateContact.email || "N/A",
                                    name: alternateContact.name,
                                    phone: alternateContact.contact,
                                    surname: "",
                                  }}
                                  designation={"Contact"}
                                />
                              </Grid>
                            )}

                            {users.map((contact) => (
                              <Grid item xs={12} sm={6}>
                                <ContactCard
                                  person={contact}
                                  designation={"Customer"}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </StyledExpansionPanel>
                  </Box>

                  {summaryData && summaryData.length > 0 && (
                    <Box className={classes.expansionSection}>
                      <StyledExpansionPanel
                        defaultExpanded
                        heading={"Options Summary"}>
                        <Box pl={2}>
                          <Divider />
                          <Box pt={1}>
                            <Grid container>
                              {summaryData && summaryData.length > 0 && (
                                <Grid item xs={12} sm={6}>
                                  {summaryData
                                    .filter(
                                      (data) => data.labelId !== "MediaUpload"
                                    )
                                    .map((data) => (
                                      <ListItem
                                        className={classes.optionListStyle}>
                                        <ListItemText
                                          primary={
                                            <Typography variant="h4">
                                              {data.label}
                                            </Typography>
                                          }></ListItemText>
                                        <ListItemText
                                          primary={
                                            <Typography
                                              color="textSecondary"
                                              variant="caption">
                                              {data.value}
                                            </Typography>
                                          }></ListItemText>
                                      </ListItem>
                                    ))}
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Box>
                      </StyledExpansionPanel>
                    </Box>
                  )}

                  {/* Quote Details Form */}
                  <Box mt={4}>
                    <SubmitQuoteForm
                      fillExisting
                      submitQuoteButtonText={
                        status !== QuoteStatus.Pending
                          ? LABELS.updateQuote
                          : undefined
                      }
                      heading={LABELS.quoteDetails}
                      quote={quote}
                      onSubmit={(values: SubmitQuoteRequest) =>
                        onSubmit(values)
                      }
                    />
                  </Box>
                  {/* Comments Section */}
                  <Box my={2}>
                    <CommentsSection
                      commentResourceType={CommentResourceType.WorkOrder}
                      resourceId={workOrderId}
                    />
                  </Box>
                </Box>
              </>
            </DashboardWrapperComponent>

            {/* PDF div */}
            <div ref={docRef} className={classes.hide}>
              <PrintQuotePdfComponent property={property} quote={quote} />
            </div>
          </Box>

          <Box maxWidth={"390px"}>
            <DetailsSidebar userDetails={userDetails}>
              <QuoteDetailsSideBar
                property={property}
                quote={quote}
                onPassQuote={onPassQuote}
              />
            </DetailsSidebar>
          </Box>
        </>
      ) : (
        <Box mt={2} p={2}>
          <Box display="flex" alignItems="center">
            <Box display="inline-block">
              <ContentHeadingSection
                onBack={() => {
                  setShowFullScreenSidebar(false);
                }}
                heading="Quote details"
              />
            </Box>
          </Box>
          <br />
          <QuoteDetailsSideBar
            property={property}
            quote={quote}
            onPassQuote={onPassQuote}
          />
        </Box>
      )}
    </Box>
  );
};

export default SubmitQuoteComponent;
