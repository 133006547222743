import { LoginImpersonatePayload } from "src/services/login/LoginData";

export enum LoginImpersonateActionTypes {
  IMPERSONATION_REQUEST = "IMPERSONATION_REQUEST",
}

export interface LoginImpersonateAction {
  type: LoginImpersonateActionTypes;
  data?: LoginImpersonatePayload | Error;
}

export class LoginImpersonateActions {
  public static postLoginStart(
    data: LoginImpersonatePayload
  ): LoginImpersonateAction {
    return { type: LoginImpersonateActionTypes.IMPERSONATION_REQUEST, data };
  }
}
