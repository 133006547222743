import React, { FC, useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  Grid,
  Card,
  CardContent,
  Avatar,
  Chip,
  Button,
} from "@material-ui/core";
import { AgentFilter, agentFilters, LABELS } from "./AgentsConstants.data";
import { color } from "../../../../theme/Theme";
import DashboardButton from "../../dashboardButton/DashboardButton";
import { Add } from "@material-ui/icons";
import SelectAgency from "../../terms/selectAgency/SelectAgency";
import TermsAndConditions from "../../terms/termsAndConditions/TermsAndConditions";
import {
  TermsAndConditionsContext,
  TermsAndConditionsContextValue,
} from "../../../../container/team/AgentsContainer";
import { agencyLogoURL } from "../../../../helper/HelperFunctions";
import { TradeAgency } from "../../../../models/trade/TradeAgency.data";
import { AgentsStyles } from "./AgentsStyles.data";
import { useLocation } from "react-router";
import { get } from "lodash";
import { Tag } from "../../../Tag/Tag";
import { GetTradeAgenciesResponse } from "../../../../services/tradeService/TradeServiceData.data";

interface Props {
  setSidebarAgency: React.Dispatch<React.SetStateAction<TradeAgency | null>>;
  getAgencies: ({ pageNumber: number, type: AgentFilter }) => void;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  activeFilter: AgentFilter;
  setActiveFilter: React.Dispatch<React.SetStateAction<AgentFilter>>;
  paginationData: GetTradeAgenciesResponse | null;
}

const AgentsComponent: FC<Props> = ({
  setSidebarAgency,
  setActiveFilter,
  activeFilter,
  paginationData,
  getAgencies,
  pageNumber,
  setPageNumber,
}) => {
  // State
  const [selectAgenciesDialogOpen, setSelectAgenciesDialogOpen] =
    useState<boolean>(false);

  // other hooks
  // Context
  const {
    selectedAgencies,
    tradeAgencies,
    setTermsDialogOpen,
    termsDialogOpen,
  } = useContext(TermsAndConditionsContext) as TermsAndConditionsContextValue;
  const classes = AgentsStyles();
  const location = useLocation();

  // effects
  useEffect(() => {
    if (selectedAgencies.length === 0) {
      setTermsDialogOpen(false);
    }
  }, [selectedAgencies.length]);

  // checks if the add agents modal is supposed to be open initially, if yes opens it
  useEffect(() => {
    const showAddAgentModalOpen = get(location, "state.showAddAgentModal");
    if (showAddAgentModalOpen) {
      setSelectAgenciesDialogOpen(true);
    }
  }, []);

  useEffect(() => {
    getAgencies({ pageNumber, type: activeFilter });
  }, [pageNumber]);

  return (
    <>
      <Box mt={3} p={2}>
        {/* Registered agents heading and add button heading */}
        <Box
          mb={2}
          color={color.secondary200}
          display="flex"
          justifyContent="flex-end"
          alignItems="center">
          <Box width={"170px"}>
            <DashboardButton
              text={LABELS.addAgent}
              fullWidth
              centered
              startIcon={<Add />}
              action={() => setSelectAgenciesDialogOpen(true)}
            />
          </Box>
        </Box>
      </Box>

      {/* Filters */}
      <Box pl={2} display="flex" gridGap={"15px"}>
        {agentFilters.map(({ value, label }) => (
          <Chip
            className={`${classes.chip} ${
              value === activeFilter ? classes.active : ""
            }`}
            label={label}
            onClick={() => {
              setActiveFilter(value);
            }}
          />
        ))}
      </Box>

      {/* Registered Agents List */}
      <Box mt={1} width="100%">
        <Grid className={classes.agentsCardsContainer} container>
          {(tradeAgencies || []).map((agency) => {
            const {
              branchDetail: {
                branchDetail: {
                  tradingName,
                  suburb,
                  postalAddress,
                  internalCode,
                },
                agencyDetail: { code },
              },
              termsDetail: { accepted },
            } = agency;

            return (
              <Grid
                className={classes.agencyCardWrapper}
                key={`branch-${internalCode}`}
                item
                lg={4}
                sm={6}
                xs={12}>
                <Card
                  onClick={() => setSidebarAgency(agency)}
                  className={`${classes.pointer} ${classes.agencyCard}`}>
                  <CardContent classes={{ root: classes.cardContentRoot }}>
                    <Box display="flex" mb={3} justifyContent="center">
                      <Avatar
                        className={classes.agencyImage}
                        src={agencyLogoURL(code)}
                        imgProps={{ className: classes.agencyImage }}>
                        <Typography variant="h1">
                          {tradingName.charAt(0)}
                        </Typography>
                      </Avatar>
                    </Box>
                    <Box textAlign="center">
                      <Typography variant="h4">{tradingName}</Typography>
                      <Typography color="textSecondary" variant="caption">
                        {suburb || postalAddress}
                      </Typography>
                    </Box>
                    {!accepted && (
                      <Box mt={2} display="flex" justifyContent="center">
                        <Tag
                          text="Pending"
                          color={color.warningLight}
                          boxProps={{ mb: 1 }}
                        />
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box p={2} display="flex" justifyContent="space-between">
        <Button
          onClick={() => {
            setPageNumber(pageNumber - 1);
          }}
          className={classes.button}
          disabled={!paginationData || paginationData.first}
          variant="outlined">
          Prev page
        </Button>
        <Button
          onClick={() => {
            setPageNumber(pageNumber + 1);
          }}
          className={classes.button}
          disabled={!paginationData || paginationData.last}
          variant="outlined">
          Next page
        </Button>
      </Box>

      <Dialog
        PaperProps={{ className: classes.dialog }}
        open={selectAgenciesDialogOpen}>
        <Box display="flex" minHeight={"50vh"} p={3}>
          <SelectAgency
            setSelectAgenciesDialogOpen={setSelectAgenciesDialogOpen}
            setTermsDialogOpen={setTermsDialogOpen}
          />
        </Box>
      </Dialog>

      {/* Terms and Conditions Dialog */}
      {selectedAgencies.length && termsDialogOpen ? (
        <Dialog
          PaperProps={{ className: classes.dialog }}
          fullWidth={true}
          maxWidth={"sm"}
          open={termsDialogOpen}>
          <Box display="flex" minHeight={"50vh"} p={3}>
            <TermsAndConditions
              key={Date.now()}
              setTermsDialogOpen={setTermsDialogOpen}
            />
          </Box>
        </Dialog>
      ) : null}
    </>
  );
};

export default AgentsComponent;
