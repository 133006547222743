import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { OfficeDetails as AddBranchRequest } from '../registerBusiness/RegistrationFormRequest.data';
import { GetBranchRequest, GetBranchSuccessPayload } from "./Branch.data";
import { UpdateBranchDetailsPayload } from '../serviceableRegions/serviceableRegionsServiceData.data';

const serviceType = ServiceType.Offices;

export class BranchService {
    private readonly service: ApiServiceBase = new ApiService(serviceType);

    public post(payload: AddBranchRequest): Promise<any> | any {
      return this.service.post({}, payload);
    }

    public get(payload: GetBranchRequest): Promise<GetBranchSuccessPayload> | GetBranchSuccessPayload {
      const {officeId} = payload;
      return this.service.get({ route: [officeId] });
    }

    public put(payload: UpdateBranchDetailsPayload, officeId: number) {
      return this.service.put({route: [officeId.toString()]}, payload);
    }
}

export default new BranchService();