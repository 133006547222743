import { GooglePlacesAutoComplete } from "./GooglePlacesAutoComplete.data";
import { AddressValue } from "./AddressValue.data";
import { TypeHelper } from "../TypeHelper";
import { Logging } from "../Logging";

export type GooglePlaceResult = google.maps.places.PlaceResult;
export type PlacePredictions= google.maps.places.AutocompletePrediction;

export class GooglePlacesHelper {
  // google is not avaiable when running the tests in jest
  public static createGooglePlacesAutoComplete(
    input: HTMLInputElement,
    listener: (value: AddressValue) => void
  ): GooglePlacesAutoComplete {
    let ctr: google.maps.places.Autocomplete | null = null;

    if (typeof google !== "undefined") {
      ctr = new google.maps.places.Autocomplete(input, {});
      ctr.setComponentRestrictions({ country: ["au"] });
      ctr.addListener("place_changed", () =>
        listener({ inputValue: input.value, place: ctr!.getPlace() })
      );
    } else {
      // Mock implementation for testing
      input.addEventListener("change", (e) =>
        listener({ inputValue: input.value, place: null })
      );
    }

    return { input, listener, autoComplete: ctr };
  }

  /// <summary>
  /// Fix address according to this article.
  /// Google Places Autocomplete suggestions with Unit No/Subpremise is not coming in the response array
  /// https://stackoverflow.com/questions/17936689/google-places-autocomplete-suggestions-with-unit-no-subpremise-is-not-coming-in
  /// </summary>
  public static fixAddressSubPremise(address: AddressValue): AddressValue {
    if (address && address.inputValue && address.place) {
      const inputValue = address.inputValue;
      const place = address.place;
      const route = place.address_components!.find(
        (t) => t.types && !!t.types.find((t2) => t2 === "route")
      );

      if (route && route.long_name) {
        const routePrefix = route.long_name.split(" ", 1)[0];
        const i = inputValue.indexOf(routePrefix);
        const j = place.formatted_address!.indexOf(routePrefix);

        if (i >= 0 && j >= 0) {
          const fullStreetNumber = inputValue.substr(0, i).trim();
          const routeIndex = place.address_components!.findIndex(
            (t) =>
              !!t.long_name &&
              t.long_name !== fullStreetNumber &&
              t.types &&
              !!t.types.find((t2) => t2 === "street_number")
          );

          if (routeIndex >= 0) {
            const newFormattedAddress = `${fullStreetNumber} ${place.formatted_address!.substr(
              j
            )}`;
            Logging.trace(
              `GooglePlacesHelper.fixAddressSubPremise: Changing Google address from '${place.formatted_address}' to '${newFormattedAddress}'`
            );

            return {
              ...address,
              place: {
                ...place,
                formatted_address: newFormattedAddress,
                // arrayReplaceAt() handles invalid index
                address_components: TypeHelper.arrayReplaceAt(
                  place.address_components!,
                  routeIndex,
                  {
                    ...place.address_components![routeIndex],
                    long_name: fullStreetNumber,
                    short_name: fullStreetNumber,
                  }
                ),
              },
            };
          }
        }
      }
    }

    return address;
  }
}
