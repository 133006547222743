import { StoreAction } from "../StoreHelper";
import {
  GetTradeAgenciesRequest,
  GetTradeAgenciesResponse,
  PatchTermsResponseRequest,
} from "../../services/tradeService/TradeServiceData.data";
import { AgentFilter } from "../../component/dashboard/team/agents/AgentsConstants.data";

export enum TradeActionType {
  GetTradeAgenciesRequest = "GET_TRADE_AGENCIES_REQUEST",
  GetTradeAgenciesSuccess = "GET_TRADE_AGENCIES_SUCCESS",
  GetTradeAgenciesError = "GET_TRADE_AGENCIES_ERROR",

  UpdateTermsResponseRequest = "UPDATE_TERMS_RESPONSE_REQUEST",
  UpdateTermsResponseSuccess = "UPDATE_TERMS_RESPONSE_SUCCESS",
  UpdateTermsResponseError = "UPDATE_TERMS_RESPONSE_ERROR",
}

export type TradeActionPayload =
  | GetTradeAgenciesResponse
  | Error
  | PatchTermsResponseRequest
  | GetTradeAgenciesRequest
  | undefined;

export type TradeAction = StoreAction<TradeActionType, TradeActionPayload>;

export class TradeActions {
  public static GetTradeAgencies(pageNumber: number, agentFilters?: Array<AgentFilter>): TradeAction {
    return { type: TradeActionType.GetTradeAgenciesRequest, data: {pageNumber, agentFilters} };
  }

  public static GetTradeAgenciesSuccess(
    responsePayload: GetTradeAgenciesResponse
  ) {
    return {
      type: TradeActionType.GetTradeAgenciesSuccess,
      data: responsePayload,
    };
  }

  public static GetTradeAgenciesError(error: Error) {
    return {
      type: TradeActionType.GetTradeAgenciesError,
      data: error,
    };
  }

  public static updateTermsResponseRequest(
    payload: PatchTermsResponseRequest
  ): TradeAction {
    return {
      type: TradeActionType.UpdateTermsResponseRequest,
      data: payload,
    };
  }

  public static updateTermsResponseSuccess(
    payload: PatchTermsResponseRequest
  ): TradeAction {
    return {
      type: TradeActionType.UpdateTermsResponseSuccess,
      data: payload,
    };
  }

  public static updateTermsResponseError(error: Error): TradeAction {
    return {
      type: TradeActionType.UpdateTermsResponseError,
      data: error,
    };
  }
}
