import React, { FC, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  withStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import RenderInput from "../../../../FormikComponents/RenderInputField";
import {
  LABELS,
  formFields,
  VALIDATION_SCHEMA,
} from "./PersonalInfoFormConstants.data";
import { PersonalInfo } from "../../../../../models/personalInfo/PersonalInfo.data";
import { Formik, Field } from "formik";
import { GetUserResponse } from "../../../../../services/login/LoginData";
import {
  inputStylePropsGenerator,
  RenderInputFieldStyles,
} from "../../../../FormikComponents/RenderInputFieldStyle";
import { AccountCircleOutlined } from "@material-ui/icons";
import { color } from "../../../../../theme/Theme";
import { ROLE_TYPES } from "../../../../../helper/TradesHelper";
import DashboardButton from "../../../dashboardButton/DashboardButton";
import RenderImageInput from "../../../../RenderImageInput/RenderImageInput";
import { PersonalInfoFormStyles } from "./PersonalInfoFormStyles.data";

interface Props {
  onSubmit: (formData: PersonalInfo) => void;
  userDetails: GetUserResponse;
}

const UserIcon = withStyles({
  root: {
    color: color.teal,
    marginRight: "1em",
  },
})(AccountCircleOutlined);

const PersonalInfoForm: FC<Props> = (props) => {
  const renderInputProps = inputStylePropsGenerator(RenderInputFieldStyles());

  const [initialValues, setInitialValues] =
    useState<PersonalInfo | undefined>(undefined);
  const { onSubmit, userDetails } = props;
  const classes = PersonalInfoFormStyles();
  useEffect(() => {
    const {
      firstName,
      lastName,
      email,
      mobileNumber,
      userTradeEntitlements,
      logo,
    } = userDetails;

    setInitialValues({
      firstName,
      lastName,
      email,
      mobileNumber,
      role:
        Array.isArray(userTradeEntitlements) && userTradeEntitlements.length > 0
          ? userTradeEntitlements[0].role
          : ROLE_TYPES.trade_employee,
      image: logo,
    });
  }, []);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.only("xs"));
  return initialValues ? (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(formValues, { resetForm }) => {
        onSubmit(formValues);
        resetForm({ values: formValues });
      }}
      validationSchema={VALIDATION_SCHEMA}>
      {({ handleSubmit, setFieldValue, values, dirty, isValid }) => {
        const disabled = !dirty || !isValid;
        return (
          <>
            <Grid container>
              <Grid item md={8}>
                <Box px={2}>
                  <Grid container>
                    <Grid item sm={12} md={4} lg={3}>
                      <Box mb={2}>
                        <Field
                          name="image"
                          component={RenderImageInput}
                          label={LABELS.image}
                          onChange={(image) => setFieldValue("image", image)}
                          {...renderInputProps}
                          src={
                            values.image
                              ? typeof values.image === "string"
                                ? values.image
                                : URL.createObjectURL(values.image)
                              : ""
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                      <Box>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Box pr={isSmall ? 0 : 2}>
                              <Field name={formFields.firstName}>
                                {({ form, field }) => (
                                  <RenderInput
                                    label={LABELS.firstName}
                                    {...renderInputProps}
                                    {...field}
                                  />
                                )}
                              </Field>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box pl={isSmall ? 0 : 2}>
                              <Field name={formFields.lastName}>
                                {({ form, field }) => (
                                  <RenderInput
                                    label={LABELS.lastName}
                                    {...renderInputProps}
                                    {...field}
                                  />
                                )}
                              </Field>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name={formFields.email}>
                        {({ form, field }) => (
                          <RenderInput
                            label={LABELS.email}
                            {...renderInputProps}
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name={formFields.mobileNumber}>
                        {({ form, field }) => (
                          <RenderInput
                            label={LABELS.mobileNumber}
                            {...renderInputProps}
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box px={2}>
                  <Typography className={classes.rolesHeading} variant="h4">
                    {LABELS.role}
                  </Typography>
                  <Box display="flex" alignItems="center" p={2}>
                    <UserIcon />
                    <Typography variant="h4">
                      {Array.isArray(userDetails.userTradeEntitlements) &&
                      userDetails.userTradeEntitlements.length > 0
                        ? ROLE_TYPES[userDetails.userTradeEntitlements[0].role]
                        : ROLE_TYPES.trade_employee}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" pr={2}>
              <DashboardButton
                variant={disabled ? "outlined" : "contained"}
                lightText={!disabled}
                greyText={disabled}
                disabled={disabled}
                text="Save"
                action={handleSubmit}
              />
            </Box>
          </>
        );
      }}
    </Formik>
  ) : null;
};

export default PersonalInfoForm;
