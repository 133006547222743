import { SignUpRequest } from "../../services/signUp/SignUpRequest.data";
import { StoreAction } from "../StoreHelper";

export enum SignUpActionType {
  SignUpLinkRequest = "SIGN_UP_LINK_REQUEST",
  SignUpLinkSuccess = "SIGN_UP_LINK_SUCCESS",
  SignUpLinkError = "SIGN_UP_LINK_ERROR",
}

export interface SignUpErrorPayload {
  readonly error: Error | any;
}

export interface SignUpStartPayload {
  readonly request: SignUpRequest;
  readonly onSuccess?: ((response: any) => void) | undefined | null;
  readonly onError?: ((error: Error | any) => void) | undefined | null;
}

interface UserTradeEntitlements {
  id: number;
  officeId: number | null;
  role: string;
  tradeId: number | null;
  userId: number;
}

export interface SignUpSuccessPayload {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly mobileNumber: string | null;
  readonly officePhoneNumber: string | null;
  readonly username: string;
  readonly id: number;
  readonly adminOnBoardingStage: string;
  readonly userTradeEntitlements: UserTradeEntitlements[];
  readonly authToken: string;
  readonly refreshToken: string;
}

export type SignUpActionPayload =
  | SignUpErrorPayload
  | SignUpStartPayload
  | SignUpSuccessPayload
  | null;

export type SignUpAction = StoreAction<SignUpActionType, SignUpActionPayload>;

export class SignUpActions {
  public static signUpStart(data: SignUpStartPayload) {
    return { type: SignUpActionType.SignUpLinkRequest, data };
  }

  public static signUpSuccess(data: SignUpSuccessPayload) {
    return { type: SignUpActionType.SignUpLinkSuccess, data };
  }

  public static signUpError(data: SignUpErrorPayload) {
    return { type: SignUpActionType.SignUpLinkError, data };
  }
}
