import { makeStyles } from "@material-ui/core";
import { color } from "../../../theme/Theme";

export const renderDatePickerStyles = makeStyles({
  filled: {
    background: color.wildSand,
    borderRadius: "4px",
    border: `1px solid ${color.lightGrey}`,
  },
  icon: {
    padding: "12px 8px",
  },
});
