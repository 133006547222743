import { ToastAction, ToastActionType } from "../actions/ToastActions";
import { ToastState } from "../state/ToastState";

const initialState: ToastState = {
  open: false,
  message: "",
  type: undefined,
};

export const toastReducer = (state = initialState, action: ToastAction) => {
  switch (action.type) {
    case ToastActionType.SHOW_TOAST: {
      return {
        open: true,
        message: action.data.message,
        type: action.data.type,
      };
    }

    case ToastActionType.HIDE_TOAST: {
      return {
        open: false,
        type: undefined,
        message: "",
      };
    }

    default:
      return state;
  }
};
