import { takeEvery, put } from "redux-saga/effects";
import { Logging } from "../../helper/Logging";
import ServiceableRegionsService from "../../services/serviceableRegions/serviceabeRegionsService";
import {
  AddServiceableRegionsRequest,
  AddServiceableRegionsRequestPayload,
  DeleteServiceableRegionRequestPayload,
} from "../../services/serviceableRegions/serviceableRegionsServiceData.data";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import { OnboardingStage } from "../../helper/OnboardingStages";
import {
  ServiceableRegionsActions,
  ServiceableRegionsActionType,
  ServiceableRegionsAction,
} from "../actions/ServiceableRegionsActions";
import { onboardingStageUpdater } from "../../helper/OnboardingStageUpdater/OnboardingStageUpdater";
import { onboardingStage } from "../../helper/Auth";
import { UpdateBranchActions } from "../actions/BranchActions";

export function* postAddServiceableRegionsWorker(
  action: ServiceableRegionsAction
) {
  const data = action.data as AddServiceableRegionsRequestPayload;
  const { request, onSuccess } = { ...data };

  try {
    Logging.debug("RegisterBusinessSagas.addServiceableRegionsWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    const response: any = (yield ServiceableRegionsService.post(
      request! as AddServiceableRegionsRequest
    ))!;

    yield put(ServiceableRegionsActions.addServiceableRegionsSuccess(response));

    if (onboardingStage() !== OnboardingStage.Completed) {
      yield onboardingStageUpdater(OnboardingStage.AddPaymentMethods);
    } else {
      yield put(UpdateBranchActions.addBranchServiceableRegion(response));
    }

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    Logging.error("RegisterBusinessSagas.addServiceableRegionsWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(ServiceableRegionsActions.serviceableRegionsError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* deleteServiceableRegionWorker(
  action: ServiceableRegionsAction
) {
  try {
    const data = action.data as DeleteServiceableRegionRequestPayload;

    Logging.debug("ServiceableRegionsSaga.deleteServiceableRegionsWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    yield ServiceableRegionsService.delete(data);

    yield put(ServiceableRegionsActions.deleteServiceableRegionSuccess(data));
  } catch (error) {
    Logging.error("ServiceableRegionsSaga.deleteServiceableRegionsWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(ServiceableRegionsActions.serviceableRegionsError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const addServiceableRegionsWatcher = [
  takeEvery(
    ServiceableRegionsActionType.ADD_SERVICEABLE_REGIONS_REQUEST,
    (action) =>
      postAddServiceableRegionsWorker({
        type: action.type,
        data: (action as ServiceableRegionsAction).data,
      })
  ),

  takeEvery(
    ServiceableRegionsActionType.DELETE_SERVICEABLE_REGION_REQUEST,
    (action) =>
      deleteServiceableRegionWorker({
        type: action.type,
        data: (action as ServiceableRegionsAction).data,
      })
  ),
];

export default addServiceableRegionsWatcher;
