import { makeStyles } from "@material-ui/core/styles";

export const AddUserDocStyles = makeStyles({
  wrapper: {
    width: "50vw",
    minHeight: "60vh",
    padding: "7%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
    "@media all and (max-width: 768px)": {
      width: "80vw",
    },
    "@media all and (max-width: 480px)": {
      width: "100vw",
      height: "100vw",
    },
  },
  spaceTop: {
    marginTop: "2em",
  },
  fieldsWrapper: {
    flexGrow: 1,
    justifyContent: "space-evenly",
  },
  navButton: {
    width: "124px",
    height: "32px",
  },
  buttonsSection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1.5em",
  },
  modalHeader: {
    display: "flex",
  },
  heading: {
    flexGrow: 1,
  },
  closeIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  }
});
