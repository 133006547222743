import { UserDetailsFormData } from "../../component/userDetails/UserDetailsData.data";
import { UpdateRequest } from "../../services/user/UserData";
import { cloneDeep } from "lodash";
import { GetUserResponse } from "../../services/login/LoginData";

export const userDetailsUpdateFormConverter = (
  data: UserDetailsFormData,
  userDetails: GetUserResponse,
  token?: string
): UpdateRequest => {
  let request: any = cloneDeep(userDetails);
  request = {
    ...request,
    ...data,
    username: data.email,
    userTradeEntitlements: [{ ...request.userTradeEntitlements[0], role: data.role }],
    userDocuments: []
  }
  delete request.image;
  delete request.role;
  delete request.id;
  delete request.confirmPassword;
  return token ? {
    registerUserRequest: JSON.stringify(request),
    file: data.image,
    token
  } :
    {
      userRequest: JSON.stringify(request),
      file: data.image,
    }
    ;
};
