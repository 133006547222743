import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { OfficeDetails as AddBranchRequest } from "../../../../../services/registerBusiness/RegistrationFormRequest.data";
import { AddBranchActions } from '../../../../../store/actions/BranchActions';
import { useHistory } from 'react-router';
import NewBranchForm from './NewBranchForm';

export const NewBranch:FC<{}> = () => {
  const tradeId = localStorage.getItem("tradeId");
  const dispatch = useDispatch()
  const history = useHistory()
  const dispatcher = {
    postBranch: (formData: AddBranchRequest) => {
      dispatch(
        AddBranchActions.addBranchRequest({
          request: {
            tradeId: Number(tradeId),
            ...formData
          },
          onSuccess: () => {
            history.goBack();
          }
        })
      )
    },
  };
    
  return (
    <Box>
      <Box onClick={() => history.goBack()} style={{ cursor: "pointer" }} mt={2} ml={3}>
        <ArrowBack />
      </Box>
      <NewBranchForm onSubmit={(formData) => dispatcher.postBranch(formData)} />
    </Box>
  )
}