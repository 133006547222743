import { AgencyBranchTermsState } from "../state/AgencyBranchTerms";
import {
  AgencyAction,
  AgencyActionType,
  AgencyActionPayload,
} from "../actions/AgencyActions";
import { AgencyBranchTermsResponse } from "../../models/agency/AgencyBranchTermsResponse";

const initialState: AgencyBranchTermsState = {
  error: null,
  loading: false,
  terms: [],
};

export const agencyBranchTermsReducer = (
  state = initialState,
  action: AgencyAction
) => {
  const data: AgencyActionPayload = action.data;
  switch (action.type) {
    case AgencyActionType.GetAgencyBranchTermsRequest:
      return { ...state, loading: true, error: null };

    case AgencyActionType.GetAgencyBranchTermsSuccess:
      return {
        loading: false,
        terms: [...state.terms, data as AgencyBranchTermsResponse],
        error: null,
      };

    case AgencyActionType.GetAgencyBranchTermsError:
      return {
        ...state,
        loading: false,
        error: data as Error,
      };

    case AgencyActionType.RespondToAgencyBranchTermsRequest:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case AgencyActionType.RespondToAgencyBranchTermsSuccess:
      return {
        ...state,
        loading: false,
        error: undefined,
      };

    case AgencyActionType.RespondToAgencyBranchTermsError:
      return {
        ...state,
        loading: false,
        error: data as Error,
      };

    default:
      return state;
  }
};
