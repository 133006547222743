export const LABELS = {
  heading: "What trades do you specialise in?",
  subheading:
    "Add any trades you hold the appropriate accreditation for and you will be able to quote for jobs in this trade category",
  addTrades: "Add trades",
  mostUsed: "MOST USED",
  allTradeCategories: "All trade categories",
  yourTrades: "YOUR TRADES",
  noTradesSelected: "No trades selected - Add trades by selecting them above",
  addMoreTrades: "Add more trades by selecting them above"
};
