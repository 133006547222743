import { makeStyles } from "@material-ui/core";
import { color } from "../../../../theme/Theme";

export const quoteDetailsSidebarStyles = makeStyles({
  contactCardDetailsListItem: {
    // Important is required because MUI's ListItem implementation overrides the properties
    paddingLeft: "8px !important",
  },
  paperClass: {
    paddingRight: 0,
    paddingLeft: 0,
  },

  quoteActionButton: {
    backgroundColor: color.teal,
    color: color.light,
    textAlign: "center",
    "&:hover": {
      backgroundColor: color.teal,
    },
  },
});
