import { HttpContentType } from "./HttpContentType";
import { ServiceType } from "./ServiceType.data";
import { QueryPath } from "./QueryPath.data";
import ApiServiceBase from "./ApiServiceBase";
import { Logging } from "../helper/Logging";
import { MultipartFormData } from "./MultipartFormData.data";

/// <summary>
/// ApiServiceMock cannot inherit ApiService, because that's mocked and that would create an infinite loop, that's why we need ApiServiceBase.
/// </summary>
export default class ApiService extends ApiServiceBase {
  protected readonly serviceType: ServiceType;

  public constructor(serviceType: ServiceType) {
    super(serviceType);
    this.serviceType = serviceType;
  }

  public get<T = void>(path: QueryPath): Promise<T> {
    Logging.debug(`ApiService.get ${this.getUrl(path)}`, { path });
    const instance = this.getAxiosInstance();
    return new Promise<T>((resolve, reject) =>
      instance
        .get(this.getUrl(path), this.getConfig(HttpContentType.Json))
        .then(
          (res: any) => {
            Logging.trace(`ApiService.get ${this.getUrl(path)} @Result`, {
              path,
              result: res,
            });

            //Workaround for token request - needs to be reverted
            resolve(res.data.data || res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.get ${this.getUrl(path)} @Error`, {
              path,
              error: err,
            });
            reject(this.processError(err));
          }
        )
    );
  }

  public post<T = void>(path: QueryPath, body: any): Promise<T> {
    Logging.debug(`ApiService.post ${this.getUrl(path)}`, {
      path,
      body,
      bodyJson: JSON.stringify(body),
    });
    const instance = this.getAxiosInstance();
    return new Promise<T>((resolve, reject) =>
      instance
        .post(this.getUrl(path), body, this.getConfig(HttpContentType.Json))
        .then(
          (res: any) => {
            Logging.trace(`ApiService.post ${this.getUrl(path)} @Result`, {
              path,
              body,
              bodyJson: JSON.stringify(body),
              result: res,
            });
            resolve(res.data.data ? res.data.data : res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.post ${this.getUrl(path)} @Error`, {
              path,
              body,
              bodyJson: JSON.stringify(body),
              error: err,
            });
            reject(this.processError(err));
          }
        )
    );
  }

  public delete<T = void>(path: QueryPath): Promise<T> {
    Logging.debug(`ApiService.delete ${this.getUrl(path)}`, { path });
    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) =>
      instance
        .delete(this.getUrl(path), this.getConfig(HttpContentType.Json))
        .then(
          (res) => {
            Logging.trace(`ApiService.delete ${this.getUrl(path)} @Result`, {
              path,
              result: res,
            });
            resolve(res.data.data);
          },
          (err) => {
            Logging.error(`ApiService.delete ${this.getUrl(path)} @Error`, {
              path,
              error: err,
            });
            reject(this.processError(err));
          }
        )
    );
  }

  public patch<T = void>(path: QueryPath, body: any): Promise<T> {
    Logging.debug(`ApiService.put ${this.getUrl(path)}`, {
      path,
      body,
      bodyJson: JSON.stringify(body),
    });
    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) =>
      instance
        .patch(this.getUrl(path), body, this.getConfig(HttpContentType.Json))
        .then(
          (res: any) => {
            Logging.trace(`ApiService.patch ${this.getUrl(path)} @Result`, {
              path,
              body,
              bodyJson: JSON.stringify(body),
              result: res,
            });
            resolve(res.data.data ? res.data.data : res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.patch ${this.getUrl(path)} @Error`, {
              path,
              body,
              bodyJson: JSON.stringify(body),
              error: err,
            });
            reject(this.processError(err));
          }
        )
    );
  }

  public put<T = void>(path: QueryPath, body: any): Promise<T> {
    Logging.debug(`ApiService.put ${this.getUrl(path)}`, {
      path,
      body,
      bodyJson: JSON.stringify(body),
    });
    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) =>
      instance
        .put(this.getUrl(path), body, this.getConfig(HttpContentType.Json))
        .then(
          (res: any) => {
            Logging.trace(`ApiService.put ${this.getUrl(path)} @Result`, {
              path,
              body,
              bodyJson: JSON.stringify(body),
              result: res,
            });
            resolve(res.data.data ? res.data.data : res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.put ${this.getUrl(path)} @Error`, {
              path,
              body,
              bodyJson: JSON.stringify(body),
              error: err,
            });
            reject(this.processError(err));
          }
        )
    );
  }

  public postMultipart<T = void>(
    path: QueryPath,
    data: MultipartFormData[]
  ): Promise<T> {
    Logging.debug(`ApiService.postMultipart ${this.getUrl(path)}`, {
      path,
      data,
    });
    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) => {
      const formData = this.prepareMultiPartForm(data);
      return instance
        .post(
          this.getUrl(path),
          formData,
          this.getConfig(HttpContentType.MultipartFormData)
        )
        .then(
          (res) => {
            Logging.trace(
              `ApiService.postMultipart ${this.getUrl(path)} @Result`,
              { path, data, result: res }
            );
            resolve(res.data);
          },
          (err) => {
            Logging.error(
              `ApiService.postMultipart ${this.getUrl(path)} @Error`,
              { path, data, error: err }
            );
            reject(this.processError(err));
          }
        );
    });
  }

  public putMultipart<T = void>(
    path: QueryPath,
    data: MultipartFormData[]
  ): Promise<T> {
    Logging.debug(`ApiService.postMultipart ${this.getUrl(path)}`, {
      path,
      data,
    });
    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) => {
      const formData = this.prepareMultiPartForm(data);
      return instance
        .put(
          this.getUrl(path),
          formData,
          this.getConfig(HttpContentType.MultipartFormData)
        )
        .then(
          (res) => {
            Logging.trace(
              `ApiService.putMultipart ${this.getUrl(path)} @Result`,
              { path, data, result: res }
            );
            resolve(res.data);
          },
          (err) => {
            Logging.error(
              `ApiService.postMultipart ${this.getUrl(path)} @Error`,
              { path, data, error: err }
            );
            reject(this.processError(err));
          }
        );
    });
  }

  public patchMultipart<T = void>(
    path: QueryPath,
    data: MultipartFormData[]
  ): Promise<T> {
    Logging.debug(`ApiService.patchMultipart ${this.getUrl(path)}`, {
      path,
      data,
    });
    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) => {
      const formData = this.prepareMultiPartForm(data);
      return instance
        .patch(
          this.getUrl(path),
          formData,
          this.getConfig(HttpContentType.MultipartFormData)
        )
        .then(
          (res) => {
            Logging.trace(
              `ApiService.patchMultipart ${this.getUrl(path)} @Result`,
              { path, data, result: res }
            );
            resolve(res.data);
          },
          (err) => {
            Logging.error(
              `ApiService.patchMultipart ${this.getUrl(path)} @Error`,
              { path, data, error: err }
            );
            reject(this.processError(err));
          }
        );
    });
  }
}
