import { makeStyles } from "@material-ui/core";
import { color } from "../../../../../theme/Theme";

export const AddArtefactsListSectionStyles = makeStyles({
  pointer: {
    cursor: "pointer",
  },
  uploadButton: {
    background: "rgba(198,203,212,.15)",
    color: color.secondary200,
    padding: ".6em 1em",
    borderRadius: "4px",
    "&:hover": {
      background: "rgba(198,203,212,.15)",
    },
  },
  documentListItem: {
    padding: "1em",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background: color.wildSand,
    },
  },
  input: { display: "none" },
  inputFieldListItem: {
    borderColor: "black",
  },
  imageIcon: {
    color: color.teal,
    marginRight: ".5em",
  },
  pdfIcon: {
    color: color.warning,
    marginRight: ".5em",
  },
});
