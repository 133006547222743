import React, { FC, useState, useRef, useEffect } from "react";
import RegistrationStepsWrapper from "../registrationStepsComponents/registrationStepsWrapper/RegistrationStepsWrapper";
import { LABELS } from "./AddTradesDocumentsConstants.data";
import ContentBoxSection from "../registrationStepsComponents/contentBox/ContentBoxSection";
import TradeDocsCard from "./tradeDocsCard/TradeDocsCard";
import AddDocModal from "./addDocModal/AddDocModal";
import { UserTradeCategoriesResponse } from "../../services/userTrades/UserTradeService.data";
import { AddDocFormData } from "./addDocModal/AddDocForm.data";
import { Typography } from "@material-ui/core";
import SkipText from "../skipText/SkipText";
import { AddDocData } from "../../container/addTradesDocuments/AddTradesDocumentsData.data";
import { AddTradesDocumentsStyles } from "./AddTradeDocumentsStyles";

interface Props {
  userTradeCategories: UserTradeCategoriesResponse;
  onSubmit: (values: AddDocData) => void;
  onNextAndSkip: () => void;
}

const AddTradesDocumentsComponent: FC<Props> = (props) => {
  const { userTradeCategories, onSubmit, onNextAndSkip } = props;

  // Modals state
  const [open, setOpen] = useState<boolean>(false);
  const [userTradeCategoryId, setUserTradeCategoryId] = useState<
    number | undefined
  >(undefined);
  const [file, setFile] = useState<File | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  // Effect for closing modal when request succeeds
  useEffect(() => {
    setOpen(false);
  }, [userTradeCategories]);

  // Modal functions
  const openUploadModal = (id: number) => {
    setOpen(true);
    setUserTradeCategoryId(id);
  };

  const closeUploadModal = () => {
    setOpen(false);
    setUserTradeCategoryId(undefined);
    setFile(null);
  };

  // File Input functions
  const fileChange = () => {
    if (fileRef.current && fileRef.current.files![0]) {
      const newFile = fileRef.current.files![0];
      setFile(newFile);
      openUploadModal(userTradeCategoryId!);
    } else {
      if (open) {
        closeUploadModal();
      }
    }
  };

  const handleInputClick = (id: number) => {
    fileRef.current!.value = "";
    setUserTradeCategoryId(id);
    setFile(null);
    fileRef.current!.click();
  };

  const classes = AddTradesDocumentsStyles();

  return (
    <>
      <AddDocModal
        onSubmit={(formValues: AddDocFormData) =>
          onSubmit({ ...formValues, userTradeCategoryId, file } as AddDocData)
        }
        fileName={(file && file.name) || undefined}
        closeModal={closeUploadModal}
        open={open}
      />
      <RegistrationStepsWrapper
        heading={LABELS.heading}
        subheading={LABELS.subheading}
        primaryButton={{
          text: "Next",
          action: () => onNextAndSkip(),
        }}
        footerComponent={
          <SkipText action={() => onNextAndSkip()}>{LABELS.skipText}</SkipText>
        }>
        <ContentBoxSection noSidePadding heading={LABELS.yourTrades}>
          {userTradeCategories.map((trade) => (
            <TradeDocsCard
              key={`${trade.id}`}
              trade={trade.tradeCategoryResponse}
              docs={trade.userTradeCategoryDocuments}
              uploadDoc={() => handleInputClick(trade.id)}
            />
          ))}
          <Typography className={classes.spaceTop} variant="body2">
            {LABELS.documentsMustHave}
          </Typography>

          {/* Hidden input for uploading file */}
          <input
            ref={fileRef}
            className={classes.fileInput}
            type="file"
            onChange={fileChange}
          />
        </ContentBoxSection>
      </RegistrationStepsWrapper>
    </>
  );
};

export default AddTradesDocumentsComponent;
