import { makeStyles } from "@material-ui/core";
import { color } from "../../../theme/Theme";

export const addBankStyles = makeStyles({
  inputs: {
    width: '100%',
    paddingBottom: '1rem',
  },
  secureIcon: {
    padding: '0 12px 16px 0',
  },
  mainContainer: {
    marginTop: '1rem',
  },
  primaryButton: {
    padding: '1.1rem',
    marginLeft: '0.25rem',
    borderRadius: 8,
  },
  secondaryButton: {
    padding: '1rem',
    marginRight: '0.25rem',
    border: `2px solid ${color.lightGrey}`,
    borderRadius: 8,
  }
})