import { makeStyles } from "@material-ui/core";

export const counterStyles = makeStyles({
  count: {
    fontSize: "40px",
  },
  pointer: {
    cursor: "pointer",
  },
});
