export const LABELS = {
  heading: "Thanks for that, now what suburbs or regions do you service?",
  subheading:
    "Tenants who live in these areas will be able to request work from you. You can edit these later.",
  serviceableRegionHeading: "Serviceable regions",
  suburbs: "Selected regions",
  addMore: "Add more using the search bar above",
  searchBarPlaceholder: "Type an address, suburb, or postcode",
  errors: {
    uniqueAddress:
      "An address with given postal code and suburb already exists",
    noPostcode:
      "We can't fetch the postcode for given location, please try more precise location",
  },
  addPostCodes:
    "Add postcodes within a specified radius (in kms) from your branch address",
  inidiviudalPostcode:
    "Add serviceable regions via individual postcode or suburb search",
};
