import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import { GetTradeRequest } from "./ListBranchesServiceData.data";
import { Trade } from "../../container/listBranches/ListBranches.data";

const serviceType = ServiceType.Trades;

class ListBranchesServiceImpl {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public get(payload: GetTradeRequest): Promise<Trade> | Trade {
    const { tradeId } = payload;
    return this.service.get({ route: [tradeId] });
  }
}

const ListBranchesService = new ListBranchesServiceImpl();
export default ListBranchesService;
