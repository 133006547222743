import React, { FC, useContext } from "react";
import {
  Box,
  Avatar,
  Typography,
  Divider,
  ListItem,
  ListItemAvatar,
  List,
  ListItemText,
  Button,
} from "@material-ui/core";
import DashboardSection from "../../dashboardSection/DashboardSection";
import { TradeAgency } from "../../../../models/trade/TradeAgency.data";
import { LABELS } from "./AgencyBranchDetailsSidebarConstants.data";
import { agencyLogoURL } from "../../../../helper/HelperFunctions";
import { color } from "../../../../theme/Theme";
import {
  PhoneOutlined,
  EmailOutlined,
  LocationOnOutlined,
  DescriptionOutlined,
} from "@material-ui/icons";
import DashboardListItem from "../../../lisItems/DashboardListItem";
import { TermsAndConditionsContext } from "../../../../container/team/AgentsContainer";
import { AgencyBranchTermsResponse } from "../../../../models/agency/AgencyBranchTermsResponse";
import { AgencyBranchDetailsSidebarStyles } from "./AgencyBranchSidebarStyles.data";

interface Props {
  agency: TradeAgency;
}

// tslint:disable-next-line:cyclomatic-complexity
const AgencyBranchSidebar: FC<Props> = ({ agency }) => {
  const classes = AgencyBranchDetailsSidebarStyles();
  const {
    onGetAgencyBranch,
    setSelectedAgencies,
    setTermsDialogOpen,
    agencyBranchTerms,
  } = useContext(TermsAndConditionsContext);
  const {
    branchDetail: {
      branchDetail: {
        tradingName,
        phone,
        email,
        streetNumber,
        streetName,
        suburb,
        state,
        internalCode,
        postalAddress,
      },
      agencyDetail: { code, tradingName: agencyTradingName },
    },
    termsDetail: { accepted },
  } = agency;

  const onViewAgreement = () => {
    const termDetails = agencyBranchTerms.find(
      (data) => data.branch.internalCode === internalCode
    );
    if (termDetails) {
      setSelectedAgencies([termDetails]);
      setTermsDialogOpen(true);
    } else {
      onGetAgencyBranch({
        branchCode: internalCode,
        onSuccess: (response: AgencyBranchTermsResponse) => {
          setSelectedAgencies([response]);
          setTermsDialogOpen(true);
        },
      });
    }
  };

  return (
    <Box pt={2}>
      <Box mt={6}>
        <DashboardSection disableSidePadding>
          <>
            {/* Agency Icon and name */}
            <Box mb={4} display="flex" justifyContent="center">
              <Avatar className={classes.agencyImage} src={agencyLogoURL(code)}>
                <Box color={color.light}>
                  <Typography color="inherit" variant="h2">
                    {tradingName.charAt(0)}
                  </Typography>
                </Box>
              </Avatar>
            </Box>
            <Box px={2} textAlign="center" my={1}>
              <Typography
                className={classes.agencyName}
                variant="h4"
                color="textSecondary">
                {tradingName}
              </Typography>
            </Box>
            <Box px={2} mb={3} textAlign="center">
              <Typography variant="h4" color="textSecondary">
                {suburb || postalAddress}
              </Typography>
            </Box>

            {/* Accepted agreement button */}
            <Box px={2}>
              <Button onClick={onViewAgreement} fullWidth variant="outlined">
                <Box color={color.secondary200} py={1}>
                  <Typography color="inherit" variant="caption">
                    {accepted ? LABELS.acceptedAgreement : LABELS.viewAgreement}
                  </Typography>
                </Box>
              </Button>
            </Box>

            {/* Main Contact */}
            <Box mt={4} pl={2}>
              <Box mb={2}>
                <Typography variant="h4">{LABELS.mainContact}</Typography>
              </Box>
              <Divider />
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Box pr={3}>
                      <PhoneOutlined />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText>{phone}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Box pr={3}>
                      <EmailOutlined />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText className={classes.listItemText}>
                    {email}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Box pr={3}>
                      <LocationOnOutlined />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText className={classes.listItemText}>{`${
                    (streetNumber && streetNumber + " ") || ""
                  }${(streetName && streetName + ", ") || ""}${
                    (suburb && suburb + " ") || ""
                  }${state || ""}`}</ListItemText>
                </ListItem>
              </List>
            </Box>

            {/* Agreement */}
            <Box pl={2} mt={4}>
              <Box mb={2}>
                <Typography variant="h4">{LABELS.agreement}</Typography>
              </Box>
              <Divider />

              <Box onClick={onViewAgreement}>
                <DashboardListItem
                  pointer
                  image={
                    <Box color={color.secondary300}>
                      <DescriptionOutlined color="inherit" />
                    </Box>
                  }
                  primary={`${agencyTradingName} Agreement`}
                />
              </Box>
            </Box>
          </>
        </DashboardSection>
      </Box>
    </Box>
  );
};

export default AgencyBranchSidebar;
