import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../theme/Theme";

export const ToastMessageStyles = makeStyles({
  icon: {
    marginRight: ".5em",
  },
  message: {
    color: color.light,
    display: "inline-block",
    fontFamily: "GT Walsheim light",
  },
  snackBarContentMessage: {
    display: "flex",
    alignItems: "center",
  },
  successIcon: {
    fill: color.teal,
  },
  errorIcon: {
    fill: color.warning,
  },
});
