import React, { FC, useState, useEffect, useRef } from "react";
import ContentHeadingSection from "../../contentHeadingSection/ContentHeadingSection";
import {
  LABELS,
  ConfirmJobBookingFormValues,
} from "./ViewJobComponentConstants.data";
import {
  Box,
  Typography,
  Divider,
  Button,
  FormHelperText,
  IconButton,
  Paper,
  useTheme,
  useMediaQuery,
  Grid,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import {
  CloudDownloadOutlined,
  LocationOnOutlined,
  Print,
} from "@material-ui/icons";
import { Redirect, useHistory, useParams } from "react-router";
import {
  getWorkOrderById,
  nextJobStatus,
  scrollToRef,
  titleCase,
  getAddressString,
  googleMapsQuery,
  getContactDetails,
} from "../../../../helper/HelperFunctions";
import QuoteDetails from "../../quoteDetails/QuoteDetails";
import StyledExpansionPanel from "../../../styledExpansionPanel/StyledExpansionPanel";
import {
  Job,
  ChangeJobStatusRequestPayload,
  AddJobVariationRequest,
  JobCostUpdateRequest,
} from "../../../../services/jobs/JobsData.data";
import { Property } from "../../../../models/property/Property.data";
import { User } from "../../../../models/user/User.data";
import { cancelledJobTypes, JobStatus } from "../../../../models/jobs/JobData";
import AddArtefactsModal, {
  ArtefactsFormValues,
} from "../addArtefactsModal/AddArtefactsModal";
import ArtefactsSection from "./artefactsSection/ArtefactsSection";
import { isNull } from "lodash";
import { ViewJobStyles } from "./ViewJobStyles.data";
import AddVariationModal from "../addVariationModal/AddVariationModal";
import {
  QuoteVariationDetails,
  Quote,
} from "../../../../models/quote/Quote.data";
import { RenderInputFieldStyles } from "../../../FormikComponents/RenderInputFieldStyle";
import { SubmitQuoteRequest } from "../../../../services/quotes/QuotesData.data";
import SubmitQuoteForm from "../../submitQuoteForm/SubmitQuoteForm";
import { QuoteStatus } from "../../../../models/quoteStatus/QuoteStatus.data";
import { QuoteVarianceType } from "../../../../models/quoteVarianceType/QuoteVarianceType";
import TradesChip from "../../../chip/TradesChip";
import { routes } from "../../../../Routes";
import ConfirmBookingForm from "./confirmBookingForm/ConfirmBookingForm";
import CommentsSection from "../../../commentsSection/CommentsSection";
import { CommentResourceType } from "../../../../models/comment/Comment";
import JobCostUpdateForm from "../jobCostUpdateForm/JobCostUpdateForm";
import { QuoteType } from "../../../../models/quote/QuoteType.data";
import PdfComponent from "./pdfPrint/PdfComponent";
import { useReactToPrint } from "react-to-print";
import DashboardButton from "../../dashboardButton/DashboardButton";
import SummaryDetailItem from "./confirmBookingForm/SummaryDetailItem";
import { color } from "../../../../theme/Theme";
import { MOMENT_DATE_FORMATS } from "../../../../constants/AppConstants";
import moment from "moment";
import { createEvent, DateArray } from "ics";
import ContactCard, { Contact } from "../../../contactCard/ContactCard";
import { LocalMarketplaceSummary } from "../../../../models/maintenance-request/MaintenanceRequest.data";
import DocGrid from "../../../docGrid/DocGrid";

interface ViewJobComponentProps {
  job: Job;
  property: Property;
  users: User[];
  confirmJobBooking: (
    formValues: ConfirmJobBookingFormValues,
    id: string
  ) => void;
  changeStatus: (data: ChangeJobStatusRequestPayload) => void;
  addArtefacts: (data: ArtefactsFormValues, id: string) => void;
  onVariationQuoteSubmit: (request: AddJobVariationRequest) => void;
  updateJobCost: (request: JobCostUpdateRequest) => void;
  isVariationJob?: boolean;
  parentJobVariationIds?: string[];
  showSidebarJob: () => void;
}

interface ViewJobPathParams {
  jobId: string;
}

// tslint:disable-next-line:cyclomatic-complexity
const ViewJobComponent: FC<ViewJobComponentProps> = ({
  job,
  property,
  users,
  confirmJobBooking,
  changeStatus,
  addArtefacts,
  onVariationQuoteSubmit,
  parentJobVariationIds,
  isVariationJob,
  updateJobCost,
  showSidebarJob,
}) => {
  // states
  // show/hide job cost update form
  const [showCostUpdateForm, setShowCostUpdateForm] = useState<boolean>(false);
  // artefacts modal state
  const [artefactsModalOpen, setArtefactsModalOpen] = useState<boolean>(false);
  // variation modal state
  const [variationModalOpen, setVariationModalOpen] = useState<boolean>(false);
  const [variationModalData, setVariationModalData] =
    useState<null | QuoteVariationDetails>(null);
  // Send for payment error message
  const [sendForPaymentError, setSendForPaymentError] = useState<string>("");
  // back button
  const [back, setBack] = useState<boolean>(false);

  // Other hooks
  const theme = useTheme();
  const history = useHistory();
  const classes = ViewJobStyles();
  const variationQuoteFormRef = useRef<HTMLDivElement>(null);
  const jobCostFormRef = useRef<HTMLDivElement>(null);
  const { errorMessageStyles } = RenderInputFieldStyles();
  const { jobId: originalJobId } = useParams<ViewJobPathParams>();

  // Functions and variables
  const {
    quote,
    quote: {
      workOrderId,
      maintenanceRequestResponse: {
        privateWorksDetails,
        workOrders,
        audit: { createdDate },
        tenantIds,
        details: { title, description, extendedData, images },
      },
      type: quoteType,
      availability,
      remarks,
      totalCost: quoteTotalCost,
    },
    quoteVariations,
    jobDetail: {
      specificMaterial,
      labourCost,
      callOutCost,
      includedMaterialCost,
      changeNote,
      serviceFeePercent,
      lineAmountType,
      totalCost,
      preApprovedDetails,
    },
    id,
    status: jobStatus,
    keyDates,
    assignedUserId: jobAssignedUserId,
    jobArtefactFiles,
  } = job;
  const {
    managingAgent,
    propertyUsers,
    tenants: { allAssignedTenants },
    postcode,
    state,
    suburb,
    address,
  } = property;
  const workOrder = getWorkOrderById(workOrders, workOrderId);

  const { tenants, alternateContact } = workOrder;

  const additionalContactList = getContactDetails(tenants || [], [
    ...allAssignedTenants,
  ]);

  const customers: Contact[] = propertyUsers
    .filter((u) => [...tenants, ...tenantIds].includes(u.id))
    .map((u) => ({
      id: u.id,
      name: u.name,
      email: u.email,
      phone: u.phone,
      surname: u.surname,
    }));

  //  On quote variation modal confirm
  const quoteVariationModalConfirmHandler = (
    modalData: QuoteVariationDetails
  ) => {
    setVariationModalData(modalData!);
    setVariationModalOpen(false);
  };
  // Variation quote form submission handler
  const handleVariationQuoteFormSubmit = (
    variationQuoteFormData: SubmitQuoteRequest
  ) => {
    const request: AddJobVariationRequest = {
      ...variationQuoteFormData,
      quoteVariationDetails: variationModalData!,
      parentJobId: job.id,
    };
    onVariationQuoteSubmit(request);
  };
  // Variations
  const variationQuotes: Quote[] = quoteVariations.filter(
    (variation: Quote) =>
      variation.status === QuoteStatus.Sent &&
      variation.quoteVariationDetails.quoteVarianceType ===
        QuoteVarianceType.newBooking
  );

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const summaryDetailsTradespersonsNames = jobAssignedUserId
    ? users.reduce((prev, { id: uid, firstName, lastName }) => {
        if (jobAssignedUserId.includes(uid)) {
          return prev + `${prev.length ? ", " : ""}${firstName} ${lastName}`;
        } else {
          return prev;
        }
      }, "")
    : undefined;

  const bookingRequired = jobStatus === JobStatus.BookingRequired;
  const cancelled = jobStatus === JobStatus.TradeCancellation;

  const [showConfirmBookingForm, setShowConfirmBookingForm] =
    useState<boolean>(bookingRequired);

  // Download ICS
  const downloadIcs = () => {
    const attendees = users.filter((u) => u.id in jobAssignedUserId);
    const placesInstance = new google.maps.places.PlacesService(
      document.createElement("div")
    );

    // creating description string
    let desc = description;

    const addField = (k: string, v: string) => {
      desc += `\n${k}: ${v}`;
    };

    addField("Workorder ID", workOrderId);
    if (workOrder.propertyAccess.accessKeyNumber) {
      addField("Key number", workOrder.propertyAccess.accessKeyNumber);
    }
    addField(
      "Job date",
      moment(keyDates!.startDate!, MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM).format(
        MOMENT_DATE_FORMATS.DO_MMM_YYYY
      )
    );
    addField(
      "Job time",
      `${moment(
        keyDates!.startDate,
        MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM
      ).format("hh:mm A")} to ${moment(
        keyDates!.expectedCompletion,
        MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM
      ).format("hh:mm A")}`
    );
    if (workOrder.comments) {
      addField("Notes", workOrder.comments);
    }
    if (remarks) {
      addField("Scope of work", remarks);
    }
    addField("Job link", window.location.href);

    let geo: any;
    placesInstance.findPlaceFromQuery(
      {
        fields: ["geometry.location"],
        query: address,
      },
      (res) => {
        if (res && res.length > 0) {
          const { lat, lng } = res[0].geometry!.location;
          geo = { lat: lat(), lon: lng() };
        }

        const event = {
          start: moment(
            keyDates!.startDate,
            MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM
          )
            .format("YYYY-M-D-H-m")
            .split("-")
            .map((t) => parseInt(t, 10)) as DateArray,
          end: moment(
            keyDates!.expectedCompletion,
            MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM
          )
            .format("YYYY-M-D-H-m")
            .split("-")
            .map((t) => parseInt(t, 10)) as DateArray,
          title,
          description: desc,
          location: address,
          geo,
          attendees: attendees.map((attendee) => ({
            name: `${attendee.firstName} ${attendee.lastName}`,
            email: attendee.email,
          })),
        };
        createEvent(event, (error, value) => {
          if (error) {
            return;
          }
          const file = new File([value], `job-${id}`, {
            type: "text/calendar;charset=utf-8",
          });
          const link = URL.createObjectURL(file);
          const downloadEl = document.createElement("a");
          downloadEl.setAttribute("download", `job-${id}`);
          downloadEl.setAttribute("href", link);
          downloadEl.click();
        });
      }
    );
  };

  const cancelledJob = cancelledJobTypes.includes(jobStatus as JobStatus);
  const summaryData: LocalMarketplaceSummary[] =
    extendedData && extendedData["featureDetails"]
      ? (extendedData["featureDetails"] as LocalMarketplaceSummary[]).filter(
          (data) => data.type === "answers"
        )
      : [];

  // effects
  useEffect(() => {
    setVariationModalData(null);
    setSendForPaymentError("");
    setShowCostUpdateForm(false);
  }, [job]);

  useEffect(() => {
    if (!variationModalOpen && variationModalData) {
      scrollToRef(variationQuoteFormRef);
    }
  }, [variationModalOpen]);

  const docRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => docRef.current,
    removeAfterPrint: true,
    onAfterPrint: () => {
      docRef.current!.className = classes.hide;
    },
    onBeforeGetContent: () => {
      docRef.current!.className = "";
    },
  });

  const isPrivate = privateWorksDetails && privateWorksDetails.isPrivate;

  // We don't have a view for cancelled jobs so for now we're redirecting to jobs list page
  return back || cancelledJob ? (
    <Redirect to={routes.dashboard.jobs()} />
  ) : (
    <Box className={classes.wrapper}>
      <Box mb={4} display="flex" alignItems="center">
        <Box width="100%" display="inline-block">
          <ContentHeadingSection
            onBack={() => setBack(true)}
            heading={LABELS.jobDetails}
            optionsSection={
              <Box display="flex" alignItems="center">
                {/* More details button */}
                {downSm && (
                  <Box className={classes.moreDetailsButtonContainer}>
                    <DashboardButton
                      action={showSidebarJob}
                      text="More details"
                    />
                  </Box>
                )}
                <IconButton
                  style={{ padding: "0" }}
                  onClick={handlePrint}
                  color="primary"
                  component="span">
                  <Print />
                </IconButton>
              </Box>
            }
          />
        </Box>
      </Box>

      {/* Variation tabs */}
      {isVariationJob ||
      job.jobVariations.filter(
        (jv) => !cancelledJobTypes.includes(jv.status as JobStatus)
      ).length ? (
        <Box mt={3} mb={1}>
          <TradesChip
            active={!isVariationJob}
            text={"Original"}
            onClick={() =>
              history.push(routes.dashboard.viewJob(originalJobId))
            }
          />
          {(
            parentJobVariationIds! ||
            job.jobVariations
              .filter(
                (jv) => !cancelledJobTypes.includes(jv.status as JobStatus)
              )
              .map((v: Job) => v.id)
          ).map((variationId, index) => (
            <TradesChip
              active={variationId === id}
              text={`${LABELS.variation} ${index + 1}`}
              onClick={() =>
                history.push(
                  routes.dashboard.viewVariationJob(originalJobId, variationId)
                )
              }
            />
          ))}
        </Box>
      ) : null}

      <Paper variant="outlined">
        <Box p={2}>
          <Box pb={2}>
            <a
              target="_blank"
              style={{ color: color.secondary200 }}
              href={googleMapsQuery(
                (getAddressString(property) || "") +
                  " " +
                  (`${suburb && titleCase(suburb)}${suburb && ","} ${state}${
                    state && ","
                  } ${postcode}` || "")
              )}>
              <Typography
                className={`${classes.summaryContent} ${classes.summaryDetailsClickableItem} ${classes.address}`}
                variant="h4">
                <Box display="flex" alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="19px">
                    <LocationOnOutlined
                      className={classes.summaryItemIconMargin}
                      fontSize="inherit"
                    />
                  </Box>
                  <Box>
                    {getAddressString(property)},{" "}
                    {`${titleCase(suburb)}${suburb && ","} ${state}${
                      state && ","
                    } ${postcode}`}
                  </Box>
                </Box>
              </Typography>
            </a>
          </Box>
          <Divider style={{ marginBottom: "1em" }} />
          {/* Workorder details */}
          {[
            { label: LABELS.title, value: title },
            { label: LABELS.description, value: description },
            { label: LABELS.workorderId, value: workOrderId },
            {
              label: LABELS.jobCategory,
              value: titleCase(workOrder.category.replaceAll("_", " ")),
            },
          ].map((el) => (
            <SummaryDetailItem
              dense
              field={el.label}
              content={
                <Typography className={classes.summaryContent} variant="body2">
                  {el.value}
                </Typography>
              }
            />
          ))}
          {workOrder.propertyAccess &&
            workOrder.propertyAccess.accessKeyNumber && (
              <SummaryDetailItem
                dense
                field={LABELS.keyNumber}
                content={
                  <Typography
                    className={classes.summaryContent}
                    variant="body2">
                    {workOrder.propertyAccess.accessKeyNumber}
                  </Typography>
                }
              />
            )}
          {workOrder.comments && (
            <SummaryDetailItem
              dense
              field={LABELS.workorderNotes}
              content={
                <Typography className={classes.summaryContent} variant="body2">
                  {workOrder.comments}
                </Typography>
              }
            />
          )}
          {remarks && (
            <SummaryDetailItem
              dense
              field={LABELS.scopeOfWork}
              content={
                <Typography className={classes.summaryContent} variant="body2">
                  {remarks}
                </Typography>
              }
            />
          )}

          {!bookingRequired && !cancelled && (
            <>
              {[
                {
                  label: LABELS.jobDate,
                  value: moment(
                    keyDates!.startDate,
                    MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM
                  ).format(MOMENT_DATE_FORMATS.DO_MMM_YYYY),
                },
                {
                  label: LABELS.jobTime,
                  value: `${moment(
                    keyDates!.startDate,
                    MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM
                  ).format("hh:mm A")} to ${moment(
                    keyDates!.expectedCompletion,
                    MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM
                  ).format("hh:mm A")}`,
                },
                {
                  label: LABELS.tradespersonName,
                  value: summaryDetailsTradespersonsNames,
                },
              ].map((el) => (
                <SummaryDetailItem
                  dense
                  field={el.label}
                  content={
                    <Typography
                      className={classes.summaryContent}
                      variant="body2">
                      {el.value}
                    </Typography>
                  }
                />
              ))}
            </>
          )}

          {!bookingRequired && (
            <Box display="flex" gridGap="1em" mt={2}>
              <Button
                variant="outlined"
                size="medium"
                onClick={() => setShowConfirmBookingForm(true)}>
                Edit booking details
              </Button>
              {!downSm && (
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => downloadIcs()}>
                  <CloudDownloadOutlined style={{ marginRight: ".5em" }} />
                  Calendar invite
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Paper>

      <StyledExpansionPanel
        defaultExpanded
        heading={
          isPrivate ? LABELS.bookingImages : LABELS.maintenanceRequestImages
        }>
        <Box p={2} pt={0}>
          {!!images.length ? (
            <DocGrid docs={images} />
          ) : (
            <Typography variant="caption" color="textSecondary">
              No images
            </Typography>
          )}
        </Box>
      </StyledExpansionPanel>

      {/* contacts */}
      <StyledExpansionPanel defaultExpanded heading="Contacts">
        {managingAgent && !isPrivate && (
          <ContactCard
            collapsible
            person={managingAgent}
            designation={LABELS.propertyManager}
          />
        )}
        {additionalContactList.map((contact) => (
          <ContactCard
            collapsible
            person={contact}
            designation={LABELS.contact}
          />
        ))}
        {alternateContact ? (
          <ContactCard
            collapsible
            person={{
              surname: "",
              name: alternateContact.name,
              email: alternateContact.email || "N/A",
              phone: alternateContact.contact,
            }}
            designation={LABELS.alternateContact}
          />
        ) : null}
        {privateWorksDetails &&
          privateWorksDetails.isPrivate &&
          customers.map((contact) => (
            <ContactCard
              collapsible
              person={contact}
              designation={"Customer"}
            />
          ))}
      </StyledExpansionPanel>

      {/* Options Summary */}
      {summaryData && summaryData.length > 0 && (
        <Box className={classes.expansionSection}>
          <StyledExpansionPanel defaultExpanded heading={LABELS.optionsSummary}>
            <Box pl={2}>
              <Divider />
              <Box pt={1}>
                <Grid container>
                  {summaryData && summaryData.length > 0 && (
                    <Grid item xs={12} sm={6}>
                      {summaryData
                        .filter((data) => data.labelId !== "MediaUpload")
                        .map((data) => (
                          <ListItem className={classes.summaryListStyle}>
                            <ListItemText
                              primary={
                                <Typography variant="h4">
                                  {data.label}
                                </Typography>
                              }></ListItemText>
                            <ListItemText
                              primary={
                                <Typography
                                  color="textSecondary"
                                  variant="caption">
                                  {data.value}
                                </Typography>
                              }></ListItemText>
                          </ListItem>
                        ))}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </StyledExpansionPanel>
        </Box>
      )}

      {/* Confirm booking form */}
      {showConfirmBookingForm && (
        <ConfirmBookingForm
          readonlyForm={!(bookingRequired || jobStatus === JobStatus.Scheduled)}
          jobAssignedUserId={jobAssignedUserId}
          jobStatus={jobStatus as JobStatus}
          keyDates={keyDates}
          createdDate={createdDate}
          confirmJobBooking={confirmJobBooking}
          workOrder={workOrder}
          id={id}
          users={users}
          quoteAvailability={availability}
          hideForm={
            !bookingRequired
              ? () => setShowConfirmBookingForm(false)
              : undefined
          }
        />
      )}
      {/* Job cost details */}
      <Box {...{ ref: jobCostFormRef }} className={classes.expansionSection}>
        {!showCostUpdateForm ? (
          <>
            <StyledExpansionPanel
              jobDetailsPanelProps={{ lineAmountType }}
              defaultExpanded
              heading={LABELS.jobEstimate}>
              <QuoteDetails
                {...{
                  callOutCost,
                  labourCost,
                  specificMaterials: specificMaterial,
                  materialCost: includedMaterialCost,
                  totalCost,
                  serviceFeePercent,
                  preApprovedDetails,
                  workorderType: workOrder.type,
                  quoteTotalCost,
                  quote,
                }}
                lineAmountType={lineAmountType}
                type={quoteType}
              />
            </StyledExpansionPanel>
            {!showCostUpdateForm && changeNote && (
              <Paper variant="outlined">
                <Box p={2}>
                  <Typography variant="h4">{LABELS.costUpdateNote}</Typography>
                  <Box mb={2} mt={1}>
                    <Divider color="disabled" />
                  </Box>
                  <Typography variant="caption">{changeNote}</Typography>
                </Box>
              </Paper>
            )}
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant={"outlined"}
                onClick={() => {
                  scrollToRef(jobCostFormRef);
                  setShowCostUpdateForm(!showCostUpdateForm);
                }}
                className={classes.button}>
                {LABELS.edit}
              </Button>
            </Box>
          </>
        ) : (
          <JobCostUpdateForm
            jobId={id}
            workorderType={workOrder.type}
            preApprovedDetails={preApprovedDetails}
            changeNote={changeNote}
            {...{
              callOutCost,
              labourCost,
              includedMaterialCost,
              specificMaterials: specificMaterial,
              type: quoteType,
              totalCost,
              serviceFeePercent,
            }}
            lineAmountType={lineAmountType}
            onCancel={() => setShowCostUpdateForm(false)}
            heading={LABELS.updateCost}
            onSubmit={(request: JobCostUpdateRequest) => updateJobCost(request)}
            quoteTotalCost={quoteTotalCost}
            job={job}
          />
        )}
      </Box>

      {/* Add artefacts modal and finalize job summary section */}
      <Box className={classes.expansionSection}>
        {jobArtefactFiles && (
          <ArtefactsSection jobArtefactFiles={jobArtefactFiles} />
        )}
        <AddArtefactsModal
          onClose={() => {
            setArtefactsModalOpen(false);
          }}
          jobArtefactFiles={jobArtefactFiles}
          open={artefactsModalOpen}
          onConfirm={(data: ArtefactsFormValues) => {
            addArtefacts(data, id);
            setSendForPaymentError("");
          }}
          showSpecialMaterialsSection={!!specificMaterial.length}
          job={job}
        />
      </Box>

      {/* Add variation modal */}
      {!isVariationJob && (
        <AddVariationModal
          open={variationModalOpen}
          handleClose={() => {
            setVariationModalOpen(false);
            setVariationModalData(null);
          }}
          handleSubmit={quoteVariationModalConfirmHandler}
        />
      )}

      {/* Submit variation quote form */}
      <Box {...{ ref: variationQuoteFormRef }}>
        {variationModalData && (
          <Box mt={3}>
            <Divider />
            <Box mt={3}>
              <SubmitQuoteForm
                setStartDateToSystemDate
                hideOnSite
                submitQuoteButtonText={LABELS.submitVariationQuote}
                quote={quote}
                onSubmit={handleVariationQuoteFormSubmit}
                heading={LABELS.variationJobDetails}
              />
            </Box>
          </Box>
        )}
      </Box>

      {/* Complete job /Add artefacts/ Finalize job buttons */}
      {!bookingRequired && jobStatus !== JobStatus.Complete && (
        <>
          <Box className={classes.buttonSection}>
            <Button
              onClick={() => setArtefactsModalOpen(true)}
              className={classes.button}
              variant="outlined">
              {jobArtefactFiles ? LABELS.updateArtefacts : LABELS.addArtefacts}
            </Button>
            {!isVariationJob && (
              <Button
                onClick={() => setVariationModalOpen(true)}
                className={`${classes.button}`}
                variant="outlined">
                {LABELS.addVariation}
              </Button>
            )}
            {jobStatus === JobStatus.Scheduled &&
              (job.quote.maintenanceRequestResponse.privateWorksDetails &&
              job.quote.maintenanceRequestResponse.privateWorksDetails
                .isPrivate ? (
                <Button
                  onClick={() => {
                    if (
                      isNull(jobArtefactFiles) ||
                      jobArtefactFiles.invoiceFileUploadDetails.length === 0 ||
                      jobArtefactFiles.jobImageFileUploadDetails.length === 0
                    ) {
                      setSendForPaymentError(LABELS.jobCompleteError);
                    } else {
                      changeStatus({ id, status: JobStatus.Complete });
                    }
                  }}
                  className={`${classes.scheduleButton} ${classes.button}`}>
                  {jobStatus === JobStatus.Scheduled && LABELS.completeJob}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    if (
                      isNull(jobArtefactFiles) ||
                      jobArtefactFiles.invoiceFileUploadDetails.length === 0 ||
                      jobArtefactFiles.jobImageFileUploadDetails.length === 0
                    ) {
                      setSendForPaymentError(LABELS.sendForPaymentErrorText);
                    } else if (
                      quoteType !== QuoteType.OnSite &&
                      totalCost === 0
                    ) {
                      setSendForPaymentError(LABELS.nonZeroCost);
                    } else {
                      changeStatus({ id, status: nextJobStatus(jobStatus)! });
                    }
                  }}
                  className={`${classes.scheduleButton} ${classes.button}`}>
                  {jobStatus === JobStatus.Scheduled && LABELS.sendForPayment}
                </Button>
              ))}
          </Box>
          {/* Error */}
          {sendForPaymentError && (
            <FormHelperText className={errorMessageStyles}>
              <div className={classes.sendForPaymentDiv}>
                {sendForPaymentError}
              </div>
            </FormHelperText>
          )}
        </>
      )}

      {/* Comments */}
      <Box mt={4}>
        <CommentsSection
          resourceId={id}
          commentResourceType={CommentResourceType.Job}
          subheading={
            isPrivate
              ? "Comments added here will send a SMS to the customer"
              : undefined
          }
        />
      </Box>

      {/* Variation Quotes */}
      {variationQuotes.length ? (
        <>
          <Box mt={3}>
            <Typography className={classes.variationQuoteHeading} variant="h4">
              {LABELS.variationQuotes}
            </Typography>
            <Divider />
            {variationQuotes.map((variationQuote) => {
              const {
                callOutCost: quoteCallOutCost,
                labourCost: quoteLabourCost,
                specificMaterials: quoteSpecificMaterials,
                totalCost: variationTotalCost,
                materialCost,
                type: variationQuoteType,
              } = variationQuote;
              return (
                <Box mt={3}>
                  <StyledExpansionPanel
                    defaultExpanded
                    heading={LABELS.variationJobDetails}>
                    <QuoteDetails
                      {...{
                        callOutCost: quoteCallOutCost,
                        specificMaterials: quoteSpecificMaterials,
                        totalCost: variationTotalCost,
                        materialCost,
                        labourCost: quoteLabourCost,
                        serviceFeePercent,
                        quoteTotalCost,
                        quote,
                      }}
                      lineAmountType={lineAmountType}
                      type={variationQuoteType}
                    />
                  </StyledExpansionPanel>
                </Box>
              );
            })}
          </Box>
        </>
      ) : null}

      {/* PDF div */}
      <div ref={docRef} className={classes.hide}>
        <PdfComponent property={property} job={job} />
      </div>
    </Box>
  );
};

export default ViewJobComponent;
