import { Close } from "@material-ui/icons";
import React, { FC } from "react";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import { VideoPlayerActions } from "../../store/actions/VideoPlayerActions";
import { useAppSelector } from "../../store/RootReducers";
import { VideoPlayerStyles } from "./VideoPlayerStyles";

const VideoPlayer: FC<{}> = () => {
  // hooks
  const classes = VideoPlayerStyles();
  const { src, visible } = useAppSelector((state) => state.videoPlayer);
  const dispatch = useDispatch();

  // functions and variables
  const dispatcher = {
    hidePlayer: () => dispatch(VideoPlayerActions.hide()),
  };

  return visible ? (
    <div className={classes.playerWrapper}>
      <ReactPlayer
        controls
        url={src!}
        className={classes.reactPlayer}
        playing
      />

      <div
        onClick={() => dispatcher.hidePlayer()}
        className={classes.closeIconContainer}>
        <Close />
      </div>
    </div>
  ) : null;
};

export default VideoPlayer;
