import * as React from "react";
import { withStyles, Typography } from "@material-ui/core";
import { ErrorPageStyle, ErrorPageStyles } from "./ErrorPageStyles";
import img404 from "../../assets/404.png";
import * as LABEL from "./ErrorPageConstants";

type OwnProps = ErrorPageStyles;

class ErrorPage extends React.Component<OwnProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <img src={img404} />
        <br />
        <Typography variant="h6" className={classes.errorMessage}>
          {LABEL.INCORRECT_LINK}
        </Typography>
      </div>
    );
  }
}

export default withStyles(ErrorPageStyle)(ErrorPage);
