import { all } from "redux-saga/effects";
import loginWatcher from "./store/sagas/LoginSaga";
import loginImpersonateWatcher from "./store/sagas/LoginImpersonateSagas";
import signUpWatcher from "./store/sagas/SignUpSagas";
import registerBusinessWatcher from "./store/sagas/RegisterBusinessSagas";
import addServiceableRegionsWatcher from "./store/sagas/ServiceableRegionsSagas";
import tradeCategoriesWatcher from "./store/sagas/TradeCategoriesSagas";
import getListBranchesWatcher from "./store/sagas/ListBranchesSagas";
import addBranchWatcher from "./store/sagas/AddBranchSagas";
import tradeUsersWatcher from "./store/sagas/TradeUsersSagas";
import getBranchWatcher from "./store/sagas/GetBranchSagas";
import updateBranchWatcher from "./store/sagas/UpdateBranchSaga";
import quotesWatcher from "./store/sagas/QuotesSaga";
import userDocumentsWatcher from "./store/sagas/UserDocumentsSaga";
import agencyWatcher from "./store/sagas/AgencySaga";
import tradesWatcher from "./store/sagas/TradeSaga";
import JobsWatcher from "./store/sagas/JobsSaga";
import commentsWatcher from "./store/sagas/CommentSagas";
import updateTeamMemberDetailsWatcher from "./store/sagas/UpdateTeamMemberSaga";
import paymentWatcher from "./store/sagas/PaymentSaga";

export default function* sagas() {
  yield all([
    loginWatcher,
    loginImpersonateWatcher,
    signUpWatcher,
    registerBusinessWatcher,
    addServiceableRegionsWatcher,
    tradeCategoriesWatcher,
    getListBranchesWatcher,
    addBranchWatcher,
    tradeUsersWatcher,
    getBranchWatcher,
    updateBranchWatcher,
    quotesWatcher,
    userDocumentsWatcher,
    agencyWatcher,
    tradesWatcher,
    JobsWatcher,
    commentsWatcher,
    updateTeamMemberDetailsWatcher,
    paymentWatcher,
  ]);
}
