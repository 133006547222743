import { createStyles, WithStyles } from "@material-ui/core";
import { color } from "../../theme/Theme";

export const RegistrationFormStyles = createStyles({
  loginComponent: {
    width: "100%",
    position: "relative",
    display: "flex",
    height: "100%",
  },
  tncLink: {
    margin: "0 6px",
    textDecoration: "underline",
  },
  registrationBox: {
    borderRadius: "8px",
    border: "solid 1px #eeeeee",
    backgroundColor: color.light,
    margin: "auto",
    padding: "3em 2.5em 2.5em 2.5em",
    overflowX: "auto",
  },
  registrationContent: {
    borderRadius: "6px",
    border: `solid 1px ${color.dark700}`,
    backgroundColor: color.light,
    marginTop: "4em",
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
  },
  articleHeader: {
    lineHeight: 1.18,
    letterSpacing: "2px",
    color: color.primary,
    padding: "1.25em 0",
    fontSize: "1em",
  },
  sectionHeader: {
    borderBottom: `solid 1px ${color.dark700}`,
    padding: "15px 15px 15px 0px",
  },
  articleContainer: {
    margin: "0 15px 15px 15px",
    position: "relative",
  },
  input: {
    color: "#2D1B49",
  },
  large: {
    width: "4em",
    height: "4em",
    margin: "8px",
  },
  imageContainer: {
    display: "inline-block",
    margin: "10% 0 0 10%",
  },
  imageContainerCentered: {
    display: "inline-block",
    margin: "50% 0 0 50%",
    transform: "translate('-50%, -50%')",
  },
  imageContainerImage: {
    height: "80px",
    width: "80px",
  },
  checkBoxLabel: {
    fontSize: "1em",
    color: "rgba(0,0,0,.6)",
  },
  checkBoxContainer: {
    marginTop: "1em",
  },
  buttonsSection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1.5em",
  },
  navButton: {
    width: "124px",
    height: "32px",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: {
    color: "red",
    fontSize: ".85em",
  },
  spaceTop: {
    marginTop: "2em",
  },
  abnLoadingMarginTop: {
    marginTop: "-0.5rem",
  },
  checkboxLabel: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.18,
    letterSpacing: 0.4,
    color: color.primary,
    marginTop: 10,
  },
  checkboxRoot: {
    alignItems: "flex-start",
  },
});

export interface RegistrationFormComponentStyles
  extends WithStyles<typeof RegistrationFormStyles> {}

export const inputsFields = {
  width: "100%",
  backgroundColor: "white",
  height: "50px",
};

export const avatarEditBorderStyle = {
  border: ".8px solid #f0f1f1",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#fafafa",
};

export const avatarEditLabelStyle = {
  fontSize: ".75em",
  color: "#9a9a9a",
};
