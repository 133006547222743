import { get } from "lodash";
import { FileTypes } from "../constants/AppConstants";
import { NamedFile } from "../models/file/NamedFile";
import { S3File } from "../models/file/S3File";

export function getFileType(contentType: string | null): FileTypes | undefined {
  // Special case: for content-type as null, we have to assume it's an image
  if (contentType === null) {
    return FileTypes.Image;
  }
  const parts = contentType.split("/");
  const category = parts[0];
  const type = parts[1];
  // image
  if (category === FileTypes.Image) {
    return FileTypes.Image;
  }
  // pdf
  if (category === "application") {
    switch (type) {
      case FileTypes.Pdf:
        return FileTypes.Pdf;
    }
  }
  // video
  if (category === "video") {
    return FileTypes.Video;
  }

  return undefined;
}

export function getDocSrc(doc: string | File | S3File | NamedFile): string {
  if (typeof doc === "string") {
    return doc;
  }

  // If S3File we will get link, if NamedFile or File we can use doc.file or doc directly to create object url
  const src = get(doc, "link");
  return (src ||
    URL.createObjectURL(
      (doc as NamedFile).file ? (doc as NamedFile).file : (doc as File)
    )) as string;
}
