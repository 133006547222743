import { TradeCategoriesState } from "../state/TradeCategoriesState";
import {
  TradeCategoriesActionTypes,
  TradeCategoriesAction,
  UserTradeDocumentUploadObject,
} from "../actions/TradeCategoriesActions";
import { GetTradeCategoriesResponse } from "../../services/tradeCategories/TradeCategories.data";
import {
  PostUserTradeCategoriesResponse,
  UserTradeCategoriesResponse,
  DeleteUserTradeCategorySuccessPayload,
  DeleteUserTradeCategoryDocumentSuccessPayload,
} from "../../services/userTrades/UserTradeService.data";
import { cloneDeep, omit } from "lodash";

export const initialState: TradeCategoriesState = {
  tradeCategories: undefined,
  loading: false,
  userTradeCategories: undefined,
  userTradeCategoriesDetails: undefined,
};

// tslint:disable-next-line: cyclomatic-complexity
export const tradeCategoriesReducer = (
  state = initialState,
  action: TradeCategoriesAction
): TradeCategoriesState => {
  switch (action.type) {
    case TradeCategoriesActionTypes.GET_CATEGORIES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case TradeCategoriesActionTypes.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        tradeCategories: action.data as GetTradeCategoriesResponse,
      };
    }

    case TradeCategoriesActionTypes.GET_CATEGORIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    }

    case TradeCategoriesActionTypes.GET_USER_TRADE_CATEGORIES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case TradeCategoriesActionTypes.GET_USER_TRADE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        userTradeCategoriesDetails: action.data as UserTradeCategoriesResponse,
      };
    }

    case TradeCategoriesActionTypes.GET_USER_TRADE_CATEGORIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    }

    case TradeCategoriesActionTypes.POST_USER_TRADE_CATEGORIES_SUCCESS: {
      const data = action.data as PostUserTradeCategoriesResponse;
      const tradeCategories = state.tradeCategories;
      const newTradeCategories: UserTradeCategoriesResponse = data.map(
        ({ id, userId, tradeCategoryId }) => {
          const tradeCategory = tradeCategories!.filter(
            (category) => category.id === tradeCategoryId
          )[0];
          return {
            id,
            userId,
            tradeCategoryResponse: tradeCategory,
            userTradeCategoryDocuments: [],
          };
        }
      );
      const existing = state.userTradeCategoriesDetails || [];
      return {
        ...state,
        loading: false,
        userTradeCategoriesDetails: [...existing, ...newTradeCategories],
      };
    }

    case TradeCategoriesActionTypes.POST_USER_TRADE_CATEGORIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    }

    case TradeCategoriesActionTypes.POST_USER_TRADE_DOCUMENT_SUCCESS: {
      const data: UserTradeDocumentUploadObject = action.data as UserTradeDocumentUploadObject;
      const newDocument = omit(data, "userTradeCategoryId");
      const newDetails: UserTradeCategoriesResponse = cloneDeep(
        state.userTradeCategoriesDetails
      )!;
      newDetails.map((tradeCategoryDetails) => {
        if (tradeCategoryDetails.id === data.userTradeCategoryId) {
          tradeCategoryDetails.userTradeCategoryDocuments.push(newDocument);
        }
      });

      return {
        ...state,
        loading: false,
        userTradeCategoriesDetails: newDetails,
      };
    }

    case TradeCategoriesActionTypes.POST_USER_TRADE_DOCUMENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    }

    case TradeCategoriesActionTypes.DELETE_USER_TRADE_CATEGORY_SUCCESS: {
      const tradeCategoryId = (action.data as DeleteUserTradeCategorySuccessPayload)
        .tradeCategoryId;
      let newDetails: UserTradeCategoriesResponse = cloneDeep(
        state.userTradeCategoriesDetails
      )!;
      newDetails = newDetails.filter((tradeCategoryDetails) => {
        return tradeCategoryDetails.id !== tradeCategoryId;
      });
      return {
        ...state,
        loading: false,
        userTradeCategoriesDetails: newDetails,
      };
    }

    case TradeCategoriesActionTypes.DELETE_USER_TRADE_CATEGORY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    }

    case TradeCategoriesActionTypes.DELETE_USER_TRADE_DOCUMENT_SUCCESS: {
      const {
        userTradeCategoryDocumentId,
        userTradeCategoryId,
      } = action.data as DeleteUserTradeCategoryDocumentSuccessPayload;

      let existing: UserTradeCategoriesResponse = cloneDeep(
        state.userTradeCategoriesDetails
      )!;
      let newDetails = existing.map((tradeCategory) => {
        let documents = tradeCategory.userTradeCategoryDocuments;
        if (tradeCategory.id === userTradeCategoryId) {
          documents = tradeCategory.userTradeCategoryDocuments.filter(
            (document) => document.id !== userTradeCategoryDocumentId
          );
        }
        return {
          ...tradeCategory,
          userTradeCategoryDocuments: documents,
        };
      });
      return {
        ...state,
        loading: false,
        userTradeCategoriesDetails: newDetails,
      };
    }

    case TradeCategoriesActionTypes.DELETE_USER_TRADE_DOCUMENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    }

    default:
      return state;
  }
};
