import {
  TopNavDashboardLink,
  topNavDashboardTabs,
  TopNavDashboardTab,
} from "./TopNavDashboardLink.data";
import {
  TopNavSettingsLink,
  topNavSettingsTabs,
  TopNavSettingsTab,
} from "./TopNavSettingsLink.data";
import {
  TopNavTeamLink,
  topNavTeamTabs,
  TopNavTeamTab,
} from "./TopNavTeamLink.data";

export enum TopNavType {
  Dashboard = "Dashboard",
  Settings = "Settings",
  Team = "Team",
}

export interface TopNavTab {
  route: string;
}

export type TopNavTabType =
  | TopNavDashboardTab
  | TopNavSettingsTab
  | TopNavTeamTab;

export type TopNavLink =
  | TopNavDashboardLink
  | TopNavSettingsLink
  | TopNavTeamLink;

export function topNavTypeToTabsMapper(type: TopNavType) {
  switch (type) {
    case TopNavType.Dashboard:
      return topNavDashboardTabs;

    case TopNavType.Settings:
      return topNavSettingsTabs;

    case TopNavType.Team:
      return topNavTeamTabs;
  }
}
