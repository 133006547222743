import React, { FC, useState, useEffect } from "react";
import {
  ListItem,
  Avatar,
  ListItemAvatar,
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useAppSelector } from "../../../store/RootReducers";
import { getNameFirstCharacters } from "../../../helper/HelperFunctions";
import { LABELS } from "./NewCommentConstants.data";
import { NewCommentStyles } from "./NewCommentStyles.data";

interface NewCommentProps {
  onPost: (comment: string) => void;
  error: boolean;
  postingComment: boolean;
  onCancel?: () => void;
  clearField?: boolean;
}

const NewComment: FC<NewCommentProps> = ({
  onPost,
  error,
  postingComment,
  onCancel,
  clearField,
}) => {
  // state
  const [newComment, setNewComment] = useState<string>("");

  //other hooks
  const classes = NewCommentStyles();
  const { logo: userImage, firstName, lastName } = useAppSelector(
    (state) => state.login.userDetails!
  );

  // Effects
  useEffect(() => {
    if (clearField) {
      setNewComment("");
    }
  }, [clearField]);

  // variables and functions
  const disablePostButton = newComment.trim().length === 0;
  return (
    <>
      <ListItem alignItems="flex-start">
        {/* User image */}
        <ListItemAvatar>
          <Avatar src={userImage}>
            {getNameFirstCharacters(firstName, lastName)}
          </Avatar>
        </ListItemAvatar>

        {/* Input field for comment */}
        <Box width="100%">
          <TextField
            className={classes.newCommentTextArea}
            InputProps={{
              disableUnderline: true,
              className: classes.newCommentInput,
              value: newComment,
            }}
            fullWidth
            multiline
            inputProps={{ style: { padding: ".5em" } }}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder={LABELS.addComment}
            variant="filled"
          />

          {/* Post comment error */}
          {error && (
            <Box mt={1}>
              <Typography variant="body2" color="error">
                {LABELS.postError}
              </Typography>
            </Box>
          )}
        </Box>
      </ListItem>

      {/* Post button */}
      <Box mt={1} textAlign="right" className={classes.buttonsSection} pr={2}>
        {/* Cancel button for replies */}
        {onCancel && (
          <Button
            onClick={() => {
              setNewComment("");
              onCancel();
            }}
            size="large"
            variant="outlined"
            className={classes.cancelButton}
            color={"default"}>
            {LABELS.cancel}
          </Button>
        )}

        {postingComment || disablePostButton ? (
          <Button
            size="large"
            className={classes.postButton}
            disabled={disablePostButton}
            variant="outlined">
            {disablePostButton ? LABELS.post : <CircularProgress size={20} />}
          </Button>
        ) : (
          <Button
            onClick={() => onPost(newComment)}
            size="large"
            className={classes.postButton}
            color={"default"}>
            {LABELS.post}
          </Button>
        )}
      </Box>
    </>
  );
};

export default NewComment;
