import { CommentResourceType } from "../../models/comment/Comment";

export interface PostCommentRequestPayload {
  resourceId: string;
  commentResourceType: CommentResourceType;
  content: string;
  parentId: string | null;
  userId: number;
  source: CommentSource;
}

export enum CommentSource {
  TRADES = "TRADES",
  TENANCY_PORTAL = "TENANCY_PORTAL",
}

export interface GetCommentsRequestPayload {
  resourceId: string;
}

export enum TradesCommentErrorType {
  PostError = "POST",
  GetError = "GET",
  PostReplyError = "POST_REPLY_ERROR",
}
