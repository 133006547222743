import {
  LoginPayload,
  RegisterUserPayload,
  VerificationLinkPayload,
  VerifyUserPayload,
  RefreshTokenPayload,
  FindUserPayload,
  LoginResponse,
  RegisterUserResponse,
  RegisterClientResponse,
  RefreshTokenResponse,
  GetUserResponse,
  ForgotPasswordPayload,
  ResetPasswordPayload,
  ValidateTokenPayload
} from "../../services/login/LoginData";
import {
  UpdateUserPayload,
  UpdateUserResponse,
  UpdatePasswordPayload,
} from "../../services/user/UserData";

export enum LoginActionTypes {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",

  UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST",
  UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR",

  REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST",
  REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS",
  REGISTER_USER_ERROR = "REGISTER_USER_ERROR",

  REGISTER_CLIENT_REQUEST = "REGISTER_CLIENT_REQUEST",
  REGISTER_CLIENT_SUCCESS = "REGISTER_CLIENT_SUCCESS",
  REGISTER_CLIENT_ERROR = "REGISTER_CLIENT_ERROR",

  VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST",
  VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS",
  VERIFY_USER_ERROR = "VERIFY_USER_ERROR",

  VERIFICATION_LINK_REQUEST = "VERIFICATION_LINK_REQUEST",
  VERIFICATION_LINK_SUCCESS = "VERIFICATION_LINK_SUCCESS",
  VERIFICATION_LINK_ERROR = "VERIFICATION_LINK_ERROR",

  REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR",

  GET_USER_REQUEST = "GET_USER_REQUEST",
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  GET_USER_ERROR = "GET_USER_ERROR",

  FORGOT_PASSWORD_LINK_REQUEST = "FORGOT_PASSWORD_LINK_REQUEST",
  FORGOT_PASSWORD_LINK_SUCCESS = "FORGOT_PASSWORD_LINK_SUCCESS",
  FORGOT_PASSWORD_LINK_ERROR = "FORGOT_PASSWORD_LINK_ERROR",

  RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR",

  UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR",

  CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR",

  CLOSE_USER_CONFIRMATION_DIALOG = "CLOSE_USER_CONFIRMATION_DIALOG",

  VALIDATE_INVITE_TOKEN_REQUEST = "VALIDATE_INVITE_TOKEN_REQUEST",
  VALIDATE_INVITE_TOKEN_SUCCESS = "VALIDATE_INVITE_TOKEN_SUCCESS",
  VALIDATE_INVITE_TOKEN_ERROR = "VALIDATE_INVITE_TOKEN_ERROR",
}

export interface LoginAction {
  type: LoginActionTypes;
  data?:
  | LoginPayload
  | RegisterUserPayload
  | VerificationLinkPayload
  | ForgotPasswordPayload
  | VerifyUserPayload
  | ResetPasswordPayload
  | UpdatePasswordPayload
  | FindUserPayload
  | RefreshTokenPayload
  | UpdateUserPayload
  | RegisterClientResponse
  | RegisterUserResponse
  | LoginResponse
  | RefreshTokenResponse
  | GetUserResponse
  | ValidateTokenPayload
  | Error;
}

export class LoginActions {
  public static postLoginStart(data: LoginPayload): LoginAction {
    return {
      type: LoginActionTypes.LOGIN_REQUEST,
      data,
    };
  }
  public static postLoginSuccess(data: LoginResponse): LoginAction {
    return {
      type: LoginActionTypes.LOGIN_SUCCESS,
      data,
    };
  }
  public static postLoginError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.LOGIN_ERROR,
      data: error,
    };
  }

  public static getUserDetails(): LoginAction {
    return {
      type: LoginActionTypes.GET_USER_REQUEST,
    };
  }
  public static getUserDetailsSuccess(data: GetUserResponse): LoginAction {
    return {
      type: LoginActionTypes.GET_USER_SUCCESS,
      data,
    };
  }
  public static getUserDetailError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.GET_USER_ERROR,
      data: error,
    };
  }

  public static putUserDetailsStart(data: UpdateUserPayload): LoginAction {
    return {
      type: LoginActionTypes.UPDATE_USER_DETAILS_REQUEST,
      data,
    };
  }
  public static putUserDetailsSuccess(data: UpdateUserResponse): LoginAction {
    return {
      type: LoginActionTypes.UPDATE_USER_DETAILS_SUCCESS,
      data,
    };
  }
  public static putUserDetailsError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.UPDATE_USER_DETAILS_ERROR,
      data: error,
    };
  }

  public static postRegisterUserStart(data: RegisterUserPayload): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_USER_REQUEST,
      data,
    };
  }
  public static postRegisterUserSuccess(
    data: RegisterUserResponse
  ): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_USER_SUCCESS,
      data,
    };
  }
  public static postRegisterUserError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_USER_ERROR,
      data: error,
    };
  }

  public static getClientTokenStart(): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_CLIENT_REQUEST,
    };
  }
  public static getClientTokenSuccess(
    data: RegisterClientResponse
  ): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_CLIENT_SUCCESS,
      data,
    };
  }
  public static getClientTokenError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_USER_ERROR,
      data: error,
    };
  }

  public static postVerificationLinkStart(
    data: VerificationLinkPayload
  ): LoginAction {
    return {
      type: LoginActionTypes.VERIFICATION_LINK_REQUEST,
      data,
    };
  }
  public static postVerificationLinkSuccess(): LoginAction {
    return {
      type: LoginActionTypes.VERIFICATION_LINK_SUCCESS,
    };
  }
  public static postVerificationLinkError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.VERIFICATION_LINK_ERROR,
      data: error,
    };
  }

  public static postVerifyUserStart(data: VerifyUserPayload): LoginAction {
    return {
      type: LoginActionTypes.VERIFY_USER_REQUEST,
      data,
    };
  }
  public static postVerifyUserSuccess(): LoginAction {
    return {
      type: LoginActionTypes.VERIFY_USER_SUCCESS,
    };
  }
  public static postVerifyUserError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.VERIFY_USER_ERROR,
      data: error,
    };
  }

  public static postRefreshTokenStart(data: RefreshTokenPayload): LoginAction {
    return {
      type: LoginActionTypes.REFRESH_TOKEN_REQUEST,
      data,
    };
  }
  public static postRefreshTokenSuccess(
    data: RefreshTokenResponse
  ): LoginAction {
    return {
      type: LoginActionTypes.REFRESH_TOKEN_SUCCESS,
      data,
    };
  }
  public static postRefreshTokenError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.REFRESH_TOKEN_ERROR,
      data: error,
    };
  }

  public static postForgotPasswordLinkStart(
    data: ForgotPasswordPayload
  ): LoginAction {
    return {
      type: LoginActionTypes.FORGOT_PASSWORD_LINK_REQUEST,
      data,
    };
  }
  public static postForgotPasswordSuccess(): LoginAction {
    return {
      type: LoginActionTypes.FORGOT_PASSWORD_LINK_SUCCESS,
    };
  }
  public static postForgotPasswordError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.FORGOT_PASSWORD_LINK_ERROR,
      data: error,
    };
  }

  public static resetPasswordStart(data: ResetPasswordPayload): LoginAction {
    return {
      type: LoginActionTypes.RESET_PASSWORD_REQUEST,
      data,
    };
  }
  public static resetPasswordSuccess(): LoginAction {
    return {
      type: LoginActionTypes.RESET_PASSWORD_SUCCESS,
    };
  }
  public static resetPasswordError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.RESET_PASSWORD_ERROR,
      data: error,
    };
  }

  public static updatePasswordStart(data: UpdatePasswordPayload): LoginAction {
    return {
      type: LoginActionTypes.UPDATE_PASSWORD_REQUEST,
      data,
    };
  }
  public static updatePasswordSuccess(): LoginAction {
    return {
      type: LoginActionTypes.UPDATE_PASSWORD_SUCCESS,
    };
  }
  public static updatePasswordError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.UPDATE_PASSWORD_ERROR,
      data: error,
    };
  }

  public static clearLoginErrror() {
    return { type: LoginActionTypes.CLEAR_LOGIN_ERROR };
  }

  public static closeUserConfirmationDialog() {
    return { type: LoginActionTypes.CLOSE_USER_CONFIRMATION_DIALOG };
  }

  public static postValidateTokenStart(data: ValidateTokenPayload): LoginAction {
    return {
      type: LoginActionTypes.VALIDATE_INVITE_TOKEN_REQUEST,
      data,
    };
  }
  public static postValidateTokenSuccess(
    data: GetUserResponse
  ): LoginAction {
    return {
      type: LoginActionTypes.VALIDATE_INVITE_TOKEN_SUCCESS,
      data,
    };
  }
  public static postValidateTokenError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.VALIDATE_INVITE_TOKEN_ERROR,
      data: error,
    };
  }
}
