import { makeStyles } from "@material-ui/core";

export const detailListItemStyles = makeStyles({
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: "-0.5em",
  },
  label: {
    marginTop: ".5em",
  },
});
