import {
  QuotesAction,
  QuotesActions,
  QuotesActionType,
} from "../actions/QuotesActions";
import { put, takeEvery } from "redux-saga/effects";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import {
  GetQuotesResponse,
  GetQuotesRequest,
  GetQuoteRequest,
  SubmitQuoteRequestData,
  UpdateQuoteRequest,
  GetQuoteResponse,
} from "../../services/quotes/QuotesData.data";
import QuotesService from "../../services/quotes/QuotesService";
import { Quote } from "../../models/quote/Quote.data";

// get quotes list
export function* getQuotesWorker(action: QuotesAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const { onSuccess, ...data } = action.data as GetQuotesRequest;
    const response: GetQuotesResponse = (yield QuotesService.post(
      data as GetQuotesRequest
    ))!;

    yield put(QuotesActions.GetQuotesSuccess(response));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(QuotesActions.GetQuotesError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

// get single quote with ID
export function* getQuoteWorker(action: QuotesAction): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const { quoteId, onSuccess } = action.data as GetQuoteRequest;
    const response: GetQuoteResponse = (yield QuotesService.getQuote({
      quoteId,
    }))!;
    yield put(QuotesActions.GetQuoteSuccess(response));

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    yield put(QuotesActions.GetQuoteError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* submitQuoteWorker(
  action: QuotesAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const { request, onSuccess } = action.data as SubmitQuoteRequestData;
    const response: Quote = (yield QuotesService.submitQuote(request))!;

    if (onSuccess) {
      onSuccess(response);
    }

    yield put(QuotesActions.SubmitQuoteSuccess(response));
  } catch (error) {
    yield put(QuotesActions.SubmitQuoteError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* updateQuoteStatusWorker(
  action: QuotesAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());
    const { status, quoteId, onSuccess } = action.data as UpdateQuoteRequest;
    const response: Quote = (yield QuotesService.setQuoteStatus(
      status,
      quoteId
    ))!;

    if (onSuccess) {
      onSuccess(response);
    }

    yield put(QuotesActions.UpdateQuoteStatusSuccess(response));
  } catch (error) {
    yield put(QuotesActions.UpdateQuoteStatusError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const quotesWatcher = [
  takeEvery(QuotesActionType.GetQuotesRequest, (action) =>
    getQuotesWorker({
      type: action.type,
      data: (action as QuotesAction).data,
    })
  ),

  takeEvery(QuotesActionType.SubmitQuoteRequest, (action) =>
    submitQuoteWorker({
      type: action.type,
      data: (action as QuotesAction).data,
    })
  ),

  takeEvery(QuotesActionType.UpdateQuoteStatusRequest, (action) =>
    updateQuoteStatusWorker({
      type: action.type,
      data: (action as QuotesAction).data,
    })
  ),

  takeEvery(QuotesActionType.GetQuoteRequest, (action) =>
    getQuoteWorker({
      type: action.type,
      data: (action as QuotesAction).data,
    })
  ),
];

export default quotesWatcher;
