import { makeStyles } from "@material-ui/core";
import { color } from "../../../../theme/Theme";

export const AddArtefactsStyles = makeStyles({
  spaceTop: {
    marginTop: "2em",
  },
  fieldsWrapper: {
    flexGrow: 1,
    justifyContent: "space-evenly",
  },
  navButton: {
    width: "124px",
    height: "32px",
  },
  buttonsSection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1.5em",
  },
  modalHeader: {
    display: "flex",
    marginBottom: "1.5em",
  },
  heading: {
    flexGrow: 1,
  },
  closeIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  formContentWrapper: {
    marginTop: "2rem",
    flexGrow: 1,
  },
  input: { display: "none" },
  requiredFieldAsterisk: {
    color: color.warning,
    marginLeft: "5px",
  },
  attention: {
    fontWeight: "bold",
  },
  attentionMessageContainer: {
    padding: "1rem",
    background: "rgba(255, 82, 91, 0.1)",
  },
  uploadButton: {
    background: "rgba(198,203,212,.15)",
    color: color.secondary200,
    padding: ".6em 1em",
    borderRadius: "4px",
    "&:hover": {
      background: "rgba(198,203,212,.15)",
    },
  },
  documentListItem: {
    padding: "1em 0",
  },
  commentsTextArea: {
    background: color.secondary,
    fontSize: "14px",
    padding: "1em",
    fontFamily: "GT Walsheim light",
  },
});
