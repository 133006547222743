import { ApiServiceMockBase } from "./ApiServiceMockBase";
// import { Logging } from "../../helper/Logging";
import * as axios from "axios";

export class LoginApiServiceMock extends ApiServiceMockBase {
    public call<T = void>(instance: axios.AxiosInstance): T {
        // Logging.debug("LoginApiServiceMock.post", {route: path.route, query: path.query})
        // throw new Error("authToken expired") as any as T
        return {
            "authToken": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMTAwMDAxIiwidXNlcm5hbWUiOiJ0cF9hZG1pbkBzbGVldmVzdXAuY29tLmF1Iiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl0sInJvbGVzIjoidXNlcixhZG1pbiIsImV4cCI6MTUzODk3NzUzNywiYXV0aG9yaXRpZXMiOlsidXNlciIsImFkbWluIl0sImp0aSI6IjgzMjc5ZjllLTk5M2QtNDAzZi1iMDlkLWYxM2M0M2U1ZGUxZSIsImNsaWVudF9pZCI6ImQ1NDk0MTRiLTg2OTctNDExYS04NGM1LWViZjVkMTZmNjczMyIsImFwcGxpY2F0aW9uX2lkIjoxMSwiZW52aXJvbm1lbnQiOiJkZXYifQ.WTjqLz8Kd3goS5XW92z9Ejm8I3rXYBPxyXjitdwjD7ZGRM6Lg2ER0T5Xo7JDaPNJMN-UDtXU80ep3m3QeSmBuR8OJhtO38MaOzQ3axmwNkoJQmbh_UgsLkrZd0gc8Z0PYLX6WMxuiFc6JsCPuxA2RPubuBCoyrB6ZrVrIBvv_QF61aTPgOULDkZV4hEm2xWC85cjiTFGTELKOj2_5n6yKx2GoxNIl7O3ttU0njGS6JWKWsM7pkA6MJnsCNyb2ld7UtWu6TgwwIAi5pgX5gAIKIj3JPu1dzYR809QnQwfVTsjBt6tuFW1BWdCWFNImU2sQ_oBiu-2RXRl9sHtAdEdNg",
            "refreshToken": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMTAwMDAxIiwidXNlcm5hbWUiOiJ0cF9hZG1pbkBzbGVldmVzdXAuY29tLmF1Iiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl0sInJvbGVzIjoidXNlcixhZG1pbiIsImV4cCI6MTUzOTIzMzEzNywiYXV0aG9yaXRpZXMiOlsidXNlciIsImFkbWluIl0sImp0aSI6ImE5MDQ2NjY4LWI4MjQtNDFiNC04OGRmLWIwOTM3ZDYzZWM2YSIsImNsaWVudF9pZCI6ImQ1NDk0MTRiLTg2OTctNDExYS04NGM1LWViZjVkMTZmNjczMyIsImFwcGxpY2F0aW9uX2lkIjoxMSwiZW52aXJvbm1lbnQiOiJkZXYiLCJhdGkiOiI4MzI3OWY5ZS05OTNkLTQwM2YtYjA5ZC1mMTNjNDNlNWRlMWUifQ.JZL72vgEPsfXnvt7KSQg5Dl_rJ2RLcOZ0kTO3K5Jn5s9ZmcSzZHqi7JOkoLuW1S5BkvYQ5-gLsJYvFGG59_rL_C8F7AlWsdDQ9UKx2JhtKFKAOoTloRtXWBzYD5E-_r8c-c-vfd7OCTlmmj4a9LtdqKceRNDwgFPpPftLHZENsPV8oFukm4d9UPSG09_GTdutOjklu_Yp-KWdIbC5ViaFSiY1GKZqSl3dMUQsDYlQqXQritlV8Kr6OsHKyqVDTyafzV3jmEvrJELouHA28ng8qM8Fz4m_cFxbxSuhOEah_SZsuwGg_I7UXdiiJ7HkDa9NQCQMLJsPSJ4wLCqhgLOIg"
        } as any as T;
    }
}