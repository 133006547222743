import { DocType } from "../../component/addTradesDocuments/addDocModal/AddDocForm.data";
import { TradeCategory } from "../tradeCategories/TradeCategories.data";

export const TRADE_CATEGORIES_PATH = "trade-categories";
export const TRADE_CATEGORY_PATH = "trade-category";

// Post User Trade categories
export interface PostUserTradeCategoriesRequestObject {
  tradeCategoryId: number;
}

export interface PostUserTradeCategoriesResponseObject {
  userId: number;
  id: number;
  tradeCategoryId: number;
}

export type PostUserTradeCategoriesResponse =
  PostUserTradeCategoriesResponseObject[];

export interface PostUserTradeCategoriesRequestPayload {
  request: PostUserTradeCategoriesRequestObject[];
  onSuccess?:
    | ((response: PostUserTradeCategoriesResponse) => void)
    | undefined
    | null;
}

// Delete user trade categories
export interface DeleteUserTradeCategoryRequestPayload {
  tradeCategoryId: number;
}

export interface DeleteUserTradeCategoryRequest {
  request: DeleteUserTradeCategoryRequestPayload;
  onSuccess?: () => void;
}

export interface DeleteUserTradeCategorySuccessPayload {
  tradeCategoryId: number;
}

// Delete user trade category document
export interface DeleteUserTradeCategoryDocumentRequest {
  userTradeCategoryId: number;
  userTradeCategoryDocumentId: number;
}

export interface DeleteUserTradeCategoryDocumentSuccessPayload {
  userTradeCategoryId: number;
  userTradeCategoryDocumentId: number;
}

//  Get user trade categories
export interface TradeDocument {
  expiryDate: string | null;
  id: number;
  link: string;
  name: string;
  tradeCategoryDocumentType: DocType;
  contentType: string;
}

export interface UserTradeCategoriesDetails {
  id: number;
  tradeCategoryResponse: TradeCategory;
  userId: number;
  userTradeCategoryDocuments: TradeDocument[];
}

export type UserTradeCategoriesResponse = UserTradeCategoriesDetails[];

export interface UserTradeDocumentRequestData {
  name: string;
  tradeCategoryDocumentType: DocType;
  userTradeCategoryId: number;
  expiryDate: string | null;
}

//  Upload user trade document
export interface UserTradeDocumentsUploadData {
  data: UserTradeDocumentRequestData;
  file: File;
}

export interface UserTradeDocumentsPayload {
  file: File;
  userTradeCategoryDocumentRequest: string;
}

export interface UserTradeDocumentUploadResponse {
  expiryDate: string;
  id: number;
  link: string;
  name: string;
  tradeCategoryDocumentType: DocType;
  contentType: string;
}
