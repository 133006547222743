import { makeStyles } from "@material-ui/styles";
import { color } from "../../../../../theme/Theme";

export const ArtefactsSectionStyles = makeStyles({
  pointer: {
    cursor: "pointer",
  },
  documentListItem: {
    padding: "1em",
    border: "none",
    display: "flex",
    alignItems: "center",
    width: "100%",
    "&:hover": {
      background: color.wildSand,
    },
    cursor: "pointer",
  },
  categoryHeading: {
    paddingBottom: "1em",
  },
  imageIcon: {
    color: color.teal,
    marginRight: ".5em",
  },
  pdfIcon: {
    color: color.warning,
    marginRight: ".5em",
  },
  pdfNumberDisplay: {
     width: "50%"
  }
});
