import React, { FC } from "react";
import { Formik, Field } from "formik";
import {
  UpdateBranchFormFields,
  UpdateBranchFormFieldNames,
  UpdateBranchFormFieldLabels,
} from "./UpdateBranchFormData.data";
import RenderInput from "../../../../FormikComponents/RenderInputField";
import {
  inputStylePropsGenerator,
  RenderInputFieldStyles,
} from "../../../../FormikComponents/RenderInputFieldStyle";
import {
  Grid,
  Box,
  FormControlLabel,
  Switch,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import RenderAddressPicker from "../../../../AddressPicker/RenderAddressPicker";
import { AddressValue } from "../../../../../helper/GooglePlaces/AddressValue.data";
import { AddressComponent } from "../../../../AddressPicker/AddressPicker.data";
import {
  getRegistrationFormAddressComponent,
  addressStringGeneratorFromBranchDetails,
} from "../../../../../helper/HelperFunctions";
import DashboardButton from "../../../dashboardButton/DashboardButton";
import { OfficeDetails } from "../../../../../services/registerBusiness/RegistrationFormRequest.data";
import { BRANCH_FORM_VALIDATION_SCHEMA } from "../AccountSettingsConstants.data";
import RenderCheckbox from "../../../../FormikComponents/RenderCheckbox";

interface UpdateBranchFormProps {
  onSubmit: (values: UpdateBranchFormFields) => void;
  initialValues: UpdateBranchFormFields;
}

const UpdateBranchesForm: FC<UpdateBranchFormProps> = (props) => {
  const renderInputProps = inputStylePropsGenerator(RenderInputFieldStyles());
  const { onSubmit, initialValues } = props;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.only("xs"));

  // helper
  const blankPlaceComponents = {
    country: undefined,
    postcode: undefined,
    state: undefined,
    streetName: undefined,
    streetNumber: undefined,
    suburb: undefined,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validationSchema={BRANCH_FORM_VALIDATION_SCHEMA}
      enableReinitialize>
      {({ setValues, handleSubmit, values, isValid, dirty }) => (
        <Box mt={2}>
          <Grid container>
            <Grid item xs={12}>
              <Field type="checkbox" name="covidApproved">
                {(fieldProps) => (
                  <RenderCheckbox
                    {...fieldProps}
                    label={UpdateBranchFormFieldLabels.covidVaccinated}
                    checked={!!values.covidApproved}
                    color="primary"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name={UpdateBranchFormFieldNames.branchName}>
                {({ form, field }) => (
                  <RenderInput
                    label={UpdateBranchFormFieldLabels.branchName}
                    {...renderInputProps}
                    {...field}
                  />
                )}
              </Field>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box pr={isSmall ? 0 : 2}>
                <Field name={UpdateBranchFormFieldNames.branchMail}>
                  {({ form, field }) => (
                    <RenderInput
                      label={UpdateBranchFormFieldLabels.branchMail}
                      {...renderInputProps}
                      {...field}
                    />
                  )}
                </Field>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box pl={isSmall ? 0 : 2}>
                <Field name={UpdateBranchFormFieldNames.branchPhone}>
                  {({ form, field }) => (
                    <RenderInput
                      label={UpdateBranchFormFieldLabels.branchPhone}
                      {...renderInputProps}
                      {...field}
                    />
                  )}
                </Field>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Field name={UpdateBranchFormFieldNames.branchAddress}>
                {({ form, field }) => (
                  <RenderAddressPicker
                    onChange={(value: AddressValue) => {
                      if (value.place) {
                        const branchAddress =
                          getRegistrationFormAddressComponent(
                            value.place!
                              .address_components! as AddressComponent[]
                          );
                        setValues({
                          ...values,
                          ...(blankPlaceComponents as Partial<OfficeDetails>),
                          ...(branchAddress as Partial<OfficeDetails>),
                          latitude: value.place.geometry?.location.lat(),
                          longitude: value.place.geometry?.location.lng(),
                        });
                      }
                    }}
                    placeholder={addressStringGeneratorFromBranchDetails(
                      initialValues
                    )}
                    endAdornmentPosition="start"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              {initialValues.primary ? (
                <Box my={1}>
                  <Typography variant="caption">
                    This is the head office
                  </Typography>
                </Box>
              ) : (
                <Field type="checkbox" name="primary">
                  {({ form, field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          onChange={(e) =>
                            form.setFieldValue(
                              "primary",
                              e.currentTarget.checked
                            )
                          }
                          name="tracker"
                          {...field}
                        />
                      }
                      label={
                        <Typography variant="h4">{"Head office"}</Typography>
                      }
                    />
                  )}
                </Field>
              )}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" pr={2}>
            <DashboardButton
              variant={"outlined"}
              color={!dirty || !isValid ? "inherit" : undefined}
              greyText={!dirty || !isValid}
              text={"Save"}
              action={handleSubmit}
              disabled={!dirty || !isValid}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default UpdateBranchesForm;
