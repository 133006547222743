import { StoreAction } from "../StoreHelper";

export enum LoadingIndicatorActionTypes {
  // Shows the indicator after 100ms
  ShowIndicator = "SHOW_LOADING",

  // Decreases the counter. Hides the indicator, if counter reaches zero.
  HideIndicator = "HIDE_LOADING",
}

export type LoadingIndicatorAction = StoreAction<
  LoadingIndicatorActionTypes,
  null
>;

export class LoadingIndicatorActions {
  // Shows the indicator after 100ms
  public static show(): LoadingIndicatorAction {
    return { type: LoadingIndicatorActionTypes.ShowIndicator, data: null };
  }

  // Decreases the counter. Hides the indicator, if counter reaches zero.
  public static hide(): LoadingIndicatorAction {
    return { type: LoadingIndicatorActionTypes.HideIndicator, data: null };
  }
}
