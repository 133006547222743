import { makeStyles } from "@material-ui/core";
import { color } from "../../../../../theme/Theme";

export const ConfirmJobBookingStyles = makeStyles({
  expansionSection: {
    marginTop: "2rem",
  },
  selectContainer: {
    marginTop: 0,
  },
  pointer: {
    cursor: "pointer",
  },
  buttonSection: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: "1em",
    },
  },
  button: {
    width: "160px",
    height: "40px",
    borderRadius: "4px",
  },
  tealButton: {
    backgroundColor: color.teal,
    color: color.light,
    textAlign: "center",
    "&:hover": {
      backgroundColor: color.teal,
    },
  },
  assigneeNameCardWrapper: {
    background: "#f6f6f6",
    borderRadius: "4px",
    border: `1px solid ${color.lightGrey}`,
    padding: "0px 10px",
    marginBottom: "20px",
  },
  collapsedDetailsListKey: {
    fontFamily: "GT Walsheim",
    fontSize: "16px",
  },
  collapsedDetailsListItem: {
    padding: "0",
  },
  summaryHeading: {
    marginBottom: "1em",
  },
  summaryDetailsClickableItem: {
    "&:hover": {
      background: color.wildSand,
    },
  },
});
