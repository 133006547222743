import { Moment } from "moment";
import * as Yup from "yup";

export const LABELS = {
  jobDetails: "Job Details",
  workorder: "Workorder",
  quoteDetails: "Quote Details",
  bookingDetails: "Booking Details",
  jobDateAndTime: "Job date and time",
  jobDate: "Job date",
  jobTime: "Job time",
  tradesmanDetails: "Tradesperson details",
  tradespersonName: "Tradesperson name",
  jobSummary: "Job summary",
  confirmBooking: "Confirm booking",
  propertyManager: "Property manager",
  tenant: "Tenant",
  contact: "Contact",
  alternateContact: "Alternate contact",
  tenantDetails: "Tenant details",
  update: "Update",
  completeJob: "Complete Job",
  addArtefacts: "Add artefacts",
  updateArtefacts: "Update artefacts",
  sendForPayment: "Send for payment",
  addVariation: "Add variation",
  variationQuoteDetails: "Variation Quote Details",
  variationJobDetails: "Variation job details",
  sendForPaymentErrorText:
    "At least one job image and one invoice are required before sending a payment request",
  jobCompleteError:
    "At least one job image and one invoice are required before marking complete.",
  variationQuotes: "Variation Quotes",
  variation: "Variation",
  original: "Original",
  submitVariationQuote: "Submit variation",
  edit: "Edit",
  updateCost: "Update Cost",
  jobEstimate: "Job estimate",
  quotedDates: "Quoted dates",
  startDate: "Start date",
  completionDate: "Completion date",
  nonZeroCost: "Please enter a valid cost before sending for approval",
  agent: "Agent",
  title: "Title",
  description: "Description",
  workorderId: "Workorder ID",
  workorderNotes: "Workorder notes",
  keyNumber: "Key number",
  scopeOfWork: "Scope of work",
  address: "Address",
  jobCategory: "Job category",
  optionsSummary: "Options Summary",
  maintenanceRequestImages: "Maintenance request images",
  bookingImages: "Booking images",
  costUpdateNote: "Cost update note",
};

export interface ConfirmJobBookingFormValues {
  time: BookingTime | "";
  date: Moment | null;
  assignedUserId: number[];
  startTime?: Moment;
  endTime?: Moment;
}

export interface BookingTime {
  timeOfDay: string;
  clockTimeRange: string;
}

export const BOOKING_TIME_OPTIONS: BookingTime[] = [
  {
    timeOfDay: "Morning",
    clockTimeRange: "8am-12pm",
  },
  {
    timeOfDay: "Afternoon",
    clockTimeRange: "1pm-5pm",
  },
];

export const CONFIRM_BOOKING_FORM_VALIDATION = Yup.object().shape({
  assignedUserId: Yup.array().required(
    "At least one user needs to be assigned the job"
  ),
  date: Yup.mixed().test("testValidDate", "Invalid Date", (value: Moment) => {
    return !!value && value.isValid();
  }),
});
