import { LoginState } from "../state/LoginState";
import { LoginAction, LoginActionTypes } from "../actions/LoginActions";
import {
  LoginResponse,
  RegisterUserResponse,
  RefreshTokenResponse,
  RegisterClientResponse,
  GetUserResponse,
} from "../../services/login/LoginData";
import {
  UserDocumentsAction,
  UserDocumentsActionType,
} from "../actions/UserDocumentsActions";
import { cloneDeep } from "lodash";
import { UserDocument } from "../../models/userDocument/UserDocument.data";
import { DeleteUserDocumentRequest } from "../../services/userDocumentsService/UserDocuments.data";
import { UpdateUserResponse } from "../../services/user/UserData";

export const initialState: LoginState = {
  error: "",
  loading: false,
  loginDetails: undefined,
  registerUserDetails: undefined,
  registerClientDetails: undefined,
  refreshTokenDetails: undefined,
  userDetails: undefined,
  guestToken: undefined,
};

export function loginReducer(state = initialState, action: LoginAction) {
  state = postLoginReducer(state, action);
  return state;
}

/* tslint:disable:cyclomatic-complexity */
function postLoginReducer(
  state: LoginState,
  action: LoginAction | UserDocumentsAction
) {
  switch (action.type) {
    case LoginActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loginDetails: action.data as LoginResponse,
      };
    case LoginActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.UPDATE_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.UPDATE_USER_DETAILS_SUCCESS:
      const data = action.data as UpdateUserResponse;
      const { userDocuments, ...newData } = data;
      return {
        ...state,
        loading: false,
        // tslint:disable-next-line: no-object-literal-type-assertion
        userDetails: ({ ...state.userDetails, ...newData } as GetUserResponse),
      };
    case LoginActionTypes.UPDATE_USER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.REGISTER_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        registerUserDetails: action.data as RegisterUserResponse,
      };
    case LoginActionTypes.REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.REGISTER_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.REGISTER_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        registerClientDetails: action.data as RegisterClientResponse,
      };
    case LoginActionTypes.REGISTER_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        refreshTokenDetails: action.data as RefreshTokenResponse,
      };
    case LoginActionTypes.REFRESH_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.VERIFICATION_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.VERIFICATION_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LoginActionTypes.VERIFICATION_LINK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.VERIFY_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.VERIFY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LoginActionTypes.VERIFY_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case LoginActionTypes.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.data as GetUserResponse,
        error: "",
      };
    case LoginActionTypes.GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        userDetails: undefined,
        error: action.data,
      };
    case LoginActionTypes.CLEAR_LOGIN_ERROR: {
      return {
        ...state,
        error: null,
        userDetails: null,
      };
    }
    case LoginActionTypes.CLOSE_USER_CONFIRMATION_DIALOG: {
      return {
        ...state,
        showUserConfirmationDialog: false,
      };
    }
    case LoginActionTypes.FORGOT_PASSWORD_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.FORGOT_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LoginActionTypes.FORGOT_PASSWORD_LINK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case LoginActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LoginActionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
      case LoginActionTypes.UPDATE_PASSWORD_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case LoginActionTypes.UPDATE_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case LoginActionTypes.UPDATE_PASSWORD_ERROR:
        return {
          ...state,
          loading: false,
          error: action.data,
        };
    // User Documents Actions
    case UserDocumentsActionType.PostUserDocumentSuccess: {
      const document = action.data as UserDocument;
      const newUserDetails = cloneDeep(state.userDetails);
      newUserDetails!.userDocuments.push(document);
      return {
        ...state,
        error: null,
        userDetails: newUserDetails,
      };
    }

    case UserDocumentsActionType.PostUserDocumentError: {
      const { data } = action;
      return {
        ...state,
        error: data,
      };
    }

    case UserDocumentsActionType.DeleteUserDocumentSuccess: {
      const documentId = (action.data as DeleteUserDocumentRequest)
        .userDocumentId;
      let newUserDetails = cloneDeep(state.userDetails);
      newUserDetails!.userDocuments = newUserDetails!.userDocuments.filter(
        (document) => document.id !== documentId
      );
      return {
        ...state,
        error: null,
        userDetails: newUserDetails,
      };
    }

    case UserDocumentsActionType.DeleteUserDocumentError: {
      const { data } = action;
      return {
        ...state,
        error: data,
      };
    }

    case LoginActionTypes.VALIDATE_INVITE_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.VALIDATE_INVITE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.data as GetUserResponse,
        error: "",
      };
    case LoginActionTypes.VALIDATE_INVITE_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        userDetails: undefined,
        error: action.data,
      };

    default:
      return state;
  }
}
