export const ADMIN_ROLE = "trade_admin";

export interface UserTradeEntitlement {
  id?: number | null;
  officeId?: number | null;
  tradeId?: number | null;
  userId: number | null;
  role: string;
}
export class SignUpRequest {
  public readonly firstName?: string | undefined;
  public readonly lastName?: string | undefined;
  public readonly email: string | null | undefined;
  public readonly username: string;
  public readonly password: string | null | undefined;
  public readonly agency?: string | null | undefined;
  public readonly userTradeEntitlements: UserTradeEntitlement[];
}
