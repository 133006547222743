import { makeStyles } from "@material-ui/core/styles";
import { pxToem } from "../../../helper/Validation";
import { color } from "../../../theme/Theme";

export const WrapperStyles = makeStyles({
  container: {
    position: "relative",
    width: "100%",
    display: "flex",
    minHeight: "100%",
    fontFamily: "GT Walsheim",
  },
  contentSection: {
    marginTop: "36px",
    padding: "16px",
    paddingTop: "0px",
    flexGrow: 1,
  },
  contentBox: {
    paddingTop: "16px",
    borderRadius: "6px",
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
  },
  validationText: {
    marginTop: "-20px",
  },
  formCardContainer: {
    borderRadius: "8px",
    border: "solid 1px #eeeeee",
    backgroundColor: color.light,
    margin: "auto",
    padding: "3em 2.5em 2.5em 2.5em",
    display: "flex",
    flexDirection: "column",
    minHeight: "70vh",
    "@media all and (max-width: 600px)": {
      margin: "0px",
      borderRadius: "0px",
    },
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    padding: "35px 15%",
    boxShadow:
      "0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "white",
    height: "100%",
    "@media all and (max-width: 1023px)": {
      overflowX: "auto",
    },
    "@media all and  (max-width: 1280px)": {
      padding: "35px 10%",
    },
  },
  buttonContainer: {
    marginTop: "3em",
    textAlign: "center",
  },
  bottomLink: {
    margin: 0,
    paddingTop: "24px",
    fontWeight: 300,
    textAlign: "center",
    fontColor: "black",
    marginLeft: pxToem(10),
  },
  errorMessageStyles: {
    color: "red",
  },
  fullHeight: {
    height: "100%",
    overflow: "auto",
  },
  fieldsContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonsSection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1.5em",
    padding: "0em 1em",
    "@media all and (max-width: 480px)": {
      padding: "0px",
    },
  },
  footerComponent: {
    flexGrow: 1,
  },
  navButton: {
    width: "124px",
    height: "32px",
  },
  noPadding: {
    padding: "0px !important",
  },
  greyBackground: {
    backgroundColor: "#f9f9f9",
  },
});
