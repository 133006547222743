import * as React from "react";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visible from "@material-ui/icons/Visibility";
import Hidden from "@material-ui/icons/VisibilityOff";

/// <summary>
/// Value is string
/// </summary>
/* tslint:disable:cyclomatic-complexity */
const RenderInput = (field: any) => {
  const hasError = !!field.meta.error && !!field.meta.touched;
  const errorMessage = field.errorMessage;
  const hasSecondaryError = !!field.secondaryValidationMessage;
  const highlightSecondaryError = !!field.secondaryValidationHighlight;

  const [hidePassword, setHidePassword] = React.useState(true);

  return (
    <div className="item-render" style={field.itemRenderStyle}>
      <FormControl error={hasError} fullWidth>
        {field.label && (
          <InputLabel
            style={field.labelStyle}
            classes={field.overrideLabelStyles}
            htmlFor={field.input.name}>
            {field.label}
          </InputLabel>
        )}
        {!field.hidden && (
          <Input
            type={hidePassword ? field.type : "text"}
            id={field.input.name}
            value={field.input.value || ""} // string
            onChange={field.input.onChange}
            onBlur={field.input.onBlur}
            disabled={field.disabled}
            classes={field.overrideInputStyles}
            //inputRef={(elem: HTMLInputElement) => { if (field.inputRef) { field.inputRef(elem); } }}
            style={field.style}
            //Browsers have no reasons to limit the year field to 4 digits by default.
            //So we should specify max attribute.
            inputRef={(e: any) => (e ? (e.max = "9999-12-31") : false)}
            endAdornment={
              field.type === "password" && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setHidePassword(!hidePassword)}>
                    {hidePassword ? <Visible /> : <Hidden />}
                  </IconButton>
                </InputAdornment>
              )
            }
            inputProps={{
              style: field.inputStyle,
              className: field.inputClassName,
              placeholder: field.placeholder,
              maxLength: field.maxLength,
              onKeyPress: (e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              },
              ...field.inputProps,
            }}
            disableUnderline={field.disableUnderline}
            {...field.customProps}
          />
        )}
        {hasError && (
          <FormHelperText
            id={`${field.input.name}-error-text`}
            className={field.errorMessageMobileStyle}>
            {field.meta.error}
          </FormHelperText>
        )}
        {hasSecondaryError && (
          <FormHelperText
            id={`${field.input.name}-error2-text`}
            error={highlightSecondaryError}
            required={true}>
            {field.secondaryValidationMessage}
          </FormHelperText>
        )}
        {!hasError && !!field.helperText && (
          <FormHelperText id={`${field.input.name}-helper-text`}>
            {field.helperText}
          </FormHelperText>
        )}
        {errorMessage && !hasError && (
          <FormHelperText
            className={field.errorMessageStyles}
            id={`${field.input.name}-error-message`}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};
/* tslint:enable */

export default RenderInput;
