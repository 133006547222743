import { color } from "../../../theme/Theme";
import { makeStyles } from "@material-ui/core";

export const addCardStyles = makeStyles({
  cardNumberInputStyle: {
    flexGrow: 1,
  },
  inputBoxStyle: {
    display: "flex",
    flexDirection: "row",
    marginTop: `0.5rem`,
  },
  expiryInputStyle: {
    flex: 1,
    marginRight: 10,
  },
  cvvInputStyle: {
    flex: 1,
    marginLeft: 10,
  },
  mainContainer: {
    marginTop: "1rem",
  },
  primaryButton: {
    padding: "1.1rem",
    marginLeft: "0.25rem",
    borderRadius: 8,
  },
  secondaryButton: {
    padding: "1rem",
    marginRight: "0.25rem",
    border: `2px solid ${color.lightGrey}`,
    borderRadius: 8,
  },
  secureBoxStyle: {
    height: 56,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    marginVertical: 20,
  },
  secureLockStyle: {
    marginRight: 16,
  },
  termsLabel: {
    fontSize: 14,
  },
  borderedBox: {
    padding: "1em",
    border: `1px solid ${color.borderColor}`,
    borderRadius: "4px",
    marginTop: "5px",
  },
});
