import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import { AppSettings } from "../../AppSettings";
import ApiServiceMock from "../__mocks__/ApiService";
import ApiServiceBase from "../ApiServiceBase";
import {
  RegisterBusinessRequest,
  UpdateBusinessRequest,
} from "./RegistrationFormRequest.data";

const serviceType = ServiceType.Trades;
import { MultipartFormData } from "../MultipartFormData.data";
import { getTradeId } from "../../helper/Auth";

export class RegisterBusinessService {
  /* istanbul ignore else*/
  private readonly service: ApiServiceBase = AppSettings.testSettings.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);

  public post(request: RegisterBusinessRequest): Promise<void> | void {
    const data: MultipartFormData[] = [];
    for (const key in request) {
      if (request.hasOwnProperty(key)) {
        data.push({
          name: key,
          content: request[key],
        });
      }
    }

    return this.service.postMultipart<void>({}, data);
  }

  public put(request: UpdateBusinessRequest): Promise<void> | void {
    const data: MultipartFormData[] = [];
    for (const key in request) {
      if (request.hasOwnProperty(key)) {
        data.push({
          name: key,
          content: request[key],
        });
      }
    }

    return this.service.putMultipart<void>({ route: [getTradeId()!] }, data);
  }
}

const registerBusinessService = new RegisterBusinessService();
export default registerBusinessService;
