import { getTradeId } from "../../helper/Auth";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";
import { SummaryCount } from "./CounterService.data";

export class CounterService {
  static service = new ApiService(ServiceType.Dashboard);

  getSummaryCounts = () => {
    const tradeId = getTradeId()!;
    return CounterService.service.get<SummaryCount>({
      route: [tradeId, "counter"],
    });
  };
}

const counterService = new CounterService();
export default counterService;
