import { makeStyles } from "@material-ui/core";
import { color } from "../../../theme/Theme";

export const NewCommentStyles = makeStyles({
  newCommentInput: {
    background: color.wildSand,
    fontSize: "14px",
    fontFamily: "GT Walsheim light",
    width: "100%",
    padding: "1em",
  },
  newCommentTextArea: {
    flexGrow: 1,
  },
  postButton: {
    borderRadius: "4px",
    width: "80px",
    height: "40px",
  },
  cancelButton: {
    borderRadius: "4px",
    width: "100px",
    height: "40px",
  },
  buttonsSection: {
    "& > * + *": {
      marginLeft: "1em",
    },
  },
});
