import React, { FC } from "react";
import { FormHelperText } from "@material-ui/core";

interface ErrorTextProps {
  text: string;
  centered?: boolean;
}

const ErrorText: FC<ErrorTextProps> = ({ text, centered }) => {
  return text ? (
    <FormHelperText
      style={{ color: "red", textAlign: centered ? "center" : "left" }}>
      {text}
    </FormHelperText>
  ) : null;
};

export default ErrorText;
