import React, { FC, useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  TextField,
  Paper,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Formik, Field } from "formik";
import { Clear } from "@material-ui/icons";
import {
  allowExtractPdf,
  invoiceIdValidation,
  LABELS,
} from "./AddArtefactsConstants";
import { isNull, get } from "lodash";
import { AddArtefactsStyles } from "./AddArtefactsStyles";
import { StyledDialog } from "../../../styledDialogContainer/StyledDialog";
import { Job, JobArtefactFiles } from "../../../../services/jobs/JobsData.data";
import { NamedFile } from "../../../../models/file/NamedFile";
import { S3File } from "../../../../models/file/S3File";
import AddArtefactsListSection from "./artefactsListSection/ArtefactsListSection";
import { getFileType, isS3File } from "../../../../helper/HelperFunctions";
import { FileTypes } from "../../../../constants/AppConstants";
import ErrorText from "../../../registrationStepsComponents/errorText/ErrorText";
import { JobStatus } from "../../../../models/jobs/JobData";
import DocGrid from "../../../docGrid/DocGrid";
import FileUpload from "../../../fileUpload/FileUpload";

interface Props {
  open: boolean;
  showSpecialMaterialsSection: boolean;
  onClose: () => void;
  onConfirm: (data: ArtefactsFormValues) => void;
  jobArtefactFiles: JobArtefactFiles | null;
  job: Job;
}

export interface ArtefactsFormValues {
  jobImages: NamedFile[];
  specialMaterialReceipts: NamedFile[];
  invoices: NamedFile[];
  comment: string;
  invoiceDeletedKeys: string[];
  jobImageDeletedKeys: string[];
  specialMaterialReceiptDeletedKeys: string[];
  extractInvoiceData: boolean;
  invoicesNumber: Array<string>;
  error?: string;
}

const AddArtefactsModal: FC<Props> = ({
  open,
  onClose,
  onConfirm,
  showSpecialMaterialsSection,
  jobArtefactFiles,
  job,
}) => {
  // Existing artefacts states
  const [existingJobImages, setExistingJobImages] = useState<S3File[]>(
    get(jobArtefactFiles, "jobImageFileUploadDetails", [])
  );
  const [existingInvoices, setExistingInvoices] = useState<S3File[]>(
    get(jobArtefactFiles, "invoiceFileUploadDetails", [])
  );
  const [
    existingSpecialMaterialDocuments,
    setExistingSpecialMaterialDocuments,
  ] = useState<S3File[]>(
    get(jobArtefactFiles, "specialMaterialReceiptFileUploadDetails", [])
  );

  // Hooks
  const classes = AddArtefactsStyles();

  // Effects
  useEffect(() => {
    refreshStates();
  }, [jobArtefactFiles]);

  // Functions

  const refreshStates = () => {
    // Clearing all the states
    setExistingInvoices(get(jobArtefactFiles, "invoiceFileUploadDetails", []));
    setExistingSpecialMaterialDocuments(
      get(jobArtefactFiles, "specialMaterialReceiptFileUploadDetails", [])
    );
    setExistingJobImages(
      get(jobArtefactFiles, "jobImageFileUploadDetails", [])
    );
  };
  const onCancel = () => {
    refreshStates();
    onClose();
  };

  //initial values
  const initialValues: ArtefactsFormValues = {
    jobImages: [],
    specialMaterialReceipts: [],
    invoices: [],
    comment: jobArtefactFiles ? jobArtefactFiles.comment : "",
    invoiceDeletedKeys: [],
    jobImageDeletedKeys: [],
    specialMaterialReceiptDeletedKeys: [],
    extractInvoiceData: false,
    invoicesNumber: [],
  };

  const formatFileNames = (
    values: ArtefactsFormValues
  ): ArtefactsFormValues => ({
    ...values,
    invoices: [
      ...values.invoices.map((inv) =>
        inv.formDataName
          ? {
              file: inv.file,
              formDataName: `${inv.formDataName}.${inv.file.name
                .split(".")
                .pop()}`,
            }
          : inv
      ),
    ],
    specialMaterialReceipts: [
      ...values.specialMaterialReceipts.map((sm) =>
        sm.formDataName
          ? {
              file: sm.file,
              formDataName: `${sm.formDataName}.${sm.file.name
                .split(".")
                .pop()}`,
            }
          : sm
      ),
    ],
  });
  const isPrivate =
    job.quote.maintenanceRequestResponse.privateWorksDetails &&
    job.quote.maintenanceRequestResponse.privateWorksDetails.isPrivate;

  return (
    <StyledDialog scroll="body" maxWidth={false} open={open}>
      <Formik<ArtefactsFormValues>
        initialValues={initialValues}
        onSubmit={(values, { setFieldValue }) => {
          // confirming invoice IDs are available
          if (invoiceIdValidation(values)) {
            const { error, ...formValues } = values;
            onConfirm(formatFileNames(formValues));
            onClose();
          } else {
            setFieldValue("error", LABELS.invoiceIdRequiredError);
          }
        }}>
        {({
          dirty,
          handleSubmit,
          setFieldValue,
          values: {
            invoices: invoicesValue,
            jobImages: jobImagesValue,
            comment,
            specialMaterialReceipts,
            invoiceDeletedKeys,
            jobImageDeletedKeys,
            specialMaterialReceiptDeletedKeys,
            extractInvoiceData,
            error,
          },
        }) => (
          <>
            <section className={classes.modalHeader}>
              <Typography className={classes.heading} variant="h5">
                {LABELS.addArtefacts}
              </Typography>

              <div className={classes.closeIconContainer}>
                <Clear onClick={onCancel} />
              </div>
            </section>

            <Typography color="textSecondary" variant="h4">
              {isPrivate
                ? LABELS.instructionsPrivateWorks
                : LABELS.instructions}
            </Typography>

            <section className={classes.formContentWrapper}>
              <Typography variant="h4">{LABELS.addJobImages}</Typography>
              <Box mt={2}>
                <Typography variant="h4" color="textSecondary">
                  {isPrivate
                    ? LABELS.jobImagesCustomer
                    : LABELS.jobImagesMessage}
                </Typography>
              </Box>

              {/* Job images */}
              <Box mt={2}>
                <FileUpload
                  dropboxHeight="150px"
                  id="job-image-input"
                  onUpload={(files) => {
                    if (!isNull(files) && files.length) {
                      setFieldValue("jobImages", [
                        ...jobImagesValue,
                        ...Array.from(files!).map((f) => ({
                          file: f,
                          formDataName: f.name,
                        })),
                      ]);
                    }
                  }}
                  clearOnChange
                  multiple
                />
              </Box>
              <DocGrid
                docs={[...jobImagesValue, ...existingJobImages]}
                remove={(image: NamedFile | S3File) => {
                  if (isS3File(image)) {
                    setExistingJobImages([
                      ...existingJobImages.filter(
                        (img) => img.id !== (image as S3File).id!
                      ),
                    ]);
                    setFieldValue("jobImageDeletedKeys", [
                      ...jobImageDeletedKeys,
                      (image as S3File).id!,
                    ]);
                  } else {
                    setFieldValue(
                      "jobImages",
                      jobImagesValue.filter(
                        (img) =>
                          img.formDataName !== (image as NamedFile).formDataName
                      )
                    );
                  }
                }}
              />

              {/* Special materials section */}
              {showSpecialMaterialsSection && (
                <Box mt={5}>
                  <AddArtefactsListSection
                    heading={LABELS.specialMaterials}
                    current={specialMaterialReceipts}
                    existing={existingSpecialMaterialDocuments}
                    onCurrentRemove={(updatedFileArray: NamedFile[]) =>
                      setFieldValue("specialMaterialReceipts", updatedFileArray)
                    }
                    onExistingRemove={(
                      updatedFileArray: S3File[],
                      id: string
                    ) => {
                      setExistingSpecialMaterialDocuments(updatedFileArray);
                      setFieldValue("specialMaterialReceiptDeletedKeys", [
                        ...specialMaterialReceiptDeletedKeys,
                        id,
                      ]);
                    }}
                    inputId={"special-materials"}
                    onFileChange={(file?: File) => {
                      if (file) {
                        setFieldValue("specialMaterialReceipts", [
                          ...specialMaterialReceipts,
                          { file, formDataName: file.name },
                        ]);
                      }
                    }}
                  />
                </Box>
              )}

              {/* Invoices */}
              <Box mt={5}>
                <AddArtefactsListSection
                  heading={
                    isPrivate
                      ? LABELS.invoiceMessageCustomer
                      : LABELS.invoiceMessage
                  }
                  current={invoicesValue}
                  singleItem
                  existing={existingInvoices}
                  onCurrentRemove={(updatedFileArray: NamedFile[]) => {
                    setFieldValue("invoices", updatedFileArray);
                    if (
                      [...existingInvoices, ...updatedFileArray].length !== 1
                    ) {
                      setFieldValue("extractInvoiceData", false);
                    }
                  }}
                  onExistingRemove={(
                    updatedFileArray: S3File[],
                    id: string
                  ) => {
                    setExistingInvoices(updatedFileArray);
                    setFieldValue("invoiceDeletedKeys", [
                      ...invoiceDeletedKeys,
                      id,
                    ]);
                    if ([...updatedFileArray, ...invoicesValue].length !== 1) {
                      setFieldValue("extractInvoiceData", false);
                    }
                  }}
                  inputId={"invoice"}
                  onFileChange={(file?: File) => {
                    if (file) {
                      setFieldValue("invoices", [
                        { file, formDataName: file.name },
                      ]);
                    }
                    if (file && allowExtractPdf(job.status as JobStatus)) {
                      setFieldValue("extractInvoiceData", true);
                    }
                  }}
                  showArtefactInputField={!extractInvoiceData}
                  onArtefactInputFieldChange={(newFieldValue, index) =>
                    setFieldValue(`invoicesNumber[${index}]`, newFieldValue)
                  }
                  artefactInputFieldLabel="Invoice ID"
                />
                {/* Toggle for extracting data from invoice */}
                {allowExtractPdf(job.status as JobStatus) &&
                  invoicesValue.length === 1 &&
                  existingInvoices.length === 0 &&
                  getFileType(invoicesValue[0].file.type) === FileTypes.Pdf && (
                    <Box mt={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(e, checked) => {
                              setFieldValue("extractInvoiceData", checked);
                              if (checked) {
                                setFieldValue("invoicesNumber", []);
                              }
                            }}
                            color="primary"
                            checked={extractInvoiceData}
                          />
                        }
                        label={
                          <Typography variant="h4">
                            {LABELS.extractInvoiceDataLabel}
                          </Typography>
                        }
                      />
                    </Box>
                  )}
              </Box>
            </section>

            {/* Comments */}
            <section>
              <Box mb={2} mt={4}>
                <Typography variant="h4">
                  {isPrivate ? LABELS.addCommentsCustomer : LABELS.addComments}
                </Typography>
              </Box>
              <Field
                name="comment"
                rows={4}
                multiline
                style={{ width: "100%" }}
                variant="filled"
                onChange={(e) => setFieldValue("comment", e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  className: classes.commentsTextArea,
                  value: comment,
                }}
                placeholder={LABELS.commentPlaceholder}
                component={TextField}
              />
            </section>

            <Box mt={4}>
              <Paper
                className={classes.attentionMessageContainer}
                variant="outlined"
                elevation={0}>
                <Typography variant="subtitle2">{LABELS.attention}</Typography>
                <Typography variant="body2">
                  {isPrivate
                    ? LABELS.attentionMessageCustomer
                    : LABELS.attentionMessage}
                </Typography>
              </Paper>
            </Box>

            <section className={classes.buttonsSection}>
              <Button
                color="secondary"
                onClick={onCancel}
                className={classes.navButton}>
                {LABELS.cancel}
              </Button>
              <Button
                disabled={!dirty}
                color="primary"
                onClick={() => handleSubmit()}
                className={classes.navButton}>
                {LABELS.confirm}
              </Button>
            </section>
            {error && (
              <Box display="flex" justifyContent="flex-end" width="100%" mt={2}>
                <ErrorText text={error} />
              </Box>
            )}
          </>
        )}
      </Formik>
    </StyledDialog>
  );
};

export default AddArtefactsModal;
