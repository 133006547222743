import React, { FC, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Button, Avatar, Box, Typography, Collapse, Paper, useTheme, useMediaQuery, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { UserData } from "../../services/tradeService/TradeServiceData.data";
import { roleToLabel, UserRole } from "../userDetails/UserDetailsData.data";
import { ListMembersStyle } from "./ListMembersStyles.data";
import { InviteUserFormData } from "../inviteUser/InviteUserFomData.data";
import { RenderInputFieldStyles, inputsFields, loginLabels } from "../FormikComponents/RenderInputFieldStyle";
import { InviteUserComponentStyles } from "../inviteUser/InviteUserComponentStyles";
import { Formik, Field } from "formik";
import { LABELS, INVITE_VALIDATION_SCHEMA } from "../inviteUser/InviteUserConstants.data";
import RenderInput from "../FormikComponents/RenderInputField";
import ContentBoxSubheading from "../registrationStepsComponents/contentBox/ContentBoxSubheading";
import RenderCheckBox from "../FormikComponents/RenderCheckbox";
import ContentBoxSection from "../registrationStepsComponents/contentBox/ContentBoxSection";

interface ListMembersProps {
  users: UserData[];
  error: Error | null;
  onAddNew: (formData: InviteUserFormData) => void;
  onEditUser: (id: string) => void;
  isCurrentUserAdmin: boolean;
}

const getUserRolesWithDot = (user: UserData) => {
  const roles = user.userTradeEntitlements.map((tradeEntitlement) =>
    roleToLabel(tradeEntitlement.role as UserRole)
  );
  return `${roles.join("・")}`;
};

const getUserRolesWithComma = (user: UserData) => {
  const roles = user.userTradeEntitlements.map((tradeEntitlement) =>
    roleToLabel(tradeEntitlement.role as UserRole)
  );
  return `${roles.join(", ")}`;
};

const getPrimaryText = (user: UserData) => `${user.firstName} ${user.lastName}`;

export const ListMembersComponent: FC<ListMembersProps> = ({ users, error, onAddNew, isCurrentUserAdmin, onEditUser }) => {
  const classes = ListMembersStyle();
  const [collapse, setCollapsible] = useState(false);

  const itemDetail = (label: string, value: string, forSummary: boolean) => {
    return (
      <Box>
        <Typography className={forSummary ? classes.labelSummary : classes.labelDetail}>{label}</Typography>
        <Typography className={forSummary ? classes.valueSummary : classes.valueDetail}>{value}</Typography>
      </Box>
    );
  }

  const addNewMembersForm = () => {
    const formInitialValues: InviteUserFormData = {
      firstName: "",
      lastName: "",
      email: "",
      role: [UserRole.tradeEmployee],
    };
    const inputClasses = RenderInputFieldStyles();
    const listMembersStyle = ListMembersStyle();
    const inviteUserStyles = InviteUserComponentStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const renderInputProps = {
      style: inputsFields,
      labelStyle: loginLabels,
      overrideInputStyles: { input: inputClasses.input },
      overrideLabelStyles: { shrink: inputClasses.labels },
    };
    return (
      <Formik
        initialValues={formInitialValues}
        onSubmit={(values, { resetForm }) => {
          onAddNew(values);
          resetForm({ values: formInitialValues });
          setCollapsible(false);
        }}
        validationSchema={INVITE_VALIDATION_SCHEMA}>
        {({ handleSubmit, setFieldValue, values, dirty, isValid, resetForm }) => {
          return (
          <ContentBoxSection heading={LABELS.nweUserDetails}>
            <Grid
              container
              spacing={isSmall ? 0 : 6}
              className={inviteUserStyles.container}>
              <Grid item lg={8}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Field name="firstName">
                      {({ form, field }) => (
                        <RenderInput
                          label={LABELS.firstName}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field name="lastName">
                      {({ form, field }) => (
                        <RenderInput
                          label={LABELS.lastName}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="email">
                      {({ form, field }) => (
                        <RenderInput
                          label={LABELS.email}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={isSmall ? "" : inviteUserStyles.roleContainer}
                item
                lg={4}>
                <ContentBoxSubheading text={LABELS.roles} />
                <Field type="checkbox" name="role" value={UserRole.tradeAdmin}>
                  {(fieldProps) => {
                  const meta = {...fieldProps.meta, error: ""}
                  return (
                    <RenderCheckBox
                      label={LABELS.tradeAdmin}
                      color={"primary"}
                      {...fieldProps}
                      meta={meta}
                    />
                  )}}
                </Field>
                <Field
                  type="checkbox"
                  name="role"
                  value={UserRole.tradeEmployee}>
                  {(fieldProps) => (
                    <RenderCheckBox
                      label={LABELS.tradeEmployee}
                      color={"primary"}
                      {...fieldProps}
                    />
                  )}
                </Field>
              </Grid>
              <Box display='inline-block' width='100%' mt={2}>
                <Button variant='outlined' className={listMembersStyle.addNewButton} style={{ float: isSmall ? 'initial' : 'right' }} disabled={!dirty || !isValid}
                  onClick={() => { handleSubmit() }}>
                  <Typography className={classes.labelSummary}>Invite</Typography>
                </Button>
              </Box>
            </Grid>
          </ContentBoxSection>
        )}}
      </Formik>
    )
  }

  const addNewMembers = () => {
    return (
      <Paper elevation={0} className={classes.addNewContainer}>
        <Collapse in={collapse} collapsedHeight={40}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography className={classes.inviteNewUserLabel} noWrap>Invite New Users</Typography>
            <Button variant='outlined' className={classes.addNewButton} onClick={() => setCollapsible(!collapse)}>
              {!collapse ? <Box display='flex'>
                <Typography className={classes.labelSummary} style={{ marginRight: 5 }}>User</Typography>
                <AddIcon />
              </Box> :
                <Typography className={classes.labelSummary}>Cancel</Typography>
              }
            </Button>
          </Box>
          <Box mt={2}>
            {addNewMembersForm()}
          </Box>
        </Collapse>
      </Paper>
    )
  }

  const userSummary = (user: UserData) => {
    const { logo } = user;
    return (
      <Box display='flex'>
        <Avatar src={logo} />
        <Box ml={2}>
          {itemDetail(getPrimaryText(user), getUserRolesWithDot(user), true)}
        </Box>
      </Box>
    )
  }

  const userDetails = (user: UserData) => {
    const { email, mobileNumber, id, userTradeEntitlements } = user
    return (
      <Box width='100%'>
        <Box mb={4}>
          {email && itemDetail('EMAIL', email, false)}
        </Box>
        <Box mb={4}>
          {mobileNumber && itemDetail('PHONE', mobileNumber, false)}
        </Box>
        <Box display='flex' justifyContent='space-between' width='100%' alignItems='flex-end'>
          {Array.isArray(userTradeEntitlements) && userTradeEntitlements.length > 0 && itemDetail('ROLE', getUserRolesWithComma(user), false)}
          {isCurrentUserAdmin && <Button className={classes.editButton} onClick={() => onEditUser(`${id}`)}>Edit</Button>}
        </Box>
      </Box>
    )
  }

  const renderTeamMembers = () => {
    return (
      (Array.isArray(users) && users.length > 0) ? users.map((user: UserData, index: number) => (
        <Accordion key={`${index}`} id={`member${user.id}`} className={classes.userContainer}>
          <AccordionSummary>
            {userSummary(user)}
          </AccordionSummary>
          <AccordionDetails>
            {userDetails(user)}
          </AccordionDetails>
        </Accordion>
      )
      ) : null
    )
  }

  return (
    <Box m={2}>
      {isCurrentUserAdmin && addNewMembers()}
      <Box mt={3}>
        {renderTeamMembers()}
      </Box>
    </Box>
  )
};
