import {
  AddServiceableRegionsSuccessPayload,
  AddServiceableRegionsRequestPayload,
  ServiceableRegionsErrorPayload,
  DeleteServiceableRegionRequestPayload,
} from "../../services/serviceableRegions/serviceableRegionsServiceData.data";
import { StoreAction } from "../StoreHelper";

export enum ServiceableRegionsActionType {
  ADD_SERVICEABLE_REGIONS_REQUEST = "ADD_SERVICEABLE_REGIONS_REQUEST",
  ADD_SERVICEABLE_REGIONS_SUCCESS = "ADD_SERVICEABLE_REGIONS_SUCCESS",
  SERVICEABLE_REGIONS_ERROR = "SERVICEABLE_REGIONS_ERROR",

  DELETE_SERVICEABLE_REGION_REQUEST = "DELETE_SERVICEABLE_REGION_REQUEST",
  DELETE_SERVICEABLE_REGION_SUCCESS = "DELETE_SERVICEABLE_REGION_SUCCESS",
}

export type ServiceableRegionsActionPayload =
  | AddServiceableRegionsRequestPayload
  | AddServiceableRegionsSuccessPayload
  | ServiceableRegionsErrorPayload
  | DeleteServiceableRegionRequestPayload;

export type ServiceableRegionsAction = StoreAction<
  ServiceableRegionsActionType,
  ServiceableRegionsActionPayload
>;

export class ServiceableRegionsActions {
  public static addServiceableRegionsRequest(
    data: AddServiceableRegionsRequestPayload
  ) {
    return {
      type: ServiceableRegionsActionType.ADD_SERVICEABLE_REGIONS_REQUEST,
      data,
    };
  }

  public static addServiceableRegionsSuccess(
    data: AddServiceableRegionsSuccessPayload
  ) {
    return {
      type: ServiceableRegionsActionType.ADD_SERVICEABLE_REGIONS_SUCCESS,
      data,
    };
  }

  public static serviceableRegionsError(data: ServiceableRegionsErrorPayload) {
    return {
      type: ServiceableRegionsActionType.SERVICEABLE_REGIONS_ERROR,
      data,
    };
  }

  public static deleteServiceableRegionRequest(
    data: DeleteServiceableRegionRequestPayload
  ) {
    return {
      type: ServiceableRegionsActionType.DELETE_SERVICEABLE_REGION_REQUEST,
      data,
    };
  }

  public static deleteServiceableRegionSuccess(
    data: DeleteServiceableRegionRequestPayload
  ) {
    return {
      type: ServiceableRegionsActionType.DELETE_SERVICEABLE_REGION_SUCCESS,
      data,
    };
  }
}
