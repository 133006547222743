import React, { FC } from "react";
import { Grid, Typography } from "@material-ui/core";
import { TradeCategory } from "../../../services/tradeCategories/TradeCategories.data";
import { titleCase } from "../../../helper/HelperFunctions";
import TradeCategoryIcon from "../../tradeCategoryIcon/TradeCategoryIcon";
import { MostUsedCategoriesStyles } from "./TopTradeCategoriesStyles";

interface Props {
  tradeCategories: TradeCategory[];
  onCardClick: (category: TradeCategory) => void;
  selectedTradeCategories: TradeCategory[];
}

const MostUsedCategories: FC<Props> = (props) => {
  const { tradeCategories, onCardClick, selectedTradeCategories } = props;
  const classes = MostUsedCategoriesStyles();
  const selectedTradeIds = selectedTradeCategories.map((trade) => trade.id);

  return (
    <Grid container className={classes.container} spacing={2}>
      {tradeCategories.map((trade) => (
        <Grid xs={12} sm={6} md={4} lg={3} key={trade.id} item>
          <div
            onClick={() => onCardClick(trade)}
            className={`${classes.tradeCard} ${
              selectedTradeIds.includes(trade.id)
                ? classes.selectedTradeCard
                : ""
            }`}>
            <TradeCategoryIcon imageClass={classes.tradeImage} tradeName={trade.name} />
            <Typography variant="h4">
              {`${titleCase(trade.description)}`}
            </Typography>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default MostUsedCategories;
