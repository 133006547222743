import { Box, Grid, ListItem, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { ConfirmJobBookingStyles } from "./ConfirmJobBookingStyles.data";

interface SummaryDetailItemProps {
  field: string;
  content: JSX.Element;
  dense?: boolean;
}

const SummaryDetailItem: FC<SummaryDetailItemProps> = ({
  field,
  content,
  dense,
}) => {
  const classes = ConfirmJobBookingStyles();

  return (
    <ListItem className={classes.collapsedDetailsListItem}>
      <Box width="100%" my={dense ? 0 : 1}>
        <Grid container>
          <Grid item xs={5}>
            <Typography
              className={classes.collapsedDetailsListKey}
              variant="body2">{`${field}:`}</Typography>
          </Grid>
          <Grid item xs={7}>
            {content}
          </Grid>
        </Grid>
      </Box>
    </ListItem>
  );
};

export default SummaryDetailItem;
