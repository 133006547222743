import React, { FC } from "react";
import {
  withStyles,
  ListItemIcon,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import { Home, LocationOnOutlined } from "@material-ui/icons";
import { color } from "../../theme/Theme";
import { googleMapsQuery } from "../../helper/HelperFunctions";

interface PropertyListItemProps {
  name: string;
  location: string;
  actionsSection?: JSX.Element;
  image?: any;
  secondaryData?: JSX.Element;
  noPadding?: boolean;
  map?: boolean;
}

const useStyles = makeStyles({
  detailsContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    alignItems: "center",
  },
  secondaryActionRoot: {
    display: "flex",
    alignItems: "center",
    right: "0px",
  },
  secondaryAction: {
    paddingRight: "40px",
  },
  listItemRoot: {
    padding: 0,
    paddingRight: ".5em",
  },
  listItemRootNoPadding: {
    padding: 0,
  },
  avatarRoot: {
    background: color.light,
    color: color.secondary500,
  },
  primaryData: {
    flexGrow: 1,
  },
});

const PropertyIcon = withStyles({
  root: {
    minWidth: "40px",
    marginRight: "1em",
  },
})(ListItemIcon);

const PropertyListItem: FC<PropertyListItemProps> = (props) => {
  const {
    actionsSection,
    image,
    location,
    name,
    secondaryData,
    noPadding,
    map,
  } = props;
  const classes = useStyles();

  return (
    <ListItem
      classes={{
        root: noPadding ? classes.listItemRootNoPadding : classes.listItemRoot,
        secondaryAction: classes.secondaryAction,
      }}
      key={name}>
      <PropertyIcon>
        {image ? (
          <Avatar src={image} />
        ) : (
          <Avatar classes={{ root: classes.avatarRoot }}>
            <Home color="action" />
          </Avatar>
        )}
      </PropertyIcon>
      <div className={classes.detailsContainer}>
        <ListItemText
          className={classes.primaryData}
          primary={<Typography variant="h4">{name}</Typography>}
          secondary={
            <Typography color="textSecondary" variant="caption">
              {location}
            </Typography>
          }
        />
        {secondaryData && <>{secondaryData}</>}
        {map && (
          <a
            target="_blank"
            style={{ color: color.secondary200 }}
            href={googleMapsQuery((name || "") + " " + (location || ""))}>
            <LocationOnOutlined />
          </a>
        )}
      </div>
      {actionsSection && (
        <ListItemSecondaryAction
          classes={{ root: classes.secondaryActionRoot }}>
          {actionsSection}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default PropertyListItem;
