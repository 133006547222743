import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import { VALIDATION_SCHEMA, LABELS } from "./LoginConstants";
import { Link } from "react-router-dom";
import { History } from "history";
import { routes } from "../../Routes";
import {
  LoginPayload,
  VerificationLinkPayload,
} from "../../services/login/LoginData";
import AuthPagesWrapper from "../authPagesWrapper/AuthPagesWrapper";
import RenderInputField from "../FormikComponents/RenderInputField";
import { capitalize } from "lodash";
import { LoginComponentStyle } from "./LoginStyles";

interface Props {
  history: History;
  postLogin: (data: LoginPayload) => void;
  errorMessage: string | null;
  verifyError: string | null;
  sendVerificationEmail: (data: VerificationLinkPayload) => void;
  userEmail: string | null;
}

type OwnProps = Props;

const Login: React.FC<OwnProps> = (props) => {
  // State
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /* tslint:disable:cyclomatic-complexity */
  const { postLogin, userEmail, errorMessage } = props;
  const initialValues: LoginPayload = {
    username: userEmail ? userEmail : "",
    password: "",
  };
  const classes = LoginComponentStyle(props);

  // get header logo image
  // TO DO: Check if sorted logo is to be shown, if yes fetch it in imgSrc
  return (
    <AuthPagesWrapper includeLogo containerClassName={"login-fields"}>
      <Formik
        initialValues={initialValues}
        onSubmit={postLogin}
        validateOnChange
        validationSchema={VALIDATION_SCHEMA}
        render={({ handleSubmit, setFieldValue, isValid, dirty }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.titleStyle}>
              <h2 className={classes.labelStyle}>{LABELS.MY_ACCOUNT}</h2>
              <p style={{ marginTop: "9px" }}>{LABELS.ENTER_DETAILS_HEADING}</p>
            </div>
            <RenderInputField
              name="username"
              label={LABELS.USERNAME}
              onChange={(event) => {
                setFieldValue("username", event.currentTarget.value);
              }}
            />

            <RenderInputField
              name="password"
              label={LABELS.PASSWORD}
              type={showPassword ? "text" : "password"}
              onChange={(event) => {
                setFieldValue("password", event.currentTarget.value);
              }}
              handleShowPassword={handleShowPassword}
              showPassword={showPassword}
            />

            <p className={`${classes.forgotPasswordLink} paragraph-small`}>
              <Link to={routes.forgotPassword} className={classes.loginLink}>
                {LABELS.FORGOT_PASSWORD}
              </Link>
            </p>
            {errorMessage && (
              <div className={classes.errorText}>{errorMessage}</div>
            )}
            <div className={classes.buttonContainer}>
              <Button
                fullWidth={true}
                type="submit"
                variant={!dirty || !isValid ? "outlined" : "text"}
                className={`${classes.loginButton} ${dirty && isValid && classes.loginButtonActive}`}
                disabled={!dirty || !isValid}>
                {LABELS.LOGIN}
              </Button>

              <p className={`${classes.bottomLink} paragraph-small`}>
                {LABELS.NOT_HAVE_ACCOUNT}
                <Link
                  to={routes.signUp.viewGeneric()}
                  className={classes.loginLink}>
                  {" "}
                  {capitalize(LABELS.SIGNUP)}
                </Link>
              </p>
            </div>
          </form>
        )}
      />
    </AuthPagesWrapper>
  );
};

export default Login;
