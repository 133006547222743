import React, { FC, useEffect, useRef, useState } from "react";
import { googleMapStyles } from "./GoogleMapStyles";

export interface LatLng {
  lat: number;
  lng: number;
}

interface GoogleMapProps {
  markers: Array<LatLng>;
  zoomCenter: LatLng | null;
  circles?: Array<{ center: LatLng; radius: number }>;
  circleConfig?: Partial<CircleConfig>;
}

const google = window.google;

interface CircleConfig {
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  fillColor: string;
  fillOpacity: number;
}

const defaultCircleConfig: CircleConfig = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#FF0000",
  fillOpacity: 0.35,
};

export const GoogleMap: FC<GoogleMapProps> = ({
  markers,
  zoomCenter,
  circles,
  circleConfig,
}) => {
  const [circleRefs, setCircleRefs] = useState<google.maps.Circle[]>([]);
  const [markerRefs, setMarkerRefs] = useState<google.maps.Marker[]>([]);
  const classes = googleMapStyles();
  let map = useRef<google.maps.Map<HTMLElement> | undefined>(undefined);

  useEffect(() => {
    map.current = new google.maps.Map(document.getElementById("map")!, {
      center:
        markers.length > 0
          ? markers[0]
          : {
              lat: 37.8136,
              lng: 144.9631,
            },
      zoom: 12,
    });
  }, []);

  useEffect(() => {
    // remove markers
    markerRefs.forEach((mr) => {
      mr.setMap(null);
      mr = null as any;
    });

    // add markers
    const latlngbounds = new google.maps.LatLngBounds();
    const updatedMarkerRefs: google.maps.Marker[] = [];
    markers.forEach((m) => {
      const marker = new google.maps.Marker({
        position: m,
        map: map.current,
      });
      updatedMarkerRefs.push(marker);
      if (markers.length > 1) latlngbounds.extend(m);
    });
    setMarkerRefs(updatedMarkerRefs);
    map.current!.fitBounds(latlngbounds);
    if (zoomCenter) {
      map.current!.panTo(zoomCenter);
    }
  }, [markers]);

  useEffect(() => {
    if (zoomCenter && zoomCenter.lat && zoomCenter.lng) {
      map.current!.panTo(zoomCenter);
    } else if (markers.length > 0) {
      map.current!.panTo(markers[0]);
    }
  }, [zoomCenter]);

  useEffect(() => {
    // removing circles
    circleRefs.forEach((cr) => {
      cr.setMap(null);
      cr = null as any;
    });

    const updatedCircleRefs: google.maps.Circle[] = [];
    // adding new circles
    (circles || []).forEach((c) => {
      const circle = new google.maps.Circle({
        ...defaultCircleConfig,
        ...(circleConfig || {}),
        center: c.center,
        radius: c.radius,
        map: map.current,
      });
      updatedCircleRefs.push(circle);
    });
    setCircleRefs(updatedCircleRefs);
    if (zoomCenter && zoomCenter.lat && zoomCenter.lng)
      map.current!.panTo(zoomCenter);
  }, [circles]);

  return <div id="map" className={classes.container}></div>;
};
