import React, { FC } from "react";
import { routes } from "../../Routes";
import ErrorPage from "../errorPage/ErrorPage";
import LoginContainer from "../../container/login/Login";
import SignUpComponent from "../../container/signUp/SignUp";
import PageNotFoundPage from "../../container/PageNotFound/PageNotFoundPage";
import { errorMessages } from "../../constants/AppConstants";
import RegistrationForm from "../../container/registrationForm/RegistrationForm";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { isLoggedIn, onboardingStage } from "../../helper/Auth";
import LandingScreen from "../landingScreen/LandingScreen";
import AddServiceableRegionsForm from "../../container/addServiceableRegionsForm/AddServiceableRegionsForm";
import AddTrades from "../../container/addTrades/AddTradeCategories";
import ListBranches from "../../container/listBranches/ListBranches";
import ListUsers from "../../container/listUsers/ListUsers";
import UserDetails from "../../container/userDetails/UserDetails";
import AddTradesDocuments from "../../container/addTradesDocuments/AddTradesDocuments";
import ConfirmRegistration from "../../container/confirmRegistration/ConfirmRegistration";
import InviteUser from "../../container/inviteUser/InivteUser";
import Header from "../header/Header";
import Dashboard from "../../container/dashboard/Dashboard";
import { OnboardingStage } from "../../helper/OnboardingStages";
import ForgotPassword from "../../container/listUsers/forgotPassword/ForgotPassword";
import ResetPassword from "../../container/resetPassword/ResetPassword";
import LoginImpersonateContainer from "../../container/loginImpersonate/LoginImpersonate";
import MaintenanceImageUnAuth from "../maintenanceImageUnAuth/MaintenanceImageUnAuth";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../helper/HelperFunctions";
import AddPayment from "../../container/paymentContainer/AddPayment";

const PrivateRoute = ({
  component: Component,
  registrationStep = false,
  ...routeProps
}) => {
  return (
    <Route
      {...routeProps}
      render={(props) =>
        isLoggedIn() ? (
          <>
            {onboardingStage() !== OnboardingStage.Completed &&
            onboardingStage() !== "null" ? (
              <Header />
            ) : null}

            {/* If User is trying to access registration step after completing registration, redirect to dashboard */}
            {(onboardingStage() === OnboardingStage.Completed ||
              onboardingStage() === "null") &&
            registrationStep &&
            !localStorage.getItem("inviteToken") ? (
              <Redirect
                to={{
                  pathname: routes.dashboard.view,
                }}
              />
            ) : (
              <Component {...props} />
            )}
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Layout: FC<RouteComponentProps> = ({ location }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{getPageTitle(location.pathname)}</title>
      </Helmet>
      <Switch location={location}>
        {/* Login */}
        <Route path="/" exact component={LandingScreen} />
        <Route
          path={routes.login.viewGeneric()}
          exact
          component={LoginContainer}
        />
        <Route
          path={routes.login.view(":agencyCode")}
          exact
          component={LoginContainer}
        />

        {/* Impersonation login */}
        <Route
          path={routes.login.impersonation}
          exact
          component={LoginImpersonateContainer}
        />

        <PrivateRoute path={routes.error.new} exact component={ErrorPage} />
        {/* Sign up*/}
        <Route
          path={routes.signUp.view(":agencyCode")}
          exact
          component={SignUpComponent}
        />
        <Route
          path={routes.signUp.viewGeneric()}
          exact
          component={SignUpComponent}
        />
        <Route
          path={routes.maintenanceImage}
          exact
          component={MaintenanceImageUnAuth}
        />

        {/* RegistrationForm */}
        <PrivateRoute
          registrationStep
          path={routes.registrationForm.viewGeneric()}
          exact
          component={RegistrationForm}
        />

        {/* Add serviceable regions */}
        <PrivateRoute
          registrationStep
          path={routes.addServiceableRegion.view(":officeId")}
          exact
          component={AddServiceableRegionsForm}
        />

        {/* Add Payment Methods */}
        <PrivateRoute
          registrationStep
          path={routes.addPaymentMethods.view}
          exact
          component={AddPayment}
        />

        {/* Add Trades */}
        <PrivateRoute
          registrationStep
          path={routes.addTrades.view}
          exact
          component={AddTrades}
        />

        {/* Add Trades Documents */}
        <PrivateRoute
          registrationStep
          path={routes.addTradesDocuments.view}
          exact
          component={AddTradesDocuments}
        />

        {/* List Branches */}
        <PrivateRoute
          registrationStep
          path={routes.listBranches.view(":tradeId")}
          exact
          component={ListBranches}
        />

        {/* List Users */}
        <PrivateRoute
          registrationStep
          path={routes.listUsers.view}
          exact
          component={ListUsers}
        />

        {/* Invite User */}
        <PrivateRoute
          registrationStep
          path={routes.inviteUser.view}
          exact
          component={InviteUser}
        />

        {/* Non private route for invited user, the conditions: either token or logged in will be checked in the container itself */}
        <Route path={routes.addUserDetails.view} component={UserDetails} />

        {/* Forgot password routes */}
        {/* Forgot password */}
        <Route path={routes.forgotPassword} exact component={ForgotPassword} />

        {/* Reset password */}
        <Route path={routes.resetPassword} component={ResetPassword} />

        {/* Dashboard Screens*/}
        <PrivateRoute path={routes.dashboard.view} component={Dashboard} />

        {/* Confirm Registration - Registration step */}
        <PrivateRoute
          registrationStep
          path={routes.confirmRegistration.view}
          exact
          component={ConfirmRegistration}
        />

        <PrivateRoute
          path={routes.error.linkExpired()}
          exact
          component={() => (
            <PageNotFoundPage
              message={errorMessages.linkExpiredMessage}
              title={errorMessages.linkExpiredTitle}
              loginLink={true}
            />
          )}
        />
        <PrivateRoute
          component={() => (
            <PageNotFoundPage
              message={errorMessages.pageNotFoundMessage}
              title={errorMessages.pageNotFoundTitle}
            />
          )}
        />
        <PrivateRoute
          path={routes.error.view()}
          exact
          component={() => (
            <PageNotFoundPage
              message={errorMessages.pageNotFoundMessage}
              title={errorMessages.pageNotFoundTitle}
            />
          )}
        />

        <Route component={ErrorPage} />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
