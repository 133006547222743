import { takeEvery, put } from "redux-saga/effects";
import {
  SignUpActionType,
  SignUpAction,
  SignUpActions,
  SignUpStartPayload,
  SignUpSuccessPayload,
} from "../../store/actions/SignUpAction";
import { Logging } from "../../helper/Logging";
import SignUpService from "../../services/signUp/SignUpService";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";

export function* postSignUpWorker(action: SignUpAction): IterableIterator<any> {
  const data = action.data as SignUpStartPayload;
  const { request, onSuccess } = { ...data };
  try {
    Logging.debug("SignUpSagas.signUpWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());
    Logging.debug("SignUpSagas.signUpWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });
    const response: SignUpSuccessPayload = (yield SignUpService.SignUpPostData(
      request
    ))!;
    if (onSuccess) {
      yield onSuccess(response);
    }
    yield put(SignUpActions.signUpSuccess(response));
  } catch (error) {
    Logging.error("SignUpSagas.signUpWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(LoadingIndicatorActions.hide());
    yield put(SignUpActions.signUpError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const signUpWatcher = [
  takeEvery(SignUpActionType.SignUpLinkRequest, (action) =>
    postSignUpWorker({ type: action.type, data: (action as SignUpAction).data })
  ),
];

export default signUpWatcher;
