import { AddDocData } from "./AddTradesDocumentsData.data";
import { UserTradeDocumentsUploadData } from "../../services/userTrades/UserTradeService.data";
import { DocType } from "../../component/addTradesDocuments/addDocModal/AddDocForm.data";

export const AddTradeDocumentsFormConverter = (formData: AddDocData):UserTradeDocumentsUploadData  => {
  return {
    file: formData.file,
    data: {
      name: formData.docName,
      tradeCategoryDocumentType: (formData.tracker ? DocType.Tracker : DocType.NonTracker),
      userTradeCategoryId: formData.userTradeCategoryId,
      expiryDate: formData.tracker ? formData.expiryDate!.format("DD/MM/YYYY")! : null
    }
  }
}