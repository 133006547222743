import React, { FC } from "react";
import { Box } from "@material-ui/core";
import StyledExpansionPanel from "../styledExpansionPanel/StyledExpansionPanel";
import ContactCardNameAndDesignation from "./ContactCardNameAndDesignation";
import ContactDetails from "./ContactDetails";

export interface Contact {
  name: string;
  surname: string;
  email: string;
  phone: string;
  [k: string]: any;
}

interface ContactCardProps {
  person: Contact;
  designation?: string;

  // For expansion panel in contact card
  collapsible?: boolean;
  // Removes elevation (box-shadow) from expansion panel
  noElevation?: boolean;
  // Removes side padding in summary section
  disableSummaryPadding?: boolean;
  detailsListItemClass?: string;
  detailsListItemTextClass?: string;
  underlined?: boolean;
  avatarColor?: string;
  noMessage?: boolean;
}

const ContactCard: FC<ContactCardProps> = ({
  person: { name, surname, phone, email },
  designation,
  collapsible,
  noElevation,
  disableSummaryPadding,
  detailsListItemClass,
  detailsListItemTextClass,
  underlined,
  avatarColor,
  noMessage,
}) => {
  return (
    <Box width="100%">
      {collapsible ? (
        <StyledExpansionPanel
          underlinedAvatar={underlined}
          disableSummaryPadding={disableSummaryPadding}
          noElevation={noElevation}
          summaryContent={
            <ContactCardNameAndDesignation
              disableListItemSidePadding={disableSummaryPadding}
              name={`${name}${surname && " " + surname}`}
              designation={designation}
              avatarColor={avatarColor}
            />
          }>
          <ContactDetails
            listItemClass={detailsListItemClass}
            email={email}
            phone={phone}
            noMessage={noMessage}
          />
        </StyledExpansionPanel>
      ) : (
        <>
          <ContactCardNameAndDesignation
            disableListItemSidePadding={disableSummaryPadding}
            name={name}
            designation={designation}
          />
          <ContactDetails
            listItemClass={detailsListItemClass}
            listItemTextClass={detailsListItemTextClass}
            email={email}
            phone={phone}
            noMessage={noMessage}
          />
        </>
      )}
    </Box>
  );
};

export default ContactCard;
