import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import {
  PostUserDocumentData,
  PostUserDocumentPayload,
  DeleteUserDocumentRequest,
} from "./UserDocuments.data";
import { MultipartFormData } from "../MultipartFormData.data";
import { getUserId } from "../../helper/Auth";

const userServiceType = ServiceType.User;
const USER_DOCUMENT_PATH = "user-document";

class UserDocumentService {
  private readonly userService: ApiServiceBase = new ApiService(
    userServiceType
  );
  public postUserDocs(docData: PostUserDocumentData) {
    const tradeDocStringified: PostUserDocumentPayload = {
      file: docData.file,
      userDocumentRequest: JSON.stringify({ ...docData.data, delete: false }),
    };
    const requestObj: MultipartFormData[] = [];
    for (const key in tradeDocStringified) {
      if (tradeDocStringified.hasOwnProperty(key)) {
        requestObj.push({
          name: key,
          content: tradeDocStringified[key],
        });
      }
    }
    return this.userService.postMultipart(
      { route: [getUserId()!.toString(), USER_DOCUMENT_PATH] },
      requestObj
    );
  }

  public deleteUserDoc(deleteDocData: DeleteUserDocumentRequest) {
    return this.userService.delete({
      route: [
        getUserId()!.toString(),
        USER_DOCUMENT_PATH,
        deleteDocData.userDocumentId.toString(),
      ],
    });
  }
}

export default new UserDocumentService();
