import React, { FC, useState } from "react";
import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanel,
  ExpansionPanelProps,
  Box,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { StyledExpansionPanelStyles } from "./StyledExpansionPanelStyles.data";
import { LineAmountType } from "../../models/payment/PaymentLineAmountType";

interface JobDetailsPanelProps {
  lineAmountType: LineAmountType;
}

interface Props extends ExpansionPanelProps {
  heading?: string;
  summaryContent?: JSX.Element;
  // Removes box shadow
  noElevation?: boolean;
  // Removes side padding from summary section
  disableSummaryPadding?: boolean;

  // Underlined panel with avatar image: the border won't span under the image
  underlinedAvatar?: boolean;

  // Full width underline
  underlined?: boolean;

  // page specific
  jobDetailsPanelProps?: JobDetailsPanelProps;

  // hides summary data on expansion
  hideSummaryOnExpansion?: boolean;
}

const StyledExpansionPanel: FC<Props> = ({
  children,
  heading,
  summaryContent,
  noElevation,
  disableSummaryPadding,
  underlined,
  underlinedAvatar,
  jobDetailsPanelProps,
  hideSummaryOnExpansion,
  ...props
}) => {
  const classes = StyledExpansionPanelStyles();
  const [expanded, setExpanded] = useState<boolean>(!!props.defaultExpanded);

  return (
    <ExpansionPanel
      onChange={(e, expansionState) => setExpanded(expansionState)}
      elevation={noElevation ? 0 : undefined}
      {...props}>
      <ExpansionPanelSummary
        className={`${
          disableSummaryPadding
            ? classes.expansionPanelSummarySectionDisablePadding
            : classes.expansionPanelSummarySection
        } ${underlinedAvatar && classes.underlinedAvatar} ${
          underlined && classes.underlined
        }`}
        classes={{ expanded: classes.expanded }}
        expandIcon={<ExpandMore />}>
        {summaryContent && !(expanded && hideSummaryOnExpansion) ? (
          summaryContent
        ) : (
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2">{heading}</Typography>
            {jobDetailsPanelProps && (
              <Box ml={2}>
                <Typography variant="caption" color="textSecondary">
                  {`( ${
                    jobDetailsPanelProps.lineAmountType ===
                    LineAmountType.INCLUSIVE
                      ? "Including GST"
                      : "Excluding GST"
                  } )`}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetailsSection}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default StyledExpansionPanel;
