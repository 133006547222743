import { userTestData } from "./services/__mocks__/UserApiServiceMock";
import { User } from "./models/user/User.data";

export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace,
}

/**
 * 1. set placeholder ###CONTAINER_TRADE_SERVICE_URL### for BaseUrl in build time, set it to uat if it's running locally and process.env.NODE_ENV is 'development'
 * 2. In terraform, ECS task configuration sets TRADE_SERVICE_URL to env variable based on the value from Consul node config
 * 3. replaced the placeholder at docker start time with TRADE_SERVICE_URL in docker-entrypoint.sh
 */
const BaseUrl =
  process.env.NODE_ENV === "production"
    ? "https://###CONTAINER_SERVICE_URL###/trade"
    : "https://trade-service-bff.uat.sortedservices.com/trade";

const tenancyS3BaseUrl =
  process.env.NODE_ENV === "production"
    ? "https://prodau-sorted-tenancy-service.s3.amazonaws.com"
    : "https://uat-sorted-tenancy-service-bucket.s3.amazonaws.com";

const bucketUrl =
  process.env.NODE_ENV === "production"
    ? "https://prodau-sorted-trade-service.s3.amazonaws.com/email-template"
    : "https://uat-sorted-trade-service-bucket.s3.amazonaws.com/email-template";

export interface TestSettings {
  readonly testing: boolean;
  readonly logLevel: LogLevel;
  readonly currentUser: User | undefined;
}

const logRocketGroup = BaseUrl.includes("prodau")
  ? "###LOG_ROCKET_PROD###"
  : (process.env.LOG_ROCKET_UAT as string);

export class AppSettings {
  public static readonly server = {
    baseUrl: BaseUrl, // DO NOT end with '/'!
    mockServices: false, // test use mocks by using jest.mock(), this is for manual testing
  };

  public static bucket = bucketUrl;

  public static readonly logging = {
    logLevel: LogLevel.Trace,
  };

  public static readonly logRocket = {
    group: logRocketGroup,
  };

  public static initTesting() {
    AppSettings.logging.logLevel = AppSettings.testSettings.logLevel;

    this.prvTestSettings = {
      ...this.prvTestSettings,
      testing: true,
    };
  }

  public static get testSettings(): TestSettings {
    return AppSettings.prvTestSettings;
  }

  public static get currentTestSetting(): TestSettings {
    return AppSettings.testSettings;
  }

  public static tenancyS3BaseURL(): string {
    return `${tenancyS3BaseUrl}/`;
  }

  private static prvTestSettings: TestSettings = {
    testing: false,
    logLevel: LogLevel.Debug,
    currentUser: userTestData.allUsers[0],
  };
}
