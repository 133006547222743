import {
  isRequired,
  email,
  phoneCheck,
  maxLength,
  minLength,
} from "../../helper/Validation";

import { ReduxFormState } from "../../store/StoreHelper";
import { Forms } from "../../helper/Forms";
import { SelectItem } from "../RenderSelect/RenderSelect";

export interface BranchAddress {
  unitNumber?: string;
  streetNumber?: string;
  streetName?: string;
  suburb?: string;
  postcode: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number;
}

export enum AddressFields {
  locality = "suburb",
  postal_code = "postcode",
  street_number = "streetNumber",
  route = "streetName",
  country = "country",
  administrative_area_level_1 = "state",
}

export const BUSINESS_TYPES: SelectItem[] = [
  new SelectItem(1, "Company"),
  new SelectItem(2, "Partnership"),
  new SelectItem(3, "Sole Trader"),
];

export class RegistrationFormData implements ReduxFormState {
  public static readonly metadata = {
    form: Forms.RegistrationForm,

    validation: {
      businessName: [isRequired("Please enter the business name")],
      businessType: [isRequired("Please enter the business type")],
      branchName: [
        isRequired(
          `Please enter the branch name. Eg: 'Melbourne' or 'Head Office'`
        ),
      ],
      branchPhone: [
        isRequired("Please enter branch phone number"),
        (num) => (phoneCheck(num) ? undefined : "Invalid phone number"),
      ],
      branchEmail: [
        isRequired("Please enter branch email address"),
        email("Check the email format (e.g. name@domain.com)"),
      ],
      abn: [
        isRequired("Please enter a valid ABN"),
        maxLength("ABN should have 11 digits", 11),
        minLength("ABN should have 11 digits", 11),
      ],
    },
  };
  public readonly businessName: string;
  public readonly businessType: number | undefined | null;
  public readonly branchName: string;
  public readonly branchPhone: string;
  public readonly branchEmail: string;
  public readonly branchAddress: BranchAddress;
  public readonly primaryAddress: boolean;
  public readonly logo: any;
  public readonly abn: any;
}
