import { makeStyles } from "@material-ui/core";
import { color, scrollStyles } from "../../../theme/Theme";

export const DashboardSectionStyles = makeStyles({
  root: {
    border: `1px solid ${color.lightGrey}`,
    padding: "1em",
    borderRadius: "0.5rem",
    ...scrollStyles,
  },
  disableSidePaddingRoot: {
    border: `1px solid ${color.lightGrey}`,
    padding: "1em 0",
    ...scrollStyles,
  },
});
