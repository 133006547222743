import React, { FC } from "react";
import LoginBackground from "../../loginBackground/LoginBackground";
import { Grid, Button } from "@material-ui/core";
import HeaderTitle from "../../headerTitle/HeaderTitle";
import { NavButton } from "./RegistrationSteps.data";
import { WrapperStyles } from "./RegistrationStepsWrapperStyles.data";

interface PropsFromParent {
  heading: string;
  subheading: string;
  primaryButton?: NavButton;
  secondaryButton?: NavButton;
  contentBox?: boolean;
  noPaddingContentBox?: boolean;
  greyBackground?: boolean;
  noPaddingButtonsSection?: boolean;
  footerComponent?: JSX.Element;
}

type OwnProps = PropsFromParent;

const RegistrationStepsWrapper: FC<OwnProps> = (props) => {
  const {
    secondaryButton,
    primaryButton,
    heading,
    subheading,
    children,
    contentBox,
    noPaddingContentBox,
    greyBackground,
    footerComponent,
    noPaddingButtonsSection,
  } = props;

  const classes = WrapperStyles();

  return (
    <LoginBackground>
      <Grid container className={classes.container}>
        <Grid
          item
          lg={6}
          md={8}
          sm={10}
          xs={12}
          className={`${classes.formCardContainer} ${
            greyBackground ? classes.greyBackground : ""
          }`}>
          <HeaderTitle title={heading} message={subheading}></HeaderTitle>
          <div
            className={`${classes.contentSection} ${
              contentBox ? classes.contentBox : ""
            } ${noPaddingContentBox ? classes.noPadding : ""}`}>
            {children}
          </div>
          <section
            className={`${classes.buttonsSection} ${
              noPaddingButtonsSection ? classes.noPadding : ""
            }`}>
            {footerComponent && (
              <div className={classes.footerComponent}>{footerComponent}</div>
            )}
            <div>
              {secondaryButton && (
                <Button
                  color="secondary"
                  onClick={secondaryButton.action}
                  className={classes.navButton}>
                  {secondaryButton.text}
                </Button>
              )}
              {primaryButton && (
                <Button
                  color="primary"
                  onClick={primaryButton.action}
                  className={classes.navButton}
                  disabled={primaryButton.disabled}>
                  {primaryButton.text}
                </Button>
              )}
            </div>
          </section>
        </Grid>
      </Grid>
    </LoginBackground>
  );
};

export default RegistrationStepsWrapper;
