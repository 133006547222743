import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import {
  TRADE_CATEGORIES_PATH,
  TRADE_CATEGORY_PATH,
  PostUserTradeCategoriesRequestPayload,
  PostUserTradeCategoriesResponse,
  UserTradeDocumentsUploadData,
  UserTradeDocumentsPayload,
  UserTradeCategoriesResponse,
  DeleteUserTradeCategoryRequestPayload,
  DeleteUserTradeCategoryDocumentRequest,
} from "./UserTradeService.data";
import { MultipartFormData } from "../MultipartFormData.data";
import { getUserId } from "../../helper/Auth";

const serviceType = ServiceType.Users;
const userTradeCategoriesServiceType = ServiceType.UserTradeCategories;
const DOCS_PATH = "documents";

export class TradeCategoriesService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);
  private readonly userTradeCategoriesService: ApiService = new ApiService(
    userTradeCategoriesServiceType
  );

  public postUserTradeCategories(
    userId: string,
    data: PostUserTradeCategoriesRequestPayload
  ):
    | Promise<PostUserTradeCategoriesResponse>
    | PostUserTradeCategoriesResponse {
    return this.service.post(
      { route: [userId, TRADE_CATEGORIES_PATH] },
      JSON.stringify(data.request)
    );
  }

  public getUserTradeCategories():
    | Promise<UserTradeCategoriesResponse>
    | UserTradeCategoriesResponse {
    const userId = getUserId();
    return this.service.get({ route: [userId!, TRADE_CATEGORY_PATH] });
  }

  public deleteUserTradeCategory(
    request: DeleteUserTradeCategoryRequestPayload
  ): Promise<any> | any {
    const userId = getUserId();
    return this.service.delete({
      route: [userId!, TRADE_CATEGORY_PATH, request.tradeCategoryId.toString()],
    });
  }

  public deleteUserTradeDocument(
    request: DeleteUserTradeCategoryDocumentRequest
  ): Promise<any> | any {
    const { userTradeCategoryId, userTradeCategoryDocumentId } = request;
    return this.userTradeCategoriesService.delete({
      route: [
        userTradeCategoryId.toString(),
        DOCS_PATH,
        userTradeCategoryDocumentId.toString(),
      ],
    });
  }

  public postTradeDocs(tradeDocData: UserTradeDocumentsUploadData) {
    const tradeDocStringified: UserTradeDocumentsPayload = {
      file: tradeDocData.file,
      userTradeCategoryDocumentRequest: JSON.stringify(tradeDocData.data),
    };
    const requestObj: MultipartFormData[] = [];
    for (const key in tradeDocStringified) {
      if (tradeDocStringified.hasOwnProperty(key)) {
        requestObj.push({
          name: key,
          content: tradeDocStringified[key],
        });
      }
    }
    return this.userTradeCategoriesService.postMultipart(
      { route: [tradeDocData.data.userTradeCategoryId.toString(), DOCS_PATH] },
      requestObj
    );
  }
}

export default new TradeCategoriesService();
