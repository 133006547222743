import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

export const breakPoints = {
  $xsmall: "0px",
  $small: "480px",
  $medium: "768px",
  $large: "1024px",
  $xlarge: "1200px",
  $xxlarge: "1440px",
};

export const layerIndex = {
  labelAboveInput: 0x100,
  iconAboveForm: 0x200,
  dialogAboveForm: 0x300,
  autoCompletePopUp: 0x400,
};

export const avatarColors = {
  cyan: "#C6CBD4",
  orange: "#FC7E63",
  blue: "#7CBEFD",
  pink: "#FD4176",
  purple: "#6200EE",
};

export const color = {
  primary: "#2d1b49",
  secondary: "#fafafa",
  secondary100: "rgba(0, 0, 0, 0.12)",
  secondary200: "rgba(0, 0, 0, 0.87)",
  secondary300: "#a9a9a9",
  atomic: "#3d505a",
  charcoal: "#4a4a4a",
  secondary400: "rgba(0, 0, 0, .5)",
  secondary500: "rgba(0, 0, 0, 0.6)",
  light: "#ffffff",

  dark: "#202020",
  dark1000: "#1e1e28",
  dark900: "#666666",
  dark800: "#9E9E9E",
  dark800Hover: "#4A4A4A",
  dark700: "#EEEFF1",
  dark600: "#F6F6F6",
  dark500: "#200e14",

  grey: "#e7e7e7",
  lightGrey: "#eeeeee",
  greyText: "#9b9b9b",
  selected: "#ebebeb",
  completeBlue: "#2ca7c0",
  sapling: "#1F2D61",
  pbs: "#002664",
  carrotOrange: "#f28f1d",
  sherpaBlue: "#003348",
  rusticRed: "#1e0f14",
  skyBlue: "#40BCA8",
  incompleteRed: "#ff3848",
  optionalGrey: "#757575",
  incompleteBlack: "1f0d33",
  teal: "#1badc1",
  lightCyan: "#f3fbfc",
  sortedNavy: "#2d1b49",
  inStyleAlmostBlack: "#011A2B",
  warning: "#FF525B",
  warningHover: "#D7444C",
  warningAlert: "#E6000D",
  wildSand: "#f6f6f6",
  borderColor: "#EBEBEB",
  warningLight: '#ffebee',
  addButtonBg: 'rgba(27, 173, 193, 0.1)',
  addButtonText: '#03b2cb',
};

export const AGENCY = {
  IPG: {
    backgroundColor: color.atomic,
    height: "25px",
  },
  PBS: { backgroundColor: color.pbs, height: "48px" },
  IMP: {
    backgroundColor: color.carrotOrange,
    height: "38px",
    paddingTop: "3px",
    paddingBottom: "3px",
  },
  LSI: {
    backgroundColor: color.sherpaBlue,
    height: "25px",
    paddingTop: "14px",
    paddingBottom: "9px",
  },
  NAX: {
    backgroundColor: color.rusticRed,
    height: "42px",
    paddingTop: "4px",
    paddingBottom: "2px",
  },
  SORTED: {
    backgroundColor: color.sortedNavy,
    height: "36px",
    paddingTop: "4px",
    paddingBottom: "2px",
  },
  INS: {
    backgroundColor: color.inStyleAlmostBlack,
    height: "34px",
    paddingTop: "4px",
    paddingBottom: "2px",
  },
  GEO: {
    backgroundColor: color.inStyleAlmostBlack,
    height: "34px",
    paddingTop: "0px",
    paddingBottom: "4px",
  },
  AU_PROP: {
    backgroundColor: color.light,
    height: "34px",
    paddingTop: "0px",
    paddingBottom: "4px",
  },
  AUBUXTON: {
    backgroundColor: color.inStyleAlmostBlack,
    height: "34px",
    paddingTop: "0px",
    paddingBottom: "4px",
  },
  AUSTAR: {
    backgroundColor: color.light,
    height: "34px",
    paddingTop: "0px",
    paddingBottom: "4px",
  },
};

export const themeConstants = {
  inputBackgroundColor: color.dark600,
  fontSize: {
    lg: 16,
    md: 16,
    sm: 14,
    xs: 12,
    xs2: 10,
    xs3: 8,
  },
};

export const scrollStyles = {
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.2)",
    outline: "1px solid slategrey",
    borderRadius: "4px",
    cursor: "pointer",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0,0,0,.3)",
  },
};

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: color.primary,
    },
    secondary: {
      main: color.warning,
    },
  },
  typography: {
    fontFamily: [
      "GT Walsheim",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontSize: 68,
      fontWeight: 500,
      lineHeight: "normal",
    },
    h2: {
      fontSize: "32px",
      fontWeight: "normal",
      color: color.dark500,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: "0.5px",
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.15,
      fontFamily: "GT Walsheim light",
      letterSpacing: ".5px",
      color: color.secondary200,
    },
    h5: {
      fontSize: 27.2,
      fontWeight: 500,
      lineHeight: "normal",
      fontStyle: "normal",
      color: color.primary,
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      color: color.secondary200,
    },
    subtitle1: {
      fontSize: "1.2rem",
      lineHeight: 1.2,
      color: color.secondary500,
    },
    subtitle2: {
      fontSize: "18px",
      lineHeight: 1.32,
      fontWeight: 500,
      color: color.secondary200,
    },
    body1: {
      fontSize: 19,
      lineHeight: 1.5,
      fontWeight: 300,
      color: color.secondary200,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.43,
      color: color.secondary200,
      fontFamily: "GT Walsheim light",
    },
    caption: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.18,
      letterSpacing: 0.4,
      color: color.primary,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.14,
      letterSpacing: 0.8,
      color: color.secondary200,
    },
  },
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          color: color.teal,
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        "&$checked": {
          color: color.teal,
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: "translate(10px, 34px)",
        zIndex: layerIndex.labelAboveInput,
      },
      shrink: {
        zIndex: 0,
      },
    },
    MuiInput: {
      input: {
        height: "30px",
        padding: "0.5em",
        fontSize: "1rem",
        borderStyle: "none",
      },
      inputMultiline: {
        height: "30px",
        padding: "0.5em",
        fontSize: "1rem",
      },
    },
    MuiSelect: {
      select: {
        padding: "14px 10px 14px 10px",
      },
    },
    MuiFormHelperText: {
      root: {
        "&$error": {
          marginTop: 0,
          paddingTop: 5,
          color: color.warning,
        },
        "&$required": {
          marginTop: 0,
          paddingTop: 5,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(0,0,0,0.6)",
        fontSize: "16px",
      },
    },
    MuiTableRow: {
      root: {
        borderLeft: "solid 3px transparent",
      },
    },
    MuiTableCell: {
      root: {
        padding: "0px 5px",
      },
      head: {
        fontWeight: 700,
        fontSize: "1rem",
        lineHeight: "1.2",
      },
      body: {
        fontWeight: 300,
        fontSize: "1rem",
        lineHeight: "1.2",
      },
    },

    MuiExpansionPanelSummary: {
      root: {
        padding: "0px 10px",
      },
      content: {
        margin: "10px 0px 10px 0px !important",
      },
    },

    MuiExpansionPanelDetails: {
      root: {
        padding: "0px 20px 10px 20px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        backgroundColor: color.teal,
        color: color.light,

        "&:hover": {
          boxShadow: "none",
          backgroundColor: color.teal,
        },

        "&:active": {
          boxShadow: "none",
          backgroundColor: color.teal,
        },
      },
      textPrimary: {
        backgroundColor: color.primary,
        color: color.light,
        textAlign: "center",
        borderRadius: "0",
        "&:hover": {
          backgroundColor: color.primary,
        },
        "&$disabled": {
          opacity: ".6",
          color: color.light,
        },
      },

      textSecondary: {
        backgroundColor: color.secondary,
        color: color.primary,
        textAlign: "center",
        borderRadius: "0",
      },
    },

    MuiIconButton: {
      root: {
        borderRadius: "0px",
      },
    },

    MuiMenuItem: {
      root: {
        fontWeight: 400,
        "&$selected": {
          fontWeight: 400,
        },
      },
    },

    MuiTab: {
      root: {
        fontWeight: 500,
        zIndex: layerIndex.labelAboveInput,
        textTransform: "none",
        minWidth: 72,
        padding: "5px 20px",
        "&$selected": {
          borderBottom: `2px solid ${color.primary}`,
        },
      },
    },

    MuiTabs: {
      indicator: {
        display: "none",
      },
    },

    MuiListItem: {
      root: {
        padding: "8px 16px 8px 16px",
      },
      default: {
        padding: "8px 16px 8px 16px",
      },
      gutters: {
        //padding: "8px 16px 8px 16px !important"
      },
    },
    MuiTypography: {
      h1: {
        "@media only screen and (max-width: 480px)": {
          fontSize: "2rem",
        },

        /* Medium devices (landscape tablets, 768px and up) */
        "@media only screen and (min-width:480px) and (max-width: 992px)": {
          fontSize: "2.5rem",
        },
      },
      h5: {
        /* Small devices (portrait tablets and large phones, 600px and up) */
        "@media only screen and (max-width: 600px)": {
          fontSize: "1.2rem",
        },
      },
    },
    MuiAccordion: {
      root: {
        border: `1px solid ${color.borderColor}`,
        boxShadow: 'none',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
    },
    MuiAccordionSummary: {
      root: {
        borderBottom: `1px solid ${color.borderColor}`,
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
          minHeight: 56,
        },
      },
      content: {
        '&$expanded': {
          margin: '12px 0',
        },
      },
      expanded: {},
    },
  },
};

const theme = createMuiTheme(themeOptions);

export default theme;
