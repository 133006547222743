export interface UserDetailsFormData {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  role: UserRole;
  image: File | null;
  password?: string;
}

export enum UserRole {
  tradeAdmin = "trade_admin",
  tradeEmployee = "trade_employee"
}

export const roleToLabel = (role: UserRole) => {
  switch (role) {
    case UserRole.tradeAdmin:
      return "Trade Admin";

    case UserRole.tradeEmployee:
      return "Trade Employee";
  }
}