import { makeStyles } from "@material-ui/core";

export const tagStyles = makeStyles({
  container: (props: any) => ({
    display: "flex",
    padding: "2px 10px",
    borderRadius: props.cardTop ? "4px  4px 0 0" : 5,
    justifyContent: "center",
    width: props.cardTop ? "100%" : "fit-content",
    maxHeight: "1.5rem",
  }),
});
