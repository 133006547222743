import * as axios from "axios";

import { AppSettings } from "../AppSettings";
import AxiosInstance from "./AxiosInstance";
import { HttpContentType } from "./HttpContentType";
import { QueryPath } from "./QueryPath.data";
import { ServiceType } from "./ServiceType.data";
import localStorage from "./LocalStorage";
import { MultipartFormData } from "./MultipartFormData.data";
import { store } from "../store/Store";
import { showToast } from "../helper/HelperFunctions";
import { ToastType } from "../models/toastTypes/ToastTypesData.data";
// import { ContentTypeHelper } from "../helper/Content/ContentType.data";
// import { SuccessActions } from "../store/actions/SuccessActions";

/// <summary>
/// ApiServiceMock cannot inherit ApiService, because that's mocked and that would create an infinite loop, that's why we need ApiServiceBase.
/// </summary>
export default abstract class ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected isFetchingToken: boolean = false;
  protected tokenSubscribers: any = [];

  constructor(serviceType: ServiceType) {
    this.serviceType = serviceType;
  }

  public abstract get<T = void>(path: QueryPath): Promise<T> | T;

  public abstract post<T = void>(path: QueryPath, body: any): Promise<T> | T;

  public abstract put<T = void>(path: QueryPath, body: any): Promise<T> | T;

  public abstract patch<T = void>(path: QueryPath, body: any): Promise<T> | T;

  public abstract delete<T = void>(path: QueryPath): Promise<T>;

  public abstract postMultipart<T = void>(
    path: QueryPath,
    data: MultipartFormData[]
  ): Promise<T> | T;

  public abstract putMultipart<T = void>(
    path: QueryPath,
    data: MultipartFormData[]
  ): Promise<T> | T;

  public abstract patchMultipart<T = void>(
    path: QueryPath,
    data: MultipartFormData[]
  ): Promise<T> | T;

  /* tslint:disable:cyclomatic-complexity */
  public processError(error: any) {
    const errorCode = error.response ? error.response.status || 500 : 500;

    switch (errorCode) {
      case 404:
        return new Error("The request is not found");
      case 500:
        showToast(
          store.dispatch,
          "Sorry, something went wrong",
          ToastType.ERROR
        );
        return new Error("Internal server error");

      case 400:
      case 422: {
        if (error.response.data.errors) {
          const err = error.response.data.errors;

          if (err instanceof Array) {
            const errArr = err as any[];

            if (errArr.length > 0 && errArr[0]) {
              if ((errArr[0] as any).message) {
                return new Error(errArr[0].message.toString());
              } else if ((errArr[0] as any).Message) {
                return new Error(errArr[0].Message.toString());
              } else {
                return new Error(errArr[0].toString());
              }
            }
          } else if ((err as any).message) {
            return new Error((err as any).message.toString());
          } else if ((err as any).Message) {
            return new Error((err as any).Message.toString());
          } else {
            return new Error(err.toString());
          }
        }

        return new Error("Internal server error");
      }
    }
    return error;
  }

  /* tslint:enable */
  protected getConfig(contentType: HttpContentType): axios.AxiosRequestConfig {
    const headers =
      this.serviceType.startsWith("api") ||
      this.serviceType.startsWith("bff/api") ||
      this.serviceType.startsWith("tenancy/api")
        ? {
            "Content-Type": contentType.toString(),
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          }
        : {
            "Content-Type": contentType.toString(),
            Authorization: `Basic ${localStorage.getItem("authTokenBasic")}`,
          };

    return {
      headers,
    };
  }

  protected isAuthTokenRequired(path: string): boolean {
    return path.includes("/api");
  }

  protected getAxiosInstance(): axios.AxiosInstance {
    const instance = AxiosInstance.create();
    const baseUrl = AppSettings.server.baseUrl;

    const subscribeTokenRefresh = (callBack: any) => {
      this.tokenSubscribers.push(callBack);
    };

    const onTokenRefreshed = (error: Error | null) => {
      this.tokenSubscribers.map((cb: any) => cb(error));
    };

    const forceLogout = () => {
      this.isFetchingToken = false;
      // store.dispatch(SuccessActions.flushData());
      // localStorage.clearAll();
      localStorage.setItem("hideRegister", "true");
      window.location.href = `/#/login`;
    };

    instance.interceptors.response.use(
      (response): any => {
        return response;
      },
      (error): any => {
        if (error.response.status !== 401) {
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          if (error.config.url.includes("login")) {
            return Promise.reject(error);
          } // login returns 401 if username or password is wrong
          if (
            !this.isFetchingToken &&
            this.isAuthTokenRequired(error.config.url)
          ) {
            this.isFetchingToken = true;
            const currentRefreshToken = localStorage.getItem("refreshToken");
            return instance
              .post(
                `${baseUrl}/${ServiceType.RefreshToken}`,
                {
                  refresh_token: currentRefreshToken,
                },
                {
                  headers: {
                    Authorization: `Basic ${localStorage.getItem(
                      "authTokenBasic"
                    )}`,
                  },
                }
              )
              .then((response) => {
                const { access_token, refresh_token } = response.data;
                this.isFetchingToken = false;
                localStorage.setItem("authToken", access_token);
                localStorage.setItem("refreshToken", refresh_token);
                error.config.headers.Authorization = `Bearer ${access_token}`;
                return instance(error.config);
              });
          } else if (
            error.config.url.includes("refreshToken") &&
            error.response.status === 401
          ) {
            onTokenRefreshed(new Error("Unable to refresh access token"));
            this.tokenSubscribers = [];
            forceLogout();
          } else {
            const initTokenSubscriber = new Promise((resolve, reject) => {
              subscribeTokenRefresh((errRefreshing: any) => {
                if (errRefreshing) {
                  return reject(errRefreshing);
                }
                error.config.headers.Authorization = `Bearer ${localStorage.getItem(
                  "authToken"
                )}`;
                return resolve(instance(error.config));
              });
            });
            return initTokenSubscriber;
          }
        }
        return Promise.reject(error);
      }
    );
    return instance;
  }

  // Generates url: {AppSettings.service.baseUrl}/{this.serviceType}/{routeParam1}/{routeParam2}/.../{routeParamN}?{queryParam1key}={queryParam1val}&{queryParam2key}={queryParam2val}...
  // Query params with null, undefined or empty string won't be appended to the url.

  /* tslint:disable:cyclomatic-complexity */
  protected getUrl(path: QueryPath): string {
    const baseUrl = AppSettings.server.baseUrl;
    let url: string = this.serviceType
      ? `${baseUrl}/${this.serviceType}`
      : `${baseUrl}`;

    if (path) {
      if (path.route && path.route.length > 0) {
        for (const route of path.route) {
          if (route && route !== "undefined") {
            url += `/${route}`;
          }
        }
      }

      if (path.query) {
        let separator = "?";

        for (const name in path.query) {
          const val = path.query[name];
          if (val !== null && val !== undefined && val !== "") {
            if (!Array.isArray(val)) {
              url += `${separator}${encodeURI(name)}=${encodeURI(
                val!.toString()
              )}`;
            } else {
              for (const v of val) {
                url += `${separator}${encodeURI(name)}=${encodeURI(
                  v!.toString()
                )}`;
              }
            }
            separator = "&";
          }
        }
      }
    }
    return url;
  }

  protected prepareMultiPartForm(data: MultipartFormData[]): FormData {
    // It is expected that if a file is to be given a name that is different from the file system name,
    // the interface created for such objects will have a key named "formDataName" and another named "file" (names are self-explanatory)

    const formData = new FormData();
    for (const item of data) {
      const { content, name } = item;
      if (Array.isArray(content) && content.length > 0) {
        for (const element of content) {
          if (
            typeof element === "object" &&
            element.hasOwnProperty("formDataName")
          ) {
            formData.append(name, element.file, element.formDataName);
          } else {
            formData.append(name, element);
          }
        }
      } else {
        if (
          typeof content === "object" &&
          content !== null &&
          content.hasOwnProperty("formDataName")
        ) {
          formData.append(name, content.file, content.formDataName);
        } else {
          formData.append(name, content);
        }
      }
    }
    return formData;
  }
}
