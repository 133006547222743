import { ErrorMessage, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import {
  accountNameRegex,
  accountNumberRegex,
  BankValues,
  bsbNumberRegex,
  bsbValidatorPattern,
  errorMessages,
  initialBankValues,
  lengths,
  maskBankAccountDetails,
  MaskDetails,
} from "../AddPaymentMethodConstants.data";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import CustomInput from "../../customInput/CustomInput";
import secureIcon from "../../../assets/images/payment/lock-24-px.svg";
import {
  PaymentActions,
  PaymentErrorPayload,
  SaveSelectedPaymentMethodPayload,
} from "../../../store/actions/PaymentActions";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { BankAccountRequest } from "../../../models/payment/PaymentRequest.data";
import { LoadingIndicatorActions } from "../../../store/actions/LoadingIndicatorAction";
import { ApplicationState } from "../../../store/RootReducers";
import { getTradeId } from "../../../helper/Auth";
import { addBankStyles } from "./AddBankStyles.data";
import { labels as LABELS } from "../AddPaymentMethodConstants.data";
import { color } from "../../../theme/Theme";

interface Props
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  onBack: () => void;
  onSubmit?: () => void;
}

const AddBank: FC<Props> = ({
  paymentErrorState,
  paymentMethodId,
  onBack,
  saveSelectedPaymentMethod,
  getPaymentAuthKey,
  paymentSuccess,
  onSubmit,
  resetPostPaymentTokenSuccess,
}) => {
  const bsbNumberValidator = CustomInput(bsbValidatorPattern);
  const [accountNumberError, setAccountNumberError] = useState<boolean>(false);
  const [maskedBankAccountDetails, setMaskedBankAccountDetails] =
    useState<MaskDetails>({
      paymentDetails: "",
      logo: "",
    });

  useEffect(() => {
    saveSelectedPaymentMethod({
      paymentMethodId,
      maskedDetails: maskedBankAccountDetails,
    });
  }, [paymentMethodId]);

  useEffect(() => {
    if (paymentSuccess) {
      resetPostPaymentTokenSuccess();
      onSubmit && onSubmit();
    }
  }, [paymentSuccess]);

  const tradeId = getTradeId()!;

  const classes = addBankStyles();

  const handleAccountNumber = (
    accountNumber: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    setFieldValue(
      "accountNumber",
      accountNumber.replace(accountNumberRegex, "")
    );
    if (accountNumber.length > 10) {
      setAccountNumberError(true);
    } else if (accountNumberError) {
      setAccountNumberError(false);
    }
  };

  const handleSubmit = (values: BankValues) => {
    const maskedBankAccountDetails = maskBankAccountDetails(
      values.accountNumber,
      values.bsbNumber
    );
    setMaskedBankAccountDetails(maskedBankAccountDetails);
    saveSelectedPaymentMethod({
      paymentMethodId: "2",
      maskedDetails: maskedBankAccountDetails,
    });
    const paymentRequest: BankAccountRequest = {
      type: "BANKACCOUNT",
      accountName: values.accountName.trim(),
      accountNumber: values.accountNumber,
      bsbNumber: values.bsbNumber,
      email: "",
    };
    getPaymentAuthKey(paymentRequest, parseInt(tradeId));
  };

  return (
    <>
      <Formik
        initialValues={initialBankValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={Yup.object().shape({
          accountName: Yup.string()
            .required(errorMessages.required)
            .max(lengths.accNameMax, errorMessages.accNameLength)
            .matches(accountNameRegex, errorMessages.accName),
          bsbNumber: Yup.string()
            .required(errorMessages.required)
            .matches(bsbNumberRegex, errorMessages.invalidBsb),
          accountNumber: Yup.string()
            .required(errorMessages.required)
            .min(lengths.accNumMin, errorMessages.accNumMin)
            .max(lengths.accNumMax),
        })}>
        {({
          touched,
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          isValid,
        }) => (
          <>
            <FormControl
              className={classes.inputs}
              error={!!errors.accountName}>
              <InputLabel>{LABELS.accountName}</InputLabel>
              <Input
                name="accountName"
                type="text"
                onChange={(event) =>
                  setFieldValue("accountName", event.target.value)
                }
                value={values.accountName}
              />
              {!!errors.accountName && (
                <FormHelperText>{errors.accountName}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              className={classes.inputs}
              error={!!errors.bsbNumber && touched.bsbNumber}>
              <InputLabel>{LABELS.bsbNumber}</InputLabel>
              <Input
                name="bsbNumber"
                onChange={handleChange}
                value={values.bsbNumber}
                inputComponent={bsbNumberValidator}
                endAdornment={
                  <InputAdornment position="end">
                    <img src={secureIcon} className={classes.secureIcon} />
                  </InputAdornment>
                }
              />
              <FormHelperText>
                <ErrorMessage name="bsbNumber" />
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.inputs} error={accountNumberError}>
              <InputLabel>{LABELS.accountNumber}</InputLabel>
              <Input
                name="accountNumber"
                value={values.accountNumber}
                onChange={(event) =>
                  handleAccountNumber(event.target.value, setFieldValue)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <img src={secureIcon} className={classes.secureIcon} />
                  </InputAdornment>
                }
              />
              {accountNumberError && (
                <FormHelperText>{errorMessages.accNumMax}</FormHelperText>
              )}
            </FormControl>
            {paymentErrorState && (
              <Typography variant="caption" style={{ color: color.warning }}>
                {paymentErrorState.error}
              </Typography>
            )}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.mainContainer}>
              <Button
                fullWidth
                className={classes.secondaryButton}
                onClick={onBack}>
                {LABELS.back}
              </Button>
              <Button
                fullWidth
                variant="contained"
                className={classes.primaryButton}
                onClick={() => {
                  if (isValid) {
                    handleSubmit();
                  }
                }}>
                {LABELS.save}
              </Button>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  paymentMethodId: state.payment.selectedPaymentMethod.paymentMethodId,
  paymentErrorState: state.payment.error,
  paymentSuccess: state.payment.success,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetPostPaymentTokenSuccess: () => {
    dispatch(PaymentActions.postPaymentTokenSuccessReset());
  },
  saveSelectedPaymentMethod: (data: SaveSelectedPaymentMethodPayload) => {
    dispatch(PaymentActions.saveSelectedPaymentMethod(data));
  },
  getPaymentAuthKey: (data: BankAccountRequest, tradeId: number) => {
    dispatch(
      PaymentActions.getPaymentAuthKeyStart({
        data,
        onSuccess: (token: string) => {
          dispatch(
            PaymentActions.postPaymentTokenStart({
              data: { resultKey: token, tradeId: tradeId },
              onSuccess: () => {
                dispatch(PaymentActions.getPaymentMethodsStart({ tradeId }));
                dispatch(LoadingIndicatorActions.hide());
              },
            })
          );
        },
        onError: (error: PaymentErrorPayload) => {
          dispatch(PaymentActions.getPaymentAuthKeyError(error));
          dispatch(LoadingIndicatorActions.hide());
        },
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBank);
