import { InviteUserFormData } from "../../component/inviteUser/InviteUserFomData.data";
import { PostTradeUserRequestData } from "../../services/tradeUsers/TradeUsers.data";

export function InviteUserFormDataToRequestConverter(
  formData: InviteUserFormData
): PostTradeUserRequestData {
  let response: PostTradeUserRequestData;
  const { email, firstName, lastName, role } = formData;
  const userTradeEntitlements = role.map((role) => ({role}))
  response = {
    email,
    firstName,
    lastName,
    username: email,
    userTradeEntitlements,
  };
  return response;
}
