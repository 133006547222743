export const LABELS = {
  enterBranchCode: "Enter unique branch code",
  enterCodeMessage:
    "To start working with a new agency, enter their unique branch code. You’ll be asked to accept their trade agreement before you are able to complete jobs for them.",
  addBranchCode: "Add branch code",
  addAnotherBranchCode: "Add another branch code",
  branchCode: "Branch code",

  // Updated terms
  updateTerms: "Updated terms",
  updatedTermsDescription:
    "A partner that you work with has updated their terms and conditions. To continue working with this agency, please review and accept their updated terms and conditions. ",

  // Select Agencies:
  alreadySelected: "You have already selected branch with given code",
  incorrectCode: "No branch found with the given code",
  alreadyResponded:
    "You have already responded to branch terms with the given code. You can perform further terms related actions by clicking on branch card on the Agents screen",

  // T&C Modal
  termsAndConditions: "Terms and conditions",
  depotsLabel: "Office to receive notifications",
  selectDepot: "Select Office",
};
