import {
  CommentAction,
  CommentActions,
  CommentActionType,
} from "../actions/CommentActions";
import {
  GetCommentsRequestPayload,
  PostCommentRequestPayload,
  TradesCommentErrorType,
} from "../../services/comment/CommentServiceData.data";
import { Logging } from "../../helper/Logging";
import { put, takeEvery } from "redux-saga/effects";
import CommentService from "../../services/comment/CommentService";
import { TradesComment } from "../../models/comment/Comment";

// Loading indicators are not required here since comments will be loaded later on
// and we can show in-place loaders

export function* getCommentsWorker(action: CommentAction) {
  const data = action.data as GetCommentsRequestPayload;
  try {
    Logging.debug("TradeSaga.getCommentsWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    const response: TradesComment = (yield CommentService.getComments(data))!;

    yield put(CommentActions.GetCommentsSuccess(response));
  } catch (error) {
    Logging.error("TradeSaga.getCommentsWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(CommentActions.GetCommentsError(TradesCommentErrorType.GetError));
  }
}

export function* postCommentWorker(action: CommentAction) {
  const data = action.data as PostCommentRequestPayload;

  try {
    Logging.debug("TradeSaga.postCommentWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    const response: TradesComment = (yield CommentService.postComment(data))!;

    yield put(CommentActions.PostCommentSuccess(response));
  } catch (error) {
    Logging.error("TradeSaga.postCommentWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(
      CommentActions.PostCommentError(
        data.parentId
          ? TradesCommentErrorType.PostReplyError
          : TradesCommentErrorType.PostError
      )
    );
  }
}

const commentsWatcher = [
  takeEvery(CommentActionType.GetCommentsRequest, (action) =>
    getCommentsWorker({
      type: action.type,
      data: (action as CommentAction).data,
    })
  ),

  takeEvery(CommentActionType.PostCommentRequest, (action) =>
    postCommentWorker({
      type: action.type,
      data: (action as CommentAction).data,
    })
  ),
];

export default commentsWatcher;
