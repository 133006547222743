import {
  GetTradeSuccessPayload,
  GetTradeRequestPayload,
  GetTradeErrorPayload
} from "../../services/listBranches/ListBranchesServiceData.data";
import { StoreAction } from "../StoreHelper";
import { BranchResponse } from "../../services/branch/Branch.data";

export enum ListBranchesActionType {
  ListBranchesRequest = "LIST_BRANCHES_REQUEST",
  ListBranchesSuccess = "LIST_BRANCHES_SUCCESS",
  ListBranchesError = "LIST_BRANCHES_ERROR",

  UpdateBranch = "LIST_BRANCH_UPDATE"
}

export type ListBranchesActionPayload =
  | GetTradeSuccessPayload
  | GetTradeRequestPayload
  | GetTradeErrorPayload
  | BranchResponse;

export type ListBranchesAction = StoreAction<
  ListBranchesActionType,
  ListBranchesActionPayload
>;

export class ListBranchesActions {
  public static listBranchesRequest(
    data: GetTradeRequestPayload
  ) {
    return {
      type: ListBranchesActionType.ListBranchesRequest,
      data,
    };
  }

  public static listBranchesSuccess(
    data: GetTradeSuccessPayload
  ) {
    return {
      type: ListBranchesActionType.ListBranchesSuccess,
      data,
    };
  }

  public static listBranchesError(data: GetTradeErrorPayload) {
    return { type: ListBranchesActionType.ListBranchesError, data };
  }

  public static updateBranch(data: BranchResponse) {
    return {type: ListBranchesActionType.UpdateBranch, data}
  }
}
