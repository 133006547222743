import SavedPaymentMethods from '../../component/dashboard/settings/payment/SavedPaymentMethods'
import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import ContentHeadingSection from '../../component/dashboard/contentHeadingSection/ContentHeadingSection'

const Payment: FC = ({ }) => {

  return (
    <Box p={2}>
      <ContentHeadingSection hideOnSmallScreens heading={'Billing'} />
      <SavedPaymentMethods />
    </Box>
  )
}

export default Payment