import React, { FC, useState } from "react";
import RegistrationStepsWrapper from "../registrationStepsComponents/registrationStepsWrapper/RegistrationStepsWrapper";
import { LABELS, VALIDATION_SCHEMA } from "./UserDetailsConstants.data";
import ContentBoxSection from "../registrationStepsComponents/contentBox/ContentBoxSection";
import { Formik, Field } from "formik";
import { UserDetailsFormData, UserRole } from "./UserDetailsData.data";
import RenderInput from "../FormikComponents/RenderInputField";
import { Grid, useTheme, Box } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RenderImageInput from "../RenderImageInput/RenderImageInput";
import {
  RenderInputFieldStyles,
  inputsFields,
  loginLabels,
} from "../FormikComponents/RenderInputFieldStyle";
import ContentBoxSubheading from "../registrationStepsComponents/contentBox/ContentBoxSubheading";
import RenderCheckBox from "../FormikComponents/RenderCheckbox";
import { GetUserResponse } from "../../services/login/LoginData";
import { get } from "lodash";
import { UserDetailsComponentStyles } from "./UserDetailsStyles.data";
import { UserDocument } from "../../models/userDocument/UserDocument.data";
import UserDocuments from "../dashboard/settings/profile/userDocuments/UserDocuments";
import DashboardSectionInnerHeading from "../dashboard/dashboardSectionInnerHeading/DashboardSectionInnerHeading";
import { AddUserDocData } from "../dashboard/settings/profile/userDocuments/UserDocumentsData.data";
import PasswordInstructions from "../passwordInstructions/PasswordInstructions";
import { phoneCheck } from "../../helper/Validation";

interface Props {
  userDetails: GetUserResponse | undefined | null;
  userDocuments: UserDocument[] | undefined | null;
  onSubmit: (formData: UserDetailsFormData) => void;
  onUserDocSubmit: (data: AddUserDocData) => void;
  onRemoveUserDocument: (userDocumentId: number) => void;
  isInvited: boolean;
}

const UserDetailsComponent: FC<Props> = ({
  userDetails,
  onSubmit,
  isInvited,
  userDocuments,
  onRemoveUserDocument,
  onUserDocSubmit,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  let firstName = "";
  let lastName = "";
  let email = "";
  let mobileNumber = "";
  let image = null;
  let role: UserRole;
  if (userDetails) {
    firstName = userDetails.firstName;
    lastName = userDetails.lastName;
    email = userDetails.email;
    mobileNumber = userDetails.mobileNumber;
    image = userDetails.logo || null;
  }
  role =
    get(userDetails, "userTradeEntitlements.0.role", false) ===
    UserRole.tradeAdmin
      ? UserRole.tradeAdmin
      : UserRole.tradeEmployee;
  const formInitialValues: UserDetailsFormData = {
    firstName,
    lastName,
    email,
    mobileNumber: mobileNumber || "",
    role,
    image,
  };

  const inputClasses = RenderInputFieldStyles();
  const classes = UserDetailsComponentStyles();
  const renderInputProps = {
    style: inputsFields,
    labelStyle: loginLabels,
    overrideInputStyles: { input: inputClasses.input },
    overrideLabelStyles: { shrink: inputClasses.labels },
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validateOnBlur
      validationSchema={VALIDATION_SCHEMA(isInvited)}>
      {({ handleSubmit, setFieldValue, values, dirty, isValid }) => (
        // tslint:disable-next-line:cyclomatic-complexity
        <RegistrationStepsWrapper
          heading={isInvited ? LABELS.inviteHeading : LABELS.heading}
          subheading={isInvited ? LABELS.inviteSubheading : LABELS.subheading}
          contentBox
          primaryButton={{
            text: "Next",
            action: () => {
              handleSubmit();
            },
            disabled: !dirty || !isValid,
          }}
          noPaddingButtonsSection>
          <ContentBoxSection heading={LABELS.yourDetails}>
            <Grid
              container
              spacing={isSmall ? 0 : 6}
              className={classes.container}>
              <Grid item lg={8}>
                <Grid container spacing={4}>
                  <Grid
                    item
                    className={classes.imageContainer}
                    xs={12}
                    md={2}
                    lg={2}>
                    <Field
                      name="image"
                      component={RenderImageInput}
                      label={LABELS.image}
                      onChange={(img: any) => setFieldValue("image", img)}
                      {...renderInputProps}
                      src={
                        values.image
                          ? typeof values.image === "string"
                            ? `${values.image}`
                            : URL.createObjectURL(values.image)
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                    <Field name="firstName">
                      {({ field }) => (
                        <RenderInput
                          label={LABELS.firstName}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                    <Field name="lastName">
                      {({ field }) => (
                        <RenderInput
                          label={LABELS.lastName}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="email">
                      {({ field }) => (
                        <RenderInput
                          label={LABELS.email}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      validate={(value) =>
                        phoneCheck(value) ? null : "Invalid phone number"
                      }
                      name="mobileNumber">
                      {({ field }) => (
                        <RenderInput
                          label={LABELS.mobileNumber}
                          {...renderInputProps}
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  {isInvited && (
                    <Grid item xs={12}>
                      <Field name="password">
                        {({ field }) => (
                          <RenderInput
                            name="password"
                            label={LABELS.password}
                            type={"password"}
                            onChange={(event) => {
                              setFieldValue(
                                "password",
                                event.currentTarget.value
                              );
                            }}
                            {...field}
                          />
                        )}
                      </Field>
                      <PasswordInstructions />
                      <br />
                      <Field name="confirmPassword">
                        {({ field }) => (
                          <RenderInput
                            name="confirmPassword"
                            label={LABELS.confirm}
                            type={showPassword ? "text" : "password"}
                            onChange={(event) => {
                              setFieldValue(
                                "confirmPassword",
                                event.currentTarget.value
                              );
                            }}
                            {...field}
                            handleShowPassword={handleShowPassword}
                            showPassword={showPassword}
                          />
                        )}
                      </Field>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                className={isSmall ? "" : classes.roleContainer}
                item
                lg={4}>
                <ContentBoxSubheading text={LABELS.roles} />
                <Field type="checkbox" name="role" value={UserRole.tradeAdmin}>
                  {(fieldProps) => (
                    <RenderCheckBox
                      disabled
                      forceCheck={values.role === UserRole.tradeAdmin}
                      label={LABELS.tradeAdmin}
                      color={"primary"}
                      {...fieldProps}
                    />
                  )}
                </Field>
                <Field
                  type="checkbox"
                  name="role"
                  value={UserRole.tradeEmployee}>
                  {(fieldProps) => (
                    <RenderCheckBox
                      disabled
                      forceCheck={values.role === UserRole.tradeEmployee}
                      label={LABELS.tradeEmployee}
                      color={"primary"}
                      {...fieldProps}
                    />
                  )}
                </Field>
              </Grid>

              {/* invited users can't upload docs on this screen since they don't have auth token */}
              {!isInvited && (
                <Grid container xs={12}>
                  <Box
                    mb={2}
                    width="100%"
                    border="1px solid #eee"
                    borderRadius="5px">
                    <Box ml={2}>
                      <DashboardSectionInnerHeading text={"Documents"} />
                    </Box>
                    <UserDocuments
                      userDocuments={userDocuments || []}
                      onSubmit={onUserDocSubmit}
                      onRemoveUserDocument={onRemoveUserDocument}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </ContentBoxSection>
        </RegistrationStepsWrapper>
      )}
    </Formik>
  );
};

export default UserDetailsComponent;
