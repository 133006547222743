import React, { FC, useEffect, useState } from "react";
import { LABELS } from "./OverViewConstants.data";
import ContentHeadingSection from "../contentHeadingSection/ContentHeadingSection";
// import DashboardSection from "../dashboardSection/DashboardSection";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import useFetch from "../../../hooks/useFetch";
import counterService from "../../../services/counter/CounterService";
import Counter from "../../counter/Counter";
import DashboardSectionHeading from "../dashBoardSectionHeading/DashboardSectionHeading";
import DashboardSection from "../dashboardSection/DashboardSection";
import { GoogleMap, LatLng } from "../../googleMap/GoogleMap";
import {
  getAddressString,
  getJobProperty,
  titleCase,
} from "../../../helper/HelperFunctions";
import ListItemSecondaryDetailComponent from "../quotes/ListItemSecondaryDetailComponent";
import DashboardListItem from "../../lisItems/DashboardListItem";
import { MOMENT_DATE_FORMATS } from "../../../constants/AppConstants";
import { JobStatus } from "../../../models/jobs/JobData";
import { AccountCircle, ArrowRight, Home } from "@material-ui/icons";
import moment from "moment";
import { color } from "../../../theme/Theme";
import { overviewStyles } from "./OverviewStyles";
import { useHistory } from "react-router";
import { routes } from "../../../Routes";
import TradeUsersService from "../../../services/tradeUsers/TradeUsersService";
import { getTradeId } from "../../../helper/Auth";
import { ROLE_TYPES } from "../../../helper/TradesHelper";
import JobsService from "../../../services/jobs/JobsService";
import { NoItemsToShow } from "./NoItemsToShow";

interface Props {
  monthJobCount: number;
}

const OverView: FC<Props> = (props) => {
  const {
    state: { data: counterData, loading: counterLoading },
    fetchData: fetchCounts,
  } = useFetch(() => counterService.getSummaryCounts(), undefined, true);

  const { state: tradeUsersState, fetchData: getTradeUsers } = useFetch(
    () => TradeUsersService.get({ tradeId: getTradeId()! }),
    undefined,
    true
  );

  // all scheduled jobs
  const { state: scheduledJobsState, fetchData: getScheduledJobs } = useFetch(
    () =>
      JobsService.post(
        {
          STATUS: [JobStatus.Scheduled],
        },
        true
      ),
    undefined,
    true
  );

  // completed this month
  const {
    state: jobsCompletedThisMonthState,
    fetchData: getJobsCompletedThisMonth,
  } = useFetch(
    () =>
      JobsService.post(
        {
          COMPLETED_AFTER: [
            moment()
              .subtract(1, "month")
              .endOf("month")
              .format(MOMENT_DATE_FORMATS.DD_MM_YYYY),
          ],
        },
        true
      ),
    undefined,
    true
  );

  const [zoomCenter, setZoomCenter] = useState<LatLng | null>(null);

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);

  useEffect(() => {
    getTradeUsers();
    getScheduledJobs();
    fetchCounts();
    getJobsCompletedThisMonth();
  }, []);

  const classes = overviewStyles();
  const history = useHistory();

  console.log("scheduledJobsState", scheduledJobsState);

  return (
    <Box p={2}>
      <ContentHeadingSection
        heading={LABELS.welcomeBack(localStorage.getItem("firstName")!)}
        hideBack
      />

      <DashboardSectionHeading text={LABELS.activity} />
      <Counter
        loading={counterLoading || counterData === undefined}
        data={
          counterData
            ? [
                {
                  label: LABELS.quoteRequests,
                  value: counterData.quotes.newQuoteCount,
                  action: () => {
                    history.push(routes.dashboard.quotes());
                  },
                },
                {
                  label: LABELS.submittedQuotes,
                  value: counterData.quotes.submittedQuoteCount,
                  action: () => {
                    history.push(routes.dashboard.quotes("sent"));
                  },
                },
                {
                  label: LABELS.bookingRequired,
                  value: counterData.jobs.bookingRequiredCount,
                  action: () => {
                    history.push(routes.dashboard.jobs());
                  },
                },
                {
                  label: LABELS.pendingApproval,
                  value: counterData.jobs.approvalPendingCount,
                  action: () => {
                    history.push(
                      routes.dashboard.jobs([JobStatus.ApprovalPending])
                    );
                  },
                },
              ]
            : []
        }
        onRefresh={() => {}}
      />

      {/* Map for upcoming jobs */}
      <DashboardSection heading={LABELS.yourScheduledJobs}>
        <Grid container spacing={2}>
          {(!scheduledJobsState.data ||
            scheduledJobsState.loading ||
            scheduledJobsState.data.properties.length > 0) && (
            <Grid item sm={12} md={6} className={classes.map}>
              {!scheduledJobsState.data || scheduledJobsState.loading ? (
                <Box className={classes.centeredBox}>
                  <CircularProgress />
                </Box>
              ) : (
                <GoogleMap
                  markers={
                    scheduledJobsState.data &&
                    scheduledJobsState.data.properties
                      ? scheduledJobsState.data.properties
                          .filter((p) => p.geocode)
                          .map((p) => ({
                            lat: +p.geocode!.latitude,
                            lng: +p.geocode!.longitude,
                          }))
                      : []
                  }
                  zoomCenter={zoomCenter}
                />
              )}
            </Grid>
          )}
          <Grid
            item
            sm={12}
            md={
              scheduledJobsState.data &&
              scheduledJobsState.data.paginatedJobResponse.content.length === 0
                ? 12
                : 6
            }
            className={classes.scheduledJobsListContainer}>
            {!scheduledJobsState.data || scheduledJobsState.loading ? (
              <Box className={classes.centeredBox}>
                <CircularProgress />
              </Box>
            ) : scheduledJobsState.data.paginatedJobResponse.content.length >
              0 ? (
              <>
                <List className={classes.scheduledJobsList}>
                  {scheduledJobsState.data.paginatedJobResponse.content.map(
                    (sj) => {
                      const { keyDates } = sj;
                      const property = getJobProperty(
                        sj,
                        scheduledJobsState.data!.properties
                      );
                      const { photos, suburb, state, postcode, geocode } =
                        property;

                      return (
                        <ListItem
                          button
                          className={classes.viewJobListItem}
                          onClick={() => {
                            if (
                              geocode &&
                              geocode.latitude &&
                              geocode.longitude
                            ) {
                              setZoomCenter({
                                lat: +geocode.latitude,
                                lng: +geocode.longitude,
                              });
                            } else {
                              setSnackbarMessage(LABELS.locationNotAvailable);
                              setZoomCenter(null);
                            }
                          }}>
                          <DashboardListItem
                            image={
                              photos && photos.length ? (
                                photos[0].link
                              ) : (
                                <Home />
                              )
                            }
                            primary={getAddressString(property)}
                            secondary={`${suburb && titleCase(suburb)}${
                              suburb && ","
                            } ${state}${state && ","} ${postcode}`}
                            secondaryData={
                              keyDates && keyDates.startDate ? (
                                <Box display="flex">
                                  <ListItemSecondaryDetailComponent
                                    primary={moment(
                                      keyDates.startDate,
                                      MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM
                                    ).format(MOMENT_DATE_FORMATS.DO_MMM_YYYY)}
                                    secondary={keyDates.timeSlot.timeOfDay!}
                                    primaryColor={color.warning}
                                  />
                                </Box>
                              ) : undefined
                            }
                          />
                          <Box
                            display="flex"
                            justifyContent="center"
                            className={`${classes.viewJob} view-job`}>
                            <ArrowRight
                              onClick={() =>
                                history.push(routes.dashboard.viewJob(sj.id))
                              }
                            />
                          </Box>
                        </ListItem>
                      );
                    }
                  )}
                </List>
                <Box
                  onClick={() => {
                    history.push(routes.dashboard.jobs([JobStatus.Scheduled]));
                  }}
                  className={classes.seeAllContainer}
                  py={1}>
                  <Typography color="primary" variant="caption">
                    {"View all >"}
                  </Typography>
                </Box>
              </>
            ) : (
              <NoItemsToShow />
            )}
          </Grid>
        </Grid>
      </DashboardSection>

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <DashboardSection
            paperClass={classes.overviewListSection}
            heading={LABELS.completedJobs}>
            {jobsCompletedThisMonthState.data === undefined ||
            jobsCompletedThisMonthState.loading ? (
              <Box className={classes.centeredBox}>
                <CircularProgress />
              </Box>
            ) : jobsCompletedThisMonthState.data!.paginatedJobResponse.content
                .length > 0 ? (
              <List>
                {jobsCompletedThisMonthState.data!.paginatedJobResponse.content.map(
                  (job) => {
                    const { completedOn } = job;
                    const property = getJobProperty(
                      job,
                      jobsCompletedThisMonthState.data!.properties
                    );
                    const { photos, suburb, state, postcode } = property;
                    return (
                      <ListItem className={classes.viewJobListItem} button>
                        <DashboardListItem
                          image={
                            photos && photos.length ? photos[0].link : <Home />
                          }
                          primary={getAddressString(property)}
                          secondary={`${suburb && titleCase(suburb)}${
                            suburb && ","
                          } ${state}${state && ","} ${postcode}`}
                          secondaryData={
                            completedOn ? (
                              <Box display="flex">
                                <ListItemSecondaryDetailComponent
                                  primary="Completed on"
                                  secondary={moment(
                                    completedOn,
                                    MOMENT_DATE_FORMATS.DD_MM_YYYY_HH_MM
                                  ).format(MOMENT_DATE_FORMATS.DO_MMM_YYYY)}
                                  primaryColor={color.primary}
                                />
                              </Box>
                            ) : undefined
                          }
                        />
                        <Box
                          display="flex"
                          justifyContent="center"
                          className={`${classes.viewJob} view-job`}>
                          <ArrowRight
                            onClick={() =>
                              history.push(routes.dashboard.viewJob(job.id))
                            }
                          />
                        </Box>
                      </ListItem>
                    );
                  }
                )}
              </List>
            ) : (
              <NoItemsToShow />
            )}
          </DashboardSection>
        </Grid>
        <Grid item sm={6} xs={12}>
          <DashboardSection
            paperClass={classes.overviewListSection}
            heading={LABELS.yourTeam}>
            {!tradeUsersState.data || tradeUsersState.loading ? (
              <Box className={classes.centeredBox}>
                <CircularProgress />
              </Box>
            ) : tradeUsersState.data.content.length > 0 ? (
              <List>
                {tradeUsersState.data.content.map((user) => (
                  <ListItem>
                    <ListItemAvatar>
                      {user.logo ? (
                        <Avatar src={user.logo} />
                      ) : (
                        <AccountCircle />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${user.firstName} ${user.lastName}`}
                      secondary={ROLE_TYPES[user.userTradeEntitlements[0].role]}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <NoItemsToShow />
            )}
          </DashboardSection>
        </Grid>
      </Grid>

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3000}
        color="white"
        onClose={() => setSnackbarMessage(null)}
        message={
          <Box color="white">
            <Typography color="inherit" variant="h4">
              {snackbarMessage}
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default OverView;
