import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import {
  LoginPayload,
  RegisterUserPayload,
  RefreshTokenPayload,
  VerifyUserPayload,
  VerificationLinkPayload,
  ForgotPasswordPayload,
  ResetPasswordPayload,
  LoginResponse,
} from "./LoginData";

const serviceType = ServiceType.Auth;

export class LoginService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public postLogin(
    payload: LoginPayload
  ): Promise<LoginResponse> | LoginResponse {
    return this.service.post({ route: ["login"] }, payload);
  }

  public postRegisterUser(payload: RegisterUserPayload): Promise<void> | void {
    return this.service.post({ route: ["registerUser"] }, payload);
  }

  public getClientToken(): Promise<void> | void {
    return this.service.get({ route: ["token"] });
  }

  public postRefreshToken(payload: RefreshTokenPayload): Promise<void> | void {
    return this.service.post({ route: ["refreshToken"] }, payload);
  }

  public postVerifyUser(payload: VerifyUserPayload): Promise<void> | void {
    return this.service.post(
      { route: ["verifyUser"], query: { token: payload.token } },
      {}
    );
  }

  public postVerificationLink(
    payload: VerificationLinkPayload
  ): Promise<void> | void {
    return this.service.post({ route: ["sendVerificationLink"] }, payload);
  }

  public postForgotPasswordLink(
    payload: ForgotPasswordPayload
  ): Promise<void> | void {
    return this.service.post(
      { route: ["forgot-password", payload.username] },
      {}
    );
  }

  public putResetPassword(
    payload: ResetPasswordPayload
  ): Promise<void> | void {
    const { onSuccess, ...requestData } = payload;
    return this.service.put(
      {
        route: ["forgot-password"],
      },
      requestData
    );
  }

  public getGuestToken(): Promise<void> | void {
    return this.service.get({
      route: ["guestToken"],
    });
  }
}

const loginService = new LoginService();
export default loginService;
