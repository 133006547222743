import React, { FC } from "react";
import logo from "../../assets/sorted_logo.svg"
import { HeaderStyles } from "./HeaderStyle";

interface Props {}

const Header: FC<Props> = (props) => {
  const classes = HeaderStyles();
  return <header className={`${classes.wrapper} ${classes.centered}`}>
    <img src={logo} />
  </header>;
};

export default Header;
