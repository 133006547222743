import {
  UserDocType,
  userDocTypesOptions,
} from "../models/userDocument/UserDocumentTypes.data";

export const getLabelFromUserDocumentType = (docType: UserDocType) => {
  let label;
  userDocTypesOptions.forEach((option) => {
    if (option.value === docType) label = option.label;
  });
  return label;
};
