import { makeStyles } from "@material-ui/core/styles";

export const ListUsersStyle = makeStyles({
  subheading: {
    marginTop: "1em",
  },
  listItem: {
    width: "100%",
    maxWidth: "inherit",
    borderRadius: "6px",
    border: "solid 1px #eeeeee",
  },
  listItemBox: {
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
    margin: "20px 0",
  },
  listItemText: {
    flexBasis: "100%",
  },
  addIcon: {
    padding: "0px",
  },
  primaryBranch: {
    height: "20px",
    borderRadius: "2px",
    backgroundColor: "#b1e2e457",
    padding: "4px 5px 0 5px",
  },
  primaryBranchText: {
    fontSize: "10px",
    textAlign: "center",
    color: "#000000",
  },
  skipLink: {
    color: "#5ebfe6cf",
    float: "right",
    fontSize: "13px",
    margin: "1em 0",
  },
  textContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  pointer: {
    cursor: "pointer",
  },
});
