import {
  isRequired,
  email,
  minLength,
  passwordValidator,
} from "../../helper/Validation";

import { ReduxFormState } from "../../store/StoreHelper";

export class ResetPasswordData implements ReduxFormState {
  public static readonly metadata = {
    validation: {
      email: [
        isRequired("Please enter the email address"),
        email("Check the email format (e.g. name@domain.com)"),
      ],
      password: [
        isRequired("Please enter the password"),
        passwordValidator("Password doesn't fulfil the requirements"),
      ],
      firstName: [
        isRequired("Please enter your first name"),
        minLength("Name must me at least 2 characters", 2),
      ],
      lastName: [isRequired("Please enter your last name")],
      confirmPassword: [
        (value, allValues) =>
          value !== allValues.password ? "Passwords don't match" : undefined,
      ],
    },
  };
  public readonly password: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string | undefined | null;
}
