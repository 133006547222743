import * as React from "react";
import { FormControl } from "@material-ui/core";
import { AddressPicker } from "./AddressPicker";
import { AddressValue } from "../../helper/GooglePlaces/AddressValue.data";

/// <summary>
/// Value is AddressPickerValue | null -- validation needs null
/// </summary>
const RenderAddressPicker = (field: any) => {
  return (
    <div>
      <FormControl fullWidth>
        <AddressPicker
          id={field.name}
          value={
            typeof field.value === "string"
              ? { inputValue: field.value, place: null }
              : field.value
          } // AddressPickerValue
          disabled={field.disabled}
          outlined={field.outlined}
          placeholder={field.placeholder}
          label={field.label}
          handleAddressChanged={(value: AddressValue) => field.onChange(value)}
          onBlur={field.onBlur}
          changeEverykeyPress={!!field.changeEverykeyPress}
          endAdornmentPosition={field.endAdornmentPosition}
          flushOnSelect={!!field.flushOnSelect}
          regionSearch={!!field.regionSearch}
          pincodeAndSuburb={!!field.pincodeAndSuburb}
        />
      </FormControl>
    </div>
  );
};

export default RenderAddressPicker;
