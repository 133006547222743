import { CommentsState } from "../state/CommentsState";
import { CommentAction, CommentActionType } from "../actions/CommentActions";
import { TradesComment } from "../../models/comment/Comment";
import { TradesCommentErrorType } from "../../services/comment/CommentServiceData.data";
import { insertReply } from "../../helper/HelperFunctions";

const initialState: CommentsState = {
  comments: [],
  loading: false,
  error: null,
  postingComment: false,
  postSuccess: false,
};

// tslint:disable-next-line:cyclomatic-complexity
export const commentsReducer = (
  state: CommentsState = initialState,
  action: CommentAction
): CommentsState => {
  const data = action.data;

  switch (action.type) {
    case CommentActionType.GetCommentsSuccess:
      return {
        ...state,
        error: null,
        loading: false,
        comments: data as TradesComment[],
      };

    case CommentActionType.GetCommentsRequest:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CommentActionType.GetCommentsError:
      return {
        ...state,
        loading: false,
        error: data as TradesCommentErrorType,
      };

    case CommentActionType.PostCommentRequest:
      return {
        ...state,
        loading: false,
        postingComment: true,
        error: null,
      };

    case CommentActionType.PostCommentSuccess:
      const { parentId } = data as TradesComment;
      
      return {
        error: null,
        postSuccess: true,
        loading: false,
        postingComment: false,
        comments: parentId
          ? insertReply(state.comments, data as TradesComment, parentId)
          : [...state.comments, data as TradesComment],
      };

    case CommentActionType.PostCommentError:
      return {
        ...state,
        postingComment: false,
        error: data as TradesCommentErrorType,
      };

    case CommentActionType.ResetPostSuccess:
      return {
        ...state,
        postSuccess: false,
      };

    default:
      return state;
  }
};
