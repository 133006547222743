import { Box, Divider, Paper, Typography } from "@material-ui/core";
import { isNull } from "lodash";
import React, { FC, useRef } from "react";
import { NamedFile } from "../../../../models/file/NamedFile";
import { S3File } from "../../../../models/file/S3File";
import AddArtefactsListSection from "../../jobs/addArtefactsModal/artefactsListSection/ArtefactsListSection";
import { submitQuoteAttachmentSectionStyles } from "./AttachmentSectionStyles";
import { LABELS } from "./AttachmentsSectionConstants";

interface AttachmentsSectionProps {
  attachments: NamedFile[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  existingAttachments: S3File[];
  deletedAttachments: string[];
  setExistingAttachments: React.Dispatch<React.SetStateAction<S3File[]>>;
}

export const AttachmentsSection: FC<AttachmentsSectionProps> = ({
  attachments,
  setFieldValue,
  existingAttachments,
  deletedAttachments,
  setExistingAttachments,
}) => {
  const attachmentInputRef = useRef<HTMLInputElement>(null);
  const classes = submitQuoteAttachmentSectionStyles();

  return (
    <Box mt={2}>
      <Paper variant="outlined">
        <Box p={2} pr={0}>
          <Typography className={classes.formHeading}>
            {LABELS.heading}
          </Typography>
          <Divider color="disabled" />
          <Box pr={2}>
            <AddArtefactsListSection
              current={attachments}
              existing={existingAttachments}
              onCurrentRemove={(updatedFileArray: NamedFile[]) =>
                setFieldValue("files", updatedFileArray)
              }
              onExistingRemove={(updatedFileArray: S3File[], id: string) => {
                setExistingAttachments(updatedFileArray);
                setFieldValue("deletedAttachments", [
                  ...deletedAttachments,
                  id,
                ]);
              }}
              inputId={"invoice"}
              onFileChange={(file?: File) => {
                if (file) {
                  setFieldValue("files", [
                    ...attachments,
                    { file, formDataName: file.name },
                  ]);
                }
              }}
            />
          </Box>
        </Box>
      </Paper>

      <input
        accept="image/*,video/*"
        className={classes.input}
        id="job-images"
        multiple
        ref={attachmentInputRef}
        type="file"
        onChange={(e) => {
          const files = e.target.files;
          if (!isNull(files) && files.length) {
            setFieldValue("files", [
              ...attachments,
              ...Array.from(e.target.files!),
            ]);
          }
          e.target.value = "";
        }}
      />
    </Box>
  );
};
