import { Box, BoxProps, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { tagStyles } from "./TagStyles";

interface TagProps {
  color: string;
  text: string;
  fontSize?: string;
  fontWeight?: number;
  minWidth?: string;
  cardTop?: boolean;
  boxProps?: BoxProps;
  style?: any;
}

export const Tag: FC<TagProps> = ({
  color,
  text,
  fontSize,
  fontWeight,
  minWidth,
  cardTop,
  boxProps,
  style,
}) => {
  const classes = tagStyles({ cardTop });
  return (
    <Box
      className={classes.container}
      style={{ background: color, minWidth, ...(style || {}) }}
      {...boxProps}>
      <Typography style={{ fontSize, fontWeight }} variant="body2">
        {text}
      </Typography>
    </Box>
  );
};
