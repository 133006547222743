import {
  GetJobsRequest,
  GetJobsResponse,
  GetJobByIdRequestPayload,
  GetJobByIdResponse,
  GetUsersByWorkorderTradeCategoryRequest,
  ConfirmJobBookingRequestPayload,
  ConfirmJobBookingSuccessPayload,
  ChangeJobStatusRequestPayload,
  AddArtefactsRequest,
  Job,
  AddVariationRequestActionPayload,
  AddArtefactsSuccessPayload,
  JobCostUpdateRequest,
} from "../../services/jobs/JobsData.data";
import { StoreAction } from "../StoreHelper";
import { User } from "../../models/user/User.data";

export enum JobsActionType {
  GetJobsRequest = "GET_JOBS_REQUEST",
  GetJobsSuccess = "GET_JOBS_SUCCESS",
  GetJobsError = "GET_JOBS_ERROR",

  GetJobByIdRequest = "GET_JOB_BY_ID_REQUEST",
  GetJobByIdSuccess = "GET_JOB_BY_ID_SUCCESS",
  GetJobByIdError = "GET_JOB_BY_ID_ERROR",

  GetJobUsersForWorkorderRequest = "GET_JOB_USERS_FOR_WORKORDER_REQUEST",
  GetJobUsersForWorkorderSuccess = "GET_JOB_USERS_FOR_WORKORDER_SUCCESS",
  GetJobUsersForWorkorderError = "GET_JOB_USERS_FOR_WORKORDER_ERROR",

  ConfirmJobBookingRequest = "CONFIRM_JOB_BOOKING_REQUEST",
  ConfirmJobBookingSuccess = "CONFIRM_JOB_BOOKING_SUCCESS",
  ConfirmJobBookingError = "CONFIRM_JOB_BOOKING_ERROR",

  ChangeJobStatusRequest = "CHANGE_JOB_STATUS_REQUEST",
  ChangeJobStatusSuccess = "CHANGE_JOB_STATUS_SUCCESS",
  ChangeJobStatusError = "CHANGE_JOB_STATUS_ERROR",

  AddArtefactsRequest = "ADD_ARTEFACTS_REQUEST",
  AddArtefactsSuccess = "ADD_ARTEFACTS_SUCCESS",
  AddArtefactsError = "ADD_ARTEFACTS_ERROR",

  AddJobVariationRequest = "ADD_JOB_VARIATION_REQUEST",
  AddJobVariationSuccess = "ADD_JOB_VARIATION_SUCCESS",
  AddJobVariationError = "ADD_JOB_VARIATION_ERROR",

  UpdateJobCostRequest = "UPDATE_JOB_COST_REQUEST",
  UpdateJobCostSuccess = "UPDATE_JOB_COST_SUCCESS",
  UpdateJobCostError = "UPDATE_JOB_COST_ERROR",

  ClearViewJobData = "CLEAR_VIEW_JOB_DATA",
}

export type JobsActionPayload =
  | GetJobsRequest
  | GetJobsResponse
  | GetJobByIdRequestPayload
  | GetJobByIdResponse
  | GetUsersByWorkorderTradeCategoryRequest
  | User[]
  | Job
  | ConfirmJobBookingRequestPayload
  | ConfirmJobBookingSuccessPayload
  | ChangeJobStatusRequestPayload
  | AddArtefactsRequest
  | AddArtefactsSuccessPayload
  | AddVariationRequestActionPayload
  | JobCostUpdateRequest
  | Error;

export type JobsAction = StoreAction<JobsActionType, JobsActionPayload>;

export class JobsActions {
  // Get Jobs list
  public static GetJobs(data: GetJobsRequest): JobsAction {
    return { type: JobsActionType.GetJobsRequest, data };
  }

  public static GetJobsSuccess(data: GetJobsResponse): JobsAction {
    return { type: JobsActionType.GetJobsSuccess, data };
  }

  public static GetJobsError(error: Error): JobsAction {
    return {
      type: JobsActionType.GetJobsError,
      data: error,
    };
  }

  // Get Job using Job ID

  public static GetJobUsingId(data: GetJobByIdRequestPayload): JobsAction {
    return {
      type: JobsActionType.GetJobByIdRequest,
      data,
    };
  }

  public static GetJobUsingIdSuccess(data: GetJobByIdResponse): JobsAction {
    return {
      type: JobsActionType.GetJobByIdSuccess,
      data,
    };
  }

  public static GetJobUsingIdError(error: Error): JobsAction {
    return {
      type: JobsActionType.GetJobByIdError,
      data: error,
    };
  }

  // Get Users for work order

  public static GetUsersForWorkorderRequest(
    data: GetUsersByWorkorderTradeCategoryRequest
  ): JobsAction {
    return {
      type: JobsActionType.GetJobUsersForWorkorderRequest,
      data,
    };
  }

  public static GetUsersForWorkorderSuccess(data: User[]): JobsAction {
    return {
      type: JobsActionType.GetJobUsersForWorkorderSuccess,
      data,
    };
  }

  public static GetUsersForWorkorderError(error: Error): JobsAction {
    return {
      type: JobsActionType.GetJobUsersForWorkorderError,
      data: error,
    };
  }

  // Confirm Job
  public static ConfirmJobBookingRequest(
    data: ConfirmJobBookingRequestPayload
  ): JobsAction {
    return {
      type: JobsActionType.ConfirmJobBookingRequest,
      data,
    };
  }

  public static ConfirmJobBookingSuccess(
    data: ConfirmJobBookingSuccessPayload
  ): JobsAction {
    return {
      type: JobsActionType.ConfirmJobBookingSuccess,
      data,
    };
  }

  public static ConfirmJobBookingError(error: Error): JobsAction {
    return {
      type: JobsActionType.ConfirmJobBookingError,
      data: error,
    };
  }

  public static ChangeJobStatusRequest(data: ChangeJobStatusRequestPayload) {
    return {
      type: JobsActionType.ChangeJobStatusRequest,
      data,
    };
  }

  public static ChangeJobStatusSuccess(
    data: ChangeJobStatusRequestPayload
  ): JobsAction {
    return {
      type: JobsActionType.ChangeJobStatusSuccess,
      data,
    };
  }

  public static ChangeJobStatusError(error: Error): JobsAction {
    return {
      type: JobsActionType.ChangeJobStatusError,
      data: error,
    };
  }

  // Add artefacts and finalize job
  public static AddArtefactsRequest(data: AddArtefactsRequest): JobsAction {
    return {
      type: JobsActionType.AddArtefactsRequest,
      data,
    };
  }

  public static AddArtefactsSuccess(
    data: AddArtefactsSuccessPayload
  ): JobsAction {
    return {
      type: JobsActionType.AddArtefactsSuccess,
      data,
    };
  }

  public static AddArtefactsError(error: Error): JobsAction {
    return {
      type: JobsActionType.AddArtefactsError,
      data: error,
    };
  }

  public static AddVariationRequest(
    data: AddVariationRequestActionPayload
  ): JobsAction {
    return {
      type: JobsActionType.AddJobVariationRequest,
      data,
    };
  }

  public static AddVariationError(data: Error): JobsAction {
    return {
      type: JobsActionType.AddJobVariationError,
      data,
    };
  }

  // Update job cost
  public static UpdateJobCostRequest(data: JobCostUpdateRequest) {
    return {
      type: JobsActionType.UpdateJobCostRequest,
      data,
    };
  }

  public static UpdateJobCostSuccess(data: JobCostUpdateRequest) {
    return {
      type: JobsActionType.UpdateJobCostSuccess,
      data,
    };
  }

  public static UpdateJobCostError(error: Error) {
    return {
      type: JobsActionType.UpdateJobCostError,
      data: error,
    };
  }

  public static ClearViewJobData() {
    return {
      type: JobsActionType.ClearViewJobData,
    };
  }
}
