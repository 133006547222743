import moment from "moment";

export enum DocType {
  Tracker = "Tracker",
  NonTracker= "Non-Tracker"
}

export interface AddDocFormData {  
  docName: string,
  tracker: boolean,
  expiryDate: moment.Moment | null
}
