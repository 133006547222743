import React, { FC } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  InputBase,
  makeStyles,
  InputLabel,
  Box,
  SelectProps,
  FormHelperText,
} from "@material-ui/core";
import { color } from "../../theme/Theme";
import { SelectItem } from "../RenderSelect/RenderSelect";
import { ErrorMessage } from "formik";
import { RenderInputFieldStyles } from "./RenderInputFieldStyle";

interface Props extends SelectProps {
  options: SelectItem[];
  onChange: any;
  value: string | number;
  label: string;
  variant?: "filled" | "outlined" | "standard";
  containerClass: string;
}

interface StylesProps {
  filled: boolean;
}

const useStyles = makeStyles({
  menuItemRoot: {
    fontSize: "16px",
    color: color.secondary200,
  },
  label: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: "12px",
  },
  shrink: {
    transform: "translate(0px, -20px) scale(0.75) !important",
  },
  input: {
    border: `1px solid ${color.lightGrey}`,
    borderRadius: "4px",
    fontSize: "16px",
    color: color.secondary200,
    backgroundColor: (props) =>
      (props as StylesProps).filled ? color.wildSand : color.light,
  },
});

const RenderSelect: FC<Props> = ({
  options,
  onChange,
  value,
  label,
  variant,
  containerClass,
  ...props
}) => {
  const classes = useStyles({ filled: variant === "filled" });
  const { errorMessageStyles } = RenderInputFieldStyles();
  return (
    <Box className={containerClass} mt={3}>
      <FormControl fullWidth variant={variant || "outlined"}>
        <>
          {label && (
            <InputLabel classes={{ shrink: classes.shrink }} htmlFor={name}>
              {label}
            </InputLabel>
          )}
          <Select
            name={name}
            value={value}
            onChange={onChange}
            input={<InputBase className={classes.input} />}
            {...props}>
            {options.map((option, index) => (
              <MenuItem
                classes={{ root: classes.menuItemRoot }}
                key={index}
                value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {props.name && (
            <FormHelperText className={errorMessageStyles}>
              <ErrorMessage name={props.name} />
            </FormHelperText>
          )}
        </>
      </FormControl>
    </Box>
  );
};

export default RenderSelect;
