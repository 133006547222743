import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import {UpdateStageRequest, ONBOARDING_STAGE_ROUTE} from "./OnboardingStage.data"
class OnboardingStageService {
  /* istanbul ignore else*/
  private readonly service: ApiServiceBase = new ApiService(ServiceType.OnboardingStage);

  public put(data: UpdateStageRequest): Promise<void> | void {
    const {adminOnBoardingStage} = data;
    const {id} = data;
    return this.service.put({route: [id, ONBOARDING_STAGE_ROUTE]}, JSON.stringify(adminOnBoardingStage));
  }
}

const onboardingStageService = new OnboardingStageService();
export default onboardingStageService;
