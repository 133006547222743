import React, { FC, ChangeEvent, FocusEvent } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import visibilityOff from "../../assets/outline-visibility-off-24-px.svg";
import visibilityOn from "../../assets/outline-remove-red-eye-24-px.svg";
import { ErrorMessage } from "formik";
import {
  RenderInputFieldStyles,
  inputsFields,
  loginLabels,
} from "./RenderInputFieldStyle";

interface Props {
  name?: string;
  disabled?: boolean;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  hidden?: boolean;
  handleShowPassword?: () => void;
  disableUnderline?: boolean;
  showPassword?: boolean;
  inputClassName?: string;
  inputStyle?: any;
  type?: string;
  value?: string | number;
  containerClass?: string;
  noArrowNumberInput?: boolean;
  max?: number;
  min?: number;
  placeholder?: string;
}

/* tslint:disable:cyclomatic-complexity */
const RenderInput: FC<Props> = (props: Props) => {
  const {
    name,
    type,
    disabled,
    onChange,
    onBlur,
    handleShowPassword,
    showPassword,
    label,
    hidden,
    disableUnderline,
    value,
    containerClass,
    noArrowNumberInput,
    max,
    min,
    placeholder,
  } = props;
  const { input, inputNoArrow, labels, errorMessageStyles } =
    RenderInputFieldStyles();

  const inputClass = () =>
    !noArrowNumberInput ? input : `${input} ${inputNoArrow}`;

  return (
    <div className={`item-render ${containerClass || ""}`}>
      <FormControl fullWidth>
        {label && (
          <InputLabel
            style={loginLabels}
            classes={{ shrink: labels }}
            htmlFor={name}>
            {label}
          </InputLabel>
        )}
        {!hidden && (
          <Input
            id={name}
            type={type}
            disabled={disabled}
            classes={{ input: inputClass() }}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            style={inputsFields}
            endAdornment={
              handleShowPassword ? (
                <InputAdornment position="end">
                  <IconButton disableRipple onClick={handleShowPassword}>
                    <img src={showPassword ? visibilityOn : visibilityOff} />
                  </IconButton>
                </InputAdornment>
              ) : null
            }
            inputProps={{
              onKeyPress: (e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              },
              min,
              max,
            }}
            disableUnderline={disableUnderline}
            placeholder={placeholder}
          />
        )}
        {!disabled && name && (
          <FormHelperText className={errorMessageStyles}>
            <ErrorMessage name={name} />
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};
/* tslint:enable */

export default RenderInput;
