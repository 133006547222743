import { List, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { passwordInstructionsStyles } from "./PasswordInstructionsStyles";

const PasswordInstructions: FC<{}> = (props) => {
  const classes = passwordInstructionsStyles();

  return (
    <List
      component={"ul"}
      style={{ listStyleType: "disc" }}
      className={classes.list}
      disablePadding
      dense>
      {[
        "At least 8 characters",
        "One special character",
        "One uppercase character",
      ].map((instruction) => (
        <li className={classes.listItem}>
          <Typography variant="caption">{instruction}</Typography>
        </li>
      ))}
    </List>
  );
};

export default PasswordInstructions;
