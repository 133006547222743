import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../theme/Theme";

export const SkipTextStyles = makeStyles({
  skipText: {
    color: color.teal,
    textDecoration: "underlined",
    cursor: "pointer",
  },
});
