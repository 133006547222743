export const NewBranchFormFieldLabels = {
  branchName: "Office Name",
  branchPhone: "Office Phone",
  branchMail: "Office email",
  branchAddress: "Office Address",
  serviceableRegions: "Type an address, suburb, or postcode",
};

export const NewBranchFormFieldNames = {
  branchName: "name",
  branchPhone: "phone",
  branchMail: "email",
  branchAddress: "branchAddress",
  serviceableRegions: "serviceableRegions",
};
