import { ServiceType } from "../ServiceType.data";
import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceFee } from "src/models/serviceFee/ServiceFee.data";

const serviceType = ServiceType.ServiceFee;
const service: ApiServiceBase = new ApiService(serviceType);

const ServiceFeeService = {
  getServiceFees: (cost: number, agencyId: number, privateName?: string) => {
    return service.get<ServiceFee>({
      query: {
        cost: cost,
        agencyId,
        privateName,
      },
    });
  },
};

export default ServiceFeeService;
