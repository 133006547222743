import React, { FC, useState, useEffect } from "react";
import { CommentResourceType } from "../../models/comment/Comment";
import {
  Paper,
  Typography,
  Divider,
  Box,
  CircularProgress,
} from "@material-ui/core";
import Comment from "./comment/Comment";
import { LABELS } from "./CommentSectionConstants.data";
import { CommentsSectionStyles } from "./CommentsSectionStyles.data";
import {
  TradesCommentErrorType,
  PostCommentRequestPayload,
  CommentSource,
} from "../../services/comment/CommentServiceData.data";
import NewComment from "./newComment/NewComment";
import { NewCommentData } from "../../models/comment/NewCommentData";
import { useAppSelector } from "../../store/RootReducers";
import { CommentActions } from "../../store/actions/CommentActions";
import { useDispatch } from "react-redux";
import { getUserId } from "../../helper/Auth";
import { isNull } from "lodash";

export interface CommentsSectionProps {
  sectionHeading?: string;
  resourceId: string;
  commentResourceType: CommentResourceType;
  subheading?: string;
}

// tslint:disable-next-line: cyclomatic-complexity
const CommentsSection: FC<CommentsSectionProps> = ({
  sectionHeading,
  resourceId,
  commentResourceType,
  subheading,
}) => {
  // State
  const [replyTo, setReplyTo] = useState<string | null>(null);
  const [postingNewComment, setPostingNewComment] = useState<boolean>(false);
  const [postingReply, setPostingReply] = useState<boolean>(false);

  // Other hooks
  const classes = CommentsSectionStyles();
  const { comments, loading, error, postSuccess } = useAppSelector(
    (state) => state.comments
  );
  const dispatch = useDispatch();

  // Dispatcher
  const dispatcher = {
    // Comments
    getComments: (id: string) => {
      dispatch(CommentActions.GetCommentsRequest({ resourceId: id }));
    },

    postComment: (comment: PostCommentRequestPayload) => {
      dispatch(CommentActions.PostCommentRequest(comment));
    },

    resetPostSuccess: () => {
      dispatch(CommentActions.ResetPostSuccess());
    },
  };

  // Effects
  useEffect(() => {
    dispatcher.getComments(resourceId);
  }, [resourceId]);

  useEffect(() => {
    // To be run after clearing/removing comment fields
    if (postSuccess) {
      dispatcher.resetPostSuccess();
      if (!postingNewComment) {
        setReplyTo(null);
        setPostingReply(false);
      } else {
        setPostingNewComment(false);
      }
    }
  }, [postSuccess]);

  // Variables and functions
  const noComments = comments.length === 0;

  const onPost = (commentData: NewCommentData) => {
    const { content, parentId } = commentData;
    const request: PostCommentRequestPayload = {
      commentResourceType,
      content,
      parentId,
      resourceId,
      userId: parseInt(getUserId()!, 10),
      source: CommentSource.TRADES,
    };

    if (isNull(parentId)) {
      setPostingNewComment(true);
    } else {
      setPostingReply(true);
    }
    dispatcher.postComment(request);
  };

  return (
    <Paper className={classes.wrapper} variant="outlined">
      <Typography
        className={classes.sectionHeading}
        style={{ marginBottom: subheading ? "0.5em" : "1em" }}
        variant="subtitle2">
        {`${sectionHeading || "Comments"} ${
          loading ? "" : `(${comments.length})` || 0
        }`}
      </Typography>
      {subheading && (
        <Typography
          style={{ marginBottom: "0.5em" }}
          variant="caption"
          color="textSecondary"
          component={"p"}>
          {subheading}
        </Typography>
      )}
      <Divider />

      {!loading && error !== TradesCommentErrorType.GetError ? (
        <>
          {/* Comments */}
          {comments.map((comment) => {
            const { id } = comment;
            return (
              <Comment
                replyingTo={replyTo}
                replySuccess={postSuccess && postingReply}
                onPostReply={(commentReply: string) =>
                  onPost({ content: commentReply, parentId: replyTo })
                }
                replyError={error === TradesCommentErrorType.PostReplyError}
                key={`comment-${id}`}
                comment={comment}
                postingComment={postingReply}
                setReplyParentId={setReplyTo}
              />
            );
          })}

          {/* New comment */}
          {noComments ? null : <Divider color="secondary" />}
          <Box mt={2}>
            <NewComment
              clearField={postingNewComment && postSuccess}
              postingComment={postingNewComment}
              error={error === TradesCommentErrorType.PostError}
              onPost={(comment: string) =>
                onPost({ content: comment, parentId: null })
              }
            />
          </Box>
        </>
      ) : (
        <Box className={classes.loadingBox}>
          {error ? (
            <Typography color="error" variant="body2">
              {LABELS.errorMessage}
            </Typography>
          ) : (
            <CircularProgress />
          )}
        </Box>
      )}
    </Paper>
  );
};

export default CommentsSection;
