import {
  GetTradeUsersAction,
  GetTradeUsersActionType,
  PostTradeUserActionType,
  PostTradeUserAction,
} from "../actions/TradeUsersActions";
import {
  GetTradeUsersSuccessPayload,
  GetTradeUsersErrorPayload,
  UserResponse,
  PostTradeUserErrorPayload,
} from "../../services/tradeUsers/TradeUsers.data";
import { TradeUsersState } from "../state/TradeUsersState";
import { cloneDeep } from "lodash";

const initialState: TradeUsersState = {
  loading: false,
  error: "",
  tradeUsers: undefined,
};

export const tradeUsersReducer = (
  state = initialState,
  action: GetTradeUsersAction | PostTradeUserAction
) => {
  switch (action.type) {
    case GetTradeUsersActionType.GetTradeUsersRequest: {
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    }

    case GetTradeUsersActionType.GetTradeUsersSuccess: {
      const tradeUsersResponse = action.data as GetTradeUsersSuccessPayload;
      return {
        ...state,
        tradeUsers: tradeUsersResponse,
        loading: false,
        success: true,
        error: null,
      };
    }

    case GetTradeUsersActionType.GetTradeUsersError: {
      const data = action.data as GetTradeUsersErrorPayload;
      return {
        ...state,
        loading: false,
        success: false,
        error: data ? data.error : null,
      };
    }

    case PostTradeUserActionType.PostTradeUserRequest: {
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    }

    case PostTradeUserActionType.PostTradeUserSuccess: {
      const userData = action.data as UserResponse;
      const tradeUsers = cloneDeep(state.tradeUsers);
      if (tradeUsers) {
        tradeUsers!.content.push(userData);
      }
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
        tradeUsers,
      };
    }

    case PostTradeUserActionType.PostTradeUserError: {
      const data = action.data as PostTradeUserErrorPayload;
      return {
        ...state,
        loading: false,
        success: false,
        error: data ? data.error : null,
      };
    }

    default:
      return state;
  }
};
