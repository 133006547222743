import { ElasticQueryState } from "../hooks/UseElasticQuery";
import { ElasticSearchLocationSuggestion } from "../models/elasticQuery/elasticSearchLocationSuggestion";
import {
  ElasticResponse,
  FormattedElasticResponse,
} from "../models/elasticQuery/formattedElasticResponse";
import { ElasticQueryIndex } from "../services/elasticSearch/ElasticSearch";

export const DEFAULT_SIZE = 20;

export type ElasticQueryTypes = ElasticSearchLocationSuggestion;
export type ElasticSearchHighlight = { highlights: { [key: string]: any } };

export const getInitialState = (
  index: ElasticQueryIndex
): ElasticQueryState => {
  switch (index) {
    case ElasticQueryIndex.AustraliaLocations:
      return {
        must: [],
        should: [],
        filter: [],
        size: 10000,
      };

    default:
      return {
        must: [],
        filter: [],
        should: [],
        size: DEFAULT_SIZE,
      };
  }
};

export const convertElasticQueryResponse = (
  response: ElasticResponse<ElasticQueryTypes>
): FormattedElasticResponse<ElasticQueryTypes> => {
  const { hits } = response;

  return {
    total: hits.total.value,
    responses: hits.hits.map((hit) => {
      const { inner_hits, highlight, _source } = hit;
      const innerHitsHighlights = inner_hits
        ? Object.keys(inner_hits).reduce((res: any, k) => {
            if (inner_hits[k].hits.hits.length) {
              res.push(inner_hits[k].hits.hits[0].highlight as any);
            }
            return res;
          }, [])
        : [];
      const highlights = { ...highlight, innerHits: [...innerHitsHighlights] };

      return { ..._source, highlights };
    }),
    // searchedTill:
    //   hits.hits[hits.hits.length - 1] &&
    //   hits.hits[hits.hits.length - 1].sort[0],
    // hard coded for now to prevent error, BE is not sending sort key
    searchedTill: 100,
  };
};
