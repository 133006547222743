const ignoreList = ['auth'];

/** @summary if the API URL constains
 * any word from ignore list, data
 * for that API will not be sent to logRocket
 * and it will not be recorded with logRocket
 */
export const apiSanitizer = (requestUrl: string) => {
  for (const word of ignoreList) {
    if (requestUrl.toLowerCase().includes(word)) {
      return true;
    }
  }
  return false;
};
