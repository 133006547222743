import { routes } from "../../Routes";
import { TopNavTab } from "./TopNav.data";

export enum TopNavTeamLink {
  People = "People",
  // Branches = "Branches",
  Agents = "Agents",
}

export interface TopNavTeamTab extends TopNavTab {
  text: TopNavTeamLink;
}

export const topNavTeamTabs: TopNavTeamTab[] = [
  {
    text: TopNavTeamLink.People,
    route: routes.dashboard.team.people,
  },
  {
    text: TopNavTeamLink.Agents,
    route: routes.dashboard.team.agents,
  },
  // {
  //   text: TopNavTeamLink.Branches,
  //   route: routes.dashboard.team.branches,
  // },
];
