import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../../theme/Theme";

export const DashboardSectionInnerHeadingStyles = makeStyles({
  sectionHeadingContainer: {
    padding: "1em",
    paddingLeft: '0px'
  },
  innerHeading: {
    color: `${color.greyText} !important`,
    textTransform: "uppercase",
    letterSpacing: '2px'
  },
});
