import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { ContentBoxSectionStyles } from "./ContentBoxSectionStyle.data";

interface Props {
  heading?: string;
  noSidePadding?: boolean;
}

type OwnProps = Props;

const ContentBoxSection: FC<OwnProps> = (props) => {
  const classes = ContentBoxSectionStyles();
  const { heading, children, noSidePadding } = props;
  return (
    <>
      {heading && (
        <>
          <Typography className={classes.heading} variant="h3">
            {heading}
          </Typography>
        </>
      )}
      <div
        className={`${classes.contentBoxBody} ${
          noSidePadding ? classes.noSidePadding : ""
        }`}>
        {children}
      </div>
    </>
  );
};

export default ContentBoxSection;
