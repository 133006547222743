import React, { FC } from "react";

import imageGeneric from "../../../../../assets/sorted-logo.jpg";
import {
  Box,
  Divider,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./PrintQuotePdfStyles";

import { Property } from "../../../../../models/property/Property.data";
import { isNull } from "lodash";
import { ListItemBoxProps } from "../SubmitQuoteComponentConstants.data";
import {
  getAddressString,
  getContactDetails,
  getDate,
  getWorkOrderById,
  submitQuoteFormHelpers,
  titleCase,
} from "../../../../../helper/HelperFunctions";
import { LABELS } from "./PrintQuotePdfConstants";
import {
  DATE_FORMAT,
  MOMENT_DATE_FORMATS,
} from "../../../../../constants/AppConstants";
import { avatarColors, color } from "../../../../../theme/Theme";
import { PROPERTY_ACCESS_LABELS } from "../../../../../models/maintenance-request/MaintenanceRequest.data";
import { Contact } from "../../../../contactCard/ContactCard";
import { Quote } from "../../../../../models/quote/Quote.data";
import { QuoteType } from "../../../../../models/quote/QuoteType.data";
import moment from "moment";
import { PDFContactCard } from "./PDFContactCard";

interface PdfComponentProps {
  quote: Quote;
  property: Property;
}

// tslint:disable-next-line:cyclomatic-complexity
const PdfComponent: FC<PdfComponentProps> = ({ quote, property }) => {
  // hooks
  const classes = useStyles();

  // variables
  const {
    id,
    maintenanceRequestResponse: {
      audit: { createdDate, modifiedDate },
      workOrders,
      tenantIds,
      details: {
        title,
        description,
        propertyAccess,
        pet,
        parkingAvailability,
        preferredAvailability,
        clearAccess,
        unSupervisedMinors,
        stairs,
      },
    },
    workOrderId,
    type,
    callOutCost,
    labourCost,
    materialCost,
    specificMaterials,
    remarks,
    lineAmountType,
    availability: { startDate, endDate },
  } = quote;
  const {
    suburb,
    state,
    postcode,
    managingAgent,
    tenants: { allAssignedTenants },
    branch: { name: agencyName },
    propertyUsers,
  } = property;
  const { getGST } = submitQuoteFormHelpers;

  const {
    tenants,
    quoteDueDate: quoteDue,
    alternateContact,
  } = getWorkOrderById(workOrders, workOrderId);

  let total =
    callOutCost +
    materialCost +
    labourCost +
    specificMaterials.map((cost) => cost.charge).reduce((a, b) => a + b, 0);
  const gst = getGST(total, lineAmountType);

  const additionalContactList = getContactDetails(
    tenants || [],
    allAssignedTenants
  );

  const users: Contact[] = propertyUsers
    .filter((u) => tenantIds.includes(u.id))
    .map((u) => ({
      id: u.id,
      name: u.name,
      email: u.email,
      phone: u.phone,
      surname: u.surname,
    }));

  const onSite = type === QuoteType.OnSite;

  const header = () => (
    <Box mb={1}>
      <div className={classes.headerLogo}>
        {<img src={imageGeneric} alt="sorted logo" className={classes.logo} />}
        <h4 className={classes.labelStyle}>Trades Portal</h4>
      </div>
    </Box>
  );

  return (
    <Box p={4}>
      {header()}
      <Paper variant="outlined">
        <Box p={4}>
          {/* Address and job id */}
          <Box mb={2} display="flex" width="100%">
            <Box {...ListItemBoxProps} style={{ border: "none" }}>
              {/* Address details */}
              <Box>
                <Typography
                  variant="h3"
                  className={classes.propertyDetailsHeading}>
                  {LABELS.propertyDetails}
                </Typography>
                <Typography className={classes.propertyJobDetailLabels}>
                  {getAddressString(property)}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="caption">{`${suburb}${
                  suburb && ","
                } ${state} ${postcode}`}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography
                  className={classes.propertyJobDetailLabels}
                  variant="h3">{`${LABELS.quoteId} #${id}`}</Typography>
                <Typography
                  color="textSecondary"
                  variant="caption">{`Last updated: ${getDate(
                  modifiedDate,
                  MOMENT_DATE_FORMATS.DD_MM_YYYY
                )}`}</Typography>
              </Box>
            </Box>
          </Box>

          <Box my={2}>
            <Typography>Agency:</Typography>
            <Typography variant="h4" className={classes.agencyName}>
              {agencyName}
            </Typography>
          </Box>

          {/* Description */}
          <Box mb={4}>
            <Box mb={1} width="100%" display="flex">
              <Box flexGrow="1">
                <Typography variant="h3">{LABELS.description}</Typography>
              </Box>

              {/* Requested date */}
              <Box mr={4} display="flex">
                <Typography className={classes.labelMarginRight} variant="h4">
                  {LABELS.requestedDate}
                </Typography>
                <Typography variant="h4" color="textSecondary">
                  {getDate(createdDate, MOMENT_DATE_FORMATS.DO_MMM_YYYY)}
                </Typography>
              </Box>

              {/* Quote due date */}
              <Box display="flex">
                <Typography className={classes.labelMarginRight} variant="h4">
                  {LABELS.quoteDue}
                </Typography>
                <Typography variant="h4" color="textSecondary">
                  {quoteDue
                    ? getDate(createdDate, MOMENT_DATE_FORMATS.DO_MMM_YYYY)
                    : "N/A"}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box mt={1}>
              <Box mb={1}>
                <Typography variant="h4">{title}</Typography>
              </Box>
              <Typography variant="caption" color="textSecondary">
                {`${description}`}
              </Typography>
            </Box>
          </Box>

          {/* Contacts */}
          <>
            <Box mb={1} flexGrow="1">
              <Typography variant="h3">{LABELS.contacts}</Typography>
            </Box>

            <Divider />

            <Box
              my={1}
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr"
              gridColumnGap="1em"
              gridRowGap="1em">
              {managingAgent && (
                <PDFContactCard
                  avatarColor={avatarColors.blue}
                  designation={"Property manager"}
                  person={managingAgent}
                />
              )}

              {additionalContactList.map((tenant) => (
                <PDFContactCard
                  avatarColor={avatarColors.orange}
                  designation={"Tenant"}
                  person={tenant}
                />
              ))}

              {alternateContact && (
                <PDFContactCard
                  avatarColor={avatarColors.orange}
                  person={{
                    email: alternateContact.email || "N/A",
                    name: alternateContact.name,
                    phone: alternateContact.contact,
                    surname: "",
                  }}
                  designation={"Contact"}
                />
              )}

              {users.map((contact) => (
                <PDFContactCard
                  avatarColor={avatarColors.orange}
                  person={contact}
                  designation={"Customer"}
                />
              ))}
            </Box>
          </>

          {/* Additional details and PM/Tenant details */}
          <div
            style={{
              pageBreakBefore:
                [
                  ...additionalContactList,
                  ...users,
                  ...(alternateContact ? [alternateContact] : []),
                  ...(managingAgent ? [managingAgent] : []),
                ].length > 3
                  ? "always"
                  : undefined,
            }}>
            <Box mt={2} display="flex" justifyContent="space-between">
              {/* Left list */}
              <Box flexBasis="48%">
                <Box bgcolor={color.wildSand}>
                  <ListItem divider key={"heading"}>
                    <ListItemText
                      primaryTypographyProps={{ variant: "h4" }}
                      primary={LABELS.additionalDetails}
                    />
                  </ListItem>
                  {[
                    LABELS.propertyAccess,
                    LABELS.preferredTime,
                    LABELS.preferredDays,
                    LABELS.noUnaccompaniedMinors,
                    LABELS.pet,
                    LABELS.animalDescription,
                    LABELS.clearAccess,
                    LABELS.stairsAccess,
                    LABELS.parkingOnSite,
                  ].map((label, index) => (
                    <ListItem divider key={`${index}-label`}>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "caption",
                          classes: { root: classes.additionalDetailKey },
                        }}
                        primary={label}
                      />
                    </ListItem>
                  ))}
                </Box>
              </Box>

              {/* Right list */}
              <Box flexBasis="48%">
                <ListItem divider key={"blank"}>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "h4",
                      style: { color: color.light },
                    }}
                    primary="magic"
                  />
                </ListItem>

                {[
                  propertyAccess
                    ? PROPERTY_ACCESS_LABELS[propertyAccess.propertyAccessType]
                    : LABELS.unconfirmed,
                  preferredAvailability
                    ? `${preferredAvailability.timeSlot}${
                        preferredAvailability.otherTime
                          ? `(${preferredAvailability.otherTime})`
                          : ""
                      }`
                    : LABELS.unconfirmed,
                  preferredAvailability
                    ? preferredAvailability.days
                        .map((day) => titleCase(day).substring(0, 3))
                        .join(", ") || LABELS.unconfirmed
                    : LABELS.unconfirmed,
                  unSupervisedMinors ? "Confirmed" : "Not confirmed",
                  pet ? (pet.present ? "Yes" : "No") : LABELS.unconfirmed,
                  pet
                    ? pet.present
                      ? pet.description
                      : "N/A"
                    : LABELS.unconfirmed,
                  !isNull(clearAccess)
                    ? clearAccess
                      ? "Yes"
                      : "No"
                    : LABELS.unconfirmed,
                  !isNull(stairs)
                    ? stairs.present
                      ? "Yes"
                      : "No"
                    : LABELS.unconfirmed,
                  !isNull(parkingAvailability)
                    ? parkingAvailability
                      ? "Yes"
                      : "No"
                    : LABELS.unconfirmed,
                ].map((value, index) => (
                  <ListItem divider key={`${index}-value`}>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "caption",
                        color: "textSecondary",
                      }}
                      primary={value}
                    />
                  </ListItem>
                ))}
              </Box>
            </Box>
          </div>
        </Box>
      </Paper>

      <Box mt={4}>
        <Paper variant="outlined">
          <Box p={4}>
            <Box display="flex" justifyContent="space-between">
              {/* Left list */}
              <Box flexBasis="48%">
                <Box bgcolor={color.wildSand}>
                  <ListItem divider key={"heading"}>
                    <ListItemText
                      primaryTypographyProps={{ variant: "h4" }}
                      primary={LABELS.quoteDetails}
                    />
                  </ListItem>
                  {[
                    LABELS.onSite,
                    LABELS.startDate,
                    LABELS.completionDate,
                    ...(onSite
                      ? [LABELS.callOutCost, LABELS.gst, LABELS.totalCost]
                      : [
                          LABELS.labourCost,
                          LABELS.includedMaterialsCost,
                          LABELS.callOutCost,
                          LABELS.gst,
                          LABELS.totalCost,
                          ...(specificMaterials?.length > 0
                            ? [LABELS.materials]
                            : []),
                        ]),
                  ].map((label, index) => (
                    <ListItem
                      divider={label !== LABELS.materials}
                      key={`${index}-label`}>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "caption",
                          classes: { root: classes.additionalDetailKey },
                        }}
                        primary={label}
                      />
                    </ListItem>
                  ))}
                  {specificMaterials.map(({ description }, index) =>
                    index === 0 ? null : (
                      <ListItem>
                        <ListItemText
                          primaryTypographyProps={{
                            variant: "caption",
                            classes: { root: classes.additionalDetailKey },
                            style: { color: color.wildSand },
                          }}
                          primary={description}
                        />
                      </ListItem>
                    )
                  )}
                </Box>
              </Box>

              <Box flexBasis="48%">
                <Box>
                  <ListItem divider key={"blank"}>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "h4",
                        style: { color: color.light },
                      }}
                      primary="magic"
                    />
                  </ListItem>
                  {[
                    onSite ? "Yes" : "No",
                    startDate
                      ? moment(startDate).format(DATE_FORMAT)
                      : LABELS.unconfirmed,
                    endDate
                      ? moment(endDate).format(DATE_FORMAT)
                      : LABELS.unconfirmed,
                    ...(onSite
                      ? [`$${callOutCost}`]
                      : [
                          `$${labourCost}`,
                          `$${callOutCost}`,
                          `$${materialCost}`,
                        ]),
                    `$${gst}`,
                    `$${total}`,
                  ].map((value, index) => (
                    <ListItem divider key={`${index}-value`}>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "caption",
                          color: "textSecondary",
                        }}
                        primary={value}
                      />
                    </ListItem>
                  ))}
                  {specificMaterials.map(
                    ({ includedMaterial, description }) => (
                      <ListItem>
                        <ListItemText
                          primaryTypographyProps={{
                            variant: "caption",
                            color: "textSecondary",
                          }}
                          primary={`${description}${
                            includedMaterial && " (included)"
                          }`}
                        />
                      </ListItem>
                    )
                  )}
                </Box>
              </Box>
            </Box>

            {/* Scope of work */}
            {remarks && (
              <Box mt={4}>
                <Box mb={1} flexGrow="1">
                  <Typography variant="h3">{LABELS.scopeOfWork}</Typography>
                </Box>
                <Divider />
                <Box mt={1}>
                  <Typography variant="caption">{remarks}</Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default PdfComponent;
