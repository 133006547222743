import * as Yup from "yup";
import { ChangePasswordFormFields } from "./ChangePasswordFormData.data";
import { passwordRex } from "../../../../../helper/Validation";

export const LABELS = {
  password: "Password",
  dummyPassword: "********",
  update: "Update",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  saveNewPassword: "Save new password",
};

export const initialFormData: ChangePasswordFormFields = {
  newPassword: "",
  confirmPassword: "",
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  newPassword: Yup.string()
    .required("This field is required")
    .matches(passwordRex, "Password doesn't fulfil the requirements"),
  confirmPassword: Yup.string()
    .required("This field is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});