import * as React from "react";
import * as loginBackground from "../../assets/images/img-bg-loginblur.jpg";
import { withStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { routes } from "../../Routes";
import localStorage from "../../services/LocalStorage";

export interface PageNotFoundStyles {
    readonly glassPanel: any;
    readonly linkStyle: any;
    readonly imageContainer: any;
}

interface PageNotFoundProps {
    readonly title?: string;
    readonly message?: string;
    readonly loginLink?: boolean;
}

const styles: PageNotFoundStyles = {
    imageContainer: {
        backgroundImage: `url(${loginBackground})`,
        width: "100%",
        height: "100vh",
        backgroundSize: "cover",
        position: "relative",
        backgroundPosition: "center center",
        color: "#fff",
    },
    glassPanel: {
        position: "absolute",
        height: "100%",
        width: "500px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "0 2em 0 4em",
        color: "#fff",
    },
    linkStyle: {
        textDecoration: "none",
        '&:hover': {
            textDecoration: "underline",
            color: 'blue'
        }
    }
}

interface StylesProps {
    readonly classes: PageNotFoundStyles
}

export type Props = StylesProps & PageNotFoundProps;

export class PageNotFoundPage extends React.Component<Props, {}> {
    public render() {
        const { classes, title, message, loginLink } = this.props;
        return (
            <div className={classes.imageContainer}>
                <div className={classes.glassPanel}>
                    <h1>
                        {title}
                    </h1>
                    <br />
                    <h2>
                        {message}
                    </h2>
                    <br />
                    {loginLink ?
                        <Typography id="message" variant="body1">
                            <Link to={routes.login.view(localStorage.getItem("agencyCode")!)} className={classes.linkStyle}>
                                Go to Login
                            </Link>
                        </Typography>
                        : ''
                    }
                </div>
            </div>
        );
    }
}

export default (withStyles(styles)(PageNotFoundPage) as any);