import * as loginBackground from "../../assets/images/img-bg-loginblur.jpg";
import { pxToem } from "../../helper/Validation";
export const LABEL_ANIMATION: string = `translate(${-10}px, ${1.5}px) scale(${0.75})`;
import { color } from "../../theme/Theme";
import { makeStyles } from "@material-ui/core/styles";
import { SIZES } from "../../constants/AppConstants";

export const LoginComponentStyle = makeStyles({
  loginComponent: {
    width: "100%",
    height: "100vh",
    position: "relative",
    fontFamily: "GT Walsheim",
  },
  glassPanel: {
    position: "relative",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  imageContainer: {
    backgroundImage: `url(${loginBackground})`,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "center center",
  },
  loginLink: {
    fontSize: "1em",
    fontWeight: 500,
    color: color.primary,
  },
  headerLogo: {
    paddingBottom: pxToem(60),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logo: {
    maxWidth: 90,
    paddingRight: "20px",
    marginRight: "20px",
    borderRight: `2px solid ${color.primary}`,
  },
  validationText: {
    marginTop: "-20px",
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    padding: "35px 15%",
    boxShadow:
      "0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "white",
    height: "100%",
    "@media all and (max-width: 1023px)": {
      overflowX: "auto",
    },
    "@media all and  (max-width: 1280px)": {
      padding: "35px 10%",
    },
  },
  loginBoxStyleOne: {
    margin: pxToem(32),
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 0,
    backgroundColor: "transparent",
  },
  titleStyle: {
    paddingBottom: "2em",
  },
  inputType: {
    height: "34px",
    color: "#2D1B49",
  },
  buttonContainer: {
    marginTop: "3em",
    textAlign: "center",
  },
  bottomLink: {
    margin: 0,
    paddingTop: "24px",
    fontWeight: 300,
    textAlign: "center",
    fontColor: "black",
    marginLeft: pxToem(10),
  },
  labelStyle: {
    color: color.primary,
    margin: 0,
    fontWeight: 500,
  },
  loginText: {
    fontSize: "30px",
    fontWeight: "bold",
    paddingTop: 30,
  },
  loginSubText: {
    fontWeight: 400,
  },
  cardPadding: {
    padding: "30",
    "@media (min-width:768px) and (max-width: 1280px)": {
      padding: "70 20 50 20",
    },
  },
  LoginPadding: {
    paddingTop: 10,
    "@media (min-width: 768px) and (max-width: 1280px)": {
      paddingBottom: 30,
    },
  },
  mdErrorMessage: {
    "@media (min-width: 768px) and (max-width: 1280px)": {
      fontSize: 20,
    },
  },
  loginButton: {
    padding: "16px 0",
    "margin-top": "15px",
    borderRadius: "0px",
  },
  loginButtonActive: {
    backgroundColor: color.teal,
    color: color.light,
    textAlign: "center",
    "&:hover": {
      backgroundColor: color.teal,
    },
  },
  loginButtonPadding: {
    "@media (min-width:768px) and (max-width: 1280px)": {
      paddingTop: 50,
    },
  },
  paragraphContent: {
    fontSize: "14px",
    fontWeight: 300,
    fontStretch: "normal",
    letterSpacing: "0",
    lineHeight: "1.36",
    color: "#4a4a4a",
    paddingTop: "12px",
    paddingBottom: "30px",
  },
  backToLoginDiv: {
    textAlign: "center",
    color: "#2d1b49",
    padding: "30px 0 5px",
  },
  backToLoginLink: {
    "text-decoration": "none",
  },
  heading2: {
    fontSize: "22.7px",
    fontWeight: 500,
    fontStretch: "normal",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  errorText: {
    color: "#FF525B",
    textAlign: "center",
    marginTop: "10px",
  },
  fullHeight: {
    height: "100%",
    overflow: "auto",
  },
  fieldsContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  forgotPasswordLink: {
    textAlign: "right",
  },
  inputContainerStyle: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingBottom: "10px",
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      paddingBottom: "8px",
    },
  },
  inputLabelStyle: {
    paddingLeft: "5px",
    width: "95%",
    color: color.incompleteBlack,
    fontSize: "1em",
  },
  inputStyle: {
    paddingLeft: "5px",
    width: "95%",
    marginTop: "0 !important",
  },
});

export const heading2 = {
  fontSize: "22.7px",
  fontWeight: "500",
  fontStretch: "normal",
  letterSpacing: "0.25px",
  color: color.secondary200,
};
