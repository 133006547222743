import * as Yup from "yup";
import {
  EMAIL_PATTERN,
  CONTACT_NUMBER_PATTERN,
} from "../../../../constants/AppConstants";
import { phoneCheck } from "../../../../helper/Validation";

export const LABELS = {
  heading: "Account",
  depotProfile: "Office Profile",
  tradeProfile: "Trade Profile",
  serviceableRegions: "Serviceable Areas",
  addServiceableRegion: "Add Serviceable Area",
  errors: {
    uniqueAddress: "An address with given postal code already exists",
    noPostcode:
      "We can't fetch the postcode for given location, please try more precise location",
  },
  addPostCodes:
    "Add postcodes within a specified radius (in kms) from your branch address",
  inidiviudalPostcode:
    "Add serviceable regions via individual postcode or suburb search",
};

export const BRANCH_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().trim().required("Office Name is required").max(50),
  email: Yup.string()
    .required("Email address is required")
    .matches(EMAIL_PATTERN, "Invalid email format"),
  phone: Yup.string()
    .required("Phone number is required")
    .test("phone-validation", "Invalid phone number", function (value) {
      return phoneCheck(value);
    }),
});

export const NEW_BRANCH_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().trim().required("Branch Name is required").max(50),
  email: Yup.string()
    .required("Email address is required")
    .matches(EMAIL_PATTERN, "Invalid email format"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(CONTACT_NUMBER_PATTERN, "Invalid phone number"),
});
