import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import {
  cardHolderNameMaxLength,
  cardHolderNameRegex,
  cardNumberLength,
  cardNumberPattern,
  CardType,
  cardTypeNotSupportedError,
  CardValues,
  CreditCardRequest,
  expiryDateError,
  expiryDateLength,
  expiryDatePattern,
  initialCardValues,
  invalidCardError,
  maskCardDetails,
  MaskDetails,
  nameFormatError,
  nameMaxLengthError,
  renderCardIcon,
  requiredFieldError,
} from "../AddPaymentMethodConstants.data";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  Typography,
} from "@material-ui/core";
import CustomInput from "../../customInput/CustomInput";
import secureIcon from "../../../assets/images/payment/lock-24-px.svg";
import value from "card-validator";
import { dateValidator } from "../../../helper/DateValidator";
import { formatCvvNumber } from "../../../helper/CardHelper";
import { TodayOutlined, ErrorOutline } from "@material-ui/icons";
import { Dispatch } from "redux";
import { ApplicationState } from "../../../store/RootReducers";
import { connect } from "react-redux";
import {
  PaymentActions,
  PaymentErrorPayload,
  SaveSelectedPaymentMethodPayload,
} from "../../../store/actions/PaymentActions";
import { LoadingIndicatorActions } from "../../../store/actions/LoadingIndicatorAction";
import { getTradeId } from "../../../helper/Auth";
import { addCardStyles } from "./AddCreditCardStyles.data";
import { labels as LABELS } from "../AddPaymentMethodConstants.data";
import { links } from "../../../constants/AppConstants";

interface AddCreditCardProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  onBack: () => void;
  onSubmit?: () => void;
}

const AddCreditCard: FC<AddCreditCardProps> = ({
  paymentMethodId,
  paymentErrorState,
  onBack,
  getPaymentAuthKey,
  saveSelectedPaymentMethod,
  onSubmit,
}) => {
  const cardExpiryValidator = CustomInput(expiryDatePattern);
  const cardNumberValidator = CustomInput(cardNumberPattern);
  const [maskedCardNumber, setMaskedCardNumber] = useState<MaskDetails>({
    paymentDetails: "",
    logo: "",
  });
  const [cardType, setCardType] = useState<string>("");
  const [cvvLength, setCvvLength] = useState<number>(3);
  const [cardError, setCardError] = useState<boolean>(false);
  const [cardErrorValue, setCardErrorValue] = useState<string>("");
  const [expiryError, setExpiryError] = useState<boolean>(false);
  const [cardTypeValidation, setCardTypeValidation] = useState<boolean>(true);
  const [isTncAccepted, setIsTncAccepted] = useState<boolean>(false);

  const tradeId = getTradeId()!;

  const classes = addCardStyles();

  useEffect(() => {
    saveSelectedPaymentMethod({
      paymentMethodId,
      maskedDetails: maskedCardNumber,
    });
  }, [paymentMethodId]);

  const handleSubmit = (paymentDetails: CardValues) => {
    const expiryDate = paymentDetails.expiryDate.split("/");
    const paymentRequest: CreditCardRequest = {
      type: "CREDITCARD",
      cardHolderName: paymentDetails.cardHolderName,
      cardNumber: paymentDetails.cardNumber,
      email: "",
      expiryMonth: expiryDate[0],
      expiryYear: expiryDate[1],
    };
    getPaymentAuthKey(paymentRequest, parseInt(tradeId));
  };

  const handleCardNumber = (cardValue: string) => {
    if (cardValue.length < 4) {
      setCardType("");
      setCardTypeValidation(true);
    } else if (cardType === "" && cardValue.length >= 4) {
      const { card } = value.number(cardValue);
      const type = card && (card as any).type;
      const cvvLength = card && card.code.size;
      const cardTypeValidation =
        type === CardType.Visa || type === CardType.MasterCard;
      setCardType(type);
      setCvvLength(cvvLength as number);
      setCardTypeValidation(cardTypeValidation);
    }
    const valid = value.number(cardValue.replace(/\s+/g, "")).isValid;
    switch (true) {
      case cardValue.length === cardNumberLength && !valid:
        setCardError(true);
        setCardErrorValue(invalidCardError);
        break;
      case !cardTypeValidation:
        setCardError(true);
        setCardErrorValue(
          `${cardType ? cardType : ""} ${cardTypeNotSupportedError}`
        );
        break;
      case cardError && cardTypeValidation:
        setCardError(false);
        setCardErrorValue("");
        break;
    }
  };

  const saveNewCard = (data: CardValues) => {
    handleSubmit(data);
    const maskedCardNumber = maskCardDetails(
      data.cardNumber.slice(-4),
      cardType
    );
    setMaskedCardNumber(maskedCardNumber);
    saveSelectedPaymentMethod({
      paymentMethodId: "1",
      maskedDetails: maskedCardNumber,
    });
    onSubmit && onSubmit();
  };

  const expiryDateValidation = (date: string) => {
    if (date.length === expiryDateLength) {
      setExpiryError(dateValidator(date, "paymentCard"));
    } else if (expiryError) {
      setExpiryError(false);
    }
  };

  return (
    <Box className={classes.borderedBox}>
      <Formik
        initialValues={initialCardValues}
        onSubmit={(values) => saveNewCard(values)}
        validationSchema={Yup.object().shape({
          cardHolderName: Yup.string()
            .max(cardHolderNameMaxLength, nameMaxLengthError)
            .matches(cardHolderNameRegex, nameFormatError)
            .required(requiredFieldError),
          cardNumber: Yup.string().min(cardNumberLength).required(),
          expiryDate: Yup.string().min(expiryDateLength).required(),
          cvv: Yup.string().min(cvvLength).required(),
        })}>
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          isValid,
        }) => (
          <>
            <Box mt={1}>
              <Typography>{LABELS.addCreditCard}</Typography>
            </Box>
            <div className={classes.inputBoxStyle}>
              <FormControl
                className={classes.cardNumberInputStyle}
                error={!!errors.cardHolderName}>
                <InputLabel>{LABELS.cardHolderName}</InputLabel>
                <Input
                  name="cardHolderName"
                  onChange={(event) =>
                    setFieldValue("cardHolderName", event.target.value)
                  }
                  value={values.cardHolderName}
                />
                {!!errors.cardHolderName && (
                  <FormHelperText>{errors.cardHolderName}</FormHelperText>
                )}
              </FormControl>
            </div>
            <div className={classes.inputBoxStyle}>
              <FormControl
                className={classes.cardNumberInputStyle}
                error={cardError}>
                <InputLabel>{LABELS.cardNumber}</InputLabel>
                <Input
                  name="cardNumber"
                  type="text"
                  inputComponent={cardNumberValidator as any}
                  onChange={(event) => {
                    setFieldValue("cardNumber", event.target.value);
                    handleCardNumber(event.target.value);
                  }}
                  value={values.cardNumber}
                  endAdornment={
                    <InputAdornment position="end">
                      {cardError ? (
                        <ErrorOutline />
                      ) : (
                        <img src={renderCardIcon(cardType)} />
                      )}
                    </InputAdornment>
                  }
                />
                {cardErrorValue && (
                  <FormHelperText>{cardErrorValue}</FormHelperText>
                )}
              </FormControl>
            </div>
            <div className={classes.inputBoxStyle}>
              <FormControl
                className={classes.expiryInputStyle}
                error={expiryError}>
                <InputLabel>{LABELS.expiry}</InputLabel>
                <Input
                  name="expiryDate"
                  type="text"
                  placeholder="MM/YY"
                  inputComponent={cardExpiryValidator as any}
                  onChange={(event) => {
                    expiryDateValidation(event.target.value);
                    setFieldValue("expiryDate", event.target.value);
                  }}
                  value={values.expiryDate}
                  endAdornment={
                    <InputAdornment position="end">
                      <TodayOutlined />
                    </InputAdornment>
                  }
                />
                {expiryError && (
                  <FormHelperText>{expiryDateError}</FormHelperText>
                )}
              </FormControl>
              <FormControl className={classes.cvvInputStyle}>
                <InputLabel>{LABELS.cvv}</InputLabel>
                <Input
                  name="cvv"
                  type="password"
                  onChange={(event) =>
                    setFieldValue("cvv", formatCvvNumber(event.target.value))
                  }
                  inputProps={{
                    maxLength: 3,
                  }}
                  value={values.cvv}
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={secureIcon} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>

            {/* TnC */}
            <Box mt={2}>
              <Typography>{LABELS.termsAndConditions}</Typography>
              <Box className={classes.borderedBox}>
                <Typography variant="body2">
                  {LABELS.tncLine1}
                  <br />
                  {LABELS.tncLine2}
                  <br />
                  {LABELS.tncLine3}
                  <br />
                  <br />
                  <a
                    style={{ fontWeight: "bold" }}
                    target="_blank"
                    href={links.creditCardAgreement}>
                    {LABELS.tncLinkLabelCard}
                  </a>
                </Typography>
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={isTncAccepted}
                    checked={isTncAccepted}
                    onChange={(e) => setIsTncAccepted(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <Typography className={classes.termsLabel}>
                    {LABELS.termsLabel}
                  </Typography>
                }
              />
            </Box>

            {paymentErrorState && (
              <Snackbar message={`${paymentErrorState.error}, try again`} />
            )}
            <div className={classes.secureBoxStyle}>
              <img src={secureIcon} className={classes.secureLockStyle} />
              <Typography variant="subtitle1">
                {LABELS.secureConnection}
              </Typography>
            </div>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.mainContainer}>
              <Button
                fullWidth
                className={classes.secondaryButton}
                onClick={onBack}>
                {LABELS.back}
              </Button>
              <Button
                fullWidth
                variant="contained"
                className={classes.primaryButton}
                disabled={!isTncAccepted}
                onClick={() => {
                  if (!(cardError || expiryError || !isValid)) {
                    handleSubmit();
                  }
                }}>
                {LABELS.save}
              </Button>
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  paymentMethodId: state.payment.selectedPaymentMethod.paymentMethodId,
  paymentErrorState: state.payment.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveSelectedPaymentMethod: (data: SaveSelectedPaymentMethodPayload) => {
    dispatch(PaymentActions.saveSelectedPaymentMethod(data));
  },
  getPaymentAuthKey: (data: CreditCardRequest, tradeId: number) => {
    dispatch(
      PaymentActions.getPaymentAuthKeyStart({
        data,
        onSuccess: (token: string) => {
          dispatch(
            PaymentActions.postPaymentTokenStart({
              data: { resultKey: token, tradeId: tradeId },
              onSuccess: () => {
                dispatch(PaymentActions.getPaymentMethodsStart({ tradeId }));
                dispatch(LoadingIndicatorActions.hide());
              },
            })
          );
        },
        onError: (error: PaymentErrorPayload) => {
          dispatch(PaymentActions.getPaymentAuthKeyError(error));
          dispatch(LoadingIndicatorActions.hide());
        },
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditCard);
