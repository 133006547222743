import {
  PostCommentRequestPayload,
  GetCommentsRequestPayload,
  TradesCommentErrorType,
} from "../../services/comment/CommentServiceData.data";
import { TradesComment } from "../../models/comment/Comment";
import { StoreAction } from "../StoreHelper";

export enum CommentActionType {
  GetCommentsRequest = "GET_COMMENTS_REQUEST",
  GetCommentsSuccess = "GET_COMMENTS_SUCCESS",
  GetCommentsError = "GET_COMMEMTS_ERROR",

  PostCommentRequest = "POST_COMMENT_REQUEST",
  PostCommentSuccess = "POST_COMMENT_SUCCESS",
  PostCommentError = "POST_COMMENT_ERROR",

  ResetPostSuccess = "RESET_POST_SUCCESS",
}

export type CommentsActionPayload =
  | PostCommentRequestPayload
  | GetCommentsRequestPayload
  | TradesComment
  | TradesCommentErrorType
  | TradesComment[]
  | TradesComment;

export type CommentAction = StoreAction<
  CommentActionType,
  CommentsActionPayload
>;

export class CommentActions {
  // Get comments actions
  public static GetCommentsRequest(data: GetCommentsRequestPayload) {
    return {
      type: CommentActionType.GetCommentsRequest,
      data,
    };
  }

  public static GetCommentsSuccess(data: TradesComment) {
    return {
      type: CommentActionType.GetCommentsSuccess,
      data,
    };
  }

  public static GetCommentsError(error: TradesCommentErrorType) {
    return {
      type: CommentActionType.GetCommentsError,
      data: error,
    };
  }

  // Post comment actions
  public static PostCommentRequest(data: PostCommentRequestPayload) {
    return {
      type: CommentActionType.PostCommentRequest,
      data,
    };
  }

  public static PostCommentSuccess(data: TradesComment) {
    return {
      type: CommentActionType.PostCommentSuccess,
      data,
    };
  }

  public static PostCommentError(error: TradesCommentErrorType) {
    return {
      type: CommentActionType.PostCommentError,
      data: error,
    };
  }

  // Reset postSuccess action
  public static ResetPostSuccess() {
    return {
      type: CommentActionType.ResetPostSuccess,
    };
  }
}
