import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import ApiServiceBase from "../ApiServiceBase";
import {
  GetTradeAgenciesRequest,
  GetTradeUsersRequestPayload,
  GetTradeUsersResponse,
  PatchTermsResponseRequest,
} from "./TradeServiceData.data";
import { getTradeId } from "../../helper/Auth";

const tradesServiceType = ServiceType.Trades;
const termsBffServiceType = ServiceType.TermsBff;
const termsServiceType = ServiceType.Terms;

class TradeService {
  private readonly tradesService: ApiServiceBase = new ApiService(
    tradesServiceType
  );

  private readonly termsBffService: ApiServiceBase = new ApiService(
    termsBffServiceType
  );

  private readonly termsService: ApiServiceBase = new ApiService(
    termsServiceType
  );

  public getUsers(
    request: GetTradeUsersRequestPayload
  ): Promise<GetTradeUsersResponse> | GetTradeUsersResponse {
    return this.tradesService.get({ route: [request.tradeId, "users"] });
  }

  public getTradeAgencies(data: GetTradeAgenciesRequest) {
    const {pageNumber, agentFilters} = data;    
    return this.termsBffService.get({ route: [getTradeId()!], query: {
      page: pageNumber.toString(),
      agentFilter: agentFilters,
      size: 9
    } });
  }

  public patchTermsAcceptance(payload: PatchTermsResponseRequest) {
    const { termId, accepted } = payload;
    return this.termsService.patch(
      { route: [termId.toString()] },
      JSON.stringify(accepted)
    );
  }
}

const tradeService = new TradeService();
export default tradeService;
