import { Action } from "redux";
import { Forms } from "../helper/Forms";
import { TypeHelper } from "../helper/TypeHelper";
import { change, initialize, touch } from "redux-form";
import { ApplicationState } from "./RootReducers";
import { store } from "./Store";

export interface StoreAction<TType extends string, TPayload>
  extends Action<TType> {
  readonly type: TType;
  readonly data: TPayload;
}

// tslint:disable
/// <summary>
/// Marker interface for redux-form state classes
/// </summary>
export interface ReduxFormState {}

/// <summary>
/// Marker interface for page state classes
/// </summary>
export interface ReducerState {
  readonly loading?: boolean;
  readonly success: boolean;
  readonly error?: any | Error | undefined | null;
}
export class StoreHelper {
  public static changeFormState<T extends ReduxFormState>(
    form: Forms,
    state: Partial<T>
  ) {
    const dispatch = store.dispatch;
    for (const key of Object.keys(state)) {
      if (key) {
        dispatch(
          change(
            form,
            key,
            TypeHelper.eliminateNullOrUndefined(state[key], null)
          )
        );
      }
    }
  }

  public static clearStoreOnLogout() {}

  public static clearStore() {}

  /// <summary>
  /// We use this plus settings props.initialValues().
  /// (props.initialValues should be enough for the app, but without this, the tests behave strange when run in a batch - not paralellism, something else with jest + redux-form.)
  /// </summary>
  public static initializeFormState<T extends ReduxFormState>(
    form: Forms,
    state: T
  ) {
    store.dispatch(initialize(form, state, false));
  }

  /// <summary>
  /// Sets form back to pristine
  /// </summary>
  public static resetPristine<T extends ReduxFormState>(
    form: Forms,
    resetValues: Partial<T>
  ) {
    StoreHelper.initializeFormState(
      form,
      StoreHelper.getFormState(form, resetValues)
    );
  }

  public static getFormState<T extends ReduxFormState>(
    form: Forms,
    defaultValue: T
  ): T {
    const forms = store.getState().form;

    if (forms) {
      const formState = forms[form];
      return formState && formState.values
        ? (formState.values as T)
        : defaultValue;
    } else {
      return defaultValue;
    }
  }

  public static touch(form: Forms, ...fields: string[]) {
    store.dispatch(touch(form, ...fields));
  }

  public static getState(): ApplicationState {
    return store.getState() as ApplicationState;
  }
}
