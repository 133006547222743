import { color } from "../../../../theme/Theme";

export const LABELS = {
  requestedDate: "Requested Date",
  workorderId: "Workorder ID",
  jobDue: "Job Due",
  quoteDue: "Quote Due",
  enterAmount: "Enter an amount",

  // Quote Details
  quoteDetails: "Quote Details",
  costOfLabour: "Cost of labour:",
  callOut: "Call out:",
  includedMaterials: "Included materials:",

  // Specific material
  specificMaterial: "Materials",
  enterItemName: "Enter an item name",

  // Sub Total
  subTotal: "Sub Total:",
  processingFee: "Processing fee:",
  estimateTotal: "Estimated Total",

  note: "Once submitted it will be passed on to the agent for consideration.",
  submitQuote: "Submit quote",
  addressOnApproval: "Address on quote approval",
  quote: "Quote",
  updateQuote: "Update quote",
  maintenanceRequestImages: "Maintenance request images",
  bookingImages: "Booking images",
};

export const SUBMIT_QUOTE_FORM_FIELDS = {
  callOutCost: "callOutCost",
  jobMinCost: "jobMinCost",
  labourCost: "labourCost",
  miscellaneousCost: "miscellaneousCost",
  workOrderId: "workOrderId",
  officeId: "officeId",
  materialCost: "materialCost",
  remarks: "remarks",
  jobMaxCost: "jobMaxCost",
  status: "status",
};

const BORDER = `1px solid ${color.lightGrey}`;

export const ListItemBoxProps = {
  alignItems: "baseline",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: BORDER,
};
