import { makeStyles } from "@material-ui/core";
import { color } from "../../../src/theme/Theme";

export const addPaymentMethodStyles = makeStyles({
  menuItem: {
    padding: "1rem",
    border: `2px solid ${color.lightGrey}`,
    borderRadius: 8,
    margin: "0.5rem 0",
  },
  selectedIcon: {
    color: color.completeBlue,
  },
  menuText: {
    display: "flex",
    flexGrow: 1,
    marginRight: "0.5rem",
    fontSize: 14,
  },
  mainContainer: {
    marginTop: "1rem",
  },
  primaryButton: {
    padding: "1.1rem",
    marginLeft: "0.25rem",
    borderRadius: 8,
  },
  secondaryButton: {
    padding: "1rem",
    marginRight: "0.25rem",
    border: `2px solid ${color.lightGrey}`,
    borderRadius: 8,
  },
  termsLabel: {
    fontSize: 14,
  },
  popupTitle: {
    fontSize: 27,
  },
  popupSubtitle: {
    fontSize: 14,
    margin: "1rem 0",
  },
});
