import * as React from "react";
import { withStyles, Typography } from "@material-ui/core";

export interface HeaderTitleStyles {
  readonly glassPanel: any;
  readonly paddingTop: any;
}

interface HeaderTitleProps {
  readonly title?: string;
  readonly message?: string;
}

const styles: HeaderTitleStyles = {
  glassPanel: {},
  paddingTop: {
    paddingTop: "16px",
  },
};

interface StylesProps {
  readonly classes: HeaderTitleStyles;
}

export type Props = StylesProps & HeaderTitleProps;

export class HeaderTitle extends React.Component<Props, {}> {
  public render() {
    const { classes, title, message } = this.props;
    return (
      <div className={classes.glassPanel}>
        <Typography variant="h5">{title}</Typography>
        <Typography className={classes.paddingTop} variant="body2">{message}</Typography>        
      </div>
    );
  }
}

export default withStyles(styles)(HeaderTitle) as any;
