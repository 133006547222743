import { StoreAction } from "../StoreHelper";

export interface ShowVideoData {
  src: string | null;
}

export enum VideoPlayerActionTypes {
  Show = "SHOW",
  Hide = "HIDE",
}

export type VideoPlayerActionPayload = ShowVideoData | undefined;

export type VideoPlayerAction = StoreAction<
  VideoPlayerActionTypes,
  VideoPlayerActionPayload
>;

export class VideoPlayerActions {
  public static show(data: ShowVideoData): VideoPlayerAction {
    return { type: VideoPlayerActionTypes.Show, data };
  }

  public static hide(): VideoPlayerAction {
    return { type: VideoPlayerActionTypes.Hide, data: undefined };
  }
}
