import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../../theme/Theme";

export const ContentBoxSectionStyles = makeStyles({
  contentBoxBody: {
    padding: "8px",
  },
  heading: {
    padding: "15px 0px",
    borderBottom: `solid 1px ${color.dark700}`,
  },
  subheading: {
    padding: "13px 0",
    borderBottom: `solid 1px ${color.dark700}`,
  },
  noBorder: {
    border: "none"
  },
  noSidePadding: {
    paddingLeft: "0px",
    paddingRight: "0px"
  }
});
