import React, { FC } from "react";
import logo from "../../assets/sorted-icon-light.png";
import { Links } from "./SidebarLinks.data";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  styled,
  Box,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import theme, { color } from "../../theme/Theme";
import { useHistory } from "react-router";
import { logOut, viewInNewTab } from "../../helper/HelperFunctions";
import { SubdirectoryArrowLeft, Close } from "@material-ui/icons";
import { SidebarStyles } from "./SidebarStyles.data";

const StyledListItemIcon = styled(ListItemIcon)({
  color: color.light,
  minWidth: "40px",
  [theme.breakpoints.down("xs")]: {
    display: "none",
  },
});

const StyledListItem = styled(ListItem)({
  marginBottom: "20px",
  [theme.breakpoints.down("xs")]: {
    display: "flex",
    justifyContent: "center",
  },
});

interface Props {
  hideSideBar: () => void;
}

const Sidebar: FC<Props> = (props) => {
  const classes = SidebarStyles();
  const history = useHistory();
  const { hideSideBar } = props;

  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <section className={classes.wrapper}>
      <Box onClick={hideSideBar} className={classes.sidebarCloseButton}>
        <Close color="inherit" />
      </Box>
      <div className={`${classes.centered} ${classes.logoContainer}`}>
        <div className={classes.logoWrapper}>
          <img src={logo} className={classes.logo} />
        </div>
        <Box
          display={downMd ? "none" : "block"}
          textAlign="center"
          color={color.light}>
          <Typography variant="caption" component="h5" color="inherit">
            Sorted
          </Typography>
          <Typography variant="caption" color="inherit">
            Services
          </Typography>
        </Box>
      </div>
      <nav className={classes.linksContainer}>
        <List>
          {Links.map((link) => (
            <StyledListItem
              onClick={() => {
                if (!link.absolute) {
                  history.push(link.route);
                  hideSideBar();
                } else {
                  viewInNewTab(link.route);
                }
              }}
              button
              key={link.text}>
              <StyledListItemIcon>
                <SvgIcon component={link.logo} />
              </StyledListItemIcon>
              <ListItemText
                className={classes.sideBarText}
                color={color.light}
                primaryTypographyProps={{
                  variant: "h4",
                  style: { color: color.light },
                }}
                primary={link.text}
              />
            </StyledListItem>
          ))}
        </List>
        <List>
          <StyledListItem onClick={() => logOut()} button>
            <StyledListItemIcon>
              <SvgIcon component={SubdirectoryArrowLeft} />
            </StyledListItemIcon>
            <ListItemText
              className={classes.sideBarText}
              color={color.light}
              primaryTypographyProps={{
                variant: "h4",
                style: { color: color.light },
              }}
              primary={"Log Out"}
            />
          </StyledListItem>
        </List>
      </nav>
    </section>
  );
};

export default Sidebar;
