import React, { FC, useContext } from "react";
import Viewer from "react-viewer";
import { ImageViewerContext } from "../../App";

const ImageViewer: FC<{}> = () => {
  const { visible, images, closeImageViewer, activeIndex } = useContext(
    ImageViewerContext
  );

  return (
    <Viewer
      activeIndex={activeIndex}
      visible={visible}
      downloadInNewWindow
      changeable={false}
      scalable={true}
      noNavbar
      zIndex={10000}
      attribute={false}
      noImgDetails
      showTotal={false}
      downloadable
      images={images}
      onClose={closeImageViewer}
    />
  );
};

export default ImageViewer;
