import { makeStyles } from "@material-ui/core";
import { color } from "../../../theme/Theme";

export const CommentStyles = makeStyles({
  commentContent: {
    margin: "1em 0",
    paddingRight: "1.5em",
  },
  commentDetailsText: {
    width: "100%",
  },
  reply: {
    background: color.wildSand,
  },
  commentListItem: {
    paddingTop: "1em",
    paddingRight: 0,
  },
  commentListItemAvatar: {
    marginTop: 0,
  },
  authorName: {
    marginBottom: ".3em",
  },
  replyCount: {
    marginRight: "1em",
  },
  repliesSeparator: {
    flexGrow: 1,
  },
  commentAndRepliesWrapper: {
    width: "100%",
  },
  replyList: {
    paddingRight: "16px",
  },
  actionsContainer: {
    "& > * + *": {
      marginLeft: "1em",
    },
    "& > *": {
      cursor: "pointer",
    },
  },
});
