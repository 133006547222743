export enum ServiceableRegionFields {
  locality = "locality",
  postal_code = "postcode",
  administrative_area_level_1 = "state",
  country = "country",
}

export interface Region {
  locality: string;
  postcode: string;
  state: string;
  country: string;
}
