import { makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AppSettings } from "../../AppSettings";
import Header from "../header/Header";

interface MaintenanceImageUnAuthProps {}

const useStyles = makeStyles({
  imageContainer: {
    height: "calc(100vh - 60px)",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const MaintenanceImageUnAuth: FC<MaintenanceImageUnAuthProps> = (props) => {
  const [imgData, setImgData] = useState<string | undefined>(undefined);

  const { maintenanceId, key } = useParams<{
    maintenanceId: string;
    key: string;
  }>();

  const classes = useStyles();

  useEffect(() => {
    fetch(
      `${AppSettings.server.baseUrl}/maintenance-request/${maintenanceId}/image/${key}`
    ).then((res) =>
      res.blob().then((blobData) => {
        setImgData(URL.createObjectURL(blobData));
      })
    );
  }, []);

  return imgData ? (
    <>
      <Header />
      <div className={classes.imageContainer}>
        <img src={imgData} alt="mrImg" />
      </div>
    </>
  ) : null;
};

export default MaintenanceImageUnAuth;
