import * as Yup from "yup";

export const LABELS = {
  resetPassword: "Reset password",
  resetPasswordMessage:
    "Don't worry! Enter your email address and we'll send you instructions with a link to reset your password.",
  emailPlaceholder: "Email address",
  return: "Return to login",
  logIn: "Log in",
  success: "Success!",
  checkInboxMessage:
    "Check your inbox for an email from us with instructions on how to reset your password.",
  resetLinkMessage: "Password reset link sent to",
  backToLogIn: "Back to login",
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string()
    .email("Please enter a valid e-mail address")
    .required("User name is required"),
});
