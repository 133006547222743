import { takeEvery, put } from "redux-saga/effects";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import { LoginActions } from "../actions/LoginActions";
import {
  LoginImpersonateAction,
  LoginImpersonateActionTypes,
} from "../actions/LoginImpersonationAction";
import {
  LoginResponse,
  LoginImpersonatePayload,
} from "../../services/login/LoginData";
import LoginImpersonateService from "../../services/login/LoginImpersonateService";
import LogRocket from "logrocket";

export function* postLoginImpersonateWorker(
  action: LoginImpersonateAction
): IterableIterator<any> {
  try {
    yield put(LoadingIndicatorActions.show());

    const data = action.data as LoginImpersonatePayload;

    const response: LoginResponse = (yield LoginImpersonateService.postLogin(
      data
    ))!;
    yield put(LoginActions.postLoginSuccess(response));

    LogRocket.identify("trades", {
      email: response.username,
      id: response.id,
    });
  } catch (error) {
    yield put(LoginActions.postLoginError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export default [
  takeEvery(LoginImpersonateActionTypes.IMPERSONATION_REQUEST, (action) =>
    postLoginImpersonateWorker(action)
  ),
];
