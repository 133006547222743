import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import ResetPasswordComponent from "../../component/resetPassword/ResetPassword";
import { ResetPasswordFormData } from "../../component/resetPassword/ResetPasswordConstants";
import { ResetPasswordPayload } from "../../services/login/LoginData";
import { LoginActions } from "../../store/actions/LoginActions";
import { useHistory, useLocation } from "react-router";
import { get } from "lodash";
import { routes } from "../../Routes";

const ResetPassword: FC<{}> = () => {
  // state
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [token, setToken] = useState<string>("");

  // hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // effects
  useEffect(() => {
    const parsedURL = queryString.parse(location.search);
    const user = get(parsedURL, "username") as string;
    const resetToken = get(parsedURL, "token") as string;

    if (!(user && resetToken)) {
      history.push(routes.login.viewGeneric());
    } else {
      setUsername(user);
      setToken(resetToken);
    }
  }, []);

  // variables and functions
  const dispatcher = {
    resetPassword: (data: ResetPasswordPayload) =>
      dispatch(LoginActions.resetPasswordStart(data)),
  };

  const onSubmit = (data: ResetPasswordFormData) => {
    const requestPayload: ResetPasswordPayload = {
      username,
      token,
      password: data.password,
      onSuccess: () => setSuccess(true),
      onError: (err: string) => setError(err),
    };
    dispatcher.resetPassword(requestPayload);
  };

  return username && token ? (
    <ResetPasswordComponent
      success={success}
      error={error}
      onSubmit={onSubmit}
    />
  ) : null;
};

export default ResetPassword;
