import React, { FC, useState, useEffect } from "react";
import { Box } from '@material-ui/core';
import { useHistory } from "react-router";
import { useAppSelector } from "../../store/RootReducers";
import { useDispatch } from "react-redux";
import { GetTradeUsersActions, PostTradeUserActions } from "../../store/actions/TradeUsersActions";
import { GetTradeUsersResponse, UserData } from "../../services/tradeService/TradeServiceData.data";
import { getTradeId } from "../../helper/Auth";
import { ListMembersComponent } from "../../component/listMembers/listMembers";
import DashboardWrapperComponent from "../../component/dashboard/DashboardWrapperComponent";
import { TopNavType } from "../../component/topNav/TopNav.data";
import { TopNavTeamLink } from "../../component/topNav/TopNavTeamLink.data";
import { GetUserResponse } from "../../services/login/LoginData";
import { InviteUserFormData } from "../../component/inviteUser/InviteUserFomData.data";
import { InviteUserFormDataToRequestConverter } from "../inviteUser/InviteUserFormDataToRequestConverter";
import { showToast } from "../../helper/HelperFunctions";
import { TypeHelper } from '../../helper/TypeHelper'
import { LABELS } from "../../component/inviteUser/InviteUserConstants.data";
import { ToastType } from "../../models/toastTypes/ToastTypesData.data";
import { UserTradeEntitlement } from "../../services/signUp/SignUpRequest.data";
import { UserRole } from "../../component/userDetails/UserDetailsData.data";
import { routes } from "../../Routes";

interface ListMembersProps {
    userDetails: GetUserResponse;
}

const ListMembers: FC<ListMembersProps> = ({ userDetails }) => {
  const tradeId = getTradeId();

  const { users, error } = useAppSelector((state) => ({
    users: state.tradeUsers.tradeUsers,
    error: state.tradeUsers.error
  }));

  
  // State
  const [usersList, setUsersList] = useState<GetTradeUsersResponse | undefined>(
    undefined
  );

  const [getUsersError, setGetUsersError] = useState<Error | null>(null);

  const history = useHistory();
  // dispatcher
  const dispatch = useDispatch();
  
  const dispatcher = {
    getUsers: (id: string) => {
      dispatch(
        GetTradeUsersActions.getTradeUsersRequest({ request: { tradeId: id } })
      );
    },
    postUser: (formData: InviteUserFormData) => {
      dispatch(
        PostTradeUserActions.postTradeUserRequest({
          request: InviteUserFormDataToRequestConverter(formData),
          onSuccess: (response) => {
            showToast(
              dispatch,
              LABELS.inviteSuccessful + `${response.email}`,
              ToastType.SUCCESS
            );
          },
        })
      );
    },
  };

  // Effects
  useEffect(() => {
    getUsers(); 
  }, []);

  useEffect(() => {
    if (users) {
      setUsersList(users);
    }
  }, [users]);

  useEffect(() => {
    setGetUsersError(error);
  }, [error]);

  const getUsers = (): void => {
    dispatcher.getUsers(tradeId!);
  };

  const editUser = (id: string) => {
    history.push(routes.dashboard.peopleEdit(id))
  }
    
  const { userTradeEntitlements, id } = userDetails;
  const showAllUsers = userTradeEntitlements.find((userTradeEntitlement: UserTradeEntitlement) => userTradeEntitlement.role as UserRole === UserRole.tradeAdmin)

  let member: UserData | undefined = undefined;
  if (usersList) {
    member = usersList.content.find((user: UserData) => user.id === id)
  }
  return (
    <Box display="flex" flexDirection="row">
        <Box flexGrow="1">
        {usersList ? (
          <DashboardWrapperComponent
            navType={TopNavType.Team}
            activeLink={TopNavTeamLink.People}
            userDetails={userDetails}
          > 
            <ListMembersComponent
              users={showAllUsers ? usersList.content : member ? [{...member}] : []}
              onAddNew={(formData) => {dispatcher.postUser(formData)}}
              error={getUsersError}
              isCurrentUserAdmin={!TypeHelper.isNullOrUndefined(showAllUsers)}
              onEditUser={editUser}
            />   
        </DashboardWrapperComponent> ) : null}  
      </Box> 
    </Box>
  )
};

export default ListMembers;
