import React, { FC } from "react";
import { GetUserResponse } from "../../../../services/login/LoginData";
import {
  UserTradeCategoriesResponse,
  DeleteUserTradeCategoryDocumentRequest,
} from "../../../../services/userTrades/UserTradeService.data";
import ContentHeadingSection from "../../contentHeadingSection/ContentHeadingSection";
import { LABELS } from "./ProfileSettingsConstants.data";
import DashboardSection from "../../dashboardSection/DashboardSection";
import PersonalInfoForm from "./personalInfoForm/PersonalInfoForm";
import { UserDetailsFormData } from "../../../userDetails/UserDetailsData.data";
import ChangePasswordForm from "./changePasswordForm/ChangePasswordForm";
import TradeCategoriesForm from "./tradeCategoriesForm/TradeCategoriesForm";
import { Box } from "@material-ui/core";
import UserDocuments from "./userDocuments/UserDocuments";
import { AddUserDocData } from "./userDocuments/UserDocumentsData.data";
import { AddDocData } from "../../../../container/addTradesDocuments/AddTradesDocumentsData.data";
import { UserDocument } from "../../../../models/userDocument/UserDocument.data";
import {
  GetTradeCategoriesResponse,
  TradeCategory,
} from "../../../../services/tradeCategories/TradeCategories.data";
import { ChangePasswordFormFields } from "./changePasswordForm/ChangePasswordFormData.data";

interface Props {
  updatingTeamMembers: boolean;
  userDetails: GetUserResponse;
  tradeCategoriesDetails: UserTradeCategoriesResponse;
  tradeCategories: GetTradeCategoriesResponse;
  onPersonalInfoSubmit: (values: UserDetailsFormData) => void;
  onChangePasswordSubmit: (values: ChangePasswordFormFields) => void;
  onAddTradeCategory: (selectedTradeCategories: TradeCategory[]) => void;
  onRemoveTradeCategory: (tradeCategoryId: number) => void;
  onAddTradeCategoryDocument: (values: AddDocData) => void;
  onUserDocSubmit: (data: AddUserDocData) => void;
  userDocuments: UserDocument[];
  onRemoveUserDocument: (userDocumentId: number) => void;
  onRemoveUserTradeCategoryDocument: (
    request: DeleteUserTradeCategoryDocumentRequest
  ) => void;
}

const ProfileSettingsComponent: FC<Props> = ({
  updatingTeamMembers,
  userDetails,
  onPersonalInfoSubmit,
  onChangePasswordSubmit,
  tradeCategoriesDetails,
  onAddTradeCategory,
  onRemoveTradeCategory,
  onAddTradeCategoryDocument,
  onUserDocSubmit,
  userDocuments,
  onRemoveUserDocument,
  tradeCategories,
  onRemoveUserTradeCategoryDocument,
}) => {
  return (
    <Box pt={3} px={2}>
      <ContentHeadingSection
        hideOnSmallScreens
        heading={updatingTeamMembers ? LABELS.updateDetails : LABELS.heading}
      />

      {/* Update Personal Info */}
      <DashboardSection
        sectionTopMargin
        innerHeading={LABELS.personalInformation}>
        <PersonalInfoForm
          onSubmit={onPersonalInfoSubmit}
          userDetails={userDetails}
        />
      </DashboardSection>

      {/* Change Password */}
      {!updatingTeamMembers && (
        <DashboardSection sectionTopMargin innerHeading={LABELS.security}>
          <ChangePasswordForm onSubmit={onChangePasswordSubmit} />
        </DashboardSection>
      )}

      {/* Trade categories */}
      <DashboardSection sectionTopMargin innerHeading={LABELS.trades}>
        <TradeCategoriesForm
          tradeCategories={tradeCategories}
          userTradeCategories={tradeCategoriesDetails}
          onAddTradeCategory={onAddTradeCategory}
          onRemoveTradeCategory={onRemoveTradeCategory}
          onRemoveUserTradeCategoryDocument={onRemoveUserTradeCategoryDocument}
          onAddTradeCategoryDocument={onAddTradeCategoryDocument}
        />
      </DashboardSection>

      {/* User Documents */}
      <Box mb={2}>
        <DashboardSection sectionTopMargin innerHeading={LABELS.documents}>
          <UserDocuments
            userDocuments={userDocuments}
            onSubmit={onUserDocSubmit}
            onRemoveUserDocument={onRemoveUserDocument}
          />
        </DashboardSection>
      </Box>
    </Box>
  );
};

export default ProfileSettingsComponent;
