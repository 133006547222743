export const maxMobileWidth = 768;
export const maxTabletWidth = 992;
export const EMAIL_PATTERN = /^([a-zA-Z0-9._+-])+\@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]$/;
export const NAME_PATTERN = /^$|^([A-Za-z])([A-Za-z0-9]+)/;
export const NUMBER_PATTERN = /^$|^[1-9.,][0-9.,]*$/;
export const NUMBER_PATTERN_LESS_THAN_0 = /^$|^[0-9.,]*$/;
export const DATE_OF_BIRTH_PATTERN =
  /^([0-2^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
export const MONTH_AND_YEAR_FORMAT = /[01][0-9]\/[0-9][0-9][0-9][0-9]/;
export const MOBILE_NUMBER_PATTERN =
  /^$|^(04|614|\+614)([0-9]{2})([ .-]?)([0-9]{3})([ .-]?)([0-9]{3})$/;
export const CONTACT_NUMBER_PATTERN =
  /^$|^(02|03|07|08|04|614|\+614)([^$]?)([0-9]{2})([ .-]?)([0-9]{3})([ .-]?)([0-9]{3})$/;
export const MIN_ID_POINTS = 80;
export const SIZES = {
  md: 780,
  lg: 1024,
  lgMax: 1366,
};

export const SCREENSIZE = {
  $xsmall: 0,
  $small: 480,
  $medium: 768,
  $large: 1024,
  $xlarge: 1200,
  $xxlarge: 1440,
};

export const FILE_SIZE_MAX = 10485760;

export const GST_PERCENT = 10;

export const invalidMobileNumber =
  "The expected format is 04XXXXXXXX or 614XXXXXXXX or +614XXXXXXXX";

export const invalidContactNumber =
  "The expected format is e.g. 02XXXXXXXX or 02 XXXXXXXX or +614XXXXXXXX";

export enum PROFILE_FORMS {
  PERSONAL_DETAILS_FORM = "PersonalDetails",
  INTRODUTION = "Introduction",
  CURRENT_INCOME_FORM = "CurrentIncome",
  EMPLOYMENT_FORM = "Employment",
  PETS_FORM = "Pets",
  ADDRESS_FORM = "Address",
  REFERENCE_FORM = "Reference",
  IDENTIFICATION_FORM = "Identification",
  DECLARATION_FORM = "Declaration",
}

export const errorMessages = {
  pageNotFoundTitle: "404",
  pageNotFoundMessage: "The page you requested was not found.",
  linkExpiredTitle: "Sorry",
  errorDialogTitle: "Oops, something went wrong.",
  linkExpiredMessage: "Invalid Register Token or Link is Expired ",
  serverError:
    "Sorry about this! Seems there was an error when we were talking to our server. Try it again and if it still doesn't work please submit a support request using the 'help' button in the bottom right corner. We'll get back to you as soon as we can.",
  authorisationError: "Access denied",

  // Login Page error messages
  loginPage: {
    incorrectCredentials: "Incorrect email or password",
    emailVerificationFailed:
      "Your email hasn’t been verified. Check your email to verify or resend the link.",
  },
};

export const agencies = {
  ipg: { name: "Independent Property Management PTY LTD" },
  sorted: { name: "Sorted Demo" },
  pbs: { name: "Peter Blackshaw" },
  pbgun: { name: "Peter Blackshaw" },
  pbsbn: { name: "Peter Blackshaw" },
  imp: { name: "JD Property (AUST) Pty Ltd" },
  lsi: { name: "Cara Real Estate P/L" },
  nax: { name: "Nelson Alexander" },
  ins: { name: "inStyle Estate Agents" },
  geo: { name: "Geocon Property Management" },
  au_prop: { name: "Propper" },
  aubuxton: { name: "Buxton Real Estate" },
  austar: { name: "Starr Partners" },
};

export const TRADE_CATEGORIES_ICON_DIRECTORY = "/TradeImages";

export const DATE_FORMAT = "DD/MM/YYYY";

export const MOMENT_DATE_FORMATS = {
  DO_MMM_YYYY: "Do MMM YYYY",
  DD_MM_YYYY: "DD/MM/YYYY",
  DD_MM_YYYY_HH_MM: "DD/MM/YYYYTHH:mm",
  DD_MMM_YYYY_SPACED: "DD MMM YYYY",

  CommentDateAndTime: "MMM Do YYYY [at] h:mm A",
  JobBookingDateAndTime: "DD/MM/YYYYTHH:MM",
};

export const confirmationDialogIconDimensions = {
  width: "64px",
  height: "64px",
};

export enum FileTypes {
  Image = "image",
  Pdf = "pdf",
  Video = "video",
}

export const paginationRowsPerPageOptions = [5, 10, 20, 30, 40, 50];
export const paginationDefaultPageSize = 10;

// links
export const links = {
  helpCenter:
    "https://help.sortedservices.com/en/collections/2894072-trades-portal",
  creditCardAgreement:
    "https://static1.squarespace.com/static/5ad6c933ee1759a2c83f3ef2/t/60d56e9edb26c6201be8ab36/1624600223136/Sorted+Direct+Debit+Agreement+1.0.pdf",
};
