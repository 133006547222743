import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import {DashboardSectionHeadingStyles} from "./DashboardSectionHeadingStyles.data"

interface Props {
  text: string
}

const DashboardSectionHeading:FC<Props> = (props) => {
  const {text} = props;
  const classes = DashboardSectionHeadingStyles();
  return (
    <div className={classes.sectionHeadingContainer}>
      <Typography variant="h4">{text}</Typography>
    </div>
   )
 }

export default DashboardSectionHeading