import React, { FC } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import { ApplicationState, useAppSelector } from "../../store/RootReducers";
import { Typography } from "@material-ui/core";
import { ReportProblemOutlined, Done } from "@material-ui/icons";
import { ToastType } from "../../models/toastTypes/ToastTypesData.data";
import { ToastMessageStyles } from "./ToastMessageStyles.data";

const ToastMessage: FC<{}> = (props) => {
  // State
  const { open, message, type } = useAppSelector((state: ApplicationState) => ({
    open: state.toast.open,
    message: state.toast.message,
    type: state.toast.type,
  }));

  const classes = ToastMessageStyles();

  const iconMapper = (toastType: ToastType) => {
    switch (toastType) {
      case ToastType.SUCCESS:
        return <Done className={`${classes.icon} ${classes.successIcon}`} />;
      case ToastType.ERROR:
        return (
          <ReportProblemOutlined
            className={`${classes.icon} ${classes.errorIcon}`}
          />
        );
    }
  };

  return (
    <Snackbar
      open={open}
      TransitionComponent={(props) => <Slide {...props} direction="up" />}
      ContentProps={{
        classes: {
          message: classes.snackBarContentMessage,
        },
      }}
      message={
        <>
          {iconMapper(type!)}
          <Typography variant={"h4"} className={classes.message}>
            {message}
          </Typography>
        </>
      }
      autoHideDuration={3000}
    />
  );
};
export default ToastMessage;
