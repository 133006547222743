import { GetTradeCategoriesResponse } from "../../services/tradeCategories/TradeCategories.data";
import {
  PostUserTradeCategoriesResponse,
  PostUserTradeCategoriesRequestPayload,
  UserTradeDocumentUploadResponse,
  UserTradeCategoriesResponse,
  UserTradeDocumentsUploadData,
  DeleteUserTradeCategoryRequest,
  DeleteUserTradeCategorySuccessPayload,
  DeleteUserTradeCategoryDocumentRequest,
  DeleteUserTradeCategoryDocumentSuccessPayload,
} from "../../services/userTrades/UserTradeService.data";

export enum TradeCategoriesActionTypes {
  GET_CATEGORIES_REQUEST = "GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR",

  GET_USER_TRADE_CATEGORIES_REQUEST = "GET_USER_TRADE_CATEGORIES_REQUEST",
  GET_USER_TRADE_CATEGORIES_SUCCESS = "GET_USER_TRADE_CATEGORIES_SUCCESS",
  GET_USER_TRADE_CATEGORIES_ERROR = "GET_USER_TRADE_CATEGORIES_ERROR",

  POST_USER_TRADE_CATEGORIES_REQUEST = "POST_USER_TRADE_CATEGORIES_REQUEST",
  POST_USER_TRADE_CATEGORIES_SUCCESS = "POST_USER_TRADE_CATEGORIES_SUCCESS",
  POST_USER_TRADE_CATEGORIES_ERROR = "POST_USER_TRADE_CATEGORIES_ERROR",

  POST_USER_TRADE_DOCUMENT_REQUEST = "POST_USER_TRADE_DOCUMENT_REQUEST",
  POST_USER_TRADE_DOCUMENT_SUCCESS = "POST_USER_TRADE_DOCUMENT_SUCCESS",
  POST_USER_TRADE_DOCUMENT_ERROR = "POST_USER_TRADE_DOCUMENT_ERROR",

  DELETE_USER_TRADE_CATEGORY_REQUEST = "DELETE_USER_TRADE_CATEGORY_REQUEST",
  DELETE_USER_TRADE_CATEGORY_SUCCESS = "DELETE_USER_TRADE_CATEGORY_SUCCESS",
  DELETE_USER_TRADE_CATEGORY_ERROR = "DELETE_USER_TRADE_CATEGORY_ERROR",

  DELETE_USER_TRADE_DOCUMENT_REQUEST = "DELETE_USER_TRADE_DOCUMENT_REQUEST",
  DELETE_USER_TRADE_DOCUMENT_SUCCESS = "DELETE_USER_TRADE_DOCUMENT_SUCCESS",
  DELETE_USER_TRADE_DOCUMENT_ERROR = "DELETE_USER_TRADE_DOCUMENT_ERROR",
}

export interface UserTradeDocumentUploadObject
  extends UserTradeDocumentUploadResponse {
  userTradeCategoryId: number;
}

export interface TradeCategoriesAction {
  type: TradeCategoriesActionTypes;
  data?:
    | GetTradeCategoriesResponse
    | PostUserTradeCategoriesResponse
    | PostUserTradeCategoriesRequestPayload
    | UserTradeDocumentsUploadData
    | UserTradeDocumentUploadObject
    | UserTradeCategoriesResponse
    | DeleteUserTradeCategoryRequest
    | DeleteUserTradeCategorySuccessPayload
    | DeleteUserTradeCategoryDocumentRequest
    | DeleteUserTradeCategoryDocumentSuccessPayload
    | Error;
}

export class TradeCategoriesActions {
  public static getTradeCategoriesRequest(): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.GET_CATEGORIES_REQUEST,
    };
  }

  public static getTradeCategoriesSuccess(
    data: GetTradeCategoriesResponse
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.GET_CATEGORIES_SUCCESS,
      data,
    };
  }

  public static getTradeCategoriesError(error: Error): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.GET_CATEGORIES_ERROR,
      data: error,
    };
  }

  public static postUserTradeCategoriesRequest(
    data: PostUserTradeCategoriesRequestPayload
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.POST_USER_TRADE_CATEGORIES_REQUEST,
      data,
    };
  }

  public static postUserTradeCategoriesSuccess(
    data: PostUserTradeCategoriesResponse
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.POST_USER_TRADE_CATEGORIES_SUCCESS,
      data,
    };
  }

  public static postUserTradeCategoriesError(
    error: Error
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.POST_USER_TRADE_CATEGORIES_ERROR,
      data: error,
    };
  }

  public static postUserTradeDocumentRequest(
    data: UserTradeDocumentsUploadData
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.POST_USER_TRADE_DOCUMENT_REQUEST,
      data,
    };
  }

  public static postUserTradeDocumentSuccess(
    data: UserTradeDocumentUploadResponse,
    userTradeCategoryId: number
  ): TradeCategoriesAction {
    const actionData: UserTradeDocumentUploadObject = {
      ...data,
      userTradeCategoryId,
    };
    return {
      type: TradeCategoriesActionTypes.POST_USER_TRADE_DOCUMENT_SUCCESS,
      data: actionData,
    };
  }

  public static postUserTradeDocumentError(
    error: Error
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.POST_USER_TRADE_DOCUMENT_ERROR,
      data: error,
    };
  }

  public static getUserTradeCategoriesRequest(): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.GET_USER_TRADE_CATEGORIES_REQUEST,
    };
  }

  public static getUserTradeCategoriesSuccess(
    data: UserTradeCategoriesResponse
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.GET_USER_TRADE_CATEGORIES_SUCCESS,
      data,
    };
  }

  public static getUserTradeCategoriesError(
    error: Error
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.GET_USER_TRADE_CATEGORIES_ERROR,
      data: error,
    };
  }

  public static deleteUserTradeCategoryRequest(
    data: DeleteUserTradeCategoryRequest
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.DELETE_USER_TRADE_CATEGORY_REQUEST,
      data,
    };
  }

  public static deleteUserTradeCategorySuccess(
    data: DeleteUserTradeCategorySuccessPayload
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.DELETE_USER_TRADE_CATEGORY_SUCCESS,
      data,
    };
  }

  public static deleteUserTradeCategoryError(
    error: Error
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.DELETE_USER_TRADE_CATEGORY_ERROR,
      data: error,
    };
  }

  // Delete user trade document
  public static deleteUserTradeCategoryDocumentRequest(
    data: DeleteUserTradeCategoryDocumentRequest
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.DELETE_USER_TRADE_DOCUMENT_REQUEST,
      data,
    };
  }

  public static deleteUserTradeCategoryDocumentSuccess(
    data: DeleteUserTradeCategoryDocumentSuccessPayload
  ): TradeCategoriesAction {
    return {
      type: TradeCategoriesActionTypes.DELETE_USER_TRADE_DOCUMENT_SUCCESS,
      data,
    };
  }

  public static deleteUserTradeCategoryDocumentError(error: Error){
    return{
      type: TradeCategoriesActionTypes.DELETE_USER_TRADE_DOCUMENT_ERROR,
      data:error
    }
  }

}
