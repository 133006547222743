import { makeStyles } from "@material-ui/core";
import { color } from "../../theme/Theme";

export const TradsChipStyles = makeStyles({
  topMargin: {
    marginTop: "2em",
  },
  activeChip: {
    background: `${color.wildSand} !important`,
  },
  inactiveChip: {
    background: color.light,
    opacity: ".5",
  },
  navLinks: {
    textTransform: "capitalize",
    fontSize: "1em",
    color: color.light,
    opacity: ".5",
  },
  navLinkActive: {
    color: `${color.light} !important`,
    opacity: 1,
    border: "0 !important",
  },
  noBorder: {
    border: "none",
  },
  chipRoot: {
    minWidth: "120px",
    cursor: "pointer",
  },
});
