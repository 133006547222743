export const LABELS = {
  additionalDetails: "Additional details",
  propertyAccess: "Property access",
  preferredTime: "Preferred time",
  preferredDays: "Preferred days",
  noUnaccompaniedMinors: "No unaccompanied minors",
  pet: "Pet",
  animalDescription: "Animal description",
  clearAccess: "Clear access to site",
  stairsAccess: "Stairs to access site",
  parkingOnSite: "Parking onsite",
  keyNumber: "Key number",
  ageOfHouse: "Age of house",
  typeOfRoof: "Type of roof",
  typeOfWalls: "Type of walls",
};
