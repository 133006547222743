export enum OnboardingStage {
    AddBusiness = "add_business",
    AddServicableRegions = "add_business_serviceable_regions",
    AddOtherOffices = "add_other_offices",
    AddOffice = "add_office",
    AddOfficeServicableRegions = "add_office_serviceable_regions",
    AddPaymentMethods = 'add_payment_methods',
    AddMoreOffice = "add_more_office",
    UpdateAdminDetails = "update_admin_details",
    AddAdminTradeCategories = "add_admin_trades_categories",
    AddAdminTradeCategoriesDocuments = "add_admin_trades_categories_documents",
    InviteMembers = "invite_members",
    Confirm = "confirm",
    Completed = "completed",
  }
  