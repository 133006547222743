import { parsePhoneNumberFromString } from "libphonenumber-js";

const emailRex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const isEmpty = (value: any) =>
  value === undefined || value === null || value.toString() === "";

export const isRequired = (errorMsg: string) => (value: any) => {
  if (isEmpty(value)) {
    return errorMsg || `Enter a value`;
  }
  return undefined;
};

export const noSpaceValidator = (errorMsg: string) => (value: any) => {
  const rex = /^\S*$/;
  if (!rex.test(value)) {
    return errorMsg || "Cannot contain spaces";
  }
  return undefined;
};

export const email = (errorMsg: string) => (value: string) => {
  if (isEmpty(value)) {
    return undefined;
  }
  if (!isEmpty(value) && !emailRex.test(value)) {
    return errorMsg || "Check the email format (e.g. name@domain.com)";
  }

  return undefined;
};

export const isJsonString = (value: string) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};

export const phone = (errorMsg: string) => {
  const rex = /^\(?0([0-9]{1})\)?([ .-]?)([0-9]{4})([ .-]?)([0-9]{4})$/;
  const rex2 = /^04([0-9]{2})([ .-]?)([0-9]{3})([ .-]?)([0-9]{3})$/;
  return (value: string) => {
    if (isEmpty(value)) {
      return undefined;
    }

    if (!rex.test(value) && !rex2.test(value)) {
      return errorMsg || "The expected format is 0X XXXX XXXX or 04XX XXX XXX";
    }
    return undefined;
  };
};

export const maxLength = (errorMsg: string, max: number) => (value: string) => {
  if (isEmpty(value)) {
    return undefined;
  }
  if (value.length > max) {
    return errorMsg || `Please enter a value less than ${max} characters`;
  }
  return undefined;
};

export const minLength = (errorMsg: string, min: number) => (value: string) => {
  if (isEmpty(value)) {
    return undefined;
  }
  if (value.length < min) {
    return errorMsg || `Please enter a value more than ${min} characters`;
  }
  return undefined;
};

export interface ErrorCheck {
  readonly flag: boolean;
  readonly message: string;
}

export const phoneCheck = (phoneNumber: string) => {
  const num = parsePhoneNumberFromString(phoneNumber, "AU");

  return !!num && num.isValid();
};

export const emailCheck = (emailAddress: string): ErrorCheck => {
  let message = "";
  let flag = true;
  if (!emailRex.test(emailAddress)) {
    flag = false;
    message = "\t• Check the email format (e.g. name@domain.com)\n";
  }
  return {
    message,
    flag,
  };
};

export const pxToem = ($pixels: number) => {
  const $context = 14;
  const calc = $pixels / $context;
  return calc + "em";
};

export const passwordValidator = (errorMsg: string) => (password: string) =>
  passwordRex.test(password) ? undefined : errorMsg;
