import { Typography, Box } from "@material-ui/core";
import React, { FC } from "react";
import { overviewStyles } from "./OverviewStyles";

export const NoItemsToShow: FC<{}> = () => {
  const classes = overviewStyles();
  return (
    <Box className={classes.centeredBox}>
      <Typography variant="body2" color="textSecondary">
        No items to show
      </Typography>
    </Box>
  );
};
