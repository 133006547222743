import React, { FC } from "react";
import Wrapper from "../authPagesWrapper/AuthPagesWrapper";
import { LABELS } from "./LandingScreenConstants";
import { Typography, Button } from "@material-ui/core";
import { Link, Redirect, useLocation } from "react-router-dom";
import { routes } from "../../Routes";
import { isLoggedIn, onboardingStage } from "../../helper/Auth";
import { OnboardingStage } from "../../helper/OnboardingStages";
import { onboardingRouteMapper } from "../../helper/HelperFunctions";
import { LandingScreenStyles } from "./LandingScreenStyles.data";

type OwnProps = {};

export const LandingScreen: FC<OwnProps> = () => {
  const classes = LandingScreenStyles();
  const location = useLocation();
  const { from } = (location.state as any) || { from: routes.dashboard.view };

  /* tslint:disable:cyclomatic-complexity */
  return isLoggedIn() ? (
    onboardingStage() === OnboardingStage.Completed ? (
      <Redirect to={from} />
    ) : (
      <Redirect to={onboardingRouteMapper(onboardingStage() as string)} />
    )
  ) : (
    <Wrapper containerClassName={"landing-wrapper"}>
      <Typography variant="h4" className={classes.tradesmanHeading}>
        {LABELS.REGISTERED_TRADESMAN}
      </Typography>
      <Typography color="primary" variant="h1">
        {LABELS.HEADING}
      </Typography>
      <Typography
        color="primary"
        className={`${classes.topMargin}`}
        variant="h5">
        {LABELS.SUBHEADING}
      </Typography>
      <Typography className={`${classes.topMargin}`} variant="subtitle1">
        {LABELS.DESCRIPTION}
      </Typography>
      <Link to={routes.signUp.viewGeneric()}>
        <Button className={`${classes.signUpButton}`} disableRipple>
          {LABELS.SIGNUP}
        </Button>
      </Link>

      <p className={`${classes.topMargin}`}>
        {LABELS.HAVE_AN_ACCOUNT}{" "}
        <Link to={routes.login.viewGeneric()} className={classes.loginLink}>
          {LABELS.LOGIN}
        </Link>
      </p>
    </Wrapper>
  );
};

export default LandingScreen;
