import { makeStyles } from "@material-ui/core";
import { color } from "../../theme/Theme";

export const uploadFileListItemIconStyles = makeStyles({
  icon: {
    marginRight: ".5em",
  },
  imgIcon: {
    color: color.teal,
  },
  pdfIcon: {
    color: color.warning,
  },
});
