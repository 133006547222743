import React, { FC } from "react";
import { LABELS } from "./QuotesComponentConstants.data";
import ContentHeadingSection from "../contentHeadingSection/ContentHeadingSection";
import { Box, useTheme, useMediaQuery, Paper, Button } from "@material-ui/core";
import DashboardWrapperComponent from "../DashboardWrapperComponent";
import { TopNavType } from "../../topNav/TopNav.data";
import { TopNavDashboardLink } from "../../topNav/TopNavDashboardLink.data";
import DetailsSidebar from "../../detailsSidebar/DetailsSideBar";
import { GetUserResponse } from "../../../services/login/LoginData";
import Quotes from "./quotesList/Quotes";
import { QuoteStatus } from "../../../models/quoteStatus/QuoteStatus.data";
import { Quote } from "../../../models/quote/Quote.data";
import QuoteDetailsSidebar from "./quoteDetailsSidebar/QuoteDetailsSidebar";
import { Property } from "../../../models/property/Property.data";
import { getQuoteProperty } from "../../../helper/HelperFunctions";
import {
  PaginatedQuoteResponse,
  QuoteStatusCount,
} from "../../../services/quotes/QuotesData.data";
import { ArrowBack } from "@material-ui/icons";
import { QuoteComponentStyles } from "./QuotesComponentStyles.data";
import { Link } from "react-router-dom";
import { routes } from "../../../Routes";

interface Props {
  userDetails: GetUserResponse;
  onFilterChange: (filter: QuoteStatus) => void;
  quoteResponse: PaginatedQuoteResponse;
  propertyList: Property[];
  quoteStatus: QuoteStatus;
  setSidebarQuote: React.Dispatch<React.SetStateAction<Quote | null>>;
  sidebarQuote: Quote | null;
  loading: boolean;
  onPassQuote: (quoteId: string) => void;
  onPageChange: (page: number, size: number) => void;
  onQuotesPerPageChange: (quotesPerPage: number) => void;
  showAgencyMessage: boolean;
  quotesStatusCount: QuoteStatusCount;
}

const QuotesComponent: FC<Props> = ({
  userDetails,
  onFilterChange,
  quoteStatus,
  propertyList,
  setSidebarQuote,
  sidebarQuote,
  quoteResponse: { totalElements, pageable, content: quotes },
  onPassQuote,
  onPageChange,
  loading,
  showAgencyMessage,
  onQuotesPerPageChange,
  quotesStatusCount,
}) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = QuoteComponentStyles();

  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow="1">
        <DashboardWrapperComponent
          navType={TopNavType.Dashboard}
          activeLink={TopNavDashboardLink.Quotes}
          userDetails={userDetails}>
          <>
            {downSm && sidebarQuote ? (
              <Box p={2}>
                <Box display="flex" alignItems="center">
                  <Box
                    onClick={() => setSidebarQuote(null)}
                    className={classes.pointer}>
                    <ArrowBack />
                  </Box>
                  <Box ml={2} display="inline-block">
                    <ContentHeadingSection
                      hideBack
                      heading={LABELS.quoteDetails}
                    />
                  </Box>
                </Box>
                <QuoteDetailsSidebar
                  property={getQuoteProperty(sidebarQuote, propertyList)}
                  quote={sidebarQuote}
                  onPassQuote={onPassQuote}
                />
              </Box>
            ) : (
              <>
                {showAgencyMessage && (
                  <Box mb={2}>
                    <Paper variant="outlined">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"space-between"}
                        p={2}>
                        <p>{LABELS.addAgencyMessage}</p>
                        <Link
                          to={{
                            state: { showAddAgentModal: true },
                            pathname: routes.dashboard.team.agents,
                          }}>
                          <Button className={classes.addAgentButton}>
                            {LABELS.addAgent}
                          </Button>
                        </Link>
                      </Box>
                    </Paper>
                  </Box>
                )}

                <ContentHeadingSection
                  heading={LABELS.quotes}
                  hideOnSmallScreens
                  hideBack
                />
                <Quotes
                  loading={loading}
                  userDetails={userDetails}
                  pageable={pageable}
                  onFilterChange={onFilterChange}
                  quotes={quotes}
                  count={totalElements}
                  quotesStatusCount={quotesStatusCount}
                  propertyList={propertyList}
                  status={quoteStatus}
                  showQuoteDetailsInSidebar={setSidebarQuote}
                  sidebarQuoteId={!!sidebarQuote ? sidebarQuote.id : undefined}
                  onPageChange={onPageChange}
                  onQuotesPerPageChange={onQuotesPerPageChange}
                />
              </>
            )}
          </>
        </DashboardWrapperComponent>
      </Box>
      <Box maxWidth="390px">
        <DetailsSidebar userDetails={userDetails}>
          {sidebarQuote && (
            <QuoteDetailsSidebar
              property={getQuoteProperty(sidebarQuote, propertyList)}
              quote={sidebarQuote}
              onPassQuote={onPassQuote}
            />
          )}
        </DetailsSidebar>
      </Box>
    </Box>
  );
};

export default QuotesComponent;
