import * as Yup from "yup";

export const LABELS = {
  enterAmount: "Enter an amount",

  // Quote Details
  costOfLabour: "Cost of labour:",
  callOut: "Call out:",
  includedMaterials: "Included materials:",

  // Specific material
  specificMaterial: "Materials",
  enterItemName: "Enter an item name",

  // Sub Total
  subTotal: "Sub Total (inc GST):",
  jobCost: "Job cost",
  gst: "+GST",
  total: "Total",
  sortedFee: "Sorted fee",
  sortedFeeHelperText:
    "A 3.5% processing fee will be automatically applied to the total cost of each job. This is for ongoing support and improvements to the Sorted platform, and will be invoiced to you on a monthly basis.",
  includesGstOf: "Includes GST of",
  processingFee: "Processing fee (ex GST):",
  estimateTotal: "Estimated Total",

  submitQuote: "Submit Quote",
  noteAgentConfirmation:
    "Once submitted it will be passed on to the agent for consideration.",
  noteCustomerConfirmation:
    "Once submitted it will be sent on to the customer for consideration.",
  onSiteQuote: "On-site quote request",
  addNote: "Add a note",
  addNotePrivate:
    "Add a note (This note will be visible to customer on quote submission)",
  notes: "Notes",
  scopeDescription: "Scope of work description",
  scopeDescriptionPrivate: "Scope of work description (sent to customer)",

  inclusiveGST: "Including GST",
  exclusiveGST: "Excluding GST",
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  startDate: Yup.mixed().required("Start date is required"),
  endDate: Yup.mixed().required("Expected completion date is required"),
});
