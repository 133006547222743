import React, { FC, useState, useRef, useEffect, useContext } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  withStyles,
  ListItemIcon,
  Box,
} from "@material-ui/core";
import {
  Clear,
  InsertDriveFileOutlined,
  Add,
  CancelOutlined,
} from "@material-ui/icons";
import { LABELS } from "./UserDocumentsConstants.data";
import { color } from "../../../../../theme/Theme";
import AddUserDocModal from "../addUserDocModal/AddUserDocModal";
import { AddUserDocFormData } from "../addUserDocModal/AddUserDocForm.data";
import { AddUserDocData } from "./UserDocumentsData.data";
import { UserDocument } from "../../../../../models/userDocument/UserDocument.data";
import { getLabelFromUserDocumentType } from "../../../../../helper/UserDocuments";
import ConfirmationDialog from "../../../../confirmationDialog/ConfirmationDialog";
import {
  confirmationDialogIconDimensions,
  FileTypes,
} from "../../../../../constants/AppConstants";
import { UserDocumentsStyles } from "./UserDocumentsStyles.data";
import {
  getFileType,
  viewInNewTab,
} from "../../../../../helper/HelperFunctions";
import { ImageViewerContext } from "../../../../../App";
import moment from 'moment';

interface Props {
  userDocuments: UserDocument[];
  onSubmit: (data: AddUserDocData) => void;
  onRemoveUserDocument: (userDoucmentId: number) => void;
}

const AddFileIcon = withStyles({
  root: {
    color: color.secondary400,
  },
})(InsertDriveFileOutlined);

const FileIcon = withStyles({
  root: {
    color: color.secondary200,
  },
})(InsertDriveFileOutlined);

const AddIcon = withStyles({
  root: {
    color: color.secondary400,
  },
})(Add);

const ListTradeCategoryIcon = withStyles({
  root: {
    minWidth: "40px",
  },
})(ListItemIcon);

const UserDocuments: FC<Props> = (props) => {
  const [file, setFile] = useState<File | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState<number | null>(null);
  const { setImages } = useContext(ImageViewerContext);

  const { userDocuments, onSubmit, onRemoveUserDocument } = props;
  // Effects
  useEffect(() => {
    setOpen(false);
    setDeleteModalOpen(false);
  }, [userDocuments]);

  // File Input functions
  const fileChange = () => {
    if (fileRef.current && fileRef.current.files![0]) {
      const newFile = fileRef.current.files![0];
      setFile(newFile);
      openUploadModal();
    } else {
      if (open) {
        closeUploadModal();
      }
    }
  };

  const handleInputClick = () => {
    fileRef.current!.value = "";
    setFile(null);
    fileRef.current!.click();
  };

  //Upload Modal functions
  const openUploadModal = () => {
    setOpen(true);
  };

  const closeUploadModal = () => {
    setOpen(false);
    setFile(null);
  };

  // Delete dialog function
  const onDeleteDocument = () => {
    onRemoveUserDocument(deleteDocumentId!);
  };

  const classes = UserDocumentsStyles();

  return (
    <Box width="100%">
      <AddUserDocModal
        onSubmit={(formValues: AddUserDocFormData) =>
          onSubmit({ ...formValues, file } as AddUserDocData)
        }
        fileName={(file && file.name) || undefined}
        closeModal={closeUploadModal}
        open={open}
      />
      <List disablePadding>
        {userDocuments.map((doc, index) => {
          const fileType = getFileType(doc.contentType);
          const expiryDays = moment.duration(moment(doc.expiryDate, 'DD/MM/YYYY').diff(moment().format('YYYY-MM-DD'))).asDays();

          return (
            <ListItem
              onClick={() =>
                fileType === FileTypes.Image
                  ? setImages([
                      {
                        src: doc.link,
                        alt: doc.name,
                        downloadUrl: doc.link,
                      },
                    ])
                  : viewInNewTab(doc.link)
              }
              classes={{
                container: classes.listItem,
              }}
              key={index}>
              <ListTradeCategoryIcon>
                <FileIcon />
              </ListTradeCategoryIcon>
              <div className={classes.detailsContainer}>
                <ListItemText
                  className={classes.documentName}
                  primary={<Typography variant="h4">{doc.name}</Typography>}
                  secondaryTypographyProps={{ variant: "caption" }}
                  secondary={getLabelFromUserDocumentType(doc.type)}
                />
                {doc.expiryDate && (
                  <ListItemText
                    primary={
                      <Typography
                        style={{ color: color.warning }}
                        variant="caption"
                        component={"h4"}>
                        {LABELS.expiryDate}
                      </Typography>
                    }
                    secondaryTypographyProps={{ variant: "caption" }}
                    secondary={doc.expiryDate}
                  />
                )}
                {doc.expiryDate && (
                  <ListItemText
                    primary={
                      <Typography
                        style={{ color: color.warning }}
                        variant="caption"
                        component={"h4"}>
                        {LABELS.expiring}
                      </Typography>
                    }
                    secondaryTypographyProps={{ variant: "caption" }}
                    secondary={expiryDays > 0 ? Math.round(expiryDays) + ' days' : expiryDays < 0 ? '! Expired' : 'Today'}
                  />
                )}
              </div>
              <ListItemSecondaryAction
                onClick={() => {
                  setDeleteDocumentId(doc.id);
                  setDeleteModalOpen(true);
                }}
                className={`${classes.pointer} ${classes.listItemSecondaryAction}`}>
                <Clear />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem
          className={`${classes.fixedListItems} ${classes.pointer}`}
          onClick={handleInputClick}>
          <ListTradeCategoryIcon>
            <AddFileIcon />
          </ListTradeCategoryIcon>
          <ListItemText
            secondaryTypographyProps={{ variant: "h4" }}
            secondary={LABELS.addDocuments}
          />
          <ListItemSecondaryAction
            onClick={handleInputClick}
            className={classes.pointer}>
            <AddIcon />
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <input
        ref={fileRef}
        className={classes.fileInput}
        type="file"
        onChange={fileChange}
      />

      <ConfirmationDialog
        open={deleteModalOpen}
        cancelText={LABELS.cancel}
        acceptText={LABELS.delete}
        primaryText={LABELS.deleteDocument}
        secondaryText={LABELS.deleteDocumentDescription}
        action={onDeleteDocument}
        handleConfirmationDialogClose={() => setDeleteModalOpen(false)}
        topIcon={
          <Box color={color.warning}>
            <CancelOutlined
              style={{ ...confirmationDialogIconDimensions }}
              color="inherit"
            />
          </Box>
        }
      />
    </Box>
  );
};

export default UserDocuments;
