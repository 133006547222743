import React, { FC, useMemo, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Divider,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import {
  LABELS,
  UPDATE_JOB_COST_FORM_FIELDS,
} from "./JobCostUpdateFormConstants";
import { ListItemBoxProps } from "../../quotes/submitQuote/SubmitQuoteComponentConstants.data";
import InputField, { StyledInput } from "../../quotes/submitQuote/InputField";
import {
  Add,
  AttachMoney,
  HelpOutlined,
  SubdirectoryArrowLeft,
} from "@material-ui/icons";
import { color } from "../../../../theme/Theme";
import { SpecificMaterial } from "../../../../models/quote/Quote.data";
import {
  isValidJobCostUpdate,
  submitQuoteFormHelpers,
} from "../../../../helper/HelperFunctions";
import { jobCostUpdateFormStyles } from "./JobCostUpdateFormStyles.data";
import { QuoteType } from "../../../../models/quote/QuoteType.data";
import {
  Job,
  JobCostUpdateRequest,
} from "../../../../services/jobs/JobsData.data";
import { WorkorderType } from "../../../../models/workOrder/WorkOrderTypes.data";
import { PreApprovedDetails } from "../../../../models/workOrder/Workorder.data";
import ErrorText from "../../../registrationStepsComponents/errorText/ErrorText";
import { LineAmountType } from "../../../../models/payment/PaymentLineAmountType";
import useFetch from "../../../../hooks/useFetch";
import serviceFeeService from "../../../../services/serviceFee/ServiceFeeService";
interface JobCostUpdateFormProps {
  onSubmit: (request: JobCostUpdateRequest) => void;
  heading: string;
  jobCostUpdateButtonText?: string;
  callOutCost: number;
  labourCost: number;
  includedMaterialCost: number;
  totalCost: number;
  specificMaterials: SpecificMaterial[];
  type: QuoteType;
  workorderType: WorkorderType;
  preApprovedDetails: PreApprovedDetails | null;
  changeNote: string;
  jobId: string;
  serviceFeePercent: number;
  lineAmountType: LineAmountType;
  // To be used to show cost limit according to the initial quote approval
  quoteTotalCost: number;
  job: Job;

  // If this prop is provided, cancel button will show up with this function as onClick handler
  onCancel?: () => void;
}

export interface JobCostUpdateFormSpecificCost {
  description: string;
  charge: string;
}

export interface JobCostUpdateRequestFormData {
  callOutCost: string;
  labourCost: string;
  includedMaterialCost: string;
  changeNote: string;
  specificMaterials: JobCostUpdateFormSpecificCost[];
}

const { formatField, getGST } = submitQuoteFormHelpers;

const JobCostUpdateForm: FC<JobCostUpdateFormProps> = ({
  onSubmit,
  heading,
  jobCostUpdateButtonText,
  callOutCost: existingCallOutCost,
  labourCost: existingLabourCost,
  includedMaterialCost: existingMaterialCost,
  specificMaterials: existingSpecificMaterials,
  totalCost: existingTotalCost,
  type,
  onCancel,
  workorderType,
  preApprovedDetails,
  changeNote: existingChangeNote,
  serviceFeePercent,
  jobId,
  lineAmountType,
  quoteTotalCost,
  job,
}) => {
  // state
  const [specificCostItemName, setSpecificCostItemName] = useState<string>("");
  const [initialValues] = useState<JobCostUpdateRequestFormData>({
    callOutCost: "" + existingCallOutCost,
    labourCost: "" + existingLabourCost,
    includedMaterialCost: "" + existingMaterialCost,
    changeNote: existingChangeNote,
    specificMaterials: existingSpecificMaterials.map(
      ({ description, charge }) => ({ description, charge: "" + charge })
    ),
  });

  // tslint:disable-next-line:cyclomatic-complexity
  // other hooks
  const classes = jobCostUpdateFormStyles();

  const {
    state: { data: serviceFee },
    fetchData: fetchServiceFee,
  } = useFetch(serviceFeeService.getServiceFees, undefined, true);

  // functions
  const jobCostUpdateFormDataToRequestConverter = ({
    callOutCost: formCallOutCost,
    labourCost: formLabourCost,
    specificMaterials: formSpecificMaterials,
    changeNote: formChangeNote,
    includedMaterialCost: formIncludedMaterialCost,
  }: JobCostUpdateRequestFormData): JobCostUpdateRequest => {
    const requestValues: JobCostUpdateRequest = {
      callOutCost: parseFloat(formCallOutCost),
      labourCost: parseFloat(formLabourCost),
      serviceFeePercent,
      includedMaterialCost: parseFloat(formIncludedMaterialCost),
      specificMaterial: formSpecificMaterials.map((item) => ({
        ...item,
        charge: parseFloat(item.charge),
      })),
      changeNote: formChangeNote,
      jobId,
      // These 2 fields will be reset in the following steps
      totalCost: 0,
      serviceFee: 0,
      lineAmountType,
    };

    const { callOutCost, labourCost, includedMaterialCost, specificMaterial } =
      requestValues;

    let totalCost =
      callOutCost +
      labourCost +
      includedMaterialCost +
      specificMaterial.map((cost) => cost.charge).reduce((a, b) => a + b, 0);

    const gst = getGST(totalCost, lineAmountType);

    if (lineAmountType !== LineAmountType.INCLUSIVE) {
      totalCost += gst;
    }

    // getting rid of edge cases where JS addition results in values like abc.000000...d
    totalCost = parseFloat(totalCost.toFixed(2));

    // Adding all costs and calculating processing fee
    requestValues.serviceFee = serviceFee?.fee || 0;

    requestValues.totalCost = totalCost;

    return requestValues;
  };

  const getServiceFee = (total: number) => {
    fetchServiceFee(
      total,
      job.quote.maintenanceRequestResponse.agencyId,
      job.quote.maintenanceRequestResponse.privateWorksDetails?.privateName
    );
  };

  const { values, setFieldValue, handleSubmit, handleChange } = useFormik({
    validateOnChange: true,
    initialValues,
    onSubmit: (values) =>
      onSubmit(jobCostUpdateFormDataToRequestConverter(values)),
  });

  const {
    callOutCost,
    includedMaterialCost,
    labourCost,
    specificMaterials,
    changeNote,
  } = values;

  const { total, gst, jobCost, validUpdate, errorMessage } = useMemo(() => {
    let total =
      (parseFloat(callOutCost) || 0) +
      (parseFloat(includedMaterialCost) || 0) +
      (parseFloat(labourCost) || 0) +
      specificMaterials
        .map((cost) => parseFloat(cost.charge) || 0)
        .reduce((a, b) => a + b, 0);

    const gst = getGST(total, lineAmountType);

    const jobCost =
      lineAmountType !== LineAmountType.INCLUSIVE ? total : total - gst;

    if (lineAmountType !== LineAmountType.INCLUSIVE) {
      total += gst;
    }

    const { validUpdate, errorMessage } = isValidJobCostUpdate(
      existingTotalCost,
      total,
      workorderType,
      // [job cost limit feature]  might need to be enabled later
      // preApprovedDetails,
      type === QuoteType.OnSite
    );

    getServiceFee(total);
    return { total, gst, jobCost, validUpdate, errorMessage };
  }, [
    values.callOutCost,
    values.includedMaterialCost,
    values.labourCost,
    values.specificMaterials,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <Paper variant="outlined">
          <Box p={2} pr={0}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.formHeading}>{heading}</Typography>
              <Box ml={2}>
                <Typography variant="caption" color="textSecondary">
                  {`( ${
                    lineAmountType === LineAmountType.INCLUSIVE
                      ? LABELS.inclusiveGST
                      : LABELS.exclusiveGST
                  } )`}
                </Typography>
              </Box>
            </Box>
            {/* quote details fields */}
            <Box mt={1} mb={3}>
              {type === QuoteType.Regular && (
                <>
                  <Box {...ListItemBoxProps} className={classes.listItemBox}>
                    <Typography variant="h4">{LABELS.costOfLabour}</Typography>
                    <Typography
                      component="span"
                      className={classes.quoteFormValue}>
                      <StyledInput
                        name={UPDATE_JOB_COST_FORM_FIELDS.labourCost}
                        onChange={handleChange}
                        value={`${labourCost}`}
                        placeholder={LABELS.enterAmount}
                        disableUnderline
                        startAdornment={
                          <InputAdornment position="start">
                            <AttachMoney />
                          </InputAdornment>
                        }
                      />
                    </Typography>
                  </Box>

                  <Box {...ListItemBoxProps} className={classes.listItemBox}>
                    <Typography variant="h4">
                      {LABELS.includedMaterials}
                    </Typography>
                    <Typography
                      component="span"
                      className={classes.quoteFormValue}>
                      <StyledInput
                        name={UPDATE_JOB_COST_FORM_FIELDS.includedMaterialCost}
                        onChange={handleChange}
                        value={`${includedMaterialCost}`}
                        placeholder={LABELS.enterAmount}
                        disableUnderline
                        startAdornment={
                          <InputAdornment position="start">
                            <AttachMoney />
                          </InputAdornment>
                        }
                      />
                    </Typography>
                  </Box>
                </>
              )}
              <Box {...ListItemBoxProps} className={classes.listItemBox}>
                <Typography variant="h4">{LABELS.callOut}</Typography>
                <Typography component="span" className={classes.quoteFormValue}>
                  <StyledInput
                    name={UPDATE_JOB_COST_FORM_FIELDS.callOutCost}
                    onChange={handleChange}
                    value={callOutCost}
                    placeholder={LABELS.enterAmount}
                    disableUnderline
                    startAdornment={
                      <InputAdornment position="start">
                        <AttachMoney />
                      </InputAdornment>
                    }
                  />
                </Typography>
              </Box>
            </Box>

            {/* Specific Cost section */}
            {type === QuoteType.Regular && (
              <>
                <Typography className={`${classes.formHeading}`}>
                  {LABELS.specificMaterial}
                </Typography>

                {/* Enter specific cost form*/}
                <Box>
                  {specificMaterials.map((specificCostItem, index) => (
                    <Box
                      key={index}
                      className={`${classes.listItemBox}`}
                      {...ListItemBoxProps}>
                      <Box className={classes.specificItemNameInputContainer}>
                        <InputField
                          placeholder={LABELS.enterItemName}
                          disableUnderline
                          onChange={(e) =>
                            setFieldValue(
                              `specificMaterials[${index}].description`,
                              e.currentTarget.value
                            )
                          }
                          style={{ padding: ".6em" }}
                          field={{
                            value: specificCostItem.description,
                          }}
                        />
                      </Box>
                      <Box className={classes.quoteFormValue}>
                        <InputField
                          placeholder={LABELS.enterAmount}
                          disableUnderline
                          field={{ value: `$${specificCostItem.charge}` }}
                          onChange={(e) => {
                            setFieldValue(
                              `specificMaterials[${index}].charge`,
                              formatField(e.currentTarget.value).replace(
                                "$",
                                ""
                              )
                            );
                          }}
                          onBlur={(e) => {
                            setFieldValue(
                              `specificMaterials[${index}].charge`,
                              formatField(e.currentTarget.value, true).replace(
                                "$",
                                ""
                              )
                            );
                          }}
                          teal
                        />
                      </Box>
                    </Box>
                  ))}
                  <Box {...ListItemBoxProps} alignItems="center" pr={2} py={1}>
                    <Box className={classes.specificItemNameInputContainer}>
                      <InputField
                        placeholder={LABELS.enterItemName}
                        disableUnderline
                        onChange={(e) =>
                          setSpecificCostItemName(e.currentTarget.value)
                        }
                        field={{ value: specificCostItemName }}
                      />
                    </Box>

                    <Add
                      className={classes.pointer}
                      onClick={() => {
                        if (specificCostItemName) {
                          setFieldValue("specificMaterials", [
                            ...specificMaterials,
                            {
                              charge: 0,
                              description: specificCostItemName,
                            },
                          ]);
                        }
                        setSpecificCostItemName("");
                      }}
                      color="disabled"
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          {/* Sub Total Section */}
          <Box p={2} pr={0} mt={2} bgcolor={color.wildSand}>
            <Box pr={2} pb={1} {...ListItemBoxProps}>
              <Typography variant="h4">{LABELS.jobCost}</Typography>
              <Box textAlign="right">
                <Typography className={classes.formHeading} color="primary">
                  {`$${jobCost.toFixed(2)}`}
                </Typography>
              </Box>
            </Box>

            <Box pr={2} py={1} {...ListItemBoxProps}>
              <Typography variant="h4">{LABELS.gst}</Typography>
              <Typography className={classes.formHeading} color="primary">
                {`$${gst.toFixed(2)}`}
              </Typography>
            </Box>

            <Box pr={2} py={1} {...ListItemBoxProps}>
              <Typography variant="h4">{LABELS.total}</Typography>
              <Box textAlign="right">
                <Typography className={classes.formHeading} color="primary">
                  {`$${total.toFixed(2)}`}
                </Typography>
                <Typography variant="caption">
                  {workorderType === WorkorderType.PreApproved &&
                  preApprovedDetails
                    ? preApprovedDetails!.hasCostLimit
                      ? LABELS.costLimit(preApprovedDetails!.costLimit)
                      : ""
                    : LABELS.costLimit(quoteTotalCost!)}
                </Typography>
              </Box>
            </Box>

            <Box pr={2} display="flex" alignItems="center" width="100%">
              <Box pr={2} py={1} {...ListItemBoxProps} alignItems="center">
                <Box display="flex" alignItems="center" flexGrow="1">
                  <Tooltip title={LABELS.sortedFeeHelperText}>
                    <HelpOutlined color="disabled" />
                  </Tooltip>
                  <Box ml={2}>
                    <Typography className={classes.inline} variant="h4">
                      {LABELS.sortedFee} {serviceFeePercent}%
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="h4">
                  {/* tslint:disable-next-line:ban-comma-operator */}
                  {`$${serviceFee?.fee}`}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.includesGSTLabel}>
                  {LABELS.includesGstOf}{" "}
                  {`$${getGST(serviceFee?.fee || 0, lineAmountType)}`}
                </Typography>
              </Box>
              <SubdirectoryArrowLeft color="disabled" />
            </Box>
          </Box>
        </Paper>

        <Box mt={2}>
          <Paper variant="outlined">
            <Box p={2}>
              <Typography
                className={`${classes.formHeading} ${classes.notesHeadingMargin}`}>
                {LABELS.notes}
              </Typography>
              <Divider color="disabled" />
              <Box mt={2}>
                <TextField
                  rows={4}
                  fullWidth
                  multiline
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    className: classes.commentsTextArea,
                  }}
                  name={UPDATE_JOB_COST_FORM_FIELDS.changeNote}
                  onChange={handleChange}
                  value={changeNote}
                  placeholder={LABELS.addNote}
                />
              </Box>
            </Box>
          </Paper>
        </Box>

        {/* Submit button */}

        <Box mt={2} display="flex" justifyContent="flex-end">
          {onCancel && (
            <Box mr={2}>
              <Button
                variant="outlined"
                onClick={onCancel}
                className={classes.submitQuoteButton}>
                {LABELS.cancel}
              </Button>
            </Box>
          )}

          <Button
            variant={validUpdate ? "contained" : "outlined"}
            disabled={!validUpdate}
            onClick={() => handleSubmit()}
            className={classes.submitQuoteButton}>
            {jobCostUpdateButtonText || LABELS.update}
          </Button>
        </Box>
        <Box mt={1} display="flex" justifyContent="flex-end">
          {errorMessage && <ErrorText text={errorMessage} />}
        </Box>
      </Box>
    </form>
  );
};

export default JobCostUpdateForm;
