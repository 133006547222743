import {
  RegisterBusinessAction,
  RegisterBusinessActionType,
  RegisterBusinessErrorPayload,
  UpdateBusinessAction,
} from "../actions/RegisterBusinessActions";
import { RegisterBusinessState } from "../state/RegisterBusinessState";
import { RegisterBusinessSuccessPayload } from "../../services/registerBusiness/RegistrationFormRequest.data";

const initialState: RegisterBusinessState = {
  loading: false,
  success: false,
  error: "",
  businessDetails: null,
};

export const registerBusinessReducer = (
  state = initialState,
  action: RegisterBusinessAction | UpdateBusinessAction
) => {
  switch (action.type) {
    case RegisterBusinessActionType.RegisterBusinessRequest: {
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    }

    case RegisterBusinessActionType.RegisterBusinessSuccess: {
      const businessDetails = action.data as RegisterBusinessSuccessPayload;
      return {
        ...state,
        businessDetails,
        loading: false,
        success: true,
        error: null,
      };
    }

    case RegisterBusinessActionType.RegisterBusinessError: {
      const data = action.data as RegisterBusinessErrorPayload;
      return {
        ...state,
        loading: false,
        success: false,
        error: data ? data.error : null,
      };
    }

    default:
      return state;
  }
};
