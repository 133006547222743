export const LABELS = {
  HEADING: "Start getting more work!",
  SUBHEADING:
    "Sign up to the Sorted Trade Portal and get instant access to hundreds of jobs, ready to quote.",
  DESCRIPTION:
    "Sorted works with Real Estate agencies and partners across different industries, all over Australia in over 30 different trade categories. New leads are generated daily.",
  HAVE_AN_ACCOUNT: "Already have an account?",
  LOGIN: "Log In",
  SIGNUP: "SIGN UP",
  REGISTERED_TRADESMAN: "Are you a registered tradesperson?",
};
