import { makeStyles } from "@material-ui/core";
import { color } from "../../../../theme/Theme";

export const AgentsStyles = makeStyles({
  agencyImage: {
    width: "9rem",
    height: "9rem",
    borderRadius: '4px',
    "@media (max-width:1500px) and (min-width:1280px)": {
      width: "7rem",
      height: "7rem",
    },
  },
  agencyCardWrapper: {
    padding: "1rem",
  },
  agentsCardsContainer: {
    width: "100%",
  },
  dialog: {
    minWidth: "30vw",
  },
  pointer: {
    cursor: "pointer",
  },
  agencyCard: {
    height: "100%",
  },
  chip: {
    cursor: 'pointer'
  },
  active: {
    border: `1px solid ${color.teal}`,    
  },
  cardContentRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  button: {
    padding: '10px'
  }
});
