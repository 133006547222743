export const LABELS = {
  description: "Description",
  requestedDate: "Requested date:",
  quoteDue: "Quote due:",
  additionalDetails: "Additional details",
  quoteId: "Quote ID:",
  propertyAccess: "Property access",
  preferredTime: "Preferred time",
  preferredDays: "Preferred days",
  noUnaccompaniedMinors: "No unaccompanied minors",
  pet: "Pet",
  animalDescription: "Animal description",
  clearAccess: "Clear access to site",
  stairsAccess: "Stairs to access site",
  parkingOnSite: "Parking onsite",
  unconfirmed: "Unconfirmed",
  propertyManager: "Property manager",
  tenants: "Tenants",
  contacts: "Contacts",
  images: "Images",
  propertyDetails: "Property details:",
  quoteDetails: "Quote details",
  onSite: "On-site quote request",
  labourCost: "Cost of labour",
  includedMaterialsCost: "Cost of included materials",
  callOutCost: "Call out cost",
  gst: "GST",
  totalCost: "Total cost",
  materials: "Materials:",
  scopeOfWork: "Scope of work description",
  startDate: "Start date",
  completionDate: "Completion date",
};
