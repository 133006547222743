import {
  UpdateBranchAction,
  UpdateBranchActions,
  UpdateBranchActionType,
} from "../actions/BranchActions";
import { UpdateBranchDetailsRequestData } from "../../services/serviceableRegions/serviceableRegionsServiceData.data";
import BranchService from "../../services/branch/BranchService";
import { put, takeEvery } from "redux-saga/effects";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import { ListBranchesActions } from "../actions/ListBranchesActions";

export function* updateBranchWorker(action: UpdateBranchAction) {
  try {
    const { payload, officeId } = action.data as UpdateBranchDetailsRequestData;

    yield put(LoadingIndicatorActions.show());
    const response = yield BranchService.put(payload, officeId);

    yield put(UpdateBranchActions.updateBranchDetailsSuccess(response));
    yield put(ListBranchesActions.updateBranch(response))
  } catch (error) {
    yield put(UpdateBranchActions.updateBranchDetailsError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const updateBranchWatcher = [
  takeEvery(UpdateBranchActionType.UpdateBranchDetailsRequest, (action) =>
    updateBranchWorker({
      type: action.type,
      data: (action as UpdateBranchAction).data,
    })
  ),
];

export default updateBranchWatcher;