import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../theme/Theme";

export const LandingScreenStyles = makeStyles({
  heading: {
    fontSize: "4.25em",
    letterSpacing: "-.5px",
  },
  light: {
    color: color.light,
  },
  topMargin: {
    marginTop: "1.5em",
  },
  signUpButton: {
    padding: "1em",
    width: "196px",
    height: "48px",
    marginTop: "5em",

    backgroundColor: color.teal,
    color: color.light,
    textAlign: "center",
    borderRadius: "0",
    "&:hover": {
      backgroundColor: color.teal,
    },
  },
  loginLink: {
    fontSize: "1em",
    fontWeight: 500,
    color: color.secondary200,
  },
  tradesmanHeading: {
    marginBottom: "1em",
  },
});
