import { makeStyles } from "@material-ui/core";
import { color } from "../../../../theme/Theme";

export const SavedPaymentMethodsStyles = makeStyles({
  listItemStyle: {
    height: 56,
    marginTop: 10,
  },
  imageStyle: {
    width: 24,
    height: 24,
    margin: 6,
  },
  itemTextStyle: {
    marginLeft: 23,
  },
  textStyle: {
    marginLeft: 23,
    color: color.dark,
    opacity: 0.6,
    fontSize: 16,
  },
  paymentMethodDetailsContainer: {
    padding: "0.5rem 3.5rem",
  },
  detailsRoot: {
    borderBottom: `1px solid ${color.borderColor}`,
  },
  font14: {
    fontSize: 14,
  },
  addButton: {
    background: color.addButtonBg,
    color: color.addButtonText,
    height: "2.5rem",
    borderRadius: 0,
  },
});
