import { takeEvery, put } from "redux-saga/effects";
import { Logging } from "../../helper/Logging";
import RegisterBusinessService from "../../services/registerBusiness/RegisterBusinessService";
import { LoadingIndicatorActions } from "../actions/LoadingIndicatorAction";
import {
  RegisterBusinessAction,
  RegisterBusinessActions,
  RegisterBusinessActionType,
  RegisterBusinessRequestPayload,
  UpdateBusinessActionType,
  UpdateBusinessAction,
  UpdateBusinessActions,
} from "../actions/RegisterBusinessActions";
import OnboardingStageService from "../../services/onboardingStage/OnboardingStageService";
import { OnboardingStage } from "../../helper/OnboardingStages";
import {
  RegisterBusinessRequest,
  RegisterBusinessSuccessPayload,
  UpdateBusinessSuccessPayload,
  UpdateBusinessRequest,
} from "../../services/registerBusiness/RegistrationFormRequest.data";

export function* postRegisterBusinessWorker(
  action: RegisterBusinessAction
): IterableIterator<any> {
  const data = action.data as RegisterBusinessRequestPayload;
  const { request, onSuccess } = { ...data };
  try {
    Logging.debug("RegisterBusinessSagas.registerBusinessWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    const response: RegisterBusinessSuccessPayload = (yield RegisterBusinessService.post(
      request! as RegisterBusinessRequest
    ))!;

    yield put(RegisterBusinessActions.registerBusinessSuccess(response));

    // Updating onboarding stage
    const onboardingRequest = {
      id: localStorage.getItem("userId")!,
      adminOnBoardingStage: OnboardingStage.AddServicableRegions,
    };

    yield OnboardingStageService.put(onboardingRequest);

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    Logging.error("RegisterBusinessSagas.registerBusinessWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(RegisterBusinessActions.registerBusinessError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* putRegisterBusinessWorker(action: UpdateBusinessAction) {
  const data = action.data as UpdateBusinessRequest;
  try {
    Logging.debug("RegisterBusinessSagas.putRegisterBusinessWorker", {
      type: action.type,
      data: Logging.traceData(action.data),
    });

    yield put(LoadingIndicatorActions.show());

    const response: UpdateBusinessSuccessPayload = (yield RegisterBusinessService.put(
      data
    ))!;

    yield put(UpdateBusinessActions.updateBusinessSuccess(response));
  } catch (error) {
    Logging.error("RegisterBusinessSagas.updateBusinessWorker", {
      error: error.message,
      type: action.type,
      data: action.data,
    });
    yield put(RegisterBusinessActions.registerBusinessError({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

const registerBusinessWatcher = [
  takeEvery(RegisterBusinessActionType.RegisterBusinessRequest, (action) =>
    postRegisterBusinessWorker({
      type: action.type,
      data: (action as RegisterBusinessAction).data,
    })
  ),

  takeEvery(UpdateBusinessActionType.UpdateBusinessRequest, (action) =>
    putRegisterBusinessWorker({
      type: action.type,
      data: (action as UpdateBusinessAction).data,
    })
  ),
];

export default registerBusinessWatcher;
