import { AgencyBranchTermsResponse } from "../../models/agency/AgencyBranchTermsResponse";
import { StoreAction } from "../StoreHelper";
import {
  AgencyBranchTermsRequestPayload,
  PostTermsAcceptanceActionPayload,
  PostTermsAcceptanceRequest,
} from "../../services/agencies/AgencyServiceData.data";

export enum AgencyActionType {
  GetAgencyBranchTermsRequest = "GET_AGENCY_BRANCH_TERMS_REQUEST",
  GetAgencyBranchTermsSuccess = "GET_AGENCY_BRANCH_TERMS_SUCCESS",
  GetAgencyBranchTermsError = "GET_AGENCY_BRANCH_TERMS_ERROR",

  RespondToAgencyBranchTermsRequest = "RESPOND_TO_AGENCY_BRANCH_TERMS",
  RespondToAgencyBranchTermsSuccess = "RESPOND_TO_AGENCY_BRANCH_TERMS_SUCCESS",
  RespondToAgencyBranchTermsError = "RESPOND_TO_AGENCY_BRANCH_TERMS_ERROR",
}

export type AgencyActionPayload =
  | AgencyBranchTermsResponse
  | AgencyBranchTermsRequestPayload
  | PostTermsAcceptanceActionPayload
  | PostTermsAcceptanceRequest
  | Error
  | undefined;

export type AgencyAction = StoreAction<AgencyActionType, AgencyActionPayload>;

export class AgencyActions {
  public static getAgencyBranchTerms(
    data: AgencyBranchTermsRequestPayload
  ): AgencyAction {
    return {
      type: AgencyActionType.GetAgencyBranchTermsRequest,
      data,
    };
  }

  public static getAgencyBranchTermsSuccess(
    termsData: AgencyBranchTermsResponse
  ) {
    return {
      type: AgencyActionType.GetAgencyBranchTermsSuccess,
      data: termsData,
    };
  }

  public static getAgencyBranchTermsError(error: Error) {
    return {
      type: AgencyActionType.GetAgencyBranchTermsError,
      data: error,
    };
  }

  public static acceptAgencyBranchTermsRequest(
    payload: PostTermsAcceptanceActionPayload
  ) {
    return {
      type: AgencyActionType.RespondToAgencyBranchTermsRequest,
      data: payload,
    };
  }

  public static acceptAgencyBranchTermsSuccess(
    payload: PostTermsAcceptanceRequest
  ) {
    return {
      type: AgencyActionType.RespondToAgencyBranchTermsSuccess,
      data: payload,
    };
  }

  public static acceptAgencyBranchTermsError(error: Error) {
    return {
      type: AgencyActionType.RespondToAgencyBranchTermsError,
      data: error,
    };
  }
}
