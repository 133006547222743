import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../theme/Theme";

export const ListMembersStyle = makeStyles({
  userContainer: {
      borderRadius: 1,
      marginBottom: '16px',
  },
  userDetailsContainer: {
      border: '1px solid'
  },
  addNewContainer: {
    padding: '16px',
    border: `1px solid ${color.lightGrey}`
  },
  inviteNewUserLabel: {
    fontSize: '22px',
    lineHeight: '24px',
    color: color.secondary200
  },
  labelSummary: {
    fontSize: '16px',
    lineHeight: '24px',
    color: color.secondary200
  },
  valueSummary: {
      fontSize: '16px',
      lineHeight: '20px',
      color: color.secondary500
  },
  labelDetail: {
    fontSize: '13px',
    lineHeight: '16px',
    color: 'rgba(0, 0, 0, 0.38)'
  },
  valueDetail: {
    fontSize: '16px',
    lineHeight: '24px',
    color: color.secondary200
  },
  editButton: {
    display: 'flex',
    alignItems: 'center',
    background: color.primary,
    color: color.light,
    padding: '5px 35px',
  },
  addNewButton: {
    display: 'flex',
    alignItems: 'center',
    background: color.light,
    padding: '5px 25px',
    borderRadius: 5,
    '&:disabled': {
      background: color.lightGrey
    },
  }
});
