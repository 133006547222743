import React, { FC } from "react";
import { Button } from "@material-ui/core";
import AuthPagesWrapper from "../authPagesWrapper/AuthPagesWrapper";
import { Formik } from "formik";
import { History } from "history";
import RenderInputField from "../FormikComponents/RenderInputField";
import {
  LABELS,
  VALIDATION_SCHEMA_IMPERSONATION,
} from "../login/LoginConstants";
import { LoginComponentStyle } from "../login/LoginStyles";
import { LoginImpersonatePayload } from "src/services/login/LoginData";

interface LoginImpersonateProps {
  history: History;
  postLogin: (data: LoginImpersonatePayload) => void;
  userEmail?: string;
  errorMessage?: string;
  verifyError?: string;
}

const initialValues: LoginImpersonatePayload = { username: "", otp: "" };

const LoginImpersonate: FC<LoginImpersonateProps> = ({
  postLogin,
  errorMessage,
}) => {
  const classes = LoginComponentStyle();

  const loginForm = ({ handleSubmit, setFieldValue, isValid, dirty }) => {
    return (
      <form onSubmit={handleSubmit}>
        <div className={classes.titleStyle}>
          <h2 className={classes.labelStyle}>{LABELS.MY_ACCOUNT}</h2>
          <p style={{ marginTop: "9px" }}>{LABELS.ENTER_DETAILS_HEADING}</p>
        </div>
        <RenderInputField
          name="username"
          label={LABELS.USERNAME}
          onChange={(event) => {
            setFieldValue("username", event.currentTarget.value);
          }}
        />
        <RenderInputField
          name="otp"
          type="number"
          noArrowNumberInput
          label={LABELS.ENTER_OTP}
          onChange={(event) => {
            setFieldValue("otp", event.currentTarget.value);
          }}
        />
        {errorMessage && (
          <div className={classes.errorText}>{errorMessage}</div>
        )}
        <div className={classes.buttonContainer}>
          <Button
            fullWidth={true}
            type="submit"
            variant={!dirty || !isValid ? "outlined" : "text"}
            className={classes.loginButton}
            disabled={!dirty || !isValid}>
            {LABELS.LOGIN}
          </Button>
        </div>
      </form>
    );
  };

  return (
    <AuthPagesWrapper includeLogo={true} containerClassName="login-fields">
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={VALIDATION_SCHEMA_IMPERSONATION}
        onSubmit={postLogin}
        render={loginForm}
      />
    </AuthPagesWrapper>
  );
};

export default LoginImpersonate;
