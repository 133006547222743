import * as React from "react";
import { withStyles, TextField, InputAdornment } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  GooglePlacesHelper,
  GooglePlaceResult,
  PlacePredictions,
} from "../../helper/GooglePlaces/GooglePlacesHelper";
import { AddressValue } from "../../helper/GooglePlaces/AddressValue.data";
import { AddressPickerState } from "./AddressPickerState.data";
import { reduxForm } from "redux-form";
import { TestForms } from "../../helper/Forms";
import { Logging } from "../../helper/Logging";
import {
  AddressPickerStyles,
  addressPickerStyles,
} from "./AddressPickerStyles.data";
import { AppIcon, AppIconType, AppIconSize } from "../AppIcon/AppIcon";
import { debounce } from "lodash";
import { getRegion } from "../../container/addServiceableRegionsForm/AddServiceableRegionsForm";

export interface AddressPickerProps {
  readonly id: string;
  readonly value?: AddressValue | undefined | null;

  // optional
  readonly disabled?: boolean | undefined | null;
  readonly placeholder?: string | undefined | null;
  readonly label?: string | undefined | null;
  readonly fixSubPremise?: boolean | undefined | null; // default: true, if true, calls GooglePlacesHelper.fixAddressSubPremise()
  readonly handleAddressChanged?:
    | ((value: AddressValue) => void)
    | undefined
    | null;
  readonly changeEverykeyPress?: boolean;
  readonly outlined?: boolean;
  readonly endAdornmentPosition?: "start" | "end";
  readonly flushOnSelect?: boolean;
  readonly regionSearch?: boolean;
  readonly pincodeAndSuburb?: boolean;
  readonly onBlur?: (value: AddressValue) => void;
}

interface AddressPickerReduxProps {
  readonly classes: AddressPickerStyles;
}

type OwnProps = AddressPickerProps & AddressPickerReduxProps;

/// <summary>
/// Google API address autocomplete picker
/// https://talum.github.io/blog/2017/05/20/autocomplete-an-address-with-a-react-form/
/// </summary>
export class AddressPickerComponent extends React.Component<
  OwnProps,
  AddressPickerState
> {
  // private addressInput: HTMLInputElement;
  placesService: google.maps.places.PlacesService;
  autoCompleteService: google.maps.places.AutocompleteService;
  debouncedFn: any;

  public constructor(props: OwnProps) {
    super(props);
    this.state = this.initializeState(props);

    this.placesService = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    this.autoCompleteService = new google.maps.places.AutocompleteService();
  }

  public componentDidUpdate(
    prevProps: Readonly<OwnProps>,
    prevState: AddressPickerState
  ) {
    const { value, disabled } = prevProps;
    if (
      !AddressValue.equals(value, this.props.value) ||
      disabled !== this.props.disabled
    ) {
      this.setState(this.initializeState(this.props));
    }
  }

  // Called when user clicks on a prediction in dropdown Fetches details about place making another API call using PlacesService
  public getPlaceDetailsUsingId(
    inputValue: string | null | undefined,
    id: string
  ) {
    const { fixSubPremise } = this.props;
    let address: AddressValue = { inputValue, place: null };
    this.placesService.getDetails(
      { placeId: id },
      (placeDetails: GooglePlaceResult) => {
        if (fixSubPremise !== false) {
          address = GooglePlacesHelper.fixAddressSubPremise({
            ...address,
            place: placeDetails,
          });
        }

        this.setState(
          {
            value: address,
            suggestions: [],
          },
          () => this.notifyChange(true)
        );
      }
    );
  }

  /// <summary>
  /// Be prepared for args.place being null. Usually, when tesing and mock implementation is used.
  /// </summary>

  // Fetches the predictions, this call is debounced (in onChange method) to prevent too many API calls
  public handleAddressChanged(
    value: AddressValue,
    updateAddress: boolean = false
  ) {
    let types;
    this.props.regionSearch ? (types = ["(regions)"]) : (types = []);

    const { id } = this.props;
    Logging.trace(`AddressPicker.handleAddressChanged() #${id}`, value);

    // tslint:disable-next-line: no-unused-expression
    value &&
      value.inputValue &&
      this.autoCompleteService.getPlacePredictions(
        {
          input: value.inputValue!,
          componentRestrictions: { country: "au" },
          types,
        },
        (predictions) => {
          if (predictions) {
            // For serviceable regions
            if (this.props.pincodeAndSuburb) {
              const pinCodeAndSuburbPrediction: any = [];
              // counter for testing whether all the API requests have returned values
              //  when all are done we can change state variable suggestions
              let counter: number = predictions.length;

              predictions.forEach((prediction, index) => {
                this.placesService.getDetails(
                  { placeId: prediction.place_id },
                  (placeDetails: GooglePlaceResult) => {
                    if (this.props.fixSubPremise !== false) {
                      const address = GooglePlacesHelper.fixAddressSubPremise({
                        inputValue: value.inputValue,
                        place: placeDetails,
                      });

                      // Formatting description and hiding outcomes with no postcode
                      const addressComponents =
                        address.place!.address_components!;
                      const region = getRegion(addressComponents);
                      if (region.locality && region.postcode) {
                        address.place!.formatted_address = `${region.locality} , ${region.postcode}`;
                        pinCodeAndSuburbPrediction.push(address);
                      }

                      counter = counter - 1;

                      if (counter === 0) {
                        this.setState(
                          {
                            value,
                            suggestions: [
                              ...pinCodeAndSuburbPrediction,
                            ] as AddressValue[],
                          },
                          () => this.notifyChange(updateAddress)
                        );
                      }
                    }
                  }
                );
              });
            } else {
              this.setState(
                {
                  value,
                  suggestions: predictions,
                },
                () => this.notifyChange(updateAddress)
              );
            }
          }
        }
      );
  }

  public render() {
    const {
      placeholder,
      classes,
      outlined,
      label,
      endAdornmentPosition,
      pincodeAndSuburb,
    } = {
      ...this.props,
    };
    const { suggestions } = this.state;

    return (
      <Autocomplete
        key={this.state.key}
        id="addrress-picker"
        freeSolo
        options={suggestions as PlacePredictions[] | AddressValue[]}
        getOptionLabel={(option: PlacePredictions | AddressValue) =>
          pincodeAndSuburb
            ? (option as AddressValue).place!.formatted_address!
            : (option as PlacePredictions).description!
        }
        onChange={(e, value: PlacePredictions | AddressValue) => {
          if (value) {
            this.props.pincodeAndSuburb
              ? this.setState(
                  {
                    value: value as AddressValue,
                    suggestions: [],
                  },
                  () => this.notifyChange(true)
                )
              : this.getPlaceDetailsUsingId(
                  (value as PlacePredictions).description,
                  (value as PlacePredictions).place_id
                );
          }
        }}
        renderInput={(params) => (
          <TextField
            onChange={this.onChange}
            onBlur={this.onBlur}
            variant={outlined ? "outlined" : "standard"}
            {...params}
            label={label}
            placeholder={placeholder || ""}
            margin="normal"
            InputProps={{
              ...params.InputProps,
              startAdornment:
                endAdornmentPosition === "start" ? (
                  <InputAdornment
                    position="start"
                    className={classes.startAdornmentIcon}>
                    <AppIcon
                      type={AppIconType.Search}
                      size={AppIconSize.Normal}
                    />
                  </InputAdornment>
                ) : null,
              endAdornment:
                endAdornmentPosition !== "start" ? (
                  <InputAdornment position="end">
                    <AppIcon
                      type={AppIconType.Search}
                      size={AppIconSize.Small}
                    />
                  </InputAdornment>
                ) : null,
            }}
          />
        )}
      />
    );
  }

  private onChange = (e: React.ChangeEvent) => {
    const target = e.currentTarget as HTMLInputElement;

    if (!this.debouncedFn) {
      this.debouncedFn = debounce((field) => {
        this.handleAddressChanged(
          {
            inputValue: field.value,
            place: null,
          },
          this.props.changeEverykeyPress
        );
      }, 1000);
    }

    this.debouncedFn(target);
  };

  private onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur(this.state.value);
    }
  };

  private initializeState(props: OwnProps): AddressPickerState {
    // this.state can be null/undefined here
    return {
      value: props.value
        ? props.value
        : // this.state ? (this.state.value || new AddressValue()) : -- controlled, always takes props
          new AddressValue(),
      suggestions: [],
      key: 1,
    };
  }

  private notifyChange(updateAddress: boolean) {
    const { handleAddressChanged, flushOnSelect } = this.props;
    if (handleAddressChanged && updateAddress) {
      handleAddressChanged(this.state.value);
      if (flushOnSelect) {
        this.setState({
          value: {
            inputValue: undefined,
            place: null,
          },
          suggestions: [],
          key: this.state.key + 1,
        });
      }
    }
  }
}

// component with styles
export const AddressPicker = withStyles(addressPickerStyles)(
  AddressPickerComponent
);

// used for testing only
export const AddressPickerTestForm = reduxForm({
  form: TestForms.AddressPickerForm,
})(AddressPicker as any) as any;
