import { makeStyles } from "@material-ui/core/styles";
import { color } from "../../theme/Theme";

export const RenderInputFieldStyles = makeStyles({
  input: {
    color: color.sortedNavy,
    display: "flex",
    alignItems: "center",
  },
  labels: {
    transform: `translate(${-10}px, ${1.5}px) scale(${0.75})`,
  },
  errorMessageStyles: {
    color: color.warning,
    marginTop: 10,
  },
  inputNoArrow: {
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
});

export const loginLabels = {
  marginLeft: "10px",
};

export const inputsFields = {
  width: "100%",
  backgroundColor: "white",
  height: 50,
};

export const inputStylePropsGenerator = (classes) => ({
  style: inputsFields,
  labelStyle: loginLabels,
  overrideInputStyles: { input: classes.input },
  overrideLabelStyles: { shrink: classes.labels },
});
